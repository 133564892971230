import React, { useState, useEffect } from "react";
import Widget from "components/Widget/index";
import { Row, Col, Table, Empty } from "antd";
import RequestHandler from "util/RequestHandler";
import CurrencyFormat from "react-currency-format";

const SubscriberFirmCollectAndPaymentPlanReport = () => {
  const [tableData, setWidgetData] = useState({
    loading: true,
  });

  const [query, setQuery] = useState("$orderby=collectPlanTotal desc");
  const [take, setTake] = useState(10);
  useEffect(() => getData(), []);

  const getData = () => {
    RequestHandler.post(
      `/SubscriberFirmReport? ${query} &$count=true&$top=${take}`,
      {},
      false
    ).then((response) => {
      setWidgetData({
        data: response.value,
        loading: false,
      });
    });
  };

  const renderCurrencyFormat = (data) => (
    <CurrencyFormat
      value={data}
      displayType={"text"}
      decimalSeparator={","}
      thousandSeparator={"."}
      prefix={"₺"}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );

  const columns = [
    {
      title: "Daire Adı",
      dataIndex: "firmId",
    },
    {
      title: "Planlanan Tahsilat",
      dataIndex: "collectPlanTotal",
      render: renderCurrencyFormat,
    },
    {
      title: "Gerçekleşen Tahsilat",
      dataIndex: "collectTotal",
      render: renderCurrencyFormat,
    },
    {
      title: "Kalan Tahsilat",
      dataIndex: "accountBalanceTotal",
      render: renderCurrencyFormat,
    },
  ];

  return (
    <>
      <Widget
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0">Daire Durumları</h2>
        }
        extra={
          <p className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
            <a href="/subscriberfirm-status-report">Tümü</a>{" "}
            <i className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle" />
          </p>
        }
      >
        <Row id="tableArea">
          <Col span={24}>
            <Table
              columns={columns}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Veri Yok"
                  />
                ),
              }}
              style={{ overflowX: "auto", height: 443, width: "100%" }}
              rowKey="firmId"
              filterable={false}
              sortable={false}
              pageable={false}
              pagination={false}
              dataSource={tableData.data}
              loading={tableData.loading}
              size="small"
            />
          </Col>
        </Row>
      </Widget>
    </>
  );
};

export default SubscriberFirmCollectAndPaymentPlanReport;
