import React, { useEffect, useState } from "react";
import {
  Table,
  Empty,
  Tooltip,
  Col,
  Row,
  notification,
  Drawer,
  Button,
  Popconfirm,
} from "antd";
import {
  PlusOutlined,
  BarsOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import config from "../../util/ApiforContext";
import FirmAccountAddForm from "../Forms/FirmAccountAddForm";
import { useHistory } from "react-router-dom";
import FirmAccountUpdate from "../Forms/FirmAccountUpdate";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
const expiration = localStorage.getItem("expiration");
const openNotificationWithIconDelete = (type) => {
  notification[type]({
    message: "BAŞARILI",
    description: "Silme işlemi başarı ile gerçekleşti.",
    duration: 2,
  });
};
const openNotificationWithIconWarningDelete = (type) => {
  notification[type]({
    message: "HATA",
    description: "Silme işlemi sırasında bir hata oluştu.",
    duration: 2,
  });
};
const FirmAccountPage = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const history = useHistory();
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  const [firmAccounts, setFirmAccounts] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [dataState, setDataState] = useState([{ skip: 0, take: 10 }]);
  const [loading, setLoading] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [bankDropdown, setBankDropdown] = useState([]);
  const [bankBranchDropdownUpdate, setBankBranchDropdownUpdate] = useState([]);
  const [firmDropdown, setFirmDropdown] = useState([]);
  const [firmAccountId, setFirmAccountId] = useState("");
  const [take, setTake] = useState(20);
  const [skip, setSkip] = useState(0);
  const [bankBranchName, setBankBranchName] = useState("");
  const [firmId, setFirmId] = useState("");
  const [iban, setIban] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankBranchId, setBankBranchId] = useState("");
  const [bankId, setBankId] = useState("");
  const errorList = [];
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 2,
      });
    });
  };
  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }

    setFirmAccounts({
      firmAccounts,
      loading: true,
    });
    if(props.match.params.firmId === undefined){
      axios
      .get(
        `${config.apiUrl}/FirmAccount?$count=true&$skip=${skip}&$top=${take}`,
        config.headers
      )
      .then((res) => {
        setFirmAccounts({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    }else{
      axios
      .get(
        `${config.apiUrl}/FirmAccount?$filter=firmId eq ${props.match.params.firmId} &$count=true&$skip=${skip}&$top=${take}`,
        config.headers
      )
      .then((res) => {
        setFirmAccounts({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    }
    
    axios
      .get(`${config.apiUrl}/bank`, config.headers)
      .then((res) => {
        setBankDropdown(res.data.value);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  }, [setBankDropdown, setDataState, skip, setSkip, setFirmAccounts,props]);

  const showDrawerUpdate = (value) => {
    axios
      .get(`${config.apiUrl}/Firm`, config.headers)
      .then((res) => {
        setFirmDropdown(res.data.value);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    axios
      .get(`${config.apiUrl}/FirmAccount/getbyid/${value}`, config.headers)
      .then((res) => {
        setBankBranchId(res.data.bankBranchId?.toString());
        setBankId(res.data.bankId.toString());
        setFirmId(res.data.firmId);
        setAccountNumber(res.data.accountNumber);
        setIban(res.data.iban);
        setIsDefault(res.data.isDefault);
        setFirmAccountId(value);
        setVisibleUpdate(true);
        axios
          .get(
            `${config.apiUrl}/bankbranch/getbranchforbankaccount?bankId=${res.data.bankId}`,
            config.headers
          )
          .then((rest) => {
            setBankBranchDropdownUpdate(rest.data);
          })
          .catch((error) => {
            if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              history.push("/not-authorized-access");
            } else if (error.response.status === 406) {
              error.response.data.data.map((item) => {
                errorList.push(item);
              });
              errorListNotification("error");
            }
          });
        if (res.data.bankBranchId !== undefined) {
          axios
            .get(
              `${config.apiUrl}/bankbranch/getbranchforbankaccountname?bankId=${res.data.bankId}&accountId=${res.data.bankBranchId}`,
              config.headers
            )
            .then((rest) => {
              setBankBranchName(rest.data.name);
              setBankBranchId(rest.data.id);
            })
            .catch((error) => {
              if (
                error.response.status === 403 ||
                error.response.status === 401
              ) {
                history.push("/not-authorized-access");
              } else if (error.response.status === 406) {
                error.response.data.data.map((item) => {
                  errorList.push(item);
                });
                errorListNotification("error");
              }
            });
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };
  const onClose = () => {
    setVisible(false);
    setVisibleUpdate(false);
  };
  const reList = () => {
    setFirmAccounts({
      firmAccounts,
      loading: true,
    });
    axios
      .get(
        `${config.apiUrl}/FirmAccount?$count=true&$skip=${skip}&$top=${take}`,
        config.headers
      )
      .then((res) => {
        setFirmAccounts({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };
  const getData = (val) => {
    if (val === "success") {
      reList();
      setVisibleUpdate(false);
      setVisible(false);
    }
  };
  const onCloseUpdate = () => {
    setVisibleUpdate(false);
  };
  const handleDelete = (key) => {
    axios
      .delete(`${config.apiUrl}/FirmAccount?id=${key}`, config.headers)
      .then((res) => {
        if (res.status == 204 || res.status == 200) {
          openNotificationWithIconDelete("success");
          setFirmAccounts({
            data: firmAccounts.data.filter((item) => item.id !== key),
            total: firmAccounts.total - 1,
            loading: false
          });
          if (firmAccounts.data.filter((item) => item.id !== key).length == 0) {
            window.location.reload();
          }
        } else {
          openNotificationWithIconWarningDelete("error");
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        } else {
          openNotificationWithIconWarningDelete("error");
        }
      });
  };
  const paging = (p) => {
    setDataState((prevState) => {
      prevState.skip = (p.current - 1) * 20;
      setSkip(prevState.skip);
      return {
        prevState,
      };
    });
  };

  const columns = [
    {
      title: "BANKA",
      dataIndex: "bankName",
      key: "bankName",
      render: (text, row, index) => {
        return (
          <div style={{ width: 100 }}>
            {" "}
            <img src={row.bankLogo}></img>
          </div>
        );
      },
    },
    {
      title: "Cari Adı",
      dataIndex: "firmName",
      key: "firmName",
      width: "10%",
      render: (text, row, index) =>
        text == null ? (
          ""
        ) : (
          <Tooltip placement="topLeft" title={text}>
            {text.substring(0, 15).length < 15
              ? text.substring(0, 15) + ""
              : text.substring(0, 15) + "..."}
          </Tooltip>
        ),
    },
    {
      title: "IBAN",
      dataIndex: "iban",
      key: "iban",
    },
    {
      title: "Hesap Numarası",
      dataIndex: "accountNumber",
      key: "accountNumber",
    },
    {
      title: "",
      dataIndex: "operation",
      align: "right",
      width: "10%",
      render: (text, record) =>
        firmAccounts.data.length >= 1 ? (
          <a
            onClick={() => {
              showDrawerUpdate(record.id);
            }}
            style={{ color: "orange", fontSize: "130%" }}
          >
            <EditOutlined />
          </a>
        ) : null,
    },
    {
      title: "",
      dataIndex: "operation",
      width: "10%",
      render: (text, record) =>
        firmAccounts.data.length >= 1 ? (
          <Popconfirm
            title="Silmek istiyor musunuz?"
            okText="Evet"
            cancelText="Hayır"
            onConfirm={() => handleDelete(record.id)}
          >
            <a style={{ color: "red", fontSize: "130%" }}>
              <DeleteOutlined />
            </a>
          </Popconfirm>
        ) : null,
    },
  ];
  return (
    <div>
      <Row>
        {isTabletOrMobileDevice && (
          <>
            <Col span={13}>
              <p style={{ fontSize: "120%" }}>
                <BarsOutlined /> CARİ HESAPLARI
              </p>
              <hr></hr>
              <br></br>
            </Col>
            <Col span={11}>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setVisible(true);
                }}
              >
                <PlusOutlined /> Cari Hesabı Ekle
              </Button>
            </Col>
          </>
        )}
        {isDesktopOrLaptop && (
          <>
            <Col span={20}>
              <p style={{ fontSize: "120%", marginTop: -9 }}>
                <BarsOutlined /> CARİ HESAPLARI
              </p>
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                onClick={() => {
                  setVisible(true);
                }}
              >
                <PlusOutlined /> Cari Hesabı Ekle
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Drawer
        title="Cari Hesabı Güncelle"
        width={241}
        onClose={onCloseUpdate}
        visible={visibleUpdate}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <FirmAccountUpdate
          firmId={firmId}
          sendDataForUpdate={getData}
          firmAccountId={firmAccountId}
          bankId={bankId}
          iban={iban}
          bankBranchName={bankBranchName}
          accountNumber={accountNumber}
          isDefault={isDefault}
        />
      </Drawer>
      <Drawer
        title="Cari Hesabı Ekle"
        width={241}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <FirmAccountAddForm firmId={props.match.params.firmId}/>
      </Drawer>
      <Row>
        {isDesktopOrLaptop && (
          <>
            <Col span={2}></Col>
            <Col span={20}>
              <Table
                columns={columns}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Veri Yok"
                    />
                  ),
                }}
                style={{ overflowX: "auto", maxHeight: "100%" }}
                size="small"
                rowKey="Id"
                filterable={true}
                sortable={true}
                pageable={true}
                onChange={paging}
                pagination={{ pageSize: 20, total: firmAccounts.total }}
                dataSource={firmAccounts.data}
                loading={firmAccounts.loading}
              />
            </Col>
            <Col span={2}></Col>
          </>
        )}
        {isTabletOrMobileDevice && (
          <>
            <Col span={24}>
              <Table
                columns={columns}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Veri Yok"
                    />
                  ),
                }}
                style={{ overflowX: "auto", width: "100%", maxHeight: "100%" }}
                size="small"
                rowKey="Id"
                filterable={true}
                sortable={true}
                pageable={true}
                onChange={paging}
                pagination={{ pageSize: 20, total: firmAccounts.total }}
                dataSource={firmAccounts.data}
                loading={firmAccounts.loading}
              />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default FirmAccountPage;
