import React, { useState, useEffect } from "react";
import { Row, Col, Table, Empty, Card, DatePicker } from "antd";
import RequestHandler from "util/RequestHandler";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { BarsOutlined, MenuUnfoldOutlined, CaretRightFilled } from "@ant-design/icons";
import { Cell, Pie, PieChart, Tooltip, Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from "recharts";

const BankVolumeReport = () => {
  const [tableData, setTableData] = useState({
    data: [],
    chartData: [],
    loading: true,
  });
  const [barChartData, setBarChartData] = useState([]);
  const [totalField, setTotalField] = useState();
  const [totalFields, setTotalFields] = useState();

  useEffect(() => {
    getData();
    getBarChartData();
  }, []);

  useEffect(() => {
    setTableData({
      ...tableData,
      chartData: getChartData(tableData.data),
    });
  }, [totalFields]);

  const getData = () => {
    RequestHandler.get("/DbsReport/BankVolumeReport").then((response) => {
      setTableData({
        data: response.data,
        chartData: getChartData(response.data),
        loading: false,
      });
    });
  };

  const getChartData = (data) => {
    if (totalFields === undefined) {
      const total = data.reduce((partialSum, x) => partialSum + x.total, 0);
      const pieData = data.filter(x => (x.total / total * 100) >= 5);
      const otherPie = {
        bankName: "Diğer",
        total: data.filter(x => (x.total / total * 100) < 5)
          .reduce((partialSum, x) => partialSum + x.total, 0),
      }

      if (otherPie.total > 0) {
        pieData.push(otherPie);
      }

      return pieData;
    } else if (totalFields.length === 1) {
      const total = data.reduce((partialSum, x) => partialSum + x.xMonthAgoTotal[totalFields[0]], 0);
      const pieData = data.filter(x => (x.xMonthAgoTotal[totalFields[0]] / total * 100) >= 5)
        .map(x => ({ bankName: x.bankName, total: x.xMonthAgoTotal[totalFields[0]] }));
      const otherPie = {
        bankName: "Diğer",
        total: data.filter(x => (x.xMonthAgoTotal[totalFields[0]] / total * 100) < 5)
          .reduce((partialSum, x) => partialSum + x.xMonthAgoTotal[totalFields[0]], 0),
      }

      if (otherPie.total > 0) {
        pieData.push(otherPie);
      }

      return pieData;
    } else {


      const total = data.reduce((partialSum, x) => {
        return partialSum + totalFields.reduce((accumulator, value) => accumulator + x.xMonthAgoTotal[value], 0);
      }, 0);


      const pieData = data.filter(x => (totalFields.reduce((accumulator, value) => accumulator + x.xMonthAgoTotal[value], 0) / total * 100) >= 5)
        .map(x => ({ bankName: x.bankName, total: totalFields.reduce((accumulator, value) => accumulator + x.xMonthAgoTotal[value], 0) }));
      const otherPie = {
        bankName: "Diğer",
        total: data.filter(x => (totalFields.reduce((accumulator, value) => accumulator + x.xMonthAgoTotal[value], 0) / total * 100) < 5)
          .reduce((partialSum, x) => partialSum + totalFields.reduce((accumulator, value) => accumulator + x.xMonthAgoTotal[value], 0), 0),
      }

      if (otherPie.total > 0) {
        pieData.push(otherPie);
      }

      return pieData;
    }
  };

  const getBarChartData = () => {
    RequestHandler.get("/DbsReport/Last12MonthsVolumeReport").then((response) => {
      if (response.success) {

        setBarChartData(response.data.map(x => {
          return {
            date: moment(x.date).format("MMMM YY"),
            total: x.total
          };
        }));
      }
    });
  };

  const renderCurrencyFormat = (data) => (
    <CurrencyFormat
      value={data}
      displayType={"text"}
      decimalSeparator={","}
      thousandSeparator={"."}
      prefix={"₺"}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  )

  const CustomPieToolTip = (toolTipProps) => {
    try {
      const { active, payload, label } = toolTipProps;
      if (!active || !payload) {
        return null;
      }
      return (
        <div>
          <p>
            <strong>{label}</strong>
          </p>
          {payload.map((item, i) => (
            <p key={i}>
              <strong style={{ backgroundColor: "white", opacity: "0.6" }}>
                {item.name}
              </strong>
            </p>
          ))}
        </div>
      );
    } catch (error) { }
  };

  const CustomBarTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: "white", width: "150%", height: "150%" }}>
          <p className="label" style={{ color: "orange", marginTop: "5%", marginLeft: "5%" }} >{`${label}`}</p>
          <p className="label" style={{ marginTop: "5%", marginLeft: "5%" }} >{renderCurrencyFormat(payload[0].value)}</p>
        </div>
      );
    }

    return null;
  };

  const COLORS = [
    "#003668",
    "#0061ba",
    "#1f94ff",
    "#8dbbe5",
    "#8de5cd",
    "#00b684",
    "#006448",
  ];

  const monthPickerOnChange = (value) => {
    if (!value) {
      setTotalFields();
      return;
    }
    const [startDate, endDate] = value;

    const startDiff = moment().startOf("month").diff(startDate.startOf("month"), 'months');
    const endDiff = moment().startOf("month").diff(endDate.startOf("month"), 'months');

    const totalFieldsRange = [];
    for (let index = endDiff; index <= startDiff; index++) {
      totalFieldsRange.push(index);
    }

    setTotalFields(totalFieldsRange);
  }

  const disabledDate = (current) => {
    return current
      && (
        current.startOf("month") < moment().add(-11, "month").startOf("month")
        || current.startOf("month") > moment().startOf("month"));
  }

  const getTotalColumnTitle = () => {
    if (totalFields === undefined) return "Son 12 Ay Toplam";

    return totalFields.length === 1 ? moment().add(-totalFields[0], "month").format("MMMM YY")
      : `${moment().add(-totalFields[0], "month").format("MMMM YY")} - ${moment().add(-totalFields[totalFields.length - 1], "month").format("MMMM YY")}`;
  }

  const renderTotalColumn = (data, row) => {
    if (totalFields === undefined) return renderCurrencyFormat(data);

    const totalValue = totalFields.length === 1 ? row.xMonthAgoTotal[totalFields[0]]
      : totalFields.reduce((accumulator, value) => accumulator + row.xMonthAgoTotal[value], 0);

    return renderCurrencyFormat(totalValue);
  }

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      render: (data) => {
        return (
          <div style={{ width: 100 }}>
            <img src={data}></img>
          </div>
        );
      },
    },
    {
      title: getTotalColumnTitle(),
      dataIndex: "total",
      render: renderTotalColumn,
    },
    {
      title: "Detay",
      dataIndex: "bankId",
      align: "center",
      render: () => (<a
        style={{ fontSize: "130%" }}
        href={"/dbs-report/bank-volume-report-detail"}
      >
        <MenuUnfoldOutlined />
      </a>),
    }
  ];

  return (
    <>

      <Row>
        <Col xs={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> BANKA BAZLI CİRO RAPORU
          </p>
        </Col>
        <Col xs={8} style={{ textAlign: "right" }}>

          <DatePicker.RangePicker
            onChange={monthPickerOnChange}
            disabledDate={disabledDate}
            // placeholder="Son 12 Ay"
            picker="month"
            format="MMMM YYYY"
            allowClear
            inputReadOnly
          />
        </Col>
      </Row>

      <Row>
        <Col md={24} lg={12}>
          <Row id="tableArea">
            <Col span={24}>
              <Table
                columns={columns}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Veri Yok"
                    />
                  ),
                }}
                style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
                rowKey="bankId"
                filterable={false}
                sortable={false}
                pageable={false}
                pagination={false}
                dataSource={tableData.data}
                loading={tableData.loading}
                size="small"
              />
            </Col>
          </Row>
        </Col>
        <Col md={24} lg={12}>
          <Row justify="center">

            <PieChart width={500} height={500}>
              <Pie
                outerRadius={150}
                data={tableData.chartData}
                dataKey="total"
                nameKey="bankName"
                label={({ percent }) => "% " + (percent * 100).toFixed(2)}
              >
                {tableData.chartData.map((entry, index) => (
                  <Cell
                    key={index}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={CustomPieToolTip} />
            </PieChart>

            <Col span={24}>
              {tableData.chartData.map((entry, index) => (
                <div key={`cell-${index}`}>
                  <a style={{ color: COLORS[index % COLORS.length] }}>
                    <CaretRightFilled />
                  </a>
                  {entry.bankName}
                </div>
              ))}
            </Col>
          </Row>

        </Col>
      </Row>

      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <Card title="AY BAZLI TOPLAM" style={{ marginTop: "1%" }} className="gx-card-widget ant-col">
            <ResponsiveContainer width="90%" height={200}>
              <BarChart data={barChartData}
                margin={{ top: 10, right: 0, left: 13, bottom: 0 }}>
                <XAxis dataKey="date" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={CustomBarTooltip} />
                <Legend />
                <Bar dataKey="total" name="Ciro" fill="#87cefa" />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BankVolumeReport;