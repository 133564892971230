import React, { useState, useEffect } from "react";
import { Popover, Badge } from 'antd';
import NotificationContent from './NotificationContent'
import RequestHandler from "util/RequestHandler";

const AppNotification = () => {
  const [notReadCount, setNotReadCount] = useState(0);

  useEffect(() => {
    getNotReadCount();
  }, []);

  const getNotReadCount = () => {
    RequestHandler.get(`/FeatureNotification/NotReadCount`).then((response) => {
      setNotReadCount(response);
    });
  };

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<NotificationContent refreshNotReadBadge={getNotReadCount} />}
      trigger="click">
      <Badge dot={notReadCount > 0}>
        <span className="gx-pointer gx-d-block">
          <i className="icon icon-notification" style={{ verticalAlign: "middle", lineHeight: 0, color: "white", fontSize: "18px" }} />
        </span>
      </Badge>
    </Popover>
  )
};

export default AppNotification;

