import React, { useState, useEffect } from "react";
import RequestHandler from "util/RequestHandler";
import { ColumnHelper } from "util/TableHelper";
import moment from "moment";

import { Table, Empty, Popconfirm, Col, Row, Drawer, Button } from "antd";
import { BarsOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import PosCommissionDefinitionTableFilter from "../Filters/PosCommissionDefinitionTableFilter";
import PosCommissionDefinitionAddForm from "../Forms/PosCommissionDefinitionAddForm";
import PosCommissionDefinitionUpdateForm from "../Forms/PosCommissionDefinitionUpdateForm";

const PosCommissionDefinition = () => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });

  const [addFormVisibility, setAddFormVisibility] = useState(false);
  const [updateFormVisibility, setUpdateFormVisibility] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({});

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    let query = '';
    const { skip, take, filter } = tableDataQueryOptions;

    const conditions = [];
    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length > 0) {
      query = `&$filter=${conditions.join(" and ")}`;
    }

    RequestHandler.get(
      `/PosCommissionDefinition?$count=true${query}&$skip=${skip}&$top=${take}`
    ).then((response) => {
      setTableData({
        data: response.value,
        total: response["@odata.count"],
        loading: false,
      });
    });
  };

  const getUpdateFormData = (id) => {
    RequestHandler.get(`/PosCommissionDefinition/GetById/${id}`).then((response) => {
      if (response) {
        response.validityDate = [moment(response.validityStartDate), moment(response.validityEndDate)];
        setUpdateFormData(response);
        setUpdateFormVisibility(true);
      }
    });
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };

  const handleOnClickDelete = (id) => {
    RequestHandler.delete(`/PosCommissionDefinition/${id}`).then((response) => {
      if (response.success) {
        getTableData();
      }
    });
  };

  const onAddFormSuccess = () => {
    getTableData();
    setAddFormVisibility(false);
  };

  const onUpdateFormSuccess = () => {
    getTableData();
    setUpdateFormVisibility(false);
  };

  const renderCommissionRate = (data) => data == 0 ? "-" : ColumnHelper.renderDecimal(data);

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      key: "bankLogo",
      width: "8%",
      render: (data) => <img src={data}></img>,
    },
    {
      title: "Geçerlilik Başlangıç Tarihi",
      dataIndex: "validityStartDate",
      align: "center",
      render: (data) => moment(data).format("MMMM YYYY"),
    },
    {
      title: "Geçerlilik Bitiş Tarihi",
      dataIndex: "validityEndDate",
      align: "center",
      render: (data) => moment(data).format("MMMM YYYY"),
    },
    {
      title: "Tek Çekim",
      dataIndex: "commissionRate1",
      align: "center",
      render: renderCommissionRate,
    },
    {
      title: "2 Taksit",
      dataIndex: "commissionRate2",
      align: "center",
      render: renderCommissionRate,
    },
    {
      title: "3 Taksit",
      dataIndex: "commissionRate3",
      align: "center",
      render: renderCommissionRate,
    },
    {
      title: "4 Taksit",
      dataIndex: "commissionRate4",
      align: "center",
      render: renderCommissionRate,
    },
    {
      title: "5 Taksit",
      dataIndex: "commissionRate5",
      align: "center",
      render: renderCommissionRate,
    },
    {
      title: "6 Taksit",
      dataIndex: "commissionRate6",
      align: "center",
      render: renderCommissionRate,
    },
    {
      title: "7 Taksit",
      dataIndex: "commissionRate7",
      align: "center",
      render: renderCommissionRate,
    },
    {
      title: "8 Taksit",
      dataIndex: "commissionRate8",
      align: "center",
      render: renderCommissionRate,
    },
    {
      title: "9 Taksit",
      dataIndex: "commissionRate9",
      align: "center",
      render: renderCommissionRate,
    },
    {
      title: "10 Taksit",
      dataIndex: "commissionRate10",
      align: "center",
      render: renderCommissionRate,
    },
    {
      title: "11 Taksit",
      dataIndex: "commissionRate11",
      align: "center",
      render: renderCommissionRate,
    },
    {
      title: "12 Taksit",
      dataIndex: "commissionRate12",
      align: "center",
      render: renderCommissionRate,
    },
    {
      title: "Düzenle",
      dataIndex: "id",
      width: "1%",
      align: "center",
      render: (data) => (
        <a onClick={() => getUpdateFormData(data)}>
          <EditOutlined style={{ color: "orange", fontSize: "130%" }} />
        </a>
      ),
    },
    {
      title: "Sil",
      dataIndex: "id",
      width: "1%",
      align: "center",
      render: (data) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleOnClickDelete(data)}
        >
          <a>
            <DeleteOutlined style={{ color: "red", fontSize: "130%" }} />
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>

      <Row>
        <Col xs={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> POS KOMİSYON TANIMLARI
          </p>
        </Col>
        <Col xs={8}>
          <Row justify="end" align="middle" style={{ paddingRight: 16 }}>

            <Button
              type="primary"
              onClick={() => {
                setAddFormVisibility(true);
              }}
              style={{ marginBottom: 0 }}
            >
              <PlusOutlined /> Tanım Ekle
            </Button>

          </Row>
        </Col>
      </Row>

      <PosCommissionDefinitionTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Drawer
        id="addDrawer"
        title="Pos Komisyon Tanımı Ekle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setAddFormVisibility(false)}
        visible={addFormVisibility}
      >
        <PosCommissionDefinitionAddForm onSuccess={onAddFormSuccess} />
      </Drawer>

      <Drawer
        id="updateDrawer"
        title="Pos Komisyon Tanımı Güncelle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setUpdateFormVisibility(false)}
        visible={updateFormVisibility}
      >
        <PosCommissionDefinitionUpdateForm
          data={updateFormData}
          onSuccess={onUpdateFormSuccess}
        />
      </Drawer>
    </>
  );
}

export default PosCommissionDefinition;
