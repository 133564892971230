import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button } from "antd";
import { SendOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const PaymentRequestSendMailForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [mailLoading, setMailLoading] = useState(false);
  const [defaultMail, setDefaultMail] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setDefaultMail(props.data.eMail);
    }
  }, [props.data]);

  const onFinish = (values) => {
      RequestHandler.post(
        `/PaymentRequest/SendMail`,
        {
          PaymentGuid : props.data.paymentGuid,
          EMail:defaultMail,
          ScreenOption:props.data.screen,
          IsOnList:true
        },
        true
      ).then((response) => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="sendReceipt"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      fields={[
        {name:"email",value:defaultMail}
    ]}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label="E-Posta"
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input value={defaultMail} onChange={(text) => {setDefaultMail(text.target.value)}} disabled={mailLoading} />
          </Form.Item>

        </Col>
        <Col span={24} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            icon={<SendOutlined />}
            htmlType="submit"
            onClick={() => setLoading(true)}
            loading={loading}
          >
            Gönder
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
export default PaymentRequestSendMailForm;
