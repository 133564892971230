import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import config from "util/ApiforContext";
import RequestHandler from "util/RequestHandler";
import { ColumnHelper } from "util/TableHelper";

import TosOrderTableFilter from "../Filters/TosOrderTableFilter";

import moment from "moment";

import { Table, Empty, Col, Row, Button,message } from "antd";
import {
  BarsOutlined,
  FilePdfOutlined,
  InfoCircleOutlined,
  SendOutlined
} from "@ant-design/icons";

const TosPayment = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [selectedRows, setSelectedRows] = useState({
    keys: [],
    data: [],
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "&$filter=DirectiveStatusId eq 1 or DirectiveStatusId eq 7",
  });
  const[loadingSendBank,setLoadingSendBank] = useState();
  const[loadingRepeat,setLoadingRepeat] = useState();
  const history = useHistory();

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  const getTableData = () => {
    const { skip, take, filter } = tableDataQueryOptions;

    setTableData({
      ...tableData,
      loading: true,
    });

     RequestHandler.get(
       `/Directive?$count=true${filter}&$skip=${skip}&$top=${take}`
     ).then((response) => {
          setTableData({
            data: response.value,
            total: response.value.length,
            loading: false,
          });
     });


    setTableData({ data: tableData.data, total: tableData.total , loading: false });
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };
  const onClickCreateOrder = (value) => {
    setLoadingSendBank(true);
      RequestHandler.post(
        "/Directive/SendDirective",
        {
          directiveIds: value
        },
        true
      ).then((res) => {
        if(res.success){
        window.location.reload();
        setLoadingSendBank(false);
        }else{
          setLoadingSendBank(false);
        }
      });
      setTimeout(() => {
      }, 500);
  };
  const onClickCreatePdf = (id) => {
    const hideMessage = message.loading("Pdf indiriliyor...", 0);
    RequestHandler.get(
      `/Directive/CreatePdf?id=${id}`
    ).then((response) => {
      hideMessage();
      window.open(
        config.apiUrl.replace("/api","")+"/content/sourcepdf/tospdf/downloadpdfs/"+response,
        '_blank' // <- This is what makes it open in a new window.
      );
    });
  };
  const onClickRetryDirective = (selectedRows) => {
    setLoadingRepeat(true);
    RequestHandler.post(
      "/Directive/RetryDirective",
      {
        directiveIds: selectedRows
      },
      true
    ).then((res) => {
      window.location.reload();
      setLoadingRepeat(false);
    });
    setTimeout(() => {
      setLoadingRepeat(false)
    }, 1000);
  };
  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "&$filter=DirectiveStatusId eq 1 or DirectiveStatusId eq 7",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };

  const onTableSelectChange = (selectedKeys, selectedRecords) => {
    RequestHandler.post(
      "/Directive/QueueControl",
      {
        directiveIds: selectedKeys
      },
      false
    ).then((res) => {
      if(res.success){
          setSelectedRows({
            keys: selectedKeys,
            data: selectedRecords,
          });
      }
    });
   
  };

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      width: "8%",
      render: (text) => <img src={text}></img>,
    },
    {
      title: "Talimat Numarası",
      dataIndex: "directiveNumber",
    },
    {
      title: "Ödeme Tarihi",
      dataIndex: "paymentDate",
      render: (data, row, index) => moment(data).format("DD.MM.YYYY"),
    },
    {
      title: "Talimat Durumu",
      dataIndex: "directiveStatus",
      render: (data, row, index) => row.directiveStatusId == 7 ? row.tosAuthorizeQueue+". " + data : data,
    },
    {
      title: "Adet",
      dataIndex: "quantity",
    },
    {
      title: "Tutar",
      dataIndex: "amount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Para Birimi",
      dataIndex: "currency",
      render: (data, row, index) => "TRY",
    },
    {
      title: "Açıklama",
      dataIndex: "description",
    },
    {
      title: "Pdf İndir",
      dataIndex: "id",
      align: "center",
      render: (data, row) => (
        <a onClick={() => {onClickCreatePdf(row.id)}} download >
          <FilePdfOutlined style={{ color: "red", fontSize: "130%" }} />
        </a>
      ),
    },
    {
      title: "Detay",
      dataIndex: "id",
      align: "center",
      render: (data, row) => (
        <a style={{ fontSize: "130%" }}>
          <InfoCircleOutlined
            onClick={() => history.push(`tos-sent-payment/${row.id}`)}
          />
        </a>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> TALİMAT LİSTESİ
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
            <Button  disabled={selectedRows.keys.length > 0 ? false : true} loading={loadingRepeat} onClick={() => {onClickRetryDirective(selectedRows.keys)}} style={{ marginBottom: 0 }}>
              <SendOutlined /> Talimatı Tekrarla
            </Button>
            <Button  disabled={selectedRows.keys.length > 0 ? false : true} loading={loadingSendBank}  onClick={() => {onClickCreateOrder(selectedRows.keys)}} style={{ marginBottom: 0 }}>
              <SendOutlined /> Onaya/Bankaya Gönder
            </Button>
        </Col>
      </Row>

      <TosOrderTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            rowSelection={{
              selectedRowKeys: selectedRows.keys,
              onChange: onTableSelectChange,
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};

export default TosPayment;
