import React, { useState, useEffect } from "react";
import Widgets from "../../HomeCards/Card";
import CurrencyFormat from "react-currency-format";
import RequestHandler from "util/RequestHandler";

const DbsNotPaidInvoiceTotal = () => {
  const [widgetData, setWidgetData] = useState({
    loading: true,
  });

  useEffect(() => getData(), []);

  const getData = () => {
    RequestHandler.get("/DbsDashboard/NotPaidInvoiceTotal").then((response) => {
      setWidgetData({
        data: response.data,
        loading: false,
      });
    });
  };

  return (
    <Widgets
      icon="revenue-new"
      currency={"İleri Vadeli"}
      title="Fatura Toplamı"
      totalBalance={
        <CurrencyFormat
          value={widgetData.data}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
          prefix={"₺"}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      }
    />
  );
};

export default DbsNotPaidInvoiceTotal;
