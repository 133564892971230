import React, { useState, useEffect } from "react";
import { Input, Col, Row, Form, Checkbox, notification, Select, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import config from "../../util/ApiforContext"
import axios from 'axios'
const expiration = localStorage.getItem("expiration");
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 11,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const TenantPaymentAccountAddForm = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [bankDropdown, setbankDropdown] = useState([]);
  const [firmDropdown, setfirmDropdown] = useState([]);
  const [bankBranchDropdown, setbankBranchDropdown] = useState([]);
  const [bankId, setbankId] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [iban, setIban] = useState("");
  const [bankBranchName, setbankBranchName] = useState("");
  const errorList = [];

  const errorListNotification = type => {
    errorList.map((item) => {
      notification[type]({
        message: 'Hata',
        description:
          item
        ,
        duration: 2,
      });
    })
  };

  const onChange = (value) => {

    axios.get(`${config.apiUrl}/bankbranch/getbranchforbank?id=${value}`, config.headers)
      .then(res => {
        const bankBranchDropdown = res.data;
        setbankBranchDropdown(bankBranchDropdown)
        setbankId(value)
        setbankBranchName('')
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
      );
  }
  const onChangeIban = (value) => {
    setIban(value.target.value);
  }
  const onChangeAccountNumber = (value) => {
    setAccountNumber(value.target.value);
  }
  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") === null) {
      window.location.reload()
    }
    axios.get(`${config.apiUrl}/bankbranch/getbranchforbank?id=${props.bankId}`, config.headers)
      .then(res => {
        const bankBranchDropdown = res.data;
        setbankBranchDropdown(bankBranchDropdown)
        setbankBranchName('')
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
      );
    axios.get(`${config.apiUrl}/bank`, config.headers)
      .then(res => {
        const bankDropdown = res.data.value.filter(x => x.isSupportAccountTransaction === true);
        setbankDropdown(bankDropdown)
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          }
        }
      );
  }, [setbankDropdown]);

  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'Ekleme işlemi başarı ile gerçekleşti.',
      duration: 2,
    });
  };
  const ibanError = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Lütfen doğru bir Iban girin',
      duration: 2,
    });
  };
  const openNotificationWithIconWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Ekleme işlemi sırasında bir hata oluştu.',
      duration: 2,
    });
  };
  const sameAccount = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Kayıtlı olan hesabı tekrar ekleyemezsiniz.',
      duration: 2,
    });
  };
  const onFinish = values => {
    axios.post(`${config.apiUrl}/TenantPaymentAccount`, {
      BankId: props.bankId,
      Name: values.Name,
      Iban: values.Iban,
      BankBranchId: values.BankBranchId,
      AccountNumber: values.AccountNumber,
      IsDefault: values.IsDefault === null ? false : values.IsDefault
    },
      config.headers)
      .then(res => {
        if (res.status === 200) {
          openNotificationWithIcon('success');
          setTimeout(function () {
            window.location.reload();
          }.bind(this), 2000)
        }
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
            setLoading(false)
          }
          else if (error.response.data.message === "IbanError") {
            ibanError('error')
            setLoading(false)
          } else if (error.response.data.message === "SameAccount") {
            sameAccount('error')
            setLoading(false)
          }
          else {
            openNotificationWithIconWarning('error')
            setLoading(false)
          }

        }
      );
  };
  const onFinishFailed = errorInfo => {
    setLoading(false)
  };
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }
  const { Option } = Select;

  return (
    <div>
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        fields={[
          { name: "BankId", value: props.bankId },
        ]}
      >
        <Row>
          {isDesktopOrLaptop && <>
            <Col span={24}>

              <Form.Item
                label="Hesap Adı"
                name="Name"
                onChange={onChangeAccountNumber}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Banka"
                name="BankId"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen banka alanını boş bırakmayın.',
                  },
                ]}
              >
                <Select
                  showSearch
                  disabled
                  placeholder="Seçiniz."
                  optionFilterProp="children"
                  onChange={onChange}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                  }
                >
                  {bankDropdown.map(item => (
                    item.name === "KASA HESABI" ? "" :
                      <Option key={item.id} >{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="IBAN"
                name="Iban"
                onChange={onChangeIban}
                rules={
                  [
                    {
                      required: true,
                      message: 'Lütfen Iban alanını boş bırakmayın.',
                    },
                  ]}
              >
                <Input type="text" onKeyDown={(evt) => evt.keyCode === 32 && evt.preventDefault()} defaultValue="TR" onFocus={(event) => { event.target.select() }} maxLength="26" />
              </Form.Item>

              <Form.Item
                label="Banka Şubesi"
                name="BankBranchId"
                rules={
                  [
                    {
                      required: true,
                      message: 'Lütfen Banka Şubesi alanını boş bırakmayın.',
                    },
                  ]}
              >
                <Select
                  showSearch
                  placeholder="Seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                  }
                >
                  {bankBranchDropdown.map(item => (
                    <Option key={item.id} >{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Hesap Numarası"
                name="AccountNumber"
                onChange={onChangeAccountNumber}
                rules=
                {
                  [
                    {
                      min: 7,
                      message: 'Hesap numarası en az 7 karakter olmalıdır.'
                    },
                    {
                      required: true,
                      message: "Lütfen Hesap Numarası alanı boş bırakmayınız"
                    }
                  ]}
              >
                <Input maxLength="17" onInput={maxLengthCheck} />
              </Form.Item>


              <Form.Item
                label="Geçerli Hesap"
                name="IsDefault"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>

            </Col>

            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={3}>
              <Form.Item {...tailLayout} >
                <Button type="primary" icon={<PlusOutlined />} htmlType="submit" onClick={() => setLoading(true)} loading={loading}>
                  Ekle
                </Button>
              </Form.Item>
            </Col>
            <Col span={3}></Col>
          </>}
          {isTabletOrMobileDevice && <>
            <Col span={24}>
              <Form.Item
                label="Hesap Adı"
                name="Name"
                onChange={onChangeAccountNumber}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Banka"
                name="BankId"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen banka alanını boş bırakmayın.',
                  },
                ]}
              >
                <Select
                  showSearch
                  disabled
                  placeholder="Seçiniz."
                  optionFilterProp="children"
                  onChange={onChange}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                  }
                >
                  {bankDropdown.map(item => (
                    item.name === "KASA HESABI" ? "" :
                      <Option key={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="IBAN"
                name="Iban"
                onChange={onChangeIban}
                rules={
                  [
                    {
                      required: true,
                      message: 'Lütfen Iban alanını boş bırakmayın.',
                    },
                  ]}
              >
                <Input type="text" defaultValue="TR" maxLength="26" />
              </Form.Item>

              <Form.Item
                label="Banka Şubesi"
                name="BankBranchId"
                rules={
                  [
                    {
                      required: true,
                      message: 'Lütfen Banka Şubesi alanını boş bırakmayın.',
                    },
                  ]}
              >
                <Select
                  showSearch
                  placeholder="Seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                  }
                >
                  {bankBranchDropdown.map(item => (
                    <Option key={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Hesap Numarası"
                name="AccountNumber"
                onChange={onChangeAccountNumber}
                rules=
                {
                  [
                    {
                      min: 7,
                      message: 'Hesap numarası en az 7 karakter olmalıdır.'
                    },
                    {
                      required: true,
                      message: "Lütfen Hesap Numarası alanı boş bırakmayınız"
                    }
                  ]}
              >
                <Input maxLength="17" onInput={maxLengthCheck} />
              </Form.Item>
              <Form.Item
                label="Geçerli Hesap"
                name="IsDefault"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>

            </Col>
            <Col span={12}></Col>

            <Col span={6}>
              <Form.Item {...tailLayout} >
                <Button type="primary" icon={<PlusOutlined />} htmlType="submit" onClick={() => setLoading(true)} loading={loading}>
                  Ekle
                </Button>
              </Form.Item>
            </Col>
            <Col span={6}></Col>
          </>}
        </Row>
      </Form>
    </div>
  )
}
export default TenantPaymentAccountAddForm