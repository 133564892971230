import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Table, Input, Tooltip, Col, Form, Empty, Row, Collapse, notification, Select, DatePicker, Button } from 'antd';
import { SearchOutlined, RollbackOutlined, CloseOutlined, BarsOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive'
import config from "../../util/ApiforContext"
import axios from 'axios';
import moment from 'moment';
import RequestHandler from "util/RequestHandler";
import { RowHelper } from "util/TableHelper";
const expiration = localStorage.getItem("expiration");
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const BankAccountPage = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1500px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const [accounts, setAccounts] = useState([{ data: [], total: 0 }]);
  const [dataState, setDataState] = useState([{ skip: 0, take: 10 }]);
  const [take, setTake] = useState(20);
  const [skip, setSkip] = useState(0);
  const [teminatDropdown, setTeminatDropdown] = useState([]);
  const [bankId, setBankId] = useState(0);
  const [query, setQuery] = useState(`$filter=TenantAccountId eq ${props.match.params.bankaccountid}  and isErpActivity eq false `);
  const [bankFilter, setBankFilter] = useState([]);
  const [getFilterCurrency, setGetFilterCurrency] = useState([]);
  const [Iban, setIban] = useState('');
  const [firmDropdown, setFirmDropdown] = useState([]);
  const [Currency, setCurrency] = useState('');
  const [TenantBankName, setTenantBankName] = useState('');
  const [Description, setDescription] = useState('');
  const [Amount1, setAmount1] = useState('');
  const [Amount2, setAmount2] = useState('');
  const [TransactionDate, setTransactionDate] = useState('');
  const [firmId, setFirmId] = useState('');
  const [TransactionProcessType, setTransactionProcessType] = useState('');
  const [isBranchShow, setIsBranchShow] = useState(true);
  const [isIbanShow, setIsIbanShow] = useState(true);
  const [isCurrencyShow, setIsCurrencyShow] = useState(true);
  const [isTenantIbanShow, setIsTenantIbanShow] = useState(true);
  const [isTransactionTypeShow, setIsTransactionTypeShow] = useState(true);
  const [isVknTcknShow, setIsVknTcknShow] = useState(true);
  const [isReceiptShow, setIsReceiptShow] = useState(true);
  const [isAdditionalDescriptionShow, setIsAdditionalDescriptionShow] = useState(true);
  const [isSenderNameShow, setIsSenderNameShow] = useState(true);
  const [isBalanceAfterTransactionShow, setIsBalanceAfterTransactionShow] = useState(true);
  const [isDescriptionShow, setIsDescriptionShow] = useState(true);
  const [isFirmNameShow, setIsFirmNameShow] = useState(true);
  const [TransactionDate1, setTransactionDate1] = useState('');
  const errorList = [];
  const errorListNotification = type => {
    errorList.map((item) => {
      notification[type]({
        message: 'Hata',
        description:
          item
        ,
        duration: 2,
      });
    })
  };
  const history = useHistory();
  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload()
    }
    RequestHandler.get(`/AccountTransaction/GetTransactionTableRole`).then((response) => {
      if (response.success) {
        setIsAdditionalDescriptionShow(response.data?.isAdditionalDescriptionShow)
        setIsBalanceAfterTransactionShow(response.data?.isBalanceAfterTransactionShow)
        setIsBranchShow(response.data?.isBranchShow)
        setIsCurrencyShow(response.data?.isCurrencyShow)
        setIsDescriptionShow(response.data?.isDescriptionShow)
        setIsFirmNameShow(response.data?.isFirmNameShow)
        setIsIbanShow(response.data?.isIbanShow)
        setIsReceiptShow(response.data?.isReceiptShow)
        setIsSenderNameShow(response.data?.isSenderNameShow)
        setIsTenantIbanShow(response.data?.isTenantIbanShow)
        setIsTransactionTypeShow(response.data?.isTransactionTypeShow)
        setIsVknTcknShow(response.data?.isVknTcknShow)
      }
    });
    axios.get(`${config.apiUrl}/accounttransaction? ${query} &$count=true&$skip=${skip}&$top=${take}`, config.headers)
      .then(res => {
        setAccounts({
          data: res.data.value,
          total: res.data['@odata.count']
        })
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
      );
    axios.get(`${config.apiUrl}/Firm/GetFirmType`, config.headers)
      .then(res => {
        setFirmDropdown(res.data.data)
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
      );
    axios.get(`${config.apiUrl}/Bank/GetFilterBank`, config.headers)
      .then(res => {
        setBankFilter(res.data)
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
      );
    axios.get(`${config.apiUrl}/Types/GetManualTransactionTypes`, config.headers)
      .then(res => {
        setTeminatDropdown(res.data)
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
      );
    axios.get(`${config.apiUrl}/currency/getfiltercurrency`, config.headers)
      .then(res => {
        setGetFilterCurrency(res.data)
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
      );
  }, [setBankFilter, setGetFilterCurrency, skip, query]);
  const goBack = () => {
    history.goBack();
  }
  const handleResets = clearFilters => {
    setSkip(0)
    setTake(20)
    setQuery(`$filter=TenantAccountId eq ${props.match.params.bankaccountid} and isErpActivity eq false `)
  };
  const handleReset = clearFilters => {
    setSkip(0)
    setTake(20)
    setQuery(`$filter=TenantAccountId eq ${props.match.params.bankaccountid} and isErpActivity eq false `)
    setIban('')
    setTransactionProcessType('')
    setCurrency('')
    setTenantBankName('')
    setFirmId('')
    setAmount2('')
    setAmount1('')
    setTransactionDate('')
    setTransactionDate1('')
    setDescription('')
  };
  const handleIban = (value) => {
    setIban(value.target.value)
  };
  const handleCurrency = (value) => {
    setCurrency(value)
  };
  const handleTenantBankId = (value) => {
    setTenantBankName(value)
  };
  const onSearch = (val) => {

    if (val.length >= 3) {
      axios.get(`${config.apiUrl}/Firm?isFilter=true&$filter=contains(name, '${val}')`, config.headers)
        .then(res => {
          setFirmDropdown(res.data.value)
        }).catch(
          error => {
            if (error.response.status === 403 || error.response.status === 401) {
              history.push('/not-authorized-access');
            } else if (error.response.status === 406) {
              error.response.data.data.map((item) => {
                errorList.push(item);
              })
              errorListNotification('error')
            }
          }
        );
    } else {
      setFirmDropdown([])
    }
  }
  const dataStateChange = (e) => {
    setAccounts(e)
  }
  const handleDescription = (value) => {
    setDescription(value.target.value)
  };
  const handleAmount1 = (value) => {
    setAmount1(value.target.value.replace(',', '.'))
  };
  const handleTransactionProcessType = value => {
    setTransactionProcessType(value)
  }
  const handleAmount2 = (value) => {
    setAmount2(value.target.value.replace(',', '.'))
  };
  const handleFirmId = value => {
    setFirmId(value)
  };
  const handleTransactionDate = (value) => {
    if (value !== null) {
      setTransactionDate(value[0].format('YYYY-MM-DD'))
      setTransactionDate1(value[1].format('YYYY-MM-DD'))
    }
  };
  const paging = (p) => {
    setDataState(prevState => {
      prevState.skip = (p.current - 1) * 20;
      setSkip(prevState.skip)
      return {
        prevState
      }
    })
  }


  const { Option } = Select;
  const onFinish = values => {
    {
      var NewTransactionDate = moment(TransactionDate).add(-1, 'days').format('YYYY-MM-DD')
      var NewTransactionDate1 = moment(TransactionDate1).add(1, 'days').format('YYYY-MM-DD')
      setSkip(0)
      setTake(15)
      var queryBankAccount = `$filter=TenantAccountId eq ${props.match.params.bankaccountid} and isErpActivity eq false `;
      if (Iban !== undefined && Iban !== "") {
        queryBankAccount += ` and Contains(Iban,'${Iban}')`;
      }
      if (TenantBankName !== undefined && TenantBankName !== "") {
        queryBankAccount += ` and Contains(TenantBankName,'${TenantBankName}')`;
      }
      if (Description !== undefined && Description !== "") {
        queryBankAccount += ` and Contains(Description,'${Description}')`;
      }
      if (TransactionDate !== undefined && TransactionDate !== "") {
        queryBankAccount += ` and date(TransactionDateValue) lt ${NewTransactionDate1} and date(TransactionDateValue) gt ${NewTransactionDate}`;
      }
      if (queryBankAccount !== "" && Amount1 !== undefined && Amount1 !== "" && (Amount2 === undefined || Amount2 === "")) {
        queryBankAccount += ` and Amount lt ${Amount1}`;
      }
      if (queryBankAccount !== "" && TransactionProcessType !== undefined && TransactionProcessType !== "") {
        queryBankAccount += ` and TransactionProcessTypeValue eq ${TransactionProcessType} `;
      }
      if (queryBankAccount !== "" && firmId !== undefined && firmId !== "") {
        queryBankAccount += ` and FirmId eq ${firmId} `;
      }

      if (TransactionProcessType === undefined || TransactionProcessType === "") {
        if (queryBankAccount !== "" && Amount1 !== undefined && Amount1 !== "" && (Amount2 === undefined || Amount2 === "")) {
          queryBankAccount += ` and (Amount lt ${Amount1} or Amount eq ${Amount1}) and (TransactionProcessTypeValue eq 2 or TransactionProcessTypeValue eq 1)`;
        }
        if (queryBankAccount !== "" && (Amount1 === undefined || Amount1 === "") && Amount2 !== undefined && Amount2 !== "") {
          queryBankAccount += ` and (Amount gt ${Amount2} or Amount eq ${Amount2}) and TransactionProcessTypeValue eq 1`;
        }
      } else {
        if (queryBankAccount !== "" && Amount1 !== undefined && Amount1 !== "" && (Amount2 === undefined || Amount2 === "")) {
          queryBankAccount += ` and (Amount lt ${Amount1} or Amount eq ${Amount1})`;
        }
        if (queryBankAccount !== "" && (Amount1 === undefined || Amount1 === "") && Amount2 !== undefined && Amount2 !== "") {
          queryBankAccount += ` and Amount gt ${Amount2} `;
        }
      }
      if (queryBankAccount !== "" && Amount1 !== undefined && Amount1 !== "" && Amount2 !== undefined && Amount2 !== "") {
        queryBankAccount += ` and (Amount lt ${Amount1} or Amount eq ${Amount1}) and (Amount gt ${Amount2} or Amount eq ${Amount2})`;
      }
      setQuery(queryBankAccount)
      queryBankAccount = `$filter=TenantAccountId eq ${props.match.params.bankaccountid} and isErpActivity eq false `;
    }
  };
  const columns = [
    {
      title: 'BANKA',
      dataIndex: 'bankId',
      key: 'bankId',
      render: (text, row, index) => {
        return <div style={{ width: 84 }}><img src={row.tenantBankLogo} ></img></div>
      },
    },
    isBranchShow ?
      {
        title: 'ŞUBE',
        dataIndex: 'tenantBranchCode',
        key: 'tenantBranchCode',
        align: 'center',
      } : {},
    isTenantIbanShow ?
      {
        title: 'IBAN / HESAP NUMARASI',
        align: 'center',
        dataIndex: 'tenantIban',
        key: 'tenantIban',
        width: '17%',
        render: (text, row, index) => {
          {
            return <div >{row.tenantIban == null ? (row.tenantAccountNumber == null ? "" : row.tenantAccountNumber) : row.tenantIban}</div>
          }
        },
      } : {},
    {
      title: 'TUTAR',
      dataIndex: 'amountString',
      key: 'amountString',
      render: (text, row, index) => {
        {
          return <div style={{ width: 98 }}>{text}</div>
        }
      },
    },
    isCurrencyShow ?
      {
        title: 'DÖVİZ',
        dataIndex: 'currency',
        align: 'center',
        key: 'currency',
        render: (text, row, index) => {
          {
            return <div >{text}</div>
          }
        },
      } : {},
    {
      title: 'TARİH',
      dataIndex: 'transactionDate',
      align: 'center',
      width: '14%',
      key: 'transactionDate',
      render: (text, row, index) => {
        {
          return <div > {row.transactionDate} </div>
        }
      },
    },
    isDescriptionShow ?
      {
        title: 'AÇIKLAMA',
        align: 'left',
        dataIndex: 'description',
        key: 'description',
        render: (text, row, index) =>
          <Tooltip placement="topLeft" title={text}>
            <div style={{ width: 326 }}>
              {text?.substring(0, 40) === undefined ? " " :
                text?.substring(0, 40).trim().length < 35 ? text + "" : text?.substring(0, 40) + "..."}
            </div></Tooltip>
      } : {},
    isFirmNameShow ?
      {
        title: 'CARİ ADI',
        dataIndex: 'firmName',
        align: 'center',
        key: 'firmName',
        render: (text, row, index) => text == null ? <div style={{ opacity: 0.7 }}>-</div> :
          <Tooltip placement="topLeft" title={text}>
            <div style={{ width: 72 }}>
              {text?.substring(0, 15) === undefined ? <div style={{ opacity: 0.7 }}>-</div> :
                text?.substring(0, 15).length < 8 ? text?.substring(0, 15) + "" : text?.substring(0, 8) + "..."}</div>
          </Tooltip>
      } : {},
  ];
  const detailColumns = [
    isIbanShow ?
      {
        title: "Karşı Iban",
        dataIndex: "iban",
      } : {},
    isVknTcknShow ?
      {
        title: "Vkn / T.C. No",
        dataIndex: "vkn",
        render: (data, row, index) => data ?? row.tckn,
      } : {},
    isTransactionTypeShow ?
      {
        title: "İşlem Tipi",
        dataIndex: "transactionTypeName",
      } : {},
    isSenderNameShow ?
      {
        title: "Gönderen Adı",
        dataIndex: "senderName",
      } : {},
    isReceiptShow ?
      {
        title: "Dekont No",
        dataIndex: "receiptNumber",
      } : {},
    isDescriptionShow ?
      {
        title: "Banka Açıklaması",
        dataIndex: "description",
      } : {},
    isAdditionalDescriptionShow ?
      {
        title: "Ek Açıklama",
        dataIndex: "additionalDescription",
      } : {},
  ];
  return (<div>
    <Row style={{ marginBottom: 9 }}>
      <Col flex={1}><a onClick={() => history.goBack()} style={{ color: 'black', marginLeft: "4%" }}> <RollbackOutlined /> GERİ DÖN</a></Col>
      <Col flex={4}></Col>
    </Row>
    <Row>
      <p style={{ fontSize: '120%', marginTop: -9 }}><BarsOutlined /> ŞUBE LİSTESİ - HESAP HAREKETLERİ</p>
    </Row>
    <Collapse defaultActiveKey={['0']}>
      <Panel header="Filtreleme için tıklayınız." key="1">

        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Row>
            {isDesktopOrLaptop && <>
              <Col span={6}> <label>Karşı Iban :</label></Col>
              <Col span={6}> <label>Tutar Aralığı :</label></Col>
              <Col span={6}> <label>Tarih Aralığı :</label></Col>
              <Col span={6}> <label>Açıklama :</label></Col>

              <Col span={6}> <Input name="Iban" value={Iban} onChange={handleIban} style={{ width: "100%" }} placeholder="Iban" /></Col>
              <Col span={6}>
                <Input.Group compact>
                  <Input onChange={handleAmount2} onKeyDown={(evt) => evt.keyCode === 109 && evt.preventDefault()} style={{ width: "50%" }} value={Amount2} name="Amount2" placeholder="Tutar Başlangıç" />
                  <Input onChange={handleAmount1} onKeyDown={(evt) => evt.keyCode === 109 && evt.preventDefault()} style={{ width: "50%" }} value={Amount1} name="Amount1" placeholder="Tutar Bitiş" />
                </Input.Group>
              </Col>

              <Col span={6}>
                <RangePicker format={"DD-MM-YYYY"} name="TransactionDate" placeholder={["Başlangıç", "Bitiş"]} Value={[moment(TransactionDate), moment(TransactionDate1)]} onChange={handleTransactionDate} style={{ width: "100%", marginBottom: "4%" }} />
              </Col>
              <Col span={6}><Input onChange={handleDescription} style={{ width: "100%" }} value={Description} name="Description" placeholder="Açıklama" /></Col>

              <Col span={6}>Ödeme / Tahsilat Tipi :</Col>
              <Col span={6}> <label>Fiyat :</label></Col>
              <Col span={6}> <label></label></Col>
              <Col span={6}></Col>

              <Col span={6}>
                <Select
                  style={{ width: "100%", marginBottom: "4%" }}
                  value={TransactionProcessType === "" ? null : TransactionProcessType}
                  name="TransactionProcessType"
                  placeholder="Ödeme/Tahsilat tipi seçiniz"
                  onChange={handleTransactionProcessType}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                  }
                >
                  {teminatDropdown.map(item => (
                    <Option key={item.value == "Ödeme" ? 2 : 1}>{item.value}</Option>
                  ))}
                </Select>
              </Col>
              <Col span={6}>
                <Select
                  showSearch
                  name="FirmId"
                  onChange={handleFirmId}
                  value={firmId === '' ? undefined : firmId}
                  style={{ width: '100%', marginBottom: "3%" }}
                  placeholder="En az 3 harf giriniz.."
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                  }
                >
                  {firmDropdown.map(item => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Col>
              <Col span={6}></Col>
              <Col span={6}></Col>

              <Col span={6}></Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
              <Col flex={5} style={{ opacity: 0.1 }}>.</Col>
              <Col flex={0.7}><Button danger size="small" icon={<CloseOutlined />} onClick={() => handleReset()}>Sıfırla</Button></Col>
              <Col flex={0}>
                <Button primary size="small" icon={<SearchOutlined />} style={{ color: "#4d98aa", borderColor: "#4d98aa" }} htmlType="submit">Filtrele</Button>
              </Col>
            </>}
            {isTabletOrMobileDevice && <>
              <Col span={12}> <label>Karşı Iban :</label></Col>
              <Col span={12}> <label>Tutar Aralığı :</label></Col>
              <Col span={12}> <Input name="Iban" value={Iban} onChange={handleIban} style={{ width: "100%" }} placeholder="Iban" /></Col>
              <Col span={12}>
                <Input.Group compact>
                  <Input onChange={handleAmount2} style={{ width: "50%" }} value={Amount2} name="Amount2" placeholder="Tutar Başlangıç" />
                  <Input onChange={handleAmount1} style={{ width: "50%" }} value={Amount1} name="Amount1" placeholder="Tutar Bitiş" />
                </Input.Group>
              </Col>
              <Col span={12}> <label>Tarih Aralığı :</label></Col>
              <Col span={12}> <label>Açıklama :</label></Col>




              <Col span={12}>
                <RangePicker format={"DD-MM-YYYY"} name="TransactionDate" placeholder={["Başlangıç", "Bitiş"]} Value={[moment(TransactionDate), moment(TransactionDate1)]} onChange={handleTransactionDate} style={{ width: "100%", marginBottom: "4%" }} />
              </Col>
              <Col span={12}><Input onChange={handleDescription} style={{ width: "100%", marginBottom: "6%" }} value={Description} name="Description" placeholder="Açıklama" /></Col>
              <Col span={12}></Col>
              <Col span={12}>Cari :</Col>
              <Col span={12}></Col>
              <Col span={12}><Select
                showSearch
                name="FirmId"
                onChange={handleFirmId}
                value={firmId === '' ? undefined : firmId}
                style={{ width: '100%', marginBottom: "3%" }}
                placeholder="En az 3 harf giriniz.."
                optionFilterProp="children"
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.props.children
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
              >
                {firmDropdown.map(item => (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                ))}
              </Select></Col>

              <Col span={12}>
                <Button danger size="small" style={{ width: "100%" }} icon={<CloseOutlined />} onClick={() => handleReset()}>Sıfırla</Button>
              </Col>
              <Col span={12}>
                <Button primary size="small" icon={<SearchOutlined />} style={{ color: "#4d98aa", borderColor: "#4d98aa", width: "100%" }} htmlType="submit">Filtrele</Button>
              </Col>
            </>}



          </Row>
        </Form>
      </Panel>
    </Collapse><br></br>
    {isTabletOrMobile === true ?
      <Table columns={columns}
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" /> }}
        style={{ maxHeight: "100%" }}
        scroll={{ x: 1150 }}
        size="small"
        rowKey="id"
        filterable={true}
        expandable={{
          expandedRowRender: (row) =>
            RowHelper.renderRowDetail({
              data: row,
              columns: detailColumns,
            }),
        }}
        sortable={true}
        pagination={{
          total: accounts.total,
          pageSize: [15]
        }}
        pageable={true}
        onChange={paging}
        dataSource={accounts.data}
      />
      : <Table columns={columns}
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" /> }}
        style={{ overflowX: "auto", maxHeight: "100%" }}
        size="small"
        rowKey="id"
        filterable={true}
        expandable={{
          expandedRowRender: record => <p><b>Karşı Iban : </b> {record.iban}<br />
            <b>VKN / T.C. No : </b> {record.tckn == null ? record.vkn : record.tckn}<br />
            <b>Banka Açıklaması : </b> {record.description}<br /></p>,
        }}
        sortable={true}
        pagination={{
          total: accounts.total,
          pageSize: [15]
        }}
        pageable={true}
        onChange={paging}
        dataSource={accounts.data}
      />}

  </div>)
}

export default BankAccountPage
