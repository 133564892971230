import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Select,
  DatePicker,
  InputNumber,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";
import moment from "moment";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const TosPaymentUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [bankDropdown, setbankDropdown] = useState([]);
  const [bankBranchDropdown, setbankBranchDropdown] = useState([]);

  const [bankId, setbankId] = useState("");
  const [bankBranchName, setbankBranchName] = useState("");
  const [iban, setIban] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: true,
  });
  const [currencySelect, setCurrencySelect] = useState({
    data: [],
    loading: true,
  });
  const [form] = Form.useForm();

  useEffect(() => {
    getCurrencies();
  }, []);

  useEffect(() => {
    if (props.data) {
      props.data.paymentDate = moment(props.data.paymentDate);
      form.setFieldsValue(props.data);
      setIban(props.data.iban)
      setAccountNumber(props.data.accountNumber)
      RequestHandler.get(`/bankbranch/getbranchforbank?id=${props.data.bankId}`).then((response) => {
        setbankBranchDropdown(response)
        setbankBranchName('')
      });  
    }
  }, [props.data]);
  const getPayments = () =>{
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    
  }
  const getCurrencies = () => {
    setCurrencySelect({
      ...bankSelect,
      loading: true,
    });
     RequestHandler.get(`/Currency?$select=id,code`).then((response) => {
       setCurrencySelect({
         data: response.value,
         loading: false,
       });
     });
    RequestHandler.get(`/bank`).then((response) => {
      const bankDropdown = response.value.filter(x=> x.isEmoney !== true);
      setbankDropdown(bankDropdown)
    });
  };
  const onChangeIban = (value) => {
    setIban(value.target.value);
}
const onChangeAccountNumber = (value) => {
  setAccountNumber(value.target.value);
}
  const onFinish = (values) => {
    values.statusId=1;
     RequestHandler.put(`/Payment`, values).then((response) => {
       setLoading(false);
       if (response.success) {
         getPayments();
         form.resetFields();
         if (props.onSuccess) props.onSuccess();
       }
     });
  };
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
     object.target.value = object.target.value.slice(0, object.target.maxLength)
      }
    }
  const onChange = (value) => {
    setbankBranchDropdown([])
    RequestHandler.get(`/bankbranch/getbranchforbank?id=${value}`).then((response) => {
      setbankBranchDropdown(response)
      setbankId(value)
      setbankBranchName('')
    });  
  }
  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };
  const { Option } = Select;

  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="tosPaymentUpdate"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label="Ad Soyad / Ünvan"
            name="title"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
              label="Banka"
              name="bankId"
              rules={[
                {
                  required: true,
                  message: 'Lütfen banka alanını boş bırakmayın.',
                },
                  ]}
            >
            <Select
              showSearch
              placeholder="Seçiniz."
              optionFilterProp="children"
              onChange={onChange}
              filterOption={(input, option) =>
              option.props.children
              .toLocaleLowerCase("tr-TR")
              .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              >
              {bankDropdown.map(item => (
              item.name === "KASA HESABI" ? "" :
              <Option key={item.id} value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
                        label="IBAN"
                        name="iban"
                        onChange={onChangeIban}
                        rules={
                          [
                      {
                          required: true,
                          message: 'Lütfen Iban alanını boş bırakmayın.',
                      },
                      ]}
                    >
                        <Input type="text" defaultValue="TR" maxLength="26" />
          </Form.Item>

          <Form.Item label="Tutar" name="amount" rules={[{ required: true }]}>
            <InputNumber min={0} style={{width:'100%'}} parser={value => value.replace(',', '.')} />
          </Form.Item>

          <Form.Item
            name="currencyId"
            label="Para Birimi"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              disabled
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              loading={currencySelect.loading}
            >
              {currencySelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Ödeme Tarihi"
            name="paymentDate"
            rules={[{ required: true }]}
          >
            <DatePicker format="DD.MM.YYYY" />
          </Form.Item>

          <Form.Item
            label="Açıklama"
            name="description"
          >
            <Input.TextArea rows={3} />
          </Form.Item>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Güncelle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default TosPaymentUpdateForm;
