import React from "react";
import Widget from "./HomeCards";
import {connect} from "react-redux";
import {THEME_TYPE_DARK} from "../../../constants/ThemeSetting";
import { useMediaQuery } from 'react-responsive'


const IconWithTextCard = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1499px)' })
  const {icon, title, subTitle,descText,color} = props;

  let {iconColor} = props;  
  if (props.themeType === THEME_TYPE_DARK) {
    iconColor = "white";
  }
  return (
    isTabletOrMobile === true ? 
    <Widget>
      <div className="gx-media gx-align-items-center gx-flex-nowrap">
        <div className="gx-mr-lg-4 gx-mr-3">
          <i className={`icon icon-${icon} gx-fs-xlxl gx-text-${iconColor} gx-d-flex`}
             style={{fontSize: 40, color:`${color}`}}/>
        </div>
        <div className="gx-media-body">
          <p className="gx-text-grey gx-mb-0" style={{fontSize:"80%"}}>{descText}</p>

          <h3 className="gx-font-weight-medium gx-mb-1"style={{width:"140%",fontSize:"99%"}}>{title}</h3>
          <p className="gx-text-grey gx-mb-0">{subTitle}</p>
        </div>
      </div>
    </Widget>
    :
    <Widget>
      <div className="gx-media gx-align-items-center gx-flex-nowrap" style={{marginTop:12}}>
        <div className="gx-mr-lg-4 gx-mr-3">
          <i className={`icon icon-${icon} gx-fs-xlxl gx-text-${iconColor} gx-d-flex`}
             style={{fontSize: 40, color:`${color}`}}/>
        </div>
        <div className="gx-media-body">
          <p className="gx-text-grey gx-mb-0" style={{fontSize:"80%"}}>{descText}</p>

          <h3 className="gx-font-weight-medium gx-mb-1" style={{fontSize:20}}>{title}</h3>
          <p className="gx-text-grey gx-mb-0">{subTitle}</p>
        </div>
      </div>
    </Widget>
  );
};

const mapStateToProps = ({settings}) => {
  const {themeType} = settings;
  return {themeType}
};
export default connect(mapStateToProps, null)(IconWithTextCard);
