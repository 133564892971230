import React,{useState,useEffect} from "react";
import { useMediaQuery } from 'react-responsive'
import {Card} from "antd";
import PropTypes from "prop-types";

const Widget = ({title, children, styleName, cover, extra, actions}) => {
const [styleValue, setStyleValue] = useState('');
const [classValue, setClassValue] = useState('');
  
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  return (
    isBigScreen ? 
    <Card
        style={{marginLeft: 125,width:300,marginBottom:10}}
        className={`gx-card-widget ${styleName} col gx-col-full col-md-12 col-sm-12 col-xs-12 col-xl-12`}>
      {children}
    </Card> :
    (
      isDesktopOrLaptop === true ? 
    <Card
        style={{marginLeft: '3%',width:300,marginBottom:10}}
        className={`gx-card-widget ${styleName} col gx-col-full col-md-12 col-sm-12 col-xs-12 col-xl-12`}>
      {children}
    </Card>
    :
    <Card
    style={{width:"90%", marginLeft:"4%", marginTop:"1%"}}
    className={`gx-card-widget ant-col `}>
      {children}
    </Card>
    )
  )
};

export default Widget;
Widget.defaultProps = {
  styleName: '',
};

Widget.propTypes = {
  title: PropTypes.node,
  extra: PropTypes.node,
  cover: PropTypes.node,
  actions: PropTypes.node,
  children: PropTypes.node.isRequired
};
