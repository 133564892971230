import React, { useState, useEffect } from "react";
import {
  Form,
  Col,
  Row,
  Button,
  Select,
  Collapse,
  DatePicker,
  Typography,
} from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";

const DbsValueReportTableFilter = (props) => {
  const [form] = Form.useForm();

  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: false,
  });

  useEffect(() => {
    getBanks();
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const getBanks = () => {
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    RequestHandler.get(`/DbsBankInfo/AddedBanks?$select=id,name`).then(
      (response) => {
        setBankSelect({
          data: response,
          loading: false,
        });
      }
    );
  };

  const onFinish = (values) => {
    {
      let query = {};

      if (values.bankId) {
        query.bankId = values.bankId;
      }

      if (values.dateRange) {
        [query.startDate, query.endDate] = values.dateRange;
      }

      if (props.onFinishFilter) props.onFinishFilter(query);
    }
  };

  const clearFilter = () => {
    form.resetFields();

    if (props.onClearFilter) {
      props.onClearFilter();
    }
  };

  return (
    <Row id="filterArea" style={{ ...props.style }}>
      <Col span={24}>
        <Collapse>
          <Collapse.Panel
            showArrow={false}
            header="Filtreleme için tıklayınız."
            extra={
              props.extraText ? (
                <Typography>
                  <Typography.Paragraph
                    style={{
                      marginBottom: 0,
                      fontSize: "80%",
                    }}
                    type="secondary"
                  >
                    {props.extraText}
                  </Typography.Paragraph>
                </Typography>
              ) : null
            }
          >
            <Form
              name="valueReportFilter"
              form={form}
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row style={{ flexDirection: "row" }}>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="bankId" label="Banka :">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Tümü"
                      loading={bankSelect.loading}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {bankSelect.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="dateRange" label="Vade Tarihi :">
                    <DatePicker.RangePicker
                      picker={props.monthly ? "month" : "date"}
                      format={props.monthly ? "MMMM YYYY" : "DD.MM.YYYY"}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ flexDirection: "row" }}>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    danger
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={() => clearFilter()}
                  >
                    Sıfırla
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    icon={<SearchOutlined />}
                    style={{ color: "#40a9ff", borderColor: "#40a9ff" }}
                    htmlType="submit"
                  >
                    Filtrele
                  </Button>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};
export default DbsValueReportTableFilter;
