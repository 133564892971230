import React, { useState, useEffect } from "react";
import { Table, Empty, Col, Row, message, Tooltip } from "antd";
import {
  BarsOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import ReplaceDate from "../../util/Extensions/DateReplaceExtension";
import { useMediaQuery } from "react-responsive";
import FirmStatusReportTableFilter from "../Filters/FirmStatusReportTableFilter";
import RequestHandler from "../../util/RequestHandler";
import { ColumnHelper } from "../../util/TableHelper";
import config from "../../util/ApiforContext";

const FirmStatusReport = () => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({});
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const history = useHistory();

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    RequestHandler.post(`/Reports`, tableDataQueryOptions, false).then(
      (response) => {
        setTableData({
          data: response.value,
          total: response.value.length,
          loading: false,
        });
      }
    );
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({});
  };

  const onFinishTableFilter = (query) => {
    console.log(query);
    setTableDataQueryOptions(query);
  };

  const getDecimalStringValue = (value) => (value ? value : 0);

  const goReportDetailAccountPage = (value) => {
    RequestHandler.get(`/Firm?$filter=name eq '${value}'`).then((response) => {
      response.value.map((item) => {
        if (item.name === value) {
          !tableDataQueryOptions.date
            ? history.push(
                `report-firm-account-transaction/${item.id}/null/null/${
                  tableDataQueryOptions.currencyCode
                    ? tableDataQueryOptions.currencyCode
                    : null
                }`
              )
            : history.push(
                `report-firm-account-transaction/${
                  item.id
                }/${tableDataQueryOptions.date
                  .replace("/", "-")
                  .replace("/", "-")}/${tableDataQueryOptions.endDate
                  .replace("/", "-")
                  .replace("/", "-")}/${
                  tableDataQueryOptions.currencyCode
                    ? tableDataQueryOptions.currencyCode
                    : null
                }`
              );
        }
      });
    });
  };

  const GoReportMonthlyPaymentPage = (value) => {
    RequestHandler.get(`/Firm?$filter=name eq '${value}'`).then((response) => {
      response.value.map((item) => {
        if (item.name === value) {
          !tableDataQueryOptions.date
            ? history.push(
                `report-firm-monthly-payment/${item.id}/null/null/${
                  tableDataQueryOptions.currencyCode
                    ? tableDataQueryOptions.currencyCode
                    : null
                }`
              )
            : history.push(
                `report-firm-monthly-payment/${
                  item.id
                }/${tableDataQueryOptions.date
                  .replace("/", "-")
                  .replace("/", "-")}/${tableDataQueryOptions.endDate
                  .replace("/", "-")
                  .replace("/", "-")}/${
                  tableDataQueryOptions.currencyCode
                    ? tableDataQueryOptions.currencyCode
                    : null
                }`
              );
        }
      });
    });
  };

  const goReportDetailCollectChart = () => {
    const date = ReplaceDate(
      tableDataQueryOptions.date ? tableDataQueryOptions.date : null
    );
    const endDate = ReplaceDate(
      tableDataQueryOptions.endDate ? tableDataQueryOptions.endDate : null
    );
    let { currencyCode, firmId } = tableDataQueryOptions;
    const {
      collectTotal,
      collectTotal2,
      collectPlanTotal,
      collectPlanTotal2,
      paymentPlanTotal,
      paymentPlanTotal2,
      paymentTotal,
      paymentTotal2,
    } = tableDataQueryOptions;

    currencyCode = currencyCode ? currencyCode : null;
    firmId = firmId ? firmId : null;

    history.push(
      `firm-status-report-chart-collect/${date}/${endDate}/${currencyCode}/${firmId}/${getDecimalStringValue(
        collectTotal
      )}/${getDecimalStringValue(collectTotal2)}/${getDecimalStringValue(
        collectPlanTotal
      )}/${getDecimalStringValue(collectPlanTotal2)}/${getDecimalStringValue(
        paymentPlanTotal
      )}/${getDecimalStringValue(paymentPlanTotal2)}/${getDecimalStringValue(
        paymentTotal
      )}/${getDecimalStringValue(paymentTotal2)}`
    );
  };

  const goReportDetailPaymentChart = () => {
    const date = ReplaceDate(
      tableDataQueryOptions.date ? tableDataQueryOptions.date : null
    );
    const endDate = ReplaceDate(
      tableDataQueryOptions.endDate ? tableDataQueryOptions.endDate : null
    );
    let { currencyCode, firmId } = tableDataQueryOptions;
    const {
      collectTotal,
      collectTotal2,
      collectPlanTotal,
      collectPlanTotal2,
      paymentPlanTotal,
      paymentPlanTotal2,
      paymentTotal,
      paymentTotal2,
    } = tableDataQueryOptions;

    currencyCode = currencyCode ? currencyCode : null;
    firmId = firmId ? firmId : null;

    history.push(
      `firm-status-report-chart-payment/${date}/${endDate}/${currencyCode}/${firmId}/${getDecimalStringValue(
        collectTotal
      )}/${getDecimalStringValue(collectTotal2)}/${getDecimalStringValue(
        collectPlanTotal
      )}/${getDecimalStringValue(collectPlanTotal2)}/${getDecimalStringValue(
        paymentPlanTotal
      )}/${getDecimalStringValue(paymentPlanTotal2)}/${getDecimalStringValue(
        paymentTotal
      )}/${getDecimalStringValue(paymentTotal2)}`
    );
  };

  const renderDecimal = (value) =>
    value !== null ? ColumnHelper.renderDecimal(value) : null;

  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);
    RequestHandler.post(
      `/Reports/GetCollectAndPaymentPlanExcel`,
      {
        FirmId: tableDataQueryOptions.firmId
          ? tableDataQueryOptions.firmId
          : null,
        CollectTotal: tableDataQueryOptions.collectTotal
          ? tableDataQueryOptions.collectTotal
          : 0,
        CollectTotal2: tableDataQueryOptions.collectTotal2
          ? tableDataQueryOptions.collectTotal2
          : 0,
        CollectPlanTotal: tableDataQueryOptions.collectPlanTotal
          ? tableDataQueryOptions.collectPlanTotal
          : 0,
        CollectPlanTotal2: tableDataQueryOptions.collectPlanTotal2
          ? tableDataQueryOptions.collectPlanTotal2
          : 0,
        PaymentPlanTotal: tableDataQueryOptions.paymentPlanTotal
          ? tableDataQueryOptions.paymentPlanTotal
          : 0,
        PaymentPlanTotal2: tableDataQueryOptions.paymentPlanTotal2
          ? tableDataQueryOptions.paymentPlanTotal2
          : 0,
        PaymentTotal: tableDataQueryOptions.paymentTotal
          ? tableDataQueryOptions.paymentTotal
          : 0,
        PaymentTotal2: tableDataQueryOptions.paymentTotal2
          ? tableDataQueryOptions.paymentTotal2
          : 0,
        CurrencyCode: tableDataQueryOptions.currencyCode
          ? tableDataQueryOptions.currencyCode
          : null,
        Date: tableDataQueryOptions.date ? tableDataQueryOptions.date : null,
        EndDate: tableDataQueryOptions.endDate
          ? tableDataQueryOptions.endDate
          : null,
      },
      false
    ).then((response) => {
      hideMessage();
      if (response) {
        window.open(
          `${
            config.apiUrl
          }/Reports/DownloadExcel?fileName=${response}&t=${RequestHandler.getToken()}`
        );
      }
    });
  };
  const columns = [
    {
      title: "Cari",
      dataIndex: "firmId",
      key: "firmId",
      align: "center",
      width: "10%",
    },
    {
      title: "Tahsilat Planı",
      dataIndex: "collectPlanTotal",
      key: "collectPlanTotal",
      align: "center",
      width: "10%",
      render: renderDecimal,
    },
    {
      title: "Gerçekleşen Tahsilat",
      dataIndex: "collectTotal",
      key: "collectTotal",
      align: "center",
      width: "10%",
      render: renderDecimal,
    },
    {
      title: "Kalan Tahsilat",
      dataIndex: "remainingCollect",
      key: "remainingCollect",
      align: "center",
      width: "10%",
      render: renderDecimal,
    },
    {
      title: "Ödeme Planı",
      dataIndex: "paymentPlanTotal",
      key: "paymentPlanTotal",
      align: "center",
      width: "10%",
      render: renderDecimal,
    },
    {
      title: "Gerçekleşen Ödeme",
      dataIndex: "paymentTotal",
      key: "paymentTotal",
      align: "center",
      width: "10%",
      render: renderDecimal,
    },
    {
      title: "Kalan Ödeme",
      dataIndex: "remainingPayment",
      key: "remainingPayment",
      align: "center",
      width: "10%",
      render: renderDecimal,
    },
    {
      title: "Tahsilat Detay",
      dataIndex: "operation",
      width: "1%",
      align: "right",
      align: "center",
      render: (text, record) =>
        tableData.data.length >= 1 ? (
          <a
            onClick={() => {
              goReportDetailAccountPage(record.firmId);
            }}
            style={{ fontSize: "130%" }}
          >
            <MenuUnfoldOutlined />
          </a>
        ) : null,
    },
    {
      title: "Ödeme Detay",
      dataIndex: "operation",
      width: "1%",
      align: "right",
      align: "center",
      render: (text, record) =>
        tableData.data.length >= 1 ? (
          <a
            onClick={() => {
              GoReportMonthlyPaymentPage(record.firmId);
            }}
            style={{ fontSize: "130%" }}
          >
            <MenuUnfoldOutlined style={{ color: "green" }} />
          </a>
        ) : null,
    },
  ];
  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> CARİ DURUM RAPORU
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
          <Tooltip title="Excel İndir">
            <a
              style={{
                color: "green",
                fontSize: "180%",
                marginRight: 30,
              }}
              onClick={onClickExportExcel}
            >
              <FileExcelOutlined />
            </a>
          </Tooltip>
        </Col>
      </Row>

      <FirmStatusReportTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        extraText="Varsayılan olarak yılın ilk gününden itibaren bugüne kadar
        TRY cinsinden veri listelenmektedir."
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row>
        {isDesktopOrLaptop && (
          <>
            <Col flex={2}></Col>
            <Col flex={"0 250px"} style={{ marginBottom: "1%" }}>
              <a
                onClick={() => goReportDetailCollectChart()}
                style={{ fontSize: "110%", color: "black" }}
              >
                <PieChartOutlined /> Gerçekleşen Tahsilat Grafik |
              </a>
            </Col>
            <Col flex={0} style={{ marginBottom: "1%" }}>
              <a
                onClick={() => goReportDetailPaymentChart()}
                style={{ fontSize: "110%", color: "black" }}
              >
                <PieChartOutlined /> Gerçekleşen Ödeme Grafik
              </a>
            </Col>
          </>
        )}

        {isTabletOrMobileDevice && (
          <>
            <Col span={12} style={{ marginBottom: "1%" }}>
              <a
                onClick={() => goReportDetailCollectChart()}
                style={{ color: "black" }}
              >
                <PieChartOutlined /> Gerçekleşen Tahsilat Grafik
              </a>
            </Col>
            <Col span={12} style={{ marginBottom: "1%" }}>
              <a
                onClick={() => goReportDetailPaymentChart()}
                style={{ color: "black" }}
              >
                <PieChartOutlined /> Gerçekleşen Ödeme Grafik
              </a>
            </Col>
          </>
        )}
      </Row>

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey={(_, index) => index}
            filterable={true}
            sortable={true}
            pageable={true}
            pagination={{
              defaultPageSize: tableDataQueryOptions.pageSize,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};

export default FirmStatusReport;
