import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, DatePicker, InputNumber } from "antd";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";
import moment from "moment";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const DbsSentInvoiceUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [amountHidden, setAmountHidden] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.data) {
      //Tutar güncelletmeyen bankalar
      const amountHiddenBankIds = [135];
      setAmountHidden(amountHiddenBankIds.indexOf(props.data.bankId) !== -1);

      props.data.invoiceDate = moment(props.data.invoiceDate);
      props.data.dueDate = moment(props.data.dueDate);

      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onFinish = (values) => {
    values.dueDate = values.dueDate.format("YYYY-MM-DD");

    RequestHandler.post(`/DbsBank/Update`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        form.resetFields();
        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="dbsSentInvoiceUpdate"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label="Vade Tarihi"
            name="dueDate"
            rules={[{ required: true }]}
          >
            <DatePicker format="DD.MM.YYYY" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Fatura Tutarı"
            name="amount"
            rules={[{ required: true }]}
            hidden={amountHidden}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item label="Açıklama" name="description">
            <Input.TextArea rows={3} maxLength={300} />
          </Form.Item>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Güncelle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default DbsSentInvoiceUpdateForm;
