import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table, Empty, Drawer, Popconfirm, Button, Row, Col } from "antd";
import TransactionBankInfoAccountAddForm from "../Forms/TransactionBankInfoAccountAddForm";
import TransactionBankInfoAccountUpdateForm from "../Forms/TransactionBankInfoAccountUpdateForm";

import {
  BankOutlined,
  DeleteOutlined,
  RollbackOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import RequestHandler from "../../util/RequestHandler";

const TransactionBankInfoAccountList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
  });

  const [addFormVisibility, setAddFormVisibility] = useState(false);
  const [updateFormVisibility, setUpdateFormVisibility] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({});

  const history = useHistory();

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    const { skip, take } = tableDataQueryOptions;
    const { bankInfoId } = props.match.params;

    RequestHandler.get(
      `/TransactionBankInfoAccount?$count=true&$skip=${skip}&$top=${take}&$filter=transactionBankInfoId eq ${bankInfoId}`
    ).then((response) => {
      setTableData({
        data: response.value,
        total: response["@odata.count"],
        loading: false,
      });
    });
  };

  const getUpdateFormData = (id) => {
    RequestHandler.get(`/TransactionBankInfoAccount/GetById/${id}`).then(
      (response) => {
        if (response) {
          setUpdateFormData(response);
          setUpdateFormVisibility(true);
        }
      }
    );

    setUpdateFormVisibility(true);
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const handleOnClickDelete = (id) => {
    RequestHandler.delete(`/TransactionBankInfoAccount?id=${id}`).then(
      (response) => {
        if (response.success) {
          getTableData();
        }
      }
    );
  };

  const onAddFormSuccess = () => {
    getTableData();
    setAddFormVisibility(false);
  };

  const onUpdateFormSuccess = () => {
    getTableData();
    setUpdateFormVisibility(false);
  };

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      key: "bankLogo",
      render: (text, row, index) => {
        return (
          <div style={{ width: 100 }}>
            {" "}
            <img src={row.bankLogo}></img>
          </div>
        );
      },
    },
    {
      title: "Hesap Numarası",
      dataIndex: "accountNumber",
      render: (text, record) => (
        <div>
          {record.accountSuffix === null
            ? record.accountNumber
            : record.accountNumber + " - " + record.accountSuffix}
        </div>
      ),
    },
    {
      title: "Iban",
      dataIndex: "iban",
    },
    {
      title: "Para Birimi",
      dataIndex: "currencyName",
    },
    {
      title: "Düzenle",
      dataIndex: "id",
      width: "1%",
      align: "center",
      render: (data, row) => (
        <a onClick={() => getUpdateFormData(data)}>
          <EditOutlined style={{ color: "orange", fontSize: "130%" }} />
        </a>
      ),
    },
    {
      title: "Sil",
      dataIndex: "id",
      width: "1%",
      align: "center",
      render: (data, row) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleOnClickDelete(data)}
        >
          <a>
            <DeleteOutlined style={{ color: "red", fontSize: "130%" }} />
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Row style={{ marginBottom: 9 }}>
        <Col flex={1}>
          <a
            onClick={() => history.goBack()}
            style={{ color: "black", marginLeft: "4%" }}
          >
            {" "}
            <RollbackOutlined /> GERİ DÖN
          </a>
        </Col>
        <Col flex={4}></Col>
      </Row>

      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BankOutlined /> BANKA HESAP TANIMLARI
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={() => setAddFormVisibility(true)}>
            <PlusOutlined /> Banka Hesabı Ekle
          </Button>
        </Col>
      </Row>

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Drawer
        id="addDrawer"
        title="Banka Hesabı Ekle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setAddFormVisibility(false)}
        visible={addFormVisibility}
      >
        <TransactionBankInfoAccountAddForm
          data={{
            bankId: props.match.params.bankId,
            transactionBankInfoId: props.match.params.bankInfoId,
          }}
          onSuccess={onAddFormSuccess}
        />
      </Drawer>

      <Drawer
        id="updateDrawer"
        title="Banka Hesabı Güncelle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setUpdateFormVisibility(false)}
        visible={updateFormVisibility}
      >
        <TransactionBankInfoAccountUpdateForm
          data={updateFormData}
          onSuccess={onUpdateFormSuccess}
          bankId={props.match.params.bankId}
        />
      </Drawer>
    </>
  );
};
export default TransactionBankInfoAccountList;
