import React, {useEffect,useState} from "react";
import {Checkbox,notification} from "antd";
import { useHistory } from 'react-router-dom';
import config from "../../../../util/ApiforContext";
import axios from 'axios';
const token = localStorage.getItem("token");
const ContactCell = (props) => {
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'Hesap ekleme işlemi başarılı!',
        duration: 2,
    });
};
const openNotificationWithIconWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Hesap ekleme işlemi sırasında bir hata oluştu.',
        duration: 2,
    });
};
  const onContactSelect = (value) => {
      axios.post(`${config.apiUrl}/Notification/AddNotificationAccount`,
           { TenantAccountId:value,
             TenantId:localStorage.getItem("tenantId").replace('"','').replace('"','').toString(),
           NotificationId:props.userId},
            {headers: {"Authorization": "bearer " + token.replace('"','').replace('"','').toString()}})
            .then(res => {
             if(res.status==200){
               openNotificationWithIcon('success');
                  props.sendDataForUpdate(bankId);
                }
            }).catch(
             error => {
                   if(error.response.status===403 || error.response.status ===401){
                     history.push('/not-authorized-access');
                   }else if(error.response.status === 406){
                     error.response.data.Data.map((item)=>{
                       errorList.push(item);
                     })
                     errorListNotification('error')
                   }else{
                     openNotificationWithIconWarning('error')
                   }}
         );
  };
  const [addContactState, setAddContactState] = useState(false);
  const history = useHistory();
  const [isCheck, setIsCheck] = useState(false);
  const errorList = [];
    const errorListNotification = type => {
      errorList.map((item)=> {
        notification[type]({
          message: 'Hata',
          description:
          item
           ,
            duration: 2,
        });
      })
    };
useEffect(() => {
}, [])
    const {contact} = props;
    const {iban, accountNumber,isAccountChecked,bankId, id, bankName, currency, branchName} = contact;
    return (
      <div className="gx-contact-item">
        <div className="gx-module-list-icon">
          <Checkbox className="gx-icon-btn"
                    checked={isAccountChecked}
                    value="checkedF"
                    onClick={() => {
                      onContactSelect(id,isAccountChecked)
                    }}/>
          <div className="gx-d-none gx-d-sm-flex" >
          </div>
          <div className="gx-ml-2 gx-d-none gx-d-sm-flex">
          </div>
        </div>

        <div className="gx-module-list-info gx-contact-list-info">
          <div className="gx-module-contact-content">
            <p className="gx-mb-1">
              <span className="gx-text-truncate gx-contact-name"> {iban === null ? (accountNumber === null ? bankName : accountNumber) : iban} </span>
              <span className="gx-toolbar-separator">&nbsp;</span>
              <span className="gx-text-truncate gx-job-title"></span>
            </p>
            <div className="gx-text-muted">
            <span className="gx-email gx-d-inline-block gx-mr-2">
                {branchName === null ? "" : branchName + ","}
            </span>
              <span className="gx-phone gx-d-inline-block">{currency}</span>
            </div>
          </div>

          <div className="gx-module-contact-right">

          </div>
        </div>
      </div>
    )
  }

export default ContactCell;
