import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Select,
  Collapse,
  InputNumber,
  DatePicker,
} from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { odata } from "./Helper";

const TosSentPaymentTableFilter = (props) => {
  const [form] = Form.useForm();
  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: false,
  });
  
  useEffect(() => {
    getBanks();
    if (props.data) {
      //onFinish(props.data);
      form.setFieldsValue(props.data);
    }
  }, [props.data]);
  const getBanks = () => {
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    
    RequestHandler.get(`/bank/GetTosBankDropdown?directiveId=${props.data.directiveId}`).then(
      (response) => {
        setBankSelect({
          data: response,
          loading: false,
        });
      }
    );
  };
  const onFinish = (values) => {
    {
      let query = "";
      const conditions = [];
      conditions.push(`&$filter=DirectiveId eq ${props.data.directiveId}`)

      if (values.bankId) {
        conditions.push(`bankId eq ${values.bankId}`);
      }
      if (values.title) {
        conditions.push(`Contains(title,'${values.title}')`);
      }
      if (values.bankBranchId) {
        conditions.push(`bankBranchId eq ${values.bankBranchId}`);
      }
      if (values.statusId) {
        conditions.push(`statusId eq ${values.statusId}`);
      }
      if (values.accountNumber) {
        conditions.push(`Contains(accountNumber,'${values.accountNumber}')`);
      }
      if (values.iban) {
        conditions.push(`Contains(iban,'${values.iban}')`);
      }
      if (values.description) {
        conditions.push(`Contains(description,'${values.description}')`);
      }
      const amountQuery = odata.ConditionalRange(
        "amount",
        values.amountMin,
        values.amountMax
      );
      if (amountQuery) conditions.push(amountQuery);
      if (values.paymentDateRange) {
        const [startDate, endDate] = values.paymentDateRange;
        conditions.push(odata.dateRange("paymentDate", startDate, endDate));
      }
      if (conditions.length !== 0) {
        query = conditions.join(" and ");
      }

      if (props.onFinishFilter) props.onFinishFilter(query);
    }
  };

  const clearFilter = () => {
    form.resetFields();

    if (props.onClearFilter) {
      props.onClearFilter();
    }
  };

  return (
    <Row id="filterArea" style={{ ...props.style }}>
      <Col span={24}>
        <Collapse>
          <Collapse.Panel header="Filtreleme için tıklayınız.">
            <Form
              name="filter"
              form={form}
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row style={{ flexDirection: "row" }}>
              <Col xs={24} md={12} lg={6}>
                <Form.Item name="bankId" label="Banka :">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Tümü"
                      loading={bankSelect.loading}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {bankSelect.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="title" label="Ad Soyad / Ünvan :">
                    <Input  placeholder="Ad Soyad / Ünvan"/>
                  </Form.Item>
                </Col>
     
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="iban"label="Iban :">
                    <Input placeholder="Iban" />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item style={{ marginBottom: 0 }} label="Tutar :">
                    <Input.Group>
                      <Row gutter={1} style={{ flexDirection: "row" }}>
                        <Col span={12}>
                          <Form.Item name="amountMin">
                            <InputNumber style={{ width: "100%" }}  placeholder="Min" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="amountMax">
                            <InputNumber style={{ width: "100%" }}  placeholder="Max"/>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>
           
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="description" label="Açıklama :">
                    <Input  placeholder="Açıklama"/>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                <Form.Item name="statusId" label="Ödeme Durumu :">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Tümü"
                      loading={bankSelect.loading}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                        <Select.Option key={1} value={1}>
                          Yeni
                        </Select.Option>
                        <Select.Option key={2} value={2}>
                          İptal
                        </Select.Option>
                        <Select.Option key={3} value={3}>
                          Hatalı
                        </Select.Option>
                        <Select.Option key={4} value={4}>
                          Onaylandı
                        </Select.Option>
                        <Select.Option key={5} value={5}>
                          Teyit Bekliyor
                        </Select.Option>
                        <Select.Option key={6} value={6}>
                          Ödenecek
                        </Select.Option>
                        <Select.Option key={7} value={7}>
                          Ödenmedi
                        </Select.Option>
                        <Select.Option key={8} value={8}>
                          Kısmi Ödendi
                        </Select.Option>
                        <Select.Option key={9} value={9}>
                          Ödendi
                        </Select.Option>
                    </Select>
                </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="paymentDateRange" label="Ödeme Tarihi :">
                    <DatePicker.RangePicker format="DD.MM.YYYY" />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ flexDirection: "row" }}>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    danger
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={() => clearFilter()}
                  >
                    Sıfırla
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    icon={<SearchOutlined />}
                    style={{ color: "#40a9ff", borderColor: "#40a9ff" }}
                    htmlType="submit"
                  >
                    Filtrele
                  </Button>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};
export default TosSentPaymentTableFilter;
