import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Form,
  Col,
  notification,
  Row,
  Button,
  Spin,
  Switch,
  Upload,
  Modal,
  Table,
  Empty,
  Checkbox,
} from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  PlusOutlined,
  MailFilled,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import config from "../../util/ApiforContext";
import Replace2x from "../../util/Extensions/ReplaceExtension";
import RequestHandler from "../../util/RequestHandler";
import SubscriberUserMatchedRecords from "../SubscriberFirm/SubscriberUserMatchedRecords";
const token = localStorage.getItem("token");
const expiration = localStorage.getItem("expiration");
const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 13,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 15,
    span: 5,
  },
};
const { TextArea } = Input;
const SubscriberFirmAddForm = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });

  const history = useHistory();
  const [values, SetValues] = useState(JSON);
  const [spinLoading, SetSpinLoading] = useState(false);
  const [loading, SetLoading] = useState(false);
  const [FirmTypeValue, setFirmTypeValue] = useState(1);
  const [visibleUserInfo, SetVisibleUserInfo] = useState(false);
  const [SubscriberUsers, SetSubscriberUsers] = useState({
    data: [],
    loading: true,
  });
  const [homeOwnerId, SetHomeOwnerId] = useState("");
  const [hirerId, SetHirerId] = useState("");
  const [name, setName] = useState("");
  const [isHired, setIsHired] = useState(true);
  const errorList = [];
  const { Option } = Select;
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Ekleme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });
  };
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 5,
      });
    });
  };

  const onChangeNameValue = (e) => {
    setName(e.target.value);
  };


  const HandleModal = () => {
    SetVisibleUserInfo(true);
  };

  const onFinish = (values) => {
    SetValues(values);
    if (isHired === true) {
      RequestHandler.post(
        "/SubscriberUser/CheckUsersExist",
        [
          {
            Email: values.HomeOwnerMail,
            Name: values.HomeOwnerName,
            PhoneNumber: values.HomeOwnerPhone,
            TcNo: values.HomeOwnerTckn,
            Vkn: values.HomeOwnerVkn,
            TenantSubscriberUserDto: {
              IsHirer: false,
            },
          },
          {
            Email: values.HirerMail,
            Name: values.HirerName,
            PhoneNumber: values.HirerPhone,
            TcNo: values.HirerTckn,
            Vkn: values.HirerVkn,
            TenantSubscriberUserDto: {
              IsHirer: true,
            },
          },
        ],
        false
      ).then((response) => {
        SetSubscriberUsers({
          data: response,
          loading: false,
        });

        if (response.length != 0) {
          SetVisibleUserInfo(true);
        } else {
          onFinishLast(values);
        }
      });
    } else {
      RequestHandler.post(
        "/SubscriberUser/CheckUsersExist",
        [
          {
            Email: values.HomeOwnerMail,
            Name: values.HomeOwnerName,
            PhoneNumber: values.HomeOwnerPhone,
            TcNo: values.HomeOwnerTckn,
            Vkn: values.HomeOwnerVkn,
            TenantSubscriberUserDto: {
              IsHirer: false,
            },
          },
        ],
        false
      ).then((response) => {
        SetSubscriberUsers({
          data: response,
          loading: false,
        });

        if (response.length != 0) {
          HandleModal();
        } else {
          onFinishLast(values);
        }
      });
    }
  };

  const onFinishLast = (values) => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }
    if (isHired === true) {
      RequestHandler.post(
        "/SubscriberFirm",
        {
          Name: values.Name,
          Address: values.Address,
          IsHired: true,
          Description: values.Description,
          FirmTypeByteValue: 1,
          SubscriberUserDtos: [
            {
              id:
                homeOwnerId != ""
                  ? homeOwnerId
                  : "00000000-0000-0000-0000-000000000000",
              Email: values.HomeOwnerMail,
              Name: values.HomeOwnerName,
              PhoneNumber: values.HomeOwnerPhone,
              TcNo: values.HomeOwnerTckn,
              Vkn: values.HomeOwnerVkn,
              TenantSubscriberUserDto: {
                IsHirer: false,
              },
            },
            {
              id:
                hirerId != ""
                  ? hirerId
                  : "00000000-0000-0000-0000-000000000000",
              Email: values.HirerMail,
              Name: values.HirerName,
              PhoneNumber: values.HirerPhone,
              TcNo: values.HirerTckn,
              Vkn: values.HirerVkn,
              TenantSubscriberUserDto: {
                IsHirer: true,
              },
            },
          ],
        },
        false
      ).then((res) => {
        if (res.statusCode === 200) {
          openNotificationWithIcon("success");
          setTimeout(
            function () {
              window.location.reload();
            }.bind(this),
            2000
          );
        } else {
          SetLoading(false);
        }
      });
    } else {
      RequestHandler.post(
        "/SubscriberFirm",
        {
          Name: values.Name,
          Address: values.Address,
          IsHired: false,
          Description: values.Description,
          FirmTypeByteValue: 1,
          SubscriberUserDtos: [
            {
              id:
                homeOwnerId != ""
                  ? homeOwnerId
                  : "00000000-0000-0000-0000-000000000000",
              Email: values.HomeOwnerMail,
              Name: values.HomeOwnerName,
              PhoneNumber: values.HomeOwnerPhone,
              TcNo: values.HomeOwnerTckn,
              Vkn: values.HomeOwnerVkn,
              TenantSubscriberUserDto: {
                IsHirer: false,
              },
            },
          ],
        },
        false
      ).then((res) => {
        if (res.statusCode === 200) {
          openNotificationWithIcon("success");
          setTimeout(
            function () {
              window.location.reload();
            }.bind(this),
            2000
          );
        } else {
          SetLoading(false);
        }
      });
    }
  };
  const onFinishFailed = (errorInfo) => {
    SetLoading(false);
  };
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const excelDownload = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Excel şablonu indirme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });
  };
  const excelDownloadFailed = (type) => {
    notification[type]({
      message: "HATA",
      description: "Excel şablonu indirme işlemi sırasında bir hata oluştu.",
      duration: 2,
    });
  };

  const excelDownloadFailedUntilThis = (type) => {
    notification[type]({
      message: "HATA",
      description: "Bu veriden sonra yüklemeye devam edilemedi.",
      duration: 5,
    });
  };
  const downloadExcel = () => {
    window.open(
      `${config.apiUrl}/SubscriberFirm/DownloadExcel?t=${Replace2x(token)}`
    );
  };

  const propsFirmExcel = {
    name: "excel",
    action: `${config.apiUrl}/SubscriberFirm/ImportExcel?type=1`,
    headers: {
      Authorization:
        "bearer " + token?.replace('"', "").replace('"', "").toString(),
    },
    onChange(info) {
      const { response, status } = info.file;
      if (info.fileList.length > 0) {
        SetSpinLoading(true);
      }
      if (info.file.status === "done") {
        excelDownload("success");
        setTimeout(
          function () {
            window.location.reload();
          }.bind(this),
          2000
        );
      } else if (info.file.status === "error") {
        if (info.file.response.statusCode === 406) {
          info.file.response.data.map((item) => {
            errorList.push(item);
            setTimeout(function () {
              window.location.reload();
            }, 5000);
          });
          errorListNotification("error");
          excelDownloadFailedUntilThis("error");
          setTimeout(function () {
            window.location.reload();
          }, 5000);
        } else {
          const notificationType = response.success ? "success" : "error";

          notification[notificationType]({
            message: response.message,
            duration: 3,
          });
          if (!response.success) {
            downloadImportResult(info.file.response.data);
          }
          setTimeout(function () {
            window.location.reload();
          }, 5000);
        }

        SetSpinLoading(false);
      }
    },
  };
  const downloadImportResult = (fileName) => {
    window.open(
      `${config.apiUrl}/SubscriberFirm/DownloadImportResult?t=${Replace2x(
        token
      )}&fileName=${fileName}`
    );
  };

  return (
    <div>
      <Spin tip="Excel yükleniyor..." spinning={spinLoading}>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          fields={[{ name: "FirmTypeValue", value: FirmTypeValue.toString() }]}
        >
          <Row>
            {isDesktopOrLaptop && (
              <>
                <Col span={20}>
                  <Form.Item
                    onChange={onChangeNameValue}
                    style={{ marginTop: "4%" }}
                    label="Daire Adı"
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Daire Adı alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Apartman"
                    name="Address"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Apartman Adı alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Kiracı var mı?" name="IsHired">
                    <Switch
                      defaultChecked={true}
                      onChange={(checked) => {
                        setIsHired(checked);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Açıklama" name="Description">
                    <TextArea maxLength={200} />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi Adı Soyadı"
                    name="HomeOwnerName"
                    rules={[
                      {
                        required: true,
                        message:
                          "Lütfen Ev Sahibi Adı Soyadı alanını boş bırakmayın",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Ev Sahibi Telefon Numarası"
                    name="HomeOwnerPhone"
                    rules={[
                      {
                        min: 12,
                        message: "Telefon numarasını eksiksiz giriniz.",
                      },
                    ]}
                  >
                    <PhoneInput
                      inputStyle={{ width: "100%" }}
                      country="tr"
                      masks={{ tr: "(...) ...-..-.." }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi T.C. Kimlik No"
                    name="HomeOwnerTckn"
                    rules={[
                      {
                        min: 11,
                        message: "TcNo 11 karakter olmalıdır.",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      autoComplete="off"
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      maxLength="11"
                      onInput={maxLengthCheck}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi Vergi Numarası"
                    name="HomeOwnerVkn"
                  >
                    <Input
                      type="text"
                      autoComplete="off"
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      maxLength="10"
                      onInput={maxLengthCheck}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi E-Mail"
                    name="HomeOwnerMail"
                    rules={[{ type: "email" }]}
                  >
                    <Input type={MailFilled} />
                  </Form.Item>
                  {isHired && (
                    <Form.Item
                      label="Kiracı Adı Soyadı"
                      name="HirerName"
                      rules={[
                        {
                          required: true,
                          message:
                            "Lütfen Kiracı Adı Soyadı alanını boş bırakmayın",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  {isHired && (
                    <Form.Item
                      label="Kiracı Telefon Numarası"
                      name="HirerPhone"
                      rules={[
                        {
                          min: 12,
                          message: "Telefon numarasını eksiksiz giriniz.",
                        },
                      ]}
                    >
                      <PhoneInput
                        inputStyle={{ width: "100%" }}
                        country="tr"
                        masks={{ tr: "(...) ...-..-.." }}
                      />
                    </Form.Item>
                  )}
                  {isHired && (
                    <Form.Item
                      label="Kiracı T.C. Kimlik No"
                      name="HirerTckn"
                      rules={[
                        {
                          min: 11,
                          message: "TcNo 11 karakter olmalıdır.",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="off"
                        onKeyDown={(evt) =>
                          evt.key === "e" && evt.preventDefault()
                        }
                        maxLength="11"
                        onInput={maxLengthCheck}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  )}
                  {isHired && (
                    <Form.Item label="Kiracı Vergi Numarası" name="HirerVkn">
                      <Input
                        type="text"
                        autoComplete="off"
                        onKeyDown={(evt) =>
                          evt.key === "e" && evt.preventDefault()
                        }
                        maxLength="10"
                        onInput={maxLengthCheck}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  )}
                  {isHired && (
                    <Form.Item
                      label="Kiracı E-Mail"
                      name="HirerMail"
                      rules={[{ type: "email" }]}
                    >
                      <Input type={MailFilled} />
                    </Form.Item>
                  )}
                </Col>
                <Col span={4}></Col>
                <Col span={6} style={{ marginLeft: -5 }}>
                  <Upload {...propsFirmExcel}>
                    <Button icon={<UploadOutlined />}>
                      Excel ile Daire Ekle
                    </Button>
                  </Upload>
                </Col>
                <Col span={6}>
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={(() => SetLoading(true), downloadExcel)}
                    loading={loading}
                  >
                    Excel Şablonu İndir
                  </Button>
                </Col>

                <Col span={6}></Col>
                <Col span={6}>
                  <Form.Item {...tailLayout}>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      htmlType="submit"
                      onClick={() => SetLoading(true)}
                      loading={loading}
                    >
                      Ekle
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
            {isTabletOrMobileDevice && (
              <>
                <Col span={24}>
                  <Form.Item
                    onChange={onChangeNameValue}
                    style={{ marginTop: "4%" }}
                    label="Daire Adı"
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Daire Adı alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Apartman"
                    name="Address"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Apartman Adı alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Kiracı var mı?" name="IsHired">
                    <Switch
                      defaultChecked={true}
                      onChange={(checked) => {
                        setIsHired(checked);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Açıklama" name="Description">
                    <TextArea maxLength={200} />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi Adı Soyadı"
                    name="HomeOwnerName"
                    rules={[
                      {
                        required: true,
                        message:
                          "Lütfen Ev Sahibi Adı Soyadı alanını boş bırakmayın",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Ev Sahibi Telefon Numarası"
                    name="HomeOwnerPhone"
                    rules={[
                      {
                        min: 12,
                        message: "Telefon numarasını eksiksiz giriniz.",
                      },
                    ]}
                  >
                    <PhoneInput
                      inputStyle={{ width: "100%" }}
                      country="tr"
                      masks={{ tr: "(...) ...-..-.." }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi T.C. Kimlik No"
                    name="HomeOwnerTckn"
                    rules={[
                      {
                        min: 11,
                        message: "TcNo 11 karakter olmalıdır.",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      autoComplete="off"
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      maxLength="11"
                      onInput={maxLengthCheck}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi Vergi Numarası"
                    name="HomeOwnerVkn"
                  >
                    <Input
                      type="text"
                      autoComplete="off"
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      maxLength="10"
                      onInput={maxLengthCheck}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi E-Mail"
                    name="HomeOwnerMail"
                    rules={[{ type: "email" }]}
                  >
                    <Input type={MailFilled} />
                  </Form.Item>
                  {isHired && (
                    <Form.Item
                      label="Kiracı Adı Soyadı"
                      name="HirerName"
                      rules={[
                        {
                          required: true,
                          message:
                            "Lütfen Kiracı Adı Soyadı alanınuı boş bırakmayın",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  {isHired && (
                    <Form.Item
                      label="Kiracı Telefon Numarası"
                      name="HirerPhone"
                      rules={[
                        {
                          min: 12,
                          message: "Telefon numarasını eksiksiz giriniz.",
                        },
                      ]}
                    >
                      <PhoneInput
                        inputStyle={{ width: "100%" }}
                        country="tr"
                        masks={{ tr: "(...) ...-..-.." }}
                      />
                    </Form.Item>
                  )}
                  {isHired && (
                    <Form.Item
                      label="Kiracı T.C. Kimlik No"
                      name="HirerTckn"
                      rules={[
                        {
                          min: 11,
                          message: "TcNo 11 karakter olmalıdır.",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="off"
                        onKeyDown={(evt) =>
                          evt.key === "e" && evt.preventDefault()
                        }
                        maxLength="11"
                        onInput={maxLengthCheck}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  )}
                  {isHired && (
                    <Form.Item label="Kiracı Vergi Numarası" name="HirerVkn">
                      <Input
                        type="text"
                        autoComplete="off"
                        onKeyDown={(evt) =>
                          evt.key === "e" && evt.preventDefault()
                        }
                        maxLength="10"
                        onInput={maxLengthCheck}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  )}
                  {isHired && (
                    <Form.Item
                      label="Kiracı E-Mail"
                      name="HirerMail"
                      rules={[{ type: "email" }]}
                    >
                      <Input type={MailFilled} />
                    </Form.Item>
                  )}
                </Col>

                <Col span={12}></Col>
                <Col span={6}>
                  <Form.Item {...tailLayout}>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      htmlType="submit"
                      onClick={() => SetLoading(true)}
                      loading={loading}
                    >
                      Ekle
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={24}></Col>
                <Col span={2}></Col>
                <Col span={6}>
                  <Upload {...propsFirmExcel}>
                    <Button icon={<UploadOutlined />}>
                      Excel ile Daire Ekle
                    </Button>
                  </Upload>
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
                <Col span={2}></Col>
                <Col span={6}>
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={(() => SetLoading(true), downloadExcel)}
                    loading={loading}
                  >
                    Excel Şablonu İndir
                  </Button>
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
                <Col span={24}></Col>
              </>
            )}
          </Row>
        </Form>
      </Spin>
      <Modal
        title={"Eşleşen Kayıtlar"}
        visible={visibleUserInfo}
        centered={true}
        keyboard={true}
        onOk={() => {
          SetVisibleUserInfo(false);
          onFinishLast(values);
        }}
        onCancel={() => {
          SetVisibleUserInfo(false);
          SetLoading(false);
        }}
        width={1000}
        destroyOnClose={true}
      >
        <SubscriberUserMatchedRecords
          Data={SubscriberUsers.data}
          HomeOwnerId={(homeOwnerId) => SetHomeOwnerId(homeOwnerId)}
          HirerId={(hirerId) => SetHirerId(hirerId)}
        />
      </Modal>
    </div>
  );
};
export default SubscriberFirmAddForm;
