import React, { useState, useEffect } from "react";
import {
  Input,
  Popconfirm,
  notification,
  Form,
  Col,
  Row,
  Button,
  Switch,
  Modal,
  Empty,
} from "antd";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PlusOutlined, MailFilled } from "@ant-design/icons";
import axios from "axios";
import config from "../../util/ApiforContext";
import SubscriberUserAdd from "./SubscriberUserAddForm";
import FormItem from "antd/lib/form/FormItem";
import SubscriberUserMatchedRecords from "../SubscriberFirm/SubscriberUserMatchedRecords";
import RequestHandler from "../../util/RequestHandler";
const expiration = localStorage.getItem("expiration");

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 15,
    span: 5,
  },
};

const { TextArea } = Input;

const SubscriberFirmUpdate = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });

  const history = useHistory();
  const [values, SetValues] = useState(JSON);
  const [spinLoading, SetSpinLoading] = useState(false);
  const [loading, SetLoading] = useState(false);
  const [FirmTypeValue, setFirmTypeValue] = useState(4);
  const [collectionAndPaymentTotal, setCollectionAndPaymentTotal] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [name, setName] = useState(props.Name);
  const [adress, setAdress] = useState(props.Address);
  const [isHired, setIsHired] = useState(props.IsHired);
  const [isHiredDefault, setIsHiredDefault] = useState(props.IsHired);
  const [description, setDescription] = useState(props.Description);
  const [homeOwnerName, setHomeOwnerName] = useState(props.HomeOwnerName);
  const [hirerName, setHirerName] = useState(props.HirerName);
  const [homeOwnerTckn, setHomeOwnerTckn] = useState(props.HomeOwnerTckn);
  const [hirerTckn, setHirerTckn] = useState(props.HirerTckn);
  const [homeOwnerVkn, setHomeOwnerVkn] = useState(props.HomeOwnerVkn);
  const [hirerVkn, setHirerVkn] = useState(props.HirerVkn);
  const [homeOwnerPhone, setHomeOwnerPhone] = useState(
    props.HomeOwnerPhone != null ? "+90" + props.HomeOwnerPhone : ""
  );
  const [hirerPhone, setHirerPhone] = useState(
    props.HirerPhone != null && props.HirerPhone.length > 1
      ? "+90" + props.HirerPhone
      : ""
  );
  const [homeOwnerMail, setHomeOwnerMail] = useState(props.HomeOwnerMail);
  const [hirerMail, setHirerMail] = useState(props.HirerMail);
  const [id, setId] = useState(props.Id);
  const [homeOwnerId, setHomeOwnerId] = useState(props.HomeOwnerId);
  const [hirerId, setHirerId] = useState(props.HirerId);
  const errorList = [];
  const [visibleHirerFields, setvisibleHirerFields] = useState(props.IsHired);
  const [visibleUserAdd, setVisibleUserAdd] = useState(false);
  const [addUserIsHirer, setAddUserIsHirer] = useState(false);
  const [accountBalanceTotal, setAccountBalancetotal] = useState(0);
  const [visibleUserInfo, SetVisibleUserInfo] = useState(false);
  const [SubscriberUsers, SetSubscriberUsers] = useState({
    data: [],
    loading: true,
  });

  const openNotificationWithIconUpdate = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Güncelleme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });
  };
  const openNotificationWithIconWarningUpdate = (type) => {
    notification[type]({
      message: "HATA",
      description: "Güncelleme işlemi sırasında bir hata oluştu.",
      duration: 2,
    });
  };

  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 4,
      });
    });
  };

  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }

    axios
      .post(
        `${config.apiUrl}/SubscriberFirm/GetCollectionPlanAndPaymentTotal`,
        {
          FirmId: props.Id,
        },
        config.headers
      )
      .then((res) => {
        setAccountBalancetotal(res.data.accountBalanceTotal);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  }, [id]);

  const onFinishUpdate = (values) => {
    SetValues(values);
    if (isHiredDefault == false && isHired == true) {
      RequestHandler.post(
        "/SubscriberUser/CheckUsersExist",
        [
          {
            Email: values.HirerMail,
            Name: values.HirerName,
            PhoneNumber: values.HirerPhone,
            TcNo: values.HirerrTckn,
            Vkn: values.HirerVkn,
            TenantSubscriberUserDto: {
              IsHirer: true,
            },
          },
        ],
        false
      ).then((response) => {
        SetSubscriberUsers({
          data: response,
          loading: false,
        });

        if (response.length != 0) {
          HandleModal();
        } else {
          onFinishUpdateLast(values);
        }
      });
    } else {
      onFinishUpdateLast(values);
    }
  };

  const onFinishUpdateLast = (values) => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }

    if (isHired) {
      axios
        .put(
          `${config.apiUrl}/SubscriberFirm`,
          {
            Id: props.Id,
            Name: values.Name,
            Address: values.Address,
            IsHired: true,
            Description: values.Description,
            SubscriberUserDtos: [
              {
                Id: props.HomeOwnerId,
                Email: values.HomeOwnerMail,
                Name: values.HomeOwnerName,
                PhoneNumber: values.HomeOwnerPhone,
                TcNo: values.HomeOwnerTckn,
                Vkn: values.HomeOwnerVkn,
                TenantSubscriberUserDto: {
                  IsHirer: false,
                  FirmId: props.Id,
                },
              },
              {
                Id:
                  hirerId != ""
                    ? hirerId
                    : "00000000-0000-0000-0000-000000000000",
                Email: values.HirerMail,
                Name: values.HirerName,
                PhoneNumber: values.HirerPhone,
                TcNo: values.HirerTckn,
                Vkn: values.HirerVkn,
                TenantSubscriberUserDto: {
                  IsHirer: true,
                  FirmId: props.Id,
                },
              },
            ],
          },
          config.headers
        )
        .then((res) => {
          if (res.status === 200) {
            openNotificationWithIconUpdate("success");
            setTimeout(
              function () {
                window.location.reload();
              }.bind(this),
              2000
            );
          }
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push("/not-authorized-access");
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            });
            errorListNotification("error");
            SetLoading(false);
          }
        });
    } else {
      axios
        .put(
          `${config.apiUrl}/SubscriberFirm`,
          {
            Id: props.Id,
            Name: values.Name,
            Address: values.Address,
            IsHired: false,
            Description: values.Description,
            SubscriberUserDtos: [
              {
                Id: props.HomeOwnerId,
                Email: values.HomeOwnerMail,
                Name: values.HomeOwnerName,
                PhoneNumber: values.HomeOwnerPhone,
                TcNo: values.HomeOwnerTckn,
                Vkn: values.HomeOwnerVkn,
                TenantSubscriberUserDto: {
                  Id: props.HomeOwnerTenantSubscriberUserId,
                  IsHirer: false,
                  FirmId: props.Id,
                },
              },
            ],
          },
          config.headers
        )
        .then((res) => {
          if (res.status === 200) {
            openNotificationWithIconUpdate("success");
            setTimeout(
              function () {
                window.location.reload();
              }.bind(this),
              2000
            );
          }
        })
        .catch((error) => {
          openNotificationWithIconWarningUpdate("error");
          if (error.response.status === 403 || error.response.status === 401) {
            history.push("/not-authorized-access");
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            });
            errorListNotification("error");
            SetLoading(false);
          }
        });
    }
  };

  const HandleModal = () => {
    SetVisibleUserInfo(true);
  };
  const onChangeNameValue = (e) => {
    setName(e.target.value);
  };
  const onChangeAddressValue = (e) => {
    setAdress(e.target.value);
  };
  const onChangeIsHiredValue = (e) => {
    setIsHired(e);
  };
  const onChangeDescriptionValue = (e) => {
    setDescription(e.target.value);
  };
  const onChangeHomeOwnerNameValue = (e) => {
    setHomeOwnerName(e.target.value);
  };
  const onChangeHomeOwnerPhoneValue = (e) => {
    setHomeOwnerPhone(e);
  };
  const onChangeHomeOwnerTcknValue = (e) => {
    setHomeOwnerTckn(e.target.value);
  };
  const onChangeHomeOwnerVknValue = (e) => {
    setHomeOwnerVkn(e.target.value);
  };
  const onChangeHomeOwnerMailValue = (e) => {
    setHomeOwnerMail(e.target.value);
  };
  const onChangeHirerNameValue = (e) => {
    setHirerName(e.target.value);
  };
  const onChangeHirerPhoneValue = (e) => {
    setHirerPhone(e);
  };
  const onChangeHirerTcknValue = (e) => {
    setHirerTckn(e.target.value);
  };
  const onChangeHirerVknValue = (e) => {
    setHirerVkn(e.target.value);
  };
  const onChangeHirerMailValue = (e) => {
    setHirerMail(e.target.value);
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };
  const onFinishFailedUpdate = (errorInfo) => {
    SetLoading(false);
  };
  const onCloseUserAdd = () => {
    setVisibleUserAdd(false);
  };

  return (
    <div>
      <Row>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishUpdate}
          onFinishFailed={onFinishFailedUpdate}
          fields={[
            { name: "FirmTypeValue", value: FirmTypeValue.toString() },
            { name: "Name", value: name },
            { name: "Address", value: adress },
            { name: "IsHired", checked: isHired },
            { name: "Description", value: description },
            { name: "HomeOwnerName", value: homeOwnerName },
            { name: "HomeOwnerPhone", value: homeOwnerPhone },
            { name: "HomeOwnerTckn", value: homeOwnerTckn },
            { name: "HomeOwnerVkn", value: homeOwnerVkn },
            { name: "HomeOwnerMail", value: homeOwnerMail },
            { name: "HirerName", value: hirerName },
            { name: "HirerPhone", value: hirerPhone },
            { name: "HirerTckn", value: hirerTckn },
            { name: "HirerVkn", value: hirerVkn },
            { name: "HirerMail", value: hirerMail },
          ]}
        >
          <Row>
            {isDesktopOrLaptop && (
              <>
                <Col span={20}>
                  <Form.Item
                    style={{ marginTop: "4%" }}
                    label="Daire Adı"
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Daire Adı alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input onChange={onChangeNameValue} />
                  </Form.Item>
                  <Form.Item
                    label="Apartman"
                    name="Address"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Apartman Adı alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input onChange={onChangeAddressValue} />
                  </Form.Item>
                  <Form.Item label="Kiracı var mı?" name="IsHired">
                    <Switch
                      defaultChecked={isHired}
                      onChange={(checked) => {
                        setvisibleHirerFields(checked);
                        setIsHired(checked);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Açıklama" name="Description">
                    <TextArea
                      maxLength={200}
                      onChange={onChangeDescriptionValue}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi Adı Soyadı"
                    name="HomeOwnerName"
                    rules={[
                      {
                        required: true,
                        message:
                          "Lütfen Ev Sahibi Adı Soyadı alanını boş bırakmayın",
                      },
                    ]}
                  >
                    <Input onChange={onChangeHomeOwnerNameValue} />
                  </Form.Item>

                  <Form.Item
                    label="Ev Sahibi Telefon Numarası"
                    name="HomeOwnerPhone"
                    rules={[
                      {
                        min: 12,
                        message: "Telefon numarasını eksiksiz giriniz.",
                      },
                    ]}
                  >
                    <PhoneInput
                      onChange={onChangeHomeOwnerPhoneValue}
                      inputStyle={{ width: "100%" }}
                      country="tr"
                      masks={{ tr: "(...) ...-..-.." }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi T.C. Kimlik No"
                    name="HomeOwnerTckn"
                    rules={[
                      {
                        min: 11,
                        message: "TcNo 11 karakter olmalıdır.",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      autoComplete="off"
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      maxLength="11"
                      onInput={maxLengthCheck}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={onChangeHomeOwnerTcknValue}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi Vergi Numarası"
                    name="HomeOwnerVkn"
                  >
                    <Input
                      type="text"
                      autoComplete="off"
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      maxLength="10"
                      onInput={maxLengthCheck}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={onChangeHomeOwnerVknValue}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi E-Mail"
                    name="HomeOwnerMail"
                    rules={[{ type: "email" }]}
                  >
                    <Input
                      type={MailFilled}
                      onChange={onChangeHomeOwnerMailValue}
                    />
                  </Form.Item>
                  {visibleHirerFields && (
                    <Form.Item
                      label="Kiracı Adı Soyadı"
                      name="HirerName"
                      rules={[
                        {
                          required: true,
                          message:
                            "Lütfen Kiracı Adı Soyadı alanını boş bırakmayın",
                        },
                      ]}
                    >
                      <Input onChange={onChangeHirerNameValue} />
                    </Form.Item>
                  )}
                  {visibleHirerFields && (
                    <Form.Item
                      label="Kiracı Telefon Numarası"
                      name="HirerPhone"
                      rules={[
                        {
                          min: 12,
                          message: "Telefon numarasını eksiksiz giriniz.",
                        },
                      ]}
                    >
                      <PhoneInput
                        onChange={onChangeHirerPhoneValue}
                        inputStyle={{ width: "100%" }}
                        country="tr"
                        masks={{ tr: "(...) ...-..-.." }}
                      />
                    </Form.Item>
                  )}
                  {visibleHirerFields && (
                    <Form.Item
                      label="Kiracı T.C. Kimlik No"
                      name="HirerTckn"
                      rules={[
                        {
                          min: 11,
                          message: "TcNo 11 karakter olmalıdır.",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="off"
                        onKeyDown={(evt) =>
                          evt.key === "e" && evt.preventDefault()
                        }
                        maxLength="11"
                        onInput={maxLengthCheck}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={onChangeHirerTcknValue}
                      />
                    </Form.Item>
                  )}
                  {visibleHirerFields && (
                    <Form.Item label="Kiracı Vergi Numarası" name="HirerVkn">
                      <Input
                        type="text"
                        autoComplete="off"
                        onKeyDown={(evt) =>
                          evt.key === "e" && evt.preventDefault()
                        }
                        maxLength="10"
                        onInput={maxLengthCheck}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={onChangeHirerVknValue}
                      />
                    </Form.Item>
                  )}
                  {visibleHirerFields && (
                    <Form.Item
                      label="Kiracı E-Mail"
                      name="HirerMail"
                      rules={[{ type: "email" }]}
                    >
                      <Input
                        type={MailFilled}
                        onChange={onChangeHirerMailValue}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={4}></Col>
                <Col span={6}></Col>
                <Col span={6}>
                  {accountBalanceTotal > 0 ? (
                    <FormItem {...tailLayout}>
                      <Popconfirm
                        title="İlgili Dairenin borcu bulunmaktadır.Ekleme işlemine devam etmek istiyor musunuz?"
                        onConfirm={() => {
                          setVisibleUserAdd(true);
                          setAddUserIsHirer(false);
                        }}
                        okText="Evet"
                        cancelText="Hayır"
                      >
                        <Button
                          type="seceondary"
                          icon={<PlusOutlined />}
                          htmlType="button"
                        >
                          Ev Sahibi Ekle
                        </Button>
                      </Popconfirm>
                    </FormItem>
                  ) : (
                    <Form.Item {...tailLayout}>
                      <Button
                        type="seceondary"
                        icon={<PlusOutlined />}
                        htmlType="button"
                        onClick={() => {
                          setVisibleUserAdd(true);
                          setAddUserIsHirer(false);
                        }}
                      >
                        Ev Sahibi Ekle
                      </Button>
                    </Form.Item>
                  )}
                </Col>
                {isHiredDefault === true ? (
                  <Col span={6}>
                    {accountBalanceTotal > 0 ? (
                      <FormItem {...tailLayout}>
                        <Popconfirm
                          title="İlgili Dairenin borcu bulunmaktadır.Ekleme işlemine devam etmek istiyor musunuz?"
                          onConfirm={() => {
                            setVisibleUserAdd(true);
                            setAddUserIsHirer(true);
                          }}
                          okText="Evet"
                          cancelText="Hayır"
                        >
                          <Button
                            type="seceondary"
                            icon={<PlusOutlined />}
                            htmlType="button"
                          >
                            Kiracı Ekle
                          </Button>
                        </Popconfirm>
                      </FormItem>
                    ) : (
                      <Form.Item {...tailLayout}>
                        <Button
                          type="seceondary"
                          icon={<PlusOutlined />}
                          htmlType="button"
                          onClick={() => {
                            setVisibleUserAdd(true);
                            setAddUserIsHirer(true);
                          }}
                        >
                          Kiracı Ekle
                        </Button>
                      </Form.Item>
                    )}
                  </Col>
                ) : (
                  <Col span={6}></Col>
                )}

                <Col span={6}>
                  <Form.Item {...tailLayout}>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      htmlType="submit"
                      onClick={() => SetLoading(true)}
                      loading={loading}
                    >
                      Güncelle
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
            {isTabletOrMobileDevice && (
              <>
                <Col span={20}>
                  <Form.Item
                    style={{ marginTop: "4%" }}
                    label="Daire Adı"
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Daire Adı alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input onChange={onChangeNameValue} />
                  </Form.Item>
                  <Form.Item
                    label="Apartman"
                    name="Address"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Apartman Adı alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input onChange={onChangeAddressValue} />
                  </Form.Item>
                  <Form.Item label="Kiracı var mı?" name="IsHired">
                    <Switch
                      defaultChecked={isHired}
                      onChange={(checked) => {
                        setvisibleHirerFields(checked);
                        setIsHired(checked);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Açıklama" name="Description">
                    <TextArea
                      maxLength={200}
                      onChange={onChangeDescriptionValue}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi Adı Soyadı"
                    name="HomeOwnerName"
                    rules={[
                      {
                        required: true,
                        message:
                          "Lütfen Ev Sahibi Adı Soyadı alanını boş bırakmayın",
                      },
                    ]}
                  >
                    <Input onChange={onChangeHomeOwnerNameValue} />
                  </Form.Item>

                  <Form.Item
                    label="Ev Sahibi Telefon Numarası"
                    name="HomeOwnerPhone"
                    rules={[
                      {
                        min: 12,
                        message: "Telefon numarasını eksiksiz giriniz.",
                      },
                    ]}
                  >
                    <PhoneInput
                      onChange={onChangeHomeOwnerPhoneValue}
                      inputStyle={{ width: "100%" }}
                      country="tr"
                      masks={{ tr: "(...) ...-..-.." }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi T.C. Kimlik No"
                    name="HomeOwnerTckn"
                    rules={[
                      {
                        min: 11,
                        message: "TcNo 11 karakter olmalıdır.",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      autoComplete="off"
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      maxLength="11"
                      onInput={maxLengthCheck}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={onChangeHomeOwnerTcknValue}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi Vergi Numarası"
                    name="HomeOwnerVkn"
                  >
                    <Input
                      type="text"
                      autoComplete="off"
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      maxLength="10"
                      onInput={maxLengthCheck}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={onChangeHomeOwnerVknValue}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ev Sahibi E-Mail"
                    name="HomeOwnerMail"
                    rules={[{ type: "email" }]}
                  >
                    <Input
                      type={MailFilled}
                      onChange={onChangeHomeOwnerMailValue}
                    />
                  </Form.Item>
                  {visibleHirerFields && (
                    <Form.Item
                      label="Kiracı Adı Soyadı"
                      name="HirerName"
                      rules={[
                        {
                          required: true,
                          message:
                            "Lütfen Kiracı Adı Soyadı alanınuı boş bırakmayın",
                        },
                      ]}
                    >
                      <Input onChange={onChangeHirerNameValue} />
                    </Form.Item>
                  )}
                  {visibleHirerFields && (
                    <Form.Item
                      label="Kiracı Telefon Numarası"
                      name="HirerPhone"
                      rules={[
                        {
                          required: false,
                          min: 12,
                          message: "Telefon numarasını eksiksiz giriniz.",
                        },
                      ]}
                    >
                      <PhoneInput
                        onChange={onChangeHirerPhoneValue}
                        inputStyle={{ width: "100%" }}
                        country="tr"
                        masks={{ tr: "(...) ...-..-.." }}
                      />
                    </Form.Item>
                  )}
                  {visibleHirerFields && (
                    <Form.Item
                      label="Kiracı T.C. Kimlik No"
                      name="HirerTckn"
                      rules={[
                        {
                          min: 11,
                          message: "TcNo 11 karakter olmalıdır.",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="off"
                        onKeyDown={(evt) =>
                          evt.key === "e" && evt.preventDefault()
                        }
                        maxLength="11"
                        onInput={maxLengthCheck}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={onChangeHirerTcknValue}
                      />
                    </Form.Item>
                  )}
                  {visibleHirerFields && (
                    <Form.Item label="Kiracı Vergi Numarası" name="HirerVkn">
                      <Input
                        type="text"
                        autoComplete="off"
                        onKeyDown={(evt) =>
                          evt.key === "e" && evt.preventDefault()
                        }
                        maxLength="10"
                        onInput={maxLengthCheck}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={onChangeHirerVknValue}
                      />
                    </Form.Item>
                  )}
                  {visibleHirerFields && (
                    <Form.Item
                      label="Kiracı E-Mail"
                      name="HirerMail"
                      rules={[{ type: "email" }]}
                    >
                      <Input
                        type={MailFilled}
                        onChange={onChangeHirerMailValue}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={4}></Col>
                <Col span={6}></Col>
                <Col span={6}>
                  <Form.Item {...tailLayout}>
                    <Popconfirm
                      visible
                      title="İlgili Dairenin borcu bulunmaktadır.Ekleme işlemine devam etmek istiyor musunuz?"
                      onConfirm={() => {
                        setVisibleUserAdd(true);
                        setAddUserIsHirer(false);
                      }}
                      okText="Evet"
                      cancelText="Hayır"
                      disabled={
                        collectionAndPaymentTotal.accountBalanceTotal <= 0
                      }
                    >
                      <Button
                        type="seceondary"
                        icon={<PlusOutlined />}
                        htmlType="button"
                        onClick={() => {
                          setVisibleUserAdd(true);
                          setAddUserIsHirer(false);
                        }}
                      >
                        Ev Sahibi Ekle
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                </Col>
                {isHiredDefault === true ? (
                  <Col span={6}>
                    <Form.Item {...tailLayout}>
                      <Button
                        type="seceondary"
                        icon={<PlusOutlined />}
                        htmlType="button"
                        onClick={() => {
                          setVisibleUserAdd(true);
                          setAddUserIsHirer(true);
                        }}
                      >
                        Kiracı Ekle
                      </Button>
                    </Form.Item>
                  </Col>
                ) : (
                  <Col span={6}></Col>
                )}

                <Col span={6}>
                  <Form.Item {...tailLayout}>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      htmlType="submit"
                      onClick={() => SetLoading(true)}
                      loading={loading}
                    >
                      Güncelle
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </Row>
      <Modal
        title={addUserIsHirer ? "Kiracı Ekle" : "Ev Sahibi Ekle"}
        visible={visibleUserAdd}
        onCancel={onCloseUserAdd}
        centered={true}
        keyboard={true}
        footer={[]}
        width={1000}
        destroyOnClose={true}
      >
        <SubscriberUserAdd SubscriberFirmId={id} IsHirer={addUserIsHirer} />
      </Modal>
      <Modal
        title={"Eşleşen Kayıtlar"}
        visible={visibleUserInfo}
        centered={true}
        keyboard={true}
        onOk={() => {
          SetVisibleUserInfo(false);
          onFinishUpdateLast(values);
        }}
        onCancel={() => {
          SetVisibleUserInfo(false);
          SetLoading(false);
        }}
        width={1000}
        destroyOnClose={true}
      >
        <SubscriberUserMatchedRecords
          Data={SubscriberUsers.data}
          HomeOwnerId={(homeOwnerId) => setHomeOwnerId(homeOwnerId)}
          HirerId={(hirerId) => setHirerId(hirerId)}
        />
      </Modal>
    </div>
  );
};
export default SubscriberFirmUpdate;
