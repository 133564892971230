import React, { useEffect, useState } from "react";
import RequestHandler from "util/RequestHandler";
import moment from "moment";

import { Table, Empty, Col, Row, Modal, Tooltip, message } from "antd";
import { FilePdfOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ColumnHelper } from "util/TableHelper";
import config from "util/ApiforContext";
import NetsisErpFirmReportDetail from "./ErpFirmReportDetail";

const NetsisErpFirmReport = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: false,
  });
  const [detailModalVisible, setDetailModalVisible] = useState();
  const [detailModalDocumentNumber, setDetailModalDocumentNumber] = useState();

  useEffect(() => getTableData(), [props.activeFirmCode]);

  const getTableData = () => {
    if (!props.activeFirmCode) return;

    setTableData({ ...tableData, loading: true });
    RequestHandler.get(`/ErpReport/NetsisFirmReport/${props.activeFirmCode}`).then((response) => {
      if (Array.isArray(response)) {
        setTableData({
          data: response,
          total: response.length,
          loading: false,
        });
      } else {
        setTableData({
          ...tableData,
          loading: false,
        });
      }
    });
  };

  const onClickDetail = (documentNumber) => {
    setDetailModalDocumentNumber(documentNumber);
    setDetailModalVisible(true);
  };

  const onClickExportPdf = () => {
    message.loading("Pdf indiriliyor...", 2);

    window.open(
      `${config.apiUrl}/ErpReport/NetsisFirmReportPdf/${props.activeFirmCode}?t=${RequestHandler.getToken()}`
    );
  };

  const columns = [
    {
      title: "Tarih",
      dataIndex: "date",
      align: "center",
      render: (text, row, index) => moment(text).format("DD.MM.YYYY"),
    },
    {
      title: "Belge No",
      dataIndex: "documentNumber",
    },
    {
      title: "Açıklama",
      dataIndex: "description",
    },
    {
      title: "Borç",
      dataIndex: "debtAmount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Alacak",
      dataIndex: "dueAmount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Bakiye",
      dataIndex: "balance",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Detay",
      dataIndex: "documentNumber",
      align: "center",
      render: (data, row) => (
        <a onClick={() => onClickDetail(data)}>
          <InfoCircleOutlined style={{ fontSize: "130%" }} />
        </a>
      ),
    },
  ];

  return (
    <>

      {tableData.data[0] && (<Row align="center">
        <Col span={20}>
          <p>
            {"Cari Bakiyesi: " + ColumnHelper.renderDecimal(tableData.data[0].balance)}
          </p>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <Tooltip title="Pdf İndir">
            <a
              style={{
                color: "red",
                fontSize: "160%",
                marginRight: 10,
              }}
              onClick={onClickExportPdf}
            >
              <FilePdfOutlined />
            </a>
          </Tooltip>
        </Col>
      </Row>)}


      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={props.activeFirmCode ? "Veri Yok" : "Önce Cari Seçmelisiniz"}
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            pagination={{
              defaultPageSize: 15,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Modal
        id="detailModal"
        centered
        visible={detailModalVisible}
        onOk={() => setDetailModalVisible(false)}
        onCancel={() => setDetailModalVisible(false)}
        width={1200}
        footer={null}
      >
        <NetsisErpFirmReportDetail documentNumber={detailModalDocumentNumber} />
      </Modal>
    </>
  );
};

export default NetsisErpFirmReport;
