import React from 'react'
import PropTypes from 'prop-types'

function SupportsList(props) {
    return (
        <div>
            MESAJ LİSTESİ
        </div>
    )
}

SupportsList.propTypes = {

}

export default SupportsList

