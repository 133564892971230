import React, { useEffect, useState } from "react";
import { Empty, notification } from 'antd';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import config from "util/ApiforContext"
import AccountTransactionListComponent from './AccountTransactionListComponent'
const expiration = localStorage.getItem("expiration");

const AccountPage = () => {
  const history = useHistory();
  const [accountsList, setAccountList] = useState([])
  const [dataState, setDataState] = useState([{ skip: 0, take: 5 }]);
  const [take, setTake] = useState(5);
  const [skip, setSkip] = useState(0);
  const [activeKey, setActiveKey] = useState('');
  const [query, setQuery] = useState('');
  const [currencyTRList, setCurrencyTRList] = useState([]);
  const errorList = [];

  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload()
    }
    axios.get(`${config.apiUrl}/TenantCurrency?$apply=groupby((Currency,CurrencyId))&$orderby=currencyId`, config.headers)
      .then(res => {
        setCurrencyTRList(res.data)

      }
      ).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          }
        }
      );

    axios.get(`${config.apiUrl}/AccountTransaction?$filter=Contains(debtOrCredit,'A') &$count=true&$skip=${skip}&$top=${take}`, config.headers)
      .then(res => {
        setAccountList(res.data.value)
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            setAccountList([])
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
      );
  }, [query, setDataState, skip, setSkip, setActiveKey]);

  const errorListNotification = type => {
    errorList.map((item) => {
      notification[type]({
        message: 'Hata',
        description:
          item
        ,
        duration: 2,
      });
    })
  };

  const ContactSideBar = (value) => {
    return <AccountTransactionListComponent data={accountsList} />
  };
  return (<div>

    {
      accountsList.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" /> :
        ContactSideBar()
    }


  </div>)
}

export default AccountPage
