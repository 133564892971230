import React, { useState, useEffect } from "react";
import {
  Table,
  Empty,
  Input,
  Popconfirm,
  Select,
  notification,
  Form,
  Col,
  Collapse,
  Tooltip,
  Row,
  Drawer,
  Button,
  Modal,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import {
  SearchOutlined,
  BarsOutlined,
  PlusOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  MenuUnfoldOutlined,
  OrderedListOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import axios from "axios";
import config from "../../util/ApiforContext";
import { odata } from "../Filters/Helper";
import { useMediaQuery } from "react-responsive";
import SubscriberUserHistory from "./SubscriberUserHistory";
import SubscriberFirmAddForm from "../Forms/SubscriberFirmAddForm";
import SubscriberFirmUpdate from "../Forms/SubscriberFirmUpdate";
import SubscriberFirmMonthlyPlanAdd from "../Forms/SubscriberFirmMonthlyPlanAdd";
import SubscriberFirmCollectionStatus from "./SubscriberFirmCollectionStatus";
const expiration = localStorage.getItem("expiration");

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};
const { Option } = Select;
const { Panel } = Collapse;

const SubscriberFirmList = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });

  const [subscriberFirms, setSubscriberFirms] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [dataState, setDataState] = useState([{ skip: 0, take: 10 }]);
  const [take, setTake] = useState(20);
  const [skip, setSkip] = useState(0);
  const [subscriberFirmDropdown, setSubscriberFirmDropdown] = useState([]);
  const [addressDropdown, setAddressDropdown] = useState([]);
  const [subscriberFirmIdUpdate, setSubscriberFirmIdUpdate] = useState("");
  const [subscriberFirmId, setSubscriberFirmId] = useState("");
  const [Name, setName] = useState("");
  const [Address, setAddress] = useState("");
  const [IsHired, setIsHired] = useState(false);
  const [order, setOrder] = useState("");
  const [homeOwnerName, setHomeOwnerName] = useState("");
  const [hirerName, setHirerName] = useState("");
  const [homeOwnerTckn, setHomeOwnerTckn] = useState("");
  const [hirerTckn, setHirerTckn] = useState("");
  const [homeOwnerVkn, setHomeOwnerVkn] = useState("");
  const [hirerVkn, setHirerVkn] = useState("");
  const [homeOwnerPhone, setHomeOwnerPhone] = useState("");
  const [hirerPhone, setHirerPhone] = useState("");
  const [homeOwnerMail, setHomeOwnerMail] = useState("");
  const [hirerMail, setHirerMail] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [FirmTypeValue, setFirmTypeValue] = useState("");
  const [query, setQuery] = useState("");
  const [filterAddress, setFilterAddress] = useState("");
  const [filterHomeOwner, setFilterHomeOwner] = useState("");
  const [filterHirer, setFilterHirer] = useState("");
  const [filterTckn, setFilterTckn] = useState("");
  const [filterVkn, setFilterVkn] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [description, setDescription] = useState("");
  const [paymentCode, setPaymentCode] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalSubcriberFirmId, setModalSubscriberFirmId] = useState("");
  const [homeOwnerId, setHomeOwnerId] = useState("");
  const [hirerId, setHirerId] = useState("");
  const [monthlyPlanAddSubscriberFirmId, setMonthlyPlanAddSubscriberFirmId] =
    useState("");
  const [
    monthlyPlanAddSubscriberFirmName,
    setMonthlyPlanAddSubscriberFirmName,
  ] = useState("");
  const [monthlyPlanAddVisible, setMonthlyPlanAddVisible] = useState(false);
  const [collectionStatusVisible, setCollectionStatusVisible] = useState(false);
  const [
    collectionStatusSubscriberFirmId,
    setCollectionStatusSubscriberFirmId,
  ] = useState("");
  const [
    subscriberFirmNameWithResponsibleUser,
    setSubscriberFirmNameWithResponsibleUser,
  ] = useState("");

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const errorList = [];
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 2,
      });
    });
  };
  const history = useHistory();

  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }

    setSubscriberFirms({
      ...subscriberFirms,
      loading: true,
    });
    axios
      .get(
        `${config.apiUrl}/SubscriberFirm? ${query}${order} &$count=true&$skip=${skip}&$top=${take}`,
        config.headers
      )
      .then((res) => {
        setSubscriberFirms({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          setSubscriberFirms({
            loading: false,
          });
          errorListNotification("error");
        }
      });
  }, [setDataState, skip, take, query, setSkip, order, setTake]);

  const handleActivePassive = (key) => {
    axios
      .delete(`${config.apiUrl}/SubscriberFirm?id=${key}`, config.headers)
      .then((res) => {
        if (res.status == 204 || res.status == 200) {
          getData("success");
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };

  const showDrawerUpdate = (value) => {
    axios
      .get(`${config.apiUrl}/SubscriberFirm/GetById/${value}`, config.headers)
      .then((res) => {
        setName(res.data[0].name);
        setAddress(res.data[0].address);
        setIsHired(res.data[0].isHired);
        setDescription(res.data[0].description);
        setPaymentCode(res.data[0].paymentCode);
        setHomeOwnerId(
          res.data[0].tenantSubscriberUsersVm.filter(
            (x) => x.isActive === true && x.isHirer === false
          )[0].subscriberUserVm.id
        );
        setHomeOwnerName(
          res.data[0].tenantSubscriberUsersVm.filter(
            (x) => x.isActive === true && x.isHirer === false
          )[0].subscriberUserVm.name
        );
        setHomeOwnerPhone(
          res.data[0].tenantSubscriberUsersVm.filter(
            (x) => x.isActive === true && x.isHirer === false
          )[0].subscriberUserVm.phoneNumber
        );
        setHomeOwnerTckn(
          res.data[0].tenantSubscriberUsersVm.filter(
            (x) => x.isActive === true && x.isHirer === false
          )[0].subscriberUserVm.tcNo
        );
        setHomeOwnerVkn(
          res.data[0].tenantSubscriberUsersVm.filter(
            (x) => x.isActive === true && x.isHirer === false
          )[0].subscriberUserVm.vkn
        );
        setHomeOwnerMail(
          res.data[0].tenantSubscriberUsersVm.filter(
            (x) => x.isActive === true && x.isHirer === false
          )[0].subscriberUserVm.email
        );

        setHirerId(
          res.data[0].isHired === true
            ? res.data[0].tenantSubscriberUsersVm.filter(
                (x) => x.isActive === true && x.isHirer === true
              )[0].subscriberUserVm.id
            : "00000000-0000-0000-0000-000000000000"
        );
        setHirerName(
          res.data[0].isHired === true
            ? res.data[0].tenantSubscriberUsersVm.filter(
                (x) => x.isActive === true && x.isHirer === true
              )[0].subscriberUserVm.name
            : ""
        );
        setHirerPhone(
          res.data[0].isHired === true
            ? res.data[0].tenantSubscriberUsersVm.filter(
                (x) => x.isActive === true && x.isHirer === true
              )[0].subscriberUserVm.phoneNumber
            : ""
        );
        setHirerTckn(
          res.data[0].isHired === true
            ? res.data[0].tenantSubscriberUsersVm.filter(
                (x) => x.isActive === true && x.isHirer === true
              )[0].subscriberUserVm.tcNo
            : ""
        );
        setHirerVkn(
          res.data[0].isHired === true
            ? res.data[0].tenantSubscriberUsersVm.filter(
                (x) => x.isActive === true && x.isHirer === true
              )[0].subscriberUserVm.vkn
            : ""
        );
        setHirerMail(
          res.data[0].isHired === true
            ? res.data[0].tenantSubscriberUsersVm.filter(
                (x) => x.isActive === true && x.isHirer === true
              )[0].subscriberUserVm.email
            : ""
        );

        setSubscriberFirmIdUpdate(value);
        setVisibleUpdate(true);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };

  const onClose = () => {
    setVisible(false);
    setVisibleUpdate(false);
  };
  const onCloseUpdate = () => {
    setVisibleUpdate(false);
  };
  const onCloseMonthlyPlanAdd = () => {
    setMonthlyPlanAddVisible(false);
  };
  const onCloseCollectionStatus = () => {
    setCollectionStatusVisible(false);
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Uyarı",
      description: "Pasif olan daireler güncellenemez.",
    });
  };
  const handleReset = (clearFilters) => {
    setSkip(0);
    setTake(20);
    setQuery("");
    setSubscriberFirmId("");
    setFilterAddress("");
    setFilterHomeOwner("");
    setFilterHirer("");
    setFilterTckn("");
    setFilterVkn("");
  };
  const getData = (val) => {
    if (val === "success") {
      reList();
      setVisibleUpdate(false);
      setVisible(false);
    }
  };
  const reList = () => {
    setSubscriberFirms({
      ...subscriberFirms,
      loading: true,
    });

    axios
      .get(
        `${config.apiUrl}/SubscriberFirm? ${query}${order} &$count=true&$skip=${skip}&$top=${take}`,
        config.headers
      )
      .then((res) => {
        setSubscriberFirms({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };

  const dataStateChange = (e) => {
    setSubscriberFirms(e);
  };
  const handleSubscriberFirmId = (value) => {
    setSubscriberFirmId(value);
  };
  const handleFilterAdress = (value) => {
    setFilterAddress(value.target.value);
  };

  const handleFilterHomeOwner = (value) => {
    setFilterHomeOwner(value.target.value);
  };

  const handleFilterHirer = (value) => {
    setFilterHirer(value.target.value);
  };

  const handleFilterTckn = (value) => {
    setFilterTckn(value.target.value);
  };

  const handleFilterVkn = (value) => {
    setFilterVkn(value.target.value);
  };
  const paging = (p, filters, sorter) => {
    let order = "";
    if (sorter.columnKey && sorter.order) {
      order =
        "&" +
        (sorter.order === "descend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }

    setOrder(order);
    setDataState((prevState) => {
      prevState.skip = (p.current - 1) * p.pageSize;
      setSkip(prevState.skip);
      setTake(p.pageSize);
      setCurrentPage(p.current);
      return {
        prevState,
      };
    });
  };

  const onSearch = (val) => {
    if (val.length >= 3) {
      axios
        .get(
          `${config.apiUrl}/SubscriberFirm?$filter=contains(name, '${val}')`,
          config.headers
        )
        .then((res) => {
          setSubscriberFirmDropdown(res.data.value);
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push("/not-authorized-access");
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            });
            errorListNotification("error");
          }
        });
    } else {
      setSubscriberFirmDropdown([]);
    }
  };

  const onFinishFilter = (values) => {
    {
      setSkip(0);
      setTake(20);
      var queryFirm = "";
      if (
        queryFirm === "" &&
        subscriberFirmId !== undefined &&
        subscriberFirmId !== ""
      ) {
        queryFirm += `$filter=Id eq ${subscriberFirmId} `;
      } else if (
        queryFirm !== "" &&
        subscriberFirmId !== undefined &&
        subscriberFirmId !== ""
      ) {
        queryFirm += ` and Id eq ${subscriberFirmId} `;
      }
      if (
        queryFirm === "" &&
        filterAddress !== undefined &&
        filterAddress !== ""
      ) {
        queryFirm += `$filter=Contains(address,'${filterAddress}') `;
      } else if (
        queryFirm !== "" &&
        filterAddress !== undefined &&
        filterAddress !== ""
      ) {
        queryFirm += ` and Contains(address,'${filterAddress}') `;
      }
      if (
        queryFirm === "" &&
        filterHomeOwner !== undefined &&
        filterHomeOwner !== ""
      ) {
        queryFirm += `$filter=Contains(homeOwnerName,'${filterHomeOwner}') `;
      } else if (
        queryFirm !== "" &&
        filterHomeOwner !== undefined &&
        filterHomeOwner !== ""
      ) {
        queryFirm += ` and Contains(homeOwnerName,'${filterHomeOwner}') `;
      }
      if (queryFirm === "" && filterHirer !== undefined && filterHirer !== "") {
        queryFirm += `$filter=Contains(hirerName,'${filterHirer}') `;
      } else if (
        queryFirm !== "" &&
        filterHirer !== undefined &&
        filterHirer !== ""
      ) {
        queryFirm += ` and Contains(hirerName,'${filterHirer}') `;
      }
      if (queryFirm === "" && filterTckn !== undefined && filterTckn !== "") {
        queryFirm += `$filter=Contains(homeOwnerTckn,'${filterTckn}') or Contains(hirerTckn,'${filterTckn}') `;
      } else if (
        queryFirm !== "" &&
        filterTckn !== undefined &&
        filterTckn !== ""
      ) {
        queryFirm += ` and Contains(homeOwnerTckn,'${filterTckn}') or Contains(hirerTckn,'${filterTckn}') `;
      }

      if (queryFirm === "" && filterVkn !== undefined && filterVkn !== "") {
        queryFirm += `$filter=Contains(homeOwnerVkn,'${filterVkn}') or Contains(hirerVkn,'${filterVkn}') `;
      } else if (
        queryFirm !== "" &&
        filterVkn !== undefined &&
        filterVkn !== ""
      ) {
        queryFirm += ` and Contains(homeOwnerVkn,'${filterVkn}') or Contains(hirerVkn,'${filterVkn}') `;
      }
      setQuery(queryFirm);
      queryFirm = "";
    }
  };

  const columns = [
    {
      title: "Daire No",
      dataIndex: "name",
      key: "name",
      render: (text, row, index) =>
        text == null ? (
          ""
        ) : (
          <Tooltip placement="topLeft" title={text}>
            {text.substring(0, 15).length < 15
              ? text.substring(0, 15) + ""
              : text.substring(0, 15) + "..."}
          </Tooltip>
        ),
    },
    {
      title: "Apartman",
      dataIndex: "address",
      key: "address",
      render: (text, row, index) => (
        <Tooltip placement="topLeft" title={text}>
          {text?.substring(0, 20) === undefined
            ? ""
            : text?.substring(0, 20).length < 20
            ? text?.substring(0, 20) + ""
            : text?.substring(0, 20) + "..."}
        </Tooltip>
      ),
    },
    {
      title: "Ev Sahibi Ad-Soyad",
      dataIndex: "tenantSubscriberUsersVm",
      align: "center",
      key: "homeOwnerName",
      render: (data, text) => (
        (text = data
          .filter((x) => x.isActive === true && x.isHirer === false)
          .map((item) => (item != Empty ? item.subscriberUserVm.name : ""))),
        (
          <Tooltip placement="topLeft" title={text}>
            <div>
              {text === undefined ? (
                <div style={{ opacity: 0.7 }}>-</div>
              ) : (
                text
              )}
            </div>
          </Tooltip>
        )
      ),
    },
    {
      title: "Kiracı Ad-Soyad",
      dataIndex: "tenantSubscriberUsersVm",
      align: "center",
      key: "hirerName",
      render: (data, text) => (
        (text = data
          .filter((x) => x.isActive === true && x.isHirer === true)
          .map((item) => (item != Empty ? item.subscriberUserVm.name : ""))),
        (
          <Tooltip placement="topLeft" title={text}>
            <div>
              {text === undefined ? (
                <div style={{ opacity: 0.7 }}>-</div>
              ) : (
                text
              )}
            </div>
          </Tooltip>
        )
      ),
    },
    {
      title: "Detay",
      dataIndex: "id",
      align: "center",
      key: "id",
      width: "5%",
      render: (text, record) => (
        <Button
          onClick={() => {
            setModalSubscriberFirmId(record.id);
            setIsModalVisible(true);
          }}
          icon={<OrderedListOutlined />}
          type="link"
          style={{
            color: "#08979c",
            fontSize: "130%",
            paddingTop: isTabletOrMobileDevice ? 3 : 5,
          }}
        ></Button>
      ),
    },
    {
      title: "Tahsilat Durumu",
      dataIndex: "id",
      align: "center",
      key: "id",
      width: "7%",
      render: (text, record) => (
        <Button
          onClick={() => {
            setCollectionStatusSubscriberFirmId(record.id);
            setSubscriberFirmNameWithResponsibleUser(
              record.name +
                "-" +
                (record.isHired === false
                  ? record.tenantSubscriberUsersVm
                      .filter((x) => x.isActive === true && x.isHirer === false)
                      .map((item) => item.subscriberUserVm.name)
                  : record.tenantSubscriberUsersVm
                      .filter((x) => x.isActive === true && x.isHirer === true)
                      .map((item) => item.subscriberUserVm.name))
            );
            setCollectionStatusVisible(true);
          }}
          icon={<MenuUnfoldOutlined />}
          type="link"
          style={{
            color: "#a0d911",
            fontSize: "130%",
            paddingTop: isTabletOrMobileDevice ? 2 : 5,
          }}
        ></Button>
      ),
    },
    {
      title: "Panel Bilgileri",
      dataIndex: "id",
      align: "center",
      key: "id",
      width: "7%",
      render: (text, record) =>
        record.isActive ? (
          <Link
            style={{ fontSize: "120%", color: "blue", paddingTop: 10 }}
            to={`/subscriberfirm-panel-info/${record.id}`}
          >
            <OrderedListOutlined />
          </Link>
        ) : (
          <Button
            onClick={() => {
              openNotificationWithIcon("warning");
            }}
            icon={<OrderedListOutlined />}
            type="link"
            style={{ fontSize: "120%", color: "blue", paddingTop: 7 }}
          ></Button>
        ),
    },
    {
      title: "Tahsilat Plan Girişi",
      dataIndex: "id",
      align: "center",
      key: "id",
      width: "8%",
      render: (text, record) => (
        <Button
          onClick={() => {
            if (record.isActive) {
              setMonthlyPlanAddSubscriberFirmId(record.id);
              setMonthlyPlanAddSubscriberFirmName(record.name);
              setMonthlyPlanAddVisible(true);
            } else {
              openNotificationWithIcon("warning");
            }
          }}
          icon={<AppstoreAddOutlined />}
          type="link"
          style={{ fontSize: "120%", color: "#9254de", paddingTop: 7 }}
        ></Button>
      ),
    },
    {
      title: "Güncelle",
      dataIndex: "operation",
      align: "center",
      width: "5%",
      render: (text, record) =>
        subscriberFirms.data.length >= 1 ? (
          record.isActive ? (
            <a
              onClick={() => {
                showDrawerUpdate(record.id);
              }}
              style={{
                color: "orange",
                fontSize: "130%",
                paddingBottom: "20px",
              }}
            >
              <EditOutlined />
            </a>
          ) : (
            <a
              onClick={() => {
                openNotificationWithIcon("warning");
              }}
              style={{
                color: "orange",
                fontSize: "130%",
                paddingBottom: "20px",
              }}
            >
              <EditOutlined />
            </a>
          )
        ) : null,
    },
    {
      title: "Durum",
      dataIndex: "operation",
      align: "center",
      render: (text, record) =>
        record.isActive ? (
          <Popconfirm
            title="Pasif yapmak istiyor musunuz?"
            okText="Evet"
            cancelText="Hayır"
            onConfirm={() => handleActivePassive(record.id)}
          >
            <a style={{ color: "green", fontSize: "130%" }}>
              <CheckCircleOutlined />
            </a>
          </Popconfirm>
        ) : (
          <Popconfirm
            title="Aktif yapmak istiyor musunuz?"
            okText="Evet"
            cancelText="Hayır"
            onConfirm={() => handleActivePassive(record.id)}
          >
            <a style={{ color: "red", fontSize: "130%" }}>
              <CloseCircleOutlined />
            </a>
          </Popconfirm>
        ),
    },
  ];

  return (
    <div>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> DAİRE LİSTESİ
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={() => setVisible(true)}>
            <PlusOutlined /> Daire Ekle
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["0"]}>
            <Panel header="Filtreleme için tıklayınız." key="1">
              <Form
                {...layout}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinishFilter}
              >
                <Row>
                  {isDesktopOrLaptop && (
                    <>
                      <Col span={6}>Daire Adı :</Col>
                      <Col span={6}>Apartman Adı</Col>
                      <Col span={6}>Ev Sahibi Adı Soyadı</Col>
                      <Col span={6}>Kiracı Adı Soyadı</Col>

                      <Col span={6}>
                        <Select
                          showSearch
                          name="subscriberFirmId"
                          onChange={handleSubscriberFirmId}
                          value={
                            subscriberFirmId === "" ? null : subscriberFirmId
                          }
                          style={{ width: "100%", marginBottom: "3%" }}
                          placeholder="En az 3 harf giriniz.."
                          optionFilterProp="children"
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLocaleLowerCase("tr-TR")
                              .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                          }
                        >
                          {subscriberFirmDropdown.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col span={6}>
                        <Input
                          name="filterAddress"
                          value={filterAddress}
                          onChange={handleFilterAdress}
                          style={{ width: "100%", marginBottom: "6%" }}
                          placeholder="Apartman Adı"
                        />
                      </Col>
                      <Col span={6}>
                        <Input
                          name="filterHomeOwner"
                          value={filterHomeOwner}
                          onChange={handleFilterHomeOwner}
                          style={{ width: "100%", marginBottom: "6%" }}
                          placeholder="Ev Sahibi Adı Soyadı"
                        />
                      </Col>
                      <Col span={6}>
                        <Input
                          name="filterHirer"
                          value={filterHirer}
                          onChange={handleFilterHirer}
                          style={{ width: "100%", marginBottom: "6%" }}
                          placeholder="Kiracı Adı Soyadı"
                        />
                      </Col>

                      <Col span={6}>Ev Sahibi/Kiracı T.C. Kimlik Numarası</Col>
                      <Col span={6}>Ev Sahibi/Kiracı Vergi Kimlik Numarası</Col>
                      <Col span={6}></Col>
                      <Col span={6}></Col>

                      <Col span={6}>
                        <Input
                          name="filterTckn"
                          value={filterTckn}
                          onChange={handleFilterTckn}
                          style={{ width: "100%", marginBottom: "6%" }}
                          placeholder="T.C. Kimlik Numarası"
                        />
                      </Col>
                      <Col span={6}>
                        <Input
                          name="filterVkn"
                          value={filterVkn}
                          onChange={handleFilterVkn}
                          style={{ width: "100%", marginBottom: "6%" }}
                          placeholder="Vergi Kimlik Numarası"
                        />
                      </Col>
                      <Col span={6}>
                        <Button
                          danger
                          size="small"
                          icon={<CloseOutlined />}
                          onClick={() => handleReset()}
                          style={{ width: "100%" }}
                        >
                          Sıfırla
                        </Button>
                      </Col>
                      <Col span={6}>
                        <Button
                          type=""
                          size="small"
                          icon={<SearchOutlined />}
                          style={{
                            color: "#4d98aa",
                            borderColor: "#4d98aa",
                            width: "100%",
                          }}
                          htmlType="submit"
                        >
                          Filtrele
                        </Button>
                      </Col>
                    </>
                  )}
                  {isTabletOrMobileDevice && (
                    <>
                      <Col span={12}>Daire Adı :</Col>
                      <Col span={12}>
                        <Select
                          showSearch
                          name="subscriberFirmId"
                          onChange={handleSubscriberFirmId}
                          value={
                            subscriberFirmId === "" ? null : subscriberFirmId
                          }
                          style={{ width: "100%", marginBottom: "3%" }}
                          placeholder="En az 3 harf giriniz.."
                          optionFilterProp="children"
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLocaleLowerCase("tr-TR")
                              .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                          }
                        >
                          {subscriberFirmDropdown.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      <Col span={12}> Apartman Adı</Col>
                      <Col span={12}>
                        <Input
                          name="filterAddress"
                          value={filterAddress}
                          onChange={handleFilterAdress}
                          style={{ width: "100%", marginBottom: "6%" }}
                          placeholder="Apartman Adı"
                        />
                      </Col>

                      <Col span={12}>Ev Sahibi Adı Soyadı</Col>
                      <Col span={12}>
                        <Input
                          name="filterHomeOwner"
                          value={filterHomeOwner}
                          onChange={handleFilterHomeOwner}
                          style={{ width: "100%", marginBottom: "6%" }}
                          placeholder="Ev Sahibi Adı Soyadı"
                        />
                      </Col>
                      <Col span={12}>Kiracı Adı Soyadı</Col>
                      <Col span={12}>
                        <Input
                          name="filterHirer"
                          value={filterHirer}
                          onChange={handleFilterHirer}
                          style={{ width: "100%", marginBottom: "6%" }}
                          placeholder="Kiracı Adı Soyadı"
                        />
                      </Col>
                      <Col span={12}>Ev Sahibi/Kiracı T.C. Kimlik Numarası</Col>
                      <Col span={12}>
                        <Input
                          name="filterTckn"
                          value={filterTckn}
                          onChange={handleFilterTckn}
                          style={{ width: "100%", marginBottom: "6%" }}
                          placeholder="T.C. Kimlik Numarası"
                        />
                      </Col>
                      <Col span={12}>
                        Ev Sahibi/Kiracı Vergi Kimlik Numarası
                      </Col>
                      <Col span={12}>
                        <Input
                          name="filterVkn"
                          value={filterVkn}
                          onChange={handleFilterVkn}
                          style={{ width: "100%", marginBottom: "6%" }}
                          placeholder="Vergi Kimlik Numarası"
                        />
                      </Col>
                      <Col span={12}>
                        <Button
                          danger
                          size="small"
                          style={{ marginTop: "5%", width: "100%" }}
                          icon={<CloseOutlined />}
                          onClick={() => handleReset()}
                        >
                          Sıfırla
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button
                          type=""
                          size="small"
                          icon={<SearchOutlined />}
                          style={{
                            color: "#4d98aa",
                            borderColor: "#4d98aa",
                            marginTop: "5%",
                            width: "100%",
                          }}
                          htmlType="submit"
                        >
                          Filtrele
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            </Panel>
          </Collapse>
          <br></br>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%" }}
            size="small"
            rowKey="id"
            filterable={true}
            sortable={true}
            expandable={{
              expandedRowRender: (record) => (
                <p>
                  <b>Ödeme Kodu : </b>{" "}
                  {record.paymentCode == null
                    ? "BELİRTİLMEDİ"
                    : record.paymentCode}
                  <br />
                  <b>Açıklama : </b>{" "}
                  {record.description == null
                    ? "BELİRTİLMEDİ"
                    : record.description}
                  <br />
                  <b>Ev Sahibi T.C. Kimlik Numarası : </b>{" "}
                  {record.homeOwnerTckn == null
                    ? "BELİRTİLMEDİ"
                    : record.homeOwnerTckn}
                  <br />
                  <b>Ev Sahibi Vergi Kimlik Numarası : </b>{" "}
                  {record.homeOwnerVkn == null
                    ? "BELİRTİLMEDİ"
                    : record.homeOwnerVkn}
                  <br />
                  <b>Kiracı T.C. Kimlik Numarası : </b>{" "}
                  {record.hirerTckn == null ? "BELİRTİLMEDİ" : record.hirerTckn}
                  <br />
                  <b>Kiracı Vergi Kimlik Numarası : </b>{" "}
                  {record.hirerVkn == null ? "BELİRTİLMEDİ" : record.hirerVkn}
                  <br />
                </p>
              ),
            }}
            pageable={true}
            onChange={paging}
            onDataStateChange={dataStateChange}
            pagination={{
              pageSizeOptions: ["20", "50", "100"],
              total: subscriberFirms.total,
              defaultPageSize: take,
              current: currentPage,
            }}
            dataSource={subscriberFirms.data}
            loading={subscriberFirms.loading}
          />
        </Col>
      </Row>

      <Modal
        style={{ display: "inline-flex" }}
        visible={isModalVisible}
        onCancel={handleCancel}
        cancelButtonProps={true}
        centered={true}
        keyboard={true}
        footer={[]}
        width={"unset"}
        destroyOnClose={true}
      >
        <SubscriberUserHistory firmId={modalSubcriberFirmId} />
      </Modal>
      <Modal
        visible={collectionStatusVisible}
        onCancel={onCloseCollectionStatus}
        centered={true}
        keyboard={true}
        footer={[]}
        width={900}
        destroyOnClose={true}
      >
        <SubscriberFirmCollectionStatus
          Id={collectionStatusSubscriberFirmId}
          SubscriberFirmNameWithResponsibleUser={
            subscriberFirmNameWithResponsibleUser
          }
        />
      </Modal>

      <Drawer
        title="Güncelle"
        width={241}
        onClose={onCloseUpdate}
        visible={visibleUpdate}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<div style={{ textAlign: "right" }}></div>}
        destroyOnClose={true}
      >
        <SubscriberFirmUpdate
          Id={subscriberFirmIdUpdate}
          Name={Name}
          Address={Address}
          IsHired={IsHired}
          Description={description}
          HomeOwnerId={homeOwnerId}
          HomeOwnerName={homeOwnerName}
          HomeOwnerPhone={homeOwnerPhone}
          HomeOwnerTckn={homeOwnerTckn}
          HomeOwnerVkn={homeOwnerVkn}
          HomeOwnerMail={homeOwnerMail}
          HirerId={hirerId}
          HirerName={hirerName}
          HirerPhone={hirerPhone}
          HirerTckn={hirerTckn}
          HirerVkn={hirerVkn}
          HirerMail={hirerMail}
        />
      </Drawer>

      <Drawer
        title="Tahsilat Plan Ekle"
        width={241}
        onClose={onCloseMonthlyPlanAdd}
        visible={monthlyPlanAddVisible}
        destroyOnClose={true}
        bodyStyle={{ paddingTop: 40 }}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <SubscriberFirmMonthlyPlanAdd
          SubscriberFirmId={monthlyPlanAddSubscriberFirmId}
          Name={monthlyPlanAddSubscriberFirmName}
        />
      </Drawer>

      <Drawer
        title="Daire Ekle"
        width={241}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <SubscriberFirmAddForm />
      </Drawer>
    </div>
  );
};

export default SubscriberFirmList;
