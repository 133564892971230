import React, { useState, useEffect } from 'react'
import { Input, Row, Form, Col, notification, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive'
import { useHistory } from "react-router-dom";
import config from "../../util/ApiforContext"
import axios from 'axios'
const expiration = localStorage.getItem("expiration");
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 11,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const MonthlyPlanTypeAdd = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const errorList = [];
  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload()
    }
    setName(name)
  }, [setName, name]);
  const onChange = (e) => {
    setName(e.target.value)
  }
  const errorListNotification = type => {
    errorList.map((item) => {
      notification[type]({
        message: 'Hata',
        description:
          item
        ,
        duration: 2,
      });
    })
  };
  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'Ekleme işlemi başarı ile gerçekleşti.',
      duration: 2,
    });
  };
  const openNotificationWithIconWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Ekleme işlemi sırasında bir hata oluştu.',
      duration: 2,
    });
  };
  const onFinish = values => {
    axios.post(`${config.apiUrl}/MonthlyPlanType`, {
      Name: values.Name,
    },
      config.headers)
      .then(res => {
        if (res.status == 200) {
          openNotificationWithIcon('success');
          setTimeout(function () {
            window.location.reload();
          }.bind(this), 2000)
        }
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          }
          else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
            setLoading(false)
          }
          else {
            openNotificationWithIconWarning('error')
            setLoading(false)
          }
        }
      );
  };

  const onFinishFailed = errorInfo => {
    setLoading(false)
  };
  return (
    <div>
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          {isDesktopOrLaptop && <>
            <Col span={24}>
              <Form.Item
                label="Tip Adı"
                style={{ marginTop: "4%" }}
                name="Name"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen Tip Adı alanını boş bırakmayın.',
                  },
                ]}
              >
                <Input value={name} onChange={onChange} />
              </Form.Item>

            </Col>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}>
              <Form.Item {...tailLayout} >
                <Button type="primary" icon={<PlusOutlined />} htmlType="submit" onClick={() => setLoading(true)} loading={loading}>
                  Ekle
                </Button>
              </Form.Item>
            </Col>
          </>}
          {isTabletOrMobileDevice && <>
            <Col span={24}>
              <Form.Item
                label="Tip Adı"
                style={{ marginTop: "4%" }}
                name="Name"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen Tip Adı alanını boş bırakmayın.',
                  },
                ]}
              >
                <Input value={name} onChange={onChange} />
              </Form.Item>

            </Col>
            <Col span={12}></Col>
            <Col span={6}>
              <Form.Item {...tailLayout} >
                <Button type="primary" icon={<PlusOutlined />} htmlType="submit" onClick={() => setLoading(true)} loading={loading}>
                  Ekle
                </Button>
              </Form.Item>
            </Col>
            <Col span={6}></Col>

          </>}

        </Row>
      </Form>
    </div>
  )
}
export default MonthlyPlanTypeAdd