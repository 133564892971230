import React, { useState, useEffect } from "react";

import RequestHandler from "util/RequestHandler";

import B2bFirmUserTableFilter from "../Filters/B2bFirmUserTableFilter";
import B2bFirmUserAddForm from "../Forms/B2bFirmUserAddForm";
import B2bFirmUserUpdateForm from "../Forms/B2bFirmUserUpdateForm";

import {
  Table,
  Empty,
  Col,
  Row,
  Button,
  Drawer,
  Popconfirm,
  Tag,
  Tooltip,
} from "antd";
import {
  BarsOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";

const FirmUser = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });
  const [addFormVisibility, setAddFormVisibility] = useState(false);
  const [updateFormVisibility, setFormPaymentVisibility] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({});

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  const getTableData = () => {
    const { skip, take, filter } = tableDataQueryOptions;

    setTableData({
      ...tableData,
      loading: true,
    });

    RequestHandler.get(
      `/TenantFirmUser?$count=true${filter}&$skip=${skip}&$top=${take}`
    ).then((response) => {
      setTableData({
        data: response.value,
        total: response["@odata.count"],
        loading: false,
      });
    });
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };

  const renderChecked = (data, row) => {
    return data ? (
      <CheckCircleOutlined style={{ color: "green", fontSize: "130%" }} />
    ) : (
      <CloseCircleOutlined style={{ color: "red", fontSize: "130%" }} />
    );
  };

  const onClickChangeStatus = (id, isActive) => {
    RequestHandler.post("/TenantFirmUser/ChangeStatus", {
      id,
      isActive: !isActive,
    }).then((response) => {
      if (response.success) {
        getTableData();
      }
    });
  };

  const handleOnClickUpdate = (record) => {
    RequestHandler.get(`/TenantFirmUser/GetById/${record.id}`).then(
      (response) => {
        if (response) {
          setUpdateFormData(response);
          setFormPaymentVisibility(true);
        }
      }
    );
  };

  const handleOnClickDelete = (id) => {
    RequestHandler.delete(`/TenantFirmUser/${id}`).then((response) => {
      if (response.success) {
        getTableData();
      }
    });
  };

  const onAddPaymentSuccess = () => {
    getTableData();
    setAddFormVisibility(false);
  };

  const onUpdatePaymentSuccess = () => {
    getTableData();
    setFormPaymentVisibility(false);
  };

  const sendCreatePasswordEmail = (tenantFirmUserId) => {
    RequestHandler.post(
      "/TenantFirmUser/SendCreatePasswordMail/" + tenantFirmUserId
    );
  };

  const columns = [
    {
      title: "Cari Kodu",
      dataIndex: "firmCode",
    },
    {
      title: "Cari Ünvanı",
      dataIndex: "firmName",
    },
    {
      title: "E-posta",
      dataIndex: "email",
    },
    {
      title: "Cari VKN / TCKN",
      dataIndex: "firmVknTckn",
      render: (data, row) => data ?? "-",
    },
    {
      title: "Telefon Numarası",
      dataIndex: "phoneNumber",
    },

    {
      title: "Ödeme Planı",
      dataIndex: "paymentPlan",
      align: "center",
      render: renderChecked,
    },
    {
      title: "Tahsilat Planı",
      dataIndex: "collectionPlan",
      align: "center",
      render: renderChecked,
    },
    {
      title: "Pos Tahsilatı",
      dataIndex: "posCollection",
      align: "center",
      render: renderChecked,
    },
    {
      title: "Stoklar",
      dataIndex: "stocks",
      align: "center",
      render: renderChecked,
    },
    {
      title: "Dbs Faturalar",
      dataIndex: "dbsInvoices",
      align: "center",
      render: renderChecked,
    },
    {
      title: "Fatura İskontolama",
      dataIndex: "invoiceDiscount",
      align: "center",
      render: renderChecked,
    },
    {
      title: "Erp Rapor",
      dataIndex: "erpReport",
      align: "center",
      render: renderChecked,
    },

    {
      title: "Şifre Durumu",
      dataIndex: "isPasswordCreated",
      align: "center",
      render: (isPasswordCreated, row, index) => {
        let color = isPasswordCreated ? "green" : "orange";
        let text = isPasswordCreated ? "Oluşturuldu" : "Bekliyor";
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "Şifre Maili",
      dataIndex: "id",
      align: "center",
      render: (data, row) =>
        row.isPasswordCreated ? null : (
          <Popconfirm
            title="Kullanıcıya tekrar şifrenizi oluşturun maili yollamak istiyor musunuz?"
            okText="Evet"
            cancelText="Hayır"
            onConfirm={(e) => sendCreatePasswordEmail(data)}
          >
            <Button type="link" style={{ marginBottom: 0 }}>
              <MailOutlined style={{ fontSize: "130%" }} />
            </Button>
          </Popconfirm>
        ),
    },
    {
      title: "Durum",
      dataIndex: "isActive",
      align: "center",
      render: (text, row) => {
        const { title, icon } = row.isActive
          ? {
              title: "Kullanıcıyı pasif etmek istiyor musunuz?",
              icon: (
                <Tooltip title="Pasif et">
                  <CheckCircleOutlined
                    style={{ color: "green", fontSize: "130%" }}
                  />
                </Tooltip>
              ),
            }
          : {
              title: "Kullanıcıyı aktif etmek istiyor musunuz?",
              icon: (
                <Tooltip title="Aktif Et">
                  <CloseCircleOutlined
                    style={{ color: "red", fontSize: "130%" }}
                  />
                </Tooltip>
              ),
            };
        return (
          <Popconfirm
            title={title}
            okText="Evet"
            cancelText="Hayır"
            onConfirm={() => onClickChangeStatus(row.id, row.isActive)}
          >
            <a>{icon}</a>
          </Popconfirm>
        );
      },
    },
    {
      title: "Düzenle",
      dataIndex: "id",
      align: "center",
      render: (data, row) => (
        <a onClick={() => handleOnClickUpdate(row)}>
          <EditOutlined style={{ color: "orange", fontSize: "130%" }} />
        </a>
      ),
    },
    {
      title: "Sil",
      dataIndex: "id",
      align: "center",
      render: (data, record) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleOnClickDelete(data)}
        >
          <a>
            <DeleteOutlined style={{ color: "red", fontSize: "130%" }} />
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> CARİ KULLANICI TANIMLARI
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={() => {
              setAddFormVisibility(true);
            }}
            style={{ marginBottom: 0 }}
          >
            <PlusOutlined /> Kullanıcı Ekle
          </Button>
        </Col>
      </Row>

      <B2bFirmUserTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Drawer
        id="addPaymentDrawer"
        title="Kullanıcı Tanımı Ekle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setAddFormVisibility(false)}
        visible={addFormVisibility}
      >
        <B2bFirmUserAddForm onSuccess={onAddPaymentSuccess} />
      </Drawer>

      <Drawer
        id="updatePaymentDrawer"
        title="Kullanıcı Tanımı Güncelle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setFormPaymentVisibility(false)}
        visible={updateFormVisibility}
      >
        <B2bFirmUserUpdateForm
          data={updateFormData}
          onSuccess={onUpdatePaymentSuccess}
        />
      </Drawer>
    </>
  );
};

export default FirmUser;
