import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Screen from '../../util/Extensions/Screens'
import {onNavStyleChange, toggleCollapsedSideNav} from "appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";


const SidebarLogo = () => {
  const dispatch = useDispatch();
  const {width, themeType, navCollapsed} = useSelector(({settings}) => settings);
  let navStyle = useSelector(({settings}) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header">

      {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) ? <div className="gx-linebar">

        <i
          className={`gx-icon-btn icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
          onClick={() => {
            if (navStyle === NAV_STYLE_DRAWER) {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            } else if (navStyle === NAV_STYLE_FIXED) {
              dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR))
            } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            } else {
              dispatch(onNavStyleChange(NAV_STYLE_FIXED))
            }
          }}
        />
      </div> : null}

      <Link to="/home" className="gx-site-logo">
        {
          Screen.screen === 0 ?
          (
            navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
              <img alt="" src=""/> :
              themeType === THEME_TYPE_LITE ?
                <img alt="" src={require("assets/images/adosoftlogo_white.png")}/> :
                <img alt="" style={{marginLeft: 36, width: 121}} src={require("assets/images/adosoftlogo_white.png")}/>
          )
          :
          (
            Screen.screen === 1 ? 
            (
              navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
                <img alt="" src=""/> :
                themeType === THEME_TYPE_LITE ?
                <img alt="paratic" title="imzapos" src={require("assets/images/imzaposLogoBeyaz.png")}/> :
                <img alt="paratic" title="imzapos" style={{marginLeft: 39, width: 121,marginTop:9}} src={require("assets/images/imzaposLogoBeyaz.png")}/>
            )
            :
            (
              navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
                <img alt="" src=""/> :
                themeType === THEME_TYPE_LITE ?
                <img alt="paratic" title="bakiyem" src={require("assets/images/logoBakiyemWhite.png")}/> :
                <img alt="paratic" title="bakiyem" style={{marginLeft: 39, width: 121,marginTop:9}} src={require("assets/images/logoBakiyemWhite.png")}/>
            )
          )
         
        }
       

      </Link>

    </div>
  );
};

export default SidebarLogo;
