import React from "react";
import Widget from "components/Widget";
import {Tabs,Row,Col } from 'antd';
import AllBankListTable from './AllBankListTable'
const TicketList =()=> {
    return (
    <AllBankListTable />
    );
}


export default TicketList;
