import React, { useState, useEffect } from "react";
import {
  Table,
  Empty,
  Form,
  DatePicker,
  Drawer,
  Select,
  Col,
  Row,
  Collapse,
  notification,
  Button,
  Typography
} from "antd";
import {
  SearchOutlined,
  CloseOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import config from "../../util/ApiforContext";
import CurrencyFormat from "react-currency-format";
import axios from "axios";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
function currencyFormat(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};
function disabledDate(current) {
  var d = new Date();
  var n = d.getFullYear();
  const start = moment(`${n - 2}-01-01`, "YYYY-MM-DD");
  const end = moment(`${n + 3}-01-01`, "YYYY-MM-DD");
  return current < start || current > end;
}
const expiration = localStorage.getItem("expiration");

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const FirmStatusReport = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  const [firmMonthlyStatusReport, setFirmMonthlyStatusReport] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [take, setTake] = useState(20);
  const [CollectTotal2, setCollectTotal2] = useState("");
  const [CollectPlanTotal2, setCollectPlanTotal2] = useState("");
  const [PaymentTotal2, setPaymentTotal2] = useState("");
  const [PaymentPlanTotal2, setPaymentPlanTotal2] = useState("");
  const [getFilterCurrency, setGetFilterCurrency] = useState([]);
  const [CollectTotal1, setCollectTotal1] = useState("");
  const [CollectPlanTotal1, setCollectPlanTotal1] = useState("");
  const [Currency, setCurrency] = useState("");
  const [PaymentTotal1, setPaymentTotal1] = useState("");
  const [PaymentPlanTotal1, setPaymentPlanTotal1] = useState("");
  const [skip, setSkip] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [TransactionDate, setTransactionDate] = useState("");
  const [TransactionDate2, setTransactionDate2] = useState("");
  const [visible, setVisible] = useState(false);
  const [firmId, setFirmId] = useState("");
  const [firmDropdown, setFirmDropdown] = useState([]);
  const [query, setQuery] = useState("");
  const [currencyString, setCurrencyString] = useState("");
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [dataState, setDataState] = useState({ skip: 0, take: 10 });

  const history = useHistory();
  const errorList = [];
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 2,
      });
    });
  };
  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }

    setFirmMonthlyStatusReport({
      ...firmMonthlyStatusReport,
      loading: true,
    });
    axios
      .post(
        `${config.apiUrl}/Reports/GetMonthlyStatusPlanReports`,
        {
          CurrencyCode: Currency === "" ? null : Currency,
          Date: TransactionDate === "" ? null : TransactionDate,
          EndDate: TransactionDate2 === "" ? null : TransactionDate2
        },
        config.headers
      )
      .then((res) => {
        var amountTotal = res.data[0]?.accountBalanceTotal.toLocaleString("tr-TR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        setCurrencyString("TRY")
        setTotalAmount(amountTotal);
        setFirmMonthlyStatusReport({
          data: res.data,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    axios
      .get(`${config.apiUrl}/currency/getfiltercurrency`, config.headers)
      .then((res) => {
        setGetFilterCurrency(res.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  }, [setDataState, skip, setSkip, query, setQuery]);

  const onSearch = (val) => {
    if (val.length >= 3) {
      axios
        .get(
          `${config.apiUrl}/Firm?$filter=contains(name, '${val}')`,
          config.headers
        )
        .then((res) => {
          setFirmDropdown(res.data.value);
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push("/not-authorized-access");
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            });
            errorListNotification("error");
          }
        });
    } else {
      setFirmDropdown([]);
    }
  };

  const paging = (p) => {
    setDataState((prevState) => {
      prevState.skip = (p.current - 1) * 20;
      setSkip(prevState.skip);
      setTake(p.pageSize);
      return {
        prevState,
      };
    });
  };

  const GoReportDetailFirmPageCollect = (value) => {
    history.push(`report-monthly-firm-details/${value}`);
  };

  const GoReportDetailFirmPagePayment = (value) => {
    history.push(`report-monthly-firm-details-payment/${value}`);
  };
  const handleTransactionDate = (value) => {
    setTransactionDate(value !== null ? value[0]?.format("MM/DD/YYYY") : null);
    setTransactionDate2(value !== null ? value[1]?.format("MM/DD/YYYY") : null);
  };
  const handleResets = (clearFilters) => {
    setSkip(0);
    setTake(20);
    setQuery(``);
  };
  const handleReset = (clearFilters) => {
    setSkip(0);
    setTake(20);
    setQuery("");
    setFirmId("");
    setCollectTotal1("");
    setCollectTotal2("");
    setCollectPlanTotal1("");
    setCollectPlanTotal2("");
    setPaymentTotal1("");
    setPaymentTotal2("");
    setTransactionDate("");
    setTransactionDate2("");
    setPaymentPlanTotal1("");
    setPaymentPlanTotal2("");
    setCurrency("");

    setFirmMonthlyStatusReport({
      ...firmMonthlyStatusReport,
      loading: true,
    });
    axios
      .post(
        `${config.apiUrl}/Reports/GetMonthlyStatusPlanReports`,
        {
          CurrencyCode: Currency === "" ? null : Currency,
          Date: TransactionDate === "" ? null : TransactionDate,
          EndDate: TransactionDate2 === "" ? null : TransactionDate2,
        },
        config.headers
      )
      .then((res) => {
        var amountTotal = res.data[0]?.accountBalanceTotal.toLocaleString("tr-TR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        setTotalAmount(amountTotal);
        setCurrencyString("TRY");
        setFirmMonthlyStatusReport({
          data: res.data,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };

  const onClose = () => {
    setVisibleUpdate(false);
    setVisible(false);
  };
  const handleCurrency = (value) => {
    setCurrency(value);
  };
  const onCloseUpdate = () => {
    setVisibleUpdate(false);
  };

  const onFinishForFilter = (values) => {
    {
      handleResets();

      setFirmMonthlyStatusReport({
        ...firmMonthlyStatusReport,
        loading: true,
      });
      axios
        .post(
          `${config.apiUrl}/Reports/GetMonthlyStatusPlanReports`,
          {
            CurrencyCode: Currency === "" ? null : Currency,
            Date: TransactionDate === "" ? null : TransactionDate,
            EndDate: TransactionDate2 === "" ? null : TransactionDate2,
          },
          config.headers
        )
        .then((res) => {
          var amountTotal = res.data[0]?.accountBalanceTotal.toLocaleString("tr-TR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })

          setCurrencyString(Currency === "" ? "TRY" : Currency)
          setTotalAmount(amountTotal);
          setFirmMonthlyStatusReport({
            data: res.data,
            total: res.data["@odata.count"],
            loading: false,
          });
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push("/not-authorized-access");
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            });
            errorListNotification("error");
          }
        });
    }
  };
  const columns = [
    {
      title: "Tarih",
      dataIndex: "transactionDate",
      key: "transactionDate",
      align: "center",
      width: "10%",
      render: (text, record) => {
        return text == null
          ? " - "
          : moment(
            text.split("/")[0] +
            (text.split("/")[1].length > 1
              ? text.split("/")[1]
              : "0" + text.split("/")[1]) +
            "13"
          ).format("MMM YYYY");
      },
    },
    {
      title: "Tahsilat Planı",
      dataIndex: "collectPlanTotal",
      key: "collectPlanTotal",
      align: "center",
      width: "10%",
      render: (text, row, index) => {
        if (text === null) {
          return "-";
        } else {
          if (text.toString().includes(".") === true) {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </div>
            );
          } else {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
                {",00"}
              </div>
            );
          }
        }
      },
    },
    {
      title: "Gerçekleşen Tahsilat",
      dataIndex: "collectTotal",
      key: "collectTotal",
      align: "center",
      width: "10%",
      render: (text, row, index) => {
        if (text === null) {
          return "-";
        } else {
          if (text.toString().includes(".") === true) {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </div>
            );
          } else {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
                {",00"}
              </div>
            );
          }
        }
      },
    },
    {
      title: "Kalan Tahsilat",
      dataIndex: "collectMounthTotal",
      key: "collectMounthTotal",
      align: "center",
      width: "10%",
      render: (text, row, index) => {
        if (text === null || text.toString().includes("-") === true) {
          return "-";
        } else {
          if (text.toString().includes(".") === true) {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </div>
            );
          } else {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
                {",00"}
              </div>
            );
          }
        }
      },
    },
    {
      title: "Ödeme Planı",
      dataIndex: "paymentPlanTotal",
      key: "paymentPlanTotal",
      align: "center",
      width: "10%",
      render: (text, row, index) => {
        if (text === null) {
          return "-";
        } else {
          if (text.toString().includes(".") === true) {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </div>
            );
          } else {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
                {",00"}
              </div>
            );
          }
        }
      },
    },
    {
      title: "Gerçekleşen Ödeme",
      dataIndex: "paymentTotal",
      key: "paymentTotal",
      align: "center",
      width: "10%",
      render: (text, row, index) => {
        if (text === null) {
          return "-";
        } else {
          if (text.toString().includes(".") === true) {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </div>
            );
          } else {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
                {",00"}
              </div>
            );
          }
        }
      },
    },
    {
      title: "Kalan Ödeme",
      dataIndex: "paymentMounthTotal",
      key: "paymentMounthTotal",
      align: "center",
      width: "10%",
      render: (text, row, index) => {
        if (text === null || text.toString().includes("-") === true) {
          return "-";
        } else {
          if (text.toString().includes(".") === true) {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </div>
            );
          } else {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
                {",00"}
              </div>
            );
          }
        }
      },
    },
    {
      title: "Tahmini Bakiye",
      dataIndex: "operation",
      width: "1%",
      align: "right",
      align: "center",
      render: (text, record) => {
        return record.accountBalanceMonthlyTotal === 0 ? "-" : <CurrencyFormat
          value={parseFloat(record.accountBalanceMonthlyTotal)}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
        />
      }
    },
    {
      title: "Tahsilat Detay",
      dataIndex: "operation",
      width: "1%",
      align: "right",
      align: "center",
      render: (text, record) =>
        firmMonthlyStatusReport.data.length >= 1 ? (
          <a
            onClick={() => {
              GoReportDetailFirmPageCollect(
                record.transactionDate.split("/")[0] +
                "-" +
                record.transactionDate.split("/")[1] +
                "/" +
                currencyString
              );
            }}
            style={{ fontSize: "130%" }}
          >
            <MenuUnfoldOutlined />
          </a>
        ) : null,
    },
    {
      title: "Ödeme Detay",
      dataIndex: "operation",
      width: "1%",
      align: "right",
      align: "center",
      render: (text, record) =>
        firmMonthlyStatusReport.data.length >= 1 ? (
          <a
            onClick={() => {
              GoReportDetailFirmPagePayment(
                record.transactionDate.split("/")[0] +
                "-" +
                record.transactionDate.split("/")[1] +
                "/" +
                currencyString
              );
            }}
            style={{ fontSize: "130%", color: "red" }}
          >
            <MenuUnfoldOutlined />
          </a>
        ) : null,
    },

  ];
  return (
    <div>
      <Row>
        <Col span={24} style={{ textAlign: 'end', fontSize: 19 }}>
          Güncel Bakiye : {totalAmount} {currencyString}

        </Col>
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]}>
            <Collapse.Panel
              showArrow={false}
              header="Filtreleme için tıklayınız."
              extra={<Typography>
                <Typography.Paragraph
                  style={{
                    marginBottom: 0,
                    fontSize: "90%",
                  }}
                  type="secondary"
                >
                  Varsayılan olarak bu aydan itibaren 12 ay ve TRY para birimi listelenmektedir.
                </Typography.Paragraph>
              </Typography>
              }
            >

              <Form
                {...layout}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinishForFilter}
              >
                <Row>
                  {isDesktopOrLaptop && (
                    <>
                      <Col span={6}>Tarih :</Col>
                      <Col span={6}>Para Birimi:</Col>
                      <Col span={6}></Col>
                      <Col span={6}></Col>

                      <Col span={6}>
                        <RangePicker
                          name="TransactionDate"
                          format="MM-YYYY"
                          picker="month"
                          disabledDate={disabledDate}
                          placeholder={["Başlangıç", "Bitiş"]}
                          onChange={handleTransactionDate}
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col span={6}>
                        <Select
                          name="Currency"
                          style={{ width: "100%" }}
                          placeholder="Para birimini seçiniz"
                          optionFilterProp="children"
                          onChange={handleCurrency}
                          value={Currency === "" ? null : Currency}
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLocaleLowerCase("tr-TR")
                              .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                          }
                        >
                          {getFilterCurrency.map((item) => (
                            <Option key={item.text}>{item.value}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col span={6}></Col>
                      <Col span={6}></Col>
                    </>
                  )}

                  {isTabletOrMobileDevice && (
                    <>
                      <Col span={12}>Tarih :</Col>

                      <Col span={12}>
                        <RangePicker
                          name="TransactionDate"
                          format="MM-YYYY"
                          picker="month"
                          disabledDate={disabledDate}
                          placeholder={["Başlangıç", "Bitiş"]}
                          onChange={handleTransactionDate}
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col span={12}>Para Birimi:</Col>
                      <Col span={12}>
                        <Select
                          name="Currency"
                          style={{ width: "100%" }}
                          placeholder="Para birimini seçiniz"
                          optionFilterProp="children"
                          onChange={handleCurrency}
                          value={Currency === "" ? null : Currency}
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLocaleLowerCase("tr-TR")
                              .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                          }
                        >
                          {getFilterCurrency.map((item) => (
                            <Option key={item.text}>{item.value}</Option>
                          ))}
                        </Select>
                      </Col>
                    </>
                  )}
                  <Col span={12}></Col>
                  <Col span={6} style={{ marginTop: "5%" }}>
                    <Button
                      danger
                      size="small"
                      icon={<CloseOutlined />}
                      onClick={() => handleReset()}
                      style={{ width: "100%" }}
                    >
                      Sıfırla
                    </Button>
                  </Col>
                  <Col span={6} style={{ marginTop: "5%" }}>
                    <Button
                      primary
                      size="small"
                      icon={<SearchOutlined />}
                      style={{
                        color: "#4d98aa",
                        borderColor: "#4d98aa",
                        width: "100%",
                      }}
                      htmlType="submit"
                    >
                      Filtrele
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Collapse.Panel>
          </Collapse>
          <br></br>
        </Col>
      </Row>
      <Drawer
        title="Rapor Detay"
        width={241}
        onClose={onCloseUpdate}
        visible={visibleUpdate}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<div style={{ textAlign: "right" }}></div>}
      ></Drawer>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%" }}
            size="small"
            rowKey="Id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              pageSizeOptions: ["15", "25", "50", "100"],
              defaultPageSize: take,
              total: firmMonthlyStatusReport.total,
            }}
            dataSource={firmMonthlyStatusReport.data}
            loading={firmMonthlyStatusReport.loading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default FirmStatusReport;
