import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form, 
  Input, 
  Button, 
  Select,
  Card,
  Tooltip
} from "antd";
import PhoneInput from "react-phone-input-2";
import {
  BarsOutlined,
  CopyOutlined,
  DownloadOutlined,
  SendOutlined
} from "@ant-design/icons";
import CurrencyInput from 'react-currency-input';
import RequestHandler from "util/RequestHandler";
import { rearg } from "lodash";
import Screen from '../../util/Extensions/Screens'
import config from '../../util/ApiforContext'

const PaymentRequest = (props) => {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [copyUrl,setCopyUrl] = useState("");
  const [amount,setAmount] = useState("");
  const [paymentGuid,setPaymentGuid] = useState("");
  const [amountString,setAmountString] = useState("");
  const [phoneNumber,setPhoneNumber] = useState("");
  const [email,setEmail] = useState("");
  const [description,setDescription] = useState("");
  const [installmentCount,setInstallmentCount] = useState("");
  const [convertedMoney,setConvertedMoney] = useState("");
  const [firmDropdown, setfirmDropdown] = useState([]);
  const [readyDisable,setReadyDisable] = useState(false);
  const [loading,setLoading] = useState(false);
  const [currency,setCurrency] = useState(1);
  const [activeProviderId, setActiveProviderId] = useState();

  const [availableProviders, setAvailableProviders] = useState({
    loading: true,
    data: [],
  });
  const [currencySelect, setCurrencySelect] = useState({
    data: [],
    loading: false,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });
  const { Option } = Select;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 24 },
  };

  
  useEffect(() => 
  {
    getTableData();
    getAvailableProviders();
  }, [tableDataQueryOptions]);

  const getTableData = () => {
    const { skip, take, filter } = tableDataQueryOptions;

    setTableData({
      ...tableData,
      loading: true,
    });
    RequestHandler.get(`/Currency?$select=id,code`).then((response) => {
        setCurrencySelect({
          data: response.value,
          loading: false,
        });
      });
  };
  const getAvailableProviders = () => {
    RequestHandler.get("/PosCollection/AvailableProviders").then((response) => {
      if (response.success) {
        setAvailableProviders({
          data: response.data,
          loading: false,
        });

        if (response.data?.length > 0) setActiveProviderId(response.data[0].id);

      } else {
        setAvailableProviders({ ...availableProviders, loading: false });
      }
    });
  }

  const onSearchs = (val) => {
    if(val.length >= 3){
      RequestHandler.get(`/Firm?isFilter=true&$filter=contains(name, '${val}')`).then((response) => {
        const firmDropdown = response.value;
        setfirmDropdown(firmDropdown)
      });
    }else{
      const firmDropdown = [];
      setfirmDropdown(firmDropdown)
    }
    }

    const onFocus = () => {
      RequestHandler.get(`/Firm?$filter=contains(name, '___')`).then((response) => {
        const firmDropdown = response.value;
        setfirmDropdown(firmDropdown)
      });
     }

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };

  const changeCurrencyValue = (value,currencyId) => {
      setAmount(value);
       RequestHandler.post(
         `/PaymentRequest/GetCurrencyValue`,
         {
           amount : value,
           currencyId: currencyId === undefined ? currency : currencyId
         },
         false
       ).then((response) => {
         setConvertedMoney(response.message)
       });
  }

  const onFinish = (values) => {
    setAmountString(values.money.Amount);
    setPhoneNumber(values.PhoneNumber);
    setEmail(values.EMail);
    setDescription(values.Description);
      RequestHandler.post(
        `/PaymentRequest`,
        {
          CurrencyId: currency,
          AmountString: values.money.Amount,
          InstallmentCount : installmentCount === "" ? 1 : installmentCount,
          PhoneNumber : values.PhoneNumber,
          BankId: values.bankId,
          FirmId: values.FirmId,
          EMail :values.EMail,
          Description: values.Description
        },
        true
      ).then((response) => {
        if(response.success){
          setPaymentGuid(response.message);
          setCopyUrl(config.frontEndUrl +"PaymentPage/"+ response.message)
          setReadyDisable(true)
        }
      });
  };

  const onGenderChange = (value) => {
    setInstallmentCount(value)
  };
  const sendMail = () => {
    RequestHandler.post(
      `/PaymentRequest/SendMail`,
      {
        PaymentGuidString :paymentGuid,
        EMail : email,
        ScreenOption: Screen.screen
      },
      true
    ).then((response) => {
      if(response.success){
        // setPaymentGuid(response);
        setReadyDisable(true)
        setLoading(false);
      }
    });
  };
  const onCurrencyChange = (value) => {
    setCurrency(value)
    if(amount != null && amount != "" && amount != undefined){
      changeCurrencyValue(amount,value);
    }
  };
  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> ÖDEME TALEBİ
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
        </Col>
        <Col xs={24} md={24} lg={24}>
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} fields={[
                    {name:"Currency",value:convertedMoney}
                ]}>
        <Row>
            <Col xs={24} md={12} lg={12}>
            
                <Card
                style={{ marginTop: 16 }}
                type="inner"
                title="Ödeme Bilgileri"
                >
                        <Form.Item
                          label="Ödeme Sağlayıcı"
                          name="bankId"
                          rules={[
                            {
                                required: true,
                                message: 'Lütfen Ödeme Sağlayıcı alanını boş bırakmayın.',
                            },
                            ]}
                      
                        >
                            <Select placeholder="Ödeme Sağlayıcı" disabled={readyDisable} onChange={value => setActiveProviderId(value)}>
                              {availableProviders.data?.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                        label="Cari Adı/Ünvanı"
                        style={{marginTop:"4%"}}
                        name="FirmId"
                       
                        >
                            <Select
                            showSearch
                            placeholder="En az 3 harf giriniz.."
                            optionFilterProp="children"
                            onFocus={onFocus}
                            disabled={readyDisable}
                            onSearch={onSearchs}
                            filterOption={(input, option) =>
                              option.props.children
                              .toLocaleLowerCase("tr-TR")
                              .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                            }
                            >
                            {firmDropdown.map(item => (
                            <Option key={item.id} value={item.id}>{item.name}</Option>
                            ))}
                            </Select>
                        </Form.Item>
                        
                        <Form.Item name="Moneys" label="Tutar" >
                        <Input.Group compact>
                        <Form.Item
                            name={['money', 'Amount']}
                            noStyle
                            rules={[{ required: true,message:"Tutar gerekli bir alan"}]}
                        >
                           <CurrencyInput onChange={(value) => changeCurrencyValue(value)} style={{width:"80%"}} className="ant-input" decimalSeparator="," thousandSeparator="." disabled={readyDisable}/>
                        </Form.Item>
                        <Form.Item
                            name={['money', 'Currency']}
                            noStyle
                        >
                             <Select
                                placeholder="TRY"
                                style={{width:'20%'}}
                                defaultValue={1}
                                disabled={readyDisable}
                                onChange={onCurrencyChange}
                                allowClear
                                loading={currencySelect.loading}
                                >
                                {currencySelect.data.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                    {item.code}
                                    </Select.Option>
                                ))}
                                </Select>
                        </Form.Item>
                        </Input.Group>
                        </Form.Item>
                        <Form.Item name="InstallmentCount" label="Taksit" >
                            <Select
                            placeholder="Taksit"
                            disabled={readyDisable}
                            onChange={onGenderChange}
                            defaultValue={1}
                            allowClear
                            >
                            <Option value={1}>Tek Çekim</Option>
                            <Option value={2}>2 Taksit</Option>
                            <Option value={3}>3 Taksit</Option>
                            <Option value={4}>4 Taksit</Option>
                            <Option value={5}>5 Taksit</Option>
                            <Option value={6}>6 Taksit</Option>
                            <Option value={7}>7 Taksit</Option>
                            <Option value={8}>8 Taksit</Option>
                            <Option value={9}>9 Taksit</Option>
                            <Option value={10}>10 Taksit</Option>
                            <Option value={11}>11 Taksit</Option>
                            <Option value={12}>12 Taksit</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="Currency" label="TL Karşılığı">
                            <Input placeholder="TL Karşılığı" defaultValue={convertedMoney} value={convertedMoney} disabled />
                        </Form.Item>
                </Card>
            </Col>
            <Col xs={24} md={12} lg={12}>
            <Card
                style={{ marginTop: 16 }}
                type="inner"
                title="İletişim Bilgileri"
                >
                    <Form.Item
                      label="Telefon Numarası"
                      name="PhoneNumber"
                      rules={[
                        {
                          min: 12,
                          message: "Telefon numarasını eksiksiz giriniz.",
                        },
                      ]}
                    >
                      <PhoneInput
                      disabled={readyDisable}
                        inputStyle={{ width: "100%" }}
                        country="tr"
                        masks={{ tr: "(...) ...-..-.." }}
                      />
                    </Form.Item>
                    {/* <Form.Item name="PhoneNumber" label="Telefon Numarası">
                        <Input placeholder="Telefon Numarası"/>
                    </Form.Item> */}
                    <Form.Item name="EMail" label="EMail" rules={[{ required: true }]}>
                        <Input placeholder="EMail" disabled={readyDisable}/>
                    </Form.Item>
                    <Form.Item name="Link" label="Link">
                    <Input.Group compact>
                        <Input 
                            placeholder="Link"
                            onChange={(text) => {setCopyUrl(text.target.value)}}
                            style={{ width: 'calc(100% - 36px)' }}
                            value = {copyUrl}
                        />
                        <Tooltip title="Linki kopyala">
                            <Button icon={<CopyOutlined />} onClick={() =>  navigator.clipboard.writeText(copyUrl)} />
                        </Tooltip>
                        </Input.Group>
                    </Form.Item>
                    <Form.Item name="Description" label="Açıklama" style={{marginTop:-16}} >
                        <Input placeholder="Açıklama" disabled={readyDisable}/>
                    </Form.Item>
                </Card>
                
            </Col>
            <Form.Item {...tailLayout} style={{textAlign:'start',marginTop:10}}>
                        <Button type="primary" disabled={readyDisable} htmlType="submit" icon={<DownloadOutlined />}>
                        Ödemeyi Hazırla
                        </Button>
                    </Form.Item>
            <Form.Item {...tailLayout} style={{textAlign:'start',marginTop:10}}>
                <Button type="primary" onClick={() => {sendMail(); setLoading(true);}} icon={<SendOutlined />} loading={loading} disabled={!readyDisable}>
                Mail ile Gönder
                </Button>
            </Form.Item>
        </Row>
        </Form>
        </Col>
      </Row>

    </>
  );
};

export default PaymentRequest;
