import React, { useState,useEffect } from 'react'
import {useHistory} from "react-router-dom";
import { Input,Select,Col,Row,notification,Form,Button} from 'antd';
import { useMediaQuery } from 'react-responsive'
import { PlusOutlined } from '@ant-design/icons';
import config from "../../util/ApiforContext"
import axios from 'axios';
const expiration = localStorage.getItem("expiration");
const { Option } = Select;
const tailLayout = {
    wrapperCol: {
      offset: 15,
      span: 5,
    },
  };
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};
const FirmClassUpdate = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
    const [loading, setLoading] = useState(false);
    const [firmClassId, setfirmClassId] = useState(props.firmClassId);
  const history = useHistory();
  const [Name, setName] = useState(props.name);
  const [serialNumber, setSerialNumber] = useState(props.serialNumber);
  const [erpValue, setErpValue] = useState(props.erpValue);
    const [FirmTypeValue, setFirmTypeValue] = useState(props.firmTypeValue);
    const [firmTypeDropdown, setfirmTypeDropdown] = useState([]);
    const errorList = [];
    const errorListNotification = type => {
      errorList.map((item)=> {
        notification[type]({
          message: 'Hata',
          description:
          item
           ,
            duration: 2,
        });
      })
    };
    useEffect(() => {
        if(expiration<Date.now()){
          localStorage.removeItem("expiration");
          localStorage.removeItem("token");
          window.location.reload();
        }
        if(localStorage.getItem("token")===null){
          window.location.reload()
        }
        axios.get(`${config.apiUrl}/Firm/GetFirmType`,config.headers)
        .then(res => {
          const firmTypeDropdown = res.data.data;
        setfirmTypeDropdown(firmTypeDropdown)
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }else if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
            }
          }
      );
      setfirmClassId(props.firmClassId)
        setName(props.name)
        setSerialNumber(props.serialNumber)
        setErpValue(props.erpValue)
        setFirmTypeValue(props.firmTypeValue)
      },[setfirmTypeDropdown,setfirmClassId,setFirmTypeValue,setName,setSerialNumber,setErpValue,props.serialNumber,props.erpValue,props.name,props.firmTypeValue,props.firmTypeDropdown,props.firmClassId]);

      const onChange = (e) => {
        setName(e.target.value)
      }
      const onChangeSerialNumber = (e) => {
        setSerialNumber(e.target.value)
      }
      const onChangeErpValue = (e) => {
        setErpValue(e.target.value)
      }
      const onChangeFirmValue = (e) => {
        setFirmTypeValue(e)
      }
    const onFinishUpdate = values => {
        axios.put(`${config.apiUrl}/FirmClass`,
         { Id:firmClassId,
          Name:values.Name,
          SerialNumber:values.SerialNumber,
          ErpValue:values.ErpValue,
          FirmTypeValue:values.FirmTypeValue},
         config.headers)
          .then(res => {
            if(res.status===200){
                openNotificationWithIconUpdate('success');
                setTimeout(function () {
                  props.sendDataForUpdate("success");
                  setLoading(false)
                 }.bind(this), 2000)
                 }
             }).catch(
              error =>  {
                    if(error.response.status===403 || error.response.status ===401){
                      history.push('/not-authorized-access');
                    }
                else if(error.response.status === 406){
                  error.response.data.data.map((item)=>{
                    errorList.push(item);
                  })
                  errorListNotification('error')
                  setLoading(false)
                }
                else{
                  openNotificationWithIconWarningUpdate('error')
                  setLoading(false)
                }
              }
          );
     };
     const onFinishFailedUpdate = errorInfo => {
      setLoading(false)
     };
     const openNotificationWithIconUpdate = type => {
        notification[type]({
          message: 'BAŞARILI',
          description:
            'Güncelleme işlemi başarı ile gerçekleşti.',
            duration: 2,
        });
     };
     const openNotificationWithIconWarningUpdate = type => {
        notification[type]({
          message: 'HATA',
          description:
            'Güncelleme işlemi sırasında bir hata oluştu.',
            duration: 2,
        });
     };
        return (
            <div>
                  <Form
                        {...layout}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinishUpdate}
                        onFinishFailed={onFinishFailedUpdate}
                        fields={[
                        {name:"Name",value:Name},
                        {name:"ErpValue",value:erpValue},
                        {name:"SerialNumber",value:serialNumber},
                        {name:"FirmTypeValue",value:FirmTypeValue.toString()},
                        ]}
                        >
                <Row>
                {isDesktopOrLaptop && <>
                  <Col span={5}></Col>
                    <Col span={13}>
                    <Form.Item
                    label="Cari Sınıfı Adı"
                    style={{marginTop:"4%"}}
                    name="Name"
                    rules={[
                        {
                        required: true,
                        message: 'Lütfen Cari Adı/Ünvanı alanını boş bırakmayın.',
                        },
                    ]}
                    >
                    <Input value={Name} onChange={onChange} />
                    </Form.Item>
                    <Form.Item
                        label="Cari Tipi"
                        name="FirmTypeValue"
                        rules={[
                        {
                            required: true,
                            message: 'Lütfen Cari Tipi alanını boş bırakmayın.',
                        },
                        ]}
                    >
                        <Select
                        placeholder="Seçiniz"
                        onChange={onChangeFirmValue}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                        }
                        >
                        {firmTypeDropdown.map(item => (
                        <Option key={item.key}>{item.value}</Option>
                        ))}
                        </Select>
                    </Form.Item>
                      <Form.Item
                      label="Seri No"
                      name="SerialNumber"
                      style={{marginTop:"4%"}}
                      >
                      <Input maxLength={20} value={serialNumber} onChange={onChangeSerialNumber}/>
                      </Form.Item>  
                      <Form.Item
                      label="Erp Kod"
                      name="ErpValue"
                      style={{marginTop:"4%"}}
                      >
                      <Input maxLength={100} value={erpValue} onChange={onChangeErpValue} />
                      </Form.Item>  
                    </Col>
                 <Col span={6}></Col>
                    <Col span={6}></Col>
                    <Col span={9}></Col>
                    <Col span={7}>
                    <Form.Item {...tailLayout} >
                    <Button type="primary" icon={<PlusOutlined />} htmlType="submit"  onClick={() => setLoading(true)} loading={loading}>
                        Güncelle
                    </Button>
                    </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                </>}
                {isTabletOrMobileDevice && <>
                    <Col span={24}>
                    <Form.Item
                    label="Cari Sınıfı Adı"
                    style={{marginTop:"4%"}}
                    name="Name"
                    rules={[
                        {
                        required: true,
                        message: 'Lütfen Cari Adı/Ünvanı alanını boş bırakmayın.',
                        },
                    ]}
                    >
                    <Input value={Name} onChange={onChange} />
                    </Form.Item>
                    <Form.Item
                        label="Cari Tipi"
                        name="FirmTypeValue"
                        rules={[
                        {
                            required: true,
                            message: 'Lütfen Cari Tipi alanını boş bırakmayın.',
                        },
                        ]}
                    >
                        <Select
                        placeholder="Seçiniz"
                        onChange={onChangeFirmValue}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                        }
                        >
                        {firmTypeDropdown.map(item => (
                        <Option key={item.key}>{item.value}</Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                      label="Seri No"
                      name="SerialNumber"
                      style={{marginTop:"4%"}}
                      >
                      <Input maxLength={20} value={serialNumber} onChange={onChangeSerialNumber}/>
                      </Form.Item>  
                      <Form.Item
                      label="Erp Kod"
                      name="ErpValue"
                      style={{marginTop:"4%"}}
                      >
                      <Input maxLength={100} value={erpValue} onChange={onChangeErpValue} />
                      </Form.Item>  
                    </Col>
                <Col span={10}></Col>
                    <Col span={8}>
                    <Form.Item {...tailLayout} >
                    <Button type="primary" icon={<PlusOutlined />} htmlType="submit"  onClick={() => setLoading(true)} loading={loading}>
                        Güncelle
                    </Button>
                    </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                </>}
              
                </Row>
                    </Form>
            </div>
        )
}
export default FirmClassUpdate