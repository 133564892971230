import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, InputNumber } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages, onInputMaxLengthCheck } from "./Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const PosBankInfoUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [activeBankId, setActiveBankId] = useState();
  const [bankRequirement, setBankRequirement] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setActiveBankId(props.data.bankId);
    }
  }, [props.data]);

  useEffect(() => {
    if (activeBankId) getBankInfoRequirements(activeBankId);
  }, [activeBankId]);

  const getBankInfoRequirements = (bankId) => {
    RequestHandler.get(
      `/PosBankInfoRequirement?$filter=bankId eq ${bankId}`
    ).then((response) => {
      if (Array.isArray(response.value)) {
        setBankRequirement(response.value[0]);
      }
    });
  };

  const onFinish = (values) => {
    RequestHandler.put(`/PosBankInfo`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        form.resetFields();

        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onFinishFailed = () => setLoading(false);

  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="posBankInfoUpdate"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="bankId" hidden>
            <Input />
          </Form.Item>

          {bankRequirement && (
            <>
              {bankRequirement.isCustomerNumberRequired && (
                <Form.Item
                  label={
                    bankRequirement.customerNumberLabel
                      ? bankRequirement.customerNumberLabel
                      : "Müşteri Numarası"
                  }
                  name="customerNumber"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input maxLength="125" onInput={onInputMaxLengthCheck} />
                </Form.Item>
              )}

              {(bankRequirement.isHostRequired ||
                bankRequirement.isPortRequired) && (
                  <Form.Item style={{ marginBottom: 0 }} label="Host" required>
                    <Input.Group>
                      <Row gutter={2}>
                        <Col span={18}>
                          {bankRequirement.isHostRequired && (
                            <Form.Item
                              name="host"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "'Host' alanı gerekli.",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          )}
                        </Col>
                        <Col span={6}>
                          {bankRequirement.isPortRequired && (
                            <Form.Item
                              name="port"
                              rules={[
                                {
                                  required: true,
                                  message: "'Port' alanı gerekli.",
                                },
                              ]}
                            >
                              <InputNumber
                                placeholder="Port"
                                min={0}
                                max={65535}
                                precision={0}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          )}
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                )}

              {bankRequirement.isFilePathRequired && (
                <Form.Item
                  label={
                    bankRequirement.filePathLabel
                      ? bankRequirement.filePathLabel
                      : "Dosya Yolu"
                  }
                  name="filePath"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              {bankRequirement.isUserNameRequired && (
                <Form.Item
                  label={
                    bankRequirement.userNameLabel
                      ? bankRequirement.userNameLabel
                      : "Kullanıcı Adı"
                  }
                  name="userName"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              {bankRequirement.isPasswordRequired && (
                <Form.Item
                  label="Şifre"
                  name="password"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
              )}
            </>
          )}

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Güncelle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default PosBankInfoUpdateForm;
