import React,  {useState,useEffect} from 'react'
import {Input,Col,Row,Form,Checkbox,notification,Select, Button} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive'
import {useHistory} from "react-router-dom";
import config from "../../util/ApiforContext"
import axios from 'axios'
const expiration = localStorage.getItem("expiration");
const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 11,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
 
const FirmAccountUpdate = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
    
    const onFocus = () => {
        axios.get(`${config.apiUrl}/Firm?$filter=contains(name, '___')`,config.headers)
            .then(res => {
                const firmDropdown = res.data.value;
                setfirmDropdown(firmDropdown);
            }).catch(
                error =>  {
                  if(error.response.status===403 || error.response.status ===401){
                    history.push('/not-authorized-access');
                  }else if(error.response.status === 406){
                    error.response.data.data.map((item)=>{
                      errorList.push(item);
                    })
                    errorListNotification('error')
                  }
                }
            );
      }
    const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [firmId, setfirmId] = useState(props.firmId);
  const [bankBranchCode, setbankBranchCode] = useState("");
    const [bankId, setbankId] = useState(props.bankId);
    const [iban, setiban] = useState(props.iban);
    const [bankBranchName, setbankBranchName] = useState(props.bankBranchName);
    const [accountNumber, setaccountNumber] = useState(props.accountNumber);
    const [isDefault, setisDefault] = useState(props.isDefault);
    const [firmDropdown, setfirmDropdown] = useState([]);
    const [bankDropdown, setbankDropdown] = useState([]);
  const errorList = [];

    const [bankBranchDropdownUpdate, setbankBranchDropdownUpdate] = useState([]);
    useEffect(() => {
      if(expiration<Date.now()){
        localStorage.removeItem("expiration");
        localStorage.removeItem("token");
        window.location.reload();
      }
      if(localStorage.getItem("token")==null){
        window.location.reload()
      }

        axios.get(`${config.apiUrl}/firm`,config.headers)
        .then(res => {
          const firmDropdown = res.data.value;
          setfirmDropdown(firmDropdown);
        }).catch(
            error =>  {
              if(error.response.status===403 || error.response.status ===401){
                history.push('/not-authorized-access');
              }else if(error.response.status === 406){
                error.response.data.data.map((item)=>{
                  errorList.push(item);
                })
                errorListNotification('error')
              }
            }
        );
        axios.get(`${config.apiUrl}/bank`,config.headers)
      .then(res => {
        const bankDropdown = res.data.value.filter(x=> x.isEmoney !== true && x.id !== 999);
        setbankDropdown(bankDropdown);
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }else if(error.response.status === 406){
            error.response.data.data.map((item)=>{
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
    );
    if(props.AccountId === undefined){
      setbankId(props.bankId)
      axios.get(`${config.apiUrl}/bankbranch/getbranchforbank?id=${props.bankId}`,config.headers)
      .then(res => {
         const bankBranchDropdownUpdate = res.data;
         setbankBranchDropdownUpdate(bankBranchDropdownUpdate)
      }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }else if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
            }
          }
      );
        }else{
          axios.get(`${config.apiUrl}/Bank/GetBankIdWithBankCode/${props.bankId}`,config.headers)
          .then(res => {
            setbankId(res.data.toString());
            axios.get(`${config.apiUrl}/bankbranch/getbranchforbank?id=${res.data}`,config.headers)
            .then(res => {
               const bankBranchDropdownUpdate = res.data;
               setbankBranchDropdownUpdate(bankBranchDropdownUpdate)
            }).catch(
                error =>  {
                  if(error.response.status===403 || error.response.status ===401){
                    history.push('/not-authorized-access');
                  }else if(error.response.status === 406){
                    error.response.data.data.map((item)=>{
                      errorList.push(item);
                    })
                    errorListNotification('error')
                  }
                }
            );
          }).catch(
              error =>  {
                if(error.response.status===403 || error.response.status ===401){
                  history.push('/not-authorized-access');
                }else if(error.response.status === 406){
                  error.response.data.data.map((item)=>{
                    errorList.push(item);
                  })
                  errorListNotification('error')
                }
              }
          );
        }
     if(props.AccountId !== undefined){
       axios.get(`${config.apiUrl}/BankBranch/GetNameWithCode?Code=${props.bankBranchCode}&BankId=${props.bankId}`,config.headers)
       .then(res => {
        setbankBranchName(res.data.data?.name);
        setbankBranchCode(res.data.data?.id);
       }).catch(
           error =>  {
             if(error.response?.status===403 || error.response?.status ===401){
               history.push('/not-authorized-access');
             }else if(error.response?.status === 406){
               error.response.data.data.map((item)=>{
                 errorList.push(item);
              })
               errorListNotification('error')
             }
           }
       );
     }else{
      setbankBranchName(props.bankBranchName)
     }
    
      setfirmId(props.firmId)
      setaccountNumber(props.accountNumber)
      setiban(props.iban)
      setisDefault(props.isDefault)
      },[setbankDropdown,setbankId,setbankBranchName,setfirmId,setfirmDropdown,setaccountNumber,setiban,setisDefault
        ,props.accountNumber,props.firmDropdown,props.firmId,props.bankId,props.bankDropdown,props.bankBranchName,props.iban,props.isDefault]
        );
        
    const openNotificationWithIconUpdate = type => {
        notification[type]({
          message: 'BAŞARILI',
          description:
            'Güncelleme işlemi başarı ile gerçekleşti.',
            duration: 2,
        });
      };
    const openNotificationWithIconWarningUpdate = type => {
        notification[type]({
          message: 'HATA',
          description:
            'Güncelleme işlemi sırasında bir hata oluştu.',
            duration: 2,
        });
    };
    const errorListNotification = type => {
        errorList.map((item)=> {
          notification[type]({
            message: 'Hata',
            description:
            item
             ,
              duration: 2,
          });
        })
      };
      const openNotificationWithIcon = type => {
        notification[type]({
          message: 'BAŞARILI',
          description:
            'Ekleme işlemi başarı ile gerçekleşti.',
            duration: 2,
        });
      };
      const ibanError = type => {
        notification[type]({
          message: 'HATA',
          description:
            'Lütfen doğru bir Iban girin',
            duration: 2,
        });
    };
      const openNotificationWithIconWarning = type => {
        notification[type]({
          message: 'HATA',
          description:
            'Ekleme işlemi sırasında bir hata oluştu.',
            duration: 2,
        });
      };
      const sameAccount = type => {
        notification[type]({
          message: 'HATA',
          description:
            'Kayıtlı olan hesabı tekrar ekleyemezsiniz.',
            duration: 2,
        });
      };
      const onFinishAdd = values => {
        axios.post(`${config.apiUrl}/FirmAccount`, { 
          BankId:values.BankId,
          FirmId:values.FirmId,
          BankBranchId: bankBranchCode === '' ? bankBranchName : bankBranchCode,
          BankBranchName:values.BankBranchId,
          AccountNumber:values.AccountNumber,
          Iban:values.Iban,
          IsDefault: values.IsDefault === null || values.IsDefault == undefined ? false : values.IsDefault
        },
        config.headers)
        .then(res => {
           if(res.status===200){
               openNotificationWithIcon('success');
               setTimeout(function () {
                  window.location.reload();
                }.bind(this), 2000)
                }
            }).catch(
             error =>  {
                     if(error.response.status===403 || error.response.status ===401){
                       history.push('/not-authorized-access');
                     }else if(error.response.status === 406){
                       error.response.data.data.map((item)=>{
                         errorList.push(item);
                       })
                       errorListNotification('error')
                       setLoading(false)
                     }
                     else if(error.response.data.message==="IbanError"){
                   ibanError('error')
                   setLoading(false)
               }else if(error.response.data.message==="SameAccount"){
                   sameAccount('error')
                   setLoading(false)
               }
               else{
                   openNotificationWithIconWarning('error')
                   setLoading(false)
               }
               
           }
         );
      }
    const onFinishUpdate = values => {
        axios.put(`${config.apiUrl}/FirmAccount`,
         { 
          Id:props.firmAccountId,
          BankId:values.BankId,
          FirmId:values.FirmId,
          BankBranchId:values.BankBranchId.toString(),
          BankBranchName:values.BankBranchId,
          AccountNumber:values.AccountNumber,
          Iban:values.Iban,
          IsDefault:values.IsDefault
          },
          config.headers)
          .then(res => {
                if(res.status==200){
                    openNotificationWithIconUpdate('success');
                    setTimeout(function () {
                        props.sendDataForUpdate("success");
                        setLoading(false)
                     }.bind(this), 2000)
                     }
             }).catch(
              error =>  {
                      if(error.response.status===403 || error.response.status ===401){
                        history.push('/not-authorized-access');
                      }
                      else if(error.response.status === 406){
                        error.response.data.data.map((item)=>{
                          errorList.push(item);
                        })
                        errorListNotification('error')
                        setLoading(false)
                      }
                 else{
                      openNotificationWithIconWarningUpdate('error')
                      setLoading(false)
                  }
                  
              }
          );
    };
    const onFinishFailedUpdate = errorInfo => {
        setLoading(false)
    };
    const handleChecked = (e) => {
        setisDefault(e.target.checked)
    }
    const handleFirmId = value => {
        setfirmId(value)
      };
     const onSearchs = (val) => {
        if(val.length >= 3){
          axios.get(`${config.apiUrl}/Firm?isFilter=true&$filter=contains(name, '${val}')`,config.headers)
          .then(res => {
             const firmDropdown = res.data.value;
             setfirmDropdown(firmDropdown)
          }).catch(
            error =>  {
              if(error.response.status===403 || error.response.status ===401){
                history.push('/not-authorized-access');
              }else if(error.response.status === 406){
                error.response.data.data.map((item)=>{
                  errorList.push(item);
                })
                errorListNotification('error')
              }
            }
        );
        }else{
          const firmDropdown = [];
          setfirmDropdown(firmDropdown)
        }
        }
    const  onChange = (value) => {
        axios.get(`${config.apiUrl}/bankbranch/getbranchforbank?id=${value}`,config.headers)
        .then(res => {
           const bankBranchDropdownUpdate = res.data;
           setbankBranchDropdownUpdate(bankBranchDropdownUpdate)
           setbankId(value)
           setbankBranchName('')
        }).catch(
            error =>  {
              if(error.response.status===403 || error.response.status ===401){
                history.push('/not-authorized-access');
              }else if(error.response.status === 406){
                error.response.data.data.map((item)=>{
                  errorList.push(item);
                })
                errorListNotification('error')
              }
            }
        );
        
   }
    const handleAccountNumber = value => {
        setaccountNumber(value.target.value)
    };
    const handleBankBranchId = value => {
      setbankBranchCode("")
        setbankBranchName(value)
    };
    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
        }
    const handleIban = value => {
        setiban(value.target.value)
    };
    const { Option } = Select;
        return (
            <div>
                 <Form
                {...layout}
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={props.AccountId !== undefined ? onFinishAdd : onFinishUpdate}
                onFinishFailed={onFinishFailedUpdate}
                fields={[
                    {name:"FirmId",value:firmId},
                    {name:"BankId",value:bankId},
                    {name:"Iban",value:iban},
                    {name:"BankBranchId",value:bankBranchName},
                    {name:"AccountNumber",value:accountNumber},
                    {name:"IsDefault",value:isDefault},
                ]}
                >
                <Row>
              {isDesktopOrLaptop && <>
                <Col span={24}>
            <Form.Item
                label="Cari Adı/Ünvanı"
                name="FirmId"
                style={{marginTop:"4%"}}
                rules={[
                {
                    required: true,
                    message: 'Lütfen Cari Adı/Ünvanı tipi alanını boş bırakmayın.',
                },
                ]}
            >
                <Select
                showSearch
                placeholder="En az 3 harf giriniz.."
                optionFilterProp="children"
                onFocus={onFocus}
                value={firmId} 
                onChange={handleFirmId}
                onSearch={onSearchs}
                filterOption={(input, option) =>
                  option.props.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
                >
                {firmDropdown.map(item => (
                <Option key={item.id}>{item.name}</Option>
                ))}
                </Select>
            </Form.Item>
            
            <Form.Item
                label="Banka"
                name="BankId"
                rules={[
                {
                    required: true,
                    message: 'Lütfen banka alanını boş bırakmayın.',
                },
                ]}
            >
                <Select
                showSearch
                placeholder="Seçiniz."
                optionFilterProp="children"
                onChange={onChange}
                filterOption={(input, option) =>
                  option.props.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
                >
                {bankDropdown.map(item => (
                    item.Name === "KASA HESABI" ? "" :
                    <Option key={item.id}>{item.name}</Option>
                ))}
                </Select>
            </Form.Item>
            
            <Form.Item
                label="IBAN"
                name="Iban"
                rules={
                  accountNumber?.length <5 ?
                  [
                {
                    required: true,
                    message: 'Lütfen Iban alanını boş bırakmayın.',
                },
                ]:null}
            >
                <Input type="text" maxLength="26"  value={iban} onChange={handleIban} />
            </Form.Item>
            <Form.Item
                label="Banka Şubesi"
                name="BankBranchId"
                rules={
                  iban?.length < 20 ?     
                    [
                {
                    required: true,
                    message: 'Lütfen Banka Şubesi alanını boş bırakmayın.',
                },
                ]:null}
            >
                <Select
                showSearch
                placeholder="Seçiniz"
                optionFilterProp="children"
                onChange={handleBankBranchId}
                value={bankBranchName}
                filterOption={(input, option) =>
                  option.props.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
                >
                {bankBranchDropdownUpdate.map(item => (
                <Option key={item.id}>{item.name}</Option>
                ))}
                </Select>
            </Form.Item>
            <Form.Item
                label="Hesap Numarası"
                name="AccountNumber"
                rules={
                  iban?.length < 20 ?     
                    [
                {
                    min: 7, 
                    message: 'Hesap numarası en az 7 karakter olmalıdır.'},
                    {required: true,
                    message: 'Lütfen Hesap Numarası alanını boş bırakmayın.',
                },
                ]:null}
            >
                <Input maxLength = "17" onInput={maxLengthCheck} 
                value={accountNumber} 
                onChange={handleAccountNumber}/>
            </Form.Item>
            <Form.Item
                label="Geçerli Hesap"
                name="IsDefault"
                valuePropName="checked"
            >
                <Checkbox onChange={handleChecked}/>
            </Form.Item>
                    </Col>
                    <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}>
            <Form.Item {...tailLayout} >
            <Button type="primary" icon={<PlusOutlined />}  htmlType="submit"  onClick={() => setLoading(true)} loading={loading}>
            {props.AccountId !== undefined ? "Ekle" : "Güncelle"}
            </Button>
            </Form.Item>
            </Col>
               </> }
         
              {isTabletOrMobileDevice && <>
                <Col span={24}>
            <Form.Item
                label="Cari Adı/Ünvanı"
                name="FirmId"
                style={{marginTop:"4%"}}
                rules={[
                {
                    required: true,
                    message: 'Lütfen Cari Adı/Ünvanı tipi alanını boş bırakmayın.',
                },
                ]}
            >
                <Select
                showSearch
                placeholder="En az 3 harf giriniz.."
                optionFilterProp="children"
                onFocus={onFocus}
                value={firmId} 
                onChange={handleFirmId}
                onSearch={onSearchs}
                filterOption={(input, option) =>
                  option.props.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
                >
                {firmDropdown.map(item => (
                <Option key={item.id}>{item.name}</Option>
                ))}
                </Select>
            </Form.Item>
            
            <Form.Item
                label="Banka"
                name="BankId"
                rules={[
                {
                    required: true,
                    message: 'Lütfen banka alanını boş bırakmayın.',
                },
                ]}
            >
                <Select
                showSearch
                placeholder="Seçiniz."
                optionFilterProp="children"
                onChange={onChange}
                filterOption={(input, option) =>
                  option.props.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
                >
                {bankDropdown.map(item => (
                    item.Name === "KASA HESABI" ? "" :
                    <Option key={item.id}>{item.name}</Option>
                ))}
                </Select>
            </Form.Item>

            <Form.Item
                label="IBAN"
                name="Iban"
                rules={[
                {
                    required: true,
                    message: 'Lütfen Iban alanını boş bırakmayın.',
                },
                ]}
            >
                <Input type="text" maxLength="26"  value={iban} onChange={handleIban} />
            </Form.Item>
            <Form.Item
                label="Banka Şubesi"
                name="BankBranchId"
                rules={
                    iban<5 ?
                    [
                {
                    required: true,
                    message: 'Lütfen Banka Şubesi alanını boş bırakmayın.',
                },
                ]:null}
            >
                <Select
                showSearch
                placeholder="Seçiniz"
                optionFilterProp="children"
                onChange={handleBankBranchId}
                value={bankBranchName}
                filterOption={(input, option) =>
                  option.props.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
                >
                {bankBranchDropdownUpdate.map(item => (
                <Option key={item.id}>{item.name}</Option>
                ))}
                </Select>
            </Form.Item>
            <Form.Item
                label="Hesap Numarası"
                name="AccountNumber"
                rules={
                    iban<5 ?
                    [
                {
                    min: 7, 
                    message: 'Hesap numarası en az 7 karakter olmalıdır.'},
                    {required: true,
                    message: 'Lütfen Hesap Numarası alanını boş bırakmayın.',
                },
                ]:null}
            >
                <Input maxLength = "17" onInput={maxLengthCheck} 
                value={accountNumber} 
                onChange={handleAccountNumber}/>
            </Form.Item>
            <Form.Item
                label="Geçerli Hesap"
                name="IsDefault"
                valuePropName="checked"
            >
                <Checkbox onChange={handleChecked}/>
            </Form.Item>
                    </Col>
                    <Col span={10}></Col>
            <Col span={8}>
            <Form.Item {...tailLayout} >
            <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      htmlType="submit"
                      onClick={() => setLoading(true)}
                      loading={loading}
                    >
                      {props.AccountId !== undefined ? "Ekle" : "Güncelle"}
            </Button>
           
            </Form.Item>
            </Col>
            <Col span={6}></Col>
               </> }
       
                </Row>
            </Form>
            </div>
        )
}
export default FirmAccountUpdate