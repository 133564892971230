import React, { useEffect, useState } from "react";
import "./AutoComplate.css";
import {
  Table,
  Menu,
  Dropdown,
  Drawer,
  Modal,
  Input,
  Tabs,
  Empty,
  notification,
  DatePicker,
  Select,
  Form,
  message,
  Button,
  Row,
  Col,
  Collapse,
  Tooltip,
  Spin
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  DownloadOutlined,
  BarsOutlined,
  WarningOutlined,
  CloseOutlined,
  MailFilled,
  CheckCircleOutlined,
  MailOutlined,
  FileExcelOutlined,
  MinusOutlined,
  FilePdfOutlined,
  TagsOutlined
} from "@ant-design/icons";
import axios from "axios";
import ReactTags from 'react-autocomplete-tag'
import { useHistory } from "react-router-dom";
import config from "../../util/ApiforContext";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import FirmAddForm from "../Forms/FirmAddForm";
import Replace2x from "../../util/Extensions/ReplaceExtension";
import Screen from "../../util/Extensions/Screens";
import Iframe from "react-iframe";
import { ColumnHelper, RowHelper } from "util/TableHelper";
import RequestHandler from "util/RequestHandler";
import { odata } from "../Filters/Helper";

const expiration = localStorage.getItem("expiration");
const { Panel } = Collapse;
const dateFormat = "DD/MM/YYYY";
const openNotificationWithIconTcNoVkno = (type) => {
  notification[type]({
    message: "Hata",
    description:
      "Vergi Numarası veya TC Kimlik numarası alanlarından en az birinin dolu olması zorunludur.",
  });
};
const updateMatchFirm = (type) => {
  notification[type]({
    message: "BAŞARILI",
    description:
      "Cari Güncelleme işlemi tamamlanmıştır. Cari işlemleri menüsünden ilgili cariye ait bilgileri güncelleyebilirsiniz.",
    duration: 5
  });
};
const openNotificationWithIconExcel = (type) => {
  notification[type]({
    message: "BAŞARILI",
    description: "Excel indirme işlemi başarı ile gerçekleşti.",
    duration: 2,
  });
};
const openNotificationWithIconWarningExcel = (type) => {
  notification[type]({
    message: "HATA",
    description: "Excel indirme işlemi sırasında bir hata oluştu.",
    duration: 2,
  });
};
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 15,
    span: 5,
  },
};
const tailLayouttwo = {
  wrapperCol: {
    offset: 15,
    span: 40,
  },
};
const { TabPane } = Tabs;
const token = localStorage.getItem("token");
const email = Replace2x(localStorage.getItem("email"));
const { RangePicker } = DatePicker;
const AccountPage = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1500px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [accountIdForModalReceipt, setAccountIdForModalReceipt] = useState("");
  const [accounts, setAccounts] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [dataState, setDataState] = useState({ skip: 0, take: 15 });
  const [take, setTake] = useState(15);
  const [skip, setSkip] = useState(0);
  const [activeKey, setActiveKey] = useState("999");
  const [teminatDropdown, setTeminatDropdown] = useState([]);
  const [query, setQuery] = useState("$filter= isErpActivity eq false ");
  const [currentPage, setCurrentPage] = useState(1);
  const [bankFilter, setBankFilter] = useState([]);
  const [getFilterCurrency, setGetFilterCurrency] = useState([]);
  const [firmSelectForFilter, setFirmSelectForFilter] = useState({
    data: [],
    loading: false,
  });
  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });
  const [wordList, setWordList] = useState([]);
  const [loadingForWords, setLoadingForWords] = useState(false);
  const [firmId, setFirmId] = useState("");
  const [Vkn, setVkn] = useState("");
  const [Tckn, setTckn] = useState("");
  const [senderName, setSenderName] = useState("");
  const [Currency, setCurrency] = useState("");
  const [TenantBankName, setTenantBankName] = useState("");
  const [Iban, setIban] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [TenantIban, setTenantIban] = useState("");
  const [Description, setDescription] = useState("");
  const [Amount1, setAmount1] = useState("");
  const [Amount2, setAmount2] = useState("");
  const [TransactionDate, setTransactionDate] = useState("");
  const [TransactionDate1, setTransactionDate1] = useState("");

  const [oldVkn, setOldVkn] = useState("");
  const [newVkn, setNewVkn] = useState("");
  const [newTckn, setNewTckn] = useState("");
  const [oldTckn, setOldTckn] = useState("");

  const [currencyTRList, setCurrencyTRList] = useState([]);
  const [transactionTypeList, setTransactionTypeList] = useState([]);
  const [spinLoading, setSpinLoading] = useState(false);
  const [transactionTypeFilterList, setTransactionTypeFilterList] = useState(
    []
  );
  const [isModalVisibleReceipt, setIsModalVisible] = useState(false);
  const [isModalTagVisible, setIsModalTagVisible] = useState(false);
  const [vknTcknModal, setVknTcknModal] = useState(false);
  const [AccountId, setAccountId] = useState("");
  const [Id, setId] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [firmIdForVknTckn, setFirmIdForVknTckn] = useState("");
  const [descriptionForMatch, setDescriptionForMatch] = useState("");
  const [Email, setEmail] = useState(email);
  const [EmailVisible, setEmailVisible] = useState(true);
  const [tagQuery, setTagQuery] = useState("");

  const [visible, setVisible] = useState(false);
  const [visibledWords, setVisibleWords] = useState(false);
  const [TransactionProcessType, setTransactionProcessType] = useState("");
  const [TransactionTypeValue, setTransactionTypeValue] = useState("");

  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [visibled, setVisibled] = useState(false);
  const [isFirmHidden, setIsFirmHidden] = useState(true);
  const [isBranchShow, setIsBranchShow] = useState(true);
  const [isIbanShow, setIsIbanShow] = useState(true);
  const [isCurrencyShow, setIsCurrencyShow] = useState(true);
  const [isTenantIbanShow, setIsTenantIbanShow] = useState(true);
  const [isTransactionTypeShow, setIsTransactionTypeShow] = useState(true);
  const [isVknTcknShow, setIsVknTcknShow] = useState(true);
  const [isReceiptShow, setIsReceiptShow] = useState(true);
  const [isAdditionalDescriptionShow, setIsAdditionalDescriptionShow] = useState(true);
  const [isSenderNameShow, setIsSenderNameShow] = useState(true);
  const [isBalanceAfterTransactionShow, setIsBalanceAfterTransactionShow] = useState(true);
  const [isDescriptionShow, setIsDescriptionShow] = useState(true);
  const [isFirmNameShow, setIsFirmNameShow] = useState(true);
  const [accountTransactionId, setAccountTransactionId] = useState();
  const [isHaveAccountRole, setIsHaveAccountRole] = useState({
    data: false,
    loading: true,
  });
  const [tags, setTags] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [prevTagList, setprevTagList] = useState([])

  const errorList = [];
  const wordArray = []
  useEffect(() => {
    RequestHandler.get("/TenantUserRole/IsHaveAccountRole").then((response) => {
      setIsHaveAccountRole({
        data: response.success,
        loading: false,
      });
    });
  }, []);

  useEffect(() => {
    if (isHaveAccountRole.loading) {
      return;
    }

    setIsFirmHidden(true);
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }

    RequestHandler.get(`/TenantTransactionTag/GetTransactionTagList`).then((response) => {
      var tagList = [];
      response.map((item) => {
        tagList.push(item.tag)
      })
      setprevTagList(tagList)
    });

    RequestHandler.get(`/AccountTransaction/GetTransactionTableRole`).then((response) => {
      if (response.success) {
        setIsAdditionalDescriptionShow(response.data?.isAdditionalDescriptionShow)
        setIsBalanceAfterTransactionShow(response.data?.isBalanceAfterTransactionShow)
        setIsBranchShow(response.data?.isBranchShow)
        setIsCurrencyShow(response.data?.isCurrencyShow)
        setIsDescriptionShow(response.data?.isDescriptionShow)
        setIsFirmNameShow(response.data?.isFirmNameShow)
        setIsIbanShow(response.data?.isIbanShow)
        setIsReceiptShow(response.data?.isReceiptShow)
        setIsSenderNameShow(response.data?.isSenderNameShow)
        setIsTenantIbanShow(response.data?.isTenantIbanShow)
        setIsTransactionTypeShow(response.data?.isTransactionTypeShow)
        setIsVknTcknShow(response.data?.isVknTcknShow)
      }
    });

    axios
      .get(`${config.apiUrl}/Types/GetManualTransactionTypes`, config.headers)
      .then((res) => {
        setTeminatDropdown(res.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });

    axios
      .get(
        `${config.apiUrl}/AccountTransaction/GetTransactionType`,
        config.headers
      )
      .then((res) => {
        setTransactionTypeList(res.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });

    setAccounts({
      ...accounts,
      loading: true,
    });
    axios
      .get(
        `${config.apiUrl}/AccountTransaction? ${query} &$count=true&$skip=${skip}&$top=${take}`,
        config.headers
      )
      .then((res) => {
        setAccounts({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });

    axios
      .get(`${config.apiUrl}/Bank/GetFilterBank`, config.headers)
      .then((res) => {
        setBankFilter(res.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    axios
      .get(`${config.apiUrl}/currency/getfiltercurrency`, config.headers)
      .then((res) => {
        setGetFilterCurrency(res.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    axios
      .get(
        `${config.apiUrl}/TenantCurrency?$apply=groupby((Currency,CurrencyId))&$orderby=currencyId`,
        config.headers
      )
      .then((res) => {
        setCurrencyTRList(res.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        }
      });
  }, [
    query,
    setDataState,
    skip,
    take,
    setTake,
    setSkip,
    isHaveAccountRole,
  ]);

  const addTag = (val) => {
    if (val.length > 1 && val.length < 21) {
      setTags([...tags, val])
      setSuggestions([])
    } else {
      countTagError("error")
    }

  }

  const removeTag = (idx) => {
    var t = [...tags]

    t.splice(idx, 1)

    setTags(t)
  }

  const handleTagChange = (val) => {
    if (val.length > 0) {
      var new_sug = [];
      prevTagList.forEach((t) => {
        if (t.includes(val)) {
          new_sug.push(t)
        }
      })
      setSuggestions(new_sug)
    } else {
      setSuggestions([])
    }
  }

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setVisibleUpdate(false);
  };
  const showModal = () => {
    setVisibled(true);
  };
  const showModalWords = () => {
    setVisibleWords(true);
  }
  const handleCancelWords = () => {
    setVisibleWords(false)
    handleCancel()
    window.location.reload();
  }
  const showModalUpdate = () => {
    setVisibleUpdate(true);
  };
  const handleCancel = (e) => {
    setVisibled(false);
  };
  const handleCancelUpdate = (e) => {
    setVisibleUpdate(false);
  };
  const handleTransactionProcessType = (value) => {
    setTransactionProcessType(value);
  };
  const setDownloadExcel = () => {
    const hide = message.loading("Pdf indiriliyor...", 0);
    var accountId = accountIdForModalReceipt;
    window.open(
      `${config.apiUrl
      }/Pdf/DownloadPdfReceiptColumnsWithDoc?id=${accountId}&isDownload=true&t=${Replace2x(
        localStorage.getItem("token")
      )}`
    );
    setIsModalVisible(true);
    alertSuccess("success");
    hide();
  };
  const showModalTag = (accountTransactionId) => {
    setSpinLoading(true);
    setAccountTransactionId(accountTransactionId);
    axios
      .get(
        `${config.apiUrl}/TenantTransactionTag/GetListQueryableOdataWithAccountTransaction/${accountTransactionId}`,
        config.headers
      )
      .then((res) => {
        var tagList = [];
        res.data.map((item) => {
          tagList.push(item.transactionTagName)
        })
        setTags(tagList)
        setSpinLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
          setSpinLoading(false);
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
          setSpinLoading(false);
        }
      });
    setIsModalTagVisible(true);
  }
  const showModalReceipt = (value) => {
    setAccountIdForModalReceipt(value);
    setEmailVisible(true);

    axios
      .get(
        `${config.apiUrl
        }/Pdf/DownloadPdfReceiptColumnsWithDoc?id=${value}&isDownload=false&t=${Replace2x(
          localStorage.getItem("token")
        )}`,
        config.headers
      )
      .then((res) => {
        if (res.status == 200) {
          setPdfUrl(res.data);
          // window.open(`${config.apiUrl}/Pdf/DownloadPdfReceiptColumnsWithDoc?id=${value}&t=${Replace2x(localStorage.getItem("token"))}`);

          // alertSuccess('success')
        }
      })
      .catch((error) => {
        if (error.response.status == 500) {
          alertError("error");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
          setLoading(false);
        }
      });
    setIsModalVisible(true);
  };
  const handleOkReceipt = () => {
    setPdfUrl("");
    setIsModalVisible(false);
  };
  const handleOkTag = () => {
    RequestHandler.post(`/TenantTransactionTag`,
      {
        AccountTransactionId: accountTransactionId,
        tenantTransactionTags: tags
      }, true
    ).then((response) => {
      setIsModalTagVisible(false);
      setTimeout(() => {
        window.location.reload();

      }, 1500);
    });

  };
  const handleCancelTag = () => {
    setIsModalTagVisible(false)
  };

  const handleCancelReceipt = () => {
    setPdfUrl("");

    setIsModalVisible(false);
  };
  const handleTransactionType = (value) => {
    setTransactionTypeValue(value);
    axios
      .get(
        `${config.apiUrl}/AccountTransaction/GetTransactionTypeGroup?id=${value}`,
        config.headers
      )
      .then((res) => {
        var idList = [];
        res.data.map((item) => {
          idList.push(item.id);
        });
        setTransactionTypeFilterList(idList);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };

  const onFirmSearchForFilter = (value) => {
    if (value.length >= 2) {
      setFirmSelectForFilter({ data: [], loading: true });

      RequestHandler.get(
        `/Firm?$select=id,firmBranchNumber,name&$filter=${odata.contains(
          "name",
          value
        )} or ${odata.contains("firmBranchNumber", value)}`
      ).then((res) => {
        setFirmSelectForFilter({ data: res.value, loading: false });
      });
    } else {
      setFirmSelectForFilter({ data: [], loading: false });
    }
  };

  const onFirmSearch = (value) => {
    if (value.length >= 2) {
      setFirmSelect({ data: [], loading: true });

      RequestHandler.get(
        `/Firm?$select=id,firmBranchNumber,name&$filter=${odata.contains(
          "name",
          value
        )} or ${odata.contains("firmBranchNumber", value)}`
      ).then((res) => {
        setFirmSelect({ data: res.value, loading: false });
      });
    } else {
      setFirmSelect({ data: [], loading: false });
    }
  };

  const dataStateChange = (e) => {
    setAccounts(e);
  };
  const handleResets = (clearFilters) => {
    setSkip(0);
    setTake(15);
    setQuery("$filter= isErpActivity eq false ");
  };
  const handleReset = (clearFilters) => {
    setSkip(0);
    setTake(15);

    if (activeKey !== "999") {
      setQuery(
        `$filter= CurrencyId eq ${activeKey} and isErpActivity eq false `
      );
    } else {
      setQuery("$filter= isErpActivity eq false ");
    }
    setIban("");
    setFirmId("");
    setAccountNumber("");
    setTagQuery("");
    setTransactionProcessType("");
    setTransactionTypeValue("");
    setCurrency("");
    setTenantBankName("");
    setDescription("");
    setAmount2("");
    setAmount1("");
    setTransactionDate("");
    setTenantIban("");
    setTransactionDate1("");
    window.location.reload();
  };
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 4,
      });
    });
  };
  const emailError = (type) => {
    notification[type]({
      message: "HATA",
      description: "Mail formatı hatalı.",
      duration: 2,
    });
  };
  const countTagError = (type) => {
    notification[type]({
      message: "HATA",
      description: "Girdiğiniz etiket 2-20 karakter arasında olmalıdır.",
      duration: 4,
    });
  };
  const tagNotSelected = (type) => {
    notification[type]({
      message: "HATA",
      description: "Henüz etiket tanımlamadınız.",
      duration: 4,
    });
  };
  const handleIban = (value) => {
    setIban(value.target.value);
  };
  const handleTenantIban = (value) => {
    setTenantIban(value.target.value);
  };
  const exportExcel = () => {
    const hide = message.loading("Excel indiriliyor...", 0);

    const queryString = query;
    axios
      .post(
        `${config.apiUrl}/AccountTransaction/exportexcel`,
        { Query: queryString },
        config.headers
      )
      .then((res) => {
        if (res.status == 200) {
          openNotificationWithIconExcel("success");
          window.open(
            `${config.apiUrl}/AccountTransaction/downloadexcel?fileName=${res.data
            }&t=${Replace2x(token)}`
          );
          hide();
        }
      })
      .catch((error) => {
        if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        } else if (
          error.response.status === 403 ||
          error.response.status === 401
        ) {
          history.push("/not-authorized-access");
        } else if (error.response.data.message == "EmailError") {
          emailError("error");
        } else {
          openNotificationWithIconWarningExcel("error");
        }
      });
  };
  const handleCurrency = (value) => {
    setCurrency(value);
  };
  const handleTenantBankName = (value) => {
    setTenantBankName(value);
  };
  const handleDescription = (value) => {
    setDescription(value.target.value);
  };
  const handleAmount1 = (value) => {
    setAmount1(value.target.value.replace(",", "."));
  };
  const handleAccountNumber = (value) => {
    setAccountNumber(value.target.value);
  };
  const handleAmount2 = (value) => {
    setAmount2(value.target.value.replace(",", "."));
  };
  const handleTransactionDate = (value) => {
    if (value !== null) {
      setTransactionDate(value[0].format("YYYY-MM-DD"));
      setTransactionDate1(value[1].format("YYYY-MM-DD"));
    }
  };
  const ContactSideBar = (value, e) => {
    return (
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (row) =>
            RowHelper.renderRowDetail({
              data: row,
              columns: detailColumns,
            }),
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Veri Yok"
            />
          ),
        }}
        style={{ overflowX: "auto", maxHeight: "100%" }}
        size="small"
        rowKey="id"
        filterable={true}
        sortable={true}
        pagination={{
          total: accounts.total,
          pageSizeOptions: ["15", "25", "50", "100"],
          defaultPageSize: take,
          current: currentPage,
        }}
        pageable={true}
        onDataStateChange={dataStateChange}
        onChange={paging}
        dataSource={accounts.data}
        loading={accounts.loading}
      />
    );
  };
  const handleChange = (activeKey) => {
    if (activeKey != "999") {
      setCurrency("");
      setQuery(
        `$filter= CurrencyId eq ${activeKey} and isErpActivity eq false `
      );
    } else {
      setQuery("$filter= isErpActivity eq false ");
    }

    setAccounts({
      ...accounts,
      loading: true,
    });
    axios
      .get(
        `${config.apiUrl}/AccountTransaction? ${query} &$count=true&$skip=${skip}&$top=${take}`,
        config.headers
      )
      .then((res) => {
        setAccounts({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    setActiveKey(activeKey);
  };
  const handleId = (accountId) => {
    axios
      .get(
        `${config.apiUrl}/AccountTransaction/Getbyid/${accountId}`,
        config.headers
      )
      .then((res) => {
        res.data.firmId === null ? setFirmId("") : setFirmId(res.data.firmId);
        res.data.id === null ? setId(res.data.id) : setId(res.data.id);
        res.data.vkn === null ? setVkn("") : setVkn(res.data.vkn);
        res.data.tckn === null ? setTckn("") : setTckn(res.data.tckn);
        res.data.senderName === null ? setSenderName("") : setSenderName(res.data.senderName);
        setDescriptionForMatch(res.data.description)
        axios
          .get(
            `${config.apiUrl}/Firm/IsHaveFirm/${res.data.firmId}`,
            config.headers
          )
          .then((response) => {
            if (!response.data) {
              setFirmId("");
            }
          })
          .catch((error) => {
            if (error.response.status === 403 || error.response.status === 401) {
              history.push("/not-authorized-access");
            } else if (error.response.status === 406) {
              alert("res")
              error.response.data.data.map((item) => {
                errorList.push(item);
              });
              errorListNotification("error");
            }
          });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    setAccountId(accountId);
  };
  const paging = (p) => {
    setDataState((prevState) => {
      prevState.skip = (p.current - 1) * p.pageSize;
      setSkip(prevState.skip);
      setTake(p.pageSize);
      setCurrentPage(p.current);
      return {
        prevState,
      };
    });
  };
  const handleFirmId = (value) => {
    setFirmId(value);
  };
  const { Option } = Select;
  const menu = (
    <Menu>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" onClick={showDrawer}>
          Yeni Cari Kaydı
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" onClick={showModal}>
          Kayıtlı Cari Eşleştir
        </a>
      </Menu.Item>
    </Menu>
  );
  const menuUpdate = (
    <Menu>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" onClick={showModalUpdate}>
          Kayıtlı Cari Güncelle
        </a>
      </Menu.Item>
    </Menu>
  );
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Eşleştirme işlemi başarı ile gerçekleşti.",
      duration: 4,
    });
  };
  const changeWithAccountIban = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Eşleştirme başarılı. Hesap hareketinden gelen Iban , Cari Hesabı olarak eklenmiştir. Cari Hesapları ekranından düzenleyebilirsiniz.",
      duration: 5,
    });
  };
  const firmAccountNotSaved = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Eşleştirme işlemi başarı ile gerçekleşti. Iban mevcut olduğu için firma hesabı eklenemedi.",
      duration: 4,
    });
  };
  const sendSuccessMail = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Mail gönderme işlemi başarılı.",
      duration: 2,
    });
  };
  const openNotificationWithIconWarning = (type) => {
    notification[type]({
      message: "HATA",
      description: "Eşleştirme işlemi sırasında bir hata oluştu.",
      duration: 2,
    });
  };
  const mailEmptyError = (type) => {
    notification[type]({
      message: "HATA",
      description: "Mail adresi girilmedi.",
      duration: 2,
    });
  };
  const mailNotFound = (type) => {
    notification[type]({
      message: "HATA",
      description: "Mail adresi kayıtlı değil.",
      duration: 2,
    });
  };

  function onChange(value) {
    setFirmId(value);
  }
  const onFinish = (values) => {
    {
      var NewTransactionDate = moment(TransactionDate)
        .add(-1, "days")
        .format("YYYY-MM-DD");
      var NewTransactionDate1 = moment(TransactionDate1)
        .add(1, "days")
        .format("YYYY-MM-DD");
      var queryAccount = "$filter= isErpActivity eq false ";
      setSkip(0);
      setTake(15);
      if (activeKey !== "999") {
        var queryBase = ` and CurrencyId eq ${activeKey}`;
        queryAccount = queryBase;
      }
      if (queryAccount === "" && Iban !== undefined && Iban !== "") {
        queryAccount += `$filter=${odata.contains("Iban", Iban)}`;
      } else if (queryAccount !== "" && Iban !== undefined && Iban !== "") {
        queryAccount += ` and ${odata.contains("Iban", Iban)}`;
      }
      if (queryAccount === "" && firmId !== undefined && firmId !== "") {
        queryAccount += `$filter=FirmId eq ${firmId} `;
      } else if (queryAccount !== "" && firmId !== undefined && firmId !== "") {
        queryAccount += ` and FirmId eq ${firmId} `;
      }
      if (
        queryAccount === "" &&
        AccountNumber !== undefined &&
        AccountNumber !== ""
      ) {
        queryAccount += `$filter=${odata.contains("TenantAccountNumber", AccountNumber)}`;
      } else if (
        queryAccount !== "" &&
        AccountNumber !== undefined &&
        AccountNumber !== ""
      ) {
        queryAccount += ` and ${odata.contains("TenantAccountNumber", AccountNumber)}`;
      }
      if (
        queryAccount === "" &&
        TenantIban !== undefined &&
        TenantIban !== ""
      ) {
        queryAccount += `$filter=${odata.contains("TenantIban", TenantIban)}`;
      } else if (
        queryAccount !== "" &&
        TenantIban !== undefined &&
        TenantIban !== ""
      ) {
        queryAccount += ` and ${odata.contains("TenantIban", TenantIban)}`;
      }
      if (
        queryAccount === "" &&
        tagQuery !== undefined &&
        tagQuery !== ""
      ) {
        queryAccount += `(${tagQuery})`;
      } else if (
        queryAccount !== "" &&
        tagQuery !== undefined &&
        tagQuery !== ""
      ) {
        queryAccount += ` and (${tagQuery})`;
      }

      if (queryAccount === "" && Currency !== undefined && Currency !== "") {
        queryAccount += `$filter=${odata.contains("Currency", Currency)}`;
      } else if (
        queryAccount !== "" &&
        Currency !== undefined &&
        Currency !== ""
      ) {
        queryAccount += ` and ${odata.contains("Currency", Currency)}`;
      }
      if (
        queryAccount === "" &&
        TenantBankName !== undefined &&
        TenantBankName !== ""
      ) {
        queryAccount += `$filter=BankId eq ${TenantBankName} `;
      } else if (
        queryAccount !== "" &&
        TenantBankName !== undefined &&
        TenantBankName !== ""
      ) {
        queryAccount += ` and BankId eq ${TenantBankName} `;
      }
      if (
        queryAccount === "" &&
        Description !== undefined &&
        Description !== ""
      ) {
        queryAccount += `$filter=${odata.contains("Description", Description)}`;
      } else if (
        queryAccount !== "" &&
        Description !== undefined &&
        Description !== ""
      ) {
        queryAccount += ` and ${odata.contains("Description", Description)}`;
      }
      if (
        queryAccount === "" &&
        TransactionTypeValue !== undefined &&
        TransactionTypeValue !== ""
      ) {
        var idList = "";
        var count = 0;
        transactionTypeFilterList.map((item) => {
          idList += count !== 0 ? "," : "";
          idList += `'${item}'`;
          count += 1;
        });
        if (transactionTypeFilterList.length > 0) {
          queryAccount += `$filter=transactionTypeId in (${idList})`;
        } else {
          queryAccount += `$filter=transactionTypeId in ('0')`;
        }
      } else if (
        queryAccount !== "" &&
        TransactionTypeValue !== undefined &&
        TransactionTypeValue !== ""
      ) {
        var idList = "";
        var count = 0;
        transactionTypeFilterList.map((item) => {
          idList += count !== 0 ? "," : "";
          idList += `'${item}'`;
          count += 1;
        });
        if (transactionTypeFilterList.length > 0) {
          queryAccount += ` and transactionTypeId in (${idList})`;
        } else {
          queryAccount += ` and transactionTypeId in ('0')`;
        }
      }
      if (
        queryAccount === "" &&
        TransactionProcessType !== undefined &&
        TransactionProcessType !== ""
      ) {
        queryAccount += `$filter=TransactionProcessTypeValue eq ${TransactionProcessType} `;
      } else if (
        queryAccount !== "" &&
        TransactionProcessType !== undefined &&
        TransactionProcessType !== ""
      ) {
        queryAccount += ` and TransactionProcessTypeValue eq ${TransactionProcessType} `;
      }
      if (
        queryAccount === "" &&
        TransactionDate !== undefined &&
        TransactionDate !== ""
      ) {
        queryAccount += `$filter=date(TransactionDateValue) lt ${NewTransactionDate1} and date(TransactionDateValue) gt ${NewTransactionDate}`;
      } else if (
        queryAccount !== "" &&
        TransactionDate !== undefined &&
        TransactionDate !== ""
      ) {
        queryAccount += ` and date(TransactionDateValue) lt ${NewTransactionDate1} and date(TransactionDateValue) gt ${NewTransactionDate}`;
      }
      if (
        TransactionProcessType === undefined ||
        TransactionProcessType === ""
      ) {
        if (
          queryAccount === "" &&
          Amount1 !== undefined &&
          Amount1 !== "" &&
          (Amount2 === undefined || Amount2 === "")
        ) {
          queryAccount += `$filter= (Amount lt ${Amount1} or Amount eq ${Amount1}) and (TransactionProcessTypeValue eq 2 or TransactionProcessTypeValue eq 1)`;
        } else if (
          queryAccount !== "" &&
          Amount1 !== undefined &&
          Amount1 !== "" &&
          (Amount2 === undefined || Amount2 === "")
        ) {
          queryAccount += ` and (Amount lt ${Amount1} or Amount eq ${Amount1}) and (TransactionProcessTypeValue eq 2 or TransactionProcessTypeValue eq 1)`;
        }
        if (
          queryAccount === "" &&
          (Amount1 === undefined || Amount1 === "") &&
          Amount2 !== undefined &&
          Amount2 !== ""
        ) {
          queryAccount += `$filter=(Amount gt ${Amount2} or Amount eq ${Amount2} ) and TransactionProcessTypeValue eq 1`;
        } else if (
          queryAccount !== "" &&
          (Amount1 === undefined || Amount1 === "") &&
          Amount2 !== undefined &&
          Amount2 !== ""
        ) {
          queryAccount += ` and (Amount gt ${Amount2} or Amount eq ${Amount2}) and TransactionProcessTypeValue eq 1`;
        }
      } else {
        if (
          queryAccount === "" &&
          Amount1 !== undefined &&
          Amount1 !== "" &&
          (Amount2 === undefined || Amount2 === "")
        ) {
          queryAccount += `$filter= (Amount lt ${Amount1} or Amount eq ${Amount1})`;
        } else if (
          queryAccount !== "" &&
          Amount1 !== undefined &&
          Amount1 !== "" &&
          (Amount2 === undefined || Amount2 === "")
        ) {
          queryAccount += ` and (Amount lt ${Amount1} or Amount eq ${Amount1})`;
        }
        if (
          queryAccount === "" &&
          (Amount1 === undefined || Amount1 === "") &&
          Amount2 !== undefined &&
          Amount2 !== ""
        ) {
          queryAccount += `$filter=Amount gt ${Amount2} `;
        } else if (
          queryAccount !== "" &&
          (Amount1 === undefined || Amount1 === "") &&
          Amount2 !== undefined &&
          Amount2 !== ""
        ) {
          queryAccount += ` and Amount gt ${Amount2} `;
        }
      }

      if (
        queryAccount === "" &&
        Amount1 !== undefined &&
        Amount1 !== "" &&
        Amount2 !== undefined &&
        Amount2 !== ""
      ) {
        queryAccount += `$filter=(Amount lt ${Amount1} or Amount eq ${Amount1}) and (Amount gt ${Amount2} or Amount eq ${Amount2})`;
      } else if (
        queryAccount !== "" &&
        Amount1 !== undefined &&
        Amount1 !== "" &&
        Amount2 !== undefined &&
        Amount2 !== ""
      ) {
        queryAccount += ` and (Amount lt ${Amount1} or Amount eq ${Amount1}) and (Amount gt ${Amount2} or Amount eq ${Amount2})`;
      }
      setCurrentPage(1);
      setQuery(queryAccount);
      queryAccount = "";
    }
  };
  const alertSuccess = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Pdf indirme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });
  };
  const alertError = (type) => {
    notification[type]({
      message: "HATA",
      description: "Pdf indirme işlemi sırasında bir hata oluştu.",
      duration: 2,
    });
  };
  const wordLengtError = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Kelimeler bölümündeki harf sayısı 5ten küçük olduğu için güncelleme başarısız oldu, düzenleyip tekrar deneyin.',
      duration: 4,
    });
  };
  const firmWordMatchOk = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Kelime ekleme işlemi başarı ile gerçekleştirilmiştir.',
      duration: 4,
    });
  };
  const onFinishUpdateReceipt = (values) => { };
  const onFinishFailedUpdateReceipt = (values) => { };
  const onChangeEmail = (values) => {
    setEmail(values.target.value);
  };
  const sendMail = () => {
    setLoading(true);
    if (Email.length > 0) {
      axios
        .post(
          `${config.apiUrl}/AccountTransaction/ReceiptSendMail`,
          { Email: Email, Path: pdfUrl, ScreenOption: Screen.screen },
          config.headers
        )
        .then((res) => {
          if (res.data === "NotFound") {
            mailNotFound("error");
            setLoading(false);
          } else if (res.status == 204 || res.status == 200) {
            setLoading(false);
            setIsModalVisible(false);
            sendSuccessMail("success");
          }
        })
        .catch((error) => {
          if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
              setLoading(false);
            });
            errorListNotification("error");
            setLoading(false);
          } else if (
            error.response.status === 403 ||
            error.response.status === 401
          ) {
            history.push("/not-authorized-access");
          }
        });
    } else {
      mailEmptyError("error");
      setLoading(false);
    }
  };
  const showVknTcknModal = () => {
    setVknTcknModal(true)
  };
  const onFinishUpdateWords = () => {
    const words = [];
    wordList.map((item) => {
      words.push(item.word.trim());
    })
    var error = 0;
    words.map((item) => {
      var wordLength = item.length;
      if (wordLength < 5 && wordLength !== 0) {
        error += 1;
      }
    })

    if (error == 0) {
      axios.put(`${config.apiUrl}/Firm/FirmMatchingWordPut`,
        {
          FirmId: firmIdForVknTckn,
          Words: words.filter(item => item.name !== "")
        },
        config.headers)
        .then(res => {
          if (res.status === 200) {
            firmWordMatchOk("success")
            setLoadingForWords(false)
            window.location.reload();
          }
        }).catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push("/not-authorized-access");
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            });
            errorListNotification("error");
            setLoadingForWords(false)
          } else {
            openNotificationWithIconWarning("error");
            setLoadingForWords(false)
          }
        });
    } else {
      wordLengtError('error')
      setLoadingForWords(false)
    }
  };
  const onOkTcknVkn = () => {
    axios.post(`${config.apiUrl}/Firm/GetDiffrentVknTckn`,
      {
        AccountTransactionId: AccountId,
        Id: firmIdForVknTckn,
        IsUpdateValue: true,
        IsIbanUpdateValue: true
      },
      config.headers
    )
      .then((res) => {
        if (res.data.data.accountIban === "") {
          openNotificationWithIcon("success")
          showModalWords(true)
        }
        else if (res.data.data.accountIban !== null) {
          changeWithAccountIban("success");
          showModalWords(true)
        } else {
          firmAccountNotSaved("success");
          showModalWords(true);
        }
        // setTimeout(() => {
        //     window.location.reload();
        // }, 4000);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        } else {
          openNotificationWithIconWarning("error");
        }
      });

  }
  const onCancelTcknVkn = () => {
    openNotificationWithIcon("success");
    setVknTcknModal(false)
    setLoading(false)
    showModalWords(true)
  }
  const hideVknTcknModal = () => {
    setVknTcknModal(false)
  };
  const onFinishUpdateWithoutModal = (values) => {

    axios
      .put(
        `${config.apiUrl}/Firm`,
        {
          AccountTransactionId: AccountId,
          Id: values.FirmId,
        },
        config.headers
      )
      .then((res) => {
        if (res.status == 200) {
          updateMatchFirm("success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        } else {
          openNotificationWithIconWarning("error");
        }
      });

  }
  const onFinishUpdate = (values) => {
    axios
      .get(
        `${config.apiUrl}/Firm/GetMathingWordWithFirmId/${values.FirmId}`,
        config.headers
      )
      .then((res) => {
        // setWordList(res.data);
        res.data.map((item) => {
          var model = {
            word: item.word
          }
          wordArray.push(model);
        })
        wordArray.length < 1 ? setWordList([{ word: "" }]) : setWordList(wordArray)
      });
    setFirmIdForVknTckn(values.FirmId)

    axios
      .put(
        `${config.apiUrl}/Firm`,
        {
          AccountTransactionId: AccountId,
          Id: values.FirmId,
        },
        config.headers
      )
      .then((res) => {
        if (res.status == 200) {
          axios.post(`${config.apiUrl}/Firm/GetDiffrentVknTckn`,
            {
              AccountTransactionId: AccountId,
              Id: values.FirmId
            },
            config.headers
          )
            .then((res) => {
              if (res.data.data.oldVkn !== null || res.data.data.oldTckn !== null) {
                showVknTcknModal();
                if (res.data.data.oldVkn !== res.data.data.newVkn) {
                  setOldVkn(res.data.data.oldVkn)
                  setNewVkn(res.data.data.newVkn)
                } else {
                  setOldVkn(undefined)
                  setNewVkn(undefined)
                }
                if (res.data.data.oldTckn !== res.data.data.newTckn) {
                  setOldTckn(res.data.data.oldTckn)
                  setNewTckn(res.data.data.newTckn);
                } else {
                  setOldTckn(undefined)
                  setNewTckn(undefined)
                }
              } else {
                if (res.data.data.AccountIban !== null) {
                  axios.post(`${config.apiUrl}/Firm/GetDiffrentVknTckn`,
                    {
                      AccountTransactionId: AccountId,
                      Id: values.FirmId,
                      IsIbanUpdateValue: true
                    },
                    config.headers
                  )
                    .then((respp) => {
                      if (respp.data.data.accountIban === "") {
                        openNotificationWithIcon("success")
                        showModalWords(true)
                      }
                      else if (respp.data.data.accountIban !== null) {
                        changeWithAccountIban("success");
                        showModalWords(true)
                      } else {
                        firmAccountNotSaved("success");
                        showModalWords(true)
                      }
                      // setTimeout(() => {
                      //     window.location.reload();
                      // }, 4000);
                    })
                    .catch((error) => {
                      if (error.response.status === 403 || error.response.status === 401) {
                        history.push("/not-authorized-access");
                      } else if (error.response.status === 406) {
                        error.response.data.data.map((item) => {
                          errorList.push(item);
                        });
                        errorListNotification("error");
                      } else {
                        openNotificationWithIconWarning("error");
                      }
                    });
                } else {
                  openNotificationWithIcon("success");
                  showModalWords(true);
                }
                // setTimeout(
                //   function () {
                //     window.location.reload();
                //   }.bind(this),
                //   2000
                // );
              }
            })
            .catch((error) => {
              if (error.response.status === 403 || error.response.status === 401) {
                history.push("/not-authorized-access");
              } else if (error.response.status === 406) {
                error.response.data.data.map((item) => {
                  errorList.push(item);
                });
                errorListNotification("error");
              } else {
                openNotificationWithIconWarning("error");
              }
            });
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        } else {
          openNotificationWithIconWarning("error");
        }
      });
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...wordList];
    list[index][name] = value;
    setWordList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...wordList];
    list.splice(index, 1);
    setWordList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setWordList([...wordList, { word: "" }]);
  };
  const onFinishFailedUpdate = (errorInfo) => {
  };
  const onFinishFailedUpdateWords = () => {
    setVisibleWords(false)
  };
  const handleChangeTagFilter = (value) => {
    var query = "";
    value.map((item, index) => {
      if (value.length > 1) {
        if (index == value.length - 1) {
          query += ` transactionTags/any(s:endswith(s, '${item}'))`
        } else {
          query += `transactionTags/any(s:endswith(s, '${item}')) OR `
        }

      } else {
        query += `transactionTags/any(s:endswith(s, '${value}'))`
      }
    })
    setTagQuery(query)

  };
  const getColumns = () => {
    const beforeDynamicColumns = [
      {
        title: "Banka",
        dataIndex: "bankId",
        key: "bankId",
        render: (text, row, index) => {
          return (
            <div style={{ width: 84 }}>
              <img src={row.tenantBankLogo}></img>
            </div>
          );
        },
      },
      isBranchShow === true ?
        {
          title: "Şube",
          dataIndex: "tenantBranchCode",
          key: "tenantBranchCode",
          align: "center",
        } : {},
      isTenantIbanShow === true ?
        {
          title: "Iban / Hesap Numarası",
          align: "center",
          dataIndex: "ibanOrAccountNumber",
          key: "ibanOrAccountNumber",
        } : {},
      {
        title: "Tutar",
        dataIndex: "amountString",
        key: "amountString",
        render: (text, row, index) => {
          {
            return Screen.screenGercek === 2 ? (
              <div style={{ width: 98, whiteSpace: "nowrap" }}>
                {text.includes("-") ? (
                  <div style={{ color: "red" }}>{text}</div>
                ) : (
                  <div style={{ color: "green", whiteSpace: "nowrap" }}>
                    {text}
                  </div>
                )}
              </div>
            ) : (
              <div style={{ width: 98, whiteSpace: "nowrap" }}>{text}</div>
            );
          }
        },
      },
    ];

    if (isHaveAccountRole.data && isBalanceAfterTransactionShow) {
      beforeDynamicColumns.push({
        title: "İşlem Sonrası Bakiye",
        dataIndex: "balanceAfterTransaction",
        render: ColumnHelper.renderDecimal,
      });
    }

    return beforeDynamicColumns.concat([
      isCurrencyShow === true ?
        {
          title: "Döviz",
          dataIndex: "currency",
          align: "center",
          key: "currency",
          render: (text, row, index) => {
            {
              return <div>{text}</div>;
            }
          },
        } : {},
      {
        title: "Tarih",
        dataIndex: "transactionDate",
        align: "center",
        width: "11%",
        key: "transactionDate",
        sorter: (a, b) =>
          moment(a.transactionDateValue).unix() -
          moment(b.transactionDateValue).unix(),
        render: (text, row, index) => {
          {
            return <div> {row.transactionDate} </div>;
          }
        },
      },
      isDescriptionShow === true ?
        {
          title: "Açıklama",
          align: "left",
          dataIndex: "description",
          width: '34%',
          key: "description",
          render: (text, row, index) => (
            <Tooltip placement="topLeft" title={text}>
              <div style={{ whiteSpace: "break-spaces" }}>
                {text?.substring(0, 60) === undefined
                  ? " "
                  : text?.trim().length <= 60
                    ? text + ""
                    : text?.substring(0, 125) + "..."}
              </div>
            </Tooltip>
          ),
        } : {},
      isFirmHidden
        ? isFirmNameShow === true ? {
          title: "Cari Adı",
          dataIndex: "firmName",
          align: "center",
          key: "firmName",
          render: (text, row, index) =>
            text == null ? (
              <div style={{ opacity: 0.7 }}>-</div>
            ) : (
              <Tooltip placement="topLeft" title={text}>
                {text?.substring(0, 15) === undefined ? (
                  <div style={{ opacity: 0.7 }}>-</div>
                ) : text?.substring(0, 15).length < 8 ? (
                  text?.substring(0, 15) + ""
                ) : (
                  text?.substring(0, 8) + "..."
                )}
              </Tooltip>
            ),
        } : {}
        : {},
      {
        title: "",
        dataIndex: "operation",
        width: "1%",
        render: (text, record) =>
          record.firmId === null ||
            record.firmId === "00000000-0000-0000-0000-000000000000" ? (
            isFirmHidden ? (
              <Dropdown
                overlay={menu}
                placement="bottomLeft"
                onClick={() => handleId(record.id)}
                trigger={["click"]}
              >
                <a style={{ color: "red", fontSize: "130%" }}>
                  <WarningOutlined />
                </a>
              </Dropdown>
            ) : (
              <a style={{ color: "red", fontSize: "130%" }}>
                <WarningOutlined />
              </a>
            )
          ) : isFirmHidden ? (
            <Dropdown
              overlay={menuUpdate}
              placement="bottomLeft"
              onClick={() => handleId(record.id)}
              trigger={["click"]}
            >
              <a style={{ color: "green", fontSize: "130%" }}>
                <CheckCircleOutlined />
              </a>
            </Dropdown>
          ) : (
            <a style={{ color: "green", fontSize: "130%" }}>
              <CheckCircleOutlined />
            </a>
          ),
      },
      {
        title: "Etiket",
        dataIndex: "operation",
        width: "1%",
        align: "center",
        render: (text, record) => {
          return (
            <a
              style={{ color: "black", fontSize: "130%" }}
              onClick={() => showModalTag(record.id)}
            >
              {" "}
              <TagsOutlined />
            </a>
          );
        },
      },
      {
        title: "Dekont",
        dataIndex: "operation",
        width: "1%",
        align: "center",
        render: (text, record) => {
          return (
            <a
              style={{ color: "black", fontSize: "130%" }}
              onClick={() => showModalReceipt(record.id)}
            >
              {" "}
              <FilePdfOutlined />
            </a>
          );
        },
      },
    ]);
  };
  const columns = getColumns();
  const detailColumns = [
    isIbanShow === true ?
      {
        title: "Karşı Iban",
        dataIndex: "iban",
      } : {},
    isVknTcknShow === true ?
      {
        title: "Vkn / T.C. No",
        dataIndex: "vkn",
        render: (data, row, index) => data ?? row.tckn,
      } : {},
    isTransactionTypeShow === true ?
      {
        title: "İşlem Tipi",
        dataIndex: "transactionTypeName",
      } : {},
    isSenderNameShow === true ?
      {
        title: "Gönderen Adı",
        dataIndex: "senderName",
      } : {},
    isReceiptShow === true ?
      {
        title: "Dekont No",
        dataIndex: "receiptNumber",
      } : {},
    isDescriptionShow === true ?
      {
        title: "Banka Açıklaması",
        dataIndex: "description",
      } : {},
    isAdditionalDescriptionShow === true ?
      {
        title: "Ek Açıklama",
        dataIndex: "additionalDescription",
      } : {},
  ];
  return (
    <div>
      <Modal
        title="Etiket"
        width={600}
        style={{ height: 1000 }}
        visible={isModalTagVisible}
        onOk={handleOkTag}
        onCancel={handleCancelTag}
        okText="Kaydet"
      >
        <Spin spinning={spinLoading} tip="Loading...">

          <ReactTags
            tags={tags}
            suggestions={suggestions}
            placeholder="Etiket girin"
            onAddHandler={(val) => addTag(val)}
            onDeleteHandler={(idx) => removeTag(idx)}
            onChangeHandler={(val) => handleTagChange(val)}
          />
        </Spin>


      </Modal>
      {isDesktopOrLaptop ? (
        <Modal
          title="Dekont"
          width={1000}
          style={{ height: 1000 }}
          visible={isModalVisibleReceipt}
          onOk={handleOkReceipt}
          onCancel={handleCancelReceipt}
          footer={null}
        >
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinishUpdateReceipt}
            onFinishFailed={onFinishFailedUpdateReceipt}
            fields={[{ name: "Email", value: Email }]}
          >
            <Row>
              <Col span={20}></Col>
              <Col span={2}>
                {EmailVisible ? (
                  <MailOutlined
                    style={{ fontSize: 20, marginBottom: 20 }}
                    onClick={() => setEmailVisible(!EmailVisible)}
                  />
                ) : (
                  <MailFilled
                    style={{ fontSize: 20, marginBottom: 20 }}
                    onClick={() => setEmailVisible(!EmailVisible)}
                  />
                )}
              </Col>
              <Col span={2}>
                <DownloadOutlined
                  style={{ fontSize: 20, marginBottom: 20 }}
                  onClick={() => setDownloadExcel()}
                />
              </Col>
            </Row>
            <Row hidden={EmailVisible}>
              <Col span={18}>
                <Form.Item
                  label="Mail Adresi"
                  name="Email"
                  style={{ width: "100%" }}
                >
                  <Input value={Email} onChange={onChangeEmail} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => sendMail()}
                    loading={loading}
                  >
                    Gönder
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Iframe
            url={pdfUrl}
            width="100%"
            height="1000px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
          />
        </Modal>
      ) : (
        <Modal
          title="Dekont"
          visible={isModalVisibleReceipt}
          onOk={handleOkReceipt}
          onCancel={handleCancelReceipt}
          footer={null}
        >
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinishUpdateReceipt}
            onFinishFailed={onFinishFailedUpdateReceipt}
            fields={[{ name: "Email", value: Email }]}
          >
            <Row>
              <Col span={14}></Col>
              <Col span={5}>
                {EmailVisible ? (
                  <MailOutlined
                    style={{ fontSize: 20, marginBottom: 20 }}
                    onClick={() => setEmailVisible(!EmailVisible)}
                  />
                ) : (
                  <MailFilled
                    style={{ fontSize: 20, marginBottom: 20 }}
                    onClick={() => setEmailVisible(!EmailVisible)}
                  />
                )}
              </Col>
              <Col span={5}>
                <DownloadOutlined
                  style={{ fontSize: 20, marginBottom: 20 }}
                  onClick={() => setDownloadExcel()}
                />
              </Col>
            </Row>
            <Row hidden={EmailVisible}>
              <Col span={12}>
                <Form.Item label="" name="Email" style={{ width: "140%" }}>
                  <Input value={Email} onChange={onChangeEmail} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => sendMail()}
                    loading={loading}
                  >
                    Gönder
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Iframe
            url={pdfUrl}
            width="100%"
            height="400px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
          />
        </Modal>
      )}

      <Tooltip placement="topLeft" title="Excel İndir">
        <a
          style={{
            color: "green",
            fontSize: "180%",
            marginRight: 60,
            float: "right",
          }}
          onClick={exportExcel}
        >
          <FileExcelOutlined />
        </a>
      </Tooltip>
      <p style={{ fontSize: "120%", marginTop: -9 }}>
        <BarsOutlined /> HESAP HAREKETLERİ
      </p>

      <Collapse defaultActiveKey={["0"]}>
        <Panel header="Filtreleme için tıklayınız." key="1">
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Row>
              {isDesktopOrLaptop && (
                <>
                  <Col span={6}>
                    <label>Banka :</label>
                  </Col>
                  <Col span={6}>
                    <label>Iban :</label>
                  </Col>
                  <Col span={6}>
                    <label>Tutar Aralığı :</label>
                  </Col>
                  <Col span={6}>
                    <label>Para Birimi :</label>
                  </Col>

                  <Col span={6}>
                    <Select
                      showSearch
                      name="TenantBankName"
                      value={TenantBankName === "" ? null : TenantBankName}
                      onChange={handleTenantBankName}
                      style={{ width: "100%" }}
                      placeholder="Banka seçiniz"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {bankFilter.map((item) => (
                        <Option key={item.text}>{item.value}</Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={6}>
                    <Input
                      value={TenantIban}
                      onChange={handleTenantIban}
                      name="TenantIban"
                      style={{ width: "100%", marginBottom: "4%" }}
                      placeholder="Iban"
                    />
                  </Col>
                  <Col span={6}>
                    <Input.Group compact>
                      <Input
                        name="Amount2"
                        value={Amount2}
                        onKeyDown={(evt) =>
                          (evt.keyCode === 109 || evt.keyCode === 32) &&
                          evt.preventDefault()
                        }
                        onChange={handleAmount2}
                        style={{ width: "50%", marginBottom: "3%" }}
                        placeholder="Başlangıç"
                      />
                      <Input
                        name="Amount1"
                        value={Amount1}
                        onKeyDown={(evt) =>
                          (evt.keyCode === 109 || evt.keyCode === 32) &&
                          evt.preventDefault()
                        }
                        onChange={handleAmount1}
                        style={{ width: "50%", marginBottom: "3%" }}
                        placeholder="Bitiş"
                      />
                    </Input.Group>
                  </Col>
                  <Col span={6}>
                    <Select
                      disabled={activeKey !== "999" ? true : false}
                      name="Currency"
                      style={{ width: "100%" }}
                      placeholder="Para birimini seçiniz"
                      optionFilterProp="children"
                      onChange={handleCurrency}
                      value={
                        activeKey !== "999"
                          ? null
                          : Currency === ""
                            ? null
                            : Currency
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {getFilterCurrency.map((item) => (
                        <Option key={item.text}>{item.value}</Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={6}>
                    {" "}
                    <label>Tarih Aralığı :</label>
                  </Col>
                  <Col span={6}>
                    {" "}
                    <label>Karşı Iban :</label>
                  </Col>
                  <Col span={6}>Açıklama :</Col>
                  <Col span={6}>Ödeme / Tahsilat Tipi :</Col>

                  <Col span={6}>
                    <RangePicker
                      format={"DD-MM-YYYY"}
                      name="TransactionDate"
                      placeholder={["Başlangıç", "Bitiş"]}
                      Value={[
                        moment(TransactionDate),
                        moment(TransactionDate1),
                      ]}
                      onChange={handleTransactionDate}
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col span={6}>
                    <Input
                      value={Iban}
                      onChange={handleIban}
                      name="Iban"
                      style={{ width: "100%", marginBottom: "4%" }}
                      placeholder="Karşı Iban"
                    />
                  </Col>

                  <Col span={6}>
                    <Input
                      name="Description"
                      value={Description}
                      onChange={handleDescription}
                      style={{ width: "100%" }}
                      placeholder="Açıklama"
                    />
                  </Col>
                  <Col span={6}>
                    <Select
                      style={{ width: "100%", marginBottom: "4%" }}
                      value={
                        TransactionProcessType === ""
                          ? null
                          : TransactionProcessType
                      }
                      name="TransactionProcessType"
                      placeholder="Ödeme/Tahsilat tipi seçiniz"
                      onChange={handleTransactionProcessType}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {teminatDropdown.map((item) => (
                        <Option key={item.value == "Ödeme" ? 2 : 1}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={6}>
                    {" "}
                    <label>Hesap Numarası :</label>
                  </Col>
                  <Col span={6}>
                    {" "}
                    <label>Cari :</label>
                  </Col>
                  <Col span={6}>İşlem Tipi :</Col>
                  <Col span={6}>Etiket</Col>

                  <Col span={6}>
                    <Input
                      name="AccountNumber"
                      value={AccountNumber}
                      onChange={handleAccountNumber}
                      style={{ width: "100%", marginBottom: "6%" }}
                      placeholder="Hesap Numarası"
                    />
                  </Col>
                  <Col span={6}>
                    <Select
                      allowClear
                      showSearch
                      name="FirmId"
                      onChange={handleFirmId}
                      value={firmId === "" ? undefined : firmId}
                      style={{ width: "100%", marginBottom: "3%" }}
                      placeholder="En az 2 harf giriniz.."
                      onSearch={onFirmSearchForFilter}
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                        option.code
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      loading={firmSelectForFilter.loading}
                    >
                      {firmSelectForFilter.data.map((item) => (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          code={item.firmBranchNumber}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={6}>
                    <Select
                      style={{ width: "100%", marginBottom: "4%" }}
                      value={
                        TransactionTypeValue === ""
                          ? null
                          : TransactionTypeValue
                      }
                      name="TransactionTypeValue"
                      placeholder="İşlem tipi seçiniz"
                      onChange={handleTransactionType}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {transactionTypeList.map((item) => (
                        <Option key={item.value}>{item.text}</Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={6}>
                    <Select
                      mode="tags"
                      placeholder="Etiket seçin"
                      onChange={handleChangeTagFilter}
                      style={{
                        width: '100%',
                      }}
                    >
                      {prevTagList.map((i) => {
                        return <Option key={i}>{i}</Option>
                      })}

                    </Select>
                  </Col>

                  <Col span={12}></Col>
                  <Col span={6}>
                    <Button
                      danger
                      size="small"
                      style={{ width: "100%" }}
                      icon={<CloseOutlined />}
                      onClick={() => handleReset()}
                    >
                      Sıfırla
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      primary
                      size="small"
                      icon={<SearchOutlined />}
                      style={{
                        color: "#4d98aa",
                        borderColor: "#4d98aa",
                        width: "100%",
                      }}
                      htmlType="submit"
                    >
                      Filtrele
                    </Button>
                  </Col>
                </>
              )}
              {isTabletOrMobileDevice && (
                <>
                  <Col span={12}>
                    <label>Banka :</label>
                  </Col>
                  <Col span={12}>
                    <label>Iban :</label>
                  </Col>
                  <Col span={12}>
                    <Select
                      showSearch
                      name="TenantBankName"
                      value={TenantBankName === "" ? null : TenantBankName}
                      onChange={handleTenantBankName}
                      style={{ width: "100%" }}
                      placeholder="Banka seçiniz"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {bankFilter.map((item) => (
                        <Option key={item.text}>{item.value}</Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <Input
                      value={TenantIban}
                      onChange={handleTenantIban}
                      name="TenantIban"
                      style={{ width: "100%", marginBottom: "4%" }}
                      placeholder="Iban"
                    />
                  </Col>
                  <Col span={12}>
                    <label>Tutar Aralığı :</label>
                  </Col>
                  <Col span={12}>
                    <label>Para Birimi :</label>
                  </Col>
                  <Col span={12}>
                    <Input.Group compact>
                      <Input
                        name="Amount2"
                        value={Amount2}
                        onKeyDown={(evt) =>
                          evt.keyCode === 109 && evt.preventDefault()
                        }
                        onChange={handleAmount2}
                        style={{ width: "50%", marginBottom: "3%" }}
                        placeholder="Başlangıç"
                      />
                      <Input
                        name="Amount1"
                        value={Amount1}
                        onKeyDown={(evt) =>
                          evt.keyCode === 109 && evt.preventDefault()
                        }
                        onChange={handleAmount1}
                        style={{ width: "50%", marginBottom: "3%" }}
                        placeholder="Bitiş"
                      />
                    </Input.Group>
                  </Col>
                  <Col span={12}>
                    <Select
                      name="Currency"
                      style={{ width: "100%" }}
                      placeholder="Para birimini seçiniz"
                      optionFilterProp="children"
                      onChange={handleCurrency}
                      value={Currency === "" ? null : Currency}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {getFilterCurrency.map((item) => (
                        <Option key={item.text}>{item.value}</Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={12}>
                    {" "}
                    <label>Tarih Aralığı :</label>
                  </Col>
                  <Col span={12}>
                    {" "}
                    <label>Karşı Iban :</label>
                  </Col>
                  <Col span={12}>
                    <RangePicker
                      name="TransactionDate"
                      placeholder={["Başlangıç", "Bitiş"]}
                      Value={[
                        moment(TransactionDate, dateFormat),
                        moment(TransactionDate1, dateFormat),
                      ]}
                      onChange={handleTransactionDate}
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col span={12}>
                    <Input
                      value={Iban}
                      onChange={handleIban}
                      name="Iban"
                      style={{ width: "100%", marginBottom: "4%" }}
                      placeholder="Karşı Iban"
                    />
                  </Col>
                  <Col span={12}>Açıklama :</Col>
                  <Col span={12}>Ödeme / Tahsilat Tipi :</Col>
                  <Col span={12}>
                    <Input
                      name="Description"
                      value={Description}
                      onChange={handleDescription}
                      style={{ width: "100%" }}
                      placeholder="Açıklama"
                    />
                  </Col>
                  <Col span={12}>
                    <Select
                      style={{ width: "100%", marginBottom: "4%" }}
                      value={
                        TransactionProcessType === ""
                          ? null
                          : TransactionProcessType
                      }
                      name="TransactionProcessType"
                      placeholder="Ödeme/Tahsilat tipi seçiniz"
                      onChange={handleTransactionProcessType}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {teminatDropdown.map((item) => (
                        <Option key={item.value == "Ödeme" ? 2 : 1}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={12}>
                    {" "}
                    <label>Hesap Numarası :</label>
                  </Col>
                  <Col span={12}>
                    {" "}
                    <label>Cari :</label>
                  </Col>

                  <Col span={12}>
                    <Input
                      name="AccountNumber"
                      value={AccountNumber}
                      onChange={handleAccountNumber}
                      style={{ width: "100%", marginBottom: "6%" }}
                      placeholder="Hesap Numarası"
                    />
                  </Col>
                  <Col span={12}>
                    <Select
                      allowClear
                      showSearch
                      name="FirmId"
                      onChange={handleFirmId}
                      value={firmId === "" ? undefined : firmId}
                      style={{ width: "100%", marginBottom: "3%" }}
                      placeholder="En az 2 harf giriniz.."
                      onSearch={onFirmSearchForFilter}
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                        option.code
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      loading={firmSelectForFilter.loading}
                    >
                      {firmSelectForFilter.data.map((item) => (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          code={item.firmBranchNumber}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={12}>İşlem Tipi</Col>
                  <Col span={12}>Etiket</Col>

                  <Col span={12}>
                    <Select
                      style={{ width: "100%", marginBottom: "4%" }}
                      value={
                        TransactionTypeValue === ""
                          ? null
                          : TransactionTypeValue
                      }
                      name="TransactionTypeValue"
                      placeholder="İşlem tipi seçiniz"
                      onChange={handleTransactionType}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {transactionTypeList.map((item) => (
                        <Option key={item.value}>{item.text}</Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <Select
                      mode="tags"
                      placeholder="Etiket seçin"
                      onChange={handleChangeTagFilter}
                      style={{
                        width: '100%',
                      }}
                    >
                      {prevTagList.map((i) => {
                        return <Option key={i}>{i}</Option>
                      })}

                    </Select>
                  </Col>

                  <Col span={12}>
                    <Button
                      danger
                      size="small"
                      style={{ width: "100%" }}
                      icon={<CloseOutlined />}
                      onClick={() => handleReset()}
                    >
                      Sıfırla
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      primary
                      size="small"
                      icon={<SearchOutlined />}
                      style={{
                        color: "#4d98aa",
                        borderColor: "#4d98aa",
                        width: "100%",
                      }}
                      htmlType="submit"
                    >
                      Filtrele
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </Panel>
      </Collapse>
      <br></br>
      <Drawer
        title="Cari Ekle"
        width={241}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
      >
        <FirmAddForm AccountId={AccountId} SenderName={senderName} Tckn={Tckn} Vkn={Vkn} Id={Id} />
      </Drawer>
      <Modal
        title="Kayıtlı Cari Eşleştir"
        visible={visibled}
        onCancel={handleCancel}
        closable={true}
        footer={[]}
      >
        <Row justify="end">
          <Form
            {...layout}
            name="basic"
            style={{ width: "100%" }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinishUpdate}
            onFinishFailed={onFinishFailedUpdate}
          >
            <Col>
              <Form.Item
                label="Cari Adı/Ünvanı"
                name="FirmId"
                rules={[
                  {
                    required: true,
                    message:
                      "Lütfen Cari Adı/Ünvanı tipi alanını boş bırakmayın.",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={onChange}
                  placeholder="En az 2 harf giriniz.."
                  onSearch={onFirmSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                    option.code
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                  }
                  loading={firmSelect.loading}
                >
                  {firmSelect.data.map((item) => (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                      code={item.firmBranchNumber}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              span={24}
              style={{
                textAlign: "right",
              }}
            >
              <Form.Item {...tailLayouttwo}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  htmlType="submit"
                  onClick={() => setLoading(true)}
                  loading={loading}
                >
                  Eşleştir
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Row>
      </Modal>
      <Modal
        title="Kayıtlı Cari Güncelle"
        visible={visibleUpdate}
        onCancel={handleCancelUpdate}
        closable={true}
        footer={[]}
      >
        <Row justify="end">
          <Form
            {...layout}
            name="basic"
            style={{ width: "100%" }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinishUpdateWithoutModal}
            onFinishFailed={onFinishFailedUpdate}
            fields={[{ name: "FirmId", value: firmId }]}
          >
            <Col>
              <Form.Item
                label="Cari Adı/Ünvanı"
                name="FirmId"
                rules={[
                  {
                    required: true,
                    message:
                      "Lütfen Cari Adı/Ünvanı tipi alanını boş bırakmayın.",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={onChange}
                  placeholder="En az 2 harf giriniz.."
                  onSearch={onFirmSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                    option.code
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                  }
                  loading={firmSelect.loading}
                >
                  {firmSelect.data.map((item) => (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                      code={item.firmBranchNumber}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              span={24}
              style={{
                textAlign: "right",
              }}
            >
              <Form.Item {...tailLayouttwo}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  htmlType="submit"
                  onClick={() => setLoading(true)}
                  loading={loading}
                >
                  Güncelle
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Row>
      </Modal>

      <Modal
        title="Kelime Eşleştir"
        visible={visibledWords}
        onCancel={handleCancelWords}
        closable={true}
        footer={[]}
      >

        <p>
          Hesap hareketindeki açıklama alanına göre Cari için eşleştirmede kullanılmak üzere kelime girişi yapmak ister misiniz ?
        </p>
        <p style={{ marginBottom: 20 }} >
          Açıklama :
          <p>
            <strong>
              {descriptionForMatch}
            </strong>
          </p>

        </p>
        <p></p>
        <p>Cariye ait mevcut kelimeleriniz varsa aşağıda listelenmiştir. Yeni kelime eklemek için + butonunu kullanabilirsiniz.</p>
        <p></p>
        <Row justify="end">

          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinishUpdateWords}
            onFinishFailed={onFinishFailedUpdateWords}
          >
            <Form.Item
              label="Kelimeler"
              name="words"
            >
              {
                wordList.map((x, i) => {
                  return (
                    <Row>
                      <Col span={20} style={{ marginLeft: '-6%' }}>
                        <Input
                          name="word"
                          placeholder="Kelime Girin"
                          value={x.word}
                          onChange={e => handleInputChange(e, i)}
                        />
                      </Col>
                      <Col span={2}>
                        {wordList.length !== 1 && <Button
                          className="mr10"
                          onClick={() => handleRemoveClick(i)}><MinusOutlined /></Button>}
                        {wordList.length - 1 === i && <Button onClick={handleAddClick}><PlusOutlined /></Button>}
                      </Col>
                      <Col></Col>
                    </Row>
                  );
                })}
            </Form.Item>
            <Form.Item {...tailLayout} >
              <Button type="primary" icon={<PlusOutlined />} htmlType="submit" onClick={() => setLoadingForWords(true)} loading={loadingForWords}>
                Güncelle
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Modal>






      {isTabletOrMobile === true ? (
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: (row) =>
              RowHelper.renderRowDetail({
                data: row,
                columns: detailColumns,
              }),
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Veri Yok"
              />
            ),
          }}
          style={{ maxHeight: "100%" }}
          scroll={{ x: 1150 }}
          size="small"
          rowKey="id"
          filterable={true}
          sortable={true}
          pagination={{
            total: accounts.total,
            pageSizeOptions: ["15", "25", "50", "100"],
            defaultPageSize: take,
            current: currentPage,
          }}
          pageable={true}
          onDataStateChange={dataStateChange}
          onChange={paging}
          dataSource={accounts.data}
          loading={accounts.loading}
        />
      ) : (
        <Tabs
          style={{ marginLeft: "2%" }}
          onChange={handleChange}
          activeKey={activeKey}
        >
          {currencyTRList.length === 0 ? null : (
            <TabPane tab={<span>TÜMÜ</span>} key="999">
              {ContactSideBar()}
            </TabPane>
          )}
          {currencyTRList.length === 0
            ? null
            : currencyTRList.map((item, index) => {
              return (
                <TabPane
                  tab={<span>{item.currency}</span>}
                  key={item.currencyId}
                >
                  {ContactSideBar(item.currency)}
                </TabPane>
              );
            })}
        </Tabs>
      )}
      <Modal
        title=""
        visible={vknTcknModal}
        onOk={onOkTcknVkn}
        onCancel={onCancelTcknVkn}
        okText="Güncelle"
        cancelText="Vazgeç"
      >
        <p></p>
        <p></p>
        <p>
          Hesap Hareketindeki
          {newTckn === undefined ? "" : " Tckn"}
          {newVkn === undefined ? "" : " Vkn "} alanı ile firmada bulunan
          {newTckn === undefined ? "" : " Tckn"}
          {newVkn === undefined ? "" : " Vkn "} alanı farklı.
        </p>
        <p> </p>

        <p> {oldTckn === undefined ? "" : "Hesap Hareketindeki Tckn : " + oldTckn}  </p>
        <p> {oldTckn === undefined ? "" : "Firmadan Gelen Tckn : " + (newTckn === null ? "BOŞ" : newTckn)} </p>
        <p> {oldVkn === undefined ? "" : "Hesap Hareketindeki Vkn : " + oldVkn}  </p>
        <p> {oldVkn === undefined ? "" : "Firmadan Gelen Vkn : " + (newVkn === null ? "BOŞ" : newVkn)} </p>
        <p></p>
        <p>Alanları güncellemek ister misiniz?</p>
      </Modal>
    </div>
  );
};

export default AccountPage;
