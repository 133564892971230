import React, { useState, useEffect } from "react";
import { Col, Row, DatePicker, Card, Form, Spin } from "antd";
import {
  InfoCircleOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import RequestHandler from "util/RequestHandler";
import moment from "moment";

const DbsSentInvoiceSummary = (props) => {
  const [summaryData, setSummaryData] = useState({
    data: { total: 0, success: 0, error: 0 },
    loading: false,
  });
  const [dateRange, setDateRange] = useState([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);

  useEffect(() => getData(), [dateRange]);

  const getData = () => {
    {
      setSummaryData({
        ...summaryData,
        loading: true,
      });

      const [startDate, endDate] = dateRange;
      RequestHandler.post(
        `/DbsSentInvoice/SentInvoiceSummary`,
        {
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
        },
        false
      ).then((response) => {
        setSummaryData({
          data: response.success ? response.data : summaryData,
          loading: false,
        });
      });
    }
  };

  return (
    <Row id="summaryArea" style={{ ...props.style }}>
      <Col span={24}>
        <Card>
          <Row align="middle">
            <Col xs={24} md={12} lg={6}>
              <Form.Item label="Gönderilme Tarihi">
                <DatePicker.RangePicker
                  format="DD.MM.YYYY"
                  allowClear={false}
                  defaultValue={dateRange}
                  disabled={summaryData.loading}
                  onChange={(dates) => setDateRange(dates)}
                />
              </Form.Item>
            </Col>

            {summaryData.loading ? (
              <Col xs={24} md={12} lg={18} style={{ textAlign: "center" }}>
                <Spin />
              </Col>
            ) : (
              <>
                <Col xs={24} md={12} lg={6} style={{ textAlign: "center" }}>
                  <p>
                    <InfoCircleOutlined
                      style={{ color: blue.primary, fontSize: "150%" }}
                    />
                  </p>
                  <h4>Toplam: {summaryData.data.total}</h4>
                </Col>

                <Col xs={24} md={12} lg={6} style={{ textAlign: "center" }}>
                  <p>
                    <CheckCircleOutlined
                      style={{ color: "green", fontSize: "150%" }}
                    />
                  </p>
                  <h4>Başarılı: {summaryData.data.success}</h4>
                </Col>

                <Col xs={24} md={12} lg={6} style={{ textAlign: "center" }}>
                  <p>
                    <ExclamationCircleOutlined
                      style={{ color: "red", fontSize: "150%" }}
                    />
                  </p>
                  <h4>Hatalı: {summaryData.data.error}</h4>
                </Col>
              </>
            )}
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
export default DbsSentInvoiceSummary;
