import moment from "moment";

const dateFormat = "YYYY-MM-DD";
const momentDate = (value) => {
  const date = moment(value);
  return date.isValid() ? date : moment();
};

export const odata = {
  contains: (columnName, value) => {
    value = value.replaceAll("'", '"');
    return `contains(tolower(${columnName}), tolower('${value}'))`;
  },
  notContains: (columnName, value) => {
    value = value.replaceAll("'", '"');
    return `not contains(tolower(${columnName}), tolower('${value}'))`;
  },
  dateRange: (columnName, startDate, endDate) => {
    const formatedStartDate = momentDate(startDate).format(dateFormat);
    const formatedEndDate = momentDate(endDate)
      .add(1, "days")
      .format(dateFormat);

    return `date(${columnName}) ge ${formatedStartDate} and date(${columnName}) lt ${formatedEndDate}`;
  },
  dateCompare: (columnName, compareLetter, date) => {
    const formatedDate = momentDate(date).format(dateFormat);

    return `date(${columnName}) ${compareLetter} date(${formatedDate})`;
  },
  dateCompareValueBefore: (date, compareLetter, columnName) => {
    return odata.dateCompare(columnName, compareLetter, date);
  },
  ConditionalRange: (columnName, minValue, maxValue) => {
    const conditions = [];

    if (minValue || minValue === 0) {
      conditions.push(`${columnName} Ge ${minValue}`);
    }

    if (maxValue || maxValue === 0) {
      conditions.push(`${columnName} Le ${maxValue}`);
    }

    return conditions.join(" and ");
  },
  orderBy: (columnName) => {
    return `$orderby=${columnName} asc`;
  },
  orderByDesc: (columnName) => {
    return `$orderby=${columnName} desc`;
  },
};

export const queryHelper = {
  getQueryFromValues: (values) => {
    return Object.keys(values)
      .filter(key => values[key] || values[key] === 0)
      .map(key => `${key}=${values[key]}`)
      .join("&");
  },
  getFilterValuesFromQuery: () => {
    const queryString = new URLSearchParams(window ? window.location.search : {});
    const values = {};
    const ignoredKeys = ["skip", "take", "current", "order", "filter"];
    queryString.forEach((value, key) => {
      if (!ignoredKeys.includes(key)) {
        values[key] = value;
      }
    })

    return values;
  },
  getFilterFromQuery: () => {
    const queryString = new URLSearchParams(window ? window.location.search : {});
    const filter = queryString.get("filter");

    return filter ? decodeURIComponent(filter) : "";
  },
  getPagingValuesFromQuery: () => {
    const queryString = new URLSearchParams(window ? window.location.search : {});
    const values = {};

    ["skip", "take", "current", "order"].forEach((key) => {
      const value = queryString.get(key);
      if (value || value === "0") {

        values[key] = key === "order" ? value : parseInt(value);
      }
    })

    return values;
  },
  getDefaultTableDataQueryOptions: () => Object.assign({
    skip: 0,
    take: 15,
    current: 1,
    order: "",
    filter: queryHelper.getFilterFromQuery(),
    values: queryHelper.getFilterValuesFromQuery(),
  }, queryHelper.getPagingValuesFromQuery()),
};
