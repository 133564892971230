import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import {
  defaultValidateMessages,
  onInputMaxLengthCheck,
  onKeyDownNumberOnly,
} from "./Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const TosBankInfoUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [activeBankId, setActiveBankId] = useState();
  const [bankRequirement, setBankRequirement] = useState({});

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setActiveBankId(props.data.bankId);
    }
  }, [props.data]);


  const onFinish = (values) => {
    RequestHandler.put(`/TosBankInfo`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        form.resetFields();

        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="tosBankInfoUpdate"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="bankId" hidden>
            <Input />
          </Form.Item>
            <>
                <Form.Item
                  label={
                      "Müşteri Numarası"
                  }
                  name="customerNumber"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input maxLength="125" onInput={onInputMaxLengthCheck} />
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }} label="Host" required>
                  <Input.Group>
                    <Row gutter={2}>
                      <Col span={18}>
                          <Form.Item
                            name="host"
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "'Host' alanı gerekli.",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                      </Col>
                      <Col span={6}>
                          <Form.Item
                            name="port"
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "'Port' alanı gerekli.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Port"
                              onKeyDown={(e) => onKeyDownNumberOnly(e)}
                              maxLength="5"
                              onInput={onInputMaxLengthCheck}
                            />
                          </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Form.Item>,
                <Form.Item
                  label={
                       "Giden Dosya Yolu"
                  }
                  name="downloadFolder"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={
                       "Gelen Dosya Yolu"
                  }
                  name="uploadFolder"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={
                       "Kullanıcı Adı"
                  }
                  name="userName"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Şifre"
                  name="password"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
            </>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Güncelle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default TosBankInfoUpdateForm;
