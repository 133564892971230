import React, { useState, useEffect } from "react";
import Widget from "components/Widget/index";
import RequestHandler from "util/RequestHandler";
import { ColumnHelper } from "util/TableHelper";
import CurrencyFormat from "react-currency-format";


import { Row, Col, Table, Empty, Tooltip, } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

import moment from "moment";

const PosCollectionTransactionSummary = () => {
  const [tableData, setWidgetData] = useState({
    loading: true,
  });

  useEffect(() => getData(), []);

  const getData = () => {
    RequestHandler.get("/B2BDashboard/LastCollections").then((response) => {
      setWidgetData({
        data: response.data,
        loading: false,
      });
    });
  };

  const renderCurrencyFormat = (data) => (
    <CurrencyFormat
      value={data}
      displayType={"text"}
      decimalSeparator={","}
      thousandSeparator={"."}
      prefix={"₺"}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  )

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      width: 150,
      render: (text) => <img src={text}></img>,
    },
    {
      title: "Cari Kodu",
      dataIndex: "firmCode",
      render: (data) => data ? data : "-",
    },
    {
      title: "Ünvan",
      dataIndex: "firmName",
      render: (data, row) => row.firmCode ? data : row.customerInformation,
    },
    {
      title: "Tahsilat Tutarı",
      dataIndex: "amount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Para Birimi",
      dataIndex: "currencyCode",
    },
    {
      title: "Taksit Sayısı",
      dataIndex: "installmentCount"
    },
    {
      title: "İşlem Tarihi",
      dataIndex: "addDate",
      align: "center",
      render: (data) => moment(data).format("DD.MM.YYYY HH:mm"),
    },
    {
      title: "İşlem Sonucu",
      dataIndex: "success",
      align: "center",
      render: (data, row) => {
        if (data === null) {
          return (
            <Tooltip title="Sonuç Bekliyor">
              <ClockCircleOutlined style={{ color: "orange", fontSize: "130%" }} />
            </Tooltip>
          )
        }

        return data ? (
          <Tooltip title={row.message}>
            <CheckCircleOutlined style={{ color: "green", fontSize: "130%" }} />
          </Tooltip>
        ) : (
          <Tooltip title={row.message}>
            <CloseCircleOutlined style={{ color: "red", fontSize: "130%" }} />
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Widget
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0">
            Son İşlemler
          </h2>
        }
        extra={
          <p className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
            <a href="/pos-collection-transaction">Detay</a>{" "}
            <i className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle" />
          </p>
        }
      >
        <Row id="tableArea">
          <Col span={24}>
            <Table
              columns={columns}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Veri Yok"
                  />
                ),
              }}
              style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
              rowKey="id"
              filterable={false}
              sortable={false}
              pageable={false}
              pagination={false}
              dataSource={tableData.data}
              loading={tableData.loading}
              size="small"
            />
          </Col>
        </Row>
      </Widget>
    </>
  );
};

export default PosCollectionTransactionSummary;
