import React, { useState,useEffect } from 'react'
import {Row,Col,Checkbox,Input,Button} from 'antd'
import {UserOutlined,UpOutlined} from "@ant-design/icons";
import { TimePicker,notification } from 'antd';
import moment from 'moment';
import RequestHandler from "util/RequestHandler";
import CurrencyInput from 'react-currency-input';
const format = 'HH:mm';

const NotificationUpdate = (props) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(true);
    const [notificationUpdateVisibility, setNotificationUpdateVisibility] = useState(false);
    const [updateFormData, setUpdateFormData] = useState({});
    const [time, setTime] = useState(props.data.time);
    const [mail, setMail] = useState(props.data.mail);
    const [id, setId] = useState(props.data.id);
    const [selectedList, setSelectedList] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [upMoney, setUpMoney] = useState(props.data.upMoney);
    const [accountBalance, setAccountBalance] = useState(props.data.accountBalance);
    const [balance, setBalance] = useState(props.data.balance);
    const [consolidated, setConsolidated] = useState(props.data.consolidated);
    const [moneyInflow, setMoneyInflow] = useState(props.data.moneyInflow);
    const [moneyOut, setMoneyOut] = useState(props.data.moneyOut);

    useEffect(() => {
        setId(props.data.id)
        props.data.time === "" ? setTime("00:00") :setTime(props.data.time);
        setUpMoney(props.data.upMoney)
        setMail(props.data.mail)
        setBalance(props.data.balance)
        setAccountBalance(props.data.accountBalance)
        setConsolidated(props.data.consolidated)
        setMoneyInflow(props.data.moneyInflow)
        setMoneyOut(props.data.moneyOut)
    }, [
      props.data.upMoney,
        props.data.id,
        props.data.mail,
        props.data.time,
        props.data.accountBalance,
        props.data.balance,
        props.data.consolidated,
        props.data.moneyInflow,
        props.data.moneyOut,
    ])
    const onChangeUpMoney = (e) => {
        setUpMoney(e)
      };
      const openNotificationWithIcon = type => {
        notification[type]({
          message: 'Uyarı',
          description:
            'Lütfen boş alanları doldurun.',
        });
      };
    const setSubmit = () => {
      if((time === "") && (balance || accountBalance || consolidated)){
          timeError("warning")
        return;
      }
        setLoading(true)
        RequestHandler.put("/Notification", {
          Id:id,
          UpMoney:upMoney,
          Mail: mail,
          Time: balance === true ? time : (consolidated === true ? time : (accountBalance === true ? time :"")),
          Balance: balance,
          AccountBalance: accountBalance,
          MoneyInflow: moneyInflow,
          MoneyOut: moneyOut,
          Consolidated: consolidated
        }).then((response) => {
            if(response.statusCode === 200 || response.statusCode === 204){
                setTimeout(function () {
                    props.sendDataForUpdate("success");
                    setLoading(false)
                   }.bind(this), 2000)
            }else{
                setLoading(false)
            }
            
        });
    };
    const timeError = type => {
      notification[type]({
        message: 'Uyarı',
        description:
          'Lütfen saati tam veya buçuk olacak şekilde seçiniz.',
      });
    };
      const onChangeTime = time => {
        if( moment(time?.toString()).format('LT').split(':')[1] !== "00" &&  moment(time?.toString()).format('LT').split(':')[1] !== "30"){
          if(time !== null){
            timeError("warning")
          }
          return;
        }
          if(time != null){
            setTime(moment(time?.toString()).format('LT'))
          }
      };
      const onChangeMail = (e) => {
        setMail(e.target.value)
      };
      const onChangeBalance = (e) => {
        setBalance(!balance)
      };
      const onChangeAccountBalance = (e) => {
        setAccountBalance(!accountBalance)
      };
      const onChangeConsolidated = (e) => {
        setConsolidated(!consolidated)
      };
      const onChangeMoneyInFlow = (e) => {
        setMoneyInflow(!moneyInflow)
      };
      const onChangeMoneyOut = (e) => {
        setMoneyOut(!moneyOut)
      };
        return (
            <>
                        <Row >
                            <Col md={12} xs={24}>
                            <Checkbox value="balance" checked={balance} onClick={onChangeBalance} >Banka Bakiyelerinin mail ile gönderilmesi </Checkbox>
                            </Col>

                            <Col md={12} xs={24}>
                            <Checkbox value="accountBalance" checked={accountBalance} onClick={onChangeAccountBalance}>Banka Hesap Bakiyelerinin mail ile gönderilmesi </Checkbox>
                            </Col>
                            
                            <Col md={12} xs={24}>
                            <Checkbox value="consolidated" checked={consolidated} onClick={onChangeConsolidated}>Konsolide </Checkbox>
                            </Col>

                            <Col md={12} xs={24}>
                            <Checkbox value="moneyInflow" checked={moneyInflow} onClick={onChangeMoneyInFlow}>Hesaplara para girişinde bilgi verilmesi</Checkbox>
                            </Col>

                            <Col md={12} xs={24}>
                            <Checkbox value="moneyOut" checked={moneyOut} onClick={onChangeMoneyOut}>Hesaplardan para çıkışında bilgi verilmesi</Checkbox>
                            </Col>
                           
                        </Row>
               
                <Row style={{marginTop:'4%'}}>
                    <Col md={7} xs={12} >
                        <Input style={{width:'100%'}} placeholder="E-mail Adresi" value={mail} onChange={onChangeMail} prefix={<UserOutlined />} />
                    </Col>
                    <Col md={5} xs={12} >
                      <CurrencyInput className="ant-input" style={{width:"100%"}} 
                        disabled={moneyOut === true ? false:(moneyInflow === true ? false:true)} 
                        placeholder="Tutardan fazlası" value={upMoney} onChange={onChangeUpMoney} 
                         decimalSeparator="," thousandSeparator="." />
                  
                    </Col>
                    <Col md={6} xs={12} >
                        <TimePicker
                        disabled={
                          moneyOut === true ? 
                          (consolidated === true ? false : 
                            (balance === true ? false : ( accountBalance === false ? true: false) 
                             )) : (
                            moneyInflow === true ? 
                            (consolidated === true ? false : 
                              (balance === true ? false : ( accountBalance === false ? true: false) )) :
                            false
                          )
                        }
                        placeholder={"Saat Seçin"} minuteStep={30} value={moment(time,format)} format={format} onChange={onChangeTime}/>
                    </Col>
                    <Col md={6} xs={12} >
                    <Button type="primary" loading={loading} onClick={() => setSubmit()}>
                        Güncelle
                    </Button>
                    </Col>
                  
                </Row>

            </>
        )
}
export default NotificationUpdate;