import React,{useEffect,useState} from "react";
import AmCharts from "@amcharts/amcharts3-react";
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Row,Col,notification } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import configs from "../../util/ApiforContext"
const expiration = localStorage.getItem("expiration");
const FirmStatusReportChart = (props) => {
    const history = useHistory();
    const paymentChartListbefore = [];
    const [firmStatusReport, setFirmStatusReport] = useState([]);
    const [paymentChartList, setPaymentChartList] = useState([]);
    const errorList = [];
    const errorListNotification = type => {
      errorList.map((item)=> {
        notification[type]({
          message: 'Hata',
          description:
          item
           ,
            duration: 2,
        });
      })
    };
    useEffect(() => {
      if(expiration<Date.now()){
        localStorage.removeItem("expiration");
        localStorage.removeItem("token");
        window.location.reload();
      }
      if(localStorage.getItem("token")==null){
        window.location.reload()
      }
        axios.post(`${configs.apiUrl}/Reports`,{
          FirmId: props.match.params.firmId === 'null' ? null : props.match.params.firmId,
        CollectPlanTotal : props.match.params.CollectPlanTotal === '0' ? 0 : props.match.params.CollectPlanTotal,
        CollectPlanTotal2 : props.match.params.CollectPlanTotal2 === '0' ? 0 : props.match.params.CollectPlanTotal2,
        CollectTotal: props.match.params.CollectTotal === '0' ? 0 : props.match.params.CollectTotal,
        CollectTotal2:props.match.params.CollectTotal2 === '0' ? 0 : props.match.params.CollectTotal2,
        PaymentPlanTotal: props.match.params.PaymentPlanTotal === '0' ? 0 : props.match.params.PaymentPlanTotal,
        PaymentPlanTotal2:props.match.params.PaymentPlanTotal2 === '0' ? 0 : props.match.params.PaymentPlanTotal2,
        PaymentTotal:props.match.params.PaymentTotal === '0' ? 0 : props.match.params.PaymentTotal,
        PaymentTotal2:props.match.params.PaymentTotal2 === '0' ? 0 : props.match.params.PaymentTotal2,
        CurrencyCode:props.match.params.CurrencyCode === 'null' ? null : props.match.params.CurrencyCode,
        Date:props.match.params.Date === 'undefined' ? null : props.match.params.Date,
        EndDate:props.match.params.Date2 === 'undefined' ? null : props.match.params.Date2
        },configs.headers)
        .then(res => {
            res.data.value.map((item)=>{
                 const newObject = {
                    FirmId: item.firmId,
                    PaymentTotal: item.paymentTotal,
                 };
                 paymentChartListbefore.push(newObject)
            })
            setPaymentChartList(paymentChartListbefore)
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }else if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
            }
          }
      );
    }, [setFirmStatusReport])
  const config = {
    "type": "pie",
    "theme": "light",
    "dataProvider": paymentChartList,
    "valueField": "PaymentTotal",
    "titleField": "FirmId",
    "balloon": {
      "fixedPosition": true
    },
    "export": {
      "enabled": false
    }
  };

  return (
    <div className="App">
     <Row style={{marginBottom:9}}>
         <Col flex={1}><a onClick={() => history.goBack()} style={{color:'black',marginLeft:"4%"}}> <RollbackOutlined /> GERİ DÖN</a></Col>
         <Col flex={4}></Col>
         </Row>
      <AmCharts.React style={{width: "100%", height: "500px"}} options={config}/>
      <Row>
      <Col flex={3}></Col>
      <Col flex={3}><p style={{fontSize:'200%'}}>GERÇEKLEŞEN ÖDEME</p><br></br></Col>
    </Row>
      
    </div>
  )
}

export default FirmStatusReportChart;
