import React, { useState } from "react";
import RequestHandler from "util/RequestHandler";
import DbsValueReportMonthly from "./DbsValueReportMonthly";
import DbsValueReportDaily from "./DbsValueReportDaily";
import config from "util/ApiforContext";

import { Tabs, Col, Row, Tooltip, message } from "antd";
import { BarsOutlined, FileExcelOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;

const DbsValueReport = () => {
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [reportFilters, setReportFilters] = useState({
    daily: {},
    monthly: {},
  });

  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);

    switch (activeTabKey) {
      case "1":
        RequestHandler.post(
          "/DbsReport/ExportMonthlyValueReportExcel",
          reportFilters.monthly
        ).then((response) => {
          hideMessage();

          if (response.success) {
            window.open(
              `${config.apiUrl
              }/DbsReport/DownloadMonthlyValueReportExcel?fileName=${response.data
              }&t=${RequestHandler.getToken()}`
            );
          }
        });
        break;
      case "2":
        RequestHandler.post(
          "/DbsReport/ExportDailyValueReportExcel",
          reportFilters.daily
        ).then((response) => {
          hideMessage();

          if (response.success) {
            window.open(
              `${config.apiUrl
              }/DbsReport/DownloadDailyValueReportExcel?fileName=${response.data
              }&t=${RequestHandler.getToken()}`
            );
          }
        });
        break;

      default:
        hideMessage();
        break;
    }
  };

  const onMonthlyFilterChange = (filterData) => {
    setReportFilters({ ...reportFilters, monthly: filterData });
  };

  const onDailyFilterChange = (filterData) => {
    setReportFilters({ ...reportFilters, daily: filterData });
  };

  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> İLERİ YÖNELİK ALACAKLAR RAPORU
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
          <Tooltip title="Excel İndir">
            <a
              style={{
                color: "green",
                fontSize: "180%",
                marginRight: 30,
              }}
              onClick={onClickExportExcel}
            >
              <FileExcelOutlined />
            </a>
          </Tooltip>
        </Col>
      </Row>

      <Tabs
        defaultActiveKey="1"
        onChange={(activeKey) => {
          setActiveTabKey(activeKey);
        }}
      >
        <TabPane tab="Ay Bazlı" key="1">
          <DbsValueReportMonthly onFilterChange={onMonthlyFilterChange} />
        </TabPane>
        <TabPane tab="Gün Bazlı" key="2">
          <DbsValueReportDaily onFilterChange={onDailyFilterChange} />
        </TabPane>
      </Tabs>
    </>
  );
};

export default DbsValueReport;
