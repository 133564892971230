import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import RequestHandler from "util/RequestHandler";
import { ColumnHelper } from "util/TableHelper";

import DbsDealerTableFilter from "../Filters/DbsDealerTableFilter";
import DbsDealerAddForm from "../Forms/DbsDealerAddForm";
import DbsDealerUpdateForm from "../Forms/DbsDealerUpdateForm";
import moment from "moment";
import config from "../../util/ApiforContext";

import {
  Table,
  Empty,
  Col,
  Row,
  Button,
  Drawer,
  Popconfirm,
  Tooltip,
  Typography,
  Tabs,
  message,
} from "antd";
import {
  BarsOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
  FileExcelOutlined,
  RollbackOutlined
} from "@ant-design/icons";
import { odata } from "../Filters/Helper";


const DbsDealer = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    activeTab: "0",
    filter: "",
  });
  const [addDealerVisibility, setAddDealerVisibility] = useState(false);
  const [updateDealerVisibility, setUpdateDealerVisibility] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({});
  const [order, setOrder] = useState("");
  const history = useHistory();

  useEffect(() => getTableData(), [order, tableDataQueryOptions]);

  const getFilterQuery = () => {
    const { filter, activeTab } = tableDataQueryOptions;

    const conditions = [];
    let query = "";

    if (props.match.params.bankId && !filter) {
      conditions.push(`bankId eq ${props.match.params.bankId}`);
    }

    switch (activeTab) {

      case "1": //Limiti Olmayan Bayiler
        conditions.push(`availability le 0`);
        break;

      case "2": //Güncel Olmayan Bayiler
        conditions.push(odata.dateCompare("lastQueryDate", "lt", moment()));
        break;

      case "3": //Hatalı Bayiler
        conditions.push(
          "isHaveWarning eq true"
        );
        break;
    }

    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length !== 0) {
      query = "$filter=" + conditions.join(" and ");
    }
    return query;
  };

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });
    const { skip, take } = tableDataQueryOptions;

    RequestHandler.get(
      `/DbsDealer?$count=true&${getFilterQuery()}${order}&$skip=${skip}&$top=${take}`
    ).then((response) => {
      if (Array.isArray(response.value)) {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      }
    });
  };

  const paging = (p, filters, sorter) => {
    let order = "";
    if (sorter.columnKey && sorter.order) {
      order =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }
    setOrder(order);
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };

  const handleOnClickUpdate = (record) => {
    RequestHandler.get(`/DbsDealer/GetById/${record.id}`).then((response) => {
      if (response) {
        setUpdateFormData(response);
        setUpdateDealerVisibility(true);
      }
    });
  };

  const handleOnClickDelete = (id) => {
    RequestHandler.delete(`/DbsDealer/${id}`).then((response) => {
      if (response.success) {
        getTableData();
      }
    });
  };

  const onAddDealerSuccess = () => {
    getTableData();
    setAddDealerVisibility(false);
  };

  const onUpdateDealerSuccess = () => {
    getTableData();
    setUpdateDealerVisibility(false);
  };

  const onTabChange = (tabKey) => {
    setTableDataQueryOptions({
      ...tableDataQueryOptions,
      skip: 0,
      current: 1,
      activeTab: tabKey,
    });
  };

  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);

    RequestHandler.post(
      `/DbsDealer/ExportExcel`,
      {
        query: getFilterQuery(),
      },
      false
    ).then((response) => {
      hideMessage();

      if (response) {
        window.open(
          `${config.apiUrl
          }/DbsDealer/DownloadExcel?fileName=${response}&t=${RequestHandler.getToken()}`
        );
      }
    });
  };

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      width: 150,
      render: (text) => <img src={text}></img>,
    },
    {
      title: "Bayi Adı",
      dataIndex: "name",
    },
    {
      title: "Bayi Kodu",
      dataIndex: "code",
    },
    {
      title: "Tanımlı Limit",
      dataIndex: "limit",
      key: "limit",
      sorter: true,
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Kullanılabilir Limit",
      key: "availability",
      dataIndex: "availability",
      sorter: true,
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Risk",
      dataIndex: "risk",
      key: "risk",
      sorter: true,
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Limit Aşımı",
      dataIndex: "limitExcess",
      render: (data) =>
        data > 0 ? (
          <Typography.Text type="danger">
            {ColumnHelper.renderDecimal(data)}
          </Typography.Text>
        ) : (
          "-"
        ),
    },
    {
      title: "İleri Tarihli Fatura Toplamı",
      dataIndex: "invoiceTotal",
      render: (data) => (data ? ColumnHelper.renderDecimal(data) : "-"),
    },
    {
      title: "Para Birimi",
      render: () => "TRY",
    },
    {
      title: "İleri Tarihli Fatura Adedi",
      dataIndex: "invoiceCount",
      render: (data) => data ?? "-",
    },
    {
      title: "Sorgu Tarihi",
      dataIndex: "lastQueryDate",
      key: "lastQueryDate",
      sorter: true,
      align: "center",
      render: (data) => (data ? moment(data).format("DD.MM.YYYY HH:mm") : "-"),
    },
    {
      title: "Uyarı",
      dataIndex: "isHaveWarning",
      align: "center",
      render: (data, row) => {
        return data ? (
          <Tooltip title={row.warningMessage}>
            <WarningOutlined style={{ color: "orange", fontSize: "130%" }} />
          </Tooltip>
        ) : undefined;
      },
    },
    {
      title: "Eşleştirme",
      dataIndex: "isFirmMatch",
      align: "center",
      render: (data) => {
        return data ? (
          <CheckCircleOutlined style={{ color: "green", fontSize: "130%" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red", fontSize: "130%" }} />
        );
      },
    },
    {
      title: "Düzenle",
      dataIndex: "id",
      align: "center",
      render: (_, row) => (
        <a onClick={() => handleOnClickUpdate(row)}>
          <EditOutlined style={{ color: "orange", fontSize: "130%" }} />
        </a>
      ),
    },
    {
      title: "Sil",
      dataIndex: "id",
      align: "center",
      render: (data) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleOnClickDelete(data)}
        >
          <a>
            <DeleteOutlined style={{ color: "red", fontSize: "130%" }} />
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      {props.match.params.bankId && (
        <Row style={{ marginBottom: 9 }}>
          <Col flex={1}>
            <a
              onClick={() => history.goBack()}
              style={{ color: "black", marginLeft: "4%" }}
            >
              {" "}
              <RollbackOutlined /> GERİ DÖN
            </a>
          </Col>
          <Col flex={4}></Col>
        </Row>
      )}

      <Row>
        <Col xs={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> BAYİ BİLGİLERİ
          </p>
        </Col>
        <Col xs={8}>
          <Row justify="end" align="middle" style={{ paddingRight: 16 }}>
            <Tooltip title="Excel İndir">
              <a
                style={{
                  color: "green",
                  fontSize: "180%",
                  marginRight: 30,
                }}
                onClick={onClickExportExcel}
              >
                <FileExcelOutlined />
              </a>
            </Tooltip>

            {!props.match.params.bankId && (
              <Button
                type="primary"
                onClick={() => {
                  setAddDealerVisibility(true);
                }}
                style={{ marginBottom: 0 }}
              >
                <PlusOutlined /> Bayi Ekle
              </Button>
            )}

          </Row>
        </Col>
      </Row>

      <DbsDealerTableFilter
        data={{ bankId: props.match.params.bankId ? parseInt(props.match.params.bankId) : undefined }}
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Tabs
        defaultActiveKey={tableDataQueryOptions.activeTab}
        onChange={onTabChange}
      >
        <Tabs.TabPane tab="Tüm Bayiler" key="0" />
        <Tabs.TabPane tab="Limiti Olmayan Bayiler" key="1" />
        <Tabs.TabPane tab="Güncel Olmayan Bayiler" key="2" />
        <Tabs.TabPane tab="Hatalı Bayiler" key="3" />
      </Tabs>

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Drawer
        id="addDealerDrawer"
        title="Bayi Ekle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setAddDealerVisibility(false)}
        visible={addDealerVisibility}
      >
        <DbsDealerAddForm onSuccess={onAddDealerSuccess} />
      </Drawer>

      <Drawer
        id="updateDealerDrawer"
        title="Bayi Güncelle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setUpdateDealerVisibility(false)}
        visible={updateDealerVisibility}
      >
        <DbsDealerUpdateForm
          data={updateFormData}
          onSuccess={onUpdateDealerSuccess}
        />
      </Drawer>
    </>
  );
};

export default DbsDealer;
