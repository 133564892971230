import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Form,
  Col,
  notification,
  Button,
  Select,
  Checkbox,
} from "antd";
import { useHistory } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import config from "../../util/ApiforContext";
import axios from "axios";
const expiration = localStorage.getItem("expiration");
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 11,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const TenantAccountUpdateForm = (props) => {
  const { Option } = Select;

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const errorList = [];
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 2,
      });
    });
  };
  const [form] = Form.useForm();

  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }
    setLoading(false);

    if (props.data) {
      props.data.typeValue =
        props.data.typeValue == null
          ? undefined
          : props.data.typeValue.toString();
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onFinishUpdate = (values) => {
    axios
      .put(
        `${config.apiUrl}/TenantAccount`,
        {
          Id: values.id,
          Name: values.name,
          IsCalculated: values.isCalculated,
          Type: parseInt(values.typeValue),
          ErpCode: values.erpCode,
        },
        config.headers
      )
      .then((res) => {
        if (res.status == 200) {
          openNotificationWithIconUpdate("success");
          setTimeout(
            function () {
              props.sendDataForUpdate("success");
              setLoading(false);
            }.bind(this),
            2000
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
          setLoading(false);
        } else {
          openNotificationWithIconWarningUpdate("error");
          setLoading(false);
        }
      });
  };

  const openNotificationWithIconUpdate = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Güncelleme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });
  };

  const openNotificationWithIconWarningUpdate = (type) => {
    notification[type]({
      message: "HATA",
      description: "Güncelleme işlemi sırasında bir hata oluştu.",
      duration: 2,
    });
  };

  const onFinishFailedUpdate = (errorInfo) => {
    setLoading(false);
  };

  return (
    <div>
      <Form
        {...layout}
        name="basic"
        form={form}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinishUpdate}
        onFinishFailed={onFinishFailedUpdate}
      >
        <Row>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Col span={24}>
            <Form.Item
              style={{ marginTop: "4%" }}
              name="name"
              label="Banka Hesap Adı"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="isCalculated"
              label="Hesaplanma"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Banka Hesap Tipi" name="typeValue">
              <Select
                style={{ width: "100%" }}
                placeholder="Tip seçiniz"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
              >
                <Option key="0">Vadesiz</Option>
                <Option key="1">Kredi</Option>
                <Option key="2">Vadeli</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Cari Kod" name="erpCode">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}></Col>
          <Col span={6}></Col>
          <Col span={6}></Col>
          <Col span={6}>
            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                htmlType="submit"
                onClick={() => setLoading(true)}
                loading={loading}
              >
                Güncelle
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default TenantAccountUpdateForm;
