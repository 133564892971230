import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Select, Space } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const DbsBankInfoAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: false,
  });
  const [currencySelect, setCurrencySelect] = useState({
    data: [],
    loading: false,
  });
  const [activeBankId, setActiveBankId] = useState();
  const [bankRequirements, setBankRequirements] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    getNotAddedBanks();
    getCurrencies();
    getBankInfoRequirements();
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const getNotAddedBanks = () => {
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    RequestHandler.get(`/DbsBankInfo/NotAddedBanks?$select=id,name`).then(
      (response) => {
        setBankSelect({
          data: response,
          loading: false,
        });
      }
    );
  };

  const getCurrencies = () => {
    setCurrencySelect({
      ...currencySelect,
      loading: true,
    });
    RequestHandler.get(`/Currency?$select=id,code`).then((response) => {
      setCurrencySelect({
        data: response.value,
        loading: false,
      });
    });
  };

  const getBankInfoRequirements = () => {
    RequestHandler.get(`/DbsBankInfoRequirement`).then((response) => {
      if (Array.isArray(response.value)) {
        setBankRequirements(response.value);
      }
    });
  };

  const onFinish = (values) => {
    if (values.currencyIds) {
      values.currencies = [];
      values.currencyIds.forEach((currencyId) => {
        values.currencies.push({ currencyId: currencyId });
      });
    }

    RequestHandler.post(`/DbsBankInfo`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        getNotAddedBanks();
        form.resetFields();
        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onFinishFailed = () => setLoading(false);

  const renderCurrencyInput = (isCurrencyBasedDbsCodeRequired) => {
    return isCurrencyBasedDbsCodeRequired ? (
      <Form.Item
        name="currencies"
        label="Para Birimi & Dbs No"
        rules={[
          {
            required: true,
          },
          {
            validator: (rule, values, callback) => {
              if (values?.length > 1) {
                const currencyIds = values.map((item) => item?.currencyId);
                var isDuplicate = currencyIds.some(
                  (item, idx) => currencyIds.indexOf(item) != idx
                );

                if (isDuplicate) {
                  return callback("Her para birimi bir kez eklenebilir.");
                }
              }

              callback();
            },
          },
        ]}
      >
        <Form.List name="currencies">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space key={key} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, "currencyId"]}
                    fieldKey={[fieldKey, "currencyId"]}
                    rules={[
                      {
                        required: true,
                        message: "'Para Birimi' alanı gerekli.",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Seçiniz"
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      loading={currencySelect.loading}
                    >
                      {currencySelect.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.code}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "dbsCode"]}
                    fieldKey={[fieldKey, "dbsCode"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "'Dbs Numarası' alanı gerekli.",
                      },
                    ]}
                  >
                    <Input placeholder="Dbs Numarası" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Ekle
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    ) : (
      <Form.Item
        name="currencyIds"
        label="Para Birimi"
        rules={[{ required: true }]}
      >
        <Select
          allowClear
          showSearch
          placeholder="Seçiniz"
          mode="multiple"
          filterOption={(input, option) =>
            option.children
              .toLocaleLowerCase("tr-TR")
              .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
          }
          loading={currencySelect.loading}
        >
          {currencySelect.data.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.code}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  const bankRequirement = bankRequirements.find(
    (x) => x.bankId === activeBankId
  );

  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="dbsBankInfoAdd"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="bankId" label="Banka" rules={[{ required: true }]}>
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              onChange={(value) => setActiveBankId(value)}
              loading={bankSelect.loading}
            >
              {bankSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {bankRequirement && (
            <>

              {bankRequirement.isUserNameRequired && (
                <Form.Item
                  label={bankRequirement.userNameLabel ?? "Kullanıcı Adı"}
                  name="userName"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              {bankRequirement.isPasswordRequired && (
                <Form.Item
                  label="Şifre"
                  name="password"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
              )}

              {bankRequirement.isDbsCodeRequired && (
                <Form.Item
                  label={bankRequirement.dbsCodeLabel ?? "Dbs Numarası"}
                  name="dbsCode"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              {bankRequirement.isOptionalDbsCodeRequired && (
                <Form.Item
                  label={bankRequirement.optionalDbsCodeLabel ?? "Opsiyonel Dbs Numarası"}
                  name="optionalDbsCode"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              {bankRequirement.isServiceUrlRequired && (
                <Form.Item
                  label={bankRequirement.serviceUrlLabel ?? "Servis Url"}
                  name="serviceUrl"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              {renderCurrencyInput(bankRequirement.isCurrencyBasedDbsCodeRequired)}

            </>
          )}

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Ekle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default DbsBankInfoAddForm;
