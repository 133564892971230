import React, { useState, useEffect } from "react";
import {
  Table,
  Empty,
  Drawer,
  Col,
  Row,
  notification,
  Popconfirm,
  Modal,
  Button,
} from "antd";
import {
  PlusOutlined,
  BarsOutlined,
  EditOutlined,
  DeleteOutlined,
  KeyOutlined,
  FieldTimeOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";
import config from "../../util/ApiforContext";
import { useHistory } from "react-router-dom";
import UserAddForm from "../Forms/UserAddForm";
import UserUpdate from "../Forms/UserUpdateForm";
import axios from "axios";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
const expiration = localStorage.getItem("expiration");
const openNotificationWithIconDelete = (type) => {
  notification[type]({
    message: "BAŞARILI",
    description: "Silme işlemi başarı ile gerçekleşti.",
    duration: 2,
  });
};
const openNotificationWithIconWarningDelete = (type) => {
  notification[type]({
    message: "HATA",
    description: "Silme işlemi sırasında bir hata oluştu.",
    duration: 2,
  });
};

const UserList = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [user, setUser] = useState({ data: [], total: 0, loading: true });
  const [dataState, setDataState] = useState([{ skip: 0, take: 15 }]);
  const [visible, setVisible] = useState(false);
  const [firstName, setFirstName] = useState(10);
  const [lastName, setLastName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTosAuthorize, setIsTosAuthorize] = useState();
  const [tosAuthorizeLimit, setTosAuthorizeLimit] = useState();
  const [tosAuthorizeId, setTosAuthorizeId] = useState();
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [wrongLoginList, setWrongLoginList] = useState([]);
  const [erpSalesCode, seterpSalesCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [take, setTake] = useState(15);
  const [skip, setSkip] = useState(0);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const errorList = [];
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 2,
      });
    });
  };
  const history = useHistory();
  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }

    setUser({
      ...user,
      loading: true,
    });

    axios
      .get(
        `${config.apiUrl}/TenantUser?$count=true&$skip=${skip}&$top=${take}`,
        config.headers
      )
      .then((res) => {
        setUser({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  }, [setDataState,take,setTake, skip, setSkip]);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'Başarılı',
      description:
        'İşlem başarı ile gerçekleşti.',
        duration: 2,
    });
};
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleDelete = (key) => {
    axios
      .delete(`${config.apiUrl}/User/DeleteTenantUser/${key}`, config.headers)
      .then((res) => {
        if (res.status == 204 || res.status == 200) {
          openNotificationWithIconDelete("success");
          setUser({
            data: user.data.filter((item) => item.id !== key),
            total: user.total - 1,
          });
          if (user.data.filter((item) => item.id !== key).length == 0) {
            window.location.reload();
          }
        } else {
          openNotificationWithIconWarningDelete("error");
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        } else {
          openNotificationWithIconWarningDelete("error");
        }
      });
  };
  const showWrongLogin = (value) => {
    axios
      .get(`${config.apiUrl}/User/lastWrongLogin/${value}`, config.headers)
      .then((res) => {
        var list=[];
        res.data.map((item)=>{
          list.push(item.addDate)
        })
        setWrongLoginList(list)
        showModal();
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  }
  const paging = (p) => {
    setDataState((prevState) => {
      prevState.skip = (p.current - 1) * p.pageSize;
      setSkip(prevState.skip);
      setTake(p.pageSize);
      setCurrentPage(p.current);
      return {
        prevState,
      };
    });
  };
  const showDrawerUpdate = (value,isTosAuthorize,tosAuthorizeId,tosAuthorizeLimit) => {
    axios
      .get(`${config.apiUrl}/User/getbyid/${value}`, config.headers)
      .then((res) => {
        setFirstName(res.data.firstName);
        setLastName(res.data.lastName);
        setEmail(res.data.email);
        setPhoneNumber(res.data.phoneNumber);
        setUserId(res.data.id);
        setIsTosAuthorize(isTosAuthorize);
        setTosAuthorizeId(tosAuthorizeId);
        setTosAuthorizeLimit(tosAuthorizeLimit);
        setVisibleUpdate(true);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
      axios
      .get(`${config.apiUrl}/User/GetSalesCode/${value}`, config.headers)
      .then((res) => {
        seterpSalesCode(res.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };
  const goTenantIdPage = (value) => {
    history.push(`user-roles/${value}`);
  };
  const goAccountAccessPage = (value) => {
    history.push(`user-accounts-roles/${value}`);
  };
  const goEntityPermissionPage = (tenantUserId) => {
    history.push(`entity-permission/${tenantUserId}`);
  };

  const dataStateChange = (e) => {
    setUser(e);
  };

  const setTosAuthorize = (tenantUserId) => {
    axios.post(`${config.apiUrl}/TosAuthorize`,
    {TenantUserId:tenantUserId},
    config.headers)
      .then(res => {
        if(res.status==200){
          openNotificationWithIcon('success');
          reList();
        }
      }).catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };

  const onClose = () => {
    setVisibleUpdate(false);
    setVisible(false);
  };
  const onCloseUpdate = () => {
    setVisibleUpdate(false);
  };
  const reList = () => {
    setUser({
      ...user,
      loading: true,
    });

    axios
      .get(
        `${config.apiUrl}/TenantUser?$count=true&$skip=${skip}&$top=${take}`,
        config.headers
      )
      .then((res) => {
        setUser({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };
  const getData = (val) => {
    if (val === "success") {
      reList();
      setVisibleUpdate(false);
      setVisible(false);
    }
  };
  const columns = [
    {
      title: "Ad",
      dataIndex: "firstName",
      align: "center",
    },
    {
      title: "Soyad",
      dataIndex: "lastName",
      align: "center",
    },
    {
      title: "E-posta Adresi",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Tos Limit",
      dataIndex: "tosAuthorizeLimit",
      align:'center',
      key: "tosAuthorizeLimit",
    },
    {
      title: "Tos Yetki",
      dataIndex: "tosAuthorize",
      align:'center',
      key: "tosAuthorize",
      render: (text, record) =>
      record.isTosAuthorize ? 
      <a
          onClick={() => {
            setTosAuthorize(record.id);
          }}
          style={{ color: "green", fontSize: "130%" }}
        >
         <CheckCircleOutlined />
        </a>
        :
        <a
          onClick={() => {
            setTosAuthorize(record.id);
          }}
          style={{ color: "red", fontSize: "130%" }}
        >
          <CloseCircleOutlined />
        </a>
    },
    {
      title: "Son 5 Hatalı Giriş",
      dataIndex: "wrongLogin",
      align:'center',
      key: "wrongLogin",
      render: (text, record) =>
      user.data.length >= 1 ? (
        <a
          onClick={() => {
            showWrongLogin(record.userId);
          }}
          style={{ color: "red", fontSize: "130%" }}
        >
          <WarningOutlined />
        </a>
      ) : null,
    },
    {
      title: "Ekran Yetkilendirme",
      dataIndex: "id",
      align: "center",
      render: (text, record) =>
        user.data.length >= 1 ? (
          <a
            onClick={() => {
              goTenantIdPage(record.userId);
            }}
            style={{ color: "black", fontSize: "130%" }}
          >
            <KeyOutlined />
          </a>
        ) : null,
    },
    {
      title: "Hesap Yetkilendirme",
      dataIndex: "id",
      align: "center",
      render: (text, record) =>
        user.data.length >= 1 ? (
          <a
            onClick={() => {
              goAccountAccessPage(record.userId);
            }}
            style={{ color: "black", fontSize: "130%" }}
          >
            <KeyOutlined />
          </a>
        ) : null,
    },
    {
      title: "İşlem Yetkilendirme",
      dataIndex: "id",
      align: "center",
      render: (text, record) =>
        record.isAdmin !== true ? (
          <a
            onClick={() => {
              goEntityPermissionPage(record.id);
            }}
            style={{ color: "black", fontSize: "130%" }}
          >
            <KeyOutlined />
          </a>
        ) : null,
    },
    {
      title: "Düzenle",
      dataIndex: "id",
      align: "center",
      render: (text, record) =>
        user.data.length >= 1 ? (
          <a
            onClick={() => {
              showDrawerUpdate(record.userId,record.isTosAuthorize,record.tosAuthorizeId,record.tosAuthorizeLimit);
            }}
            style={{ color: "orange", fontSize: "130%" }}
          >
            <EditOutlined />
          </a>
        ) : null,
    },
    {
      title: "Sil",
      dataIndex: "id",
      align: "center",
      render: (text, record) =>
        user.data.length >= 1 ? (
          record.isAdmin !== true ? (
            <Popconfirm
              title="Silmek istiyor musunuz?"
              okText="Evet"
              cancelText="Hayır"
              onConfirm={() => handleDelete(record.id)}
            >
              <a style={{ color: "red", fontSize: "130%" }}>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          ) : null
        ) : null,
    },
  ];
  return (
    <>
      <Row>
        <Col xs={16}>
          <p style={{ fontSize: "120%", marginTop: -9 }}>
            <BarsOutlined /> KULLANICI LİSTESİ
          </p>
        </Col>
        <Col xs={8} style={{ marginTop: -9, textAlign: "right" }}>
          <Button
            type="primary"
            onClick={() => {
              setVisible(true);
            }}
          >
            <PlusOutlined /> Kullanıcı Ekle
          </Button>
        </Col>
      </Row>
      <Drawer
        title="Kullanıcı Güncelle"
        width={241}
        onClose={onCloseUpdate}
        visible={visibleUpdate}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <UserUpdate
          firstName={firstName}
          lastName={lastName}
          erpSalesCode = {erpSalesCode}
          phoneNumber={phoneNumber}
          email={email}
          isTosAuthorize = {isTosAuthorize}
          tosAuthorizeId = {tosAuthorizeId}
          tosAuthorizeLimit = {tosAuthorizeLimit}
          sendDataForUpdate={getData}
          userId={userId}
        />
      </Drawer>
      <Drawer
        title="Kullanıcı Ekle"
        width={241}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <UserAddForm />
      </Drawer>
      <Row>
        {isDesktopOrLaptop && (
          <>
            <Col span={2}></Col>
            <Col span={20}>
              <Table
                columns={columns}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Veri Yok"
                    />
                  ),
                }}
                style={{ overflowX: "auto", maxHeight: "100%" }}
                size="small"
                rowKey="id"
                filterable={true}
                sortable={true}
                pageable={true}
                onChange={paging}
                onDataStateChange={dataStateChange}
                pagination={{
                  pageSizeOptions: ["15", "25", "50", "100"],
                  defaultPageSize: take,
                  total: user.total,
                  current: currentPage,
                }}
                dataSource={user.data}
                loading={user.loading}
              />
            </Col>
            <Col span={2}></Col>
          </>
        )}
        {isTabletOrMobileDevice && (
          <>
            <Col span={24}>
              <Table
                columns={columns}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Veri Yok"
                    />
                  ),
                }}
                style={{ overflowX: "auto", width: "100%", maxHeight: "100%" }}
                size="small"
                rowKey="id"
                filterable={true}
                sortable={true}
                pageable={true}
                onChange={paging}
                pagination={{ pageSize: 20, total: user.total }}
                dataSource={user.data}
                loading={user.loading}
              />
            </Col>
          </>
        )}
      </Row>
      <Modal 
      visible={isModalVisible} 
      onOk={handleOk} 
      title={<div style={{textAlign:'center'}}>Son 5 Hatalı Giriş</div>}
      width={300}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      okButtonProps={{hidden: true}}
      onCancel={handleCancel}>
        <div>
        {wrongLoginList.length >0 ?
        wrongLoginList.map((item)=>{
          return <p style={{textAlign:'center'}} ><FieldTimeOutlined style={{color:'darkred'}} /> {moment(item).format('L LTS')}</p>
        }) : <p style={{textAlign:'center'}}>Hatalı Giriş Bulunmamaktadır.</p>}
        </div>
      </Modal>
    </>
  );
};

export default UserList;
