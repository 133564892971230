import React, { useState, useEffect } from "react";
import {
  Table,
  Empty,
  Form,
  DatePicker,
  Select,
  Col,
  Input,
  Row,
  Collapse,
  notification,
  Button,
  Typography,
} from "antd";
import {
  BarsOutlined,
  SearchOutlined,
  CloseOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import config from "../../util/ApiforContext";
import CurrencyFormat from "react-currency-format";
import axios from "axios";
import moment from "moment";
import ReplaceDate from "../../util/Extensions/DateReplaceExtension";
import { useMediaQuery } from "react-responsive";
function currencyFormat(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};
function disabledDate(current) {
  var d = new Date();
  var n = d.getFullYear();
  const start = moment(`${n - 2}-01-01`, "YYYY-MM-DD");
  const end = moment(`${n + 3}-01-01`, "YYYY-MM-DD");
  return current < start || current > end;
}
const expiration = localStorage.getItem("expiration");

const { Option } = Select;
const { RangePicker } = DatePicker;
const SubscriberFirmStatusReport = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  const [firmStatusReport, setFirmStatusReport] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [take, setTake] = useState(15);
  const [CollectTotal2, setCollectTotal2] = useState("");
  const [CollectPlanTotal2, setCollectPlanTotal2] = useState("");
  const [PaymentTotal2, setPaymentTotal2] = useState("");
  const [PaymentPlanTotal2, setPaymentPlanTotal2] = useState("");
  const [CollectTotal1, setCollectTotal1] = useState("");
  const [CollectPlanTotal1, setCollectPlanTotal1] = useState("");
  const [Currency, setCurrency] = useState("");
  const [PaymentTotal1, setPaymentTotal1] = useState("");
  const [PaymentPlanTotal1, setPaymentPlanTotal1] = useState("");
  const [skip, setSkip] = useState(0);
  const [TransactionDate, setTransactionDate] = useState("");
  const [TransactionDate2, setTransactionDate2] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [firmId, setFirmId] = useState("");
  const [firmAddress, setFirmAddress] = useState("");
  const [firmDropdown, setFirmDropdown] = useState([]);
  const [query, setQuery] = useState("$orderby=collectPlanTotal desc");
  const [order, setOrder] = useState("");
  const [dataState, setDataState] = useState({ skip: 0, take: 10 });
  const history = useHistory();
  const errorList = [];
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 2,
      });
    });
  };
  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }

    setFirmStatusReport({
      ...firmStatusReport,
      loading: true,
    });
    axios
      .post(
        `${config.apiUrl}/SubscriberFirmReport/? ${query} &$count=true&$skip=${skip}&$top=${take}`,
        {},
        config.headers
      )
      .then((res) => {
        setFirmStatusReport({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  }, [setDataState, skip, setSkip, query, setQuery]);
  const onSearch = (val) => {
    if (val.length >= 3) {
      axios
        .get(
          `${config.apiUrl}/SubscriberFirm?$filter=contains(name, '${val}')`,
          config.headers
        )
        .then((res) => {
          setFirmDropdown(res.data.value);
        })
        .catch((error) => {
          if (
            error.response?.status === 403 ||
            error.response?.status === 401
          ) {
            history.push("/not-authorized-access");
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            });
            errorListNotification("error");
          }
        });
    } else {
      setFirmDropdown([]);
    }
  };

  const paging = (p) => {
    setDataState((prevState) => {
      prevState.skip = (p.current - 1) * p.pageSize;
      setSkip(prevState.skip);
      setTake(p.pageSize);
      setCurrentPage(p.current);
      return {
        prevState,
      };
    });
  };
  const GoReportDetailAccountPage = (value) => {
    history.push(`report-firm-account-transaction/${value}/null/null`);
  };
  const GoReportDetailCollectChart = (value) => {
    var Date = TransactionDate === "" ? null : TransactionDate;
    var Date2 = TransactionDate2 === "" ? null : TransactionDate2;
    var CurrencyCode = Currency === "" ? null : Currency;
    var FirmId = firmId === "" ? null : firmId;
    var CollectTotal = CollectPlanTotal1 === "" ? 0 : CollectPlanTotal1;
    var CollectTotal22 = CollectPlanTotal2 === "" ? 0 : CollectPlanTotal2;
    var CollectPlanTotal = CollectTotal1 === "" ? 0 : CollectTotal1;
    var CollectPlanTotal22 = CollectTotal2 === "" ? 0 : CollectTotal2;
    var PaymentPlanTotal = PaymentTotal1 === "" ? 0 : PaymentTotal1;
    var PaymentPlanTotal22 = PaymentTotal2 === "" ? 0 : PaymentTotal2;
    var PaymentTotal = PaymentPlanTotal1 === "" ? 0 : PaymentPlanTotal1;
    var PaymentTotal22 = PaymentPlanTotal2 === "" ? 0 : PaymentPlanTotal2;
    if (Date != null) {
      if (Date.includes("/")) {
        var newDAte = ReplaceDate(Date);
        var newDAte2 = ReplaceDate(Date2);
      } else {
        newDAte = Date;
        newDAte2 = Date2;
      }
    }
    history.push(
      `subscriberfirm-status-report-chart-collect/${newDAte}/${newDAte2}/${CurrencyCode}/${FirmId}/${CollectTotal}/${CollectTotal22}/${CollectPlanTotal}/${CollectPlanTotal22}/${PaymentPlanTotal}/${PaymentPlanTotal22}/${PaymentTotal}/${PaymentTotal22}`
    );
  };

  const handleTransactionDate = (value) => {
    setTransactionDate(value !== null ? value[0]?.format("MM/DD/YYYY") : null);
    setTransactionDate2(value !== null ? value[1]?.format("MM/DD/YYYY") : null);
  };

  const handleReset = (clearFilters) => {
    setSkip(0);
    setTake(15);
    setFirmId("");
    setFirmAddress("");
    setCollectTotal1("");
    setCollectTotal2("");
    setCollectPlanTotal1("");
    setCollectPlanTotal2("");
    setPaymentTotal1("");
    setPaymentTotal2("");
    setTransactionDate("");
    setTransactionDate2("");
    setPaymentPlanTotal1("");
    setPaymentPlanTotal2("");
    setCurrentPage(1);

    setFirmStatusReport({
      ...firmStatusReport,
      loading: true,
    });
    axios
      .post(
        `${config.apiUrl}/SubscriberFirmReport? ${query} &$count=true&$top=${take}`,
        {},
        config.headers
      )
      .then((res) => {
        setFirmStatusReport({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };
  const dataStateChange = (e) => {
    setFirmStatusReport(e);
  };
  const handleFirmId = (value) => {
    setFirmId(value);
  };
  const handleFirmAddress = (value) => {
    setFirmAddress(value.target.value);
  };
  const handleCollectTotal1 = (value) => {
    setCollectTotal1(value.target.value);
  };
  const handleCollectTotal2 = (value) => {
    setCollectTotal2(value.target.value);
  };
  const handleCollectPlanTotal1 = (value) => {
    setCollectPlanTotal1(value.target.value);
  };
  const handleCollectPlanTotal2 = (value) => {
    setCollectPlanTotal2(value.target.value);
  };

  const onFinishForFilter = (values) => {
    {
      setFirmStatusReport({
        ...firmStatusReport,
        loading: true,
      });
      let queryFilter = "$orderby=collectPlanTotal desc";
      if (firmAddress !== undefined && firmAddress !== "") {
        queryFilter = `$filter=Contains(firmName,'${firmAddress}')&$orderby=collectPlanTotal desc `;
      } else {
        queryFilter = "$orderby=collectPlanTotal desc";
      }
      axios
        .post(
          `${config.apiUrl}/SubscriberFirmReport? ${queryFilter} &$count=true&$top=${take}`,
          {
            FirmId: firmId === "" ? null : firmId,
            CollectTotal: CollectPlanTotal1 === "" ? 0 : CollectPlanTotal1,
            CollectTotal2: CollectPlanTotal2 === "" ? 0 : CollectPlanTotal2,
            CollectPlanTotal: CollectTotal1 === "" ? 0 : CollectTotal1,
            CollectPlanTotal2: CollectTotal2 === "" ? 0 : CollectTotal2,
            PaymentPlanTotal: PaymentTotal1 === "" ? 0 : PaymentTotal1,
            PaymentPlanTotal2: PaymentTotal2 === "" ? 0 : PaymentTotal2,
            PaymentTotal: PaymentPlanTotal1 === "" ? 0 : PaymentPlanTotal1,
            PaymentTotal2: PaymentPlanTotal2 === "" ? 0 : PaymentPlanTotal2,
            CurrencyCode: Currency === "" ? null : Currency,
            Date: TransactionDate === "" ? null : TransactionDate,
            EndDate: TransactionDate2 === "" ? null : TransactionDate2,
          },
          config.headers
        )
        .then((res) => {
          setFirmStatusReport({
            data: res.data.value,
            total: res.data["@odata.count"],
            loading: false,
          });
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push("/not-authorized-access");
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            });
            errorListNotification("error");
          }
        });
    }
  };
  const columns = [
    {
      title: "Daire",
      dataIndex: "firmId",
      key: "firmId",
      align: "center",
      width: "10%",
    },
    {
      title: "Apartman",
      dataIndex: "firmName",
      key: "firmName",
      align: "center",
      width: "10%",
    },
    {
      title: "Tahsilat Planı",
      dataIndex: "collectPlanTotal",
      key: "collectPlanTotal",
      align: "center",
      width: "10%",
      render: (text, record) => {
        if (text === null) {
          return <div>{null}</div>;
        } else {
          if (text.toString().includes(".") === true) {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </div>
            );
          } else {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
                {",00"}
              </div>
            );
          }
        }
      },
    },
    {
      title: "Gerçekleşen Tahsilat",
      dataIndex: "collectTotal",
      key: "collectTotal",
      align: "center",
      width: "10%",
      render: (text, record) => {
        if (text === null) {
          return <div>{null}</div>;
        } else {
          if (text.toString().includes(".") === true) {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </div>
            );
          } else {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
                {",00"}
              </div>
            );
          }
        }
      },
    },
    {
      title: "Kalan Tahsilat",
      dataIndex: "accountBalanceTotal",
      key: "accountBalanceTotal",
      align: "center",
      width: "10%",
      render: (text, record) => {
        if (text === null) {
          return <div>{null}</div>;
        } else {
          if (text.toString().includes(".") === true) {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </div>
            );
          } else {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
                {",00"}
              </div>
            );
          }
        }
      },
    },
    {
      title: "Detay",
      dataIndex: "operation",
      width: "1%",
      align: "right",
      align: "center",
      render: (text, record) =>
        firmStatusReport.data.length >= 1 ? (
          <a
            onClick={() => {
              GoReportDetailAccountPage(record.id);
            }}
            style={{ fontSize: "130%" }}
          >
            <MenuUnfoldOutlined />
          </a>
        ) : null,
    },
  ];
  return (
    <div>
      <Row>
        {isTabletOrMobileDevice && (
          <>
            <Col span={24}>
              <p style={{ fontSize: "120%" }}>
                <BarsOutlined /> DAİRE DURUM RAPORU
              </p>
              <hr></hr>
              <br></br>
            </Col>
          </>
        )}
        {isDesktopOrLaptop && (
          <>
            <Col span={20}>
              <p style={{ fontSize: "120%", marginTop: -9 }}>
                <BarsOutlined /> DAİRE DURUM RAPORU
              </p>
            </Col>
            <Col span={4}></Col>
          </>
        )}
        <Col span={24}>
          <Collapse defaultActiveKey={["0"]}>
            <Collapse.Panel
              key="1"
              showArrow={false}
              header="Filtreleme için tıklayınız."
              extra={
                <Typography>
                  <Typography.Paragraph
                    style={{
                      marginBottom: 0,
                      fontSize: "80%",
                    }}
                    type="secondary"
                  >
                    Varsayılan olarak bu aydan itibaren ± 6 aylık veri
                    listelenmektedir.
                  </Typography.Paragraph>
                </Typography>
              }
            >
              <Form
                {...layout}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinishForFilter}
              >
                <Row>
                  {isDesktopOrLaptop && (
                    <>
                      <Col span={6}>Apartman :</Col>
                      <Col span={6}>Daire :</Col>
                      <Col span={6}>Tahsilat Planı :</Col>
                      <Col span={6}>Gerçekleşen Tahsilat :</Col>

                      <Col span={6}>
                        <Input
                          name="FirmAddress"
                          value={firmAddress}
                          onChange={handleFirmAddress}
                          placeholder="Apartman Adı"
                        ></Input>
                      </Col>
                      <Col span={6}>
                        <Select
                          showSearch
                          name="FirmId"
                          onChange={handleFirmId}
                          value={firmId === "" ? null : firmId}
                          style={{ width: "100%", marginBottom: "3%" }}
                          placeholder="En az 3 harf giriniz.."
                          optionFilterProp="children"
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLocaleLowerCase("tr-TR")
                              .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                          }
                        >
                          {firmDropdown.map((item) => (
                            <Option key={item.id} value={item.name}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col span={6}>
                        <Input.Group compact>
                          <Input
                            name="CollectTotal2"
                            value={CollectTotal2}
                            onChange={handleCollectTotal2}
                            style={{ width: "50%", marginBottom: "3%" }}
                            placeholder="Başlangıç"
                          />
                          <Input
                            name="CollectTotal1"
                            value={CollectTotal1}
                            onChange={handleCollectTotal1}
                            style={{ width: "50%", marginBottom: "3%" }}
                            placeholder="Bitiş"
                          />
                        </Input.Group>
                      </Col>
                      <Col span={6}>
                        <Input.Group compact>
                          <Input
                            name="CollectPlanTotal2"
                            value={CollectPlanTotal2}
                            onChange={handleCollectPlanTotal2}
                            style={{ width: "50%", marginBottom: "3%" }}
                            placeholder="Başlangıç"
                          />
                          <Input
                            name="CollectPlanTotal1"
                            value={CollectPlanTotal1}
                            onChange={handleCollectPlanTotal1}
                            style={{ width: "50%", marginBottom: "3%" }}
                            placeholder="Bitiş"
                          />
                        </Input.Group>
                      </Col>

                      <Col span={6}>Tarih :</Col>
                      <Col span={6}></Col>
                      <Col span={6}></Col>
                      <Col span={6}></Col>

                      <Col span={6}>
                        <RangePicker
                          picker="month"
                          format="MM-YYYY"
                          name="TransactionDate"
                          disabledDate={disabledDate}
                          placeholder={["Başlangıç", "Bitiş"]}
                          onChange={handleTransactionDate}
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col span={6}></Col>
                      <Col span={6}></Col>
                      <Col span={6}></Col>

                      <Col span={12}></Col>
                      <Col span={6}>
                        <Button
                          danger
                          size="small"
                          icon={<CloseOutlined />}
                          onClick={() => handleReset()}
                          style={{ width: "100%" }}
                        >
                          Sıfırla
                        </Button>
                      </Col>
                      <Col span={6}>
                        <Button
                          primary
                          size="small"
                          icon={<SearchOutlined />}
                          style={{
                            color: "#4d98aa",
                            borderColor: "#4d98aa",
                            width: "100%",
                          }}
                          htmlType="submit"
                        >
                          Filtrele
                        </Button>
                      </Col>
                    </>
                  )}
                  {isTabletOrMobileDevice && (
                    <>
                      <Col span={12}>Apartman :</Col>
                      <Col span={12}>Daire :</Col>
                      <Col span={12}>
                        <Input
                          name="FirmAddress"
                          value={firmAddress}
                          onChange={handleFirmAddress}
                          placeholder="Apartman Adı"
                        ></Input>
                      </Col>
                      <Col span={12}>
                        <Select
                          showSearch
                          name="FirmId"
                          onChange={handleFirmId}
                          value={firmId === "" ? null : firmId}
                          style={{ width: "100%", marginBottom: "3%" }}
                          placeholder="En az 3 harf giriniz.."
                          optionFilterProp="children"
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLocaleLowerCase("tr-TR")
                              .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                          }
                        >
                          {firmDropdown.map((item) => (
                            <Option key={item.id} value={item.name}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col span={12}>Tahsilat Planı:</Col>
                      <Col span={12}>Gerçekleşen Tahsilat:</Col>

                      <Col span={12}>
                        <Input.Group compact>
                          <Input
                            name="CollectTotal2"
                            value={CollectTotal2}
                            onChange={handleCollectTotal2}
                            style={{ width: "50%", marginBottom: "3%" }}
                            placeholder="Başlangıç"
                          />
                          <Input
                            name="CollectTotal1"
                            value={CollectTotal1}
                            onChange={handleCollectTotal1}
                            style={{ width: "50%", marginBottom: "3%" }}
                            placeholder="Bitiş"
                          />
                        </Input.Group>
                      </Col>
                      <Col span={12}>
                        <Input.Group compact>
                          <Input
                            name="CollectPlanTotal2"
                            value={CollectPlanTotal2}
                            onChange={handleCollectPlanTotal2}
                            style={{ width: "50%", marginBottom: "3%" }}
                            placeholder="Başlangıç"
                          />
                          <Input
                            name="CollectPlanTotal1"
                            value={CollectPlanTotal1}
                            onChange={handleCollectPlanTotal1}
                            style={{ width: "50%", marginBottom: "3%" }}
                            placeholder="Bitiş"
                          />
                        </Input.Group>
                      </Col>

                      <Col span={12}>Tarih :</Col>
                      <Col span={12}></Col>
                      <Col span={12}>
                        <DatePicker
                          format={"DD-MM-YYYY"}
                          name="TransactionDate"
                          disabledDate={disabledDate}
                          placeholder="Tarih Seçin"
                          onChange={handleTransactionDate}
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col span={12}></Col>

                      <Col span={12}>
                        <Button
                          danger
                          size="small"
                          style={{ marginTop: "5%", width: "100%" }}
                          icon={<CloseOutlined />}
                          onClick={() => handleReset()}
                        >
                          Sıfırla
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button
                          primary
                          size="small"
                          icon={<SearchOutlined />}
                          style={{
                            color: "#4d98aa",
                            borderColor: "#4d98aa",
                            marginTop: "5%",
                            width: "100%",
                          }}
                          htmlType="submit"
                        >
                          Filtrele
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            </Collapse.Panel>
          </Collapse>
          <br></br>
        </Col>
      </Row>
      <Row>
        {isDesktopOrLaptop && (
          <>
            <Col flex={2}></Col>
            <Col flex={"0 250px"} style={{ marginBottom: "1%" }}>
              <a
                onClick={() => {
                  GoReportDetailCollectChart();
                }}
                style={{ fontSize: "110%", color: "black" }}
              >
                <PieChartOutlined /> Gerçekleşen Tahsilat Grafik
              </a>
            </Col>
          </>
        )}

        {isTabletOrMobileDevice && (
          <>
            <Col span={12} style={{ marginBottom: "1%" }}>
              <a
                onClick={() => {
                  GoReportDetailCollectChart();
                }}
                style={{ color: "black" }}
              >
                <PieChartOutlined /> Gerçekleşen Tahsilat Grafik
              </a>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%" }}
            size="small"
            rowKey="Id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            onDataStateChange={dataStateChange}
            pagination={{
              pageSizeOptions: ["15", "25", "50", "100"],
              defaultPageSize: take,
              total: firmStatusReport.total,
              current: currentPage,
            }}
            dataSource={firmStatusReport.data}
            loading={firmStatusReport.loading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SubscriberFirmStatusReport;
