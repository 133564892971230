import React, { useState, useEffect } from "react";

import RequestHandler from "util/RequestHandler";
import { ColumnHelper } from "util/TableHelper";

import moment from "moment";

import {
  Table,
  Empty,
  Col,
  Row,
  Button,
  Popconfirm,
  Tooltip,
} from "antd";
import {
  SendOutlined,
  WarningOutlined,
} from "@ant-design/icons";

const DbsInvoiceSentWarning = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sendInvoiceLoading, setSendInvoiceLoading] = useState(false);

  useEffect(() => {
    if (props.data?.notUpToDateInvoices) {
      setSelectedRowKeys(props.data.notUpToDateInvoices.map(x => x.id));
    }
  }, []);

  const onTableSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const onConfirmSendInvoice = async () => {
    setSendInvoiceLoading(true);

    const invoiceIds = selectedRowKeys.concat(props.data.upToDateInvoiceIds);
    await RequestHandler.post("/DbsBank/Add", { invoiceIds: invoiceIds });
    setSendInvoiceLoading(false);

    if (props.onFinish) props.onFinish(true);
  };

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      width: 150,
      render: (text) => <img src={text}></img>,
    },
    {
      title: "Bayi Kodu",
      dataIndex: "dbsDealerCode",
    },
    {
      title: "Bayi Adı",
      dataIndex: "dbsDealerName",
    },
    {
      title: "Fatura Numarası",
      dataIndex: "invoiceNumber",
    },
    {
      title: "Vade Tarihi",
      dataIndex: "dueDate",
      align: "center",
      render: (data, row, index) => moment(data).format("DD.MM.YYYY"),
    },
    {
      title: "Fatura Tutarı",
      dataIndex: "amount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Para Birimi",
      dataIndex: "currencyCode",
    },
    {
      title: "Bayi Limiti",
      dataIndex: "dbsDealerAvailability",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Bayi Sorgu Tarihi",
      dataIndex: "dbsDealerLastQueryDate",
      align: "center",
      render: (data, row, index) => data ? moment(data).format("DD.MM.YYYY HH:mm") : "-",
    },
    {
      title: "Bayi Uyarı",
      dataIndex: "dbsDealerIsHaveWarning",
      align: "center",
      render: (data, row) => {
        return data ? (
          <Tooltip title={row.dbsDealerWarningMessage}>
            <WarningOutlined style={{ color: "orange", fontSize: "130%" }} />
          </Tooltip>
        ) : undefined;
      },
    },
  ];

  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p>Aşağıdaki listeden kontrol ederek istediğiniz faturaları gönderebilir veya bu adımda listedeki faturaları göndermekten vazgeçebilirsiniz.
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
          <Popconfirm
            title="Göndermek istiyor musunuz?"
            okText="Evet"
            cancelText="Hayır"
            placement="bottom"
            onConfirm={onConfirmSendInvoice}
          >
            <Button
              loading={sendInvoiceLoading}
              style={{ marginBottom: 0, marginRight: 15 }}
            >
              <SendOutlined /> Faturaları Gönder
            </Button>
          </Popconfirm>

        </Col>
      </Row>

      <Row id="tableArea">
        <Col span={24}>
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: onTableSelectChange,
            }}
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            pageable={false}
            pagination={false}
            dataSource={props.data?.notUpToDateInvoices}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};

export default DbsInvoiceSentWarning;
