import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Select,
  Collapse,
  InputNumber,
} from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { odata } from "./Helper";

const TenantAccountTableFilter = (props) => {
  const [form] = Form.useForm();

  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: false,
  });
  const [currencySelect, setCurrencySelect] = useState({
    data: [],
    loading: true,
  });

  useEffect(() => {
    getBanks();
    getCurrencies();
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const getBanks = () => {
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    RequestHandler.get(`/Bank/GetFilterBank`).then((response) => {
      setBankSelect({
        data: response,
        loading: false,
      });
    }
    );
  };

  const getCurrencies = () => {
    setCurrencySelect({
      ...currencySelect,
      loading: true,
    });
    RequestHandler.get(`/Currency/GetFilterCurrency`).then((response) => {
      setCurrencySelect({
        data: response,
        loading: false,
      });
    });
  };

  const onFinish = (values) => {
    {
      let query = "";
      const conditions = [];

      if (values.bankId) {
        conditions.push(`bankId eq ${values.bankId}`);
      }

      if (values.iban) {
        conditions.push(odata.contains("iban", values.iban));
      }

      if (values.currencyId) {
        conditions.push(`currency eq '${values.currencyId}'`);
      }

      const amountQuery = odata.ConditionalRange(
        "balance",
        values.balanceMin,
        values.balanceMax
      );
      if (amountQuery) conditions.push(amountQuery);

      if (values.accountNumber) {
        conditions.push(odata.contains("accountNumber", values.accountNumber));
      }

      if (values.typeValue) {
        conditions.push(`typeValue eq ${values.typeValue}`);
      }

      if (conditions.length !== 0) {
        query = conditions.join(" and ");
      }
      if (props.onFinishFilter) props.onFinishFilter(query, values);
    }
  };

  const clearFilter = () => {
    form.resetFields();

    if (props.onClearFilter) {
      props.onClearFilter();
    }
  };

  return (
    <Row id="filterArea" style={{ ...props.style }}>
      <Col span={24}>
        <Collapse>
          <Collapse.Panel header="Filtreleme için tıklayınız.">
            <Form
              name="filter"
              form={form}
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row style={{ flexDirection: "row" }}>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="bankId" label="Banka :">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Tümü"
                      loading={bankSelect.loading}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {bankSelect.data.map((item) => (
                        <Select.Option key={item.text} value={item.text}>
                          {item.value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="iban" label="Iban :">
                    <Input allowClear />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="currencyId" label="Para Birimi :">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Tümü"
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      loading={currencySelect.loading}
                    >
                      {currencySelect.data.map(({ text, value }) => (
                        <Select.Option key={text} value={text}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label="Tutar :"
                  >
                    <Input.Group>
                      <Row gutter={1} style={{ flexDirection: "row" }}>
                        <Col span={12}>
                          <Form.Item name="balanceMin">
                            <InputNumber style={{ width: "100%" }} precision={2} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="balanceMax">
                            <InputNumber style={{ width: "100%" }} precision={2} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="accountNumber" label="Hesap Numarası :">
                    <Input allowClear />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="typeValue" label="Tip :">
                    <Select
                      allowClear
                      placeholder="Tümü"
                    >
                      <Select.Option key="0" value="0">Vadesiz</Select.Option>
                      <Select.Option key="1" value="1">Kredi</Select.Option>
                      <Select.Option key="2" value="2">Vadeli</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

              </Row>

              <Row style={{ flexDirection: "row" }}>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    danger
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={() => clearFilter()}
                  >
                    Sıfırla
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    icon={<SearchOutlined />}
                    style={{ color: "#40a9ff", borderColor: "#40a9ff" }}
                    htmlType="submit"
                  >
                    Filtrele
                  </Button>
                </Col>
              </Row>

            </Form>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};
export default TenantAccountTableFilter;