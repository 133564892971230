import React,{useEffect,useState} from "react";

import ContactCell from "./ContactCell/index";

const ContactList = (props) => {
  const getData = (val) =>{
      props.getDataComp(val);
}
  return (
    <div style={props.disabledPage ? {pointerEvents: "none", opacity: "0.4"} : {}} className="gx-contact-main-content">
      {props.contactList.map((contact, index) =>
        <ContactCell key={index} userId={props.userId} contact={contact} sendDataForUpdate={getData}/>
      )}

    </div>
  )
};

export default ContactList;
