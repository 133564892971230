import React, { useState, useEffect } from "react";
import { Input, notification, Form, Col, Row, Button, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PlusOutlined, MinusOutlined, MailFilled } from "@ant-design/icons";
import axios from "axios";
import config from "../../util/ApiforContext";
import RequestHandler from "../../util/RequestHandler";
import SubscriberUserMatchedRecords from "../SubscriberFirm/SubscriberUserMatchedRecords";
const expiration = localStorage.getItem("expiration");

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 15,
    span: 5,
  },
};

const { TextArea } = Input;

const SubscriberUserAdd = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });

  const history = useHistory();
  const [spinLoading, SetSpinLoading] = useState(false);
  const [loading, SetLoading] = useState(false);
  const [id, setId] = useState(props.SubscriberFirmId);
  const [isHirer, setIsHirer] = useState(props.IsHirer);
  const [values, SetValues] = useState(JSON);
  const [SubscriberUsers, SetSubscriberUsers] = useState({
    data: [],
    loading: true,
  });
  const [visibleUserInfo, SetVisibleUserInfo] = useState(false);
  const [homeOwnerId, SetHomeOwnerId] = useState("");
  const [hirerId, SetHirerId] = useState("");
  const errorList = [];

  const openNotificationWithIconAdd = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Ekleme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });
  };
  const openNotificationWithIconWarningAdd = (type) => {
    notification[type]({
      message: "HATA",
      description: "Ekleme işlemi sırasında bir hata oluştu.",
      duration: 2,
    });
  };

  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 4,
      });
    });
  };

  const onFinish = (values) => {
    SetValues(values);

    RequestHandler.post(
      "/SubscriberUser/CheckUsersExist",
      [
        {
          Email: values.Mail,
          Name: values.Name,
          PhoneNumber: values.Phone,
          TcNo: values.Tckn,
          Vkn: values.Vkn,
          TenantSubscriberUserDto: {
            IsHirer: props.IsHirer,
          },
        },
      ],
      false
    ).then((response) => {
      SetSubscriberUsers({
        data: response,
        loading: false,
      });

      if (response.length != 0) {
        SetVisibleUserInfo(true);
      } else {
        onFinishUserAdd(values);
      }
    });
  };

  const onFinishUserAdd = (values) => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }
    axios
      .post(
        `${config.apiUrl}/SubscriberUser`,
        {
          id:
            homeOwnerId == "" && hirerId == ""
              ? "00000000-0000-0000-0000-000000000000"
              : homeOwnerId != ""
              ? homeOwnerId
              : hirerId,
          Email: values.Mail,
          Name: values.Name,
          PhoneNumber: values.Phone,
          TcNo: values.Tckn,
          Vkn: values.Vkn,
          TenantSubscriberUserDto: {
            IsHirer: props.IsHirer,
            FirmId: props.SubscriberFirmId,
          },
        },
        config.headers
      )
      .then((res) => {
        if (res.status === 200) {
          openNotificationWithIconAdd("success");
          setTimeout(
            function () {
              window.location.reload();
            }.bind(this),
            2000
          );
        }
      })
      .catch((error) => {
        openNotificationWithIconWarningAdd("error");
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
          SetLoading(false);
        }
      });
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const onFinishFailedUserAdd = () => {
    SetLoading(false);
  };

  return (
    <div>
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailedUserAdd}
      >
        <Row>
          {isDesktopOrLaptop && (
            <>
              <Col span={20}>
                <Form.Item
                  label="Adı Soyadı"
                  name="Name"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Adı Soyadı alanını boş bırakmayın",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Telefon Numarası"
                  name="Phone"
                  rules={[
                    {
                      min: 12,
                      message: "Telefon numarasını eksiksiz giriniz.",
                    },
                  ]}
                >
                  <PhoneInput
                    inputStyle={{ width: "100%" }}
                    country="tr"
                    masks={{ tr: "(...) ...-..-.." }}
                  />
                </Form.Item>
                <Form.Item
                  label="T.C. Kimlik No"
                  name="Tckn"
                  rules={[
                    {
                      min: 11,
                      message: "TcNo 11 karakter olmalıdır.",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    autoComplete="off"
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    maxLength="11"
                    onInput={maxLengthCheck}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item label="Vergi Numarası" name="Vkn">
                  <Input
                    type="text"
                    autoComplete="off"
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    maxLength="10"
                    onInput={maxLengthCheck}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="E-Mail"
                  name="Mail"
                  rules={[{ type: "email" }]}
                >
                  <Input type={MailFilled} />
                </Form.Item>
              </Col>
              <Col span={4}></Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
              <Col span={6}></Col>

              <Col span={6}>
                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    htmlType="submit"
                    onClick={() => SetLoading(true)}
                    loading={loading}
                  >
                    Ekle
                  </Button>
                </Form.Item>
              </Col>
            </>
          )}
          {isTabletOrMobileDevice && (
            <>
              <Col span={24}>
                <Form.Item
                  label="Adı Soyadı"
                  name="Name"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Adı Soyadı alanını boş bırakmayın",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Telefon Numarası"
                  name="Phone"
                  rules={[
                    {
                      min: 12,
                      message: "Telefon numarasını eksiksiz giriniz.",
                    },
                  ]}
                >
                  <PhoneInput
                    inputStyle={{ width: "100%" }}
                    country="tr"
                    masks={{ tr: "(...) ...-..-.." }}
                  />
                </Form.Item>
                <Form.Item
                  label="T.C. Kimlik No"
                  name="Tckn"
                  rules={[
                    {
                      min: 11,
                      message: "TcNo 11 karakter olmalıdır.",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    autoComplete="off"
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    maxLength="11"
                    onInput={maxLengthCheck}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item label="Vergi Numarası" name="Vkn">
                  <Input
                    type="text"
                    autoComplete="off"
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    maxLength="10"
                    onInput={maxLengthCheck}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="E-Mail"
                  name="Mail"
                  rules={[{ type: "email" }]}
                >
                  <Input type={MailFilled} />
                </Form.Item>
              </Col>

              <Col span={12}></Col>
              <Col span={6}>
                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    htmlType="submit"
                    onClick={() => SetLoading(true)}
                    loading={loading}
                  >
                    Ekle
                  </Button>
                </Form.Item>
              </Col>

              <Col span={6}></Col>
              <Col span={24}></Col>
              <Col span={24}></Col>
            </>
          )}
        </Row>
      </Form>
      <Modal
        title={"Eşleşen Kayıtlar"}
        visible={visibleUserInfo}
        centered={true}
        keyboard={true}
        onOk={() => {
          SetVisibleUserInfo(false);
          onFinishUserAdd(values);
        }}
        onCancel={() => {
          SetVisibleUserInfo(false);
          SetLoading(false);
        }}
        width={1000}
        destroyOnClose={true}
      >
        <SubscriberUserMatchedRecords
          Data={SubscriberUsers.data}
          HomeOwnerId={(homeOwnerId) => SetHomeOwnerId(homeOwnerId)}
          HirerId={(hirerId) => SetHirerId(hirerId)}
        />
      </Modal>
    </div>
  );
};
export default SubscriberUserAdd;
