import React, { useState, useEffect } from "react";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";

import {
  Form,
  Col,
  Row,
  Button,
  Select,
  DatePicker,
  Input,
  InputNumber,
  Tooltip,
  Modal
} from "antd";
import { CalculatorOutlined, PlusOutlined } from "@ant-design/icons";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const commissionInputProps = {
  style: { width: "100%" },
  min: 0,
  max: 100,
  decimalSeparator: ",",
  precision: 2,
  placeholder: "0,00"
}

const PosCommissionDefinitionUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: false,
  });
  const [calculateCommissionModal, setCalculateCommissionModal] = useState({
    visible: false,
    installmentCount: 2
  });
  const [form] = Form.useForm();
  const [calculateForm] = Form.useForm();

  useEffect(() => {
    getBanks();
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const getBanks = () => {
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    RequestHandler.get(`/PosBankInfo/AddedBanks?$select=id,name`).then(
      (response) => {
        setBankSelect({
          data: response,
          loading: false,
        });
      }
    );
  };

  const onFinish = (values) => {
    const [validityStartDate, validityEndDate] = values.validityDate;

    values.validityStartDate = validityStartDate.startOf("month").format("YYYY-MM-DD");
    values.validityEndDate = validityEndDate.startOf("month").format("YYYY-MM-DD");
    values.validityDate = undefined;

    for (let index = 1; index <= 12; index++) {
      if (!values["commissionRate" + index]) {
        values["commissionRate" + index] = 0;
      }
    }

    RequestHandler.put(`/PosCommissionDefinition`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        form.resetFields();
        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const renderInstallmentInput = (installmentCount) => {
    return (

      <Form.Item label={installmentCount + " Taksit"} style={{ marginBottom: 0 }} name={"commissionRate" + installmentCount}>
        <Input.Group compact>
          <Form.Item name={"commissionRate" + installmentCount} style={{ width: 'calc(100% - 35px)' }}>
            <InputNumber {...commissionInputProps} name={"commissionRate" + installmentCount} />
          </Form.Item>
          <Tooltip title="Hesapla">
            <Button
              type="primary"
              icon={<CalculatorOutlined />}
              onClick={() => setCalculateCommissionModal({ visible: true, installmentCount: installmentCount })}
            />
          </Tooltip>
        </Input.Group>
      </Form.Item>
    )
  };

  const onCloseModal = () => {
    calculateForm.resetFields();
    setCalculateCommissionModal({ ...calculateCommissionModal, visible: false });
  };

  const onFinishCalculateForm = (values) => {
    var addFormValues = form.getFieldsValue();
    addFormValues["commissionRate" + calculateCommissionModal.installmentCount] = (values.firstCommissionRate + ((calculateCommissionModal.installmentCount - 1) * values.otherCommissionRates)) / calculateCommissionModal.installmentCount;

    form.setFieldsValue(addFormValues);
    onCloseModal();
  };

  return (
    <>

      <Form
        {...formItemLayout}
        validateMessages={defaultValidateMessages}
        form={form}
        name="posCommissionUpdate"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={() => setLoading(false)}
      >
        <Row>
          <Col span={24}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>

            <Form.Item name="bankId" label="Banka" rules={[{ required: true }]}>
              <Select
                allowClear
                showSearch
                placeholder="Seçiniz"
                filterOption={(input, option) =>
                  option.children
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
                loading={bankSelect.loading}
              >
                {bankSelect.data.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Geçerlilik Tarihi" name="validityDate" rules={[{ required: true }]}>
              <DatePicker.RangePicker picker="month" format={"MMMM YYYY"} style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item label="Tek Çekim" name="commissionRate1">
              <InputNumber {...commissionInputProps} />
            </Form.Item>

            {renderInstallmentInput(2)}
            {renderInstallmentInput(3)}
            {renderInstallmentInput(4)}
            {renderInstallmentInput(5)}
            {renderInstallmentInput(6)}
            {renderInstallmentInput(7)}
            {renderInstallmentInput(8)}
            {renderInstallmentInput(9)}
            {renderInstallmentInput(10)}
            {renderInstallmentInput(11)}
            {renderInstallmentInput(12)}

          </Col>
        </Row>
        <Row justify="space-between">
          <Col>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Güncelle
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal
        id="calculateCommissionModal"
        title={`${calculateCommissionModal.installmentCount} Taksit Komisyon Hesaplama`}
        centered
        visible={calculateCommissionModal.visible}
        onOk={onCloseModal}
        onCancel={onCloseModal}
        width={600}
        footer={null}
      >

        <Form
          {...formItemLayout}
          validateMessages={defaultValidateMessages}
          form={calculateForm}
          name="calculateForm"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishCalculateForm}
        >
          <Row>
            <Col span={24}>

              <Form.Item label="İlk Taksit" name="firstCommissionRate">
                <InputNumber {...commissionInputProps} />
              </Form.Item>

              <Form.Item label="Sonraki Taksit(ler)" name="otherCommissionRates">
                <InputNumber {...commissionInputProps} />
              </Form.Item>

            </Col>
          </Row>
          <Row justify="space-between">
            <Col>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<CalculatorOutlined />}
                htmlType="submit"
              >
                Hesapla
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default PosCommissionDefinitionUpdateForm;
