import React, { useState } from "react";
import MonthlyStatusReport from "./MonthlyStatusReport";
import DailyStatusReport from "./DailyStatusReport";
import { Tabs, Col, Row, Tooltip, message } from "antd";
import { BarsOutlined, FileExcelOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;

const ValueReport = (props) => {
  const [activeTabKey, setActiveTabKey] = useState("1");


  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> TARİH BAZLI DURUM RAPORU
          </p>
        </Col>
      </Row>

      <Tabs
        defaultActiveKey="1"
        onChange={(activeKey) => {
          setActiveTabKey(activeKey);
        }}
      >
        <TabPane tab="Ay Bazlı" key="1">
        <MonthlyStatusReport />
        </TabPane>
        <TabPane tab="Gün Bazlı" key="2">
          <DailyStatusReport />
        </TabPane>
      </Tabs>
    </>
  );
};

export default ValueReport;
