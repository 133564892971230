import React from "react";
import {Table,Empty,Card} from 'antd';
import { useMediaQuery } from 'react-responsive'
import CurrencyFormat from 'react-currency-format';

import Widget from "./TahsilatPlanWidget";

const FriendshipCard =(props)=> {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1436px)' })
    const {dataValues,planType,currencyLogo} = props;
    function currencyFormat(num) {
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    const columns = [
        {
            title: '',
            width: '40%',
            dataIndex: 'firmId',
        },
        {
          title: 'Planlanan',
          className: 'column-money',
          width:"20%",
          dataIndex: 'collectTotal',
          render: (text, row, index) => {
            if(text.includes(".00") === true){
              return <div>{text === null ? text : <CurrencyFormat value={parseFloat(text)} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={row.currencyLogo} /> }{',00'} </div>
            }else{
              return <div>{text === null ? text : <CurrencyFormat value={parseFloat(text)} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={row.currencyLogo} /> } </div>
            }
            }
          },
        {
            title: 'Gerçekleşen',
            className: 'column-money',
            dataIndex: 'accountTotal',
            width:"20%",
            render: (text, row, index) => {
            if(text === null){
              return <div>{null}</div>
            }else{
              if(text.includes(".00") === true){
                return <div><CurrencyFormat value={parseFloat(text)} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={row.currencyLogo} />{',00'}</div>
              }else{
                return <div><CurrencyFormat value={parseFloat(text)} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={row.currencyLogo} /></div>
              }
            }
            }
          },
        {
          title: 'Kalan',
          dataIndex: 'kalan',
          width:"20%",
          render: (text, row, index) => {
            const fark = row.collectTotal-row.accountTotal
            const farkString = fark.toString();
            const sonuc = farkString.includes(".") ?fark.toFixed(2) : `${farkString}.00`;
            
          return  isTabletOrMobile === true ? 
          (sonuc.includes(".00") === true ? <div style={{color:"red",width:"150%"}}> <CurrencyFormat value={fark} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={row.currencyLogo} /></div> 
          :
          <div style={{color:"red",width:"150%"}}> <CurrencyFormat value={fark} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={row.currencyLogo} /></div>)
          : 
          (sonuc.includes(".00") === true ? 
          <div style={{color:"red"}}> <CurrencyFormat value={fark} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={row.currencyLogo}  />{',00'}</div> 
          :
          <div style={{color:"red"}}> <CurrencyFormat value={fark} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={row.currencyLogo} /></div>)
          
          }
        }
      ];
      
      const data = dataValues;
  return (
    <Card   className={`gx-card-widget ant-col `} style={{marginTop:"2%",marginLeft:"1%", width:"95%",marginBottom:"5%"}}>
      <h2>{planType}</h2>
    <div className="gx-table-responsive">
    <Table className="gx-table-no-bordered" columns={columns} dataSource={data} pagination={false} bordered={false}
          size="small"/>
    </div>
    </Card>
    
  );
}

export default FriendshipCard;
