const token = localStorage.getItem("token");
export default {
  // apiUrl: "https://localhost:5001/api",
  //apiUrl: "https://uatapi.paratic.com.tr/api",
  apiUrl: "https://banka-api.adosoft.com.tr/api",
  headers: { headers: { "Authorization": "Bearer " + token?.replace('"', '').replace('"', '').toString() }, },
  //demoUrl: "https://localhost:44351/",
  demoUrl: "https://test.finekra.com/",
  //demoUrl: "https://www.finekra.com/",
  //frontEndUrl: "http://localhost:3000/",
  // frontEndUrl: "https://panel.paratic.com.tr/",
  //frontEndUrl: "https://ykb.finekra.com/",
  frontEndUrl: "https://banka.adosoft.com.tr",
  //supportPanelImgUrl: "https://localhost:44391",
  supportPanelImgUrl: "https://banka-api.adosoft.com.tr",
  renderNotifications: false,
}