import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import RequestHandler from "util/RequestHandler";
import MicroErpFirmReportTableFilter from "../Filters/MicroErpFirmReportTableFilter";
import ErpFirmInvoiceReportDetail from "./ErpFirmInvoiceReportDetail";
import moment from "moment";

import { Table, Empty, Col, Row, Tabs, Modal } from "antd";
import {
  BarsOutlined,
  InfoCircleOutlined,
  RollbackOutlined,
} from "@ant-design/icons";

const ErpFirmInvoiceReport = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });
  const [detailModal, setDetailModal] = useState({
    visible: false,
  });
  const history = useHistory();

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  const getTableData = () => {
    const { skip, take, filter } = tableDataQueryOptions;
    const { firmCode, currency } = props.match.params;

    setTableData({
      ...tableData,
      loading: true,
    });

    let conditions = [];
    let query = "";

    if (firmCode) {
      conditions.push(`firmCode eq '${firmCode}'`);
    }

    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length !== 0) {
      query = conditions.join(" and ");
    }

    RequestHandler.get(
      `/ErpReport/GetInvoiceReport?$count=true&$filter=${query}&$skip=${skip}&$top=${take}&currency=${currency}`
    ).then((response) => {
      setTableData({
        data: response[0].value,
        total: response[0]["@odata.count"],
        loading: false,
      });
    });
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      ...tableDataQueryOptions,
      skip: 0,
      current: 1,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { ...prevState, skip: 0, current: 1, filter: query };
    });
  };

  const handleOnClickDetail = (rowData) => {
    setDetailModal({
      firmInvoiceId: rowData.id,
      visible: true,
    });
  };

  const columns = [
    {
      title: "Vade Tarihi",
      dataIndex: "dueDate",
      render: (data, row, index) => moment(data).format("DD.MM.YYYY"),
    },
    {
      title: "Açıklama",
      dataIndex: "description",
    },
    {
      title: "Evrak No",
      dataIndex: "documentNumber",
    },
    {
      title: "Fatura No",
      dataIndex: "invoiceNumber",
    },
    {
      title: "Tutar",
      dataIndex: "amountString",
    },
    {
      title: "Detay",
      dataIndex: "firmCode",
      align: "center",
      render: (data, row) => (
        <a onClick={() => handleOnClickDetail(row)}>
          <InfoCircleOutlined style={{ fontSize: "130%" }} />
        </a>
      ),
    },
  ];

  return (
    <>
      {props.match.params.firmCode && (
        <Row style={{ marginBottom: 9 }}>
          <Col flex={1}>
            <a
              onClick={() => history.goBack()}
              style={{ color: "black", marginLeft: "4%" }}
            >
              {" "}
              <RollbackOutlined /> GERİ DÖN
            </a>
          </Col>
          <Col flex={4}></Col>
        </Row>
      )}
      <Row>
        <Col xs={15} lg={20}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> CARİ FATURALARI
          </p>
        </Col>
      </Row>

      {/* <MicroErpFirmReportTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      /> */}

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{
              overflowX: "auto",
              maxHeight: "100%",
              width: "100%",
            }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Modal
        id="detailModal"
        centered
        visible={detailModal.visible}
        onOk={() => setDetailModal({ ...detailModal, visible: false })}
        onCancel={() => setDetailModal({ ...detailModal, visible: false })}
        width={800}
        footer={null}
      >
        <ErpFirmInvoiceReportDetail firmInvoiceId={detailModal.firmInvoiceId} />
      </Modal>
    </>
  );
};

export default ErpFirmInvoiceReport;
