import React, { useEffect, useState } from "react";
import { Layout, PageHeader, Button } from "antd";

import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";

import Topbar from "../Topbar/index";
import { footerText } from "util/config";
import App from "routes/index";
import Screen from '../../util/Extensions/Screens'
import RequestHandler from "util/RequestHandler";
import { useSelector } from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import config from "../../util/ApiforContext"
import Replace2x from '../../util/Extensions/ReplaceExtension';

const { Content, Footer } = Layout;

const MainApp = () => {
  const { width, navStyle } = useSelector(({ settings }) => settings);
  const [isDemo, setIsDemo] = useState(false);
  const match = useRouteMatch();
  const tenantId = Replace2x(localStorage.getItem("tenantId"));
  const token = Replace2x(localStorage.getItem("token"));
  const email = Replace2x(localStorage.getItem("email"));

  useEffect(() => {
    RequestHandler.get(`/Tenant/GetById/${tenantId}`).then(
      (res) => {
        if (res.isDemo) {
          setIsDemo(true);
        }
      }
    );
  }, [])


  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return '';
    }
  };
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return <Topbar />;
      case NAV_STYLE_DRAWER:
        return <Topbar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  };

  const getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />;
      case NAV_STYLE_DRAWER:
        return <Sidebar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />;
      default:
        return null;
    }
  };
  function boughtParatic() {
    window.open(
      config.demoUrl + "Paketler?vk=" + tenantId + "&eMail=" + email + "&t=" + token,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  return (
    <Layout className="gx-app-layout" >
      {getSidebar(navStyle, width)}
      {
        isDemo ?
          <PageHeader
            title="Finansal Kolaylık "
            className="site-page-header"
            subTitle="Tek ekranda tüm hesaplarınızı takip edebilirsiniz"
            extra={[
              <Button key="1" style={{ marginRight: 50 }} onClick={boughtParatic} type="primary">
                Satın Al
              </Button>,
            ]}
            avatar={{ src: 'https://icon-library.com/images/finance-icon/finance-icon-19.jpg' }}
            style={{ backgroundColor: '#fff', height: 68, position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1 }}
          > </PageHeader> : null
      }

      <Layout style={{ marginTop: isDemo ? 68 : null }}>
        {getNavStyles(navStyle)}
        <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
          <App match={match} />
          <Footer>
            <div className="gx-layout-footer-content">
              {
                Screen.screen === 0 ?
                  footerText :
                  null
              }

            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  )
};
export default MainApp;

