import React, { useEffect, useState } from 'react'
import { Table, Empty, notification } from "antd";
import axios from "axios"
import Screen from 'util/Extensions/Screens'
import CurrencyFormat from 'react-currency-format';
import config from "util/ApiforContext"
import { useMediaQuery } from 'react-responsive'
import { useHistory } from "react-router-dom";
import Widget from "components/Widget/index";


const expiration = localStorage.getItem("expiration");

const BankList = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const history = useHistory();
  const [bankListForTRY, setBankListForTRY] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [bankListForTotal, setBankListForTotal] = useState([]);
  const [chartTakeValuesState, setChartTakeValuesState] = useState([]);
  const [activeKey, setActiveKey] = useState("9999");
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [yesterday, setYesterday] = useState("");
  const [count, setCount] = useState(0);
  const [query, setQuery] = useState('');
  const [totalCurrencyValue, setTotalCurrencyValue] = useState(0);
  const errorList = [];

  const errorListNotification = type => {
    errorList.map((item) => {
      notification[type]({
        message: 'Hata',
        description:
          item
        ,
        duration: 2,
      });
    })
  };
  const chartTakeValuesTotal = [];

  useEffect(() => {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    today.toDateString()
    setYesterday(yesterday)
    yesterday.toDateString()
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload()
    }
    axios.get(`${config.apiUrl}/TenantCurrency?$apply=groupby((Currency,CurrencyId))&$orderby=currencyId`, config.headers)
      .then(res => {
        if (res.data.length === 0) {
          return null;
        } else {
          axios.get(`${config.apiUrl}/tenantaccount/GetTenantAccountsList?currencyId=${res.data[0]?.currencyId}&$apply=groupby((bankName,bankId,bankLogo,currency,currencyLogo),aggregate(Balance with sum as balance))`, config.headers)
            .then(res => {
              var bankAccountList = [];
              var toplam = 0;
              var currency = "";
              var currencyLogo = "";
              res.data.map((item) => {
                var model = {}
                model.bankLogo = item.BankLogo;
                model.bankId = item.BankId;
                model.balance = item.balance.toFixed(2).replace(".", ",");
                model.currency = item.Currency;
                model.currencyLogo = item.CurrencyLogo;
                bankAccountList.push(model);
                toplam += item.balance
                currency = item.Currency;
                currencyLogo = item.CurrencyLogo;
              })
              var model = {}
              model.bankLogo = "Toplam";
              model.balance = toplam.toFixed(2).replace(".", ",");
              model.bankId = "";
              model.currency = currency;
              model.currencyLogo = currencyLogo;
              bankAccountList.push(model)
              setBankListForTRY(bankAccountList)
            }).catch(
              error => {
                if (error.response.status === 403 || error.response.status === 401) {
                  setBankListForTRY([]);
                } else if (error.response.status === 406) {
                  error.response.data.data.map((item) => {
                    errorList.push(item);
                  })
                  errorListNotification('error')
                }
              }
            );
        }
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          }
        }
      );
    axios.get(`${config.apiUrl}/tenantaccount/GetListForTotal`, config.headers)
      .then(res => {
        var tenantTotalList = [];
        var total = 0;
        res.data.map((item) => {
          var model = {};
          model.bankLogo = item.bankLogo;
          model.totalBalance = item.balance;
          model.bankName = item.bankName;
          var currencyList = [0, 1, 2];
          currencyList.map((i) => {
            if (item.tenantList[i]?.currencyId == 1) {
              model.try = item.tenantList[i]?.balance === undefined ? 0 : item.tenantList[i].balance;
            } else if (item.tenantList[i]?.currencyId == 2) {
              model.usd = item.tenantList[i]?.balance === undefined ? 0 : item.tenantList[i].balance;
            } else if (item.tenantList[i]?.currencyId == 3) {
              model.eur = item.tenantList[i]?.balance === undefined ? 0 : item.tenantList[i].balance;
            }
          })
          model.usd === undefined ? model.usd = 0 : model.usd = model.usd;
          model.try === undefined ? model.try = 0 : model.try = model.try;
          model.eur === undefined ? model.eur = 0 : model.eur = model.eur;
          tenantTotalList.push(model)
          total += item.balance
        })
        var model = {};
        model.bankLogo = "TOPLAM";
        model.balance = parseFloat(total.toFixed(2));
        tenantTotalList.push(model)
        setTotalCurrencyValue(parseFloat(total.toFixed(2)))
        setBankListForTotal(tenantTotalList)
      }
      ).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            setBankListForTotal([])
            setTotalCurrencyValue(0)
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
      );;

    axios.get(`${config.apiUrl}/TenantCurrency?$apply=groupby((Currency,CurrencyId))&$orderby=currencyId`, config.headers)
      .then(res => {
        setCurrencyList(res.data)
        setActiveKey("9999")
        if (res.data.length !== 0) {
          axios.get(`${config.apiUrl}/tenantaccount/GetTenantAccountChartList?currencyId=${res.data[0]?.currencyId}&$apply=groupby((name),aggregate(value with sum as value))`, config.headers)
            .then(res => {
              const chartValuesTRY = res.data;
              var total = 0;
              var valueTotal = 0;
              var currency = "";
              try {
                {
                  chartValuesTRY.map((value, index) => {
                    if (value.value < 0) {
                      value.value = 0
                    }
                    total += value.value;
                  })
                }

                {
                  chartValuesTRY.map((value, index) => {
                    var percents = ((value.value / total) * 100).toFixed(2);
                    var percentsFloat = parseFloat(percents);

                    if (percentsFloat < 0) {
                      percentsFloat = 0;
                    }
                    value.value = percentsFloat;

                    if (value.value < 10) {
                      valueTotal += value.value;
                    } else {
                      currency = value.Currency;
                      chartTakeValuesTotal.push(value)
                    }
                  })
                }
                if (valueTotal != 0) {
                  var model = {};
                  model.Currency = res.data[0]?.currency;
                  model.name = "DİĞER";
                  model.value = valueTotal;
                  chartTakeValuesTotal.push(model)
                }
                setChartTakeValuesState(chartTakeValuesTotal)
              } catch (error) {
                if (error.response.status === 403 || error.response.status === 401) {
                  setChartTakeValuesState([])
                } else if (error.response.status === 406) {
                  error.response.data.data.map((item) => {
                    errorList.push(item);
                  })
                  errorListNotification('error')
                }
              }
            }).catch(
              error => {
                if (error.response.status === 403 || error.response.status === 401) {
                  setChartTakeValuesState([])
                } else if (error.response.status === 406) {
                  error.response.data.data.map((item) => {
                    errorList.push(item);
                  })
                  errorListNotification('error')
                }
              }
            );
        } else {
          return null;
        }

      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          }
        }
      );
    axios.get(`${config.apiUrl}/TenantAccount? ${query} &$count=true&$skip=${skip}&$top=${take}`, config.headers)
      .then(res => {
        setCount(res.data['@odata.count'])
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            setCount(0)
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
      );
  }, [setCurrencyList, setBankListForTRY]);
  const titles = [];
  titles.push("Banka")
  currencyList.map((item) => {
    if (item.currency === "TRY" || item.currency === "USD" || item.currency === "EUR") {
      titles.push(item.currency)
    }
  })

  const columns = [
    {
      title: 'Banka',
      dataIndex: 'bankLogo',
      align: 'center',
      render: (text, row, index) => {
        return <div style={{ width: 100 }}><img src={row.bankLogo}  ></img></div>
      }
    },
    {
      title: 'Toplam',
      dataIndex: 'balance',
      align: 'center',

      render: (text, row, index) => {
        return row.totalBalance.toString().includes(".") ?
          <div style={{ fontSize: 13 }}><CurrencyFormat value={row.totalBalance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /></div>
          :
          <div style={{ fontSize: 13 }}><CurrencyFormat value={row.totalBalance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} />,00</div>

      }
    },
    {
      title: 'TRY',
      dataIndex: 'balance',
      align: 'center',

      render: (text, row, index) => {
        return row.try.toString().includes(".") ?
          <div style={{ fontSize: 13 }}><CurrencyFormat value={row.try} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /></div>
          :
          <div style={{ fontSize: 13 }}><CurrencyFormat value={row.try} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} />,00</div>

      }
    },
    {
      title: 'USD',
      dataIndex: 'balance',
      align: 'center',

      render: (text, row, index) => {
        return row.usd.toString().includes(".") ?
          <div style={{ fontSize: 13 }}><CurrencyFormat value={row.usd} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"$"} /></div>
          :
          <div style={{ fontSize: 13 }}><CurrencyFormat value={row.usd} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"$"} />,00</div>

      }
    },
    {
      title: 'EUR',
      dataIndex: 'balance',
      align: 'center',
      render: (text, row, index) => {
        return row.eur.toString().includes(".") ?
          <div style={{ fontSize: 13 }}><CurrencyFormat value={row.eur} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"€"} /></div>
          :
          <div style={{ fontSize: 13 }}><CurrencyFormat value={row.eur} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"€"} />,00 </div>
      }
    },
  ];
  const columnsForMobile = [
    {
      title: 'Banka',
      dataIndex: 'bankName',
      align: 'center',
      render: (text, row, index) => {
        return <strong>{text}</strong>
      }
    },
    {
      title: 'Toplam',
      dataIndex: 'balance',
      align: 'center',

      render: (text, row, index) => {
        return <div style={{ fontSize: 13 }}><CurrencyFormat value={row.totalBalance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /></div>
      }
    },
    {
      title: 'TRY',
      dataIndex: 'balance',
      align: 'center',

      render: (text, row, index) => {
        return <div style={{ fontSize: 13 }}><CurrencyFormat value={row.try} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /></div>
      }
    },
    {
      title: 'USD',
      dataIndex: 'balance',
      align: 'center',

      render: (text, row, index) => {
        return <div style={{ fontSize: 13 }}><CurrencyFormat value={row.usd} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"$"} /></div>
      }
    },
    {
      title: 'EUR',
      dataIndex: 'balance',
      align: 'center',
      render: (text, row, index) => {
        return <div style={{ fontSize: 13 }}><CurrencyFormat value={row.eur} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"€"} /></div>
      }
    },
  ];
  return (
    <div >
      {
        isDesktopOrLaptop ?
          <Widget
            title={
              <h2 className="h4 gx-text-capitalize gx-mb-0">
                Banka Listesi</h2>
            } extra={
              <p className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
                <a href="/bank-list">Tüm Bankalar</a>  <i
                  className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle" /></p>
            }>
            {
              count < 5 ? <div className="gx-table-responsive">

                <Table className="gx-table-no-bordered" locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" /> }}  columns={columns} dataSource={bankListForTotal.filter(x => x.bankLogo !== "TOPLAM")} pagination={false}
                  size="small" style={{ height: 490 }} />
              </div>
                :
                <div className="gx-table-responsive">

                  <Table className="gx-table-no-bordered" locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" /> }}  columns={columns} dataSource={bankListForTotal.filter(x => x.bankLogo !== "TOPLAM")} pagination={false}
                    size="small" style={{ height: 779 }} />
                  {/* {
                    Screen.screen === 0 ?
                      <img style={{ marginTop: -380 }} src={require(`assets/images/Home/AllBanksFooter.png`)} />
                      : null
                  } */}
                </div>
            }


          </Widget>
          :
          <Widget
            title={
              <h2 className="h4 gx-text-capitalize gx-mb-0">
                Banka Listesi</h2>
            } extra={
              <p className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
              </p>
            }>
            <div className="gx-table-responsive">
              <Table className="gx-table-no-bordered" locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" /> }} columns={columnsForMobile} dataSource={bankListForTotal.filter(x => x.bankLogo !== "TOPLAM")} pagination={false}
                size="small" style={{ height: 780 }} />
              <a href="/bank-list">Tüm Bankalar</a>  <i
                className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle" />
              {/* {
                Screen.screen === 0 ?
                  <img style={{ marginTop: -380 }} src={require(`assets/images/Home/AllBanksFooter.png`)} />
                  : null
              } */}
            </div>

          </Widget>
      }

    </div>
  )
}
export default BankList;
