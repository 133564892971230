import React, { useState,useEffect } from "react";
import { Avatar, Popover, Modal } from "antd";
import Replace2x from "../../util/Extensions/ReplaceExtension";
import RequestHandler from "util/RequestHandler";
import { UserOutlined,AliwangwangOutlined } from "@ant-design/icons";
import DefaultModuleUpdateForm from "../../routes/Forms/DefaultModuleUpdateForm";

const userName = localStorage.getItem("userName");

const UserInfo = () => {
  const [defaultModuleModalVisible, setDefaultModuleModalVisible] =
    useState(false);
    const [accessSupportPanel, setAccessSupportPanel] = useState();
    useEffect(() => {
      RequestHandler.get("/SupportPanel/AccessControl").then((response) => {
        setAccessSupportPanel(response);
      });
    }, [accessSupportPanel])

  const userSignOut = () => {
    RequestHandler.get("/Auth/Logout").then((response) => {
      if (response.success) {
        localStorage.removeItem("token");
        window.location.reload();
      }
    });
  };
 


  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>{Replace2x(userName)}</li>
      <hr></hr>
      <li onClick={() => setDefaultModuleModalVisible(true)}>
        Anasayfayı Değiştir
      </li>
       <li>
        <a style={{ color: "#545454" }} href="/account-transaction-role">
          Parametreler
        </a>
      </li> 
      
      <li>
        <a style={{ color: "#545454" }} href="/password-change">
          Şifremi Değiştir
        </a>
      </li>
      {
        accessSupportPanel ? 
        <>
        {/* <li>
        <a style={{color: "#545454" }} href="/support-panel/2">
          Destek Taleplerim
        </a>
        </li>         */}
        </>: null
      }
      <li onClick={() => userSignOut()}>Çıkış Yap</li>
    </ul>
  );

  const supportPanelDropdown = (
    <ul className="gx-user-popover">
       <li>
        <a style={{ color: "#545454",whiteSpace:'nowrap' }} href="/support-panel/1">
          Sıkça Sorulan Sorular
        </a>
      </li> 
      <li>
        <a style={{ color: "#545454" }} href="/support-panel/2">
          Destek Talebi
        </a>
      </li>
    </ul>
  );

  return (
    <>
      <Popover
        overlayClassName="gx-popover-horizantal"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          className="gx-avatar-name"
          style={{ backgroundColor: "white" }}
          icon={<UserOutlined style={{ color: "#086880" }} />}
        />
      </Popover>

      {/* <Popover
        overlayClassName="gx-popover-horizantal"
        placement="bottomRight"
        content={supportPanelDropdown}
        trigger="click"
      >
       <Avatar 
       className="gx-avatar-name" 
       style={{backgroundColor:'white',marginLeft:10}} 
       icon={<AliwangwangOutlined style={{color:'#086880'}}/>} />
      </Popover> */}
      
      <Modal
        id="defaultModuleModal"
        centered
        visible={defaultModuleModalVisible}
        onOk={() => setDefaultModuleModalVisible(false)}
        onCancel={() => setDefaultModuleModalVisible(false)}
        footer={null}
      >
        <DefaultModuleUpdateForm
          onSuccess={() => setDefaultModuleModalVisible(false)}
        />
      </Modal>
    </>
  );
};

export default UserInfo;
