import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Select,
  DatePicker,
  InputNumber,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";
import moment from "moment";
import { odata } from "../Filters/Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const DbsInvoiceUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: true,
  });
  const [currencySelect, setCurrencySelect] = useState({
    data: [],
    loading: true,
  });
  const [dealerSelect, setDealerSelect] = useState({
    data: [],
    loading: false,
  });
  const [activeBankId, setActiveBankId] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    getBanks();
  }, []);

  useEffect(() => {
    if (props.data) {
      props.data.invoiceDate = moment(props.data.invoiceDate);
      props.data.dueDate = moment(props.data.dueDate);
      form.setFieldsValue(props.data);
      setActiveBankId(props.data.bankId);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.data?.bankId == activeBankId) {
      form.setFieldsValue({
        dbsDealerId: props.data.dbsDealerId,
        currencyId: props.data.currencyId,
      });
    } else {
      form.resetFields(["dbsDealerId", "currencyId"]);
    }

    setDealerSelect({
      data: [],
      loading: false,
    });
    getCurrencies();
  }, [activeBankId]);

  const getBanks = () => {
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    RequestHandler.get(`/DbsBankInfo/AddedBanks?$select=id,name`).then(
      (response) => {
        setBankSelect({
          data: response,
          loading: false,
        });
      }
    );
  };

  const getCurrencies = () => {
    if (!activeBankId) {
      setCurrencySelect({
        data: [],
        loading: false,
      });
      return;
    }

    setCurrencySelect({
      data: [],
      loading: true,
    });
    RequestHandler.get(`/DbsInvoice/AvailableCurrencies/${activeBankId}`).then(
      (response) => {
        setCurrencySelect({
          data: response,
          loading: false,
        });
      }
    );
  };

  const onDealerSearch = (value) => {
    if (value.length >= 2) {
      setDealerSelect({ ...dealerSelect, loading: true });

      RequestHandler.get(
        `/DbsDealer?$select=id,code,name&$filter=(${odata.contains(
          "name",
          value
        )} or ${odata.contains("code", value)}) and bankId eq ${activeBankId}`
      ).then((res) => {
        setDealerSelect({ data: res.value, loading: false });
      });
    } else {
      setDealerSelect({ data: [], loading: false });
    }
  };

  const onFinish = (values) => {
    values.invoiceDate = values.invoiceDate.format("YYYY-MM-DD");
    values.dueDate = values.dueDate.format("YYYY-MM-DD");

    RequestHandler.put(`/DbsInvoice`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        form.resetFields();
        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="dbsInvoiceUpdate"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="bankId" label="Banka" rules={[{ required: true }]}>
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              onChange={(value) => setActiveBankId(value)}
              loading={bankSelect.loading}
            >
              {bankSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="dbsDealerId"
            label="Bayi"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              disabled={!activeBankId}
              placeholder="Seçiniz"
              onSearch={onDealerSearch}
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                  option.code
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              loading={dealerSelect.loading}
            >
              {props.data?.dbsDealerId && (
                <Select.Option
                  key={props.data.dbsDealerId}
                  value={props.data.dbsDealerId}
                  code={props.data.dbsDealerCode}
                >
                  {props.data.dbsDealerName}
                </Select.Option>
              )}
              {dealerSelect.data
                .filter((x) => x.id != props.data?.dbsDealerId)
                .map((item) => (
                  <Select.Option key={item.id} value={item.id} code={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="currencyId"
            label="Para Birimi"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              disabled={!activeBankId}
              placeholder="Seçiniz"
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              loading={currencySelect.loading}
            >
              {currencySelect.data.map((item) => (
                <Select.Option key={item.currencyId} value={item.currencyId}>
                  {item.currencyCode}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Fatura Tarihi"
            name="invoiceDate"
            rules={[{ required: true }]}
          >
            <DatePicker format="DD.MM.YYYY" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Vade Tarihi"
            name="dueDate"
            rules={[{ required: true }]}
          >
            <DatePicker format="DD.MM.YYYY" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Fatura Numarası"
            name="invoiceNumber"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Fatura Tutarı"
            name="amount"
            rules={[{ required: true }]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item label="Açıklama" name="description">
            <Input.TextArea rows={3} maxLength={300} />
          </Form.Item>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Güncelle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default DbsInvoiceUpdateForm;
