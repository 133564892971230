import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import RequestHandler from "../../util/RequestHandler";
import { defaultValidateMessages } from "./Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const TransactionBankInfoUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [activeBankId, setActiveBankId] = useState();
  const [bankRequirements, setBankRequirements] = useState({});

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setActiveBankId(props.data.bankId);
    }
  }, [props.data]);

  useEffect(() => {
    if (activeBankId) getBankInfoRequirements(activeBankId);
  }, [activeBankId]);

  const getBankInfoRequirements = (bankId) => {
    RequestHandler.get("/Bank/GetBankColumnsByBankId/" + bankId).then(
      (response) => {
        setBankRequirements({
          isRequiredAccountInfo: response.isRequiredAccountInfo,
          ...response.bankInfoRequiredColumns,
          ...response.bankInfoAccountRequiredColumns,
        });
      }
    );
  };

  const onFinish = (values) => {
    RequestHandler.put(`/TransactionBankInfo`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        form.resetFields();

        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  return (
    <>
      <Form
        {...formItemLayout}
        validateMessages={defaultValidateMessages}
        form={form}
        name="bankInfoUpdate"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col span={24}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>

            <Form.Item name="bankId" hidden>
              <Input />
            </Form.Item>

            {bankRequirements && (
              <>
                {bankRequirements.isUserNameRequired && (
                  <Form.Item
                    label="Kullanıcı Adı"
                    name="userName"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen kullanıcı adı alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}

                {bankRequirements.isUserCodeRequired && (
                  <Form.Item
                    label="Kullanıcı Kodu"
                    name="userCode"
                    rules={[
                      {
                        required: true,
                        message:
                          "Lütfen Kullanıcı Kodu alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}

                {bankRequirements.isPasswordRequired && (
                  <Form.Item
                    label="Şifre"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Şifre alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input.Password autoComplete="new-password" />
                  </Form.Item>
                )}

                {bankRequirements.isCustomerNumberRequired && (
                  <Form.Item
                    label="Müşteri Numarası"
                    name="customerNumber"
                    rules={[
                      {
                        required: true,
                        message:
                          "Lütfen Müşteri Numarası alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}

                {bankRequirements.isCorporateCodeRequired && (
                  <Form.Item
                    label="Kurum Kodu"
                    name="corporateCode"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Kurum Kodu alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}

                {bankRequirements.isServiceUrlRequired && (
                  <Form.Item
                    label="Url"
                    name="serviceUrl"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Url alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}

                {bankRequirements.isFirmNameRequired && (
                  <Form.Item
                    label="Firma Adı"
                    name="firmName"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Firma Adı alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}

                {bankRequirements.isFirmKeyRequired && (
                  <Form.Item
                    label="Firma Anahtarı"
                    name="firmKey"
                    rules={[
                      {
                        required: true,
                        message:
                          "Lütfen Firma Anahtarı alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
              </>
            )}

            <Col style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                htmlType="submit"
                onClick={() => setLoading(true)}
                loading={loading}
              >
                Güncelle
              </Button>
            </Col>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default TransactionBankInfoUpdateForm;
