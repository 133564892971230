import React, { useEffect, useState } from "react";
import {
  Form,
  DatePicker,
  Table,
  Empty,
  Input,
  Popconfirm,
  notification,
  Collapse,
  Button,
  Select,
  Drawer,
  Tooltip,
  Col,
  Modal,
  Row,
} from "antd";
import config from "../../util/ApiforContext";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  BarsOutlined,
  PlusOutlined,
  CloseOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import MonthlyPlanAdd from "../Forms/MonthlyPlanAdd";
import MonthlyPlanUpdate from "../Forms/MonthlyPlanUpdate";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { odata } from "../Filters/Helper";

import { useMediaQuery } from "react-responsive";
import RequestHandler from "../../util/RequestHandler";
const { confirm } = Modal;
const expiration = localStorage.getItem("expiration");
const { Panel } = Collapse;
const dateFormat = "DD/MM/YYYY";
const openNotificationWithIconDelete = (type) => {
  notification[type]({
    message: "BAŞARILI",
    description: "Silme işlemi başarı ile gerçekleşti.",
    duration: 2,
  });
};
const openNotificationWithIconWarningDelete = (type) => {
  notification[type]({
    message: "HATA",
    description: "Silme işlemi sırasında bir hata oluştu.",
    duration: 2,
  });
};
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 11,
  },
};
const { RangePicker } = DatePicker;
const MonthlyPlanList = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const history = useHistory();
  const [monthlyPlans, setMonthlyPlans] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [dataState, setDataState] = useState([{ skip: 0, take: 10 }]);
  const [teminatDropdown, setTeminatDropdown] = useState([]);
  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });
  const [ManualTransactionTypeDropdown, setManualTransactionTypeDropdown] =
    useState([]);
  const [currencyDropdown, setCurrencyDropdown] = useState([]);
  const [monthlyPlanId, setMonthlyPlanId] = useState("");
  const [FirmId, setFirmId] = useState("");
  const [FirmIdUpdate, setFirmIdUpdate] = useState("");
  const [MonthlyPlanTypeId, setMonthlyPlanTypeId] = useState("");
  const [MonthlyPlanTypeIdForFilter, setMonthlyPlanTypeIdForFilter] =
    useState("");
  const [OtherTransactionProcessType, setOtherTransactionProcessType] =
    useState("");
  const [
    OtherTransactionProcessTypeUpdate,
    setOtherTransactionProcessTypeUpdate,
  ] = useState("");
  const [CurrencyCode, setCurrencyCode] = useState("");
  const [Currency, setCurrency] = useState("");
  const [Amount, setAmount] = useState("");
  const [take, setTake] = useState(20);
  const [skip, setSkip] = useState(0);
  const [query, setQuery] = useState("");
  const [Amount1, setAmount1] = useState("");
  const [Amount2, setAmount2] = useState("");
  const [TransactionDate, setTransactionDate] = useState("");
  const [TransactionDate1, setTransactionDate1] = useState("");
  const [Description, setDescription] = useState("");
  const [DescriptionUpdate, setDescriptionUpdate] = useState("");
  const [order, setOrder] = useState("");
  const [getFilterCurrency, setGetFilterCurrency] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isRepeat, setIsRepeat] = useState(false);
  const [changeAll, setChangeAll] = useState(false);
  const [visibleUpdate, setvisibleUpdate] = useState(false);
  const errorList = [];
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 2,
      });
    });
  };

  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") === null) {
      window.location.reload();
    }

    setMonthlyPlans({
      ...monthlyPlans,
      loading: true,
    });
    axios
      .get(
        `${config.apiUrl}/MonthlyPlan? ${query}${order} &$count=true&$skip=${skip}&$top=${take}`,
        config.headers
      )
      .then((res) => {
        setMonthlyPlans({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    axios
      .get(`${config.apiUrl}/Types/GetManualTransactionTypes`, config.headers)
      .then((res) => {
        setTeminatDropdown(res.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });

    axios
      .get(`${config.apiUrl}/MonthlyPlan?$select=currencyCode`, config.headers)
      .then((res) => {
        const uniqueTags = [];
        res.data.value.map(img => {
          if (uniqueTags.indexOf(img.currencyCode) === -1) {
            uniqueTags.push(img.currencyCode)
          }
        });
        setGetFilterCurrency(uniqueTags);

      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });

    axios
      .get(`${config.apiUrl}/Currency`, config.headers)
      .then((res) => {
        setCurrencyDropdown(res.data.value);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    axios
      .get(`${config.apiUrl}/MonthlyPlanType`, config.headers)
      .then((res) => {
        setManualTransactionTypeDropdown(res.data.value);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  }, [order, query, setDataState, take, setTake, skip, setSkip]);

  const showDrawerUpdate = (value) => {
    axios
      .get(`${config.apiUrl}/MonthlyPlan/getbyid/${value}`, config.headers)
      .then((res) => {
        setMonthlyPlanTypeId(res.data.monthlyPlanTypeId);
        setCurrencyCode(res.data.currencyId.toString());
        setAmount(res.data.amount);
        setOtherTransactionProcessTypeUpdate(
          res.data.otherTransactionProcessType.toString()
        );
        setTransactionDate(res.data.transactionDate);
        setDescriptionUpdate(res.data.description);
        setMonthlyPlanId(value);
        setFirmIdUpdate(res.data.firmId);
        if (res.data.isRepeat) {
          confirm({
            title: 'Yapacağınız güncellemenin tüm tekrarlı kayıtlara uygulanmasını ister misiniz?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            cancelText: 'Hayır',
            okText: 'Evet',
            onOk() {
              setChangeAll(true);
              setvisibleUpdate(true);
            },
            onCancel() {
              setChangeAll(false);
              setvisibleUpdate(true);
            },
          });
        } else {
          setChangeAll(false);
          setvisibleUpdate(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };

  const onClose = () => {
    setVisible(false);
    setvisibleUpdate(false);
  };
  const onCloseUpdate = () => {
    setvisibleUpdate(false);
  };

  const onFirmSearch = (value) => {
    if (value.length >= 2) {
      setFirmSelect({ data: [], loading: true });

      RequestHandler.get(
        `/Firm?$select=id,firmBranchNumber,name&$filter=${odata.contains(
          "name",
          value
        )} or ${odata.contains("firmBranchNumber", value)}`
      ).then((res) => {
        setFirmSelect({ data: res.value, loading: false });
      });
    } else {
      setFirmSelect({ data: [], loading: false });
    }
  };

  const handleDelete = (key, changeAll) => {
    axios
      .delete(`${config.apiUrl}/MonthlyPlan?id=${key}&isChangeAll=${changeAll}`, config.headers)
      .then((res) => {
        if (res.status === 204 || res.status === 200) {
          openNotificationWithIconDelete("success");
          // setMonthlyPlans({
          //   data: monthlyPlans.data.filter((item) => item.id !== key),
          //   total: monthlyPlans.total - 1,
          //   loading: false
          // });
          window.location.reload();

          if (
            monthlyPlans.data.filter((item) => item.id !== key).length === 0
          ) {
            window.location.reload();
          }
        } else {
          openNotificationWithIconWarningDelete("error");
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        } else {
          openNotificationWithIconWarningDelete("error");
        }
      });
  };

  const reList = () => {
    setMonthlyPlans({
      ...monthlyPlans,
      loading: true,
    });

    axios
      .get(
        `${config.apiUrl}/MonthlyPlan? ${query} &$count=true&$skip=${skip}&$top=${take}`,
        config.headers
      )
      .then((res) => {
        setMonthlyPlans({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };
  const handleReset = (clearFilters) => {
    setSkip(0);
    setTake(20);
    setQuery("");
    setMonthlyPlanTypeIdForFilter("");
    setOtherTransactionProcessType("");
    setCurrency("");
    setFirmId("");
    setDescription("");
    setAmount2("");
    setTransactionDate("");
    setMonthlyPlanId("");
    setAmount1("");
  };
  const handleOtherTransactionProcessType = (value) => {
    setOtherTransactionProcessType(value);
  };
  const handleCurrency = (value) => {
    setCurrency(value);
  };
  const handleFirmId = (value) => {
    setFirmId(value);
  };
  const getData = (val) => {
    if (val === "success") {
      reList();
      setvisibleUpdate(false);
      setVisible(false);
    }
  };
  const dataStateChange = (e) => {
    setMonthlyPlans(e);
  };
  const handleDescription = (value) => {
    setDescription(value.target.value);
  };
  const handleAmount2 = (value) => {
    setAmount2(value.target.value.replace(',', '.'));
  };
  const handleTransactionDate = (value) => {
    if (value !== null) {
      setTransactionDate(value[0].format("YYYY-MM-DD"));
      setTransactionDate1(value[1].format("YYYY-MM-DD"));
    }
  };
  const handleMonthlyPlanTypeId = (value) => {
    setMonthlyPlanTypeIdForFilter(value);
  };
  const handleAmount1 = (value) => {
    setAmount1(value.target.value.replace(',', '.'));
  };
  const paging = (p, filters, sorter) => {
    let order = "";
    if (sorter.columnKey && sorter.order) {
      order =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }
    setOrder(order);
    setDataState((prevState) => {
      prevState.skip = (p.current - 1) * p.pageSize;
      setSkip(prevState.skip);
      setTake(p.pageSize);
      setCurrentPage(p.current);
      return {
        prevState,
      };
    });
  };

  const { Option } = Select;
  const onFinishForFilter = (values) => {
    {
      var NewTransactionDate = moment(TransactionDate)
        .add(-1, "days")
        .format("YYYY-MM-DD");
      var NewTransactionDate1 = moment(TransactionDate1)
        .add(1, "days")
        .format("YYYY-MM-DD");
      setSkip(0);
      setTake(20);
      var queryFirm = "";

      if (queryFirm === "" && Currency !== undefined && Currency !== "") {
        queryFirm += `$filter= CurrencyCode eq '${Currency}' `;
      } else if (
        queryFirm !== "" &&
        Currency !== undefined &&
        Currency !== ""
      ) {
        queryFirm += ` and CurrencyCode eq '${Currency}' `;
      }

      if (queryFirm === "" && FirmId !== undefined && FirmId !== "") {
        queryFirm += `$filter=FirmId eq ${FirmId} `;
      } else if (queryFirm !== "" && FirmId !== undefined && FirmId !== "") {
        queryFirm += ` and FirmId eq ${FirmId} `;
      }

      if (queryFirm === "" && Description !== undefined && Description !== "") {
        queryFirm += `$filter=Contains(Description,'${Description}')`;
      } else if (
        queryFirm !== "" &&
        Description !== undefined &&
        Description !== ""
      ) {
        queryFirm += ` and Contains(Description,'${Description}')`;
      }

      if (
        queryFirm === "" &&
        TransactionDate !== undefined &&
        TransactionDate !== ""
      ) {
        queryFirm += `$filter=date(TransactionDateValue) lt ${NewTransactionDate1} and date(TransactionDateValue) gt ${NewTransactionDate}`;
      } else if (
        queryFirm !== "" &&
        TransactionDate !== undefined &&
        TransactionDate !== ""
      ) {
        queryFirm += ` and date(TransactionDateValue) lt ${NewTransactionDate1} and date(TransactionDateValue) gt ${NewTransactionDate}`;
      }
      if (
        queryFirm === "" &&
        Amount1 !== undefined &&
        Amount1 !== "" &&
        (Amount2 === undefined || Amount2 === "")
      ) {
        queryFirm += `$filter= (Amount lt ${Amount1} or Amount eq ${Amount1})`;
      } else if (
        queryFirm !== "" &&
        Amount1 !== undefined &&
        Amount1 !== "" &&
        (Amount2 === undefined || Amount2 === "")
      ) {
        queryFirm += ` and (Amount lt ${Amount1} or Amount eq ${Amount1})`;
      }
      if (
        queryFirm === "" &&
        (Amount1 === undefined || Amount1 === "") &&
        Amount2 !== undefined &&
        Amount2 !== ""
      ) {
        queryFirm += `$filter= (Amount gt ${Amount2} or Amount eq ${Amount2})`;
      } else if (
        queryFirm !== "" &&
        (Amount1 === undefined || Amount1 === "") &&
        Amount2 !== undefined &&
        Amount2 !== ""
      ) {
        queryFirm += ` and (Amount gt ${Amount2} or Amount eq ${Amount2})`;
      }
      if (
        queryFirm === "" &&
        Amount1 !== undefined &&
        Amount1 !== "" &&
        Amount2 !== undefined &&
        Amount2 !== ""
      ) {
        queryFirm += `$filter=(Amount lt ${Amount1} or Amount eq ${Amount1}) and (Amount gt ${Amount2} or Amount eq ${Amount2})`;
      } else if (
        queryFirm !== "" &&
        Amount1 !== undefined &&
        Amount1 !== "" &&
        Amount2 !== undefined &&
        Amount2 !== ""
      ) {
        queryFirm += ` and (Amount lt ${Amount1} or Amount eq ${Amount1}) and (Amount gt ${Amount2} or Amount eq ${Amount2})`;
      }

      if (
        queryFirm === "" &&
        OtherTransactionProcessType !== undefined &&
        OtherTransactionProcessType !== ""
      ) {
        queryFirm += `$filter=OtherTransactionProcessTypeValue eq ${OtherTransactionProcessType} `;
      } else if (
        queryFirm !== "" &&
        OtherTransactionProcessType !== undefined &&
        OtherTransactionProcessType !== ""
      ) {
        queryFirm += ` and OtherTransactionProcessTypeValue eq ${OtherTransactionProcessType} `;
      }

      if (
        queryFirm === "" &&
        MonthlyPlanTypeIdForFilter !== undefined &&
        MonthlyPlanTypeIdForFilter !== ""
      ) {
        queryFirm += `$filter=MonthlyPlanTypeId eq ${MonthlyPlanTypeIdForFilter} `;
      } else if (
        queryFirm !== "" &&
        MonthlyPlanTypeIdForFilter !== undefined &&
        MonthlyPlanTypeIdForFilter !== ""
      ) {
        queryFirm += ` and MonthlyPlanTypeId eq ${MonthlyPlanTypeIdForFilter} `;
      }
      setQuery(queryFirm);
      setCurrentPage(1);

      queryFirm = "";
    }
  };
  const columns = [
    {
      title: "CARİ",
      dataIndex: "firmName",
      key: "firmName",
      render: (text, row, index) =>
        text === null ? (
          ""
        ) : (
          <Tooltip placement="topLeft" title={text}>
            {text.substring(0, 15).length < 15
              ? text.substring(0, 15) + ""
              : text.substring(0, 15) + "..."}
          </Tooltip>
        ),
    },
    {
      title: "İŞLEM TİPİ",
      dataIndex: "monthlyPlanTypeName",
      key: "monthlyPlanTypeName",
    },
    {
      title: "İŞLEM TÜRÜ",
      dataIndex: "otherTransactionProcessType",
      key: "otherTransactionProcessType",
    },
    {
      title: "CARİ KODU",
      dataIndex: "firmCode",
      key: "firmCode",
    },

    {
      title: "TARİH",
      dataIndex: "transactionDate",
      key: "transactionDateValue",
      sorter: true,
      render: (text, row, index) => {
        {
          return <div>{text.substring(0, 10)}</div>;
        }
      },
    },
    {
      title: "TUTAR",
      dataIndex: "amountString",
      key: "amount",
      align: "left",
      sorter: true,
      render: (text, row, index) => {
        {
          return <div style={{ width: "170%" }}>{text}</div>;
        }
      },
    },
    {
      title: "DÖVİZ",
      dataIndex: "currencyCode",
      key: "currencyCode",
    },
    {
      title: "AÇIKLAMA",
      align: "left",
      dataIndex: "description",
      key: "description",
      render: (text, row, index) =>
        text === null ? (
          ""
        ) : (
          <Tooltip placement="topLeft" title={text}>
            {text?.substring(0, 50) === undefined
              ? " "
              : text?.substring(0, 50).trim().length < 50
                ? text + ""
                : text?.substring(0, 50) + "..."}
          </Tooltip>
        ),
    },
    {
      title: "",
      dataIndex: "operation",
      align: "right",
      width: "10%",
      render: (text, record) =>
        monthlyPlans.data.length >= 1 ? (
          <a
            onClick={() => {
              showDrawerUpdate(record.id);
            }}
            style={{ color: "orange", fontSize: "130%" }}
          >
            <EditOutlined />
          </a>
        ) : null,
    },
    {
      title: "",
      dataIndex: "operation",
      width: "1%",
      render: (text, record) =>
        monthlyPlans.data.length >= 1 ? (
          <Popconfirm
            title="Silmek istiyor musunuz?"
            okText="Evet"
            cancelText="Hayır"
            onConfirm={() => {
              record.isRepeat ?
                confirm({
                  title: 'Yapacağınız silme işleminin tüm tekrarlı kayıtlara uygulanmasını ister misiniz?',
                  icon: <ExclamationCircleOutlined />,
                  content: '',
                  cancelText: 'Hayır',
                  okText: 'Evet',
                  onOk() {
                    handleDelete(record.id, true)
                  },
                  onCancel() {
                    handleDelete(record.id, false)
                  },
                })
                :
                handleDelete(record.id, false)
            }}
          >
            <a style={{ color: "red", fontSize: "130%" }}>
              <DeleteOutlined />
            </a>
          </Popconfirm>
        ) : null,
    },
  ];

  return (
    <div>
      <Row>
        {isTabletOrMobileDevice && (
          <>
            <Col span={13}>
              <p style={{ fontSize: "110%" }}>
                <BarsOutlined /> GELİR-GİDER PLANLAMA
              </p>
              <hr></hr>
              <br></br>
            </Col>
            <Col span={11}>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setVisible(true);
                }}
              >
                <PlusOutlined /> Aylık Plan Ekle
              </Button>
            </Col>
          </>
        )}
        {isDesktopOrLaptop && (
          <>
            <Col span={20}>
              <p style={{ fontSize: "120%", marginTop: -5 }}>
                <BarsOutlined /> GELİR-GİDER PLANLAMA
              </p>
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                onClick={() => {
                  setVisible(true);
                }}
              >
                <PlusOutlined /> Aylık Plan Ekle
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Drawer
        title="Aylık Plan Güncelle"
        onClose={onCloseUpdate}
        visible={visibleUpdate}
        bodyStyle={{ padding: 24 }}
        width={500}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <MonthlyPlanUpdate
          monthlyPlanId={monthlyPlanId}
          FirmId={FirmIdUpdate.toString()}
          sendDataForUpdate={getData}
          OtherTransactionProcessType={OtherTransactionProcessTypeUpdate}
          MonthlyPlanTypeId={MonthlyPlanTypeId}
          CurrencyCode={CurrencyCode}
          Amount={Amount}
          isRepeat={isRepeat}
          changeAll={changeAll}
          TransactionDate={TransactionDate}
          Description={DescriptionUpdate}
          teminatDropdown={teminatDropdown}
          currencyDropdown={currencyDropdown}
          ManualTransactionTypeDropdown={ManualTransactionTypeDropdown}
        />
      </Drawer>
      <Drawer
        title="Aylık Plan Ekle"
        onClose={onClose}
        visible={visible}
        bodyStyle={{ padding: 24 }}
        width={500}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <MonthlyPlanAdd />
      </Drawer>
      <Collapse defaultActiveKey={["0"]}>
        <Panel header="Filtreleme için tıklayınız." key="1">
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinishForFilter}
          >
            <Row>
              {isDesktopOrLaptop && (
                <>
                  <Col span={6}>Cari :</Col>
                  <Col span={6}>İşlem Tipi :</Col>
                  <Col span={6}>İşlem Türü :</Col>
                  <Col span={6}>Tarih Aralığı :</Col>

                  <Col span={6}>
                    <Select
                      showSearch
                      name="FirmId"
                      style={{ width: "100%" }}
                      onChange={handleFirmId}
                      value={FirmId === "" ? null : FirmId}
                      placeholder="En az 2 harf giriniz.."
                      onSearch={onFirmSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                        option.code
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      loading={firmSelect.loading}
                    >
                      {firmSelect.data.map((item) => (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          code={item.firmBranchNumber}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={6}>
                    <Select
                      style={{ width: "100%" }}
                      name="MonthlyPlanTypeId"
                      value={
                        MonthlyPlanTypeIdForFilter === ""
                          ? null
                          : MonthlyPlanTypeIdForFilter
                      }
                      onChange={handleMonthlyPlanTypeId}
                      placeholder="İşlem tipi seçiniz"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {ManualTransactionTypeDropdown.map((item) => (
                        <Option key={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={6}>
                    <Select
                      style={{ width: "100%", marginBottom: "4%" }}
                      value={
                        OtherTransactionProcessType === ""
                          ? null
                          : OtherTransactionProcessType
                      }
                      name="OtherTransactionProcessType"
                      placeholder="Ödeme/Tahsilat tipi seçiniz"
                      onChange={handleOtherTransactionProcessType}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {teminatDropdown.map((item) => (
                        <Option key={item.value == "Ödeme" ? 2 : 1}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={6}>
                    <RangePicker
                      format={"DD-MM-YYYY"}
                      name="TransactionDate"
                      placeholder={["Başlangıç", "Bitiş"]}
                      Value={[
                        moment(TransactionDate),
                        moment(TransactionDate1),
                      ]}
                      style={{ width: "100%" }}
                      onChange={handleTransactionDate}
                    />
                  </Col>

                  <Col span={6}>Tutar Aralığı</Col>
                  <Col span={6}>Para Birimi :</Col>
                  <Col span={6}>Açıklama :</Col>
                  <Col span={6}></Col>

                  <Col span={6}>
                    <Input.Group compact>
                      <Input
                        name="Amount2"
                        value={Amount2}
                        style={{ width: "50%" }}
                        onChange={handleAmount2}
                        placeholder="Tutar Başlangıç"
                      />
                      <Input
                        name="Amount1"
                        value={Amount1}
                        onChange={handleAmount1}
                        style={{ width: "50%" }}
                        placeholder="Tutar Bitiş"
                      />
                    </Input.Group>
                  </Col>
                  <Col span={6}>
                    <Select
                      name="Currency"
                      style={{ width: "100%" }}
                      showSearch
                      onChange={handleCurrency}
                      value={Currency === "" ? null : Currency}
                      placeholder="Para birimini seçiniz"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {getFilterCurrency.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={6}>
                    <Input
                      name="Description"
                      value={Description}
                      onChange={handleDescription}
                      style={{ width: "100%", marginBottom: "4%" }}
                      placeholder="Açıklama"
                    />
                  </Col>
                  <Col span={6}></Col>

                  <Col span={12}></Col>
                  <Col span={6}>
                    <Button
                      danger
                      size="small"
                      icon={<CloseOutlined />}
                      onClick={() => handleReset()}
                      style={{ width: "100%" }}
                    >
                      Sıfırla
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      primary
                      size="small"
                      icon={<SearchOutlined />}
                      style={{
                        color: "#4d98aa",
                        borderColor: "#4d98aa",
                        width: "100%",
                      }}
                      htmlType="submit"
                    >
                      Filtrele
                    </Button>
                  </Col>
                </>
              )}
              {isTabletOrMobileDevice && (
                <>
                  <Col span={12}>Cari :</Col>
                  <Col span={12}>İşlem Tipi :</Col>

                  <Col span={12}>
                    <Select
                      showSearch
                      name="FirmId"
                      style={{ width: "100%" }}
                      onChange={handleFirmId}
                      value={FirmId === "" ? null : FirmId}
                      placeholder="En az 2 harf giriniz.."
                      onSearch={onFirmSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                        option.code
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      loading={firmSelect.loading}
                    >
                      {firmSelect.data.map((item) => (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          code={item.firmBranchNumber}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <Select
                      style={{ width: "100%" }}
                      name="MonthlyPlanTypeId"
                      value={
                        MonthlyPlanTypeIdForFilter === ""
                          ? null
                          : MonthlyPlanTypeIdForFilter
                      }
                      onChange={handleMonthlyPlanTypeId}
                      placeholder="İşlem tipi seçiniz"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {ManualTransactionTypeDropdown.map((item) => (
                        <Option key={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={12}>İşlem Türü :</Col>
                  <Col span={12}>Tarih Aralığı :</Col>

                  <Col span={12}>
                    <Select
                      style={{ width: "100%", marginBottom: "4%" }}
                      value={
                        OtherTransactionProcessType === ""
                          ? null
                          : OtherTransactionProcessType
                      }
                      name="OtherTransactionProcessType"
                      placeholder="Ödeme/Tahsilat tipi seçiniz"
                      onChange={handleOtherTransactionProcessType}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {teminatDropdown.map((item) => (
                        <Option key={item.value === "Ödeme" ? 2 : 1}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <RangePicker
                      name="TransactionDate"
                      placeholder={["Başlangıç", "Bitiş"]}
                      Value={[
                        moment(TransactionDate, dateFormat),
                        moment(TransactionDate1, dateFormat),
                      ]}
                      style={{ width: "100%" }}
                      onChange={handleTransactionDate}
                    />
                  </Col>

                  <Col span={12}>Tutar Aralığı</Col>
                  <Col span={12}>Para Birimi :</Col>

                  <Col span={12}>
                    <Input.Group compact>
                      <Input
                        name="Amount2"
                        value={Amount2}
                        style={{ width: "50%" }}
                        onChange={handleAmount2}
                        placeholder="Tutar Başlangıç"
                      />
                      <Input
                        name="Amount1"
                        value={Amount1}
                        onChange={handleAmount1}
                        style={{ width: "50%" }}
                        placeholder="Tutar Bitiş"
                      />
                    </Input.Group>
                  </Col>
                  <Col span={12}>
                    <Select
                      name="Currency"
                      style={{ width: "100%" }}
                      showSearch
                      onChange={handleCurrency}
                      value={Currency === "" ? null : Currency}
                      placeholder="Para birimini seçiniz"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {getFilterCurrency.map((item) => (
                        <Option key={item.text} value={item.text}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={12}>Açıklama :</Col>
                  <Col span={12}></Col>
                  <Col span={12}>
                    <Input
                      name="Description"
                      value={Description}
                      onChange={handleDescription}
                      style={{ width: "100%", marginBottom: "4%" }}
                      placeholder="Açıklama"
                    />
                  </Col>
                  <Col span={12}></Col>
                  <Col span={12}>
                    <Button
                      danger
                      size="small"
                      style={{ width: "100%", marginTop: "%6" }}
                      icon={<CloseOutlined />}
                      onClick={() => handleReset()}
                    >
                      Sıfırla
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      primary
                      size="small"
                      icon={<SearchOutlined />}
                      style={{
                        color: "#4d98aa",
                        borderColor: "#4d98aa",
                        width: "100%",
                        marginTop: "%6",
                      }}
                      htmlType="submit"
                    >
                      Filtrele
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </Panel>
      </Collapse>
      <br></br>
      <Table
        columns={columns}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Veri Yok"
            />
          ),
        }}
        style={{ overflowX: "auto", maxHeight: "100%" }}
        size="small"
        rowKey="id"
        filterable={true}
        expandable={{
          expandedRowRender: (record) => (
            <p>
              <b>Açıklama : </b> {record.description}
              <br />
            </p>
          ),
        }}
        sortable={true}
        pageable={true}
        onChange={paging}
        onDataStateChange={dataStateChange}
        pagination={{
          pageSizeOptions: ["15", "25", "50", "100"],
          defaultPageSize: take,
          total: monthlyPlans.total,
          current: currentPage,
        }}
        dataSource={monthlyPlans.data}
        loading={monthlyPlans.loading}
      />
    </div>
  );
};

export default MonthlyPlanList;
