import React, { useState, useEffect } from "react";
import {
  Table,
  Empty,
  Drawer,
  Col,
  Row,
  notification,
  Popconfirm,
  Button,
} from "antd";
import {
  PlusOutlined,
  BarsOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import FirmClassAddForm from "../Forms/FirmClassAddForm";
import FirmClassUpdate from "../Forms/FirmClassUpdate";
import config from "../../util/ApiforContext";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
const expiration = localStorage.getItem("expiration");
const openNotificationWithIconDelete = (type) => {
  notification[type]({
    message: "BAŞARILI",
    description: "Silme işlemi başarı ile gerçekleşti.",
    duration: 2,
  });
};
const openNotificationWithIconWarningDelete = (type) => {
  notification[type]({
    message: "HATA",
    description: "Silme işlemi sırasında bir hata oluştu.",
    duration: 2,
  });
};
const FirmClassList = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const history = useHistory();
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  const [firmClasses, setFirmClasses] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [dataState, setDataState] = useState([{ skip: 0, take: 10 }]);
  const [firmClassId, setFirmClassId] = useState("");
  const [Name, setName] = useState("");
  const [serialNumber, setSerialNumber] = useState('');
  const [erpValue, setErpValue] = useState('');
  const [take, setTake] = useState(20);
  const [skip, setSkip] = useState(0);
  const [FirmTypeValue, setFirmTypeValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const errorList = [];
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 2,
      });
    });
  };
  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }

    setFirmClasses({
      ...firmClasses,
      loading: true,
    });

    axios
      .get(
        `${config.apiUrl}/FirmClass?$count=true&$skip=${skip}&$top=${take}`,
        config.headers
      )
      .then((res) => {
        setFirmClasses({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  }, [setDataState, skip, setSkip]);
  const handleDelete = (key) => {
    axios
      .delete(`${config.apiUrl}/FirmClass?id=${key}`, config.headers)
      .then((res) => {
        if (res.status == 204 || res.status == 200) {
          openNotificationWithIconDelete("success");
          setFirmClasses({
            data: firmClasses.data.filter((item) => item.id !== key),
            total: firmClasses.total - 1,
            loading: false,
          });
          if (firmClasses.data.filter((item) => item.id !== key).length == 0) {
            window.location.reload();
          }
        } else {
          openNotificationWithIconWarningDelete("error");
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else {
          openNotificationWithIconWarningDelete("error");
        }
      });
  };
  const paging = (p) => {
    setDataState((prevState) => {
      prevState.skip = (p.current - 1) * 20;
      setSkip(prevState.skip);
      return {
        prevState,
      };
    });
  };
  const showDrawerUpdate = (value) => {
    axios
      .get(`${config.apiUrl}/FirmClass/getbyid/${value}`, config.headers)
      .then((res) => {
        setName(res.data.name);
        setSerialNumber(res.data.serialNumber);
        setErpValue(res.data.erpValue);
        setFirmTypeValue(res.data.firmTypeValue);
        setFirmClassId(value);
        setVisibleUpdate(true);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        }
      });
  };
  const onClose = () => {
    setVisibleUpdate(false);
    setVisible(false);
  };
  const onCloseUpdate = () => {
    setVisibleUpdate(false);
  };
  const reList = () => {
    setFirmClasses({
      firmClasses,
      loading: true,
    });
    axios
      .get(
        `${config.apiUrl}/FirmClass?$count=true&$skip=${skip}&$top=${take}`,
        config.headers
      )
      .then((res) => {
        setFirmClasses({
          data: res.data.value,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        }
      });
  };
  const getData = (val) => {
    if (val === "success") {
      reList();
      setVisibleUpdate(false);
      setVisible(false);
    }
  };
  //filtre

  const columns = [
    {
      title: "Cari Sınıf Adı",
      dataIndex: "name",
      key: "name",
      width: "8%",
    },
    {
      title: "Cari Tipi",
      dataIndex: "firmTypeValue",
      key: "firmTypeValue",
      width: "8%",
    },
    {
      title: "Seri No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: "8%",
    },
    {
      title: "Erp Kod",
      dataIndex: "erpValue",
      key: "erpValue",
      width: "8%",
    },

    {
      title: "",
      dataIndex: "operation",
      align: "right",
      width: "2%",
      render: (text, record) =>
        firmClasses.data.length >= 1 && record.name !="Apartman Dairesi" ? (
          <a
            onClick={() => {
              showDrawerUpdate(record.id);
            }}
            style={{ color: "orange", fontSize: "130%" }}
          >
            <EditOutlined />
          </a>
        ) : null,
    },
    {
      title: "",
      dataIndex: "operation",
      width: "1%",
      render: (text, record) =>
        firmClasses.data.length >= 1 && record.name !="Apartman Dairesi" ? (
          <Popconfirm
            title="Silmek istiyor musunuz?"
            okText="Evet"
            cancelText="Hayır"
            onConfirm={() => handleDelete(record.id)}
          >
            <a style={{ color: "red", fontSize: "130%" }}>
              <DeleteOutlined />
            </a>
          </Popconfirm>
        ) : null,
    },
  ];
  return (
    <div>
      <Row>
        {isTabletOrMobileDevice && (
          <>
            <Col span={13}>
              <p style={{ fontSize: "120%" }}>
                <BarsOutlined /> CARİ SINIFLARI
              </p>
              <hr></hr>
              <br></br>
            </Col>
            <Col span={11}>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setVisible(true);
                }}
              >
                <PlusOutlined /> Cari Sınıfı Ekle
              </Button>
            </Col>
          </>
        )}
        {isDesktopOrLaptop && (
          <>
            <Col span={20}>
              <p style={{ fontSize: "120%", marginTop: -9 }}>
                <BarsOutlined /> CARİ SINIFLARI
              </p>
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                onClick={() => {
                  setVisible(true);
                }}
              >
                <PlusOutlined /> Cari Sınıfı Ekle
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Drawer
        title="Cari Sınıfı Güncelle"
        width={241}
        onClose={onCloseUpdate}
        visible={visibleUpdate}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <FirmClassUpdate
          name={Name}
          erpValue={erpValue}
          serialNumber={serialNumber}
          sendDataForUpdate={getData}
          firmClassId={firmClassId}
          firmTypeValue={FirmTypeValue}
        />
      </Drawer>
      <Drawer
        title="Cari Sınıfı Ekle"
        width={241}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <FirmClassAddForm />
      </Drawer>
      <Row>
        {isDesktopOrLaptop && (
          <>
            <Col span={2}></Col>
            <Col span={20}>
              <Table
                columns={columns}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Veri Yok"
                    />
                  ),
                }}
                style={{ overflowX: "auto", maxHeight: "100%" }}
                size="small"
                rowKey="Id"
                filterable={true}
                sortable={true}
                pageable={true}
                onChange={paging}
                pagination={{ pageSize: 20, total: firmClasses.total }}
                dataSource={firmClasses.data}
                loading={firmClasses.loading}
              />
            </Col>
            <Col span={2}></Col>
          </>
        )}
        {isTabletOrMobileDevice && (
          <>
            <Col span={24}>
              <Table
                columns={columns}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Veri Yok"
                    />
                  ),
                }}
                style={{ overflowX: "auto", width: "100%", maxHeight: "100%" }}
                size="small"
                rowKey="Id"
                filterable={true}
                sortable={true}
                pageable={true}
                onChange={paging}
                pagination={{ pageSize: 20, total: firmClasses.total }}
                dataSource={firmClasses.data}
                loading={firmClasses.loading}
              />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default FirmClassList;
