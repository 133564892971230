import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Input, Select, Form, Col, notification, Row, Button } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../util/ApiforContext";
const expiration = localStorage.getItem("expiration");

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 15,
    span: 5,
  },
};
const { Option } = Select;
const { TextArea } = Input;

const FirmUpdate = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const onChangeFirmType = (value) => {
    setFirmTypeValue(value);
    setFirmClassId("");
    axios
      .get(`${config.apiUrl}/FirmClass?firmTypeId=${value}`, config.headers)
      .then((res) => {
        setfirmClassDropdown(res.data.value);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };

  const history = useHistory();
  const [inputList, setInputList] = useState([]);
  const [wordList, setWordList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Name, setName] = useState(props.Name);
  const [DirectiveName, setDirectiveName] = useState(props.DirectiveName);
  const [Email, setEmail] = useState(props.Email);
  const [ErpSalesCode, setErpSalesCode] = useState(props.ErpSalesCode);
  const [groupCode, setGroupCode] = useState(props.GroupCode);
  const [TaxOffice, setTaxOffice] = useState(props.TaxOffice);
  const [Vkn, setVkn] = useState(props.Vkn);
  const [TcNo, setTcNo] = useState(props.TcNo);
  const [Phone, setPhone] = useState(props.Phone);
  const [MobilePhone, setMobilePhone] = useState(props.MobilePhone);
  const [Fax, setFax] = useState(props.Fax);
  const [Address, setAddress] = useState(props.Address);
  const [FirmTypeValue, setFirmTypeValue] = useState(props.FirmTypeValue);
  const [FirmBranchNumber, setFirmBranchNumber] = useState(
    props.FirmBranchNumber
  );
  const [FirmClassId, setFirmClassId] = useState(props.FirmClassId);
  const [firmTypeDropdown, setfirmTypeDropdown] = useState(
    props.firmTypeDropdown
  );
  const [firmClassDropdown, setfirmClassDropdown] = useState(
    props.firmClassDropdown
  );
  const [ErpCode, setErpCode] = useState(props.ErpCode);
  const errorList = [];
  const wordArray = [];

  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }
    props.wordList.map((item) => {
      var model = {
        word: item.word,
      };
      wordArray.push(model);
    });
    axios
      .get(`${config.apiUrl}/Firm/GetFirmType`, config.headers)
      .then((res) => {
        const firmTypeDropdown = res.data.data;
        setfirmTypeDropdown(firmTypeDropdown);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    axios
      .get(`${config.apiUrl}/FirmClass`, config.headers)
      .then((res) => {
        const firmClassDropdown = res.data.value;
        setfirmClassDropdown(firmClassDropdown);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    wordArray.length < 1 ? setWordList([{ word: "" }]) : setWordList(wordArray);

    setName(props.Name);
    setDirectiveName(props.DirectiveName);
    setEmail(props.Email);
    setGroupCode(props.GroupCode);
    setErpSalesCode(props.ErpSalesCode);
    setTaxOffice(props.TaxOffice);
    setVkn(props.Vkn);
    setTcNo(props.TcNo);
    setPhone("+90" + props.Phone);
    setMobilePhone("+90" + props.MobilePhone);
    setFax(props.Fax);
    setAddress(props.Address);
    setFirmTypeValue(props.FirmTypeValue);
    setFirmBranchNumber(props.FirmBranchNumber);
    setFirmClassId(props.FirmClassId);
    setErpCode(props.ErpCode);
  }, [
    setName,
    setDirectiveName,
    setEmail,
    setErpSalesCode,
    setTaxOffice,
    setVkn,
    setTcNo,
    setTcNo,
    setFax,
    setAddress,
    setFirmTypeValue,
    setFirmBranchNumber,
    setFirmClassId,
    setWordList,
    setGroupCode,
    setErpCode,
    props.Name,
    props.DirectiveName,
    props.Email,
    props.ErpSalesCode,
    props.TaxOffice,
    props.Vkn,
    props.TcNo,
    props.Phone,
    props.MobilePhone,
    props.Fax,
    props.Address,
    props.FirmTypeValue,
    props.GroupCode,
    props.FirmBranchNumber,
    props.FirmClassId,
    props.wordList,
    props.ErpCode,
  ]);
  const openNotificationWithIconUpdate = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Güncelleme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });
  };
  const openNotificationWithIconWarningUpdate = (type) => {
    notification[type]({
      message: "HATA",
      description: "Güncelleme işlemi sırasında bir hata oluştu.",
      duration: 2,
    });
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...wordList];
    list[index][name] = value;
    setWordList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...wordList];
    list.splice(index, 1);
    setWordList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setWordList([...wordList, { word: "" }]);
  };
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 4,
      });
    });
  };

  const onFinishUpdate = (values) => {
    const words = [];
    wordList.map((item) => {
      words.push(item.word.trim());
    });
    var error = 0;
    words.map((item) => {
      var wordLength = item.length;
      if (wordLength < 5 && wordLength !== 0) {
        error += 1;
      }
    });

    axios
      .put(
        `${config.apiUrl}/Firm`,
        {
          Id: props.firmId,
          Name: values.Name,
          FirmTypeValue:
            values.FirmTypeValue === null ? 0 : parseInt(values.FirmTypeValue),
          FirmBranchNumber: values.FirmBranchNumber,
          DirectiveName: values.DirectiveName,
          Email: values.Email,
          ErpSalesCode: values.ErpSalesCode,
          TaxOffice: values.TaxOffice,
          Vkn: values.Vkn,
          TcNo: values.TcNo,
          GroupCode: values.GroupCode,
          Phone: values.Phone,
          MobilePhone: values.MobilePhone,
          Fax: values.Fax,
          Address: values.Address,
          FirmClassId: values.FirmClassId,
          ErpCode: values.ErpCode,
        },
        config.headers
      )
      .then((res) => {
        if (res.status == 200) {
          if (error == 0) {
            axios
              .put(
                `${config.apiUrl}/Firm/FirmMatchingWordPut`,
                {
                  FirmId: props.firmId,
                  Words: words.filter((item) => item.name !== ""),
                },
                config.headers
              )
              .then((res) => {
                if (res.status === 200) {
                }
              })
              .catch((error) => {
                if (
                  error.response.status === 403 ||
                  error.response.status === 401
                ) {
                  history.push("/not-authorized-access");
                } else if (error.response.status === 406) {
                  error.response.data.data.map((item) => {
                    errorList.push(item);
                  });
                  errorListNotification("error");
                  setLoading(false);
                } else {
                  openNotificationWithIconWarningUpdate("error");
                  setLoading(false);
                }
              });
          } else {
            wordLengtError("error");
            setLoading(false);
          }
          setInputList(words);

          openNotificationWithIconUpdate("success");
          setTimeout(
            function () {
              props.sendDataForUpdate("success");
              setLoading(false);
            }.bind(this),
            2000
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
          setLoading(false);
        } else {
          openNotificationWithIconWarningUpdate("error");
          setLoading(false);
        }
      });
  };
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };
  const onFinishFailedUpdate = (errorInfo) => {
    setLoading(false);
  };
  const wordLengtError = (type) => {
    notification[type]({
      message: "HATA",
      description:
        "Kelimeler bölümündeki harf sayısı 5ten küçük olduğu için güncelleme başarısız oldu, düzenleyip tekrar deneyin.",
      duration: 4,
    });
  };
  const onChangePhoneValue = (e) => {
    setPhone(e);
  };
  const onChangeEmailValue = (e) => {
    setEmail(e.target.value);
  };
  const onChangeErpSalesCode = (e) => {
    setErpSalesCode(e.target.value);
  };
  const onChangeVknValue = (e) => {
    setVkn(e.target.value);
  };
  const onChangeTcNoValue = (e) => {
    setTcNo(e.target.value);
  };
  const onChangeTaxValue = (e) => {
    setTaxOffice(e.target.value);
  };
  const onChangeMobilePhoneValue = (e) => {
    setMobilePhone(e);
  };
  const onChangeFirmGroupCode = (e) => {
    setGroupCode(e.target.value);
  };
  const onChangeFaxValue = (e) => {
    setFax(e.target.value);
  };
  const onChangeAddressValue = (e) => {
    setAddress(e.target.value);
  };
  const onChangeNameValue = (e) => {
    setName(e.target.value);
  };
  const onChangeFirmBranchNumberValue = (e) => {
    setFirmBranchNumber(e.target.value);
  };
  const onChangeDirectiveNameValue = (e) => {
    setDirectiveName(e.target.value);
  };
  const onChangeFirmClassValue = (e) => {
    setFirmClassId(e);
  };
  const onChangeErpCodeValue = (e) => {
    setErpCode(e.target.value);
  };
  const onClickFirmClassValue = (e) => {
    axios
      .get(
        `${config.apiUrl}/FirmClass?firmTypeId=${FirmTypeValue}`,
        config.headers
      )
      .then((res) => {
        setfirmClassDropdown(res.data.value);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        }
      });
  };
  const onChangeFirmTypeValue = (e) => {
    setFirmTypeValue(e);
  };
  return (
    <div>
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinishUpdate}
        onFinishFailed={onFinishFailedUpdate}
        fields={[
          { name: "Name", value: Name },
          { name: "DirectiveName", value: DirectiveName },
          { name: "Email", value: Email },
          { name: "ErpSalesCode", value: ErpSalesCode },
          { name: "TaxOffice", value: TaxOffice },
          { name: "Vkn", value: Vkn },
          { name: "GroupCode", value: groupCode },
          { name: "TcNo", value: TcNo },
          { name: "Phone", value: Phone },
          { name: "MobilePhone", value: MobilePhone },
          { name: "Fax", value: Fax },
          { name: "Address", value: Address },
          {
            name: "FirmTypeValue",
            value: FirmTypeValue === "0" ? null : FirmTypeValue,
          },
          { name: "FirmBranchNumber", value: FirmBranchNumber },
          { name: "FirmClassId", value: FirmClassId },
          { name: "ErpCode", value: ErpCode },
        ]}
      >
        <Row>
          {isDesktopOrLaptop && (
            <>
              <Col span={24}>
                <Form.Item
                  label="Cari Adı/Ünvanı"
                  name="Name"
                  style={{ marginTop: "4%" }}
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Cari Adı/Ünvanı alanını boş bırakmayın.",
                    },
                  ]}
                >
                  <Input onChange={onChangeNameValue} />
                </Form.Item>
                <Form.Item label="Talimat Ünvanı" name="DirectiveName">
                  <Input onChange={onChangeDirectiveNameValue} />
                </Form.Item>
                <Form.Item label="E-Posta Adresi" name="Email">
                  <Input onChange={onChangeEmailValue} />
                </Form.Item>
                <Form.Item label="Vergi Dairesi" name="TaxOffice">
                  <Input onChange={onChangeTaxValue} />
                </Form.Item>

                <Form.Item
                  label="Vergi Numarası"
                  name="Vkn"
                  rules={[
                    {
                      min: 10,
                      message: "Vergi Numarası 10 Karakter Olmalıdır.",
                    },
                  ]}
                >
                  <Input
                    onChange={onChangeVknValue}
                    type="number"
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    maxLength="10"
                    onInput={maxLengthCheck}
                  />
                </Form.Item>
                <Form.Item
                  label="T.C. Kimlik Numarası "
                  name="TcNo"
                  rules={[
                    {
                      min: 11,
                      message: "TC Kimlik Numarası 11 Karakter Olmalıdır.",
                    },
                  ]}
                >
                  <Input
                    onChange={onChangeTcNoValue}
                    type="number"
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    maxLength="11"
                    onInput={maxLengthCheck}
                  />
                </Form.Item>
                <Form.Item label="Telefon Numarası" name="Phone">
                  <PhoneInput
                    onChange={onChangePhoneValue}
                    inputStyle={{ width: "100%" }}
                    country="tr"
                    masks={{ tr: "(...) ...-..-.." }}
                    rules={[
                      {
                        min: 12,
                        message: "Telefon numarasını eksiksiz giriniz.",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label="Cep Numarası"
                  name="MobilePhone"
                  rules={[
                    {
                      min: 12,
                      message: "Telefon numarasını eksiksiz giriniz.",
                    },
                  ]}
                >
                  <PhoneInput
                    onChange={onChangeMobilePhoneValue}
                    inputStyle={{ width: "100%" }}
                    country="tr"
                    masks={{ tr: "(...) ...-..-.." }}
                  />
                </Form.Item>
                <Form.Item label="Fax Numarası" name="Fax">
                  <Input onChange={onChangeFaxValue} />
                </Form.Item>
                <Form.Item label="Adres" name="Address">
                  <TextArea onChange={onChangeAddressValue} rows={4} />
                </Form.Item>
                <Form.Item label="Cari Tipi" name="FirmTypeValue">
                  <Select
                    placeholder="Seçiniz"
                    optionFilterProp="children"
                    onChange={onChangeFirmType}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLocaleLowerCase("tr-TR")
                        .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                    }
                  >
                    {firmTypeDropdown.map((item) => (
                      <Option key={item.key}>{item.value}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Cari Kodu"
                  name="FirmBranchNumber"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Cari Kodu alanını boş bırakmayın.",
                    },
                  ]}
                >
                  <Input onChange={onChangeFirmBranchNumberValue} />
                </Form.Item>
                <Form.Item
                  label="Erp Kodu"
                  name="ErpCode"
                  rules={[
                    {
                      max: 100,
                      message:
                        "Erp Kodu alanı maksimum 100 karakter olmalıdır.",
                    },
                  ]}
                >
                  <Input onChange={onChangeErpCodeValue} />
                </Form.Item>
                <Form.Item label="Satıcı Kodu" name="ErpSalesCode">
                  <Input onChange={onChangeErpSalesCode} />
                </Form.Item>
                <Form.Item label="Cari Sınıfı" name="FirmClassId">
                  <Select
                    showSearch
                    placeholder="Seçiniz"
                    onChange={onChangeFirmClassValue}
                    onClick={onClickFirmClassValue}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLocaleLowerCase("tr-TR")
                        .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                    }
                  >
                    {firmClassDropdown.map((item) => (
                      <Option key={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Grup Kodu" name="GroupCode">
                  <Input onChange={onChangeFirmGroupCode} />
                </Form.Item>
                <Form.Item label="Kelimeler" name="words">
                  {wordList.map((x, i) => {
                    return (
                      <Row>
                        <Col span={20} style={{ marginLeft: "-6%" }}>
                          <Input
                            name="word"
                            placeholder="Kelime Girin"
                            value={x.word}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </Col>
                        <Col span={2}>
                          {wordList.length !== 1 && (
                            <Button
                              className="mr10"
                              onClick={() => handleRemoveClick(i)}
                            >
                              <MinusOutlined />
                            </Button>
                          )}
                          {wordList.length - 1 === i && (
                            <Button onClick={handleAddClick}>
                              <PlusOutlined />
                            </Button>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                </Form.Item>
              </Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
              <Col span={3}>
                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    htmlType="submit"
                    onClick={() => setLoading(true)}
                    loading={loading}
                  >
                    Güncelle
                  </Button>
                </Form.Item>
              </Col>
              <Col span={3}></Col>
            </>
          )}
          {isTabletOrMobileDevice && (
            <>
              <Col span={24}>
                <Form.Item
                  label="Cari Adı/Ünvanı"
                  name="Name"
                  style={{ marginTop: "4%" }}
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Cari Adı/Ünvanı alanını boş bırakmayın.",
                    },
                  ]}
                >
                  <Input onChange={onChangeNameValue} />
                </Form.Item>
                <Form.Item label="Talimat Ünvanı" name="DirectiveName">
                  <Input onChange={onChangeDirectiveNameValue} />
                </Form.Item>
                <Form.Item label="E-Posta Adresi" name="Email">
                  <Input onChange={onChangeEmailValue} />
                </Form.Item>
                <Form.Item label="Vergi Dairesi" name="TaxOffice">
                  <Input onChange={onChangeTaxValue} />
                </Form.Item>
                <Form.Item
                  label="Vergi Numarası"
                  name="Vkn"
                  rules={[
                    {
                      min: 10,
                      message: "Vergi Numarası 10 Karakter Olmalıdır.",
                    },
                  ]}
                >
                  <Input
                    onChange={onChangeVknValue}
                    type="number"
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    maxLength="10"
                    onInput={maxLengthCheck}
                  />
                </Form.Item>
                <Form.Item
                  label="T.C. Kimlik Numarası "
                  name="TcNo"
                  rules={[
                    {
                      min: 11,
                      message: "TC Kimlik Numarası 11 Karakter Olmalıdır.",
                    },
                  ]}
                >
                  <Input
                    onChange={onChangeTcNoValue}
                    type="number"
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    maxLength="11"
                    onInput={maxLengthCheck}
                  />
                </Form.Item>

                <Form.Item label="Telefon Numarası" name="Phone">
                  <PhoneInput
                    onChange={onChangePhoneValue}
                    inputStyle={{ width: "100%" }}
                    country="tr"
                    masks={{ tr: "(...) ...-..-.." }}
                    rules={[
                      {
                        min: 12,
                        message: "Telefon numarasını eksiksiz giriniz.",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label="Cep Numarası"
                  name="MobilePhone"
                  rules={[
                    {
                      min: 12,
                      message: "Telefon numarasını eksiksiz giriniz.",
                    },
                  ]}
                >
                  <PhoneInput
                    onChange={onChangeMobilePhoneValue}
                    inputStyle={{ width: "100%" }}
                    country="tr"
                    masks={{ tr: "(...) ...-..-.." }}
                  />
                </Form.Item>
                <Form.Item label="Fax Numarası" name="Fax">
                  <Input onChange={onChangeFaxValue} />
                </Form.Item>
                <Form.Item label="Adres" name="Address">
                  <TextArea onChange={onChangeAddressValue} rows={4} />
                </Form.Item>
                <Form.Item label="Cari Tipi" name="FirmTypeValue">
                  <Select
                    placeholder="Seçiniz"
                    optionFilterProp="children"
                    onChange={onChangeFirmType}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLocaleLowerCase("tr-TR")
                        .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                    }
                  >
                    {firmTypeDropdown.map((item) => (
                      <Option key={item.key}>{item.value}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Cari Kodu"
                  name="FirmBranchNumber"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Cari Kodu alanını boş bırakmayın.",
                    },
                  ]}
                >
                  <Input onChange={onChangeFirmBranchNumberValue} />
                </Form.Item>
                <Form.Item
                  label="Erp Kodu"
                  name="ErpCode"
                  rules={[
                    {
                      max: 100,
                      message:
                        "Erp Kodu alanı maksimum 100 karakter olmalıdır.",
                    },
                  ]}
                >
                  <Input onChange={onChangeErpCodeValue} />
                </Form.Item>
                <Form.Item label="Satıcı Kodu" name="ErpSalesCode">
                  <Input onChange={onChangeErpSalesCode} />
                </Form.Item>
                <Form.Item label="Cari Sınıfı" name="FirmClassId">
                  <Select
                    showSearch
                    placeholder="Seçiniz"
                    onChange={onChangeFirmClassValue}
                    onClick={onClickFirmClassValue}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLocaleLowerCase("tr-TR")
                        .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                    }
                  >
                    {firmClassDropdown.map((item) => (
                      <Option key={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Grup Kodu" name="GroupCode">
                  <Input onChange={onChangeFirmGroupCode} />
                </Form.Item>
                <Form.Item label="Kelimeler" name="words">
                  {wordList.map((x, i) => {
                    return (
                      <Row>
                        <Col span={20} style={{ marginLeft: "-6%" }}>
                          <Input
                            name="word"
                            placeholder="Kelime Girin"
                            value={x.word}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </Col>
                        <Col span={2}>
                          {wordList.length !== 1 && (
                            <Button
                              className="mr10"
                              onClick={() => handleRemoveClick(i)}
                            >
                              <MinusOutlined />
                            </Button>
                          )}
                          {wordList.length - 1 === i && (
                            <Button onClick={handleAddClick}>
                              <PlusOutlined />
                            </Button>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                </Form.Item>
              </Col>
              <Col span={10}></Col>
              <Col span={8}>
                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    htmlType="submit"
                    onClick={() => setLoading(true)}
                    loading={loading}
                  >
                    Güncelle
                  </Button>
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </>
          )}
        </Row>
      </Form>
    </div>
  );
};
export default FirmUpdate;
