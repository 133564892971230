import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button } from "antd";
import { SendOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const B2bPosCollectionSendReceiptForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [mailLoading, setMailLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      getDefaultMail(props.data.id);
    }
  }, [props.data]);

  const getDefaultMail = (id) => {
    setMailLoading(true);
    setLoading(true);
    RequestHandler.get(`/PosCollection/GetDefaultMailForReceipt/${id}`).then((response) => {
      setMailLoading(false);
      setLoading(false);

      if (typeof response === 'string') {
        form.setFieldsValue({ ...form.getFieldsValue(), email: response });
      }
    });
  }

  const onFinish = (values) => {
    RequestHandler.post('/PosCollection/SendReceipt', values).then((response) => {
      setLoading(false);

      if (response.success) {
        form.resetFields();
      }

      if (props.onFinish) props.onFinish();
    });

  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="sendReceipt"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label="E-Posta"
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input disabled={mailLoading} />
          </Form.Item>

        </Col>
        <Col span={24} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            icon={<SendOutlined />}
            htmlType="submit"
            onClick={() => setLoading(true)}
            loading={loading}
          >
            Gönder
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
export default B2bPosCollectionSendReceiptForm;
