import React, { useState, useEffect } from "react";

import { Row, Spin } from "antd";

import MicroErpFirmReport from "./Micro/ErpFirmReport";
import NetsisErpFirmReport from "./Netsis";

import RequestHandler from "util/RequestHandler";

const ErpReportPage = (props) => {
  const [tenantErpProgram, setTenantErpProgram] = useState({ data: null, loading: true });

  useEffect(() => {
    RequestHandler.get(`/TenantErpProgram/GetByTenant`).then((response) => {
      setTenantErpProgram({
        data: response,
        loading: false,
      });
    });
  }, [])

  return (
    <>
      {tenantErpProgram.loading && (
        <Row align="middle" justify="center" style={{ height: "100%" }}>
          <Spin spinning={true} />
        </Row>
      )}

      {!tenantErpProgram.loading && tenantErpProgram.data === null && (
        <Row align="middle" justify="center" style={{ height: "100%" }}>
          Firmanızın tanımlı bir erp programı bulunmuyor.
        </Row>)}
      {tenantErpProgram.data?.erpProgramId === 1 && (<MicroErpFirmReport />)}
      {tenantErpProgram.data?.erpProgramId === 3 && (<NetsisErpFirmReport />)}

    </>
  );
};

export default ErpReportPage;
