import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Select,
  Collapse,
  DatePicker,
  InputNumber
} from "antd";
import moment from "moment";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { odata } from "./Helper";

const PosTransactionTableFilter = (props) => {
  const [form] = Form.useForm();
  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: false,
  });
  const [operationTypeSelect, setOperationTypeSelect] = useState({
    data: [],
    loading: false,
  });

  useEffect(() => {
    getBanks();
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

   const getBanks = () => {
     setBankSelect({
       ...bankSelect,
       loading: true,
     });

     RequestHandler.get(`/Bank/GetFilterBank`).then(
       (response) => {
         setBankSelect({
           data: response,
           loading: false,
         });
       }
     );
   };

  const onFinish = (values) => {
    {
      var model = {};
      let query = "";
      const conditions = [];

      if (values.bankId) {
        model.bankId = values.bankId;
      }
      
      if (values.accountNumber) {
        conditions.push(`Contains(accountNumber, '${values.accountNumber}')`);
      }

      if (values.iban) {
        conditions.push(`Contains(iban, '${values.iban}')`);
      }
      
      if(props.amountModel){
        
        model.amountMin = values.amountMin === undefined || values.amountMin === null ? null : values.amountMin;
        model.amountMax = values.amountMax === undefined || values.amountMax === null ? null : values.amountMax;
        
      }
      else{
        if (values.amountMin) {
          conditions.push(`amount Ge ${values.amountMin}`);
        }
  
        if (values.amountMax) {
          conditions.push(`amount Le ${values.amountMax}`);
        }
      }
      if (values.paymentDateRange) {
        const [startDate, endDate] = values.paymentDateRange;
        model.endDate = endDate;
        model.startDate = startDate;
      }
      
      if (conditions.length !== 0) {
        query = conditions.join(" and ");
      }
      
      if (props.onFinishFilter) props.onFinishFilter(query,model);
    }
  };

  const clearFilter = () => {
    form.resetFields();

    if (props.onClearFilter) {
      props.onClearFilter();
    }
  };

  return (
    <Row id="filterArea" style={{ ...props.style }}>
      <Col span={24}>
        <Collapse>
          <Collapse.Panel header="Filtreleme için tıklayınız.">
            <Form
              name="tenantFilter"
              form={form}
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row style={{ flexDirection: "row" }}>
              {!props.isAccountFilter ?
               <Col xs={24} md={12} lg={6}>
               <Form.Item name="bankId" label="Banka :">
                 <Select
                   allowClear
                   showSearch
                   placeholder="Tümü"
                   loading={bankSelect.loading}
                   filterOption={(input, option) =>
                     option.props.children
                       .toLocaleLowerCase("tr-TR")
                       .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                   }
                 >
                   {bankSelect.data.map((item) => (
                     <Select.Option key={item.text} value={item.text}>
                       {item.value}
                     </Select.Option>
                   ))}
                 </Select>
               </Form.Item>
             </Col> 
                  : null
                }
               
                {props.isAccountFilter ?
                 <Col xs={24} md={12} lg={6}>
                 <Form.Item name="accountNumber" label="Hesap Numarası :">
                     <Input placeholder="Hesap Numarası" />
                   </Form.Item>
                 </Col>
                  : null
                }
                 {props.isAccountFilter ?
                 <Col xs={24} md={12} lg={6}>
                 <Form.Item name="iban" label="Iban :">
                     <Input placeholder="Iban"  />
                   </Form.Item>
                 </Col>
                  : null
                }
              
                <Col xs={24} md={12} lg={6}>
                  <Form.Item style={{ marginBottom: 0 }} label="Tutar :">
                    <Input.Group>
                      <Row gutter={1} style={{ flexDirection: "row" }}>
                        <Col span={12}>
                          <Form.Item name="amountMin">
                            <InputNumber placeholder="Min" style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="amountMax">
                            <InputNumber placeholder="Max" style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>
                {!props.isAccountFilter ?
                 <Col xs={24} md={12} lg={6}>
                 <Form.Item name="paymentDateRange" label="Bakiye Tarihi :">
                   <DatePicker.RangePicker   
                    picker={props.monthly ? "month" : "date"}
                    format={props.monthly ? "MMMM YYYY" : "DD.MM.YYYY"} 
                    disabledDate={(current) => {
                      return moment().add(0, 'days')  <= current 
                      }}
                    />
                 </Form.Item>
               </Col>
                  : null
                }
               

              </Row>

              <Row style={{ flexDirection: "row" }}>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    danger
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={() => clearFilter()}
                  >
                    Sıfırla
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    icon={<SearchOutlined />}
                    style={{ color: "#40a9ff", borderColor: "#40a9ff" }}
                    htmlType="submit"
                  >
                    Filtrele
                  </Button>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};
export default PosTransactionTableFilter;
