import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Select,
  Collapse,
  DatePicker,
  InputNumber
} from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { odata } from "./Helper";
import moment from "moment";

const PosTransactionTableFilter = (props) => {
  const [form] = Form.useForm();

  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: false,
  });
  const [operationTypeSelect, setOperationTypeSelect] = useState({
    data: [],
    loading: false,
  });

  useEffect(() => {
    getBanks();
    getOperationTypes();
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.filter) {
      const [bankId, installmentCount, operationDate] = props.filter.split("&");
      form.setFieldsValue({
        posBankId: parseInt(bankId),
        installmentCount: parseInt(installmentCount),
        operationDateRange: [moment(operationDate).startOf("month"), moment(operationDate).endOf("month")]
      });
    }
  }, [props.filter]);

  const getBanks = () => {
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    RequestHandler.get(`/PosTransaction/AddedBanks?$select=id,name`).then(
      (response) => {
        setBankSelect({
          data: response,
          loading: false,
        });
      }
    );
  };

  const getOperationTypes = () => {
    setOperationTypeSelect({
      ...operationTypeSelect,
      loading: true,
    });
    RequestHandler.get("/PosTransaction/OperationTypes").then((response) => {
      setOperationTypeSelect({
        data: response,
        loading: false,
      });
    });
  };

  const onFinish = (values) => {
    {
      let query = "";
      const conditions = [];

      if (values.posBankId) {
        conditions.push(`posBankId eq ${values.posBankId}`);
      }

      if (values.operationTypeId === 0 || values.operationTypeId) {
        conditions.push(`operationTypeId eq ${values.operationTypeId}`);
      }

      if (values.installmentCount) {
        conditions.push(`installmentCount eq ${values.installmentCount}`);
      }

      if (values.memberWorkplaceNumber) {
        conditions.push(
          odata.contains("memberWorkplaceNumber", values.memberWorkplaceNumber)
        );
      }

      if (values.memberWorkplaceName) {
        conditions.push(
          odata.contains("memberWorkplaceName", values.memberWorkplaceName)
        );
      }

      if (values.terminalNumber) {
        conditions.push(
          odata.contains("terminalNumber", values.terminalNumber)
        );
      }

      if (values.provisionNumber) {
        conditions.push(
          odata.contains("provisionNumber", values.provisionNumber)
        );
      }

      if (values.operationDateRange) {
        const [startDate, endDate] = values.operationDateRange;

        conditions.push(odata.dateRange("operationDate", startDate, endDate));
      }

      if (values.valueDateRange) {
        const [startDate, endDate] = values.valueDateRange;

        conditions.push(odata.dateRange("valueDate", startDate, endDate));
      }

      if (values.endOfDayDateRange) {
        const [startDate, endDate] = values.endOfDayDateRange;

        conditions.push(odata.dateRange("endOfDayDate", startDate, endDate));
      }

      if (conditions.length !== 0) {
        query = conditions.join(" and ");
      }

      if (props.onFinishFilter) props.onFinishFilter(query);
    }
  };

  const clearFilter = () => {
    form.resetFields();

    if (props.onClearFilter) {
      props.onClearFilter();
    }
  };

  return (
    <Row id="filterArea" style={{ ...props.style }}>
      <Col span={24}>
        <Collapse>
          <Collapse.Panel header="Filtreleme için tıklayınız.">
            <Form
              name="tenantFilter"
              form={form}
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row style={{ flexDirection: "row" }}>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="operationDateRange" label="İşlem Tarihi :">
                    <DatePicker.RangePicker
                      format="DD.MM.YYYY"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item name="valueDateRange" label="Valör Tarihi :">
                    <DatePicker.RangePicker
                      format="DD.MM.YYYY"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item name="endOfDayDateRange" label="Gün Sonu Tarihi :">
                    <DatePicker.RangePicker
                      format="DD.MM.YYYY"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="posBankId" label="POS Banka :">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Tümü"
                      loading={bankSelect.loading}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {bankSelect.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item name="operationTypeId" label="İşlem Tipi :">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Tümü"
                      loading={operationTypeSelect.loading}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {operationTypeSelect.data.map((item) => (
                        <Select.Option key={item.key} value={item.key}>
                          {item.value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item name="installmentCount" label="Taksit Sayısı :">
                    <InputNumber
                      allowClear
                      style={{ width: "100%" }}
                      min={1}
                      max={12}
                      precision={0}
                    />
                  </Form.Item>

                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="memberWorkplaceNumber"
                    label="Üye İş Yeri No :"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="memberWorkplaceName"
                    label="Üye İş Yeri Adı :"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="terminalNumber" label="Terminal No :">
                    <Input />
                  </Form.Item>

                  <Form.Item name="provisionNumber" label="Provizyon No :">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ flexDirection: "row" }}>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    danger
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={() => clearFilter()}
                  >
                    Sıfırla
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    icon={<SearchOutlined />}
                    style={{ color: "#40a9ff", borderColor: "#40a9ff" }}
                    htmlType="submit"
                  >
                    Filtrele
                  </Button>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};
export default PosTransactionTableFilter;
