import React, { useState, useEffect } from "react";
import { Row, Col, Table, Empty, message, Tooltip as AntdTooltip } from "antd";
import RequestHandler from "util/RequestHandler";
import CurrencyFormat from "react-currency-format";
import { BarsOutlined, MenuUnfoldOutlined, CaretRightFilled, FileExcelOutlined } from "@ant-design/icons";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import config from "util/ApiforContext";

const BankLimitReport = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    chartData: [],
    loading: true,
  });

  useEffect(() => getData(), []);

  const getData = () => {
    RequestHandler.get("/DbsReport/BankSummaryReport").then((response) => {
      setTableData({
        data: response.data,
        chartData: getChartData(response.data),
        loading: false,
      });

    });
  };

  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);

    const url = props.excelUrl ? props.excelUrl : "/DbsReport/ExportBankSummaryReportExcel";
    const downloadUrl = url.replace("ExportBankSummaryReportExcel", "DownloadBankSummaryReportExcel");
    RequestHandler.post(url).then((response) => {
      hideMessage();

      if (response.success) {
        window.open(
          `${config.apiUrl
          }${downloadUrl}?fileName=${response.data}&t=${RequestHandler.getToken()}`
        );
      }
    });
  };

  const getChartData = (data) => {
    const totalLimit = data.reduce((partialSum, x) => partialSum + x.totalLimit, 0);
    const pieData = data.filter(x => (x.totalLimit / totalLimit * 100) >= 5);
    const otherPie = {
      bankName: "Diğer",
      totalLimit: data.filter(x => (x.totalLimit / totalLimit * 100) < 5)
        .reduce((partialSum, x) => partialSum + x.totalLimit, 0),
    }

    if (otherPie.totalLimit > 0) {
      pieData.push(otherPie);
    }

    return pieData;
  };

  const renderCurrencyFormat = (data) => (
    <CurrencyFormat
      value={data}
      displayType={"text"}
      decimalSeparator={","}
      thousandSeparator={"."}
      prefix={"₺"}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  )

  const CustomToolTip = (toolTipProps) => {
    try {
      const { active, payload, label } = toolTipProps;
      if (!active || !payload) {
        return null;
      }
      return (
        <div>
          <p>
            <strong>{label}</strong>
          </p>
          {payload.map((item, i) => (
            <p key={i}>
              <strong style={{ backgroundColor: "white", opacity: "0.6" }}>
                {item.name}
              </strong>
            </p>
          ))}
        </div>
      );
    } catch (error) { }
  };

  const COLORS = [
    "#003668",
    "#0061ba",
    "#1f94ff",
    "#8dbbe5",
    "#8de5cd",
    "#00b684",
    "#006448",
  ];

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      render: (text, row, index) => {
        return (
          <div style={{ width: 100 }}>
            <img src={text}></img>
          </div>
        );
      },
    },
    {
      title: "Limit",
      dataIndex: "totalLimit",
      render: renderCurrencyFormat,
    },
    {
      title: "Kullanılabilir Limit",
      dataIndex: "totalAvailability",
      render: renderCurrencyFormat,
    },
    {
      title: "Bayi Sayısı",
      dataIndex: "dealerCount",
    },
    {
      title: "Detay",
      dataIndex: "bankId",
      align: "center",
      render: (data, record, text) => (<a
        style={{ fontSize: "130%" }}
        href={`/dbs-dealer/${data}`}
      >
        <MenuUnfoldOutlined />
      </a>),
    }
  ];

  return (
    <>

      <Row>
        <Col xs={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> BANKA BAZLI LİMİT RAPORU
          </p>
        </Col>
        <Col xs={8}>
          <Row justify="end" align="middle">
            <AntdTooltip title="Excel İndir">
              <a
                style={{
                  color: "green",
                  fontSize: "180%",
                  marginRight: 30,
                }}
                onClick={onClickExportExcel}
              >
                <FileExcelOutlined />
              </a>
            </AntdTooltip>
          </Row>
        </Col>
      </Row>


      <Row>
        <Col md={24} lg={12}>
          <Row id="tableArea">
            <Col span={24}>
              <Table
                columns={columns}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Veri Yok"
                    />
                  ),
                }}
                style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
                rowKey="bankId"
                filterable={false}
                sortable={false}
                pageable={false}
                pagination={false}
                dataSource={tableData.data}
                loading={tableData.loading}
                size="small"
              />
            </Col>
          </Row>
        </Col>
        <Col md={24} lg={12}>
          <Row justify="center">

            <PieChart width={500} height={500}>
              <Pie
                outerRadius={150}
                data={tableData.chartData}
                dataKey="totalLimit"
                nameKey="bankName"
                label={({ percent }) => "% " + (percent * 100).toFixed(2)}
              >
                {tableData.chartData.map((entry, index) =>
                (
                  <Cell
                    key={index}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={CustomToolTip} />
            </PieChart>

            <Col span={24}>
              {tableData.chartData.map((entry, index) => (
                <div key={`cell-${index}`}>
                  <a style={{ color: COLORS[index % COLORS.length] }}>
                    <CaretRightFilled />
                  </a>
                  {entry.bankName}
                </div>
              ))}
            </Col>
          </Row>

        </Col>
      </Row>

    </>
  );
};

export default BankLimitReport;
