import React,{useEffect,useState} from 'react';
import { Form, Input,notification, Button,Row,Col } from 'antd';
import { useMediaQuery } from 'react-responsive'
import config from "../../util/ApiforContext"
import {useHistory} from "react-router-dom";
import Replace2x from '../../util/Extensions/ReplaceExtension'
import { Transfer } from 'antd';
import axios from 'axios';
const userId = localStorage.getItem("userId");
const expiration = localStorage.getItem("expiration");

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const GroupAdd = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const history = useHistory();
  const [userList, setUserList] = useState([]);
  const [mockDataTenant, setmockDataTenant] = useState([]);
  const [mockDataUser, setmockDataUser] = useState([]);
    const [tenants, SetTenants] = useState([]);
    const [targetKeysTenant, setTargetKeysTenant] = useState([]);  
    const errorList = [];
  const [loading, setLoading] = useState(false);
  const [targetKeysUser, setTargetKeysUser] = useState([]);  
    const errorListNotification = type => {
      errorList.map((item)=> {
        notification[type]({
          message: 'Hata',
          description:
          item
           ,
            duration: 2,
        });
      })
    };
    const openNotificationWithIcon = type => {
      notification[type]({
        message: 'BAŞARILI',
        description:
          'Ekleme işlemi başarı ile gerçekleşti.',
          duration: 2,
      });
    };
    const openNotificationWithIconWarning = type => {
      notification[type]({
        message: 'HATA',
        description:
          'Ekleme işlemi sırasında bir hata oluştu.',
          duration: 2,
      });
    };
    useEffect(() => {
      if(expiration<Date.now()){
        localStorage.removeItem("expiration");
        localStorage.removeItem("token");
        window.location.reload();
      }
      if(localStorage.getItem("token")==null){
        window.location.reload()
      }
      axios.get(`${config.apiUrl}/User/GetUserTenants/${Replace2x(userId)}`,config.headers)
      .then(res => {
        getMockTenant(res.data);
        const tenantList=[];
        res.data.map((item)=>{
          tenantList.push(item.id)
        })
        axios.post(`${config.apiUrl}/User/GetUserListForTenants`,{
          tenants:tenantList
        },config.headers)
        .then(res => {
          getMockUser(res.data)
          }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }else if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
              setLoading(false)
            }
          }
        );
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }else if(error.response.status === 406){
            error.response.data.data.map((item)=>{
              errorList.push(item);
            })
            errorListNotification('error')
            setLoading(false)
          }
        }
    );
    },[SetTenants]);
    
    const onFinish = (values) => {
      var tenantList = [];
      var userList = [];
      targetKeysTenant.map((item)=>{
        tenantList.push(item);
      })
      targetKeysUser.map((item)=>{
        userList.push(item);
      })
      axios.post(`${config.apiUrl}/Group`,{
        Name:values.name,
        TenantIds:tenantList,
        UserIds:userList
      },config.headers)
      .then(res => {
        openNotificationWithIcon('success');
                    setTimeout(function () {
                       window.location.reload();
                     }.bind(this), 2000)
                     
        }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }else if(error.response.status === 406){
            error.response.data.data.map((item)=>{
              errorList.push(item);
            })
            errorListNotification('error')
            setLoading(false)
          }else{
            openNotificationWithIconWarning('error')
            setLoading(false)
        }
        }
      );
    };

    const onFinishFailed = (errorInfo) => {
    };

  const getMockTenant = (value) => {
    const targetKeysTenant = [];
    const mockDataTenant = [];
    value.map((item,index)=>{
      const data = {
        key: item.id,
        title: item.name,
        description: ``,
      };
      if (data.chosen) {
        targetKeysTenant.push(data.key);
      }
      mockDataTenant.push(data);
    })
    setmockDataTenant(mockDataTenant)
    setTargetKeysTenant(targetKeysTenant)
  };

  const getMockUser = (value) => {
    const targetKeysUser = [];
    const mockDataUser = [];
    value.map((item,index)=>{
      const data = {
        key: item.id,
        title: item.firstName,
        description: ``,
      };
      if (data.chosen) {
        targetKeysUser.push(data.key);
      }
      mockDataUser.push(data);
    })
    setmockDataUser(mockDataUser)
    setTargetKeysUser(targetKeysUser)
  };

  const filterOptionUser = (inputValue, option) => option.description.indexOf(inputValue) > -1;

  const handleChangeUser = targetKeysUser => {
      setTargetKeysUser(targetKeysUser)
  };

  const handleSearchUser = (dir, value) => {
  };

  const filterOptionTenant = (inputValue, option) => option.description.indexOf(inputValue) > -1;

  const handleChangeTenant = targetKeysTenant => {
      setTargetKeysTenant(targetKeysTenant)
  };

  const handleSearchTenant = (dir, value) => {
  };

    return (
      <div>
        {isTabletOrMobileDevice && <>
          <Form
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Grup Adı"
        name="name"
        rules={[
          {
            required: true,
            message: 'İsim alanını boş bırakmayın',
          },
        ]}
      >
        <Input />
      </Form.Item>
    
      <Form.Item
        label="Şirketler"
        name="users"
      >
         <Transfer
            dataSource={mockDataTenant}
            style={{marginLeft:'-24%'}}

            showSearch
            locale={
              { searchPlaceholder: "Arama Yapınız",
              notFoundContent: 'Liste Boş',
              itemUnit:'Şirket',
              itemsUnit:'Şirket',
              selectAll:'Hepsini Seç',
              selectInvert:'Seçimleri Kaldır' }
            }
            listStyle={{
              width: 300,
              height: 250,
            }}
            titles={['(-)','(+)']}
            filterOption={filterOptionTenant}
            targetKeys={targetKeysTenant}
            onChange={handleChangeTenant}
            onSearch={handleSearchTenant}
            render={item => item.title}
          />
      </Form.Item>

      <Form.Item
        label="Kullanıcılar"
        name="tenants"
      >
       
       <Transfer
      style={{marginLeft:'-24%'}}
      dataSource={mockDataUser}
        showSearch
        locale={
          { searchPlaceholder: "Arama Yapınız",
          notFoundContent: 'Liste Boş',
          itemUnit:'Kullanıcı',
          itemsUnit:'Kullanıcı',
          selectAll:'Hepsini Seç',
          selectInvert:'Seçimleri Kaldır' }
        }
        listStyle={{
          width: 300,
          height: 250,
        }}
        filterOption={filterOptionUser}
        targetKeys={targetKeysUser}
        onChange={handleChangeUser}
        onSearch={handleSearchUser}
        render={item => item.title}
      /> 
      </Form.Item>

<Col span={20}>
      <Form.Item {...tailLayout}>
        <Button type="primary" style={{marginLeft:'100%'}} htmlType="submit"  onClick={() => setLoading(true)} loading={loading}>
          Ekle
        </Button>
      </Form.Item>
      </Col>
    </Form>
         </>}
         {isDesktopOrLaptop && <>
         <Row>
         <Form
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Grup Adı"
        name="name"
        rules={[
          {
            required: true,
            message: 'İsim alanını boş bırakmayın',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Şirketler"
        name="users"
      >
         <Transfer
        dataSource={mockDataTenant}
        showSearch
        locale={
          { searchPlaceholder: "Arama Yapınız",
          notFoundContent: 'Liste Boş',
          itemUnit:'Şirket',
          itemsUnit:'Şirket',
          selectAll:'Hepsini Seç',
          selectInvert:'Seçimleri Kaldır' }
        }
        listStyle={{
          width: 300,
          height: 250,
        }}
        titles={['(-)','(+)']}
        filterOption={filterOptionTenant}
        targetKeys={targetKeysTenant}
        onChange={handleChangeTenant}
        onSearch={handleSearchTenant}
        render={item => item.title}
      />
      </Form.Item>
      <Form.Item
        label="Kullanıcılar"
        name="tenants"
      >
       <Transfer
        dataSource={mockDataUser}
        showSearch
        locale={
          { searchPlaceholder: "Arama Yapınız",
          notFoundContent: 'Liste Boş',
          itemUnit:'Kullanıcı',
          itemsUnit:'Kullanıcı',
          selectAll:'Hepsini Seç',
          selectInvert:'Seçimleri Kaldır' }
        }
        listStyle={{
          width: 300,
          height: 250,
        }}
        filterOption={filterOptionUser}
        targetKeys={targetKeysUser}
        onChange={handleChangeUser}
        onSearch={handleSearchUser}
        render={item => item.title}
      /> 
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" style={{marginLeft:'100%'}} htmlType="submit" onClick={() => setLoading(true)} loading={loading}>
          Ekle
        </Button>
      </Form.Item>
    </Form>
         </Row>
         </>}
      </div>
      
    );
}

export default GroupAdd