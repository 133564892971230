import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Table,
  Empty,
  Input,
  Button,
  Col,
  notification,
  Form,
  Row,
  Collapse,
} from "antd";
import { useMediaQuery } from "react-responsive";
import {
  SearchOutlined,
  RollbackOutlined,
  MenuUnfoldOutlined,
  BarsOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import config from "../../util/ApiforContext";
import axios from "axios";
import moment from "moment";
import { ColumnHelper, RowHelper } from "util/TableHelper";
import { odata } from "../Filters/Helper";


const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};
const { Panel } = Collapse;
const expiration = localStorage.getItem("expiration");
const AccountPage = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  var tenantId = props.match.params.tenantid.split("-")[0];
  var currency = props.match.params.tenantid.split("-")[1];
  const history = useHistory();
  const [accounts, setAccounts] = useState([{ data: [], total: 0 }]);
  const [teminatDropdown, setTeminatDropdown] = useState([]);
  const [dataState, setDataState] = useState([{ skip: 0, take: 10 }]);
  const [take, setTake] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [query, setQuery] = useState(
    `$filter=BankId eq ${tenantId} and Currency eq '${currency}' `
  );
  const [bankFilter, setBankFilter] = useState([]);
  const [getFilterCurrency, setGetFilterCurrency] = useState([]);
  const [Iban, setIban] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [Currency, setCurrency] = useState("");
  const [TransactionProcessType, setTransactionProcessType] = useState("");

  const [BankName, setBankName] = useState("");
  const errorList = [];
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 2,
      });
    });
  };
  const [Description, setDescription] = useState("");
  const [Amount1, setAmount1] = useState("");
  const [Amount2, setAmount2] = useState("");
  const [order, setOrder] = useState("");

  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }
    axios
      .get(`${config.apiUrl}/Types/GetManualTransactionTypes`, config.headers)
      .then((res) => {
        setTeminatDropdown(res.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    axios
      .get(
        `${config.apiUrl}/tenantaccount? ${query}${order} &$count=true&$skip=${skip}&$top=${take}`,
        config.headers
      )
      .then((res) => {
        setAccounts({
          data: res.data.value,
          total: res.data["@odata.count"],
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    axios
      .get(`${config.apiUrl}/Bank/GetFilterBank`, config.headers)
      .then((res) => {
        setBankFilter(res.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    axios
      .get(`${config.apiUrl}/currency/getfiltercurrency`, config.headers)
      .then((res) => {
        setGetFilterCurrency(res.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  }, [order,query, setDataState, skip, setSkip]);

  const handleResets = (clearFilters) => {
    setSkip(0);
    setTake(20);
    setQuery(`$filter=BankId eq ${tenantId} and Currency eq '${currency}' `);
  };
  const handleReset = (clearFilters) => {
    setSkip(0);
    setTake(20);
    setQuery(`$filter=BankId eq ${tenantId} and Currency eq '${currency}' `);
    setIban("");
    setAccountNumber("");
    setTransactionProcessType("");
    setCurrency("");
    setBankName("");
    setDescription("");
    setAmount1("");
    setAmount2("");
  };
  const handleIban = (value) => {
    setIban(value.target.value);
  };
  const handleAccountNumber = (value) => {
    setAccountNumber(value.target.value);
  };
  const handleCurrency = (value) => {
    setCurrency(value);
  };
  const handleBankName = (value) => {
    setBankName(value);
  };
  const handleDescription = (value) => {
    setDescription(value.target.value);
  };
  const handleAmount1 = (value) => {
    setAmount1(value.target.value);
  };
  const handleAmount2 = (value) => {
    setAmount2(value.target.value);
  };
  const handleTransactionProcessType = (value) => {
    setTransactionProcessType(value);
  };
  const paging = (p,filters,sorter) => {
    let order = "";
    if (sorter.columnKey && sorter.order) {
      order =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }
    setOrder(order);
    setDataState((prevState) => {
      prevState.skip = (p.current - 1) * 20;
      setSkip(prevState.skip);
      setTake(p.pageSize);
      setCurrentPage(p.current);
      return {
        prevState,
      };
    });
  };

  const onFinish = (values) => {
    {
      setSkip(0);
      setTake(20);
      var queryAccounts = `$filter=BankId eq ${tenantId} and Currency eq '${currency}' `;
      if (Iban !== undefined && Iban !== "") {
        queryAccounts += ` and Contains(Iban,'${Iban}')`;
      }
      if (AccountNumber !== undefined && AccountNumber !== "") {
        queryAccounts += ` and Contains(AccountNumber,'${AccountNumber}')`;
      }
      if (BankName !== undefined && BankName !== "") {
        queryAccounts += ` and Contains(BankName,'${BankName}')`;
      }
      if (Description !== undefined && Description !== "") {
        queryAccounts += ` and Contains(Description,'${Description}')`;
      }
      if (
        queryAccounts !== "" &&
        TransactionProcessType !== undefined &&
        TransactionProcessType !== "" &&
        TransactionProcessType === "2"
      ) {
        queryAccounts += ` and Balance lt ${0} `;
      }
      if (
        queryAccounts !== "" &&
        TransactionProcessType !== undefined &&
        TransactionProcessType !== "" &&
        TransactionProcessType === "1"
      ) {
        queryAccounts += ` and Balance gt ${0} `;
      }
      if (
        queryAccounts !== "" &&
        Amount1 !== undefined &&
        Amount1 !== "" &&
        (Amount2 === undefined || Amount2 === "")
      ) {
        queryAccounts += ` and Balance lt ${Amount1}`;
      }
      if (
        queryAccounts !== "" &&
        (Amount1 === undefined || Amount1 === "") &&
        Amount2 !== undefined &&
        Amount2 !== ""
      ) {
        queryAccounts += ` and Balance gt ${Amount2} `;
      }
      if (
        Amount1 !== undefined &&
        Amount1 !== "" &&
        Amount2 !== undefined &&
        Amount2 !== ""
      ) {
        queryAccounts += ` and Balance lt ${Amount1} and Balance gt ${Amount2}`;
      }
      setQuery(queryAccounts);
      setCurrentPage(1);
      queryAccounts = `$filter=BankId eq ${tenantId} and Currency eq '${currency}' `;
    }
  };

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankName",
      key: "bankName",
      render: (text, row, index) => {
        return (
          <div style={{ width: 100 }}>
            {" "}
            <img src={row.bankLogo}></img>
          </div>
        );
      },
    },
    {
      title: "Döviz",
      dataIndex: "currency",
      align: "center",
      key: "currency",
    },
    {
      title: "Şube",
      dataIndex: "branchCode",
      align: "center",
      key: "branchCode",

    },
    {
      title: "Iban / Hesap Numarası",
      dataIndex: "iban",
      align: "center",
      key: "iban",
      render: (text, row, index) => {
        {
          return (
            <div>
              {row.iban == null
                ? row.accountNumber == null
                  ? ""
                  : row.accountNumber
                : row.iban}
            </div>
          );
        }
      },
    },

    {
      title: "Son Sorgulanma Tarihi",
      dataIndex: "updateDate",
      key: "updateDate",
      sorter: true,
      align: "center",
      render: (text, row, index) => {
        {
          return (
            <div>
              {moment(row.updateDate).format("L")}{" "}
              {moment(row.updateDate).format("LT")}
            </div>
          );
        }
      },
    },
    {
      title: "Tutar",
      dataIndex: "balanceString",
      key: "balance",
      sorter: true,
      render: (text, row, index) => {
        {
          return <div style={{ width: "150%" }}>{text}</div>;
        }
      },
    },
    {
      title: "Hesaplanma",
      dataIndex: "",
      width: "2%",
      align: "center",
      render: (text, row, index) => {
        if (row.isCalculated == true) {
          return (
            <div style={{ color: "green" }}>
              <CheckOutlined />
            </div>
          );
        } else {
          return (
            <div style={{ color: "red" }}>
              <CloseOutlined />
            </div>
          );
        }
      },
    },
    {
      title: "Detay",
      dataIndex: "",
      width: "1%",
      align: "center",
      render: (record, text) => {
        return (
          <Link
            style={{ fontSize: "150%" }}
            to={`/bank-accounts-account-transaction/${record.id}`}
          >
            <MenuUnfoldOutlined />
          </Link>
        );
      },
    },
  ];

  const detailColumns = [
    {
      title: "Kullanılabilir Bakiye",
      dataIndex: "availableBalance",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Kredi Limiti",
      dataIndex: "creditLimit",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Kredi Bakiyesi",
      dataIndex: "creditBalance",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Bloke Tutarı",
      dataIndex: "blockedAmount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Vade Bitiş Tarihi",
      dataIndex: "dueDate",
      render: (data, row, index) =>
        data ? moment(data).format("DD.MM.YYYY") : "-",
    },
    {
      title: "Vade Faiz Oranı",
      dataIndex: "interestRate",
    },
    {
      title: "Hesap Açılış Tarihi",
      dataIndex: "openDate",
      render: (data, row, index) =>
        data ? moment(data).format("DD.MM.YYYY") : "-",
    },
  ];

  return (
    <div>
      <Row style={{ marginBottom: 9 }}>
        <Col flex={1}>
          <a
            onClick={() => history.goBack()}
            style={{ color: "black", marginLeft: "4%" }}
          >
            {" "}
            <RollbackOutlined /> GERİ DÖN
          </a>
        </Col>
        <Col flex={4}></Col>
      </Row>
      <Row>
        <p style={{ fontSize: "120%", marginLeft: 14 }}>
          <BarsOutlined /> BANKA HESAPLARI
        </p>
      </Row>
      <Collapse defaultActiveKey={["0"]}>
        <Panel header="Filtreleme için tıklayınız." key="1">
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Row>
              {isDesktopOrLaptop && (
                <>
                  <Col span={6}>
                    {" "}
                    <label>Iban :</label>
                  </Col>
                  <Col span={6}>
                    {" "}
                    <label>Tutar Aralığı :</label>
                  </Col>
                  <Col span={6}>Hesap Numarası:</Col>
                  <Col span={6}></Col>

                  <Col span={6}>
                    <Input
                      name="Iban"
                      value={Iban}
                      onChange={handleIban}
                      style={{ width: "100%" }}
                      placeholder="Iban"
                    />
                  </Col>
                  <Col span={6}>
                    <Input.Group compact>
                      <Input
                        onChange={handleAmount2}
                        style={{ width: "50%" }}
                        value={Amount2}
                        name="Amount2"
                        placeholder="Başlangıç"
                      />
                      <Input
                        onChange={handleAmount1}
                        style={{ width: "50%" }}
                        value={Amount1}
                        name="Amount1"
                        placeholder="Bitiş"
                      />
                    </Input.Group>
                  </Col>
                  <Col span={6}>
                    <Input
                      name="AccountNumber"
                      value={AccountNumber}
                      onChange={handleAccountNumber}
                      style={{ width: "100%", marginBottom: "6%" }}
                      placeholder="Hesap Numarası"
                    />
                  </Col>
                  <Col span={6}> </Col>

                  <Col span={12} style={{ opacity: 0.1 }}>
                    .
                  </Col>
                  <Col span={6}>
                    <Button
                      danger
                      size="small"
                      icon={<CloseOutlined />}
                      onClick={() => handleReset()}
                      style={{ width: "100%" }}
                    >
                      Sıfırla
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      primary
                      size="small"
                      icon={<SearchOutlined />}
                      style={{
                        color: "#4d98aa",
                        borderColor: "#4d98aa",
                        width: "100%",
                      }}
                      htmlType="submit"
                    >
                      Filtrele
                    </Button>
                  </Col>
                </>
              )}
              {isTabletOrMobileDevice && (
                <>
                  <Col span={12}>
                    {" "}
                    <label>Iban :</label>
                  </Col>
                  <Col span={12}>
                    {" "}
                    <label>Tutar Aralığı :</label>
                  </Col>
                  <Col span={12}>
                    <Input
                      name="Iban"
                      value={Iban}
                      onChange={handleIban}
                      style={{ width: "100%" }}
                      placeholder="Iban"
                    />
                  </Col>
                  <Col span={12}>
                    <Input.Group compact>
                      <Input
                        onChange={handleAmount2}
                        style={{ width: "25%" }}
                        value={Amount2}
                        name="Amount2"
                        placeholder="Başlangıç"
                      />
                      <Input
                        onChange={handleAmount1}
                        style={{ width: "25%", marginBottom: "5%" }}
                        value={Amount1}
                        name="Amount1"
                        placeholder="Bitiş"
                      />
                    </Input.Group>
                  </Col>

                  <Col span={12}>Hesap Numarası:</Col>
                  <Col span={12}></Col>

                  <Col span={12}>
                    <Input
                      name="AccountNumber"
                      value={AccountNumber}
                      onChange={handleAccountNumber}
                      style={{ width: "100%", marginBottom: "6%" }}
                      placeholder="Hesap Numarası"
                    />
                  </Col>
                  <Col span={12}></Col>

                  <Col span={12}>
                    <Button
                      danger
                      size="small"
                      style={{ width: "100%" }}
                      icon={<CloseOutlined />}
                      onClick={() => handleReset()}
                    >
                      Sıfırla
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      primary
                      size="small"
                      icon={<SearchOutlined />}
                      style={{
                        color: "#4d98aa",
                        borderColor: "#4d98aa",
                        width: "100%",
                      }}
                      htmlType="submit"
                    >
                      Filtrele
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </Panel>
      </Collapse>
      <br></br>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (row) =>
            RowHelper.renderRowDetail({
              data: row,
              columns: detailColumns,
            }),
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Veri Yok"
            />
          ),
        }}
        size="small"
        style={{ overflowX: "auto", maxHeight: "100%" }}
        rowKey="id"
        filterable={true}
        sortable={true}
        pageable={true}
        onChange={paging}
        pagination={{
          pageSizeOptions: ["15", "25", "50", "100"],
          defaultPageSize: take,
          total: accounts.total,
          current: currentPage,
        }}
        dataSource={accounts.data}
      />
    </div>
  );
};

export default AccountPage;
