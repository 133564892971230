import React, { useState,useEffect } from 'react'
import HomeCards from '../HomePage/Chartjs/HomeWidget'
import HomeCharts from '../HomePage/Chartjs/HomeCharts'
import HomeBarCharts from '../HomePage/Chartjs/HomeBarCharts'
import TahsilatPlan from '../HomePage/Lists/TahsilatPlanLists'
import {useHistory} from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import axios from 'axios'
import config from "../../util/ApiforContext"
import { Tabs,Row,Col,Spin } from 'antd';
import { ExclamationCircleOutlined,HomeOutlined,AccountBookOutlined } from '@ant-design/icons';
const expiration = localStorage.getItem("expiration");
const Index = () => 
{
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1499px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const history = useHistory();
  const [currencyTRY, setCurrencyTRY] = useState(0);
  const [visible, setVisible] = useState(0);
  const [currencyTRList, setCurrencyTRList] = useState([]);
  const [CollectAndPaymentChartTRY, setCollectAndPaymentChartTRY] = useState([]);
  const [MonthlyAccountTRY, setMonthlyAccountTRY] = useState([]);
  const [CollectListTRY, setCollectListTRY] = useState([]);
  const [PaymentListTRY, setPaymentListTRY] = useState([]);
  const [TotalPaymentTRY, setTotalPaymentTRY] = useState([]);
  const [TotalCollectTRY, setTotalCollectTRY] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const [loading, setLoading] = useState(true);
  const [availableBalanceTRY, setAvailableBalanceTRY] = useState("");
  const showModal = () => {
    setVisible(true)
  };
  const hideModal = () => {
    setVisible(false)
  };
  useEffect(() => {
    if(expiration<Date.now()){
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if(localStorage.getItem("token")==null){
      window.location.reload()
    }
    axios.get(`${config.apiUrl}/TenantCurrency?$apply=groupby((Currency,CurrencyId))&$orderby=currencyId`,config.headers)
    .then(res => {
      setCurrencyTRList(res.data)
      setActiveKey(res.data[0]?.currencyId.toString())

    }
      ).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
 
    axios.get(`${config.apiUrl}/Reports/GetMonthlyCollectAndPayment/1`,config.headers)
    .then(res => {
      setCollectAndPaymentChartTRY(res)
    }).catch(
      error =>  {
        if(error.response.status===403 || error.response.status ===401){
          history.push('/not-authorized-access');
        }
      }
  );
  
      axios.get(`${config.apiUrl}/Reports/GetTotalAmountByCurrencyId/1`,config.headers)
        .then(res => {
          setCurrencyTRY(res)
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }
          }
      );
      axios.get(`${config.apiUrl}/Reports/GetTotalCollectByCurrencyId/1`,config.headers)
        .then(res => {
          setTotalCollectTRY(res.data)
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }
          }
      );
      axios.get(`${config.apiUrl}/Reports/GetTotalPaymentByCurrencyId/1`,config.headers)
        .then(res => {
          setTotalPaymentTRY(res.data)
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }
          }
      );
      axios.get(`${config.apiUrl}/Reports/GetAvailableBalance/1`,config.headers)
      .then(res => {
        setAvailableBalanceTRY(res.data)
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
      axios.get(`${config.apiUrl}/Reports/GetMonthlyAccount/1`,config.headers)
      .then(res => {
        setMonthlyAccountTRY(res)
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
      axios.get(`${config.apiUrl}/Reports/GetMonthlyCollectAndPaymentPlanList?currencyId=1&id=1`,config.headers)
      .then(res => {
        setCollectListTRY(res)
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
      axios.get(`${config.apiUrl}/Reports/GetMonthlyCollectAndPaymentPlanList?currencyId=1&id=2`,config.headers)
      .then(res => {
        setPaymentListTRY(res)
        setLoading(false)
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );     
  },[]);
  
  const ContactSideBar = (value,e) => {
    return(
      isDesktopOrLaptop === true ?
      <Spin spinning={loading}>
      <div style={{marginLeft:"1%"}} className="ant-row">
      <HomeCards icon="home" title={currencyTRY.data} subTitle={value} descText="TOPLAM BAKİYE"/>
      <HomeCards icon="long-arrow-up" title={TotalCollectTRY} subTitle={value} descText="GELECEK TAHSİLAT" color="green"  />
      <HomeCards icon="long-arrow-down" title={TotalPaymentTRY} subTitle={value} descText="YAPILACAK ÖDEME" color="red"/>
      <HomeCards icon="calendar" title={availableBalanceTRY} subTitle={value} descText="TAHMİNİ KALAN BAKİYE" />
      <HomeBarCharts chartValues={CollectAndPaymentChartTRY.data} />
      <HomeCharts chartValues={MonthlyAccountTRY.data} />
      
        <Col span={12}><TahsilatPlan dataValues={CollectListTRY?.data} planType="TAHSİLAT PLANI" currencyLogo={""} /></Col>
        <Col span={12}><TahsilatPlan dataValues={PaymentListTRY?.data} planType="ÖDEME PLANI" currencyLogo={""}/></Col>
      </div>
      </Spin>
      :
      <Spin spinning={loading}>
      <div className="ant-row">
      <HomeCards icon="home" title={currencyTRY.data} subTitle={value} descText="TOPLAM BAKİYE"/>
      <HomeCards icon="long-arrow-up" title={TotalCollectTRY} subTitle={value} descText="GELECEK TAHSİLAT" color="green"  />
      <HomeCards icon="long-arrow-down" title={TotalPaymentTRY} subTitle={value} descText="YAPILACAK ÖDEME" color="red"/>
      <HomeCards icon="calendar" title={availableBalanceTRY} subTitle={value} descText="TAHMİNİ KALAN BAKİYE" />
      <HomeBarCharts chartValues={CollectAndPaymentChartTRY.data} />
      <HomeCharts chartValues={MonthlyAccountTRY.data} />
      <Col span={24}><TahsilatPlan dataValues={CollectListTRY?.data} planType="TAHSİLAT PLANI" currencyLogo={""} /></Col>
        <Col span={24}><TahsilatPlan dataValues={PaymentListTRY?.data} planType="ÖDEME PLANI" currencyLogo={""}/></Col>
      </div>
      </Spin>
        
      
  )
};
  const handleChange = (activeKey) => {
      axios.get(`${config.apiUrl}/Reports/GetTotalAmountByCurrencyId/${activeKey}`,config.headers)
      .then(res => {
        setCurrencyTRY(res)
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
      axios.get(`${config.apiUrl}/Reports/GetMonthlyCollectAndPaymentPlanList?currencyId=${activeKey}&id=2`,config.headers)
     .then(res => {
       setPaymentListTRY(res)
     }).catch(
      error =>  {
        if(error.response.status===403 || error.response.status ===401){
          history.push('/not-authorized-access');
        }
      }
  );
     axios.get(`${config.apiUrl}/Reports/GetAvailableBalance/${activeKey}`,config.headers)
    .then(res => {
      setAvailableBalanceTRY(res.data)
    }).catch(
      error =>  {
        if(error.response.status===403 || error.response.status ===401){
          history.push('/not-authorized-access');
        }
      }
  );
      axios.get(`${config.apiUrl}/Reports/GetMonthlyCollectAndPayment/${activeKey}`,config.headers)
      .then(res => {
        setCollectAndPaymentChartTRY(res)
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
      axios.get(`${config.apiUrl}/Reports/GetTotalCollectByCurrencyId/${activeKey}`,config.headers)
      .then(res => {
        setTotalCollectTRY(res.data)
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
      axios.get(`${config.apiUrl}/Reports/GetMonthlyAccount/${activeKey}`,config.headers)
      .then(res => {
        setMonthlyAccountTRY(res)
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
    axios.get(`${config.apiUrl}/Reports/GetTotalPaymentByCurrencyId/${activeKey}`,config.headers)
      .then(res => {
        setTotalPaymentTRY(res.data)
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
      axios.get(`${config.apiUrl}/Reports/GetMonthlyCollectAndPaymentPlanList?currencyId=${activeKey}&id=1`,config.headers)
      .then(res => {
        setCollectListTRY(res)
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
    setActiveKey(activeKey)
  };
    
    const { TabPane } = Tabs;
    return (
      <Row>
        <Col>
      <div>
      {isTabletOrMobileDevice && <> 
        <Col span={24}>
        <p style={{fontSize:'110%'}}><HomeOutlined /> AY BAZLI GRAFİK</p><hr></hr><br></br>
        </Col>
        <Tabs
        style={{marginLeft:"2%"}}

        onChange={handleChange}
        activeKey={activeKey}
        >
        {
          currencyTRList.length === 0 ? null :   
          currencyTRList.map((item,index)=>{
            return(
          <TabPane
          tab={
                <span >
                  {item.currency}
                </span>
              }
              key={item.currencyId}
        >
        {ContactSideBar(item.currency)}
          </TabPane>)
          })
        }

      </Tabs>
      </>}
      {isDesktopOrLaptop && <>
      <Col span={24}>
      <p style={{fontSize:'120%',marginTop:-5}}><HomeOutlined /> AY BAZLI GRAFİK</p>
      </Col>
      <Tabs
      style={{marginLeft:"2%"}}

      onChange={handleChange}
      activeKey={activeKey}
      >
      {
        currencyTRList.length === 0 ? null :   
        currencyTRList.map((item,index)=>{
          return(
         <TabPane
         tab={
               <span >
                {item.currency}
               </span>
             }
             key={item.currencyId}
       >
       {ContactSideBar(item.currency)}
         </TabPane>)
        })
      }

    </Tabs>
      </>}
   
    </div>
    </Col>
    </Row>
    )
}
export default Index