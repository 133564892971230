import React, { useState } from "react";
import RequestHandler from "util/RequestHandler";
import PosVolumeReportMonthly from "./PosVolumeReportMonthly";
import PosVolumeReportDaily from "./PosVolumeReportDaily";
import config from "../../util/ApiforContext";
import Replace2x from "../../util/Extensions/ReplaceExtension";

import { Tabs, Col, Row, Tooltip, message } from "antd";
import { BarsOutlined, FileExcelOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;

const PosVolumeReport = (props) => {
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [reportFilters, setReportFilters] = useState({
    daily: {},
    monthly: {},
  });

  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);

    if (activeTabKey === "1") {
      RequestHandler.post(
        "/PosTransaction/MonthlyPosVolumeReportExportExcel",
        reportFilters.monthly
      ).then((response) => {
        hideMessage();

        if (response.success) {
          window.open(
            `${
              config.apiUrl
            }/PosTransaction/MonthlyPosVolumeReportDownloadExcel?fileName=${
              response.data
            }&t=${Replace2x(localStorage.getItem("token"))}`
          );
        }
      });
    } else {
      RequestHandler.post(
        "/PosTransaction/DailyPosVolumeReportExportExcel",
        reportFilters.daily
      ).then((response) => {
        hideMessage();

        if (response.success) {
          window.open(
            `${
              config.apiUrl
            }/PosTransaction/DailyPosVolumeReportDownloadExcel?fileName=${
              response.data
            }&t=${Replace2x(localStorage.getItem("token"))}`
          );
        }
      });
    }
  };

  const onMonthlyFilterChange = (filterData) => {
    setReportFilters({ ...filterData, monthly: filterData });
  };

  const onDailyFilterChange = (filterData) => {
    setReportFilters({ ...reportFilters, daily: filterData });
  };

  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> POS CİRO RAPORU
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
          <Tooltip title="Excel İndir">
            <a
              style={{
                color: "green",
                fontSize: "180%",
                marginRight: 30,
              }}
              onClick={onClickExportExcel}
            >
              <FileExcelOutlined />
            </a>
          </Tooltip>
        </Col>
      </Row>

      <Tabs
        defaultActiveKey="1"
        onChange={(activeKey) => {
          setActiveTabKey(activeKey);
        }}
      >
        <TabPane tab="Ay Bazlı" key="1">
          <PosVolumeReportMonthly onFilterChange={onMonthlyFilterChange} />
        </TabPane>
        <TabPane tab="Gün Bazlı" key="2">
          <PosVolumeReportDaily onFilterChange={onDailyFilterChange} />
        </TabPane>
      </Tabs>
    </>
  );
};

export default PosVolumeReport;
