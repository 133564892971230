import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import RequestHandler from "util/RequestHandler";
import { ColumnHelper } from "util/TableHelper";
import TosSentPaymentTableFilter from "../Filters/TosSentPaymentTableFilter";
import TosCreateOrderForm from "../Forms/TosCreateOrderForm";
import config from "../../util/ApiforContext";
import moment from "moment";

import { Table, Empty, Col, Row, Button, Modal,Tooltip, message } from "antd";
import {
  BarsOutlined,
  SendOutlined,
  RollbackOutlined,
  FileExcelOutlined
} from "@ant-design/icons";

const TosSentPayment = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: `&$filter=DirectiveId eq ${props.match.params.directiveId}`,
  });
  const [selectedRows, setSelectedRows] = useState({
    keys: [],
    data: [],
  });
  const [createOrderModalVisible, setCreateOrderModalVisible] = useState(false);
  const history = useHistory();

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  const getTableData = () => {
    let { skip, take, filter } = tableDataQueryOptions;
    if (props.match.params.directiveId && !filter) {
      //ordernumber varsa ekrandan filtreyide kaldirabiliriz.
      filter = `&$filter=DirectiveId eq ${props.match.params.directiveId}`;
    }

    setTableData({
      ...tableData,
      loading: true,
    });
     RequestHandler.get(
       `/payment?$count=true${filter}&$skip=${skip}&$top=${take}`
     ).then((response) => {
         setTableData({
           data: response.value,
           total: response.value?.length,
           loading: false,
         });
     });

    setTableData({ data: tableData.data, total: tableData.data.length, loading: false });
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: `&$filter=DirectiveId eq ${props.match.params.directiveId}`,
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };
  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);

    RequestHandler.post(
      `/Payment/ExportExcel`,
      {
        query: tableDataQueryOptions.filter,
      },
      false
    ).then((response) => {
      hideMessage();
      if (response) {
        window.open(
          `${config.apiUrl
          }/Payment/DownloadExcel?fileName=${response}&t=${RequestHandler.getToken()}`
        );
      }
    });
  };

  const onTableSelectChange = (selectedKeys, selectedRecords) => {
    setSelectedRows({
      keys: selectedKeys,
      data: selectedRecords,
    });
  };

  const onClickCreateOrder = () => {
    setCreateOrderModalVisible(true);
  };

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      width: "8%",
      render: (text) => <img src={text}></img>,
    },
    {
      title: "Ad Soyad / Ünvan",
      align:'center',
      dataIndex: "title",
    },
    {
      title: "Iban",
      align:'center',
      dataIndex: "iban",
    },
    {
      title: "Tutar",
      align:'center',
      dataIndex: "amount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Para Birimi",
      align:'center',
      dataIndex: "currency",
      render: (data, row) => (
        <>
          TRY
        </>
      ),
    },
    {
      title: "Ödeme Durumu",
      align:'center',
      dataIndex: "status",
    },
    {
      title: "Durum Mesajı",
      align:'center',
      dataIndex: "statusMessage",
    },
    {
      title: "Ödeme Tarihi",
      align:'center',
      dataIndex: "paymentDate",
      render: (data, row, index) => moment(data).format("DD.MM.YYYY"),
    },
    {
      title: "Açıklama",
      dataIndex: "description",
    },
  ];

  return (
    <>
      {props.match.params.directiveId && (
        <Row style={{ marginBottom: 9 }}>
          <Col flex={1}>
            <a
              onClick={() => history.goBack()}
              style={{ color: "black", marginLeft: "4%" }}
            >
              {" "}
              <RollbackOutlined /> GERİ DÖN
            </a>
          </Col>
          <Col flex={4}></Col>
        </Row>
      )}
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> TALİMAT DETAY
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
            <Tooltip title="Excel İndir">
              <a
                style={{
                  color: "green",
                  fontSize: "180%",
                  marginRight: 30,
                }}
                onClick={onClickExportExcel}
              >
                <FileExcelOutlined />
              </a>
            </Tooltip>
          {selectedRows.keys.length > 0 && (
            <Button onClick={onClickCreateOrder} style={{ marginBottom: 0 }}>
              <SendOutlined /> Talimat Oluştur
            </Button>
          )}
        </Col>
      </Row>

      <TosSentPaymentTableFilter
        data={{ directiveId: props.match.params.directiveId }}
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            // rowSelection={{
            //   selectedRowKeys: selectedRows.keys,
            //   onChange: onTableSelectChange,
            // }}
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Modal
        id="createOrderModal"
        centered
        visible={createOrderModalVisible}
        onOk={() => setCreateOrderModalVisible(false)}
        onCancel={() => setCreateOrderModalVisible(false)}
        width={800}
        footer={null}
      >
        <TosCreateOrderForm
          reCreateMode
          selectedPayments={selectedRows.data}
          data={{ paymentIds: selectedRows.keys }}
        />
      </Modal>
    </>
  );
};

export default TosSentPayment;
