import axios from "axios";
import config from "./ApiforContext";
import { notification } from "antd";

const isObject = (value) =>
  typeof value === "object" && !Array.isArray(value) && value !== null;
const RequestHandler = {
  send: (endPoint, method, data, showDefaultNotification, options) => {
    const expiration = localStorage.getItem("expiration");

    if (expiration < Date.now() && !endPoint.startsWith("/Auth") && !endPoint.startsWith("/PaymentRequest") && !endPoint.startsWith("/PosCollection")) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location = "/signin";
    }

    const token = RequestHandler.getToken();

    let defaultOptions = {
      method: method,
      url: endPoint.startsWith("http") ? endPoint : config.apiUrl + endPoint,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "bearer " + token,
      },
      data: method !== "get" ? data : undefined,
      params: method === "get" ? data : undefined,
    };

    if (options) {
      defaultOptions = Object.assign(defaultOptions, options);
    }

    return axios.request(defaultOptions).then((response) => {
      if (showDefaultNotification) {
        let type = response.data.success ? "success" : "error";
        let message = response.data.success
          ? "İşlem başarılı."
          : response.data.message;

        notification[type]({
          message: message,
          duration: 3,
        });
      }

      if (options?.rawResponse) {
        return response;
      }
      return response.data;
    })
      .catch((error) => {
        let message = error.toString();
        if (error.response) {
          if (error.response.status === 406) {
            error.response.data.data.forEach((validationMessage) => {
              notification.error({
                message: validationMessage,
                duration: 3,
              });
            });
          } else if (error.response.status === 400) {
            message = isObject(error.response.data)
              ? error.response.data.message
              : error.response.data;

            notification.error({
              message: message,
              duration: 3,
            });
          } else if (
            error.response.status === 403 ||
            error.response.status === 401
          ) {
            window.location = "/not-authorized-access";
            return;
          } else {
            notification.error({
              message: error.response.data?.message
                ? error.response.data.message
                : message,
              duration: 3,
            });
          }
        }

        if (options?.rawResponse) {
          return error;
        }

        return error.response
          ? error.response.data
          : {
            message: message,
            success: false,
          };
      });
  },

  get: (endPoint, data, options) =>
    RequestHandler.send(endPoint, "get", data, false, options),

  post: (endPoint, data, showDefaultNotification = true, options) =>
    RequestHandler.send(
      endPoint,
      "post",
      data,
      showDefaultNotification,
      options
    ),

  put: (endPoint, data, showDefaultNotification = true, options) =>
    RequestHandler.send(
      endPoint,
      "put",
      data,
      showDefaultNotification,
      options
    ),

  delete: (endPoint, data, showDefaultNotification = true, options) =>
    RequestHandler.send(
      endPoint,
      "delete",
      data,
      showDefaultNotification,
      options
    ),
  getToken: () =>
    localStorage.getItem("token")?.replace('"', "").replace('"', ""),
};

export default RequestHandler;
