const getMenuItems = (permissions) => [
  {
    key: "home",
    url: "/home",
    // icon: "icon icon-home",
    title: "Anasayfa",
    style: { color: "white" },
    children: [
      {
        key: "home-act",
        url: "/home-act",
        icon: "icon icon-revenue-new",
        title: "Hesap Hareketleri",
        module: 1,
      },
      {
        key: "home-pos",
        url: "/home-pos",
        icon: "icon icon-pricing-table",
        title: "POS Rapor",
        module: 5,
      },
      {
        key: "home-dbs",
        url: "/home-dbs",
        icon: "icon icon-family",
        title: "Doğrudan Borçlandırma Sistemi",
        module: 3,
      },
      {
        key: "home-b2b",
        url: "/home-b2b",
        icon: "icon icon-profile",
        title: "B2B Anasayfa",
        module: 8,
      },
      {
        key: "home-subscriberFirm",
        url: "/home-subscriberFirm",
        icon: "icon icon-all-contacts",
        title: "Apartman-Site",
        module: 9,
      },
    ],
  },
  {
    key: "accountprocess",
    // icon: "icon icon-card",
    title: "Hesap İşlemleri",
    module: 1,
    children: [
      {
        key: "bank-list",
        url: "/bank-list",
        icon: "icon icon-all-contacts",
        title: "Banka Bakiyeleri",
      },
      {
        key: "bank-accounts",
        url: "/bank-accounts",
        icon: "icon icon-cards-list-view",
        title: "Hesap Bakiyeleri",
      },
      {
        key: "account-transaction",
        url: "/account-transaction",
        icon: "icon icon-select",
        title: "Hesap Hareketleri",
      },

    ],
  },
  {
    key: "posOperations",
    // icon: "icon icon-revenue-new",
    title: "POS Rapor",
    module: 5,
    children: [
      {
        key: "pos-transaction",
        url: "/pos-transaction",
        icon: "icon icon-all-contacts",
        title: "POS Hareketleri",
      },
      {
        key: "value-report",
        url: "/value-report",
        icon: "icon icon-all-contacts",
        title: "Valör Raporu",
      },
      {
        key: "commission-report",
        url: "/commission-report",
        icon: "icon icon-all-contacts",
        title: "Komisyon Raporu",
      },
      {
        key: "pos-volume-report",
        url: "/pos-volume-report",
        icon: "icon icon-all-contacts",
        title: "Ciro Raporu",
      },
      {
        key: "commission-definition",
        url: "/commission-definition",
        icon: "icon icon-feedback",
        title: "Komisyon Tanımlama",
      },
    ],
  },
  {
    key: "tos",
    // icon: "icon icon-revenue-new",
    title: "TÖS",
    module: 2,
    children: [
      {
        key: "tos-payment",
        url: "/tos-payment",
        icon: "icon icon-ckeditor",
        title: "Ödeme Listesi",
      },
      {
        key: "tos-order",
        url: "/tos-order",
        icon: "icon icon-editor",
        title: "Talimat Listesi",
      },
      {
        key: "tos-sent-order",
        url: "/tos-sent-order",
        icon: "icon icon-editor",
        title: "Gönderilmiş Talimatlar",
      },
      {
        key: "tos-sent-payment",
        url: "/tos-sent-payment",
        icon: "icon icon-editor",
        title: "Gönderilmiş Ödemeler",
      },
    ],
  },
  {
    key: "dbs",
    // icon: "icon icon-revenue-new",
    title: "DBS",
    module: 3,
    children: [
      {
        key: "dbs-dealer",
        url: "/dbs-dealer",
        icon: "icon icon-team",
        title: "Bayi Bilgileri",
      },
      {
        key: "dbs-invoice",
        url: "/dbs-invoice",
        icon: "icon icon-editor",
        title: "Fatura Listesi",
      },
      {
        key: "dbs-sent-invoice",
        url: "/dbs-sent-invoice",
        icon: "icon icon-sent",
        title: "Gönderilmiş Faturalar",
      },
      {
        key: "dbs-report",
        icon: "icon icon-data-display",
        title: "Raporlar",
        children: [
          {
            key: "dbs-report/bank-limit-report",
            url: "/dbs-report/bank-limit-report",
            icon: "icon icon-chart-radial",
            title: "Banka Bazlı Limit Raporu",
          },
          {
            key: "dbs-report/bank-volume-report",
            url: "/dbs-report/bank-volume-report",
            icon: "icon icon-chart-composed",
            title: "Banka Bazlı Ciro Raporu",
          },
          {
            key: "dbs-report/firm-status-report",
            url: "/dbs-report/firm-status-report",
            icon: "icon icon-profile",
            title: "Cari Durum Raporu",
          },
          {
            key: "dbs-report/value-report",
            url: "/dbs-report/value-report",
            icon: "icon icon-all-contacts",
            title: "İleri Yönelik Alacaklar Raporu",
          },
        ],
      },
    ],
  },
  {
    key: "subscriberFirm",
    // icon: "icon icon-card",
    title: "ASY",
    module: 9,
    children: [
      {
        key: "subscriberfirm-list",
        url: "/subscriberfirm-list",
        icon: "icon icon-all-contacts",
        title: "Daire Listesi",
      },
      {
        key: "subscriberfirm-status-report",
        url: "/subscriberfirm-status-report",
        icon: "icon icon-profile",
        title: "Daire Durumları",
      },
    ],
  },
  {
    key: "reports",
    // icon: "icon icon-amchart",
    title: "Raporlar",
    children: [
      {
        key: "firm-status-report",
        url: "/firm-status-report",
        icon: "icon icon-company",
        title: "Cari Durumları",
      },
      {
        key: "chart-reports",
        url: "/chart-reports",
        icon: "icon icon-company",
        title: "Ay Bazlı Grafik",
      },
      {
        key: "monthly-firm-status-report",
        url: "/monthly-firm-status-report",
        icon: "icon icon-company",
        title: "Tarih Bazlı Durum",
      },
      {
        key: "day-reports",
        url: "/day-reports",
        icon: "icon icon-company",
        title: "Gün Sonu Raporu",
      },
      {
        key: "consolidated-bank",
        url: "/consolidated-bank",
        icon: "icon icon-all-contacts",
        title: "Konsolide Banka",
        isRender: permissions.isGroupUser,
      },
    ],
  },
  {

    key: "firms",
    // icon: "icon icon-company",
    title: "Cari İşlemleri",
    children: [
      {
        key: "firm-list",
        url: "/firm-list",
        icon: "icon icon-all-contacts",
        title: "Cari Listesi",
      },
      {
        key: "firm-account-list",
        url: "/firm-account-list",
        icon: "icon icon-anchor",
        title: "Cari Hesapları",
      },
    ],
  },
  {

    key: "paymentManagement",
    // icon: "icon icon-company",
    title: "Nakit Yönetimi",
    children: [
      {
        key: "monthlyPlans",
        icon: "icon icon-company",
        title: "Gelir-Gider Planlama",
        url: "/monthly-plan-list"
      },
      {
        key: "manual-account-transaction",
        url: "/manual-account-transaction",
        icon: "icon icon-data-entry",
        title: "Kasa İşlemleri",
      }
    ],
  },
  {
    key: "erp",
    title: "Erp",
    module: 7,
    children: [
      {
        key: "erp-incoming-transaction",
        url: "/erp-incoming-transaction",
        icon: "icon icon-chart-line",
        title: "Gelen İşlemler",
      },
      {
        key: "erp-outgoing-transaction",
        url: "/erp-outgoing-transaction",
        icon: "icon icon-chart-area-new",
        title: "Giden İşlemler",
      },
      {
        key: "erp-report",
        icon: "icon icon-pricing-table",
        title: "Erp Rapor",
        children: [
          {
            key: "erp-firm-report",
            url: "/erp-firm-report",
            icon: "icon icon-all-contacts",
            title: "Cari Takip",
          },
          {
            key: "erp-stock-report",
            url: "/erp-stock-report",
            icon: "icon icon-all-contacts",
            title: "Stok Durumu",
          },
        ],
      },
      {
        key: "erp-activity-list",
        url: "/erp-activity-list",
        icon: "icon icon-data-entry",
        title: "Veri Yükleme",
      },
    ],
  },
  {
    key: "b2b",
    title: "B2B",
    module: 8,
    children: [
      {
        key: "pos-collection-transaction",
        url: "/pos-collection-transaction",
        icon: "icon icon-timepicker",
        title: "Tahsilat Geçmişi",
      },
      {
        key: "pos-collection",
        url: "/pos-collection",
        icon: "icon icon-litcoin",
        title: "Pos Tahsilatı",
      },
      {
        key: "firm-user",
        url: "/firm-user",
        icon: "icon icon-contacts",
        title: "Firma Paneli",
      },
      {
        key: "payment-request",
        url: "/payment-request",
        icon: "icon icon-contacts",
        title: "Ödeme Talebi",
      },
      {
        key: "payment-list",
        url: "/payment-list",
        icon: "icon icon-all-contacts",
        title: "Ödeme Takibi",
      },
    ],
  },
  {
    key: "setting",
    // icon: "icon icon-setting",
    title: "Ayarlar",
    isRender: permissions.isAdmin,
    children: [
      {
        key: "group-list",
        url: "/group-list",
        icon: "icon icon-company",
        title: "Grup Yönetimi",
        isRender: permissions.isGroupAdmin,
      },
      {
        key: "transaction-bank-info",
        url: "/transaction-bank-info",
        icon: "icon icon-all-contacts",
        title: "Hesap Erişimi",
      },
      {
        key: "currency-live",
        url: "",
        icon: "icon icon-cards-list-view",
        title: "Döviz Kuru",
      },
      {
        key: "password-access",
        url: "",
        icon: "icon icon-cards-list-view",
        title: "Sms Onayı",
      },
      {
        key: "notifications",
        url: "/notifications",
        icon: "icon icon-cards-list-view",
        title: "Bildirim",
      },
      {
        key: "user-list",
        url: "/user-list",
        icon: "icon icon-all-contacts",
        title: "Kullanıcı Listesi",
      },
      {
        key: "types",
        // icon: "icon icon-setting",
        title: "İşlem Tipleri",
        children: [
          {
            key: "firm-class-list",
            url: "/firm-class-list",
            icon: "icon icon-menu-unfold",
            title: "Cari Sınıfları",
          },
          {
            key: "monthly-plan-type-list",
            url: "/monthly-plan-type-list",
            icon: "icon icon-home",
            title: "Gelir-Gider Tipleri",
          },
          {
            key: "manual-account-transaction-type",
            url: "/manual-account-transaction-type",
            icon: "icon icon-data-entry",
            title: "Kasa İşlem Tipi",
          },
        ],
      },
    ],
  },
];

export default getMenuItems;
