import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Select,
  DatePicker,
  Upload,
  notification,
} from "antd";
import { PlusOutlined, DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import CurrencyInput from 'react-currency-input';
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";
import config from "../../util/ApiforContext";
import moment from 'moment';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const TosPaymentAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [bankBranchDropdown, setbankBranchDropdown] = useState([]);
  const [bankId, setbankId] = useState("");
  const [bankBranchName, setbankBranchName] = useState("");
  const [iban, setIban] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [firmName, setFirmName] = useState("");
  const [title, setTitle] = useState("");
  const [bankBranchId, setBankBranchId] = useState("");
  const [accountNumberFromFirmAccount, setAccountNumberFromFirmAccount] = useState("");
  const [ibanFromFirmAccount, setIbanFromFirmAccount] = useState("");
  const [bankDropdown, setbankDropdown] = useState([]);
  const [firmDropdown, setfirmDropdown] = useState([]);
  const [fileList, setFileList] = useState([]);

  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: true,
  });
  const [currencySelect, setCurrencySelect] = useState({
    data: [],
    loading: true,
  });
  const [form] = Form.useForm();

  useEffect(() => {
    getCurrencies();
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const maxLengthCheck = (object) => {
    if (object.target.value?.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }

  const getCurrencies = () => {
    setIban('TR')
    setCurrencySelect({
      ...bankSelect,
      loading: true,
    });
    RequestHandler.get(`/bank?$filter=isEmoney ne true&$orderby=name`).then((response) => {
      const bankDropdown = response.value.filter(x => x.id !== 999);
      setbankDropdown(bankDropdown)
    });
  };

  const onFinish = (values) => {
    values.paymentDate = values.paymentDate.format("YYYY-MM-DD HH:mm:ss");
    values.AmountString = values.amount;
    values.amount = 1;
    RequestHandler.post(`/Payment`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        // getBanks();
        form.resetFields();
        setTitle('')
        setbankId('')
        setIban('TR')
        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onChange = (value) => {
    RequestHandler.get(`/bankbranch/getbranchforbank?id=${value}`).then((response) => {
      setbankBranchDropdown(response)
      setbankId(value)
      setbankBranchName('')
    });
  }
  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };
  const onChangeIban = (value) => {
    setIban(value.target.value);
  }
  const onChangeTitle = (value) => {
    setTitle(value.target.value);
  }
  const onChangeAmount = (value) => {
    setTitle(value.target.value.replace(',', '.'));
  }
  function disabledDate(current) {
    return moment().add(-1, 'days') >= current
  }
  const onSearch = (val) => {
    if (val.length >= 3) {
      RequestHandler.get(`/Firm?isFilter=true&$filter=contains(name, '${val}')`).then((response) => {
        const firmDropdown = response.value;
        setfirmDropdown(firmDropdown)
      });
    } else {
      const firmDropdown = [];
      setfirmDropdown(firmDropdown)
    }
  }
  const onFocus = () => {
    RequestHandler.get(`/Firm?$filter=contains(name, '___')`).then((response) => {
      const firmDropdown = response.value;
      setfirmDropdown(firmDropdown)
    });
  }
  const onChangeFirm = (val) => {
    RequestHandler.get(`/FirmAccount?$filter= firmId eq ${val} and isDefault eq true`).then((response) => {
      if (response?.value[0] === undefined) {
        RequestHandler.get(`/FirmAccount?$filter= firmId eq ${val}`).then((res) => {
          if (res?.value[0] != undefined) {
            setTitle(res?.value[0].firmName)
            setBankBranchId(res?.value[0].bankBranchId)
            setbankId(res?.value[0].bankId)
            setAccountNumber(res?.value[0].accountNumber)
            setIban(res?.value[0].iban)
            setAccountNumberFromFirmAccount(res?.value[0].accountNumber)
            setIbanFromFirmAccount(res?.value[0].iban)
            RequestHandler.get(`/bankbranch/getbranchforbank?id=${res?.value[0].bankId}`).then((response) => {
              setbankBranchDropdown(response)
            });
          } else {
            setTitle(undefined)
            setBankBranchId(undefined)
            setbankId(undefined)
            setAccountNumber(undefined)
            setIban(undefined)
            setAccountNumberFromFirmAccount(undefined)
            setIbanFromFirmAccount(undefined)
          }
        });
      } else {
        setTitle(response?.value[0].firmName)
        setBankBranchId(response?.value[0].bankBranchId)
        setbankId(response?.value[0].bankId)
        setAccountNumber(response?.value[0].accountNumber)
        setIban(response?.value[0].iban)
        setAccountNumberFromFirmAccount(response?.value[0].accountNumber)
        setIbanFromFirmAccount(response?.value[0].iban)
        RequestHandler.get(`/bankbranch/getbranchforbank?id=${response?.value[0].bankId}`).then((response) => {
          setbankBranchDropdown(response)
        });
      }
    });
  }
  const downloadExcelTemplate = () => {
    window.open(
      `${config.apiUrl
      }/Payment/DownloadExcelTemplate?t=${RequestHandler.getToken()}`
    );
  };
  const onChangeAccountNumber = (value) => {
    setAccountNumber(value.target.value);
  }
  const uploadExcelProps = {
    name: "excel",
    action: `${config.apiUrl}/Payment/ImportExcel`,
    headers: {
      Authorization: "bearer " + RequestHandler.getToken(),
    },
    accept: ".xlsx",
    maxCount: 1,
    onChange(info) {
      setFileList(info.fileList);
      const { response, status } = info.file;

      if (status !== "error" && status !== "done") return;
      if (!response) return;

      const notificationType = response.success ? "success" : "error";

      notification[notificationType]({
        message: response.message,
        duration: 3,
      });
      if (!response.success) {
        downloadImportResult(info.file.response.data);
      }
      onSuccess();
    },
  };
  const onSuccess = () => {
    form.resetFields();
    setFileList([]);

    if (props.onSuccess) props.onSuccess();
  };
  const downloadImportResult = (fileName) => {
    window.open(
      `${config.apiUrl
      }/Payment/DownloadImportResult?t=${RequestHandler.getToken()}&fileName=${fileName}`
    );
  };

  const { Option } = Select;
  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="tosPaymentAdd"
      initialValues={{
        remember: true,
      }}
      fields={[
        { name: "title", value: title },
        { name: "bankId", value: bankId },
        { name: "BankBranchId", value: bankBranchId },
        { name: "accountNumber", value: accountNumber },
        { name: "iban", value: iban },
      ]}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            label="Cari Adı/Ünvanı"
            name="FirmId"
          >
            <Select
              showSearch
              placeholder="En az 3 harf giriniz.."
              optionFilterProp="children"
              onFocus={onFocus}
              onSearch={onSearch}
              onChange={onChangeFirm}
              filterOption={(input, option) =>
                option.props.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
            >
              {firmDropdown.map(item => (
                <Option key={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Ad Soyad / Ünvan"
            name="title"
            rules={[{ required: true }]}
          >
            <Input value={title} onChange={onChangeTitle} />
          </Form.Item>

          <Form.Item
            label="Banka"
            name="bankId"
            rules={[
              {
                required: true,
                message: 'Lütfen banka alanını boş bırakmayın.',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Seçiniz."
              optionFilterProp="children"
              onChange={onChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
            >
              {bankDropdown.map(item => (
                item.name === "KASA HESABI" ? "" :
                  <Option key={item.id} value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="IBAN"
            name="iban"
            onChange={onChangeIban}
            rules={
              [
                {
                  required: true,
                  message: 'Lütfen Iban alanını boş bırakmayın.',
                },
              ]}
          >
            <Input type="text" onFocus={(event) => { event.target.select() }} maxLength="26" />
          </Form.Item>

          <Form.Item label="Tutar" name="amount" rules={[{ required: true }]}>
            <CurrencyInput style={{ width: "100%" }} className="ant-input" decimalSeparator="," thousandSeparator="." />
            {/* <InputNumber min={0} style={{width:'100%'}} parser={value => value.replace(',', '.')} /> */}
          </Form.Item>

          <Form.Item
            name="currencyId"
            label="Para Birimi"
          // rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              defaultValue="1"
              disabled
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
            >
              <Select.Option selected key={1} value={"1"}>
                TRY
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Ödeme Tarihi"
            name="paymentDate"
            rules={[{ required: true }]}
          >
            <DatePicker disabledDate={disabledDate} format="DD.MM.YYYY" />
          </Form.Item>

          <Form.Item
            label="Açıklama"
            name="description"
          >
            <Input.TextArea rows={3} />
          </Form.Item>

          <Col style={{ textAlign: "right" }}>
            <Button
              icon={<DownloadOutlined />}
              onClick={(() => setLoadingExcel(true), downloadExcelTemplate)}
              loading={loadingExcel}
            >
              Excel Şablonu İndir
            </Button>
            <Upload {...uploadExcelProps} fileList={fileList}>
              <Button icon={<UploadOutlined />}>Excel ile Ekle</Button>
            </Upload>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Ekle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default TosPaymentAddForm;
