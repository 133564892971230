import React from "react";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Card} from "antd";
import CurrencyFormat from 'react-currency-format';

function currencyFormat(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
const CustomTooltip = ({ active, payload, label }) => {

  if (active) {
if(payload!= null){
  return (
    <div className="custom-tooltip" style={{backgroundColor:"white",width:"150%", height:"150%"}}>
      <div className="label" style={{color:"red",marginTop:"5%",marginLeft:"5%"}} >{`${label}`}</div>
      <div className="label" style={{color:"orange",marginTop:"5%",marginLeft:"5%"}} >{`${payload[0]?.name}`}</div>
      <div className="label" style={{marginLeft:"5%"}} >{ <CurrencyFormat value={payload[0].value.toString().includes(".") ? payload[0].value : payload[0].value + ",00"} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} />}</div>
      <div className="label" style={{color:"orange",marginLeft:"5%"}} >{`${payload[1]?.name}`}</div>
      <div className="label" style={{marginLeft:"5%"}} >{ <CurrencyFormat value={payload[1].value.toString().includes(".") ? payload[1].value : payload[1].value + ",00"} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} /> }</div>
    </div>
  );
}
   
  }

  return null;
};
const BiaxialBarChart = (props) => {
  const {chartValues} = props;
  try {
    chartValues.map(item =>{
      item.odeme = item.odeme;
      item.tahsilat = item.tahsilat;
    })
  } catch (error) {
  }
    
  const data = chartValues;
  return(
<Card
        style={{width:"90%", marginLeft:"4%", marginTop:"1%"}}
        className={`gx-card-widget ant-col `}>
          
     <h2 style={{textAlign:"center"}} >AYLIK GERÇEKLEŞEN TAHSİLAT VE YAPILAN ÖDEME</h2><br/>
 
  <ResponsiveContainer width="90%" height={200}>
    <BarChart data={data}
              margin={{top: 10, right: 0, left: 13, bottom: 0}}>
      <XAxis dataKey="name"/>
      <YAxis/>
      <CartesianGrid strokeDasharray="3 3"/>
      <Tooltip content={CustomTooltip} />
      <Legend/>
      <Bar dataKey="odeme" name="Ödeme" fill="#ff7f50"/>
      <Bar dataKey="tahsilat" name="Tahsilat" fill="#87cefa"/>
    </BarChart>
  </ResponsiveContainer>
  </Card>
  )
};

export default BiaxialBarChart;
