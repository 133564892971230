import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const PosCollectionBankInfoUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [activeBankId, setActiveBankId] = useState();
  const [bankRequirement, setBankRequirement] = useState({});

  useEffect(() => {
    if (props.data) {
      props.data.notifyMails = props.data.notifyMails?.split(",");

      form.setFieldsValue(props.data);
      setActiveBankId(props.data.bankId);
    }
  }, [props.data]);

  useEffect(() => {
    if (activeBankId) getBankInfoRequirements(activeBankId);
  }, [activeBankId]);

  const getBankInfoRequirements = (bankId) => {
    RequestHandler.get(
      `/PosCollectionBankInfoRequirement?$filter=bankId eq ${bankId}`
    ).then((response) => {
      if (Array.isArray(response.value)) {
        setBankRequirement(response.value[0]);
      }
    });
  };

  const onFinish = (values) => {
    values.notifyMails = values.notifyMails?.join(",");

    RequestHandler.put(`/PosCollectionBankInfo`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        form.resetFields();

        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="posBankInfoUpdate"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="bankId" hidden>
            <Input />
          </Form.Item>

          {bankRequirement && (
            <>
              {bankRequirement.isUserNameRequired && (
                <Form.Item
                  label={bankRequirement.userNameLabel ? bankRequirement.userNameLabel : "Kullanıcı Adı"}
                  name="userName"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              {bankRequirement.isPasswordRequired && (
                <Form.Item
                  label={bankRequirement.passwordLabel ? bankRequirement.passwordLabel : "Şifre"}
                  name="password"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
              )}

              {bankRequirement.isFirmKeyRequired && (
                <Form.Item
                  label={bankRequirement.firmKeyLabel ? bankRequirement.firmKeyLabel : "Firma Kodu"}
                  name="firmKey"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              {bankRequirement.isFirmSecretRequired && (
                <Form.Item
                  label={bankRequirement.firmSecretLabel ? bankRequirement.firmSecretLabel : "Firma Gizli Kodu"}
                  name="firmSecret"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              <Form.Item
                label="Ödeme Bildirim E-postaları"
                name="notifyMails"
                rules={[{
                  validator: (rule, values) => {
                    if (!values) {
                      return Promise.resolve();
                    }

                    const emailRegex = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
                    const invalidInputs = values.filter((value) => !value.match(emailRegex));
                    if (invalidInputs.length === 0) {
                      return Promise.resolve();
                    }

                    if (invalidInputs.length === 1) {
                      return Promise.reject(new Error(`'${invalidInputs.join('')}' geçersiz bir e-postadır.`));
                    } else {
                      return Promise.reject(new Error(`'${invalidInputs.join(', ')}' değerleri geçersiz e-postadır.`));
                    }
                  }
                }]}
              >
                <Select
                  allowClear
                  showSearch
                  tokenSeparators={[',', ';']}
                  mode="tags"
                >
                </Select>
              </Form.Item>

              <Form.Item
                label="Erp Kod"
                name="erpBankFirmCode"
              >
                <Input />
              </Form.Item>

            </>
          )}

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Güncelle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default PosCollectionBankInfoUpdateForm;
