import React, { useEffect, useState } from 'react'
import { Collapse, Select,Row,Input,Col,Button } from 'antd';
import { QuestionCircleOutlined} from '@ant-design/icons';

const { Panel } = Collapse;
const { Option } = Select;
const { Search } = Input;
const callback = (key) => {
  }

 const Questions = () => {
        const [title1, setTitle1] = useState("Erişim bilgilerim geldi kullanıcı adı ya da şifre hatalı hatası veriyor. Ne yapabilirim?");
        const [title1Display, setTitle1Display] = useState('block');
        const [title2, setTitle2] = useState("Erişim bilgilerim geldi. İşlem sırasında bir hata oluştu hatası veriyor. Ne yapabilirim?");
        const [title2Display, setTitle2Display] = useState('block');
        const [title3, setTitle3] = useState("Finekra’da bazı hesaplarım görünmüyor. Ne yapabilirim ?");
        const [title3Display, setTitle3Display] = useState('block');
        const [title4, setTitle4] = useState("Hesap hareketim bankada görünüyor ama Finekra’da göremiyorum. Ne yapabilirim ?");
        const [title4Display, setTitle4Display] = useState('block');
        const [title5, setTitle5] = useState("Bazı hesaplarımın toplam bakiyeye dahil edilmemesi için ne yapabilirim ?");
        const [title5Display, setTitle5Display] = useState('block');
        const [title6, setTitle6] = useState("En fazla kaç kullanıcı ekleyebilirim ?");
        const [title6Display, setTitle6Display] = useState('block');
        const [title7, setTitle7] = useState("Birden fazla yönetici kullanıcı ekleyebilir miyim ?");
        const [title7Display, setTitle7Display] = useState('block');
        const [title8, setTitle8] = useState("Hesap ve Ekranlar için kullanıcıların yetkilerini düzenleyebilir miyim ?");
        const [title8Display, setTitle8Display] = useState('block');
        const [title9, setTitle9] = useState("Hesap Hareketi gerçekleştiğinde bildirim almak için ne yapabilirim ?");
        const [title9Display, setTitle9Display] = useState('block');
        const [title10, setTitle10] = useState("Hesap hareketlerimi carilerim ile nasıl eşleştirebilirim?");
        const [title10Display, setTitle10Display] = useState('block');
        const searchReset = (val) => {
            if(val.target.value === ""){
                setTitle1Display('block')
                setTitle2Display('block')
                setTitle3Display('block')
                setTitle4Display('block')
                setTitle5Display('block')
                setTitle6Display('block')
                setTitle7Display('block')
                setTitle8Display('block')
                setTitle9Display('block')
                setTitle10Display('block')
            }
        }
        const onSearch = (value) => {
            if(!title1.toLowerCase().includes(value.toLowerCase())){
                setTitle1Display('none')
            }else{
                setTitle1Display('block')
            }
            if(!title2.toLowerCase().includes(value.toLowerCase())){
                setTitle2Display('none')
            }else{
                setTitle2Display('block')
            }
            if(!title3.toLowerCase().includes(value.toLowerCase())){
                setTitle3Display('none')
            }else{
                setTitle3Display('block')
            }
            if(!title4.toLowerCase().includes(value.toLowerCase())){
                setTitle4Display('none')
            }else{
                setTitle4Display('block')
            }
            if(!title5.toLowerCase().includes(value.toLowerCase())){
                setTitle5Display('none')
            }else{
                setTitle5Display('block')
            }
            if(!title6.toLowerCase().includes(value.toLowerCase())){
                setTitle6Display('none')
            }else{
                setTitle6Display('block')
            }
            if(!title7.toLowerCase().includes(value.toLowerCase())){
                setTitle7Display('none')
            }else{
                setTitle7Display('block')
            }
            if(!title8.toLowerCase().includes(value.toLowerCase())){
                setTitle8Display('none')
            }else{
                setTitle8Display('block')
            }
            if(!title9.toLowerCase().includes(value.toLowerCase())){
                setTitle9Display('none')
            }else{
                setTitle9Display('block')
            }
            if(!title10.toLowerCase().includes(value.toLowerCase())){
                setTitle10Display('none')
            }else{
                setTitle10Display('block')
            }
          };
        return (
            <>
            <Row>
                <Col span={18}></Col>
                <Col span={6}><Search placeholder="Başlıkta Ara" onSearch={onSearch} onChange={(val) => {searchReset(val)}} style={{ width: 200 }} /></Col>
            </Row>
                 <Collapse
                    onChange={callback}
                    accordion
                    style={{backgroundColor:'white'}}
                    >
                    <Panel header="Erişim bilgilerim geldi kullanıcı adı ya da şifre hatalı hatası veriyor. Ne yapabilirim?" style={{display:title1Display}} key="1" >
                        <div>
                        <p>Şifre/Kullanıcı adının başında boşluk olmaması gerekmektedir ve genellikle SMS yolu ile iletilen şifrelerde harfler karışabilmektedir(küçük L ve büyük I harfi gibi).</p>
                        <p><strong>Sık karşılaşılan durumlar :</strong></p>
                        <p><strong>Kuveyt Türk Bankası:</strong> şifrenin yarısı mail ile diğer yarısı SMS ile gelmektedir. Tamamının yazılması gerekir.</p>
                        <p><strong>Akbank:</strong> Kullanıcı ve Şifre bilgisini KİŞ üzerinden oluşturduktan sonra erişim aktif olması için şubenize bilgi vermeniz gerekmektedir.</p>
                        <p><strong>Yapı Kredi Bankası :</strong> Kullanıcı Kodu girer iken CODA\ yazılmaması gerekiyor. Tarafınıza iletilen şifrede ilk iki harf büyük XX ( I var ise Iğdır’ın I sıdır) 3 ve 4. Harfeler küçük ( I var ise lalenin l sidir ) ve 4 rakamdan oluşur XXxx1111 şeklindedir , büyük küçük harf duyarlıdır.</p>
                        <p>Tüm kontroller sonucunda hata devam ediyor ise şubenizle iletişime geçebilir ve bizlere destek talebi oluşturabilirsiniz. Kısa süre içinde ekibimiz dönüş sağlayacaktır.</p>
                        </div>
                    </Panel>
                    <Panel header="Erişim bilgilerim geldi. İşlem sırasında bir hata oluştu hatası veriyor. Ne yapabilirim?" style={{display:title2Display}} key="2" >
                        <div>
                        <p>Erişim bilgilerinizin doğru girildiğinden emin olunuz. Ardından sorun devam ediyor ise banka tarafında tanım eksik olabilmektedir. İlgili banka için imzaladığınız tanım formundaki IP adreslerine banka tarafından tanımlamanın tamamlanması gerekmektedir. Şubenizle iletişime geçip hata aldığınızı belirtebilirsiniz.</p>

                        <p><strong>Sık karşılaşılan durumlar :</strong></p>

                        <p><strong>Vakıfbank :</strong> Hesap numarası alanında hesap numarası 001580 ile başlar.</p>

                        <p><strong>Akbank :</strong> Müşteri Numarası alanı URF kodunuzdur. Kodunuzu bilmiyorsanız şube temsilcinizden öğrenebilirsiniz.</p>

                        <p><strong>Iban/Hesap no bilgisi istenen bankalar:</strong> Ekrandan girilen hesaba tanımın yapılmamış olması durumu olabilmektedir. Şubeniz ile görüşerek Finekra’da görünmesini istediğiniz tüm hesaplarınız için bankada online webservis tanımı yapıldığını teyit edebilirsiniz.</p>
                        </div>
                    </Panel>
                    <Panel header="Finekra’da bazı hesaplarım görünmüyor. Ne yapabilirim ?" style={{display:title3Display}} key="3" >
                        <div>
                        <p><strong>Hesap Erişimi girişinde Hesap No/Iban bilgisi isteyen bankalar:</strong> İlk hesabınız ile erişimi kaydettikten sonra listedeki Hesaplar butonu ile diğer hesaplarınızı da eklemeniz gerekmektedir. Eğer diğer hesapları eklerken hata alıyorsanız banka tarafında tanım eksikliği olabilmektedir. Şubeniz ile görüşerek ilgili hesaplar için tanımlama talep edebilirsiniz.</p>

                        <p><strong>Hesap Erişimi girişinde Hesap No/Iban bilgisi istemeyen bankalar:</strong> Bankadan gelen tüm hesaplar otomatik olarak Finekra’da listelenecektir. Eksik hesaplarınız var ise banka tarafında tanım eksikliği olabilmektedir. Şubeniz ile görüşerek ilgili hesaplar için tanımlama talep edebilirsiniz.</p>

                        <p>Tüm hesaplarınız tanımlı ve banka tarafında da tanım eksikliği yok ise destek talebi oluşturabilirsiniz. Kısa süre içinde ekibimiz dönüş sağlayacaktır.</p>
                        </div>
                    </Panel>
                    <Panel header="Hesap hareketim bankada görünüyor ama Finekra’da göremiyorum. Ne yapabilirim ?" style={{display:title4Display}} key="4" >
                        <div>
                        <p>Finekra’da hesaplar 15 dakikada bir sorgulanmaktadır (Halkbank-Vakıfbank istisna 30 dk). İlgili hareket 15 dk içinde gerçekleşti ve banka servisine geldi ise maksimum 15 dk içinde Finekra’da listelenecektir.</p>

                        <p>Sorun devam ediyor ise hesabınıza ait  <strong>banka, hesap no/iban</strong> ve döviz cinsi bilgileri ile harekete ait <strong>gelen/giden bilgisi, tutar, hareketin gerçekleşme tarih saat</strong> bilgisini içerir destek talebi açabilirsiniz. Kısa süre içinde ekibimiz dönüş sağlayacaktır.</p>
                        </div>
                    </Panel>
                    <Panel header="Bazı hesaplarımın toplam bakiyeye dahil edilmemesi için ne yapabilirim ?" style={{display:title5Display}} key="5" >
                        <div>
                        <p>Finekra’da istediğiniz hesapların bakiye toplamlarına dahil edilip edilmemesini seçebilirsiniz.</p>

                        <p><strong>Hesap İşlemleri {'>'} Hesap Listesi</strong> ekranından <strong>Düzenle</strong> butonu ile açılan formda <strong>Hesaplanma </strong>alanındaki tik ile yönetebilirsiniz.</p>
                        </div>
                    </Panel>
                    <Panel header="En fazla kaç kullanıcı ekleyebilirim ?" style={{display:title6Display}} key="6" >
                        <div>
                            Finekra’da kullanıcı sayısı kısıtı bulunmuyor. İstediğiniz sayıda kullanıcıyı <strong> Ayarlar {'>'} Kullanıcı Listesi</strong> ekranından ekleyebilirsiniz.
                        </div>
                    </Panel>
                    <Panel header="Birden fazla yönetici kullanıcı ekleyebilir miyim ?" style={{display:title7Display}} key="7" >
                        <div>
                        <p>Finekra’da varsayılan olarak sadece ilk kullanıcı yönetici olarak eklenmektedir.</p>

                        <p>Birden fazla kullanıcının yönetici olabilmesi için kullanıcıları ekledikten sonra istediğiniz kullanıcıların E-mail (en fazla 2 E-mail) adresleri ile bize destek talebi açarsanız kısa süre içinde ekibimiz dönüş sağlayacaktır.</p>
                        </div>
                    </Panel>
                    <Panel header="Hesap ve Ekranlar için kullanıcıların yetkilerini düzenleyebilir miyim ?" style={{display:title8Display}} key="8" >
                        <div>
                        <p>Tüm kullanıcılar ilk eklendiğinde varsayılan olarak tüm hesaplara ve tüm ekranlara yetkili olarak eklenir. Yetkilerini düzenlemek istediğiniz kullanıcı için <strong>Ayarlar {'>'} Kullanıcı Listesi {'>'} Ekran Yetki / Hesap Yetki</strong> butonları ile yetkileri düzenleyebilirsiniz.</p>
                        </div>
                    </Panel>
                    <Panel header="Hesap Hareketi gerçekleştiğinde bildirim almak için ne yapabilirim ?" style={{display:title9Display}} key="9" >
                        <div>
                        <p>Finekra’da hesap hareketi ve bakiyeleriniz için mail ile bildirim alabilirsiniz.</p>

                        <p><strong>Ayarlar {'>'} Bildirim</strong> ekranına giderek hangi durumlarda bildirim almak istiyorsanız seçim yaparak bildirim kurabilirsiniz.</p>

                        <p>Ekranda istenen <strong>saat</strong> alanı bakiye bilgisinin mail atılması için günlük olarak gelmesini istediğiniz saattir.</p>

                        <p>Ekrandaki <strong>tutar</strong> alanına tutar girişi yaparsanız girdiğiniz tutarın üstündeki hareketler mail ile bildirilecektir.</p>
                        </div>
                    </Panel>
                    <Panel header="Hesap hareketlerimi carilerim ile nasıl eşleştirebilirim?" style={{display:title10Display}} key="10" >
                        <div>
                        <p>Finekra’da hareketleriniz carileriniz ile otomatik olarak eşleşir. Eşleştirmenin yapılabilmesi için carilerinizin <strong>Cariler ve Planlar {'>'} Cari İşlemleri</strong> ekranında kayıtlı olması gerekir. <strong>Cari Ekle</strong> butonu ile tek tek ya da formun en altındaki Excel şablonunu indirerek toplu olarak Carilerinizi kaydedebilirsiniz.</p>

                        <p>Carilerinizin hesaplarını kaydetmeniz, Vkn/Tckn bilgilerini eklemeniz gibi daha detaylı kayıtlarınızda hareketlerinizin cari ile otomatik eşleşmesi oranı artacaktır.</p>
                        </div>
                    </Panel>
                    </Collapse>
                    <Col style={{textAlign:'center',marginTop:30,marginBottom:17}}>
                    <a href='/support-panel/2'><Button type="primary"> Farklı Konuda Destek Al</Button></a>
                    </Col>
            </>
        )
}
export default Questions;