import React ,{useEffect,useState} from "react";
import {Button,notification,Result,message,Row,Col, Form, Input} from "antd";
import axios from 'util/Api'
import config from "../util/ApiforContext"
import { CheckOutlined } from '@ant-design/icons';
import {useHistory} from "react-router-dom";
import Screen from '../util/Extensions/Screens'
const FormItem = Form.Item;
const NewPassword = (props) => {
const history = useHistory();
const [isHavePsrGuid, setIsHavePsrGuid] = useState(true);
const [loading, setLoading] = useState(false);
var errorList = [];

const errorListNotification = type => {
  errorList.map((item)=> {
    notification[type]({
      message: 'Hata',
      description:
      item
       ,
        duration: 4,
    });
  })
};
  useEffect(() => {
    errorList = []
    axios.get(`${config.apiUrl}/auth/IsHavePsrGuid?PsrGuid=${props.match.params.psrGuid}`,
     {})
     .then(res => {
        if(res.status==200){
          setIsHavePsrGuid(true)
             }
         }).catch(
          error => {
            if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
              setTimeout(() => {
                window.location.href="/signin";
              }, 1000); 
             }else{
              openNotificationWithIconWarning('error')}
             }
      );
  }, [])
  const onFinishFailed = errorInfo => {
    setLoading(false)
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}
let hide = () => {};
  const onFinish = values => {
    const hide = message.loading('İşleminiz gerçekleştiriliyor..', 0);
    if(values.password !== values.confirm){
     
        NotSamePasswordWarning('error')
        setLoading(false)
        hide()
    }else{
            axios.post(`${config.apiUrl}/auth/NewPassword`, { 
              PsrGuid:props.match.params.psrGuid,
               Password:values.password
             },
             {})
             .then(res => {
                if(res.status==200){
                    openNotificationWithIcon('success');
                    hide()
                    setTimeout(function () {
                      history.push('/signin')
                      
                     }.bind(this), 2000)
                     }
                 }).catch(
                  error => {
                    if(error.response.status === 406){
                      error.response.data.data.map((item)=>{
                        if(errorList.includes(item) == false){
                          errorList.push(item);
                        }
                      })
                      errorListNotification('error')
                      hide()
                      setLoading(false)
                     }
                     else{
                      openNotificationWithIconWarning('error')
                      hide()
                      setLoading(false)}
                     }
              );
    }
  };
  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'Şifre güncelleme işlemi başarı ile gerçekleşti.',
        duration: 2,
    });
  };
  const NotSamePasswordWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Şifreler aynı değil.',
        duration: 2,
    });
  };
  const openNotificationWithIconWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Şifre güncelleme işlemi sırasında bir hata oluştu.',
        duration: 2,
    });
  };
  return (
    isHavePsrGuid === true ?
    <div className="gx-login-container"  >
      <div className="gx-login-content">

        <div className="gx-login-header">
          {
            Screen.screen === 0 ?
            <img src={require("assets/images/logoAdosoft.png")} alt="paratic" title="paratic" style={{width:'40%'}}/>
            :
            (
              Screen.screen === 1 ? 
              <img src={require("assets/images/imzaPosLogo.png")} alt="paratic" title="paratic" style={{width:'40%'}}/>
              :
              <img src={require("assets/images/logoBakiyem.png")} alt="paratic" title="bakiyem" style={{width:'40%'}}/>
            )
            
          }
          
        </div>
        <div className="gx-mb-4">
          <h3>Şifre Oluştur</h3>
          <p>Hesabınızın şifresini giriniz.</p>
        </div>

        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">

          <FormItem rules={ [{required: true, message: 'Lütfen şifrenizi giriniz.'}]} name="password">
            <Input className='gx-input-lineheight' type="password" placeholder="Şifrenizi Giriniz" onKeyDown={ (evt) => evt.keyCode === 32 && evt.preventDefault() }/>
          </FormItem>

          <FormItem rules={ [{required: true, message: 'Lütfen şifrenizi giriniz.'}]} name="confirm">
            <Input className='gx-input-lineheight' placeholder="Şifrenizi Tekrar Giriniz" type="password" onKeyDown={ (evt) => evt.keyCode === 32 && evt.preventDefault() }/>
          </FormItem>
        <Row>
          <Col span={12}>
          <FormItem>
            <Button type="primary" icon={<CheckOutlined />} htmlType="submit"  onClick={() => setLoading(true)} loading={loading}>
              Oluştur
            </Button>
                  </FormItem>
          </Col>
          <Col span={12}>
          <a href="/signin">
                      Giriş Sayfasına Dön
                    </a>
          </Col>
        </Row>
        </Form>
      </div>
    </div>
    :
    <div>
                <Result
                    status="404"
                    title="404"
                    subTitle="Üzgünüm, böyle bir kullanıcı bulamadım."
                    extra={<Button type="primary" href='/signin'> Giriş Sayfasına Dön</Button>}
                />
            </div>
  );
};

export default NewPassword;
