import React, { Component } from 'react'
import {Row,Col,Button} from 'antd'
import {
    BarsOutlined,
    UnorderedListOutlined
  } from "@ant-design/icons";
const TableTransactionRole = () => {
        return (
            <div>
                <Row>
                    <Col style={{paddingBottom:60}} xl={24} lg={24} md={24} sm={12} xs={24}>
                    <p style={{ fontSize: "120%" }}>
                        <BarsOutlined /> TABLO PARAMETRELERİ
                    </p>
                    </Col>
                    <Col style={{textAlign:'center'}} xl={6} lg={6} md={6} sm={12} xs={12}>
                    <a href="/account-transaction-role"><Button type="primary"><UnorderedListOutlined /> Hesap Hareketleri</Button></a>
                    </Col>
                </Row>
            </div>
        )
}
export default TableTransactionRole