import React from "react";

import { Row, Col } from "antd";
import DbsNotPaidInvoiceTotal from "../HomeComponent/Dbs/DbsNotPaidInvoiceTotal";
import DbsNotPaidInvoiceTotalCurrentMonth from "../HomeComponent/Dbs/DbsNotPaidInvoiceTotalCurrentMonth";
import DbsNotPaidInvoiceTotalToday from "../HomeComponent/Dbs/DbsNotPaidInvoiceTotalToday";
import DbsNotPaidInvoiceCount from "../HomeComponent/Dbs/DbsNotPaidInvoiceCount";

import DbsBankSummaryReport from "../HomeComponent/Dbs/DbsBankSummaryReport";
import DbsBankVolumeReport from "../HomeComponent/Dbs/DbsBankVolumeReport";
import DbsDealerTop10HighestLimitReport from "../HomeComponent/Dbs/DbsDealerTop10HighestLimitReport";
import DbsDealerTop10VolumeReport from "../HomeComponent/Dbs/DbsDealerTop10VolumeReport";

const DbsHome = (props) => {
  return (
    <>
      <Row>
        <DbsNotPaidInvoiceTotal />
        <DbsNotPaidInvoiceTotalCurrentMonth />
        <DbsNotPaidInvoiceTotalToday />
        <DbsNotPaidInvoiceCount />
      </Row>
      <Row>
        <Col md={24} lg={12}>
          <DbsBankSummaryReport />
        </Col>
        <Col md={24} lg={12}>
          <DbsBankVolumeReport />
        </Col>
      </Row>
      <Row>
        <Col md={24} lg={12}>
          <DbsDealerTop10HighestLimitReport />
        </Col>
        <Col md={24} lg={12}>
          <DbsDealerTop10VolumeReport />
        </Col>
      </Row>
    </>
  );
};
export default DbsHome;