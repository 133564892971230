import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Table, Empty, Popconfirm, Row, Drawer, Col, Tooltip } from "antd";
import TransactionBankInfoAddForm from "../Forms/TransactionBankInfoAddForm";
import TransactionBankInfoUpdateForm from "../Forms/TransactionBankInfoUpdateForm";

import {
  DeleteOutlined,
  RightSquareOutlined,
  EditOutlined,
} from "@ant-design/icons";
import RequestHandler from "../../util/RequestHandler";

const BankInfo = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
  });

  const [addFormVisibility, setAddFormVisibility] = useState(false);
  const [updateFormVisibility, setUpdateFormVisibility] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({});
  const [demoInformation, setDemoInformation] = useState(false);

  const history = useHistory();

  useEffect(() => {
    //ekli hesap yoksa add form aç
    RequestHandler.get(`/Tenant/GetById/${localStorage.getItem("tenantId").replace('"','').replace('"','').toString()}`).then(
      (res) => {
            if(res.isDemo){
              setDemoInformation(true)
            }else{
              RequestHandler.get("/TransactionBankInfo/IsHaveTransactionBankInfo").then(
                (response) => {
                  if (!response) {
                    setAddFormVisibility(true);
                  }
                }
              );
            }
          }
        );
    
  }, []);

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  useEffect(() => {
    if (props.addButtonEvent > 0) {
      setAddFormVisibility(true);
    }
  }, [props.addButtonEvent]);

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    const { skip, take } = tableDataQueryOptions;

    RequestHandler.get(
      `/TransactionBankInfo?$count=true&$skip=${skip}&$top=${take}`
    ).then((response) => {
      setTableData({
        data: response.value,
        total: response["@odata.count"],
        loading: false,
      });
    });
  };

  const getUpdateFormData = (id) => {
    RequestHandler.get(`/TransactionBankInfo/GetById/${id}`).then(
      (response) => {
        if (response) {
          setUpdateFormData(response);
          setUpdateFormVisibility(true);
        }
      }
    );

    setUpdateFormVisibility(true);
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const goAccountsPage = (bankId, bankInfoId) => {
    history.push(`transaction-bank-account-info/${bankId}/${bankInfoId}`);
  };

  const handleOnClickDelete = (id) => {
    RequestHandler.delete(`/TransactionBankInfo?id=${id}`).then((response) => {
      if (response.success) {
        getTableData();
      }
    });
  };

  const onAddFormSuccess = () => {
    getTableData();
    setAddFormVisibility(false);
  };

  const onUpdateFormSuccess = () => {
    getTableData();
    setUpdateFormVisibility(false);
  };

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      render: (data, row, index) => (
        <img style={{ width: 120 }} src={data}></img>
      ),
    },
    {
      title: "Kullanıcı Adı",
      dataIndex: "userName",
    },
    {
      title: "Hesaplar",
      dataIndex: "operation",
      align: "center",
      render: (text, record) =>
        record.isHaveTransactionInfoAccount === true ? (
          <Tooltip placement="topLeft" title="Hesaplara Git">
            <a style={{ color: "#3598dc", fontSize: "170%" }}>
              <RightSquareOutlined
                onClick={() => goAccountsPage(record.bankId, record.id)}
              />
            </a>
          </Tooltip>
        ) : (
          <Tooltip placement="topLeft" title="Hesap Kaydı Yapılamaz">
            <a style={{ color: "#3598dc", fontSize: "170%", opacity: "0.3" }}>
              <RightSquareOutlined disabled />
            </a>
          </Tooltip>
        ),
    },
    {
      title: "Düzenle",
      dataIndex: "id",
      width: "1%",
      align: "center",
      render: (data, row) => (
        <a onClick={() => getUpdateFormData(data)}>
          <EditOutlined style={{ color: "orange", fontSize: "130%" }} />
        </a>
      ),
    },
    {
      title: "Sil",
      dataIndex: "id",
      width: "1%",
      align: "center",
      render: (data, row) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleOnClickDelete(data)}
        >
          <a>
            <DeleteOutlined style={{ color: "red", fontSize: "130%" }} />
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Drawer
        id="addDrawer"
        title="Banka Ekle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setAddFormVisibility(false)}
        visible={addFormVisibility}
      >
        <TransactionBankInfoAddForm demoInformation={demoInformation} onSuccess={onAddFormSuccess} />
      </Drawer>

      <Drawer
        id="updateDrawer"
        title="Banka Güncelle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setUpdateFormVisibility(false)}
        visible={updateFormVisibility}
      >
        <TransactionBankInfoUpdateForm
          data={updateFormData}
          onSuccess={onUpdateFormSuccess}
        />
      </Drawer>
    </>
  );
};

export default BankInfo;
