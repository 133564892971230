import React, { useState, useEffect } from "react";
import Widget from "components/Widget/index";
import { Row, Col, Table, Empty } from "antd";
import RequestHandler from "util/RequestHandler";
import CurrencyFormat from "react-currency-format";
import moment from "moment";

const DbsBankVolumeReport = (props) => {
  const [tableData, setTableData] = useState({
    loading: true,
  });

  useEffect(() => getData(), []);

  const getData = () => {
    RequestHandler.get("/DbsDashboard/BankVolumeReport").then((response) => {
      setTableData({
        data: response.data,
        loading: false,
      });
    });
  };

  const renderCurrencyFormat = (data) => (
    <CurrencyFormat
      value={data}
      displayType={"text"}
      decimalSeparator={","}
      thousandSeparator={"."}
      prefix={"₺"}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  )

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      render: (text, row, index) => {
        return (
          <div style={{ width: 100 }}>
            <img src={text}></img>
          </div>
        );
      },
    },
    {
      title: "Son 12 Ay Toplam",
      dataIndex: "total",
      render: renderCurrencyFormat,
    },
    {
      title: "Bugün",
      dataIndex: "today",
      render: renderCurrencyFormat,
    },
    {
      title: moment().format("MMMM YY"),
      dataIndex: ["xMonthAgoTotal", "0"],
      render: renderCurrencyFormat,
    },
    {
      title: moment().add(-1, "month").format("MMMM YY"),
      dataIndex: ["xMonthAgoTotal", "1"],
      render: renderCurrencyFormat,
    },
    {
      title: moment().add(-2, "month").format("MMMM YY"),
      dataIndex: ["xMonthAgoTotal", "2"],
      render: renderCurrencyFormat,
    },
  ];
  return (
    <>
      <Widget
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0">Banka Ciro Raporu</h2>
        }
        extra={
          <p className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
            <a href="/bank-volume-report-detail">Detay</a>{" "}
            <i className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle" />
          </p>
        }
      >
        <Row id="tableArea">
          <Col span={24}>
            <Table
              columns={columns}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Veri Yok"
                  />
                ),
              }}
              style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
              rowKey="bankId"
              filterable={false}
              sortable={false}
              pageable={false}
              pagination={false}
              dataSource={tableData.data}
              loading={tableData.loading}
              size="small"
            />
          </Col>
        </Row>
      </Widget>
    </>
  );
};

export default DbsBankVolumeReport;
