import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import RequestHandler from "util/RequestHandler";
import { Col, Row, Modal, Spin } from "antd";
import {
  ExclamationCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import AccountTransaction from "../HomeComponent/Act/AccountTransaction";
import Widgets from "../HomeCards/Card";
import config from "util/ApiforContext";
import Replace2x from "util/Extensions/ReplaceExtension";
import SubscriberFirmCollectAndPaymentPlanReport from "../HomeComponent/SubscriberFirm/SubscriberFirmCollectAndPaymentPlanReport";

const SubscriberFirmHome = (props) => {
  const [totalPlan, setTotalPlan] = useState(0);
  const [totalCollectAccount, setTotalCollectAccount] = useState(0);
  const [totalSubscriberFirmCount, setTotalSubscriberFirmCount] = useState(0);
  const [totalCurrencyValue, setTotalCurrencyValue] = useState(0);
  const [visibleModal, setVisibleModal] = useState(false);
  const [permissions, setPermissions] = useState({
    loading: true,
    modules: [],
    isAdmin: false,
    defaultModuleId: null,
  });

  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const tenantId = Replace2x(localStorage.getItem("tenantId"));
  const token = Replace2x(localStorage.getItem("token"));
  const email = Replace2x(localStorage.getItem("email"));

  function boughtEndTimeParatic() {
    window.location.href =
      config.demoUrl +
      "Paketler?vk=" +
      tenantId +
      "&eMail=" +
      email +
      "&t=" +
      token;
  }
  function confirm() {
    Modal.confirm({
      title: "Demo Süreniz Sona Erdi",
      icon: <ClockCircleOutlined style={{ color: "darkred" }} />,
      content:
        "İşletmenize uygun paketi seçerek giriş bilgileriniz değişmeden Paratic'i kullanmaya devam edebilirsiniz.",
      okText: "Paket Seç",
      onOk: boughtEndTimeParatic,
      keyboard: false,
      cancelButtonProps: { hidden: true },
    });
  }

  useEffect(() => {
    //modüller yüklenmediyse istek atma

    if (permissions.loading) {
      return;
    }

    setTimeout(() => {
      setLoading(false);
    }, 5000);

    RequestHandler.get(
      `/Tenant/GetById/${localStorage
        .getItem("tenantId")
        .replace('"', "")
        .replace('"', "")
        .toString()}`
    ).then((res) => {
      var effectiveDate = new Date(res.effectiveDate).getTime();
      var nowDate = Date.now();
      RequestHandler.get(
        `/User/GetById/${localStorage
          .getItem("userId")
          .replace('"', "")
          .replace('"', "")
          .toString()}`
      ).then((resp) => {
        if (nowDate > effectiveDate && res.isDemo) {
          confirm();
        } else if (res.isDemo && !resp.isLogin) {
          setVisibleModal(true);
          RequestHandler.get(
            "/WebSite/DeleteLastLogin?email=" +
              Replace2x(localStorage.getItem("email")) +
              "&changeIsLogin=true"
          ).then((res) => {});
        } else {
          if (permissions.isAdmin) {
            RequestHandler.get(
              "/TransactionBankInfo/IsHaveTransactionBankInfo"
            ).then((resInfo) => {
              if (!resInfo && !res.isDemo) {
                Modal.confirm({
                  title: "Uyarı!",
                  icon: <ExclamationCircleOutlined />,
                  content:
                    "Banka tanımlamalarınız yapılmamıştır. Lütfen tanımlama yapınız.",
                  okText: "Tamam",
                  cancelText: "Vazgeç",
                  onOk() {
                    history.push("/transaction-bank-info");
                  },
                });
              }
            });
          }
        }
      });
    });

    RequestHandler.get(
      "/TenantCurrency?$apply=groupby((Currency,CurrencyId))&$orderby=currencyId"
    ).then((res) => {
      if (res.length !== 0) {
        RequestHandler.get(
          `/Dashboard/GetTotalPlanAndCollectByMonth/${res[0]?.currencyId ?? 0}`
        ).then((res) => {
          setTotalPlan(res.collectPlanTotal);
          setTotalCollectAccount(res.collectTotalAccount);
          setLoading(false);
        });
      }
    });

    RequestHandler.get("/SubscriberFirm?$count=true").then((res) => {
      setTotalSubscriberFirmCount(res["@odata.count"]);
    });

    RequestHandler.get("/Dashboard/GetListForTotal").then((res) => {
      var tenantTotalList = [];
      var total = 0;
      res.map((item) => {
        var model = {
          bankLogo: item.bankLogo,
          totalBalance: item.balance,
          bankName: item.bankName,
          currency: item.tenantList,
        };
        tenantTotalList.push(model);
        total += item.balance;
      });
      var model = {
        bankLogo: "TOPLAM",
        balance: parseFloat(total.toFixed(2)),
      };
      tenantTotalList.push(model);
      setTotalCurrencyValue(parseFloat(total.toFixed(2)));
    });
  }, [
    permissions.loading,
  ]);

  useEffect(() => {
    if (!props.permissions) {
      getUserPermissions();
    } else {
      setPermissions(props.permissions);
    }
  }, [props.permissions]);

  const getUserPermissions = () => {
    setPermissions({
      ...permissions,
      loading: false,
    });
    RequestHandler.get("/User/MenuPermissions").then((response) => {
      setPermissions({
        ...response,
        loading: false,
      });
      localStorage.setItem("defaultModuleId", response.defaultModuleId);
    });
  };

  const handleOkModal = () => {
    setVisibleModal(false);
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const monthNames = [
    "OCAK",
    "ŞUBAT",
    "MART",
    "NİSAN",
    "MAYIS",
    "HAZİRAN",
    "TEMMUZ",
    "AĞUSTOS",
    "EYLÜL",
    "EKİM",
    "KASIM",
    "ARALIK",
  ];
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1130px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const date = new Date();

  return (
    <>
      {isDesktopOrLaptop ? (
        <Spin spinning={loading}>
          <Row style={{ marginLeft: 2 }}>
            <Col span={24}>
              <div class="ant-row">
                <Widgets
                  icon="home"
                  currency={""}
                  title="TOPLAM BAKİYE"
                  totalBalance={
                    <CurrencyFormat
                      value={totalCurrencyValue}
                      displayType={"text"}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      prefix={"₺"}
                    />
                  }
                />
                <Widgets
                  icon="long-arrow-up"
                  color="green"
                  title={`${
                    monthNames[date.getMonth()]
                  } AYI PLANLANAN TAHSİLAT`}
                  currency={"TRY"}
                  totalBalance={
                    <CurrencyFormat
                      value={totalPlan}
                      displayType={"text"}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      prefix={" "}
                    />
                  }
                />
                <Widgets
                  icon="long-arrow-down"
                  totalBalance={
                    <CurrencyFormat
                      value={totalCollectAccount}
                      displayType={"text"}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      prefix={" "}
                    />
                  }
                  currency={"TRY"}
                  title={`${
                    monthNames[date.getMonth()]
                  } AYI GERÇEKLEŞEN TAHSİLAT`}
                  color="red"
                />
                <Widgets
                  icon="data-entry"
                  totalBalance={totalSubscriberFirmCount}
                  s
                  title={`TOPLAM DAİRE SAYISI`}
                />
              </div>
            </Col>
            <Col span={12} style={{ marginTop: 20, marginBottom: 20 }}>
              <AccountTransaction />
            </Col>
            <Col span={12} style={{ marginTop: 20, marginBottom: 20 }}>
              <SubscriberFirmCollectAndPaymentPlanReport />
            </Col>
          </Row>
        </Spin>
      ) : (
        <Row>
          <Col span={24}>
            <div class="ant-row">
              <Widgets
                icon="home"
                currency={""}
                title="TOPLAM BAKİYE"
                totalBalance={
                  <CurrencyFormat
                    value={totalCurrencyValue}
                    displayType={"text"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    prefix={"₺"}
                  />
                }
              />
              <Widgets
                icon="long-arrow-up"
                color="green"
                title={`${monthNames[date.getMonth()]} AYI PLANLANAN TAHSİLAT`}
                currency={"TRY"}
                totalBalance={
                  <CurrencyFormat
                    value={totalPlan}
                    displayType={"text"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    prefix={" "}
                  />
                }
              />
              <Widgets
                icon="long-arrow-down"
                totalBalance={
                  <CurrencyFormat
                    value={totalCollectAccount}
                    displayType={"text"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    prefix={" "}
                  />
                }
                currency={"TRY"}
                title={`${
                  monthNames[date.getMonth()]
                } AYI GERÇEKLEŞEN TAHSİLAT`}
                color="red"
              />
              <Widgets
                icon="data-entry"
                totalBalance={totalSubscriberFirmCount}
                s
                title={`TOPLAM DAİRE SAYISI`}
              />
            </div>
          </Col>
          <Col
            span={24}
            style={{
              marginBottom: 20,
              maxWidth: isTablet ? "95%" : "100%",
              marginLeft: isTablet ? 12 : 0,
            }}
          >
            <AccountTransaction />
          </Col>
          <Col
            span={24}
            style={{
              marginBottom: 20,
              maxWidth: isTablet ? "95%" : "100%",
              marginLeft: isTablet ? 12 : 0,
            }}
          >
            <SubscriberFirmCollectAndPaymentPlanReport />
          </Col>
        </Row>
      )}
      <Modal
        visible={visibleModal}
        onOk={handleOkModal}
        cancelButtonProps={{ hidden: true }}
        okText="Deneyelim!"
      >
        <p>Hoşgeldiniz ! Sizleri burada görmekten çok memnunuz.</p>
        <p>
          Sizin için oluşturduğumuz demo ekranlarımızı <strong>5 gün</strong>{" "}
          boyunca inceleyebilirsiniz.
        </p>
        <p>
          Finekra Üye Paneli ile üyelerinize tahsilatlar tanımlayabilir,
          üyelerin aylık ödemelerini takip edebilirsiniz. Ayriyetten Finekra tek
          ekranda tüm banka hareketlerinizi sunar ve muhasebe programınıza
          entegre olarak aktarım yapabilmenizi sağlar.
        </p>
        <p>-Ayarlar/Hesap Erişimi Ekranından tanım formunu edinin</p>
        <p>-Tanım formunu şubenize iletip bankadan bilgilerinizi alın</p>
        <p>-Kolayca Bilgilerinizi girip kullanmaya başlayın.</p>
        <p>
          Pos Rapor , Toplu Ödeme, DBS gibi diğer ürünlerimiz hakkında bilgi
          almak için buraya tıklayın, size ulaşalım.
        </p>
      </Modal>
    </>
  );
};
export default SubscriberFirmHome;
