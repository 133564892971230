import React, {useState,useEffect} from "react";
import {Layout} from "antd";
import {Link} from "react-router-dom";
import { Menu, Dropdown, Button, Tooltip,Avatar,Popover } from 'antd';
import { AlignLeftOutlined, UserOutlined,DownOutlined,AliwangwangOutlined } from '@ant-design/icons';
import {toggleCollapsedSideNav} from "../../appRedux/actions/Setting";
import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import {useDispatch, useSelector} from "react-redux";
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import UserInfo from "components/UserInfo";
import config from "../../util/ApiforContext"
import { useMediaQuery } from 'react-responsive'
import Auxiliary from "util/Auxiliary";
const token = localStorage.getItem("token");
const tenantId = localStorage.getItem("tenantId");
const userId = localStorage.getItem("userId");
const {Header} = Layout;
const text = <span>Destek Paneli</span>;
function handleButtonClick(e) {
}

const Topbar = () => {
  const history = useHistory();
  const [tenantAccounts, setTenantAccounts] = useState([]);
  const [currentTenantAccount, setcurrentTenantAccount] = useState("");
  const [isGroupUser, setIsGroupUser] = useState(false);
  const [accessSupportPanel, setAccessSupportPanel] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  function handleMenuClick(e) {
    if (e.key == "konsolide") {
      history.push(`/consolidated-bank`);
    } else {
      axios
        .get(
          `${config.apiUrl}/Auth/ChangeTenant/${userId
            .replace('"', "")
            .replace('"', "")
            .toString()}/${e.key}`,
          {
            headers: {
              Authorization:
                "bearer " + token.replace('"', "").replace('"', "").toString(),
            },
          }
        )
        .then(({ data }) => {
          const expiration = new Date(data.expiration).getTime();
          localStorage.removeItem("userId");
          localStorage.removeItem("tenantId");
          localStorage.removeItem("token");
          localStorage.removeItem("expiration");
          localStorage.removeItem("userName");
          localStorage.setItem("userId", JSON.stringify(data.userId));
          localStorage.setItem("tenantId", JSON.stringify(data.tenantId));
          localStorage.setItem("expiration", JSON.stringify(expiration));
          localStorage.setItem("token", JSON.stringify(data.token));
          localStorage.setItem("userName", JSON.stringify(data.userName));
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + data.token;
          history.push(`/home`);
          window.location.reload();
        })
        .catch(function (error) {});
    }
  }
  useEffect(() => {
    axios
    .get(
      `${config.apiUrl}/SupportPanel/AccessControl`,
      {
        headers: {
          Authorization:
            "bearer " + token.replace('"', "").replace('"', "").toString(),
        },
      }
    )
    .then((res) => {
      setAccessSupportPanel(res.data);
    });

    axios
      .get(
        `${config.apiUrl}/User/GetUserTenants/${userId
          ?.replace('"', "")
          .replace('"', "")
          .toString()}`,
        {
          headers: {
            Authorization:
              "bearer " + token.replace('"', "").replace('"', "").toString(),
          },
        }
      )
      .then((res) => {
        setTenantAccounts(res.data);
        {
          res.data.map((tenantAccounts, index) => {
            if (
              tenantAccounts.id ===
              tenantId?.replace('"', "").replace('"', "").toString()
            ) {
              setcurrentTenantAccount(tenantAccounts.name);
            }
          });
        }
      });
    axios
      .get(`${config.apiUrl}/User/IsGroupUser`, {
        headers: {
          Authorization:
            "bearer " + token.replace('"', "").replace('"', "").toString(),
        },
      })
      .then((res) => {
        setIsGroupUser(res.data);
      });
  }, [setTenantAccounts]);
  const { locale, width, navCollapsed, navStyle } = useSelector(
    ({ settings }) => settings
  );
  const { searchText, setSearchText } = useState("");
  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
       {/* <li style={{textAlign:'center'}}>
        <a style={{ color: "#545454" }} href="/support-panel/2">
          Destek Taleplerim
        </a>
      </li>       */}
    </ul>
  );

  const menu = (
    <Menu onClick={handleMenuClick}>
      {tenantAccounts.map((item, index) => (
        <Menu.Item key={item.id} icon={<UserOutlined />}>
          {item.name}
        </Menu.Item>
      ))}
      {
         isGroupUser === true ?   <Menu.Item key="konsolide" icon={<AlignLeftOutlined />}>
         Konsolide
       </Menu.Item>
       :null
      }
    </Menu>
  );
  return (
      <ul className="gx-header-notifications gx-ml-auto" >
         <Dropdown onClick={handleButtonClick} overlay={menu}>
            <Button  type="primary" style={{marginTop:19}} >
          {isTabletOrMobileDevice ? null : currentTenantAccount }   <DownOutlined />
            </Button>
          </Dropdown>
    {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-user-nav"></li>
          </Auxiliary>
        }
         {/* {
          accessSupportPanel ?
          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={userMenuOptions}
            trigger="click"
          >
             <Avatar className="gx-avatar-name" style={{backgroundColor:'white'}} icon={<AliwangwangOutlined style={{color:'#086880'}}/>} />
          </Popover> 
          : null
        }  */}
         
        
      </ul>
  );
};

export default Topbar;
