import React, { useState, useEffect } from "react";
import RequestHandler from "util/RequestHandler";
import moment from "moment";
import DbsValueReportTableFilter from "../../Filters/DbsValueReportTableFilter";
import { ColumnHelper } from "util/TableHelper";

import { Table, Empty, Col, Row } from "antd";

const detailTableGroupedByBank = (data) => {
  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      render: (text) => <img src={text} style={{ maxWidth: 100 }}></img>,
    },
    {
      title: "Fatura Toplamı",
      dataIndex: "amount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Tahsil Edilen Toplam",
      dataIndex: "collectedAmount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Kalan",
      dataIndex: "remainingAmount",
      render: ColumnHelper.renderDecimal,
    },
  ];
  return (
    <Table
      columns={columns}
      locale={{
        emptyText: (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" />
        ),
      }}
      expandable={{
        expandedRowRender: (row) => detailTableGroupedByDealer(row.details),
      }}
      rowKey="bankId"
      filterable={true}
      sortable={true}
      pagination={false}
      dataSource={data}
      size="small"
    />
  );
};

const detailTableGroupedByDealer = (data) => {
  const columns = [
    {
      title: "Bayi Kodu",
      dataIndex: "dbsDealerCode",
    },
    {
      title: "Bayi Adı",
      dataIndex: "dbsDealerName",
    },
    {
      title: "Fatura Toplamı",
      dataIndex: "amount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Tahsil Edilen Toplam",
      dataIndex: "collectedAmount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Kalan",
      dataIndex: "remainingAmount",
      render: ColumnHelper.renderDecimal,
    },
  ];
  return (
    <Table
      columns={columns}
      locale={{
        emptyText: (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" />
        ),
      }}
      rowKey="dbsDealerId"
      filterable={true}
      sortable={true}
      pagination={false}
      dataSource={data}
      size="small"
    />
  );
};

const DbsValueReportMonthly = (props) => {
  const defaultQueryData = {
    pageSize: 15,
  };
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] =
    useState(defaultQueryData);

  useEffect(
    () => getTableData(),
    [
      tableDataQueryOptions.bankId,
      tableDataQueryOptions.startDate,
      tableDataQueryOptions.endDate,
    ]
  );

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    RequestHandler.post(
      `/DbsReport/MonthlyValueReport`,
      tableDataQueryOptions,
      false
    ).then((response) => {
      setTableData({
        data: response.value,
        total: response.totalCount,
        totalRow: response.totalRow,
        loading: false,
      });
    });

    if (props.onFilterChange) {
      props.onFilterChange(tableDataQueryOptions);
    }
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        pageSize: p.pageSize,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions(defaultQueryData);
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        ...query,
      };
    });
  };

  const columns = [
    {
      title: "Vade Tarihi",
      dataIndex: "date",
      render: (data) => moment(data).format("MMMM YYYY"),
    },
    {
      title: "Fatura Toplamı",
      dataIndex: "amount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Tahsil Edilen Toplam",
      dataIndex: "collectedAmount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Kalan",
      dataIndex: "remainingAmount",
      render: ColumnHelper.renderDecimal,
    },
  ];

  return (
    <>
      <DbsValueReportTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
        monthly={true}
        extraText="Varsayılan olarak 2 ay önceden itibaren 8 aylık veri
        listelenmektedir."
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            expandable={{
              expandedRowRender: (row) =>
                detailTableGroupedByBank(row.details),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey={"date"}
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.pageSize,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
            summary={() => {
              if (!tableData.totalRow) return;

              const { amount, collectedAmount, remainingAmount } = tableData.totalRow;
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>Toplam:</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {ColumnHelper.renderDecimal(amount)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {ColumnHelper.renderDecimal(collectedAmount)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {ColumnHelper.renderDecimal(remainingAmount)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default DbsValueReportMonthly;
