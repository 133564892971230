import React, { useState, useEffect } from "react";
import RequestHandler from "util/RequestHandler";
import moment from "moment";
import DailyReportTableFilter from "../Filters/DailyReportTableFilter";
import { ColumnHelper } from "util/TableHelper";
import { Table, Empty, Col, Row, Tooltip, message } from "antd";
import { BarsOutlined, FileExcelOutlined,MenuUnfoldOutlined } from "@ant-design/icons";
import config from "../../util/ApiforContext";
import { useHistory } from "react-router-dom";
const DailyBalanceReport = (props) => {
const history = useHistory();

  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
    startDate: null,
    endDate:null
  });

  useEffect(() => getTableData(), [tableDataQueryOptions]);
  const detailTableBankAccounts = (data) => {
    const columns = [
      {
        title: "Banka",
        dataIndex: "bankLogo",
        key: "bankLogo",
        render: (text, row, index) => {
          return (
            <div style={{ width: 100 }}>
              {" "}
              <img src={text}></img>
            </div>
          );
        },
      },
      {
        title: "Bakiye",
        dataIndex: "amount",
        key: "amount",
        align: "center",
        render: ColumnHelper.renderDecimal,
      },
      {
        title: "Hesap Detay",
        dataIndex: "operation",
        align: "center",
        render: (text, record) =>
            <a
              onClick={() => {
                GoDailyAccountReport(record.bankId,moment(record.transactionDate).format("DD.MM.YYYY"));
              }}
              style={{ fontSize: "130%", color: "red" }}
            >
              <MenuUnfoldOutlined />
            </a>
      },
    ];
    return (
      <Table
        columns={columns}
        locale={{
          emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" />
          ),
        }}
        rowKey={(record) => record.BankId + "-" + record.memberWorkplaceNumber}
        filterable={true}
        sortable={true}
        pagination={false}
        dataSource={data}
        size="small"
      />
    );
  };
  const GoDailyAccountReport = (value,date) => {
    history.push(`monthly-report-account/${value}/${date}`);
  };
  const getFilterQuery = () => {
    const { filter } = tableDataQueryOptions;

    const conditions = [];
    let query = "";

    if (filter) {
      conditions.push(filter);
    }
    if (conditions.length !== 0) {
      query = "$filter=" + conditions.join(" and ");
    }
    return query;
  };

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    const { skip, take } = tableDataQueryOptions;
  
    if((tableDataQueryOptions.endDate != null || tableDataQueryOptions.endDate != undefined) && tableDataQueryOptions.bankFilterId && ((tableDataQueryOptions.amountMin != null || tableDataQueryOptions.amountMin != undefined )|| (tableDataQueryOptions.amountMax != null || tableDataQueryOptions.amountMax != undefined))){
      RequestHandler.get(
        `/DailyBalanceReport?isBankMonthly=true&bankFilterId=${tableDataQueryOptions.bankFilterId}&isOdataQuery=true
        &startDate=${moment(tableDataQueryOptions.startDate).format("DD.MM.YYYY")}
        &endDate=${moment(tableDataQueryOptions.endDate).format("DD.MM.YYYY")}
        ${tableDataQueryOptions.amountMin !== null ? "&amountMin=" + tableDataQueryOptions.amountMin : ""}
        ${tableDataQueryOptions.amountMax !== null ? "&amountMax=" + tableDataQueryOptions.amountMax : ""}
        &${getFilterQuery()}&$count=true&$skip=${skip}&$top=${take}`
      ).then((response) => {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      });
    } 
    else if((tableDataQueryOptions.endDate != null || tableDataQueryOptions.endDate != undefined) && tableDataQueryOptions.bankFilterId && ((tableDataQueryOptions.amountMin != null || tableDataQueryOptions.amountMin != undefined )|| (tableDataQueryOptions.amountMax != null || tableDataQueryOptions.amountMax != undefined))){
      RequestHandler.get(
        `/DailyBalanceReport?isBankMonthly=true
        ${tableDataQueryOptions.amountMin !== null ? "&amountMin=" + tableDataQueryOptions.amountMin : ""}
        ${tableDataQueryOptions.amountMax !== null ? "&amountMax=" + tableDataQueryOptions.amountMax : ""}
        &bankFilterId=${tableDataQueryOptions.bankFilterId}&isOdataQuery=true&startDate=${moment(tableDataQueryOptions.startDate).format("DD.MM.YYYY")}&endDate=${moment(tableDataQueryOptions.endDate).format("DD.MM.YYYY")}&$count=true&$skip=${skip}&$top=${take}`
      ).then((response) => {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      });
    }
    else if((tableDataQueryOptions.endDate != null || tableDataQueryOptions.endDate != undefined) && tableDataQueryOptions.bankFilterId){
      RequestHandler.get(
        `/DailyBalanceReport?isBankMonthly=true&bankFilterId=${tableDataQueryOptions.bankFilterId}&isOdataQuery=true&startDate=${moment(tableDataQueryOptions.startDate).format("DD.MM.YYYY")}&endDate=${moment(tableDataQueryOptions.endDate).format("DD.MM.YYYY")}&$count=true&$skip=${skip}&$top=${take}`
      ).then((response) => {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      });
    }
    else if((tableDataQueryOptions.endDate != null || tableDataQueryOptions.endDate != undefined) && ((tableDataQueryOptions.amountMin != null || tableDataQueryOptions.amountMin != undefined )|| (tableDataQueryOptions.amountMax != null || tableDataQueryOptions.amountMax != undefined)))
    {
      RequestHandler.get(
        `/DailyBalanceReport?isBankMonthly=true&isOdataQuery=true
        ${tableDataQueryOptions.amountMin !== null ? "&amountMin=" + tableDataQueryOptions.amountMin : ""}
        ${tableDataQueryOptions.amountMax !== null ? "&amountMax=" + tableDataQueryOptions.amountMax : ""}
        &startDate=${moment(tableDataQueryOptions.startDate).format("DD.MM.YYYY")}
        &endDate=${moment(tableDataQueryOptions.endDate).format("DD.MM.YYYY")}&$count=true&$skip=${skip}&$top=${take}`
      ).then((response) => {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      });
    }
    else if((tableDataQueryOptions.endDate != null || tableDataQueryOptions.endDate != undefined))
    {
      RequestHandler.get(
        `/DailyBalanceReport?isBankMonthly=true&isOdataQuery=true&startDate=${moment(tableDataQueryOptions.startDate).format("DD.MM.YYYY")}&endDate=${moment(tableDataQueryOptions.endDate).format("DD.MM.YYYY")}&$count=true&$skip=${skip}&$top=${take}`
      ).then((response) => {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      });
    }
    else if(tableDataQueryOptions.bankFilterId != null && ((tableDataQueryOptions.amountMin != null || tableDataQueryOptions.amountMin != undefined )|| (tableDataQueryOptions.amountMax != null || tableDataQueryOptions.amountMax != undefined))){
      RequestHandler.get(
        `/DailyBalanceReport?isBankMonthly=true&bankFilterId=${tableDataQueryOptions.bankFilterId}
        ${tableDataQueryOptions.amountMin !== null ? "&amountMin=" + tableDataQueryOptions.amountMin : ""}
        ${tableDataQueryOptions.amountMax !== null ? "&amountMax=" + tableDataQueryOptions.amountMax : ""}
        &isOdataQuery=true&$count=true&$skip=${skip}&$top=${take}`
      ).then((response) => {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      });
    }
    else if(tableDataQueryOptions.bankFilterId != null){
      RequestHandler.get(
        `/DailyBalanceReport?isBankMonthly=true&bankFilterId=${tableDataQueryOptions.bankFilterId}&isOdataQuery=true&$count=true&$skip=${skip}&$top=${take}`
      ).then((response) => {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      });
    }
   
    else if((tableDataQueryOptions.amountMin != null || tableDataQueryOptions.amountMin != undefined )|| (tableDataQueryOptions.amountMax != null || tableDataQueryOptions.amountMax != undefined)){
      RequestHandler.get(
        `/DailyBalanceReport?isBankMonthly=true
        ${tableDataQueryOptions.amountMin !== null ? "&amountMin=" + tableDataQueryOptions.amountMin : ""}
        ${tableDataQueryOptions.amountMax !== null ? "&amountMax=" + tableDataQueryOptions.amountMax : ""}
        &isOdataQuery=true&$count=true&$skip=${skip}&$top=${take}`
      ).then((response) => {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      });
    }
    else{
      RequestHandler.get(
        `/DailyBalanceReport?isBankMonthly=true&$count=true&${getFilterQuery()}&$skip=${skip}&$top=${take}`
      ).then((response) => {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      });
    }

  if (props.onFilterChange) {
    props.onFilterChange(tableDataQueryOptions);
  }
  };
  
  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query,model) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query,endDate: model.endDate,startDate: model.startDate,bankFilterId: model.bankId,amountMin : model.amountMin, amountMax : model.amountMax };
    });
  };
  
  const tableColumns = [
    {
      title: "Tarih",
      dataIndex: "transactionDate",
      key: "transactionDate",
      align: "center",
      render: (data, row, index) => moment(data).format("MMMM YYYY"),
    },
    {
      title: "Bakiye",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: ColumnHelper.renderDecimal,
    }
  ];

  return (
    <>
     <DailyReportTableFilter
        onClearFilter={onClearTableFilter}
        monthly={true}
        amountModel={true}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={tableColumns.filter((column) => !column.isRowDetail)}
            expandable={{
              expandedRowRender: (row) =>
                detailTableBankAccounts(row.details),
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{
              overflowX: "auto",
              maxHeight: "100%",
              width: "100%",
            }}
            rowKey={(record, index) => index}
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};

export default DailyBalanceReport;
