import React, { useState, useEffect } from "react";
import RequestHandler from "util/RequestHandler";
import moment from "moment";
import PosTransactionTableFilter from "../Filters/PosTransactionTableFilter";
import { odata } from "../Filters/Helper";
import { ColumnHelper } from "util/TableHelper";
import { useHistory } from "react-router-dom";

import { Table, Empty, Col, Row, Tooltip, message } from "antd";
import { BarsOutlined, FileExcelOutlined, RollbackOutlined } from "@ant-design/icons";
import config from "../../util/ApiforContext";

const PosTransaction = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });
  const history = useHistory();

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  const getFilterQuery = () => {
    const { filter } = tableDataQueryOptions;

    const conditions = [];
    let query = "";

    if (props.match.params.filter && !filter) {
      const [bankId, installmentCount, operationDate] = props.match.params.filter.split("&");

      conditions.push(`posBankId eq ${bankId}`);
      conditions.push(`installmentCount eq ${installmentCount}`);
      conditions.push(odata.dateRange("operationDate", moment(operationDate).startOf("month"), moment(operationDate).endOf("month")));
    }

    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length !== 0) {
      query = "$filter=" + conditions.join(" and ");
    }
    return query;
  };

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    const { skip, take } = tableDataQueryOptions;

    RequestHandler.get(
      `/PosTransaction?$count=true&${getFilterQuery()}&$skip=${skip}&$top=${take}`
    ).then((response) => {
      setTableData({
        data: response.value,
        total: response["@odata.count"],
        loading: false,
      });
    });
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };

  const renderRowDetail = ({ columns, data }) => {
    return (
      <p>
        {columns.map((column, index) => (
          <p key={index} style={{ marginBottom: ".25em" }}>
            <b>{column.title} : </b>{" "}
            {column.render
              ? column.render(data[column.dataIndex], data, index)
              : data[column.dataIndex]}
          </p>
        ))}
      </p>
    );
  };

  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);

    RequestHandler.post(
      `/PosTransaction/ExportExcel`,
      {
        query: getFilterQuery(),
      },
      false
    ).then((response) => {
      hideMessage();
      if (response) {
        window.open(
          `${config.apiUrl
          }/PosTransaction/DownloadExcel?fileName=${response}&t=${RequestHandler.getToken()}`
        );
      }
    });
  };

  const tableColumns = [
    {
      title: "POS Banka",
      dataIndex: "posBankLogo",
      width: "120px",
      render: (text) => <img src={text}></img>,
    },
    {
      title: "Üye İş Yeri Adı",
      dataIndex: "memberWorkplaceName",
    },
    {
      title: "Üye İş Yeri No",
      dataIndex: "memberWorkplaceNumber",
    },
    {
      title: "Terminal No",
      dataIndex: "terminalNumber",
    },
    {
      title: "Provizyon No",
      dataIndex: "provisionNumber",
    },
    {
      title: "İşlem Tarihi",
      dataIndex: "operationDate",
      align: "center",
      render: (data, row, index) => moment(data).format("DD.MM.YYYY HH:mm"),
    },
    {
      title: "Valör Tarihi",
      dataIndex: "valueDate",
      align: "center",
      render: (data, row, index) => moment(data).format("DD.MM.YYYY"),
    },
    {
      title: "İşlem Tutarı",
      dataIndex: "amount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Net Tutar",
      dataIndex: "netAmount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Para Birimi",
      dataIndex: "currency",
    },
    {
      title: "Komisyon Tutarı",
      dataIndex: "totalCommissionAmount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Komisyon Oranı(%)",
      dataIndex: "commissionRate",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Taksit Sayısı",
      dataIndex: "installmentCount",
    },
    {
      title: "Taksit Sırası",
      dataIndex: "installmentOrder",
    },

    {
      title: "Gün Sonu Tarihi",
      dataIndex: "endOfDayDate",
      align: "center",
      isRowDetail: true,
      render: (data, row, index) => moment(data).format("DD.MM.YYYY"),
    },
    {
      title: "Kart Numarası",
      dataIndex: "cardNumber",
      isRowDetail: true,
    },
    {
      title: "Banka Komisyon Tutarı",
      dataIndex: "bankCommissionAmount",
      isRowDetail: true,
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Diğer Komisyon Tutarı",
      dataIndex: "otherCommissionAmount",
      isRowDetail: true,
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Açıklama",
      dataIndex: "description",
      isRowDetail: true,
    },
    {
      title: "İşlem Tipi",
      dataIndex: "operationType",
      isRowDetail: true,
    },
    {
      title: "IBAN / Hesap No",
      dataIndex: "returnIban",
      isRowDetail: true,
    },
    {
      title: "Pos Tipi",
      dataIndex: "posType",
      isRowDetail: true,
    },
    {
      title: "Pos Kart Tipi",
      dataIndex: "posCardType",
      isRowDetail: true,
    },
  ];

  return (
    <>
      {props.match.params.filter && (
        <Row style={{ marginBottom: 9 }}>
          <Col flex={1}>
            <a
              onClick={() => history.goBack()}
              style={{ color: "black", marginLeft: "4%" }}
            >
              {" "}
              <RollbackOutlined /> GERİ DÖN
            </a>
          </Col>
          <Col flex={4}></Col>
        </Row>
      )}

      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> POS HAREKETLERİ
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
          <Tooltip title="Excel İndir">
            <a
              style={{
                color: "green",
                fontSize: "180%",
                marginRight: 30,
              }}
              onClick={onClickExportExcel}
            >
              <FileExcelOutlined />
            </a>
          </Tooltip>
        </Col>
      </Row>

      <PosTransactionTableFilter
        filter={props.match.params.filter}
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={tableColumns.filter((column) => !column.isRowDetail)}
            expandable={{
              expandedRowRender: (row) =>
                renderRowDetail({
                  data: row,
                  columns: tableColumns.filter((column) => column.isRowDetail),
                }),
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{
              overflowX: "auto",
              maxHeight: "100%",
              width: "100%",
            }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};

export default PosTransaction;
