import React, { useState, useEffect } from "react";
import { Row, Col, Table, Empty, Spin, message, Tooltip } from "antd";
import RequestHandler from "util/RequestHandler";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { BarsOutlined, FileExcelOutlined } from "@ant-design/icons";
import config from "util/ApiforContext";

const BankVolumeDetailReport = (props) => {
  const [tableData, setTableData] = useState({
    loading: true,
  });
  const [detailTableData, setDetailTableData] = useState({});

  useEffect(() => getData(), []);

  const getData = () => {
    const url = (props.dataUrl ? props.dataUrl : "/DbsDashboard") + "/BankVolumeReport";

    RequestHandler.get(url).then((response) => {
      setTableData({
        data: response.data,
        loading: false,
      });
    });
  };

  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);

    const url = (props.dataUrl ? props.dataUrl : "/DbsDashboard") + "/ExportBankVolumeReportExcel";
    const downloadUrl = url.replace("ExportBankVolumeReportExcel", "DownloadBankVolumeReportExcel");
    RequestHandler.post(url).then((response) => {
      hideMessage();

      if (response.success) {
        window.open(
          `${config.apiUrl
          }${downloadUrl}?fileName=${response.data}&t=${RequestHandler.getToken()}`
        );
      }
    });
  };

  const renderCurrencyFormat = (data) => (
    <CurrencyFormat
      value={data}
      displayType={"text"}
      decimalSeparator={","}
      thousandSeparator={"."}
      prefix={"₺"}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  )

  const renderRowDetail = (rowId) => {
    const detail = detailTableData[rowId];

    if (!detail) return null;

    const detailColumns = [
      {
        title: "Bayi Adı",
        dataIndex: "name",
      },
      {
        title: "Bayi Kodu",
        dataIndex: "code",
      },
      {
        title: "Son 12 Ay Toplam",
        dataIndex: "total",
        render: renderCurrencyFormat,
      },
      {
        title: "Bugün",
        dataIndex: "today",
        render: renderCurrencyFormat,
      },
      {
        title: moment().format("MMMM YY"),
        dataIndex: ["xMonthAgoTotal", "0"],
        render: renderCurrencyFormat,
      },
      {
        title: moment().add(-1, "month").format("MMMM YY"),
        dataIndex: ["xMonthAgoTotal", "1"],
        render: renderCurrencyFormat,
      },
      {
        title: moment().add(-2, "month").format("MMMM YY"),
        dataIndex: ["xMonthAgoTotal", "2"],
        render: renderCurrencyFormat,
      },
      {
        title: moment().add(-3, "month").format("MMMM YY"),
        dataIndex: ["xMonthAgoTotal", "3"],
        render: renderCurrencyFormat,
      },
      {
        title: moment().add(-4, "month").format("MMMM YY"),
        dataIndex: ["xMonthAgoTotal", "4"],
        render: renderCurrencyFormat,
      },
      {
        title: moment().add(-5, "month").format("MMMM YY"),
        dataIndex: ["xMonthAgoTotal", "5"],
        render: renderCurrencyFormat,
      },
    ];

    return detail.loading ? (
      <Row justify="center">
        <Spin style={{ margin: 5 }} />
      </Row>
    ) : (
      <Table
        columns={detailColumns}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Veri Yok"
            />
          ),
        }}
        rowKey="code"
        filterable={true}
        sortable={true}
        pageable={true}
        pagination={{
          defaultPageSize: 20,
          hideOnSinglePage: true,
          pageSizeOptions: ["20"],
          total: detail.data.length,
        }}
        dataSource={detail.data}
        size="small"
      />
    );
  };

  const onExpand = (expanded, row) => {
    if (!expanded) return;

    setDetailTableData((prevState) => {
      prevState[row.bankId] = {
        data: [],
        loading: true,
      };
      return prevState;
    });

    const url = (props.dataUrl ? props.dataUrl : "/DbsDashboard") + "/BankVolumeDealerDetailReport/" + row.bankId;
    RequestHandler.get(url).then((response) => {

      setDetailTableData((prevState) => {
        let newState = { ...prevState };
        newState[row.bankId] = {
          data: Array.isArray(response.data) ? response.data : prevState.data,
          loading: false,
        };
        return newState;
      });

    });
  };

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      render: (text, row, index) => {
        return (
          <div style={{ width: 100 }}>
            <img src={text}></img>
          </div>
        );
      },
    },
    {
      title: "Son 12 Ay Toplam",
      dataIndex: "total",
      render: renderCurrencyFormat,
    },
    {
      title: "Bugün",
      dataIndex: "today",
      render: renderCurrencyFormat,
    },
    {
      title: moment().format("MMMM YY"),
      dataIndex: ["xMonthAgoTotal", "0"],
      render: renderCurrencyFormat,
    },
    {
      title: moment().add(-1, "month").format("MMMM YY"),
      dataIndex: ["xMonthAgoTotal", "1"],
      render: renderCurrencyFormat,
    },
    {
      title: moment().add(-2, "month").format("MMMM YY"),
      dataIndex: ["xMonthAgoTotal", "2"],
      render: renderCurrencyFormat,
    },
    {
      title: moment().add(-3, "month").format("MMMM YY"),
      dataIndex: ["xMonthAgoTotal", "3"],
      render: renderCurrencyFormat,
    },
    {
      title: moment().add(-4, "month").format("MMMM YY"),
      dataIndex: ["xMonthAgoTotal", "4"],
      render: renderCurrencyFormat,
    },
    {
      title: moment().add(-5, "month").format("MMMM YY"),
      dataIndex: ["xMonthAgoTotal", "5"],
      render: renderCurrencyFormat,
    },
  ];

  return (
    <>

      <Row>
        <Col xs={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> BANKA CİRO RAPORU
          </p>
        </Col>
        <Col xs={8}>
          <Row justify="end" align="middle">
            <Tooltip title="Excel İndir">
              <a
                style={{
                  color: "green",
                  fontSize: "180%",
                  marginRight: 30,
                }}
                onClick={onClickExportExcel}
              >
                <FileExcelOutlined />
              </a>
            </Tooltip>
          </Row>
        </Col>
      </Row>

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            expandable={{
              expandedRowRender: (row) => renderRowDetail(row.bankId),
              onExpand: onExpand,
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="bankId"
            filterable={false}
            sortable={false}
            pageable={false}
            pagination={false}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};

export default BankVolumeDetailReport;
