import React, { useState, useEffect } from "react";
import RequestHandler from "util/RequestHandler";
import AccountTransactionHome from "./Pages/AccountTransactionHome";
import PosReportHome from "./Pages/PosReportHome";
import B2bHome from "./Pages/B2bHome";
import DbsHome from "./Pages/DbsHome";
import SubscriberFirmHome  from "./Pages/SubscriberFirmHome";
import { Row, Spin } from "antd";

const HomePage = () => {
  const [permissions, setPermissions] = useState({
    loading: true,
    modules: [],
    isAdmin: false,
    defaultModuleId: null,
  });

  const [userDefaultSelectableModules, setUserDefaultSelectableModules] =
    useState({
      data: [],
      loading: true,
    });

  useEffect(() => {
    getModules();
    getUserPermissions();
  }, []);

  const getModules = () => {
    RequestHandler.get("/Module/GetDefaultSelectableModules").then(
      (response) => {
        setUserDefaultSelectableModules({
          data: response,
          loading: false,
        });
      }
    );
  };

  const getUserPermissions = () => {
    RequestHandler.get("/User/MenuPermissions").then((response) => {
      setPermissions({
        ...response,
        loading: false,
      });
      localStorage.setItem("defaultModuleId", response.defaultModuleId);
    });
  };

  const RenderModulePage = () => {
    const homePages = {
      1: <AccountTransactionHome permissions={permissions} />,
      3: <DbsHome permissions={permissions} />,
      5: <PosReportHome permissions={permissions} />,
      8: <B2bHome permissions={permissions} />,
      9: <SubscriberFirmHome permissions = {permissions}/>
    };

    //default module varsa
    if (
      permissions.defaultModuleId &&
      userDefaultSelectableModules.data.some(
        (x) => x.id == permissions.defaultModuleId
      )
    ) {
      return homePages[permissions.defaultModuleId];
    }

    //default module yoksa
    if (permissions.modules.includes(1)) {
      return homePages[1];
    }
    else if (permissions.modules.includes(5)) {
      return homePages[5];
    }
    else if (permissions.modules.includes(3)) {
      return homePages[3];
    }
    else if (permissions.modules.includes(8)) {
      return homePages[8];
    }
    else if (permissions.modules.includes(9)) {
      return homePages[9];
    }
    else {
      window.location = "/not-authorized-access";
      return null;
    }
  };

  return permissions.loading || userDefaultSelectableModules.loading ? (
    <Row align="middle" justify="center" style={{ height: "100%" }}>
      <Spin spinning={true} />
    </Row>
  ) : (
    RenderModulePage()
  );
};
export default HomePage;
