import React, { useEffect, useState } from "react";
import RequestHandler from "util/RequestHandler";
import { ColumnHelper } from "util/TableHelper";
import config from "util/ApiforContext";

import { Table, Empty, Col, Row, Tooltip, message } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";

const NetsisErpFirmReportDetail = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: false,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });

  useEffect(() => getTableData(), [props.documentNumber]);

  const getTableData = () => {
    if (!props.documentNumber) {
      return;
    }

    setTableData({
      ...tableData,
      loading: true,
    });

    RequestHandler.get(
      `/ErpReport/NetsisFirmReportDetail/${props.documentNumber}`
    ).then((response) => {
      setTableData({
        data: response,
        total: response.length,
        loading: false,
      });
    });
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClickExportPdf = () => {
    message.loading("Pdf indiriliyor...", 2);

    window.open(
      `${config.apiUrl}/ErpReport/NetsisFirmReportDetailPdf/${props.documentNumber}?t=${RequestHandler.getToken()}`
    );
  };

  const columns = [
    {
      title: "Stok Kodu",
      dataIndex: "stockCode",
    },
    {
      title: "Stok Adı",
      dataIndex: "stockName",
    },
    {
      title: "Depo Kodu",
      dataIndex: "warehouseCode",
    },
    {
      title: "Miktar",
      dataIndex: "quantity",
    },
    {
      title: "Net Fiyat",
      dataIndex: "netPrice",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Net Tutar",
      dataIndex: "netAmount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Kdv Oranı (%)",
      dataIndex: "kdvRate",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Kdv Tutarı",
      dataIndex: "kdvAmount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Kdv Dahil Tutar",
      dataIndex: "kdvIncludedAmount",
      render: ColumnHelper.renderDecimal,
    },
  ];

  return (
    <>

      {tableData.data[0] && (<Row align="center">
        <Col span={20}>

          <Tooltip title="Pdf İndir">
            <a
              style={{
                color: "red",
                fontSize: "160%",
                marginRight: 10,
              }}
              onClick={onClickExportPdf}
            >
              <FilePdfOutlined />
            </a>
          </Tooltip>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
        </Col>
      </Row>)}

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey={(row) => row.documentNumber + row.stockCode}
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              current: tableDataQueryOptions.current,
              total: tableData.total,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};

export default NetsisErpFirmReportDetail;
