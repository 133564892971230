import React, { useState, useEffect } from "react";

import RequestHandler from "util/RequestHandler";

import ErpExportTransactionTableFilter from "../Filters/ErpExportTransactionTableFilter";

import moment from "moment";
import { odata } from "../Filters/Helper";
import axios from "axios";

import {
  Table,
  Empty,
  Col,
  Row,
  Button,
  notification,
  Select,
  Tooltip,
  Popconfirm,
  Tabs,
  Input,
  Modal,
} from "antd";
import {
  BarsOutlined,
  SendOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import FirmTransactionMatchForm from "../Forms/FirmTransactionMatchForm";
import config from "../../util/ApiforContext";

const ErpExportTransaction = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    activeTab: "0",
    filter: "",
  });
  const [options, setOptions] = useState({ pageTitle: "ERP AKTARIM" });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [firmClass, setFirmClass] = useState({
    data: [],
    loading: true,
  });
  const [firm, setFirm] = useState([]);
  const [columnSettings, setColumnSettings] = useState();
  const [firmMatchModal, setFirmMatchModal] = useState({
    isUpdate: false,
    isVisible: false,
  });
  const [firmMatchFormData, setFirmMatchFormData] = useState();
  const [chequeNumbers, setChequeNumbers] = useState([]);

  useEffect(() => {
    const pageOptions = {
      1: {
        pageTitle: "ERP AKTARIM - GELEN",
      },
      2: {
        pageTitle: "ERP AKTARIM - GİDEN",
      },
    };
    if (pageOptions[props.transactionProcessType]) {
      setOptions(pageOptions[props.transactionProcessType]);
    }
  }, []);

  useEffect(() => {
    getColumnSettings();
    getFirmClasses();
  }, []);

  useEffect(() => {
    if (columnSettings) {
      getTableData();
    }
  }, [tableDataQueryOptions, columnSettings]);

  const getTableData = (isRefresh) => {
    setSelectedRowKeys([]);

    //isRefresh eşleştirme sonucu dropdownların state i update olması için
    setTableData(
      isRefresh
        ? {
            data: [],
            total: 0,
            loading: true,
          }
        : { ...tableData, loading: true }
    );
    const { skip, take, filter, activeTab } = tableDataQueryOptions;

    let conditions = ["isManualTransaction Eq false"];
    let query = "";

    if (props.transactionProcessType) {
      conditions.push(
        `TransactionProcessTypeValue Eq ${props.transactionProcessType}`
      );
    }

    if (activeTab !== "-1") {
      conditions.push(`erpTransferStatusId Eq ${activeTab}`);
    }

    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length !== 0) {
      query = conditions.join(" and ");
    }

    RequestHandler.get(
      `/ErpAccountTransaction?$count=true&$filter=${query}&$skip=${skip}&$top=${take}`
    )
      .then((response) => {
        if (isExportAvailable && response.value) {
          response.value.forEach((x) => {
            if (!x.chaResponsibilityCode) {
              x.chaResponsibilityCode =
                columnSettings.centerOfResponsibilityCode;
            }
          });
        }
        return response;
      })
      .then((response) => {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      });
  };

  const getFirmClasses = () => {
    RequestHandler.get(
      `/FirmClass?$filter=isErpType Eq true&${odata.orderBy("name")}`
    ).then((response) => {
      setFirmClass({
        data: response.value,
        loading: false,
      });
    });
  };

  const getColumnSettings = () => {
    RequestHandler.get("/TenantErpProgram/GetByTenant").then((response) => {
      if (response) {
        setColumnSettings(response);
      } else {
        //erp tanımlı değilse sadece table loaderı kaldırdık
        setTableData({ ...tableData, loading: false });
      }
    });
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      ...tableDataQueryOptions,
      skip: 0,
      current: 1,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: 0,
        current: 1,
        filter: query,
      };
    });
  };

  const onTableSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const onConfirmExport = () => {
    let isSuccess = true;
    const selectedRowsData = tableData.data.filter((row) =>
      selectedRowKeys.includes(row.id)
    );

    // tenantAccountErpCode valuesu olmayan bir tenant account hareketi seçili ise
    if (selectedRowsData.some((row) => !row.tenantAccountErpCode)) {
      notification.error({
        message:
          "Seçili hesap hareketleri içerisinde 'Erp Kodu' olmayan Hesaba ait kayıtlar var.",
        duration: 3,
      });
      isSuccess = false;
    }

    // erp valuesu olan bir firmClass ve cari seçili değilse
    if (
      !selectedRowsData.every(
        (row) =>
          firmClass.data.some(
            (firmClassHasErpValue) =>
              firmClassHasErpValue.id === row.firmClassId
          ) &&
          row.firmId &&
          row.firmId !== "00000000-0000-0000-0000-000000000000"
      )
    ) {
      notification.error({
        message:
          "Seçili hesap hareketleri içerisinde 'Tip' veya 'Cari' seçilmemiş kayıtlar var.",
        duration: 3,
      });
      isSuccess = false;
    }

    // firmBranchNumber valuesu olmayan bir firm seçili ise
    if (selectedRowsData.some((row) => !row.firmBranchNumber)) {
      notification.error({
        message:
          "Seçili hesap hareketleri içerisinde 'Cari Kodu' olmayan Cari kayıtları var.",
        duration: 3,
      });
      isSuccess = false;
    }

    if (
      columnSettings?.isHasChequeNumber &&
      selectedRowsData.some(
        (row) => !row.chequeNumber && row.firmClassName == "Çek"
      )
    ) {
      notification.error({
        message:
          "Seçili hesap hareketleri içerisinde Çek Numarası olmayan Cari kayıtları var.",
        duration: 3,
      });
      isSuccess = false;
    }

    if (isSuccess) {
      RequestHandler.post("/ErpAccountTransaction", selectedRowsData).then(
        (response) => {
          if (response.success) {
            getTableData();
          }
        }
      );
    }
  };

  const onFirmSearch = (value) => {
    if (value.length >= 2) {
      RequestHandler.get(
        `/Firm?$select=id,name,firmBranchNumber,firmClassId&$filter=${odata.contains(
          "name",
          value
        )} or ${odata.contains("firmBranchNumber", value)}`
      ).then((res) => {
        if (res.value) {
          setFirm(res.value);
        }
      });
    } else {
      setFirm([]);
    }
  };

  const onTabChange = (tabKey) => {
    setTableDataQueryOptions({
      ...tableDataQueryOptions,
      skip: 0,
      current: 1,
      activeTab: tabKey,
    });
  };

  const onClickMatchFirm = (isUpdate, data) => {
    setFirmMatchFormData(data);
    setFirmMatchModal({ isUpdate: isUpdate, isVisible: true });
  };

  const getColumns = () => {
    const columnsBeforeDynamicColumns = [
      {
        title: "Banka",
        dataIndex: "bankLogo",
        width: "8%",
        render: (text) => <img src={text}></img>,
      },
      {
        title: "Tutar",
        dataIndex: "amountString",
        render: (data) => <span style={{ whiteSpace: "nowrap" }}>{data}</span>,
      },
      {
        title: "Tarih",
        dataIndex: "transactionDate",
        render: (data, row, index) => moment(data).format("DD.MM.YYYY"),
      },
      {
        title: "Cari",
        dataIndex: "firmId",
        width: "15%",
        render: (data, row, index) => (
          <Select
            allowClear
            showSearch
            placeholder="En az 2 harf giriniz.."
            defaultValue={data ?? undefined}
            disabled={!isExportAvailable}
            style={{ width: "100%" }}
            dropdownStyle={{ minWidth: "50%" }}
            onSearch={onFirmSearch}
            onChange={(value, option) => {
              let rows = [...tableData.data];
              let rowToUpdate = {
                ...row,
                firmId: value,
                firmName: option?.firmName,
                firmClassId: option?.firmClassId,
                firmClassName: option?.firmClassName,
                firmBranchNumber: option?.firmBranchNumber,
                chequeNumbers: [],
                chequeNumber: option?.chequeNumber,
              };
              rows[index] = rowToUpdate;

              setTableData({ ...tableData, data: rows });
            }}
            filterOption={(input, option) =>
              option.children
                .toLocaleLowerCase("tr-TR")
                .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
            }
          >
            {data && (
              <Select.Option
                key={row.firmId}
                value={row.firmId}
                firmName={row.firmName}
                firmClassId={row.firmClassId}
                firmBranchNumber={row.firmBranchNumber}
              >
                {row.firmBranchNumber
                  ? `${row.firmName} (${row.firmBranchNumber})`
                  : row.firmName}
              </Select.Option>
            )}

            {firm
              .filter((item) => item.id != data)
              .map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                  firmName={item.name}
                  firmClassId={item.firmClassId}
                  firmBranchNumber={item.firmBranchNumber}
                >
                  {item.firmBranchNumber
                    ? `${item.name} (${item.firmBranchNumber})`
                    : item.name}
                </Select.Option>
              ))}
          </Select>
        ),
      },
      {
        title: "Tip",
        dataIndex: "firmClassId",
        width: "10%",
        render: (data, row, index) => {
          const firmClassAvailable = firmClass.data.some((e) => e.id === data);

          return (
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              value={firmClassAvailable ? data : undefined}
              disabled={!isExportAvailable}
              style={{ width: "100%" }}
              loading={firmClass.loading}
              onChange={(value, option) => {
                let rows = [...tableData.data];
                if (
                  columnSettings?.isHasChequeNumber &&
                  option?.children.toLocaleLowerCase("tr-TR") == "çek" &&
                  row.firmId != null
                ) {
                  axios
                    .post(
                      `${config.apiUrl}/ErpOnare/GetChequeNumbers`,
                      {
                        firmBranchNumber: row.firmBranchNumber,
                      },
                      config.headers
                    )
                    .then((res) => {
                      let rowToUpdate = {
                        ...row,
                        firmClassId: value,
                        chequeNumbers:
                          res.status == 200
                            ? res.data != ""
                              ? res.data
                              : []
                            : [],
                        firmClassName: option?.children,
                        chequeNumber: option?.chequeNumber,
                      };
                      rows[index] = rowToUpdate;
                      setTableData({ ...tableData, data: rows });
                    });
                } else {
                  let rowToUpdate = {
                    ...row,
                    firmClassId: value,
                    chequeNumbers: [],
                    firmClassName: option?.children,
                    chequeNumber: option?.chequeNumber,
                  };
                  rows[index] = rowToUpdate;

                  setTableData({ ...tableData, data: rows });
                }
              }}
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
            >
              {firmClass.data.map((item) => {
                if (
                  props.transactionProcessType === 2 && //giden
                  item.name.toLocaleLowerCase() === "banka"
                )
                  return;

                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                );
              })}
            </Select>
          );
        },
      },
    ];

    if (columnSettings?.isHasCenterOfResponsibility) {
      columnsBeforeDynamicColumns.push({
        title: "Sorumluluk Merkezi",
        dataIndex: "chaResponsibilityCode",
        width: "15%",
        render: (data, row, index) => {
          return (
            <Input
              value={data}
              disabled={!isExportAvailable}
              onChange={(event) => {
                let rows = [...tableData.data];
                let rowToUpdate = {
                  ...row,
                  chaResponsibilityCode: event.target.value,
                };
                rows[index] = rowToUpdate;

                setTableData({ ...tableData, data: rows });
              }}
            />
          );
        },
      });
    }

    if (columnSettings?.isHasProjectCode) {
      columnsBeforeDynamicColumns.push({
        title: "Proje Kodu",
        dataIndex: "projectCode",
        width: "15%",
        render: (data, row, index) => (
          <Input
            defaultValue={data ?? undefined}
            disabled={!isExportAvailable}
            onChange={(event) => {
              let rows = [...tableData.data];
              let rowToUpdate = {
                ...row,
                projectCode: event.target.value,
              };
              rows[index] = rowToUpdate;

              setTableData({ ...tableData, data: rows });
            }}
          />
        ),
      });
    }

    if (columnSettings?.isHasChequeNumber) {
      columnsBeforeDynamicColumns.push({
        title: "Çek Numarası",
        dataIndex: "chequeNumber",
        width: "15%",
        render: (data, row, index) => {
          return (
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              disabled={
                !isExportAvailable ||
                row.firmId == null ||
                row.firmClassName?.toLocaleLowerCase("tr-TR") != "çek"
              }
              value={row.chequeNumber}
              style={{ width: "100%" }}
              onChange={(value) => {
                let rows = [...tableData.data];
                let rowToUpdate = {
                  ...row,
                  chequeNumber: value,
                };
                rows[index] = rowToUpdate;
                setTableData({ ...tableData, data: rows });
              }}
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
            >
              {row.chequeNumbers?.map((item) => (
                <Select.Option key={item.CHEQUE_ID} value={item.CHEQUE_NO}>
                  {item.CHEQUE_NO} ({item.CHEQUE_VALUE} - {item.CURRENCY_ID})
                </Select.Option>
              ))}
            </Select>
          );
        },
      });
    }

    return columnsBeforeDynamicColumns.concat([
      {
        title: "Açıklama",
        dataIndex: "description",
        render: (text, row, index) => (
          <Tooltip placement="topLeft" title={text}>
            <div style={{ whiteSpace: "break-spaces" }}>
              {text?.trim()?.length > 60
                ? text.substring(0, 125) + "..."
                : text}
            </div>
          </Tooltip>
        ),
      },
      {
        title: "Evrak No",
        dataIndex: "documentNumber",
      },
      // {
      //   title: "Eşleştirme",
      //   dataIndex: "firmId",
      //   align: "center",
      //   render: (data, row) => {
      //     const isUpdate = data ? true : false;
      //     const formData = { accountTransactionId: row.id };

      //     return isUpdate ? (
      //       <Tooltip title="Kayıtlı Cari Güncelle">
      //         <a style={{ color: "green", fontSize: "130%" }} onClick={() => onClickMatchFirm(isUpdate, formData)}>
      //           <CheckCircleOutlined />
      //         </a>
      //       </Tooltip>
      //     ) : (
      //       <Tooltip title="Kayıtlı Cari Eşleştir">
      //         <a style={{ color: "red", fontSize: "130%" }} onClick={() => onClickMatchFirm(isUpdate, formData)}>
      //           <CloseCircleOutlined />
      //         </a>
      //       </Tooltip>
      //     )
      //   }
      // },
      {
        title: "Durum",
        dataIndex: "erpTransferStatusId",
        align: "center",
        render: (i, row) => {
          const icon = {
            0: {
              component: <InfoCircleOutlined />,
            },
            1: {
              color: "green",
              component: <CheckCircleOutlined />,
            },
            2: {
              color: "orange",
              component: <ClockCircleOutlined />,
            },

            3: {
              color: "red",
              component: <CloseCircleOutlined />,
            },
          };
          return (
            <Tooltip title={row.erpTransferStatus}>
              <a style={{ fontSize: "130%", color: icon[i].color }}>
                {icon[i].component}
              </a>
            </Tooltip>
          );
        },
      },
    ]);
  };

  const isExportAvailable =
    tableDataQueryOptions.activeTab === "0" ||
    tableDataQueryOptions.activeTab === "3";

  const columns = getColumns();

  const onSuccessFirmMatch = () => {
    setFirmMatchModal({ ...firmMatchModal, isVisible: false });
    getTableData(true);
  };

  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> {options.pageTitle}
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
          <Popconfirm
            title="Aktarmak istiyor musunuz?"
            okText="Evet"
            cancelText="Hayır"
            placement="bottom"
            disabled={selectedRowKeys.length < 1}
            onConfirm={onConfirmExport}
          >
            {isExportAvailable && (
              <Button
                type="primary"
                disabled={selectedRowKeys.length < 1}
                style={{ marginBottom: 0 }}
              >
                <SendOutlined /> Erp'ye Aktar
              </Button>
            )}
          </Popconfirm>
        </Col>
      </Row>

      <ErpExportTransactionTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Tabs
        defaultActiveKey={tableDataQueryOptions.activeTab}
        onChange={onTabChange}
      >
        <Tabs.TabPane tab="Tümü" key="-1" />
        <Tabs.TabPane tab="Aktarılmadı" key="0" />
        <Tabs.TabPane tab="Başarılı" key="1" />
        <Tabs.TabPane tab="Aktarılıyor" key="2" />
        <Tabs.TabPane tab="Başarısız" key="3" />
      </Tabs>

      <Row id="tableArea">
        <Col span={24}>
          <Table
            rowSelection={
              isExportAvailable
                ? {
                    selectedRowKeys: selectedRowKeys,
                    onChange: onTableSelectChange,
                  }
                : undefined
            }
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Modal
        centered
        title={
          firmMatchModal.isUpdate
            ? "Kayıtlı Cari Güncelle"
            : "Kayıtlı Cari Eşleştir"
        }
        visible={firmMatchModal.isVisible}
        onCancel={() =>
          setFirmMatchModal({ ...firmMatchModal, isVisible: false })
        }
        footer={null}
      >
        <FirmTransactionMatchForm
          onSuccess={onSuccessFirmMatch}
          isUpdate={firmMatchModal.isUpdate}
          data={firmMatchFormData}
        />
      </Modal>
    </>
  );
};

export default ErpExportTransaction;
