import React, { useState, useEffect } from "react";
import {
  Table,
  Empty,
  Popconfirm,
  notification,
  Col,
  Row,
  Drawer,
  Button,
} from "antd";
import { Link } from "react-router-dom";
import {
  BarsOutlined,
  PlusOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import FirmUpdate from "../Forms/FirmUpdate";
import { odata } from "../Filters/Helper";
import FirmAddForm from "../Forms/FirmAddForm";
import { RowHelper } from "util/TableHelper";
import RequestHandler from "util/RequestHandler";
import FirmListTableFilter from "../Filters/FirmListTableFilter";


const FirmList = () => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });
  const [order, setOrder] = useState("");

  const [firmTypeDropdown, setFirmTypeDropdown] = useState([]);
  const [wordList, setWordList] = useState([]);
  const [firmClassDropdown, setFirmClassDropdown] = useState([]);
  const [firmIdUpdate, setFirmIdUpdate] = useState("");
  const [Name, setName] = useState("");
  const [DirectiveName, setDirectiveName] = useState("");
  const [Email, setEmail] = useState("");
  const [ErpSalesCode, setErpSalesCode] = useState("");
  const [TaxOffice, setTaxOffice] = useState("");
  const [groupCode, setGroupCode] = useState("");
  const [Vkn, setVkn] = useState("");
  const [TcNo, setTcNo] = useState("");
  const [Phone, setPhone] = useState("");
  const [MobilePhone, setMobilePhone] = useState("");
  const [Fax, setFax] = useState("");
  const [Address, setAddress] = useState("");
  const [FirmTypeValue, setFirmTypeValue] = useState("");
  const [ErpCode, setErpCode] = useState("");
  const [FirmBranchNumberUpdate, setFirmBranchNumberUpdate] = useState("");
  const [FirmClassId, setFirmClassId] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [syncButton, setSyncButton] = useState({ visible: false, loading: false });

  useEffect(() => {
    RequestHandler.get(`/Firm/GetFirmType`).then((response) => {
      setFirmTypeDropdown(response.data);
    });
    RequestHandler.get(`/FirmClass`).then((response) => {
      setFirmClassDropdown(response.value);
    });
    RequestHandler.get("/TenantErpProgram/IsHaveTenantSyncRole").then((response) => {
      if (response) {
        setSyncButton({ ...syncButton, visible: true });
      }
    });
  }, []);
  useEffect(() => getTableData(), [order, tableDataQueryOptions]);

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    let query = '';
    const { skip, take, filter } = tableDataQueryOptions;

    const conditions = ["Name ne 'Diğer'"];
    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length > 0) {
      query = `&$filter=${conditions.join(" and ")}`;
    }

    RequestHandler.get(
      `/Firm?$count=true${query}${order}&$skip=${skip}&$top=${take}`
    ).then((response) => {
      if (Array.isArray(response.value)) {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      }
    });
  };

  const paging = (p, filters, sorter) => {
    let orderBy = "";
    if (sorter.columnKey && sorter.order) {
      orderBy =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }
    setOrder(orderBy);
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };

  const showDrawerUpdate = (id) => {
    RequestHandler.get(`/Firm/GetById/${id}`).then((response) => {
      setName(response.name);
      setDirectiveName(response.directiveName);
      setEmail(response.email);
      setErpSalesCode(response.erpSalesCode);
      setTaxOffice(response.taxOffice);
      setGroupCode(response.groupCode)
      setVkn(response.vkn);
      setTcNo(response.tcNo);
      setPhone(response.phone == null ? "          " : response.phone);
      setMobilePhone(
        response.mobilePhone == null ? "          " : response.mobilePhone
      );
      setFax(response.fax);
      setAddress(response.address);
      setErpCode(response.erpCode);
      setFirmTypeValue(response.firmTypeValue.toString());
      setFirmBranchNumberUpdate(response.firmBranchNumber);
      setFirmClassId(response.firmClassId);
      setFirmIdUpdate(id);
      setVisibleUpdate(true);
    });

    RequestHandler.get(`/Firm/GetMathingWordWithFirmId/${id}`).then((res) => {
      setWordList(res);
    });
  };

  const onClose = () => {
    setVisible(false);
  };
  const onCloseUpdate = () => {
    setVisibleUpdate(false);
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Uyarı",
      description: "Pasif olan firmalar güncellenemez.",
    });
  };

  const handleActivePassive = (id) => {
    RequestHandler.delete(`/Firm?id=${id}`).then((res) => {
      if (res.success) {
        getTableData();
      }
    });
  };

  const getData = (val) => {
    if (val === "success") {
      getTableData();
      setVisibleUpdate(false);
      setVisible(false);
    }
  };

  const onConfirmSyncFirms = () => {
    setSyncButton({ ...syncButton, loading: true });
    RequestHandler.get("/ErpReport/SyncMikro").then((response) => {
      setSyncButton({ ...syncButton, loading: false });
      if (response) {
        getTableData();

        notification.success({
          message: "Senkronizasyon işlemi başarılı.",
        })
      }
      else {
        notification.error({
          message: "Senkronizasyon işlemi başarısız.",
        })
      }
    });

  }

  const columns = [
    {
      title: "Cari Adı",
      dataIndex: "name",
      key: "name",
      render: (text) => {
        if (!text) return "";

        return text.substring(0, 15).length < 15
          ? text.substring(0, 15) + ""
          : text.substring(0, 15) + "...";
      },
    },
    {
      title: "Cari Kodu",
      dataIndex: "firmBranchNumber",
      key: "firmBranchNumber",
      render: (text) => {
        if (!text) return "";

        return text.substring(0, 15).length < 15
          ? text.substring(0, 15) + ""
          : text.substring(0, 15) + "...";
      },
    },
    {
      title: "Tckn / Vkn",
      dataIndex: "tcNo",
      align: "center",
      key: "vknTckn",
      render: (_, row) => {
        return row.tcNo === null || row.tcNo == ""
          ? row.vkn === null || row.vkn == ""
            ? "-"
            : row.vkn
          : row.tcNo;
      },
    },
    {
      title: "Cari Tipi",
      dataIndex: "firmTypeValue",
      align: "center",
      key: "firmTypeValue",
    },

    {
      title: "Durum",
      dataIndex: "operation",
      align: "center",
      render: (_, record) =>
        record.isActive ? (
          <Popconfirm
            title="Pasif yapmak istiyor musunuz?"
            okText="Evet"
            cancelText="Hayır"
            onConfirm={() => handleActivePassive(record.id)}
          >
            <a style={{ color: "green", fontSize: "130%" }}>
              <CheckCircleOutlined />
            </a>
          </Popconfirm>
        ) : (
          <Popconfirm
            title="Aktif yapmak istiyor musunuz?"
            okText="Evet"
            cancelText="Hayır"
            onConfirm={() => handleActivePassive(record.id)}
          >
            <a style={{ color: "red", fontSize: "130%" }}>
              <CloseCircleOutlined />
            </a>
          </Popconfirm>
        ),
    },
    {
      title: "Düzenle",
      dataIndex: "operation",
      align: "center",
      width: "1%",
      render: (_, record) =>
        record.isActive ? (
          <a
            onClick={() => {
              showDrawerUpdate(record.id);
            }}
            style={{ color: "orange", fontSize: "130%" }}
          >
            <EditOutlined />
          </a>
        )
          :
          (
            <a
              onClick={() => {
                openNotificationWithIcon("warning");
              }}
              style={{ color: "orange", fontSize: "130%" }}
            >
              <EditOutlined />
            </a>
          ),
    },
    {
      title: "Hesaplar",
      dataIndex: "",
      align: "center",
      render: (record) => {
        return (
          <Link
            style={{ fontSize: "150%" }}
            to={`/firm-account-list/${record.id}`}
          >
            <MenuUnfoldOutlined />
          </Link>
        );
      },
    },
  ];

  const renderDetailString = (text) => text ? text : "-";
  const detailColumns = [
    {
      title: "E-posta",
      dataIndex: "email",
      render: renderDetailString,
    },
    {
      title: "Vergi Dairesi",
      dataIndex: "taxOffice",
      render: renderDetailString,
    },
    {
      title: "Adres",
      dataIndex: "address",
      render: renderDetailString,
    },
    {
      title: "Fax Numarası",
      dataIndex: "fax",
      render: renderDetailString,
    },
    {
      title: "Cep Telefonu",
      dataIndex: "mobilePhone",
      render: renderDetailString,
    },
    {
      title: "Ev Telefonu",
      dataIndex: "phone",
      render: renderDetailString,
    },
  ];

  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> CARİ LİSTESİ
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>

          {syncButton.visible && (
            <Popconfirm
              title="Erp programınız ile cari senkronizasyonu tetiklemek istiyor musunuz?"
              okText="Evet"
              cancelText="Hayır"
              placement="bottom"
              disabled={syncButton.loading}
              onConfirm={onConfirmSyncFirms}
            >
              <Button
                disabled={syncButton.loading}
                loading={syncButton.loading}
                style={{ marginRight: 15 }}
              >
                <SyncOutlined /> Cari Senkronizasyonu
              </Button>
            </Popconfirm>)}

          <Button
            type="primary"
            onClick={() => setVisible(true)}
          >
            <PlusOutlined /> Cari Ekle
          </Button>
        </Col>
      </Row>

      <FirmListTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            expandable={{
              expandedRowRender: (row) =>
                RowHelper.renderRowDetail({
                  data: row,
                  columns: detailColumns,
                }),
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%" }}
            rowKey="id"
            filterable={true}
            size="small"
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
          />
        </Col>
      </Row>

      <Drawer
        title="Cari Ekle"
        width={241}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <FirmAddForm />
      </Drawer>

      <Drawer
        title="Cari Güncelle"
        width={241}
        onClose={onCloseUpdate}
        visible={visibleUpdate}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <FirmUpdate
          firmId={firmIdUpdate}
          Name={Name}
          DirectiveName={DirectiveName}
          Email={Email}
          ErpSalesCode={ErpSalesCode}
          TaxOffice={TaxOffice}
          Vkn={Vkn}
          TcNo={TcNo}
          GroupCode={groupCode}
          wordList={wordList}
          Phone={Phone}
          sendDataForUpdate={getData}
          MobilePhone={MobilePhone}
          Fax={Fax}
          Address={Address}
          FirmTypeValue={FirmTypeValue}
          FirmBranchNumber={FirmBranchNumberUpdate}
          FirmClassId={FirmClassId}
          firmTypeDropdown={firmTypeDropdown}
          firmClassDropdown={firmClassDropdown}
          ErpCode={ErpCode}
        />
      </Drawer>

    </>
  );
};

export default FirmList;
