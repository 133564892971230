import React, { useState } from "react";
import NetsisErpFirmReport from "./ErpFirmReport";
import NetsisErpFirmInvoiceReport from "./ErpFirmInvoiceReport";

import RequestHandler from "util/RequestHandler";
import { odata } from "../../../Filters/Helper";

import { Tabs, Select, Row, Col } from "antd";
import { BarsOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;


const NetsisErpReportPage = () => {
  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });
  const [activeFirmCode, setActiveFirmCode] = useState();


  const onFirmSeach = (value) => {
    if (value.length >= 2) {
      setFirmSelect({ data: [], loading: true });

      RequestHandler.get(
        `/Firm?$select=id,firmBranchNumber,name&$filter=${odata.contains(
          "name",
          value
        )} or ${odata.contains("firmBranchNumber", value)}`
      ).then((res) => {
        setFirmSelect({ data: res.value, loading: false });
      });
    } else {
      setFirmSelect({ data: [], loading: false });
    }
  };

  return (
    <>

      <Row>
        <Col xs={16} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> CARİ TAKİP
          </p>
        </Col>
        <Col xs={8} md={12} lg={8} style={{ textAlign: 'right' }}>
          <Select
            allowClear
            showSearch
            placeholder="Cari Seçiniz"
            onSearch={onFirmSeach}
            onSelect={(value) => setActiveFirmCode(value)}
            filterOption={(input, option) =>
              option.children
                .toLocaleLowerCase("tr-TR")
                .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
              option.code
                .toLocaleLowerCase("tr-TR")
                .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
            }
            loading={firmSelect.loading}
            style={{ width: "100%" }}
          >
            {firmSelect.data.map((item) => (
              <Select.Option
                key={item.id}
                value={item.firmBranchNumber}
                code={item.firmBranchNumber}
              >
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>


      <Tabs defaultActiveKey="1">
        <TabPane tab="Cari Ekstre" key="1">
          <NetsisErpFirmReport activeFirmCode={activeFirmCode} />
        </TabPane>
        <TabPane tab="Emanet Faturalar" key="2">
          <NetsisErpFirmInvoiceReport activeFirmCode={activeFirmCode} />
        </TabPane>
      </Tabs>
    </>
  );
};

export default NetsisErpReportPage;
