import React, { useState, useEffect, useRef } from "react";

import {
  Col,
  Row,
  Divider,
  Input,
  Form,
  Button,
  Card,
  InputNumber,
  Checkbox,
  Modal,
  Select,
} from "antd";
import { BarsOutlined } from "@ant-design/icons";
import Cleave from "cleave.js/react";

import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "../Forms/Helper";
import { odata } from "../Filters/Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
const PosCollectionPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [isInstallmentRequired, setIsInstallmentRequired] = useState();
  const [creditCardType, setCreditCardType] = useState("");
  const [form] = Form.useForm();
  const [paymentModal, setPaymentModal] = useState({
    visible: false,
    source: null,
  });
  const [availableProviders, setAvailableProviders] = useState({
    loading: true,
    data: [],
  });
  const [firmsBalance, setFirmsBalance] = useState({
    loading: true,
    data: []
  });
  const [activeFirmBalance, setActiveFirmBalance] = useState(0);
  const [availableInstallments, setAvailableInstallments] = useState({
    loading: false,
    data: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  });
  const [activeProviderId, setActiveProviderId] = useState();
  const [activeFirmId, setActiveFirmId] = useState();
  const [currencyCodes, setCurrencyCodes] = useState(["TRY", "USD", "EUR"]);
  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });
  const iframeRef = useRef();

  useEffect(() => {
    getAvailableProviders();
    getFirmsBalance();
  }, []);

  useEffect(() => {
    let currencies = ["TRY", "USD", "EUR"];

    //Param Pos || NKolay Pos
    if (activeProviderId === 889 || activeProviderId === 890) {
      currencies = ["TRY"];
    }

    setCurrencyCodes(currencies);
    form.setFieldsValue({ ...form.getFieldsValue(), currencyCode: currencies[0] });

  }, [activeProviderId]);

  useEffect(() => {
    if (!activeFirmId) return;

    getDefaultInstallment(activeFirmId);
  }, [activeFirmId]);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const getAvailableProviders = () => {
    RequestHandler.get("/PosCollection/AvailableProviders").then((response) => {
      if (response.success) {
        setAvailableProviders({
          data: response.data,
          loading: false,
        });

        if (response.data?.length > 0) {
          setActiveProviderId(response.data[0].id);
          RequestHandler.get(`/PosCollectionBankInfo?$filter=BankId eq ${response.data[0].id}`).then((responseReq) => {
            setIsInstallmentRequired(responseReq.value[0]?.isInstallmentRequired);
          });
        }

      } else {
        setAvailableProviders({ ...availableProviders, loading: false });
      }
    });

  }

  const getFirmsBalance = () => {
    RequestHandler.get("/PosCollection/GetFirmsBalanceFromErp").then((response) => {
      if (Array.isArray(response)) {
        setFirmsBalance({
          data: response,
          loading: false,
        });

      } else {
        setFirmsBalance({ ...firmsBalance, loading: false });
      }
    });

  }


  const getDefaultInstallment = (firmId) => {
    setAvailableInstallments({ ...availableInstallments, loading: true });

    RequestHandler.get(`/PosCollection/MaxAvailableInstallmentCount/${firmId}`).then((response) => {
      setAvailableInstallments({ ...availableInstallments, loading: false });

      //Bayiye tanımlı taksit sayısını seçili getiriyoruz.
      form.setFieldsValue({ ...form.getFieldsValue(), installmentCount: response.data });
    });
  }

  const onFirmSeach = (value) => {
    if (value.length >= 2) {
      setFirmSelect({ ...firmSelect, loading: true });

      RequestHandler.get(
        `/Firm?$select=id,firmBranchNumber,name&$filter=${odata.contains(
          "name",
          value
        )} or ${odata.contains("firmBranchNumber", value)}`
      ).then((res) => {
        setFirmSelect({ data: res.value, loading: false });
      });
    } else {
      setFirmSelect({ data: [], loading: false });
    }
  };

  const onFinish = (values) => {
    let expireMonth, expireYear;

    if (values.expiration) {
      [expireMonth, expireYear] = values.expiration.split("/");
    }
    RequestHandler.post(
      `/PosCollection/SalesRequest`,
      {
        holderName: values.holderName,
        cardNumber: values.cardNumber,
        cvv: values.cvv,
        expireMonth: expireMonth,
        expireYear: expireYear,
        amount: values.amount,
        currencyCode: values.currencyCode,
        bankId: values.bankId,
        firmId: values.firmId,
        installmentCount: values.installmentCount,
        customerInformation: values.customerInformation
      },
      false
    ).then((response) => {
      setLoading(false);
      if (response.success) {
        //fill html
        setPaymentModal({ visible: true, source: response.data });
        form.resetFields();
        form.setFieldsValue({
          bankId: values.bankId,
          firmId: values.firmId
        });

        if (props.onSuccess) props.onSuccess();
      }
    });
  };
  const onChangeValue = (value) => {
    setActiveProviderId(value);
    RequestHandler.get(`/PosCollectionBankInfo?$filter=BankId eq ${value}`).then((response) => {
      setIsInstallmentRequired(response.value[0]?.isInstallmentRequired);
    });
  }
  const onFinishFailed = () => {
    setLoading(false);
  };

  const renderCardInfoInputs = () => {
    const redirectProviderIds = [890];
    const amountMax = activeFirmBalance > 0 ? activeFirmBalance : undefined;
    return redirectProviderIds.includes(activeProviderId) ? (
      <>
        <Col span={12}>
          <Form.Item
            label="Tutar"
            name="amount"
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              decimalSeparator=","
              precision={2}
              min={1.00}
              max={amountMax}
              placeholder="0,00"
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Para Birimi"
            name="currencyCode"
            initialValue={currencyCodes[0]}
          >
            <Select>
              {currencyCodes.map(currencyCode =>
                <Select.Option value={currencyCode}>{currencyCode}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
      </>
    ) : (
      <>
        <Col span={8}>
          <Form.Item
            label="Tutar"
            name="amount"
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              decimalSeparator=","
              precision={2}
              min={1.00}
              max={amountMax}
              placeholder="0,00"
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="Para Birimi"
            name="currencyCode"
            initialValue={currencyCodes[0]}
          >
            <Select>
              {currencyCodes.map(currencyCode =>
                <Select.Option value={currencyCode}>{currencyCode}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          {
            isInstallmentRequired ?
              <Form.Item
                label="Taksit"
                name="installmentCount"
                initialValue={1}
              >
                <Select
                  loading={availableInstallments.loading}
                  disabled={availableInstallments.loading}
                >
                  <Select.Option value={1}>Tek Çekim</Select.Option>
                  {availableInstallments.data.map(installmentCount =>
                    <Select.Option value={installmentCount}>{installmentCount} Taksit</Select.Option>)
                  }
                </Select>
              </Form.Item>
              :
              null
          }

        </Col>

        <Col span={24}>
          <Form.Item
            label="Kart Üzerindeki İsim"
            name="holderName"
            rules={[{ required: true }]}
          >
            <Input placeholder="Ad Soyad" />
          </Form.Item>
          <Form.Item
            label="Kart Numarası"
            name="cardNumber"
            valuePropName="rawValue"
            rules={[{ required: true }]}
          >
            <Cleave
              className="ant-input"
              placeholder="0000 0000 0000 0000"
              options={{
                creditCard: true,
                onCreditCardTypeChanged: (type) =>
                  setCreditCardType(type),
              }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Son Kullanma Tarihi"
            name="expiration"
            rules={[{ required: true }]}
          >
            <Cleave
              className="ant-input"
              placeholder="AA/YY"
              options={{ date: true, datePattern: ["m", "d"] }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Güvenlik Kodu"
            name="cvv"
            rules={[{ required: true }]}
          >
            <Cleave
              className="ant-input"
              placeholder="CVV"
              options={{
                blocks: [4],
                numericOnly: true,
              }}
            />
          </Form.Item>
        </Col>
      </>
    )
  };

  return (
    <>
      <Row id="titleArea">
        <Col xs={24} md={12} lg={16}>
          <h1 style={{ marginBottom: 0 }}>
            <BarsOutlined /> Pos Tahsilatı
          </h1>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}></Col>
      </Row>

      <Divider style={{ margin: "12px 0" }} />

      <Row id="paymentArea" justify="center">
        <Col md={16} lg={16} xl={12}>
          <Card title="Ödeme Formu" loading={availableProviders.loading || firmsBalance.loading}>
            {availableProviders.data.length > 0 ? (
              <Form
                {...formItemLayout}
                validateMessages={defaultValidateMessages}
                form={form}
                name="PaymentForm"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelAlign="left"
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Ödeme Sağlayıcı"
                      name="bankId"
                      initialValue={
                        availableProviders.data?.length > 0 &&
                        availableProviders.data[0].id
                      }
                      hidden={availableProviders.data.length === 1}
                    >
                      <Select onChange={value => onChangeValue(value)}>
                        {availableProviders.data?.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Cari"
                      name="firmId"
                    >
                      <Select
                        allowClear
                        showSearch
                        placeholder="Seçiniz"
                        onSearch={onFirmSeach}
                        filterOption={(input, option) =>
                          option.children
                            .toLocaleLowerCase("tr-TR")
                            .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                          option.code
                            .toLocaleLowerCase("tr-TR")
                            .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                        }
                        loading={firmSelect.loading}
                        onSelect={(value, option) => {
                          setActiveFirmId(value);

                          const firm = firmsBalance.data.find(x => x.code === option.code);
                          const balance = firm?.balance > 0 ? firm.balance : undefined;

                          setActiveFirmBalance(balance);
                          form.setFieldsValue({ ...form.getFieldsValue(), amount: balance });
                        }}
                      >
                        {firmSelect.data.map((item) => (
                          <Select.Option
                            key={item.id}
                            value={item.id}
                            code={item.firmBranchNumber}
                          >
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Müşteri Bilgisi"
                      name="customerInformation"
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  {renderCardInfoInputs()}

                  <Col span={24}>
                    <Form.Item
                      name="agreement"
                      valuePropName="checked"
                      rules={[
                        {
                          required: true,
                          message: "Sözleşmeleri kabul etmeniz gerekmektedir.",
                        },
                      ]}
                    >
                      <Checkbox>
                        <a
                          href={"/gizlilik-ve-guvenlik-politikasi.pdf"}
                          target="_blank"
                        >
                          Gizlilik
                        </a>{" "}
                        ve{" "}
                        <a href={"/tuketici-haklari.pdf"} target="_blank">
                          Tüketici Hakları
                        </a>{" "}
                        sözleşmelerini okudum, onaylıyorum.
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => setLoading(true)}
                      loading={loading}
                    >
                      Öde
                    </Button>
                  </Col>
                </Row>
              </Form>
            ) : (
              "Firmanızın tanımlı bir ödeme sağlayıcısı bulunmuyor."
            )}
          </Card>
        </Col>
      </Row>

      <Modal
        id="paymentModal"
        centered
        visible={paymentModal.visible}
        onCancel={() => setPaymentModal({ visible: false, source: null })}
        destroyOnClose={true}
        width={600}
        footer={null}
      >
        <iframe
          id="paymentFrame"
          ref={iframeRef}
          style={{ width: "100%", border: 0 }}
          srcDoc={paymentModal.source}
          sandbox="allow-same-origin allow-scripts allow-forms"
          scrolling="auto"
          onLoad={() => {
            const iframe = iframeRef.current;
            iframe.style.height = iframe.scrollWidth + 150 + "px";
          }}
        />
      </Modal>
    </>
  );
};

export default PosCollectionPage;
