import React, { useState, useEffect } from 'react'
import { Input, Col, Row, notification, Form, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import config from "../../util/ApiforContext"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios';
const expiration = localStorage.getItem("expiration");
const tailLayout = {
  wrapperCol: {
    offset: 15,
    span: 5,
  },
};
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};
const FirmClassUpdate = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [userId, setUserId] = useState(props.userId);
  const [firstName, setFirstName] = useState(props.firstName);
  const [lastName, setLastName] = useState(props.lastName);
  const [email, setEmail] = useState(props.email);
  const [erpSalesCode, setErpSalesCode] = useState(props.erpSalesCode);
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber);
  const [isTosAuthorize, setIsTosAuthorize] = useState(props.isTosAuthorize);
  const [tosAuthorizeId, setTosAuthorizeId] = useState(props.tosAuthorizeId);
  const [tosAuthorizeLimit, setTosAuthorizeLimit] = useState(props.tosAuthorizeLimit);
  const errorList = [];
  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload()
    }
    setUserId(props.userId)
    setFirstName(props.firstName)
    setLastName(props.lastName)
    setEmail(props.email)
    setTosAuthorizeId(props.tosAuthorizeId)
    setErpSalesCode(props.erpSalesCode)
    setIsTosAuthorize(props.isTosAuthorize)
    setPhoneNumber("+90" + props.phoneNumber)
    setTosAuthorizeLimit(props.tosAuthorizeLimit)
  }, [props.userId, props.firstName, props.lastName, props.email, props.tosAuthorizeId, props.isTosAuthorize, props.tosAuthorizeLimit, props.erpSalesCode, props.phoneNumber]);
  const onChangeFirstName = (e) => {
    setFirstName(e.target.value)
  }
  const onChangeLastName = (e) => {
    setLastName(e.target.value)
  }
  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }
  const onChangeErpSalesCode = (e) => {
    setErpSalesCode(e.target.value)
  }
  const onChangePhoneNumber = (e) => {
    setPhoneNumber(e)
  }
  const mailError = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Mail adresi zaten kayıtlı.',
      duration: 2,
    });
  };
  const errorListNotification = type => {
    errorList.map((item) => {
      notification[type]({
        message: 'Hata',
        description:
          item
        ,
        duration: 2,
      });
    })
  };
  const onFinishUpdate = values => {
    axios.put(`${config.apiUrl}/Auth`,
      {
        Id: userId,
        LastName: values.LastName,
        FirstName: values.FirstName,
        SalesCode: values.ErpSalesCode,
        Email: values.Email,
        PhoneNumber: values.PhoneNumber,
        Password: values.Password,
      },
      config.headers)
      .then(res => {
        if (res.status == 200) {
          if (values.TosAuthorizeLimit !== undefined && values.TosAuthorizeLimit !== 0 && values.TosAuthorizeLimit !== null) {
            axios.post(`${config.apiUrl}/TosAuthorize/UpdateTosLimit`,
              {
                TosAuthorizeLimit: values.TosAuthorizeLimit,
                Id: tosAuthorizeId
              },
              config.headers)
              .then(res => {
                if (res.status == 200) {

                  openNotificationWithIconUpdate('success');
                  setTimeout(function () {
                    props.sendDataForUpdate("success");
                    setLoading(false)
                  }.bind(this), 2000)
                }
              }).catch(
                error => {
                  if (error.response.status === 403 || error.response.status === 401) {
                    history.push('/not-authorized-access');
                  }
                  else if (error.response.status === 406) {
                    error.response.data.data.map((item) => {
                      errorList.push(item);
                    })
                    errorListNotification('error')
                    setLoading(false)
                  }
                  else {
                    openNotificationWithIconWarningUpdate('error')
                    setLoading(false)
                  }
                }
              );
          } else {
            openNotificationWithIconUpdate('success');
            setTimeout(function () {
              props.sendDataForUpdate("success");
              setLoading(false)
            }.bind(this), 2000)
          }

        }
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          }
          else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
            setLoading(false)
          }
          else {
            openNotificationWithIconWarningUpdate('error')
            setLoading(false)
          }
        }
      );
  };
  const onFinishFailedUpdate = errorInfo => {
    setLoading(false)
  };
  const openNotificationWithIconUpdate = type => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'Güncelleme işlemi başarı ile gerçekleşti.',
      duration: 2,
    });
  };
  const openNotificationWithIconWarningUpdate = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Güncelleme işlemi sırasında bir hata oluştu.',
      duration: 2,
    });
  };
  return (
    <div>
      {isDesktopOrLaptop && <>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishUpdate}
          onFinishFailed={onFinishFailedUpdate}
          fields={[
            { name: "FirstName", value: firstName },
            { name: "LastName", value: lastName },
            { name: "PhoneNumber", value: phoneNumber },
            { name: "Email", value: email },
            { name: "ErpSalesCode", value: erpSalesCode },
            { name: "TosAuthorizeLimit", value: tosAuthorizeLimit }
          ]}
        >
          <Row>
            <Col span={5}></Col>
            <Col span={15}>
              <Form.Item
                label="Ad"
                name="FirstName"
                style={{ marginTop: "4%" }}
                rules={[
                  {
                    required: true,
                    message: 'Lütfen Ad alanını boş bırakmayın.',
                  },
                ]}
              >
                <Input name={firstName} onChange={onChangeFirstName} />
              </Form.Item>
              <Form.Item
                label="Soyad"
                name="LastName"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen Soyad alanını boş bırakmayın.',
                  },
                ]}
              >
                <Input name={lastName} onChange={onChangeLastName} />
              </Form.Item>
              <Form.Item label="Şifre" name="Password">
                <Input.Password autoComplete="new-password" />
              </Form.Item>
              <Form.Item
                label="E-Posta Adresi"
                name="Email"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen E-Posta Adresi alanını boş bırakmayın.',
                  },
                ]}
              >
                <Input name={email} onChange={onChangeEmail} />
              </Form.Item>
              <Form.Item
                label="Satıcı Kodu"
                name="ErpSalesCode"
              >
                <Input name={erpSalesCode} onChange={onChangeErpSalesCode} />
              </Form.Item>
              <Form.Item
                label="Telefon Numarası"
                name="PhoneNumber"
                rules={[
                  {
                    min: 12,
                    message: 'Telefon numarasını eksiksiz giriniz.'
                  },
                  {
                    required: true,
                    message: 'Lütfen Telefon Numarası alanını boş bırakmayın.',
                  },
                ]}
              >
                <PhoneInput name={phoneNumber} onChange={onChangePhoneNumber}
                  inputStyle={{ width: "100%" }}
                  country='tr'
                  masks={{ tr: '(...) ...-..-..' }}
                />
              </Form.Item>
              {
                isTosAuthorize ?
                  <Form.Item
                    label="Tos limit"
                    name="TosAuthorizeLimit"
                  >
                    <Input name={tosAuthorizeLimit} type='number' onChange={(text) => { setTosAuthorizeLimit(text.target.value) }} value={tosAuthorizeLimit} />
                  </Form.Item>
                  : null
              }


            </Col>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={9}></Col>
            <Col span={7}>
              <Form.Item style={{ marginLeft: '250%' }} >
                <Button type="primary" icon={<PlusOutlined />} htmlType="submit" onClick={() => setLoading(true)} loading={loading}>
                  Güncelle
                </Button>
              </Form.Item>
            </Col>
            <Col span={2}></Col>
          </Row>
        </Form>
      </>}
      {isTabletOrMobileDevice && <>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishUpdate}
          onFinishFailed={onFinishFailedUpdate}
          fields={[
            { name: "FirstName", value: firstName },
            { name: "LastName", value: lastName },
            { name: "PhoneNumber", value: phoneNumber },
            { name: "Email", value: email },
            { name: "ErpSalesCode", value: erpSalesCode }
          ]}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Ad"
                name="FirstName"
                style={{ marginTop: "4%" }}
                rules={[
                  {
                    required: true,
                    message: 'Lütfen Ad alanını boş bırakmayın.',
                  },
                ]}
              >
                <Input name={firstName} onChange={onChangeFirstName} />
              </Form.Item>
              <Form.Item
                label="Soyad"
                name="LastName"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen Soyad alanını boş bırakmayın.',
                  },
                ]}
              >
                <Input name={lastName} onChange={onChangeLastName} />
              </Form.Item>
              <Form.Item
                label="E-Posta Adresi"
                name="Email"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen E-Posta Adresi alanını boş bırakmayın.',
                  },
                ]}
              >
                <Input name={email} onChange={onChangeEmail} />
              </Form.Item>
              <Form.Item
                label="Satıcı Kodu"
                name="ErpSalesCode"
              >
                <Input name={erpSalesCode} onChange={onChangeErpSalesCode} />
              </Form.Item>
              <Form.Item
                label="Telefon Numarası"
                name="PhoneNumber"
                rules={[
                  {
                    min: 12,
                    message: 'Telefon numarasını eksiksiz giriniz.'
                  },
                  {
                    required: true,
                    message: 'Lütfen Telefon Numarası alanını boş bırakmayın.',
                  },
                ]}
              >
                <PhoneInput name={phoneNumber} onChange={onChangePhoneNumber}
                  inputStyle={{ width: "100%" }}
                  country='tr'
                  masks={{ tr: '(...) ...-..-..' }}
                />
              </Form.Item>

            </Col>
            <Col span={10}></Col>
            <Col span={8}>
              <Form.Item {...tailLayout} >
                <Button type="primary" icon={<PlusOutlined />} htmlType="submit" onClick={() => setLoading(true)} loading={loading}>
                  Güncelle
                </Button>
              </Form.Item>
            </Col>
            <Col span={6}></Col>
          </Row>
        </Form>
      </>}

    </div>
  )
}
export default FirmClassUpdate