import React, { useState, useEffect } from "react";
import {
  Table,
  Empty,
  DatePicker,
  Select,
  Col,
  Row,
  Collapse,
  notification,
} from "antd";

import { useHistory } from "react-router-dom";
import config from "../../util/ApiforContext";
import CurrencyFormat from "react-currency-format";
import axios from "axios";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
const dateFormat = "DD/MM/YYYY";
function currencyFormat(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};
function disabledDate(current) {
  var d = new Date();
  var n = d.getFullYear();
  const start = moment(`${n - 2}-01-01`, "YYYY-MM-DD");
  const end = moment(`${n + 3}-01-01`, "YYYY-MM-DD");
  return current < start || current > end;
}
const expiration = localStorage.getItem("expiration");

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const SubscriberFirmCollectionStatus = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  const [firmMonthlyStatusReport, setFirmMonthlyStatusReport] = useState({
    data: [],
    total: 0,
    loading: true,
  });

  const [skip, setSkip] = useState(0);
  const [query, setQuery] = useState("");
  const [currencyString, setCurrencyString] = useState("");
  const [dataState, setDataState] = useState({ skip: 0, take: 20 });
  const [subscriberFirmId, setSubscriberFirmId] = useState(props.Id);
  const [firmNameWithResponsibleUser, setFirmNameWithResponsibleUser] =
    useState(props.SubscriberFirmNameWithResponsibleUser);

  const history = useHistory();
  const errorList = [];
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 2,
      });
    });
  };
  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }

    setFirmMonthlyStatusReport({
      ...firmMonthlyStatusReport,
      loading: true,
    });
    axios
      .post(
        `${config.apiUrl}/SubscriberFirm/GetMonthlyCollectionStatusPlanReports`,
        {
          FirmId: subscriberFirmId,
        },
        config.headers
      )
      .then((res) => {
        setCurrencyString("TRY");
        setFirmMonthlyStatusReport({
          data: res.data,
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  }, [
    setDataState,
    skip,
    setSkip,
    query,
    setQuery,
    setSubscriberFirmId,
    setFirmNameWithResponsibleUser,
  ]);

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "FirmNameAndResponsibleUser",
      align: "center",
      width: "15%",
      render: () => {
        return (
          <p key={firmNameWithResponsibleUser} style={{ fontWeight: "700" }}>
            {firmNameWithResponsibleUser}
          </p>
        );
      },
    },
    {
      title: "Tarih",
      dataIndex: "transactionDate",
      key: "transactionDate",
      align: "center",
      width: "10%",
      style: "color:green",
      render: (text, record) => {
        return (
          <p style={{ fontWeight: "700" }}>
            {text == "9999/01"
              ? " Toplam "
              : moment(
                text.split("/")[0] +
                (text.split("/")[1].length > 1
                  ? text.split("/")[1]
                  : "0" + text.split("/")[1]) +
                "13"
              ).format("MMM YYYY")}
          </p>
        );
      },
    },
    {
      title: "Tahsilat Planı",
      dataIndex: "collectPlanTotal",
      key: "collectPlanTotal",
      align: "center",
      width: "10%",
      render: (text, row, index) => {
        if (text === null) {
          return "-";
        } else {
          if (text.toString().includes(".") === true) {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </div>
            );
          } else {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
                {",00"}
              </div>
            );
          }
        }
      },
    },
    {
      title: "Gerçekleşen Tahsilat",
      dataIndex: "collectTotal",
      key: "collectTotal",
      align: "center",
      width: "10%",
      render: (text, row, index) => {
        if (text === null) {
          return "-";
        } else {
          if (text.toString().includes(".") === true) {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </div>
            );
          } else {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
                {",00"}
              </div>
            );
          }
        }
      },
    },
    {
      title: "Kalan Tahsilat",
      dataIndex: "collectMounthTotal",
      key: "collectMounthTotal",
      align: "center",
      width: "10%",
      render: (text, row, index) => {
        if (text === null || text.toString().includes("-") === true) {
          return "-";
        } else {
          if (text.toString().includes(".") === true) {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </div>
            );
          } else {
            return (
              <div>
                <CurrencyFormat
                  value={parseFloat(text)}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
                {",00"}
              </div>
            );
          }
        }
      },
    },
  ];

  return (
    <div>
      <Row>
        {isDesktopOrLaptop && (
          <>
            <Col span={2}></Col>
            <Col span={20}>
              <Table
                columns={columns}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Veri Yok"
                    />
                  ),
                }}
                style={{ overflowX: "auto", maxHeight: "100%" }}
                size="small"
                rowKey="transactionDate"
                sortable={true}
                dataSource={firmMonthlyStatusReport.data}
                loading={firmMonthlyStatusReport.loading}
                pagination={false}
              />
            </Col>
            <Col span={2}></Col>
          </>
        )}
        {isTabletOrMobileDevice && (
          <>
            <Col span={24}>
              <Table
                columns={columns}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Veri Yok"
                    />
                  ),
                }}
                style={{ overflowX: "auto", maxHeight: "100%" }}
                size="small"
                rowKey="transactionDate"
                sortable={true}
                dataSource={firmMonthlyStatusReport.data}
                loading={firmMonthlyStatusReport.loading}
                pagination={false}
              />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default SubscriberFirmCollectionStatus;
