import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Table,
  Empty,
  Drawer,
  Row,
  Col,
  Popconfirm,
  Tooltip,
} from "antd";
import {
  BankOutlined,
  CloseOutlined,
  DeleteOutlined,
  MenuUnfoldOutlined,
  EditOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import TenantAccountUpdateForm from "../Forms/TenantAccountUpdateForm";
import moment from "moment";
import RequestHandler from "util/RequestHandler";
import Replace2x from "../../util/Extensions/ReplaceExtension";
import { ColumnHelper, RowHelper } from "util/TableHelper";
import { odata, queryHelper } from "../Filters/Helper";
import TenantAccountTableFilter from "../Filters/TenantAccountTableFilter";

const userId = Replace2x(localStorage.getItem("userId"));
const BankPage = () => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });

  const [tableDataQueryOptions, setTableDataQueryOptions] = useState(queryHelper.getDefaultTableDataQueryOptions());
  const [updateFormData, setUpdateFormData] = useState();
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setFilterValues();
    getTableData();
  }, [tableDataQueryOptions]);

  useEffect(() => {
    RequestHandler.get(`/user/IsAdmin?userId=${userId}`).then(response => {
      setIsAdmin(response);
    });
  }, []);

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    let query = '';
    const { skip, take, filter, order } = tableDataQueryOptions;

    const conditions = [];
    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length > 0) {
      query = `&$filter=${conditions.join(" and ")}`;
    }

    RequestHandler.get(`/TenantAccount?$count=true${query}${order}&$skip=${skip}&$top=${take}`).then((response) => {
      if (Array.isArray(response.value)) {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      }
    });
  };

  const setFilterValues = () => {
    const filterValues = {
      ...tableDataQueryOptions.values,
      skip: tableDataQueryOptions.skip,
      take: tableDataQueryOptions.take,
      current: tableDataQueryOptions.current,
      order: tableDataQueryOptions.order,
      filter: tableDataQueryOptions.filter,
    };
    const queryString = queryHelper.getQueryFromValues(filterValues);

    history.replace({
      pathname: window.location.pathname,
      search: queryString
    });
  };

  const paging = (p, _, sorter) => {
    let orderValue = "";
    if (sorter.columnKey && sorter.order) {
      orderValue =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }

    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
        order: orderValue
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      ...tableDataQueryOptions,
      skip: 0,
      current: 1,
      filter: "",
      values: {}
    });
  };

  const onFinishTableFilter = (query, values) => {
    setTableDataQueryOptions((prevState) => {
      return { ...prevState, skip: 0, current: 1, filter: query, values: values };
    });
  };

  const handleOnClickDelete = (id) => {
    RequestHandler.post(`/TenantAccount/PassiveAccount/${id}`).then((response) => {
      if (response.success) {
        getTableData();
      }
    });
  };

  const showDrawerUpdate = (value) => {
    RequestHandler.get(`/TenantAccount/getbyid/${value}`).then((response) => {
      setUpdateFormData(response);
      setVisibleUpdate(true);
    });
  };

  const onCloseUpdate = () => setVisibleUpdate(false);

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      key: "bankLogo",
      render: (text, row, index) => {
        return (
          <div style={{ width: 100 }}>
            <img src={row.bankLogo}></img>
          </div>
        );
      },
    },
    {
      title: "Şube Adı / Kodu",
      dataIndex: "branchName",
      align: "center",
      key: "branchName",
      width: "15%",
      render: (text, row, index) => (
        <Tooltip placement="topLeft" title={text}>
          <div>
            {row.branchName == null
              ? row.branchCode == null
                ? ""
                : row.branchCode
              : row.branchName}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Hesap Adı",
      dataIndex: "name",
      align: "center",
      key: "name",
      width: "10%",
    },
    {
      title: "Iban / Hesap Numarası",
      dataIndex: "iban",
      align: "center",
      key: "iban",
      width: "15%",
      render: (text, row, index) => {
        {
          return (
            <div>
              {row.iban == null
                ? row.accountNumber == null
                  ? ""
                  : row.accountNumber
                : row.iban}
            </div>
          );
        }
      },
    },
    {
      title: "Döviz",
      dataIndex: "currency",
      align: "center",
      key: "currency",
      width: "10%",
    },
    {
      title: "Tutar",
      dataIndex: "balance",
      align: "left",
      key: "balance",
      sorter: true,
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Tip",
      dataIndex: "type",
      align: "center",
      key: "type",
      width: "10%",
      render: (text, row, index) => {
        if (row.typeValue != null) {
          return <div>{row.type}</div>;
        }
      },
    },
    {
      title: "Son Sorgulanma Tarihi",
      dataIndex: "updateDate",
      key: "updateDate",
      sorter: true,
      align: "center",
      render: (text, row, index) => {
        {
          return (
            <div>
              {moment(row.updateDate).format("L")}{" "}
              {moment(row.updateDate).format("LT")}
            </div>
          );
        }
      },
    },
    {
      title: "Detay",
      dataIndex: "",
      width: "2%",
      align: "center",
      render: (record, text) => {
        return (
          <Link
            style={{ fontSize: "150%" }}
            to={`/bank-accounts-account-transaction/${record.id}`}
          >
            <MenuUnfoldOutlined />
          </Link>
        );
      },
    },
    {
      title: "Hesaplanma",
      dataIndex: "",
      width: "2%",
      align: "center",
      render: (record, text) => {
        if (record.isCalculated == true) {
          return (
            <div style={{ color: "green" }}>
              <CheckOutlined />
            </div>
          );
        } else {
          return (
            <div style={{ color: "red" }}>
              <CloseOutlined />
            </div>
          );
        }
      },
    },
    {
      title: "Düzenle",
      dataIndex: "operation",
      width: "1%",
      align: "center",
      render: (text, record) =>
        tableData.data.length >= 1 ? (
          <a
            onClick={() => {
              showDrawerUpdate(record.id);
            }}
            style={{ color: "orange", fontSize: "130%" }}
          >
            <EditOutlined />
          </a>
        ) : null,
    },
    isAdmin === true ?
      {
        title: "Sil",
        dataIndex: "id",
        align: "center",
        render: (data, record) => (
          <Popconfirm
            title="Hesabınız pasife alınacaktır. Aktife almak için bizimle iletişime geçebilirsiniz."
            okText="Tamam, sil"
            cancelText="Hayır silme"
            onConfirm={() => handleOnClickDelete(data)}
          >
            <a>
              <DeleteOutlined style={{ color: "red", fontSize: "130%" }} />
            </a>
          </Popconfirm>
        ),
      } : {},
  ];

  const detailColumns = [
    {
      title: "Kullanılabilir Bakiye",
      dataIndex: "availableBalance",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Kredi Limiti",
      dataIndex: "creditLimit",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Kredi Bakiyesi",
      dataIndex: "creditBalance",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Bloke Tutarı",
      dataIndex: "blockedAmount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Vade Bitiş Tarihi",
      dataIndex: "dueDate",
      render: (data, row, index) =>
        data ? moment(data).format("DD.MM.YYYY") : "-",
    },
    {
      title: "Vade Faiz Oranı",
      dataIndex: "interestRate",
    },
    {
      title: "Hesap Açılış Tarihi",
      dataIndex: "openDate",
      render: (data, row, index) =>
        data ? moment(data).format("DD.MM.YYYY") : "-",
    },
  ];

  return (
    <>

      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BankOutlined /> HESAP LİSTESİ
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
        </Col>
      </Row>

      <TenantAccountTableFilter
        data={tableDataQueryOptions.values}
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            expandable={{
              expandedRowRender: (row) =>
                RowHelper.renderRowDetail({
                  data: row,
                  columns: detailColumns,
                }),
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%" }}
            rowKey="id"
            filterable={true}
            size="small"
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
          />
        </Col>
      </Row>

      <Drawer
        title="Banka Hesabı Düzenle"
        width={241}
        onClose={onCloseUpdate}
        visible={visibleUpdate}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <TenantAccountUpdateForm
          sendDataForUpdate={(val) => {
            if (val === "success") {
              getTableData();
              setVisibleUpdate(false);
            }
          }}
          data={updateFormData}
        />
      </Drawer>
    </>
  );
};

export default BankPage;
