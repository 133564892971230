import React, { useEffect,useState } from 'react'
import {Row,Col,Checkbox} from 'antd'
import RequestHandler from "util/RequestHandler";
import Screen from 'util/Extensions/Screens'
import Replace2x from 'util/Extensions/ReplaceExtension';
import {
    BarsOutlined,
    UnorderedListOutlined
  } from "@ant-design/icons";


 
const AccountTransactionRole = () => {

    const [userId, setUserId] = useState("");
    const [isBranchShow, setIsBranchShow] = useState(false);
    const [isIbanShow, setIsIbanShow] = useState(false);
    const [isCurrencyShow, setIsCurrencyShow] = useState(false);
    const [isTenantIbanShow, setIsTenantIbanShow] = useState(false);
    const [isTransactionTypeShow, setIsTransactionTypeShow] = useState(false);
    const [isVknTcknShow, setIsVknTcknShow] = useState(false);
    const [isReceiptShow, setIsReceiptShow] = useState(false);
    const [isAdditionalDescriptionShow, setIsAdditionalDescriptionShow] = useState(false);
    const [isSenderNameShow, setIsSenderNameShow] = useState(false);
    const [isBalanceAfterTransactionShow, setIsBalanceAfterTransactionShow] = useState(false);
    const [isDescriptionShow, setIsDescriptionShow] = useState(false);
    const [isFirmNameShow, setIsFirmNameShow] = useState(false);
    const [isFull, setIsFull] = useState(false);
    
    function onChangeBranch(checked) {
        RequestHandler.post(
            "/AccountTransaction/AddTransactionTableRoles",
            {
                IsBranchShow: checked.target.checked,
                IsIbanShow: isIbanShow,
                IsCurrencyShow: isCurrencyShow,
                IsTenantIbanShow: isTenantIbanShow,
                IsTransactionTypeShow: isTransactionTypeShow,
                IsVknTcknShow: isVknTcknShow,
                IsReceiptShow: isReceiptShow,
                IsAdditionalDescriptionShow: isAdditionalDescriptionShow,
                IsSenderNameShow: isSenderNameShow,
                IsBalanceAfterTransactionShow: isBalanceAfterTransactionShow,
                IsDescriptionShow: isDescriptionShow,
                IsFirmNameShow: isFirmNameShow,
            },
            true
          ).then((res) => {
            if(res.success){
                reLoadData();
              }
          });
      }
    function onChangeBalanceAfterTransaction(checked) {
        RequestHandler.post(
            "/AccountTransaction/AddTransactionTableRoles",
            {
                IsBranchShow: isBranchShow,
                IsIbanShow: isIbanShow,
                IsCurrencyShow: isCurrencyShow,
                IsTenantIbanShow: isTenantIbanShow,
                IsTransactionTypeShow: isTransactionTypeShow,
                IsVknTcknShow: isVknTcknShow,
                IsReceiptShow: isReceiptShow,
                IsAdditionalDescriptionShow: isAdditionalDescriptionShow,
                IsSenderNameShow: isSenderNameShow,
                IsBalanceAfterTransactionShow: checked.target.checked,
                IsDescriptionShow: isDescriptionShow,
                IsFirmNameShow: isFirmNameShow,
            },
            true
          ).then((res) => {
            if(res.success){
                reLoadData();
              }
          });
      }
    function onChangeCurrency(checked) {
        RequestHandler.post(
            "/AccountTransaction/AddTransactionTableRoles",
            {
                IsBranchShow: isBranchShow,
                IsIbanShow: isIbanShow,
                IsCurrencyShow: checked.target.checked,
                IsTenantIbanShow: isTenantIbanShow,
                IsTransactionTypeShow: isTransactionTypeShow,
                IsVknTcknShow: isVknTcknShow,
                IsReceiptShow: isReceiptShow,
                IsAdditionalDescriptionShow: isAdditionalDescriptionShow,
                IsSenderNameShow: isSenderNameShow,
                IsBalanceAfterTransactionShow: isBalanceAfterTransactionShow,
                IsDescriptionShow: isDescriptionShow,
                IsFirmNameShow: isFirmNameShow,
            },
            true
          ).then((res) => {
            if(res.success){
                reLoadData();
              }
          });
      }
      function onChangeTenantIban(checked) {
        RequestHandler.post(
            "/AccountTransaction/AddTransactionTableRoles",
            {
                IsBranchShow: isBranchShow,
                IsIbanShow: isIbanShow,
                IsCurrencyShow: isCurrencyShow,
                IsTenantIbanShow: checked.target.checked,
                IsTransactionTypeShow: isTransactionTypeShow,
                IsVknTcknShow: isVknTcknShow,
                IsReceiptShow: isReceiptShow,
                IsAdditionalDescriptionShow: isAdditionalDescriptionShow,
                IsSenderNameShow: isSenderNameShow,
                IsBalanceAfterTransactionShow: isBalanceAfterTransactionShow,
                IsDescriptionShow: isDescriptionShow,
                IsFirmNameShow: isFirmNameShow,
            },
            true
          ).then((res) => {
            if(res.success){
                reLoadData();
              }
          });
      }
      function onChangeIban(checked) {
        RequestHandler.post(
            "/AccountTransaction/AddTransactionTableRoles",
            {
                IsBranchShow: isBranchShow,
                IsIbanShow: checked.target.checked,
                IsCurrencyShow: isCurrencyShow,
                IsTenantIbanShow: isTenantIbanShow,
                IsTransactionTypeShow: isTransactionTypeShow,
                IsVknTcknShow: isVknTcknShow,
                IsReceiptShow: isReceiptShow,
                IsAdditionalDescriptionShow: isAdditionalDescriptionShow,
                IsSenderNameShow: isSenderNameShow,
                IsBalanceAfterTransactionShow: isBalanceAfterTransactionShow,
                IsDescriptionShow: isDescriptionShow,
                IsFirmNameShow: isFirmNameShow,
            },
            true
          ).then((res) => {
              if(res.success){
                reLoadData();
              }
          });
      }
      function onChangeDescription(checked) {
        RequestHandler.post(
            "/AccountTransaction/AddTransactionTableRoles",
            {
                IsBranchShow: isBranchShow,
                IsIbanShow: isIbanShow,
                IsCurrencyShow: isCurrencyShow,
                IsTenantIbanShow: isTenantIbanShow,
                IsTransactionTypeShow: isTransactionTypeShow,
                IsVknTcknShow: isVknTcknShow,
                IsReceiptShow: isReceiptShow,
                IsAdditionalDescriptionShow: isAdditionalDescriptionShow,
                IsSenderNameShow: isSenderNameShow,
                IsBalanceAfterTransactionShow: isBalanceAfterTransactionShow,
                IsDescriptionShow: checked.target.checked,
                IsFirmNameShow: isFirmNameShow,
            },
            true
          ).then((res) => {
            if(res.success){
                reLoadData();
              }
          });
      }
      function onChangeFirmName(checked) {
        RequestHandler.post(
            "/AccountTransaction/AddTransactionTableRoles",
            {
                IsBranchShow: isBranchShow,
                IsIbanShow: isIbanShow,
                IsCurrencyShow: isCurrencyShow,
                IsTenantIbanShow: isTenantIbanShow,
                IsTransactionTypeShow: isTransactionTypeShow,
                IsVknTcknShow: isVknTcknShow,
                IsReceiptShow: isReceiptShow,
                IsAdditionalDescriptionShow: isAdditionalDescriptionShow,
                IsSenderNameShow: isSenderNameShow,
                IsBalanceAfterTransactionShow: isBalanceAfterTransactionShow,
                IsDescriptionShow: isDescriptionShow,
                IsFirmNameShow: checked.target.checked,
            },
            true
          ).then((res) => {
            if(res.success){
                reLoadData();
              }
          });
      }
      function onChangeTransactionType(checked) {
        RequestHandler.post(
            "/AccountTransaction/AddTransactionTableRoles",
            {
                IsBranchShow: isBranchShow,
                IsIbanShow: isIbanShow,
                IsCurrencyShow: isCurrencyShow,
                IsTenantIbanShow: isTenantIbanShow,
                IsTransactionTypeShow: checked.target.checked,
                IsVknTcknShow: isVknTcknShow,
                IsReceiptShow: isReceiptShow,
                IsAdditionalDescriptionShow: isAdditionalDescriptionShow,
                IsSenderNameShow: isSenderNameShow,
                IsBalanceAfterTransactionShow: isBalanceAfterTransactionShow,
                IsDescriptionShow: isDescriptionShow,
                IsFirmNameShow: isFirmNameShow,
            },
            true
          ).then((res) => {
            if(res.success){
                reLoadData();
              }
          });
      }
      function onChangeSenderName(checked) {
        RequestHandler.post(
            "/AccountTransaction/AddTransactionTableRoles",
            {
                IsBranchShow: isBranchShow,
                IsIbanShow: isIbanShow,
                IsCurrencyShow: isCurrencyShow,
                IsTenantIbanShow: isTenantIbanShow,
                IsTransactionTypeShow: isTransactionTypeShow,
                IsVknTcknShow: isVknTcknShow,
                IsReceiptShow: isReceiptShow,
                IsAdditionalDescriptionShow: isAdditionalDescriptionShow,
                IsSenderNameShow: checked.target.checked,
                IsBalanceAfterTransactionShow: isBalanceAfterTransactionShow,
                IsDescriptionShow: isDescriptionShow,
                IsFirmNameShow: isFirmNameShow,
            },
            true
          ).then((res) => {
            if(res.success){
                reLoadData();
              }
          });
      }
      function onChangeReceiptNo(checked) {
        RequestHandler.post(
            "/AccountTransaction/AddTransactionTableRoles",
            {
                IsBranchShow: isBranchShow,
                IsIbanShow: isIbanShow,
                IsCurrencyShow: isCurrencyShow,
                IsTenantIbanShow: isTenantIbanShow,
                IsTransactionTypeShow: isTransactionTypeShow,
                IsVknTcknShow: isVknTcknShow,
                IsReceiptShow: checked.target.checked,
                IsAdditionalDescriptionShow: isAdditionalDescriptionShow,
                IsSenderNameShow: isSenderNameShow,
                IsBalanceAfterTransactionShow: isBalanceAfterTransactionShow,
                IsDescriptionShow: isDescriptionShow,
                IsFirmNameShow: isFirmNameShow,
            },
            true
          ).then((res) => {
            if(res.success){
                reLoadData();
              }
          });
      }
      function onChangeVkn(checked) {
        RequestHandler.post(
            "/AccountTransaction/AddTransactionTableRoles",
            {
                IsBranchShow: isBranchShow,
                IsIbanShow: isIbanShow,
                IsCurrencyShow: isCurrencyShow,
                IsTenantIbanShow: isTenantIbanShow,
                IsTransactionTypeShow: isTransactionTypeShow,
                IsVknTcknShow: checked.target.checked,
                IsReceiptShow: isReceiptShow,
                IsAdditionalDescriptionShow: isAdditionalDescriptionShow,
                IsSenderNameShow: isSenderNameShow,
                IsBalanceAfterTransactionShow: isBalanceAfterTransactionShow,
                IsDescriptionShow: isDescriptionShow,
                IsFirmNameShow: isFirmNameShow,
            },
            true
          ).then((res) => {
            if(res.success){
                reLoadData();
              }
          });
      }
      function onChangeFull(checked) {
        RequestHandler.post(
            "/AccountTransaction/AddTransactionTableRoles",
            {
                IsBranchShow: checked.target.checked,
                IsIbanShow: checked.target.checked,
                IsCurrencyShow: checked.target.checked,
                IsTenantIbanShow: checked.target.checked,
                IsTransactionTypeShow: checked.target.checked,
                IsVknTcknShow: checked.target.checked,
                IsReceiptShow: checked.target.checked,
                IsAdditionalDescriptionShow: checked.target.checked,
                IsSenderNameShow: checked.target.checked,
                IsBalanceAfterTransactionShow: checked.target.checked,
                IsDescriptionShow: checked.target.checked,
                IsFirmNameShow: checked.target.checked,
            },
            true
          ).then((res) => {
            if(res.success){
                reLoadData();
              }
          });
          setIsBranchShow(checked.target.checked)
          setIsIbanShow(checked.target.checked)
          setIsCurrencyShow(checked.target.checked)
          setIsTenantIbanShow(checked.target.checked)
          setIsTransactionTypeShow(checked.target.checked)
          setIsVknTcknShow(checked.target.checked)
          setIsReceiptShow(checked.target.checked)
          setIsAdditionalDescriptionShow(checked.target.checked)
          setIsSenderNameShow(checked.target.checked)
          setIsBalanceAfterTransactionShow(checked.target.checked)
          setIsDescriptionShow(checked.target.checked)
          setIsFirmNameShow(checked.target.checked)
          setIsFull(checked.target.checked)
      }
      function onChangeAdditionalDescription(checked) {
        RequestHandler.post(
            "/AccountTransaction/AddTransactionTableRoles",
            {
                IsBranchShow: isBranchShow,
                IsIbanShow: isIbanShow,
                IsCurrencyShow: isCurrencyShow,
                IsTenantIbanShow: isTenantIbanShow,
                IsTransactionTypeShow: isTransactionTypeShow,
                IsVknTcknShow: isVknTcknShow,
                IsReceiptShow: isReceiptShow,
                IsAdditionalDescriptionShow: checked.target.checked,
                IsSenderNameShow: isSenderNameShow,
                IsBalanceAfterTransactionShow: isBalanceAfterTransactionShow,
                IsDescriptionShow: isDescriptionShow,
                IsFirmNameShow: isFirmNameShow,
            },
            true
          ).then((res) => {
            if(res.success){
                reLoadData();
              }
          });
      }
      function reLoadData(){
        RequestHandler.get(`/AccountTransaction/GetTransactionTableRole`).then((response) => {
            if (response.success) {
                setIsAdditionalDescriptionShow(response.data?.isAdditionalDescriptionShow)
                setIsBalanceAfterTransactionShow(response.data?.isBalanceAfterTransactionShow)
                setIsBranchShow(response.data?.isBranchShow)
                setIsCurrencyShow(response.data?.isCurrencyShow)
                setIsDescriptionShow(response.data?.isDescriptionShow)
                setIsFirmNameShow(response.data?.isFirmNameShow)
                setIsIbanShow(response.data?.isIbanShow)
                setIsReceiptShow(response.data?.isReceiptShow)
                setIsSenderNameShow(response.data?.isSenderNameShow)
                setIsTenantIbanShow(response.data?.isTenantIbanShow)
                setIsTransactionTypeShow(response.data?.isTransactionTypeShow)
                setIsVknTcknShow(response.data?.isVknTcknShow)
                if(
                  response.data?.isAdditionalDescriptionShow && 
                  response.data?.isBalanceAfterTransactionShow && 
                  response.data?.isBranchShow && 
                  response.data?.isCurrencyShow && 
                  response.data?.isDescriptionShow && 
                  response.data?.isFirmNameShow && 
                  response.data?.isIbanShow && 
                  response.data?.isReceiptShow && 
                  response.data?.isSenderNameShow && 
                  response.data?.isTenantIbanShow && 
                  response.data?.isTransactionTypeShow && 
                  response.data?.isVknTcknShow
                ){
                  setIsFull(true)
                }else{
                  setIsFull(false)
                }
            }
          });
      }
    useEffect(() => {
        setUserId(userId);
        reLoadData();
      }, [
          setIsAdditionalDescriptionShow,
          setIsBalanceAfterTransactionShow,
          setIsBranchShow,
          setIsCurrencyShow,
          setIsDescriptionShow,
          setIsFirmNameShow,
          setIsIbanShow,
          setIsReceiptShow,
          setIsSenderNameShow,
          setIsTenantIbanShow,
          setIsTransactionTypeShow,
          setIsVknTcknShow,
          reLoadData
        ])
        return (
            <div>
                 <Row>
                    <Col style={{paddingBottom:60}} xl={24} lg={24} md={24} sm={12} xs={24}>
                    <p style={{ fontSize: "120%" }}>
                        <BarsOutlined /> HESAP HAREKETLERİ KOLON PARAMETRELERİ
                    </p>
                    </Col>
                    <Col style={{textAlign:'right', marginBottom:30}} xl={12} lg={12} md={12} sm={12} xs={12}>
                        Tümünü Seç : 
                    </Col>
                    <Col style={{textAlign:'left', marginBottom:30}} xl={12} lg={12} md={12} sm={12} xs={12}>
                         <Checkbox checked={isFull} onChange={onChangeFull} />
                    </Col>
                    
                    <Col xl={2} lg={2} md={2} >
                    </Col>


                    <Col style={{textAlign:'right',marginBottom:10}} xl={6} lg={6} md={6} sm={12} xs={12}>
                        Şube 
                    </Col>
                    <Col style={{textAlign:'left'}} xl={2} lg={2} md={2} sm={12} xs={12}>
                         <Checkbox checked={isBranchShow} onChange={onChangeBranch} />
                    </Col>

                    <Col style={{textAlign:'right'}} xl={6} lg={6} md={6} sm={12} xs={12}>
                    Karşı Iban / Hesap Numarası 
                    </Col>
                    <Col style={{textAlign:'left'}} xl={2} lg={2} md={2} sm={12} xs={12}>
                         <Checkbox checked={isIbanShow} onChange={onChangeIban} />
                    </Col>
                    <Col xl={6} lg={6} md={6} >
                    </Col>

                    <Col xl={2} lg={2} md={2} >
                    </Col>
                    <Col style={{textAlign:'right',marginBottom:10}} xl={6} lg={6} md={6} sm={12} xs={12}>
                    Iban / Hesap Numarası 
                    </Col>
                    <Col style={{textAlign:'left'}} xl={2} lg={2} md={2} sm={12} xs={12}>
                         <Checkbox checked={isTenantIbanShow} onChange={onChangeTenantIban} />
                    </Col>

                    <Col style={{textAlign:'right'}} xl={6} lg={6} md={6} sm={12} xs={12}>
                    Ek Açıklama 
                    </Col>
                    <Col style={{textAlign:'left'}} xl={2} lg={2} md={2} sm={12} xs={12}>
                        <Checkbox checked={isAdditionalDescriptionShow} onChange={onChangeAdditionalDescription} />
                    </Col>
                    <Col xl={6} lg={6} md={6} >
                    </Col>

                    <Col xl={2} lg={2} md={2} >
                    </Col>
                    <Col style={{textAlign:'right',marginBottom:10}} xl={6} lg={6} md={6} sm={12} xs={12}>
                    İşlem Sonrası Bakiye 
                    </Col>
                    <Col style={{textAlign:'left'}} xl={2} lg={2} md={2} sm={12} xs={12}>
                         <Checkbox checked={isBalanceAfterTransactionShow} onChange={onChangeBalanceAfterTransaction} />
                    </Col>



                    <Col style={{textAlign:'right'}} xl={6} lg={6} md={6} sm={12} xs={12}>
                    İşlem Tipi 
                    </Col>
                    <Col style={{textAlign:'left'}} xl={2} lg={2} md={2} sm={12} xs={12}>
                         <Checkbox checked={isTransactionTypeShow} onChange={onChangeTransactionType} />
                    </Col>

                    <Col xl={6} lg={6} md={6} >
                    </Col>

                    <Col xl={2} lg={2} md={2} >
                    </Col>

                    <Col style={{textAlign:'right',marginBottom:10}} xl={6} lg={6} md={6} sm={12} xs={12}>
                    Cari Adı 
                    </Col>
                    <Col style={{textAlign:'left'}} xl={2} lg={2} md={2} sm={12} xs={12}>
                         <Checkbox checked={isFirmNameShow} onChange={onChangeFirmName} />
                    </Col>  



                    <Col style={{textAlign:'right'}} xl={6} lg={6} md={6} sm={12} xs={12}>
                    Gönderen Adı 
                    </Col>
                    <Col style={{textAlign:'left'}} xl={2} lg={2} md={2} sm={12} xs={12}>
                         <Checkbox checked={isSenderNameShow} onChange={onChangeSenderName} />
                    </Col>

                    <Col xl={6} lg={6} md={6} >
                    </Col>

                    <Col xl={2} lg={2} md={2} >
                    </Col>

                    <Col style={{textAlign:'right',marginBottom:10}} xl={6} lg={6} md={6} sm={12} xs={12}>
                    Döviz 
                    </Col>
                    <Col style={{textAlign:'left'}} xl={2} lg={2} md={2} sm={12} xs={12}>
                         <Checkbox checked={isCurrencyShow} onChange={onChangeCurrency} />
                    </Col>



                   <Col style={{textAlign:'right'}} xl={6} lg={6} md={6} sm={12} xs={12}>
                    Dekont No 
                    </Col>
                    <Col style={{textAlign:'left'}} xl={2} lg={2} md={2} sm={12} xs={12}>
                         <Checkbox checked={isReceiptShow} onChange={onChangeReceiptNo} />
                    </Col>

                    <Col xl={6} lg={6} md={6} >
                    </Col>

                    <Col xl={2} lg={2} md={2} >
                    </Col>
                   
                    <Col style={{textAlign:'right',marginBottom:10}} xl={6} lg={6} md={6} sm={12} xs={12}>
                    Açıklama 
                    </Col>
                    <Col style={{textAlign:'left'}} xl={2} lg={2} md={2} sm={12} xs={12}>
                         <Checkbox checked={isDescriptionShow} onChange={onChangeDescription} />
                    </Col>
                    <Col style={{textAlign:'right',marginBottom:10}} xl={6} lg={6} md={6} sm={12} xs={12}>
                    Vkc / T.C. No 
                    </Col>
                    <Col style={{textAlign:'left'}} xl={2} lg={2} md={2} sm={12} xs={12}>
                         <Checkbox checked={isVknTcknShow} onChange={onChangeVkn} />
                    </Col>
                </Row>
            </div>
        )
}
export default AccountTransactionRole