import React, { useState, useEffect } from "react";
import RequestHandler from "util/RequestHandler";
import PosBankInfoAddForm from "../Forms/PosBankInfoAddForm";
import PosBankInfoUpdateForm from "../Forms/PosBankInfoUpdateForm";

import { Table, Empty, Popconfirm, Col, Row, Drawer } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

function PosInfo(props) {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
  });

  const [addFormVisibility, setAddFormVisibility] = useState(false);
  const [updateFormVisibility, setUpdateFormVisibility] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({});

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  useEffect(() => {
    if (props.addButtonEvent > 0) {
      setAddFormVisibility(true);
    }
  }, [props.addButtonEvent]);

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    const { skip, take } = tableDataQueryOptions;

    RequestHandler.get(
      `/PosBankInfo?$count=true&$skip=${skip}&$top=${take}`
    ).then((response) => {
      setTableData({
        data: response.value,
        total: response["@odata.count"],
        loading: false,
      });
    });
  };

  const getUpdateFormData = (id) => {
    RequestHandler.get(`/PosBankInfo/GetById/${id}`).then((response) => {
      if (response) {
        setUpdateFormData(response);
        setUpdateFormVisibility(true);
      }
    });
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const handleOnClickDelete = (id) => {
    RequestHandler.delete(`/PosBankInfo/${id}`).then((response) => {
      if (response.success) {
        getTableData();
      }
    });
  };

  const onAddFormSuccess = () => {
    getTableData();
    setAddFormVisibility(false);
  };

  const onUpdateFormSuccess = () => {
    getTableData();
    setUpdateFormVisibility(false);
  };

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      key: "bankLogo",
      width: "8%",
      render: (text, row, index) => {
        {
          if (text === "KASA HESABI") {
            return <img src={row.bankLogo}></img>;
          }
          return <img src={row.bankLogo}></img>;
        }
      },
    },
    {
      title: "Müşteri Numarası",
      dataIndex: "customerNumber",
    },
    {
      title: "Kullanıcı Adı",
      dataIndex: "userName",
    },
    {
      title: "Düzenle",
      dataIndex: "id",
      width: "1%",
      align: "center",
      render: (data, row) => (
        <a onClick={() => getUpdateFormData(data)}>
          <EditOutlined style={{ color: "orange", fontSize: "130%" }} />
        </a>
      ),
    },
    {
      title: "Sil",
      dataIndex: "id",
      width: "1%",
      align: "center",
      render: (data, row) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleOnClickDelete(data)}
        >
          <a>
            <DeleteOutlined style={{ color: "red", fontSize: "130%" }} />
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Drawer
        id="addDrawer"
        title="Pos Ekle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setAddFormVisibility(false)}
        visible={addFormVisibility}
      >
        <PosBankInfoAddForm onSuccess={onAddFormSuccess} />
      </Drawer>

      <Drawer
        id="updateDrawer"
        title="Pos Güncelle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setUpdateFormVisibility(false)}
        visible={updateFormVisibility}
      >
        <PosBankInfoUpdateForm
          data={updateFormData}
          onSuccess={onUpdateFormSuccess}
        />
      </Drawer>
    </>
  );
}

export default PosInfo;
