import React, { useState } from "react";
import { Layout, Popover } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "../languageData";
import UserInfo from "components/UserInfo";
import TenantIndex from "../index";
import HorizontalNav from "../HorizontalNav";
import Screen from "../../../util/Extensions/Screens";
import { Link } from "react-router-dom";
import {
  switchLanguage,
  toggleCollapsedSideNav,
} from "../../../appRedux/actions/Setting";
import { useMediaQuery } from "react-responsive";
import AppNotification from "components/AppNotification";
import config from "util/ApiforContext";

const { Header } = Layout;

const InsideHeader = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const locale = useSelector(({ settings }) => settings.locale);
  const navCollapsed = useSelector(({ settings }) => settings.navCollapsed);
  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={(e) => dispatch(switchLanguage(language))}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };

  return (
    <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
      <Header
        style={{ backgroundColor: "transparent" }}
        className="gx-header-horizontal-main"
      >
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
              <i
                className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                }}
              />
            </div>
            {Screen.screen === 0 ? (
              <div>
                <Link
                  to="/home"
                  className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
                >
                  <img
                    alt=""
                    style={{ width: 100 }}
                    src={require("assets/images/adosoftlogo_white.png")}
                  />{" "}
                </Link>
                <Link
                  to="/home"
                  className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
                >
                  <img
                    alt=""
                    style={{ width: 125 }}
                    src={require("assets/images/adosoftlogo_white.png")}
                  />{" "}
                </Link>
              </div>
            ) :
              (
                Screen.screen === 1 ?
                  (
                    <div>
                      <Link
                        to="/home"
                        className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
                      >
                        <img
                          alt="paratic"
                          title="imzapos"
                          style={{ width: 100, marginBottom: 15 }}
                          src={require("assets/images/imzaposLogoBeyaz.png")}
                        />
                      </Link>
                      <Link
                        to="/home"
                        className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
                      >
                        <img
                          alt="paratic"
                          title="imzapos"
                          style={{ width: 125 }}
                          src={require("assets/images/imzaposLogoBeyaz.png")}
                        />{" "}
                      </Link>
                    </div>
                  )
                  :
                  (
                    <div>
                      <Link
                        to="/home"
                        className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
                      >
                        <img
                          alt="paratic"
                          title="Bakiyem"
                          style={{ width: 100, marginBottom: 15 }}
                          src={require("assets/images/logoBakiyemWhite.png")}
                        />
                      </Link>
                      <Link
                        to="/home"
                        className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
                      >
                        <img
                          alt="paratic"
                          title="Bakiyem"
                          style={{ width: 125 }}
                          src={require("assets/images/logoBakiyemWhite.png")}
                        />{" "}
                      </Link>
                    </div>
                  )
              )
            }
            <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
              <HorizontalNav />
            </div>
            <ul
              className="gx-header-notifications gx-ml-auto"
              style={{
                marginRight: useMediaQuery({
                  query: "(max-width: 980px)",
                })
                  ? 0
                  : -52,
                marginTop: -3,
              }}
            >
              {config.renderNotifications && (
                <li className="gx-notify">
                  <AppNotification />
                </li>
              )}
              <li>
                <UserInfo />
              </li>
              <li>
                <TenantIndex />
              </li>
            </ul>
          </div>
        </div>
      </Header>
    </div>
  );
};

const mapStateToProps = ({ settings }) => {
  const { locale, navCollapsed } = settings;
  return { locale, navCollapsed };
};
export default connect(mapStateToProps, {
  toggleCollapsedSideNav,
  switchLanguage,
})(InsideHeader);
