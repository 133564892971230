import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import {
  defaultValidateMessages,
  onInputMaxLengthCheck,
  onKeyDownNumberOnly,
} from "./Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const TosBankInfoAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: false,
  });
  const [activeBankId, setActiveBankId] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    getNotAddedBanks();
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const getNotAddedBanks = () => {
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    
    RequestHandler.get(`/Bank/GetTosBankDropdown`).then((response) => {
      setBankSelect({
        data: response.filter((x) => x.id !== 999 && x.isTosSupport == 1),
        loading: false,
      });
    });
  };
  

  const onFinish = (values) => {
    RequestHandler.post(`/TosBankInfo`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        getNotAddedBanks();
        form.resetFields();
        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  const onBankChange = (bankId) => {
    setActiveBankId(bankId);
  };


  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="tosBankInfoAdd"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="bankId" label="Banka" rules={[{ required: true }]}>
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              onChange={onBankChange}
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              loading={bankSelect.loading}
            >
              {bankSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

            <>
                <Form.Item
                  label={
                    "Müşteri Numarası"
                  }
                  name="customerNumber"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input maxLength="125" onInput={onInputMaxLengthCheck} />
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }} label="Host" required>
                  <Input.Group>
                    <Row gutter={2}>
                      <Col span={18}>
                          <Form.Item
                            name="host"
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "'Host' alanı gerekli.",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                      </Col>
                      <Col span={6}>
                          <Form.Item
                            name="port"
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "'Port' alanı gerekli.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Port"
                              onKeyDown={(e) => onKeyDownNumberOnly(e)}
                              maxLength="5"
                              onInput={onInputMaxLengthCheck}
                            />
                          </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Form.Item>

                <Form.Item
                  label={
                    "Gelen Dosya Yolu"
                  }
                  name="UploadFolder"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={
                    "Giden Dosya Yolu"
                  }
                  name="DownloadFolder"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={
                    "Kullanıcı Adı"
                  }
                  name="userName"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Şifre"
                  name="password"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
            </>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Ekle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default TosBankInfoAddForm;
