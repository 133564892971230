import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Select, InputNumber, DatePicker, Spin, notification, Upload } from "antd";
import { PlusOutlined, UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import config from "../../util/ApiforContext";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";
import { odata } from "../Filters/Helper";
import moment from "moment";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const ManualAccountAdd = (props) => {
  const [loading, setLoading] = useState(false);
  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });
  const [manualTransactionTypeSelect, setManualTransactionTypeSelect] = useState({
    data: [],
    loading: false,
  });
  const [transactionProcessTypeSelect, setTransactionProcessTypeSelect] = useState({
    data: [],
    loading: false,
  });
  const [currencySelect, setCurrencySelect] = useState({
    data: [],
    loading: true,
  });
  const [tahsilatButton, setTahsilatButton] = useState(false);
  const [odemeButton, setOdemeButton] = useState(false);
  const [spinLoading, setSpinLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getManualTransactionTypes();
    getTransactionProcessTypes();
    getCurrencies();
  }, []);

  useEffect(() => {
    if (props.data) {
      getFirmByIdForSelect(props.data.firmId);
      props.data.transactionDate = moment(props.data.transactionDateValue);
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const getManualTransactionTypes = () => {
    setManualTransactionTypeSelect({
      ...manualTransactionTypeSelect,
      loading: true,
    });
    RequestHandler.get(`/ManualTransactionType?$select=id,name`).then((response) => {
      setManualTransactionTypeSelect({
        data: response.value,
        loading: false,
      });
    });
  };

  const getTransactionProcessTypes = () => {
    setTransactionProcessTypeSelect({
      ...transactionProcessTypeSelect,
      loading: true,
    });
    RequestHandler.get(`/Types/GetManualTransactionTypes`).then((response) => {
      setTransactionProcessTypeSelect({
        data: response,
        loading: false,
      });
    });
  };

  const getCurrencies = () => {
    setCurrencySelect({
      data: [],
      loading: true,
    });
    RequestHandler.get(`/Currency?$select=id,code`).then(
      (response) => {
        setCurrencySelect({
          data: response.value,
          loading: false,
        });
      }
    );
  };

  const getFirmByIdForSelect = (firmId) => {
    setFirmSelect({
      ...firmSelect,
      loading: true,
    });
    RequestHandler.get(`/Firm?$select=id,firmBranchNumber,name&$filter=id eq ${firmId}`).then((response) => {
      setFirmSelect({
        data: response.value,
        loading: false,
      });
    });
  };

  const onFirmSeach = (value) => {
    if (value.length >= 2) {
      setFirmSelect({ ...firmSelect, loading: true });

      RequestHandler.get(`/Firm?$select=id,firmBranchNumber,name&$filter=${odata.contains("name", value)} or ${odata.contains("firmBranchNumber", value)}`).then((res) => {
        setFirmSelect({ data: res.value, loading: false });
      });
    } else {
      setFirmSelect({ data: [], loading: false });
    }
  };

  const onSuccess = () => {
    form.resetFields();
    setFileList([]);

    if (props.onSuccess) props.onSuccess();
  };

  const onFinish = (values) => {
    values.transactionDate = values.transactionDate.format("YYYY-MM-DD");
    values.amountString = values.amount.toLocaleString("tr-TR");

    RequestHandler.post(`/ManualAccountTransaction`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        onSuccess();
      }
    });
  };

  const transactionProcessTypeOnChange = (value) => {
    if (value === 1) {
      setTahsilatButton(true)
      setOdemeButton(false)
    }
    else if (value === 2) {
      setOdemeButton(true)
      setTahsilatButton(false)
    }

    setFileList([]);
  }

  const propsTahsilat = {
    name: 'excel',
    action: `${config.apiUrl}/ManualAccountTransaction/ImportExcel?type=1`,
    headers: { "Authorization": "bearer " + RequestHandler.getToken() },
    accept: ".xlsx",
    maxCount: 1,
    onChange(info) {
      setFileList(info.fileList);
      setSpinLoading(true);
      const { response, status } = info.file;

      if (status !== "error" && status !== "done") return;
      if (!response) return;

      const notificationType = response.success ? "success" : "error";

      notification[notificationType]({
        message: response.message,
        duration: 3,
      });
      setSpinLoading(false);
      if (!response.success) {
        downloadImportResult(info.file.response.data);
      }
      onSuccess();
    },
  };

  const propsOdeme = {
    name: 'excel',
    action: `${config.apiUrl}/ManualAccountTransaction/ImportExcel?type=2`,
    headers: { "Authorization": "bearer " + RequestHandler.getToken() },
    accept: ".xlsx",
    maxCount: 1,
    onChange(info) {
      setFileList(info.fileList);
      setSpinLoading(true);
      const { response, status } = info.file;

      if (status !== "error" && status !== "done") return;
      if (!response) return;

      const notificationType = response.success ? "success" : "error";

      notification[notificationType]({
        message: response.message,
        duration: 3,
      });
      setSpinLoading(false);
      if (!response.success) {
        downloadImportResult(info.file.response.data);
      }
      onSuccess();
    },
  };

  const downloadImportResult = (fileName) => {
    window.open(
      `${config.apiUrl}/ManualAccountTransaction/DownloadImportResult?t=${RequestHandler.getToken()}&fileName=${fileName}`
    );
  };

  const downloadExcel = () => {
    window.open(`${config.apiUrl}/ManualAccountTransaction/DownloadExcel?t=${RequestHandler.getToken()}`)
  }

  return (
    <Spin tip="Excel yükleniyor..." spinning={spinLoading}>
      <Form
        {...formItemLayout}
        validateMessages={defaultValidateMessages}
        form={form}
        name="dbsDealerUpdate"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={() => setLoading(false)}
      >
        <Row>
          <Col span={24}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>

            <Form.Item label="Ödeme/Tahsilat Tipi" name="transactionProcessType" rules={[{ required: true }]}>
              <Select
                allowClear
                showSearch
                placeholder="Tümü"
                onChange={transactionProcessTypeOnChange}
                filterOption={(input, option) =>
                  option.children
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
                loading={transactionProcessTypeSelect.loading}
              >
                {transactionProcessTypeSelect.data.map(({ key, value }) => (
                  <Select.Option key={key} value={key}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Cari Adı/Ünvanı" name="firmId" rules={[{ required: true }]}>
              <Select
                allowClear
                showSearch
                placeholder="Seçiniz"
                onSearch={onFirmSeach}
                filterOption={(input, option) =>
                  option.children
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                  option.code
                    ?.toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
                loading={firmSelect.loading}
              >
                {firmSelect.data
                  .map((item) => (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                      code={item.firmBranchNumber}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label="İşlem Tipi" name="manualTransactionTypeId" rules={[{ required: true }]}>
              <Select
                allowClear
                showSearch
                placeholder="Tümü"
                filterOption={(input, option) =>
                  option.children
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
                loading={manualTransactionTypeSelect.loading}
              >
                {manualTransactionTypeSelect.data.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="currencyId"
              label="Para Birimi"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Seçiniz"
                filterOption={(input, option) =>
                  option.children
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
                loading={currencySelect.loading}
              >
                {currencySelect.data.map(({ id, code }) => (
                  <Select.Option key={id} value={id}>
                    {code}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Tutar"
              name="amount"
              rules={[{ required: true }]}
            >
              <InputNumber precision={2} style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Tarih"
              name="transactionDate"
              rules={[{ required: true }]}
            >
              <DatePicker format="DD.MM.YYYY" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item label="Açıklama" name="description">
              <Input.TextArea rows={4} />
            </Form.Item>

            <Row justify="space-between">
              <Col>
                {tahsilatButton === true && (
                  <>
                    <Button icon={<DownloadOutlined />} onClick={() => downloadExcel()} loading={loading}>
                      Excel Şablonu İndir
                    </Button>
                    <Upload {...propsTahsilat} fileList={fileList}>
                      <Button icon={<UploadOutlined />}>Excel ile Tahsilat Ekle</Button>
                    </Upload>
                  </>
                )}
                {odemeButton === true && (
                  <>
                    <Button icon={<DownloadOutlined />} onClick={() => downloadExcel()} loading={loading}>
                      Excel Şablonu İndir
                    </Button>
                    <Upload {...propsOdeme} fileList={fileList}>
                      <Button icon={<UploadOutlined />}>Excel ile Ödeme Ekle</Button>
                    </Upload>
                  </>
                )}
              </Col>
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  htmlType="submit"
                  onClick={() => setLoading(true)}
                  loading={loading}
                >
                  Ekle
                </Button>
              </Col>
            </Row>

          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
export default ManualAccountAdd;