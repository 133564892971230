import React, { useState, useEffect } from "react";

import RequestHandler from "util/RequestHandler";
import { ColumnHelper } from "util/TableHelper";

import FirmDbsStatusReportFilter from "../../Filters/FirmDbsStatusReportFilter";
import moment from "moment";
import config from "util/ApiforContext";

import {
  Table,
  Empty,
  Col,
  Row,
  Tooltip,
  Typography,
  Spin,
  message,
} from "antd";
import {
  BarsOutlined,
  WarningOutlined,
  FileExcelOutlined
} from "@ant-design/icons";
import { odata } from "../../Filters/Helper";


const FirmDbsStatusReport = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });
  const [order, setOrder] = useState("");
  const [detailTableData, setDetailTableData] = useState({});

  useEffect(() => getTableData(), [order, tableDataQueryOptions]);

  const getFilterQuery = () => {
    const { filter } = tableDataQueryOptions;

    const conditions = [];
    let query = "";

    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length !== 0) {
      query = "$filter=" + conditions.join(" and ");
    }
    return query;
  };

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });
    const { skip, take } = tableDataQueryOptions;
    const url = props.dataUrl ? props.dataUrl : "/DbsReport/DbsFirmStatus";

    RequestHandler.get(`${url}?$count=true&${getFilterQuery()}${order}&$skip=${skip}&$top=${take}`).then((response) => {
      if (Array.isArray(response.value)) {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      }
    });
  };

  const paging = (p, filters, sorter) => {
    let orderValue = "";
    if (sorter.columnKey && sorter.order) {
      orderValue =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }
    setOrder(orderValue);
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };

  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);

    const url = props.excelUrl ? props.excelUrl : "/DbsReport/ExportDbsFirmStatusExcel";
    const downloadUrl = url.replace("ExportDbsFirmStatusExcel", "DownloadDbsFirmStatusExcel");
    RequestHandler.post(
      url,
      {
        query: getFilterQuery(),
      },
      false
    ).then((response) => {
      hideMessage();

      if (response) {
        window.open(
          `${config.apiUrl}${downloadUrl}?fileName=${response}&t=${RequestHandler.getToken()}`
        );
      }
    });
  };

  const renderRowDetail = (rowId) => {
    const detail = detailTableData[rowId];

    if (!detail) return null;

    const detailColumns = [
      {
        title: "Banka",
        dataIndex: "bankLogo",
        width: 125,
        render: (text) => <img src={text}></img>,
      },
      {
        title: "Bayi Kodu",
        dataIndex: "code",
      },
      {
        title: "Tanımlı Limit",
        dataIndex: "limit",
        render: ColumnHelper.renderDecimal,
      },
      {
        title: "Kullanılabilir Limit",
        dataIndex: "availability",
        render: ColumnHelper.renderDecimal,
      },
      {
        title: "Risk",
        dataIndex: "risk",
        render: ColumnHelper.renderDecimal,
      },
      {
        title: "Limit Aşımı",
        dataIndex: "limitExcess",
        render: (data) =>
          data > 0 ? (
            <Typography.Text type="danger">
              {ColumnHelper.renderDecimal(data)}
            </Typography.Text>
          ) : (
            "-"
          ),
      },
      {
        title: "İleri Tarihli Fatura Toplamı",
        dataIndex: "invoiceTotal",
        render: (data) => (data ? ColumnHelper.renderDecimal(data) : "-"),
      },
      {
        title: "Para Birimi",
        render: () => "TRY",
      },
      {
        title: "İleri Tarihli Fatura Adedi",
        dataIndex: "invoiceCount",
        render: (data) => data ?? "-",
      },
      {
        title: "Sorgu Tarihi",
        dataIndex: "lastQueryDate",
        align: "center",
        render: (data) => (data ? moment(data).format("DD.MM.YYYY HH:mm") : "-"),
      },
      {
        title: "Uyarı",
        dataIndex: "isHaveWarning",
        align: "center",
        render: (data, row) => {
          return data ? (
            <Tooltip title={row.warningMessage}>
              <WarningOutlined style={{ color: "orange", fontSize: "130%" }} />
            </Tooltip>
          ) : undefined;
        },
      }
    ];

    return detail.loading ? (
      <Row justify="center">
        <Spin style={{ margin: 5 }} />
      </Row>
    ) : (
      <Table
        columns={detailColumns}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Veri Yok"
            />
          ),
        }}
        rowKey="id"
        filterable={true}
        sortable={true}
        pageable={true}
        pagination={{
          pageSizeOptions: ["10"],
          total: detail.data.length,
        }}
        dataSource={detail.data}
        size="small"
      />
    );
  };

  const onExpand = (expanded, row) => {
    if (!expanded) return;

    setDetailTableData((prevState) => {
      prevState[row.id] = {
        data: [],
        loading: true,
      };
      return prevState;
    });

    RequestHandler.get(`/DbsDealer?$filter=firmId eq ${row.id}`).then((response) => {

      setDetailTableData((prevState) => {
        let newState = { ...prevState };
        newState[row.id] = {
          data: Array.isArray(response.value) ? response.value : prevState.data,
          loading: false,
        };
        return newState;
      });

    });
  };

  const columns = [
    {
      title: "Cari Adı",
      dataIndex: "name",
    },
    {
      title: "Cari Kodu",
      dataIndex: "code",
    },
    {
      title: "Tanımlı Limit",
      dataIndex: "limit",
      key: "limit",
      sorter: true,
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Kullanılabilir Limit",
      key: "availability",
      dataIndex: "availability",
      sorter: true,
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Risk",
      dataIndex: "risk",
      key: "risk",
      sorter: true,
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "İleri Tarihli Fatura Toplamı",
      dataIndex: "invoiceTotal",
      render: (data) => (data ? ColumnHelper.renderDecimal(data) : "-"),
    },
    {
      title: "Para Birimi",
      render: () => "TRY",
    },
    {
      title: "İleri Tarihli Fatura Adedi",
      dataIndex: "invoiceCount",
      render: (data) => data ?? "-",
    }
  ];

  return (
    <>
      <Row>
        <Col xs={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> CARİ DURUM RAPORU
          </p>
        </Col>
        <Col xs={8}>
          <Row justify="end" align="middle">
            <Tooltip title="Excel İndir">
              <a
                style={{
                  color: "green",
                  fontSize: "180%",
                  marginRight: 30,
                }}
                onClick={onClickExportExcel}
              >
                <FileExcelOutlined />
              </a>
            </Tooltip>
          </Row>
        </Col>
      </Row>

      <FirmDbsStatusReportFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            expandable={{
              expandedRowRender: (row) => renderRowDetail(row.id),
              onExpand: onExpand,
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};

export default FirmDbsStatusReport;
