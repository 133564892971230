import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Select, InputNumber } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages, onInputMaxLengthCheck } from "./Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const PosBankInfoAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: false,
  });
  const [activeBankId, setActiveBankId] = useState();
  const [bankRequirements, setBankRequirements] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getNotAddedBanks();
    getBankInfoRequirements();
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const getNotAddedBanks = () => {
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    RequestHandler.get(`/PosBankInfo/NotAddedBanks?$select=id,name`).then(
      (response) => {
        setBankSelect({
          data: response,
          loading: false,
        });
      }
    );
  };

  const getBankInfoRequirements = () => {
    RequestHandler.get(`/PosBankInfoRequirement`).then((response) => {
      if (Array.isArray(response.value)) {
        setBankRequirements(response.value);
      }
    });
  };

  const onFinish = (values) => {
    RequestHandler.post(`/PosBankInfo`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        getNotAddedBanks();
        form.resetFields();
        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onFinishFailed = () => setLoading(false);

  const bankRequirement = bankRequirements.find(
    (x) => x.bankId === activeBankId
  );

  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="posBankInfoAdd"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="bankId" label="Banka" rules={[{ required: true }]}>
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              onChange={(value) => setActiveBankId(value)}
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              loading={bankSelect.loading}
            >
              {bankSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {bankRequirement && (
            <>
              {bankRequirement.isCustomerNumberRequired && (
                <Form.Item
                  label={
                    bankRequirement.customerNumberLabel
                      ? bankRequirement.customerNumberLabel
                      : "Müşteri Numarası"
                  }
                  name="customerNumber"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input maxLength="125" onInput={onInputMaxLengthCheck} />
                </Form.Item>
              )}

              {(bankRequirement.isHostRequired ||
                bankRequirement.isPortRequired) && (
                  <Form.Item style={{ marginBottom: 0 }} label="Host" required>
                    <Input.Group>
                      <Row gutter={2}>
                        <Col span={18}>
                          {bankRequirement.isHostRequired && (
                            <Form.Item
                              name="host"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "'Host' alanı gerekli.",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          )}
                        </Col>
                        <Col span={6}>
                          {bankRequirement.isPortRequired && (
                            <Form.Item
                              name="port"
                              rules={[
                                {
                                  required: true,
                                  message: "'Port' alanı gerekli.",
                                },
                              ]}
                            >
                              <InputNumber
                                placeholder="Port"
                                min={0}
                                max={65535}
                                precision={0}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          )}
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                )}

              {bankRequirement.isFilePathRequired && (
                <Form.Item
                  label={
                    bankRequirement.filePathLabel
                      ? bankRequirement.filePathLabel
                      : "Dosya Yolu"
                  }
                  name="filePath"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              {bankRequirement.isUserNameRequired && (
                <Form.Item
                  label={
                    bankRequirement.userNameLabel
                      ? bankRequirement.userNameLabel
                      : "Kullanıcı Adı"
                  }
                  name="userName"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              {bankRequirement.isPasswordRequired && (
                <Form.Item
                  label="Şifre"
                  name="password"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
              )}
            </>
          )}

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Ekle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default PosBankInfoAddForm;
