import React, { useState, useEffect } from "react";
import {
  Form,
  DatePicker,
  Input,
  notification,
  Button,
  Select,
  Col,
  Row,
  Upload,
  Spin,
  Radio,
  Space,
  Typography,
} from "antd";
import { useHistory } from "react-router-dom";
import CurrencyInput from "react-currency-input";
import { useMediaQuery } from "react-responsive";
import config from "../../util/ApiforContext";
import Replace2x from "../../util/Extensions/ReplaceExtension";
import {
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import axios from "axios";
const expiration = localStorage.getItem("expiration");
const { Option } = Select;
const { Text } = Typography;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 11,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const SubscriberFirmMonthlyPlanAdd = (props) => {
  const token = localStorage.getItem("token");

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [repeatDayCount, setRepeatDayCount] = useState(1);
  const [repeatEndDayCount, setRepeatEndDayCount] = useState(1);
  const [repeatDate, setRepeatDate] = useState();
  const [spinLoading, SetSpinLoading] = useState(false);
  const [tahsilatButton, setTahsilatButton] = useState(false);
  const [odemeButton, setOdemeButton] = useState(false);
  const [repeatPlan, setRepeatPlan] = useState(false);
  const [teminatDropdown, setteminatDropdown] = useState([]);
  const [firmDropdown, setfirmDropdown] = useState([]);
  const [currencyDropdown, setcurrencyDropdown] = useState([]);
  const [ManualTransactionTypeDropdown, setManualTransactionTypeDropdown] =
    useState([]);

  const [subscriberFirmId, setSubscriberFirmId] = useState(
    props.SubscriberFirmId
  );
  const [subscriberFirmName, setSubscriberFirmName] = useState(props.Name);
  const errorList = [];
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 5,
      });
    });
  };
  const onChangeRepeat = (val) => {
    setRepeatPlan(val.target.value);
  };

  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }
    axios
      .get(`${config.apiUrl}/Types/GetManualTransactionTypes`, config.headers)
      .then((res) => {
        const teminatDropdown = res.data.filter((x) => x.value == "Tahsilat");
        setteminatDropdown(teminatDropdown);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    axios
      .get(`${config.apiUrl}/Currency`, config.headers)
      .then((res) => {
        const currencyDropdown = res.data.value.filter((x) => x.code == "TRY");
        setcurrencyDropdown(currencyDropdown);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    axios
      .get(`${config.apiUrl}/MonthlyPlanType`, config.headers)
      .then((res) => {
        const ManualTransactionTypeDropdown = res.data.value.filter(
          (x) => x.name == "Diğer"
        );
        setManualTransactionTypeDropdown(ManualTransactionTypeDropdown);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  }, [
    setteminatDropdown,
    setcurrencyDropdown,
    setManualTransactionTypeDropdown,
  ]);

  const { TextArea } = Input;
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Ekleme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });
  };
  const excelDownload = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Excel şablonu indirme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });
  };
  const excelDownloadFailed = (type) => {
    notification[type]({
      message: "HATA",
      description: "Excel şablonu indirme işlemi sırasında bir hata oluştu.",
      duration: 2,
    });
  };
  const downloadExcel = () => {
    window.open(
      `${config.apiUrl}/MonthlyPlan/DownloadExcel?t=${Replace2x(token)}`
    );
  };
  const openNotificationWithIconWarning = (type) => {
    notification[type]({
      message: "HATA",
      description: "Ekleme işlemi sırasında bir hata oluştu.",
      duration: 2,
    });
  };
  const onFinish = (values) => {
    const valuedate = values["TransactionDate"];

    const repeatTime = values.repeat?.value;
    const repeatKind = values.repeat?.day;
    const repeatContinue = values.repeatContinue == 1 ? null : repeatDayCount;
    const repeatEndDateDay =
      values.repeatEndDate == 2 ? repeatEndDayCount : null;
    const repeatEndDate = values.repeatEndDate == 3 ? repeatDate : null;

    axios
      .post(
        `${config.apiUrl}/MonthlyPlan`,
        {
          MonthlyPlanTypeId: ManualTransactionTypeDropdown[0].id,
          OtherTransactionProcessType: teminatDropdown[0].key,
          Description: values.Description,
          Amount: 1,
          AmountString: values.Amount,
          TransactionDate: valuedate.format("YYYY-MM-DD"),
          CurrencyId: values.CurrencyCode,
          FirmId: props.SubscriberFirmId,

          IsRepeat: repeatPlan,
          RepeatTime: repeatTime,
          RepeatKind: repeatKind,
          RepeatContinue: repeatContinue,
          repeatEndDateDay: repeatEndDateDay,
          repeatEndDate: repeatEndDate?.format("YYYY-MM-DD"),
        },
        config.headers
      )
      .then((res) => {
        if (res.status == 200) {
          openNotificationWithIcon("success");
          setTimeout(
            function () {
              window.location.reload();
            }.bind(this),
            2000
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        } else {
          openNotificationWithIconWarning("error");
          setLoading(false);
        }
      });
  };
  const propsTahsilat = {
    name: "excel",
    action: `${config.apiUrl}/MonthlyPlan/ImportExcel?type=1`,
    headers: {
      Authorization:
        "bearer " + token?.replace('"', "").replace('"', "").toString(),
    },
    onChange(info) {
      const { response, status } = info.file;

      if (info.fileList.length > 0) {
        SetSpinLoading(true);
      }
      if (info.file.status === "done") {
        excelDownload("success");
        setTimeout(
          function () {
            window.location.reload();
          }.bind(this),
          2000
        );
      } else if (info.file.status === "error") {
        if (info.file.response.statusCode === 406) {
          info.file.response.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
          setTimeout(function () {
            window.location.reload();
          }, 5000);
        } else {
          const notificationType = response.success ? "success" : "error";

          notification[notificationType]({
            message: response.message,
            duration: 3,
          });
          if (!response.success) {
            downloadImportResult(info.file.response.data);
          }
          setTimeout(function () {
            window.location.reload();
          }, 5000);
        }
      }
    },
  };
  const downloadImportResult = (fileName) => {
    window.open(
      `${config.apiUrl}/MonthlyPlan/DownloadImportResult?t=${Replace2x(
        token
      )}&fileName=${fileName}`
    );
  };
  const propsOdeme = {
    name: "excel",
    action: `${config.apiUrl}/MonthlyPlan/ImportExcel?type=2`,
    headers: {
      Authorization:
        "bearer " + token?.replace('"', "").replace('"', "").toString(),
    },
    onChange(info) {
      const { response, status } = info.file;

      SetSpinLoading(true);
      if (info.file.status === "done") {
        excelDownload("success");
        setTimeout(
          function () {
            window.location.reload();
          }.bind(this),
          2000
        );
      } else if (info.file.status === "error") {
        if (info.file.response.statusCode === 406) {
          info.file.response.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
          setTimeout(function () {
            window.location.reload();
          }, 5000);
        } else {
          const notificationType = response.success ? "success" : "error";

          notification[notificationType]({
            message: response.message,
            duration: 3,
          });
          if (!response.success) {
            downloadImportResult(info.file.response.data);
          }
          setTimeout(function () {
            window.location.reload();
          }, 5000);
        }
      }
    },
  };
  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };
  return (
    <div>
      <Spin tip="Excel yükleniyor..." spinning={spinLoading}>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row>
            <Col
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: "2%",
                marginBottom: "5%",
              }}
            >
              <Radio.Group
                defaultValue={false}
                onChange={(value) => {
                  onChangeRepeat(value);
                }}
                buttonStyle="button"
              >
                <Radio.Button value={false}>Plan Girişi</Radio.Button>
                <Radio.Button value={true}>Tekrarlı Plan Girişi</Radio.Button>
              </Radio.Group>
            </Col>
            <Col span={24}>
              <Form.Item label="Daire Adı" name="FirmName">
                <Input readOnly={true} defaultValue={subscriberFirmName} />
              </Form.Item>
              <Form.Item
                label="Tutar"
                name="Amount"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Tutar alanını boş bırakmayın.",
                  },
                ]}
              >
                <CurrencyInput
                  className="ant-input"
                  style={{ width: "100%" }}
                  decimalSeparator=","
                  thousandSeparator="."
                />
              </Form.Item>
              <Form.Item
                label="Para Birimi"
                name="CurrencyCode"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Para Birimi alanını boş bırakmayın.",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Para birimini seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                  }
                >
                  {currencyDropdown.map((item) => (
                    <Option key={item.id}>
                      {item.code + " (" + item.symbol + ")"}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {repeatPlan ? (
                <>
                  <Form.Item
                    label="İlk Tekrar Tarihi"
                    name="TransactionDate"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Tarih alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      placeholder="Tarih seçiniz"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item label="Tekrar Etme Sıklığı">
                    <Input.Group compact>
                      <Text style={{ marginTop: 4 }}>Her</Text>
                      <Form.Item
                        name={["repeat", "value"]}
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: "Bu alan boş bırakılamaz",
                          },
                        ]}
                      >
                        <Select
                          style={
                            isTabletOrMobileDevice
                              ? { marginLeft: 10 }
                              : { marginLeft: 10, width: "22%" }
                          }
                          placeholder="Seçiniz"
                        >
                          <Option value={1}>1</Option>
                          <Option value={2}>2</Option>
                          <Option value={3}>3</Option>
                          <Option value={4}>4</Option>
                          <Option value={5}>5</Option>
                          <Option value={6}>6</Option>
                          <Option value={7}>7</Option>
                          <Option value={8}>8</Option>
                          <Option value={9}>9</Option>
                          <Option value={10}>10</Option>
                          <Option value={11}>11</Option>
                          <Option value={12}>12</Option>
                          <Option value={13}>13</Option>
                          <Option value={14}>14</Option>
                          <Option value={15}>15</Option>
                          <Option value={16}>16</Option>
                          <Option value={17}>17</Option>
                          <Option value={18}>18</Option>
                          <Option value={19}>19</Option>
                          <Option value={20}>20</Option>
                          <Option value={21}>21</Option>
                          <Option value={22}>22</Option>
                          <Option value={23}>23</Option>
                          <Option value={24}>24</Option>
                          <Option value={25}>25</Option>
                          <Option value={26}>26</Option>
                          <Option value={27}>27</Option>
                          <Option value={28}>28</Option>
                          <Option value={29}>29</Option>
                          <Option value={30}>30</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={["repeat", "day"]}
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: "Bu alan boş bırakılamaz",
                          },
                        ]}
                      >
                        <Select
                          style={{ marginRight: 10 }}
                          placeholder="Seçiniz"
                        >
                          <Option value="day">Günde</Option>
                          <Option value="week">Haftada</Option>
                          <Option value="month">Ayda</Option>
                          <Option value="year">Yılda</Option>
                        </Select>
                      </Form.Item>
                      <Text style={{ marginTop: 4 }}>Bir</Text>
                    </Input.Group>
                  </Form.Item>

                  <Form.Item
                    label="Sonraki Tekrarlar"
                    name="repeatContinue"
                    rules={[
                      {
                        required: true,
                        message:
                          "Lütfen Tekrarlanma Sıklığı alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Radio.Group onChange={(value) => { }} value={1}>
                      <Space direction="vertical">
                        <Radio value={1}>Tekrarlanma Gününde</Radio>
                        <Radio value={2}>
                          <Input
                            style={{ width: 60, marginLeft: 10 }}
                            value={repeatDayCount}
                            onChange={(e) => {
                              setRepeatDayCount(e.target.value);
                            }}
                          />{" "}
                          gün sonra
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    label="Ne Zaman Sonlansın"
                    name="repeatEndDate"
                    rules={[
                      {
                        required: true,
                        message:
                          "Lütfen Sonlanma tarihi alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Radio.Group onChange={(value) => { }} value={1}>
                      <Space direction="vertical">
                        <Radio value={2}>
                          <Input
                            style={{ width: 60, marginLeft: 10 }}
                            value={repeatEndDayCount}
                            onChange={(e) => {
                              setRepeatEndDayCount(e.target.value);
                            }}
                          />{" "}
                          tekrardan sonra
                        </Radio>
                        <Radio value={3}>
                          <DatePicker
                            format={"DD-MM-YYYY"}
                            onSelect={(dt) => {
                              setRepeatDate(dt);
                            }}
                            placeholder="Tarih seçiniz"
                            style={{ width: "50%" }}
                          />{" "}
                          <Text>tarihinde</Text>
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </>
              ) : (
                <Form.Item
                  label="Tarih"
                  name="TransactionDate"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Tarih alanını boş bırakmayın.",
                    },
                  ]}
                >
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    placeholder="Tarih seçiniz"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              )}
              <Form.Item label="Açıklama" name="Description">
                <TextArea rows={4} />
              </Form.Item>
            </Col>

            <Col span={6} style={{ marginLeft: -5 }}>
              {tahsilatButton === true && !repeatPlan ? (
                <Upload {...propsTahsilat}>
                  <Button icon={<UploadOutlined />}>
                    Excel ile Tahsilat Ekle
                  </Button>
                </Upload>
              ) : odemeButton === true && !repeatPlan ? (
                <Upload {...propsOdeme}>
                  <Button icon={<UploadOutlined />}>
                    Excel ile Ödeme Ekle
                  </Button>
                </Upload>
              ) : null}
            </Col>
            <Col span={6} style={{ marginLeft: 5 }}>
              {tahsilatButton === true && !repeatPlan ? (
                <Button
                  icon={<DownloadOutlined />}
                  onClick={(() => setLoading(true), downloadExcel)}
                  loading={loading}
                >
                  Excel Şablonu İndir
                </Button>
              ) : odemeButton === true && !repeatPlan ? (
                <Button
                  icon={<DownloadOutlined />}
                  onClick={(() => setLoading(true), downloadExcel)}
                  loading={loading}
                >
                  Excel Şablonu İndir
                </Button>
              ) : null}
            </Col>
            <Col span={6}></Col>
            <Col span={3}>
              <Form.Item {...tailLayout}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  htmlType="submit"
                  onClick={() => setLoading(true)}
                  loading={loading}
                >
                  Ekle
                </Button>
              </Form.Item>
            </Col>
            <Col span={6}></Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};
export default SubscriberFirmMonthlyPlanAdd;
