import React, { useState, useEffect } from "react";

import RequestHandler from "util/RequestHandler";
import { ColumnHelper } from "util/TableHelper";

import DbsInvoiceTableFilter from "../Filters/DbsInvoiceTableFilter";
import DbsInvoiceAddForm from "../Forms/DbsInvoiceAddForm";
import DbsInvoiceUpdateForm from "../Forms/DbsInvoiceUpdateForm";
import DbsInvoiceSentWarning from "./DbsInvoiceSentWarning";

import moment from "moment";

import {
  Table,
  Empty,
  Col,
  Row,
  Button,
  Drawer,
  Popconfirm,
  Tooltip,
  Modal
} from "antd";
import {
  BarsOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { odata } from "../Filters/Helper";

const DbsInvoice = () => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });
  const [addInvoiceVisibility, setAddInvoiceVisibility] = useState(false);
  const [updateInvoiceVisibility, setUpdateInvoiceVisibility] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sendInvoiceLoading, setSendInvoiceLoading] = useState(false);
  const [order, setOrder] = useState("");
  const [sendInvoiceWarningModal, setSendInvoiceWarningModal] = useState({ visible: false });

  useEffect(() => getTableData(), [order, tableDataQueryOptions]);

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });
    setSelectedRowKeys([]);

    let query = '';
    const { skip, take, filter } = tableDataQueryOptions;

    const conditions = [];
    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length > 0) {
      query = `&$filter=${conditions.join(" and ")}`;
    }

    RequestHandler.get(
      `/DbsInvoice?$count=true${query}${order}&$skip=${skip}&$top=${take}`
    ).then((response) => {
      if (Array.isArray(response.value)) {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      }
    });
  };

  const paging = (p, filters, sorter) => {
    let order = "";
    if (sorter.columnKey && sorter.order) {
      order =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }
    setOrder(order);
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };

  const handleOnClickUpdate = (record) => {
    RequestHandler.get(`/DbsInvoice/GetById/${record.id}`).then((response) => {
      if (response) {
        setUpdateFormData(response);
        setUpdateInvoiceVisibility(true);
      }
    });
  };

  const handleOnClickDelete = (id) => {
    RequestHandler.delete(`/DbsInvoice/${id}`).then((response) => {
      if (response.success) {
        getTableData();
      }
    });
  };

  const onAddInvoiceSuccess = () => {
    getTableData();
    setAddInvoiceVisibility(false);
  };

  const onUpdateInvoiceSuccess = () => {
    getTableData();
    setUpdateInvoiceVisibility(false);
  };

  const onTableSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const sendInvoices = (invoiceIds) => {
    if (invoiceIds && invoiceIds.length <= 0) {
      setSendInvoiceLoading(false);
      getTableData();
      return;
    }

    RequestHandler.post("/DbsBank/Add", { invoiceIds: invoiceIds }).then(
      (response) => {
        setSendInvoiceLoading(false);
        getTableData();
      }
    );
  };

  const onFinishSendInvoiceWarning = (isSent) => {
    setSendInvoiceWarningModal({ visible: false });
    if (isSent) {
      getTableData();
    } else {
      sendInvoices(sendInvoiceWarningModal.data?.upToDateInvoiceIds);
    }
  };


  const onConfirmSendInvoice = async () => {
    setSendInvoiceLoading(true);
    const checkInvoicesResult = await RequestHandler.post("/DbsBank/CheckInvoiceDealerIsUpToDate", { invoiceIds: selectedRowKeys }, false);

    if (checkInvoicesResult.success) {
      sendInvoices(checkInvoicesResult.data.upToDateInvoiceIds);
    } else {
      setSendInvoiceLoading(false);
      setSendInvoiceWarningModal({ visible: true, data: checkInvoicesResult.data });
    }
  };


  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      width: 150,
      render: (text) => <img src={text}></img>,
    },
    {
      title: "Bayi Adı",
      dataIndex: "dbsDealerName",
    },
    {
      title: "Fatura Tarihi",
      dataIndex: "invoiceDate",
      sorter: true,
      key: "invoiceDate",
      render: (data, row, index) => moment(data).format("DD.MM.YYYY"),
    },
    {
      title: "Vade Tarihi",
      dataIndex: "dueDate",
      sorter: true,
      key: "dueDate",
      render: (data, row, index) => moment(data).format("DD.MM.YYYY"),
    },
    {
      title: "Fatura Durumu",
      dataIndex: "status",
    },
    {
      title: "Fatura Numarası",
      dataIndex: "invoiceNumber",
    },
    {
      title: "Fatura Tutarı",
      dataIndex: "amount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Para Birimi",
      dataIndex: "currencyCode",
    },
    {
      title: "Açıklama",
      dataIndex: "description",
    },
    {
      title: "Uyarı",
      dataIndex: "isHaveWarning",
      align: "center",
      render: (data, row) => {
        return data ? (
          <Tooltip title={row.warningMessage}>
            <WarningOutlined style={{ color: "orange", fontSize: "130%" }} />
          </Tooltip>
        ) : undefined;
      },
    },
    {
      title: "Düzenle",
      dataIndex: "id",
      align: "center",
      render: (data, row) => (
        <a onClick={() => handleOnClickUpdate(row)}>
          <EditOutlined style={{ color: "orange", fontSize: "130%" }} />
        </a>
      ),
    },
    {
      title: "Sil",
      dataIndex: "id",
      align: "center",
      render: (data, record) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleOnClickDelete(data)}
        >
          <a>
            <DeleteOutlined style={{ color: "red", fontSize: "130%" }} />
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> FATURA LİSTESİ
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
          <Popconfirm
            title="Göndermek istiyor musunuz?"
            okText="Evet"
            cancelText="Hayır"
            placement="bottom"
            disabled={selectedRowKeys.length < 1}
            onConfirm={onConfirmSendInvoice}
          >
            <Button
              disabled={selectedRowKeys.length < 1}
              loading={sendInvoiceLoading}
              style={{ marginBottom: 0, marginRight: 15 }}
            >
              <SendOutlined /> Faturaları Gönder
            </Button>
          </Popconfirm>

          <Button
            type="primary"
            onClick={() => {
              setAddInvoiceVisibility(true);
            }}
            style={{ marginBottom: 0 }}
          >
            <PlusOutlined /> Fatura Ekle
          </Button>
        </Col>
      </Row>

      <DbsInvoiceTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: onTableSelectChange,
            }}
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Drawer
        id="addInvoiceDrawer"
        title="Fatura Ekle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setAddInvoiceVisibility(false)}
        visible={addInvoiceVisibility}
      >
        <DbsInvoiceAddForm onSuccess={onAddInvoiceSuccess} />
      </Drawer>

      <Drawer
        id="updateInvoiceDrawer"
        title="Fatura Güncelle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setUpdateInvoiceVisibility(false)}
        visible={updateInvoiceVisibility}
      >
        <DbsInvoiceUpdateForm
          data={updateFormData}
          onSuccess={onUpdateInvoiceSuccess}
        />
      </Drawer>

      <Modal
        id="dbsInvoiceSentWarningModal"
        title={<p style={{ marginBottom: 0 }}>
          <WarningOutlined style={{ color: "orange" }} /> Göndermek istediğiniz faturaların bir kısmında bayi limit bilgisi güncel olmayabilir, bayi limiti aşılmış veya bayide bir hata olabilir.</p>}
        centered
        visible={sendInvoiceWarningModal.visible}
        onCancel={() => onFinishSendInvoiceWarning()}
        destroyOnClose={true}
        width="95%"
        footer={null}
      >
        <DbsInvoiceSentWarning
          data={sendInvoiceWarningModal.data}
          onFinish={onFinishSendInvoiceWarning}
        />
      </Modal>
    </>
  );
};

export default DbsInvoice;
