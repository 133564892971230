import React, {useEffect,useState} from "react";
import { Drawer,notification,Checkbox,Row,Col} from "antd"; 
import { RollbackOutlined,KeyOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import CustomScrollbars from "util/CustomScrollbars";
import Replace2x from '../../util/Extensions/ReplaceExtension'

import axios from "axios"
import ContactList from "components/contact/ContactList";
import config from "../../util/ApiforContext"
const expiration = localStorage.getItem("expiration");

const Contact = (props) => {
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'Hesap yetkilendirme işlemi başarılı!',
        duration: 2,
    });
};
const openNotificationWithIconWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Hesap yetkilendirme işlemi sırasında bir hata oluştu.',
        duration: 2,
    });
};
const onContactSelect = (value) => {
  axios.post(`${config.apiUrl}/User/AddFullAccountAuth`,
  { 
  UserId:props.match.params.userId
},
config.headers)
   .then(res => {
    if(res.status==200){
      setIsFullAccountChecked(res.data.data.isFullAccountAuth)
      openNotificationWithIcon('success');
      setDisabled(res.data.data.isFullAccountAuth)
       }
   }).catch(
    error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }else if(error.response.status === 406){
            error.response.data.data.map((item)=>{
              errorList.push(item);
            })
            errorListNotification('error')
          }else{
      openNotificationWithIconWarning('error')}}
);
};
    const history = useHistory();
    const [bankListForTRY, setBankListForTRY] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [drawerState, setDrawerState] = useState(false);
    const [isFullAccountChecked, setIsFullAccountChecked] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [checkboxDisabled, setCheckboxDisabled] = useState(false);
    const errorList = [];
    const errorListNotification = type => {
      errorList.map((item)=> {
        notification[type]({
          message: 'Hata',
          description:
          item
           ,
            duration: 2,
        });
      })
    };
    const onToggleDrawer = () => {
        setDrawerState(true)
    }
    const onToggleDrawerClose = () => {
        setDrawerState(false)
    }
    const getComp = (val) => {
      axios.post(`${config.apiUrl}/tenantaccount/GetWithTenantAccountUser`,{
        TenantId: Replace2x(localStorage.getItem("tenantId")),
        UserId:props.match.params.userId,
        BankId:val
      },config.headers)
      .then(res => {
        setContactList(res.data)
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }else if(error.response.status === 406){
            error.response.data.data.map((item)=>{
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
    );
    }
    axios.get(`${config.apiUrl}/user/IsAdmin?userId=${props.match.params.userId}`,config.headers)
      .then(res => {
          setDisabled(res.data);
          setCheckboxDisabled(res.data);
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }else if(error.response.status === 406){
            error.response.data.data.map((item)=>{
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
    );
    useEffect(() => {
      axios.get(`${config.apiUrl}/user/IsAdmin?userId=${props.match.params.userId}`,config.headers)
      .then(res => {
          setDisabled(res.data);
          setCheckboxDisabled(res.data);
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }else if(error.response.status === 406){
            error.response.data.data.map((item)=>{
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
    );
      if(expiration<Date.now()){
        localStorage.removeItem("expiration");
        localStorage.removeItem("token");
        window.location.reload();
      }
      if(localStorage.getItem("token")==null){
        window.location.reload()
      }
      axios.post(`${config.apiUrl}/User/IsFullAccountAuth`,{UserId : props.match.params.userId},config.headers)
        .then(res => {
          setIsFullAccountChecked(res.data.data)
          setDisabled(res.data.data)
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }else if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
            }
          }
      );
      
        axios.get(`${config.apiUrl}/tenantaccount?$apply=groupby((BankLogo,BankId,BankCode),aggregate(Balance with sum as Balance))`,config.headers)
        .then(res => {
            setBankListForTRY(res.data)
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }else if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
            }
          }
      );
      },[setIsFullAccountChecked,setDisabled]);
      const goBankAccount=(value)=>{
        axios.post(`${config.apiUrl}/tenantaccount/GetWithTenantAccountUser`,{
          TenantId: Replace2x(localStorage.getItem("tenantId")),
          UserId:props.match.params.userId,
          BankId:value
        },config.headers)
        .then(res => {
       
          setContactList(res.data)
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }else if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
            }
          }
      );
      }
      
  const ContactSideBar = () => {
    return <div style={disabled ? {pointerEvents: "none", opacity: "0.4"} : {}} className="gx-module-side">
      <div className="gx-module-side-header">
        <div className="gx-module-logo">
          <span>Kullanıcı Hesap Yetkileri </span>
        </div>
      </div>
     
      <div className="gx-module-side-content">
        
        <CustomScrollbars className="gx-module-side-scroll">
          <div className="gx-module-add-task">
           
          </div>
          <div className="gx-module-side-nav">
            <ul className="gx-module-nav">
              {bankListForTRY.map(option => 
              <li key={option.bankId} className="gx-nav-item" onClick={() =>goBankAccount(option.bankId)}>
                  <span
                    className={`gx-link`} >
                    <img style={{width:'80%'}} src={option.bankLogo}  ></img>
                  </span>
                </li>
              )}

            </ul>
          </div>
        </CustomScrollbars>
      </div>
    </div>

  };
    return (
      <div>
          <Row style={{marginBottom:9}}>
         <Col flex={1}><a onClick={() => history.goBack()} style={{color:'black',marginLeft:"4%"}}> <RollbackOutlined /> GERİ DÖN</a></Col>
         <Col flex={4}></Col>
       </Row>
       <Row>
    <Col flex={1}> <p style={{fontSize:'200%',marginLeft:"4%"}}><KeyOutlined /> HESAP YETKİLENDİRME</p><hr></hr><br></br></Col>
    <Col flex={4}></Col>
    </Row>
      <div className="gx-main-content">
        <div className="gx-app-module">
        <div className="gx-d-block gx-d-lg-none">
            <Drawer
              placement="left"
              width={100}
              closable={false}
              visible={drawerState}
              onClose={onToggleDrawerClose}>
              {ContactSideBar()}
            </Drawer>
          </div>
          <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
            {ContactSideBar()}
          </div>

          <div className="gx-module-box">
            <div className="gx-module-box-header">
            <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                  <i className="icon icon-menu gx-icon-btn" aria-label="Menu"
                     onClick={onToggleDrawer}/>
              </span>
            </div>
            <div className="gx-module-box-content">

              <div className="gx-module-box-topbar">
              <Checkbox className="gx-icon-btn"
                    checked={isFullAccountChecked}
                    value="checkedF"
                    disabled = {checkboxDisabled}
                    onClick={() =>onContactSelect()}/> <p>Tüm Yetkiler</p> 
              </div>
              <CustomScrollbars className="gx-module-content-scroll">
                {contactList.length === 0 ?
                  <div className="gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center">
                    Lütfen bir banka seçiniz
                  </div>
                  : <ContactList disabledPage={isFullAccountChecked} getDataComp={getComp} userId={props.match.params.userId} contactList={contactList}/>
                }
              </CustomScrollbars>

            </div>
          </div>
        </div>
        
      </div>
      </div>
    )
  }

export default Contact;
