import React, { useState, useEffect } from "react";
import RequestHandler from "util/RequestHandler";
import moment from "moment";

import { Table, Empty, Col, Row } from "antd";
import { BarsOutlined } from "@ant-design/icons";

const ErpFirmInvoiceReportDetail = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });

  useEffect(() => getTableData(), [tableDataQueryOptions, props.firmInvoiceId]);

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    RequestHandler.get(
      `/ErpReport/GetInvoiceDetailReport/${props.firmInvoiceId}`
    ).then((response) => {
      if (response) {
        setTableData({
          data: response,
          total: response.length,
          loading: false,
        });
      }
    });
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const columns = [
    {
      title: "Vade Tarihi",
      dataIndex: "dueDate",
      render: (data, row, index) => moment(data).format("DD.MM.YYYY"),
    },
    {
      title: "Ürün Kodu",
      dataIndex: "productCode",
    },
    {
      title: "Ürün Adı",
      dataIndex: "productName",
    },
    {
      title: "Miktar",
      dataIndex: "count",
    },
    {
      title: "Tutar",
      dataIndex: "amountString",
    },
  ];

  return (
    <>
      <Row>
        <Col xs={15} lg={20}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> FATURA DETAY
          </p>
        </Col>
      </Row>

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{
              overflowX: "auto",
              maxHeight: "100%",
              width: "100%",
            }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};

export default ErpFirmInvoiceReportDetail;
