import React, { useEffect, useState } from "react";
import {
  Table,
  Empty,
  Card,
  Col,
  notification,
  Spin,
  Row,
  Select,
  Tabs,
  Tooltip as AntdTooltip,
  Collapse
} from "antd";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import {
  MenuUnfoldOutlined,
  CaretRightFilled,
  BarsOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import config from "../../util/ApiforContext";
import { useMediaQuery } from "react-responsive";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { odata } from "../Filters/Helper";

const { Panel } = Collapse;
const { Option } = Select;
const expiration = localStorage.getItem("expiration");
const COLORS = [
  "#003668",
  "#0061ba",
  "#1f94ff",
  "#8dbbe5",
  "#8de5cd",
  "#00b684",
  "#006448",
];
const RADIAN = Math.PI / 180;
const renderCustomizedLabelTRY = ({ x, y, stroke, value }) => {
  return "% " + value.toFixed(2);
};
const CustomToolTip = (props) => {
  try {
    const { active, payload, label } = props;
    if (!active || !payload) {
      return null;
    }
    return (
      <div>
        <p>
          <strong>{label}</strong>
        </p>
        {payload.map((item, i) => (
          <p key={i}>
            <strong style={{ backgroundColor: "white", opacity: "0.6" }}>
              {item.name}
            </strong>
          </p>
        ))}
      </div>
    );
  } catch (error) { }
};

const BankList = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const isChartWidth = useMediaQuery({ query: "(min-width: 500px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const history = useHistory();
  const [bankListForTRY, setBankListForTRY] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currencyList, setCurrencyList] = useState([]);
  const [bankListForTotal, setBankListForTotal] = useState([]);
  const [exchangeRate, setExchangeRate] = useState([]);
  const [chartTakeValuesState, setChartTakeValuesState] = useState([]);
  const [totalBalanceWithCurrency, setTotalBalanceWithCurrency] = useState([]);
  const [totalChart, setTotalChart] = useState([]);
  const [activeKey, setActiveKey] = useState("9999");
  const [yesterday, setYesterday] = useState("");
  const [isLiveValue, setIsLiveValue] = useState(false);
  const [dailyDate, setDailyDate] = useState("");
  const [currencyLogo, setCurrencyLogo] = useState("₺");
  const [liveDate, setLiveDate] = useState("");
  const [query, setQuery] = useState("");
  const [totalCurrencyValue, setTotalCurrencyValue] = useState(0);
  const errorList = [];

  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 2,
      });
    });
  };
  const chartTakeValues = [];
  const chartTakeValuesTotal = [];

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today);

    yesterday.setDate(yesterday.getDate() - 1);
    today.toDateString();
    setYesterday(yesterday);
    yesterday.toDateString();
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }
    axios
      .get(
        `${config.apiUrl}/tenantaccount/GetListForTotalChart?$apply=groupby((BankName),aggregate(Balance with sum as value))`,
        config.headers
      )
      .then((res) => {
        const chartList = [];
        const chartValuesTRY = res.data;
        {
          chartValuesTRY.map((value, index) => {
            var chartModel = {};
            chartModel.name = value.bankName;
            chartModel.value = value.value;
            chartList.push(chartModel);
          });
        }
        var total = 0;
        var valueTotal = 0;
        try {
          {
            chartList.map((value, index) => {
              if (value.value < 0) {
                value.value = 0;
              }
              total += value.value;
            });
          }

          {
            chartList.map((value, index) => {
              var percents = ((value.value / total) * 100).toFixed(2);
              var percentsFloat = parseFloat(percents);
              if (percentsFloat < 0) {
                percentsFloat = 0;
              }
              value.value = percentsFloat;
              if (value.value < 10) {
                valueTotal += value.value;
              } else {
                chartTakeValues.push(value);
              }
            });
          }
          if (valueTotal != 0) {
            var model = {};
            model.name = "DİĞER";
            model.value = valueTotal;
            chartTakeValues.push(model);
          }
          setTotalChart(chartTakeValues);

        } catch (error) {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push("/not-authorized-access");
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            });
            errorListNotification("error");
          }
        }
      });
    axios
      .get(
        `${config.apiUrl}/tenantaccount?$filter=currencyId ne null&$apply=groupby((Currency,CurrencyId))&$orderby=currencyId`,
        config.headers
      )
      .then((res) => {
        axios
          .get(
            `${config.apiUrl}/TenantAccount/CurrencyBankListTotal?$filter=CurrencyId ne null`,
            config.headers
          )
          .then((res) => {
            setTotalBalanceWithCurrency(res.data);
          })
          .catch((error) => {
            if (error.response.status === 406) {
              error.response.data.data.map((item) => {
                errorList.push(item);
              });
              errorListNotification("error");
            } else if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              history.push("/not-authorized-access");
            } else {
              openNotificationWithIconWarningExcel("error");
            }
          });
        if (res.data.length !== 0) {
          axios
            .get(
              `${config.apiUrl
              }/tenantaccount/GetTenantAccountsList?currencyId=${res.data[0]?.currencyId ?? 0
              }&$apply=groupby((bankName,bankId,bankLogo,currency,currencyLogo),aggregate(Balance with sum as balance))`,
              config.headers
            )
            .then((res) => {
              var bankAccountList = [];
              var toplam = 0;
              var currency = "";
              var currencyLogo = "";
              res.data.map((item) => {
                var model = {};
                model.bankLogo = item.bankLogo;
                model.bankId = item.bankId;
                model.balance = item.balance.toFixed(2).replace(".", ",");
                model.currency = item.currency;
                model.currencyLogo = item.currencyLogo;
                bankAccountList.push(model);
                toplam += item.balance;
                currency = item.currency;
                currencyLogo = item.currencyLogo;
              });
              var model = {};
              model.bankLogo = "Toplam";
              model.balance = toplam.toFixed(2).replace(".", ",");
              model.bankId = "";
              model.currency = currency;
              model.currencyLogo = currencyLogo;
              bankAccountList.push(model);
              setBankListForTRY(bankAccountList);
            })
            .catch((error) => {
              if (
                error.response.status === 403 ||
                error.response.status === 401
              ) {
                history.push("/not-authorized-access");
              } else if (error.response.status === 406) {
                error.response.data.data.map((item) => {
                  errorList.push(item);
                });
                errorListNotification("error");
              }
            });
        } else {
          return null;
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        }
      });
    axios
      .get(`${config.apiUrl}/tenantaccount/GetListForTotal`, config.headers)
      .then((res) => {
        var tenantTotalList = [];
        var total = 0;
        res.data.map((item) => {
          var model = {};
          model.bankLogo = item.bankLogo;
          model.totalBalance = item.balance;
          model.bankName = item.bankName;
          model.currency = item.tenantList;
          tenantTotalList.push(model);
          total += item.balance;
        });
        var model = {};
        model.bankLogo = "TOPLAM";
        model.balance = parseFloat(total.toFixed(2));
        tenantTotalList.push(model);
        setTotalCurrencyValue(parseFloat(total.toFixed(2)));
        setBankListForTotal(tenantTotalList);
      });
    axios
      .get(
        `${config.apiUrl}/TenantCurrency/GetExchangeRateList`,
        config.headers
      )
      .then((res) => {
        axios
          .get(
            `${config.apiUrl}/TenantCurrency/GetIsDailyValue`,
            config.headers
          )
          .then((ress) => {
            setIsLiveValue(ress.data.isLiveValue);
          })
          .catch((error) => {
            if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              history.push("/not-authorized-access");
            } else if (error.response.status === 406) {
              error.response.data.data.map((item) => {
                errorList.push(item);
              });
              errorListNotification("error");
            }
          });
        setLiveDate(res.data[0].liveDate);
        setDailyDate(res.data[0].dailyDate);
        setExchangeRate(res.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    axios
      .get(
        `${config.apiUrl}/TenantCurrency/GetCurrencyListWithIsCalculated?$filter=CurrencyId ne null&$apply=groupby((Currency,CurrencyId))&$orderby=CurrencyId`,
        config.headers
      )
      .then((res) => {
        setCurrencyList(res.data);
        setActiveKey("9999");
        if (res.data.length !== 0) {
          axios
            .get(
              `${config.apiUrl
              }/tenantaccount/GetTenantAccountChartList?currencyId=${res[0]?.currencyId ?? 0
              }&$apply=groupby((name),aggregate(value with sum as value))`,
              config.headers
            )
            .then((res) => {
              const chartValuesTRY = res.data;
              var total = 0;
              var valueTotal = 0;
              var currency = "";
              try {
                {
                  chartValuesTRY.map((value, index) => {
                    if (value.value < 0) {
                      value.value = 0;
                    }
                    total += value.value;
                  });
                }

                {
                  chartValuesTRY.map((value, index) => {
                    var percents = ((value.value / total) * 100).toFixed(2);
                    var percentsFloat = parseFloat(percents);

                    if (percentsFloat < 0) {
                      percentsFloat = 0;
                    }
                    value.value = percentsFloat;

                    if (value.value < 10) {
                      valueTotal += value.value;
                    } else {
                      currency = value.currency;
                      chartTakeValuesTotal.push(value);
                    }
                  });
                }
                if (valueTotal != 0) {
                  var model = {};
                  model.Currency = res.data[0]?.currency;
                  model.name = "DİĞER";
                  model.value = valueTotal;
                  chartTakeValuesTotal.push(model);
                }
                setChartTakeValuesState(chartTakeValuesTotal);
                setLoading(false)

              } catch (error) {
                if (
                  error.response.status === 403 ||
                  error.response.status === 401
                ) {
                  history.push("/not-authorized-access");
                } else if (error.response.status === 406) {
                  error.response.data.data.map((item) => {
                    errorList.push(item);
                  });
                  errorListNotification("error");
                }
              }
            })
            .catch((error) => {
              if (
                error.response.status === 403 ||
                error.response.status === 401
              ) {
                history.push("/not-authorized-access");
              } else if (error.response.status === 406) {
                error.response.data.data.map((item) => {
                  errorList.push(item);
                });
                errorListNotification("error");
              }
            });
        } else {
          return null;
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        }
      });
  }, [setCurrencyList, setBankListForTRY, setBankListForTotal]);

  useEffect(() => {
    axios
      .get(
        `${config.apiUrl}/tenantaccount?$filter=currencyId ne null&$apply=groupby((Currency,CurrencyId))&$orderby=currencyId`,
        config.headers
      )
      .then((res) => {
        axios
          .get(
            `${config.apiUrl}/TenantAccount/CurrencyBankListTotal?$filter=CurrencyId ne null`,
            config.headers
          )
          .then((res) => {
            setTotalBalanceWithCurrency(res.data);
          })
          .catch((error) => {
            if (error.response.status === 406) {
              error.response.data.data.map((item) => {
                errorList.push(item);
              });
              errorListNotification("error");
            } else if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              history.push("/not-authorized-access");
            } else {
              openNotificationWithIconWarningExcel("error");
            }
          });
        if (res.data.length !== 0) {
          axios
            .get(
              `${config.apiUrl
              }/tenantaccount/GetTenantAccountsList?currencyId=${res.data[0]?.currencyId ?? 0
              }${query}&$apply=groupby((bankName,bankId,bankLogo,currency,currencyLogo),aggregate(Balance with sum as balances))`,
              config.headers
            )
            .then((res) => {
              var bankAccountList = [];
              var toplam = 0;
              var currency = "";
              var currencyLogo = "";
              res.data.map((item) => {
                var model = {};
                model.bankLogo = item.bankLogo;
                model.bankId = item.bankId;
                model.balance = item.balances.toFixed(2).replace(".", ",");
                model.currency = item.currency;
                model.currencyLogo = item.currencyLogo;
                bankAccountList.push(model);
                toplam += item.balances;
                currency = item.currency;
                currencyLogo = item.currencyLogo;
              });
              var model = {};
              model.bankLogo = "Toplam";
              model.balance = toplam.toFixed(2).replace(".", ",");
              model.bankId = "";
              model.currency = currency;
              model.currencyLogo = currencyLogo;
              bankAccountList.push(model);
              setBankListForTRY(bankAccountList);
            })
            .catch((error) => {
              if (
                error.response.status === 403 ||
                error.response.status === 401
              ) {
                history.push("/not-authorized-access");
              } else if (error.response.status === 406) {
                error.response.data.data.map((item) => {
                  errorList.push(item);
                });
                errorListNotification("error");
              }
            });
        } else {
          return null;
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        }
      });
  }, [query])
  const titles = [];
  titles.push("Banka");
  currencyList.map((item) => {
    titles.push(item.currency);
  });


  function onChange(value) {
    var exchangeValue = exchangeRate.filter(x => x.currencyCode == value)[0]?.value;
    if (value != "TRY") {
      bankListForTotal.map((item) => {
        item.totalBalance = (item.totalBalance / parseFloat(exchangeValue.replace(',', '.'))).toFixed(2);
      })
    }
    axios
      .get(`${config.apiUrl}/tenantaccount/GetListForTotal?currencyCode=${value}`, config.headers)
      .then((res) => {
        var tenantTotalList = [];
        var total = 0;
        res.data.map((item) => {
          setCurrencyLogo(item.currencyLogo);
          var model = {};
          model.bankLogo = item.bankLogo;
          model.currencyLogo = item.currencyLogo;
          model.totalBalance = parseFloat(item.balance.toFixed(2));
          model.bankName = item.bankName;
          model.currency = item.tenantList;
          tenantTotalList.push(model);
          total += item.balance;
        });
        var model = {};
        model.bankLogo = "TOPLAM";
        model.balance = parseFloat(total.toFixed(2));
        tenantTotalList.push(model);
        setTotalCurrencyValue(parseFloat(total.toFixed(2)));
        setBankListForTotal(tenantTotalList);
      });
    // setBankListForTotal(bankListForTotal)

  }

  function onSearch(val) {
  }
  const columnsTotal = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      width: "20%",
      render: (text, row, index) => {
        {
          if (row.bankLogo != "TOPLAM") {
            return <img src={row.bankLogo}></img>;
          }
        }
      },
    },
    {
      title: "Bakiye",
      dataIndex: "balance",
      align: "right",
      render: (text, row, index) => {
        {
          if (row.bankLogo != "TOPLAM") {
            return (
              <CurrencyFormat
                value={row.totalBalance}
                displayType={"text"}
                decimalSeparator={","}
                thousandSeparator={"."}
                prefix={"₺"}
              />
            );
          }
        }
      },
    },
  ];
  const columnsExchangeRate = [
    {
      dataIndex: "currencyName",
    },
    {
      dataIndex: "currencyCode",
    },
    {
      dataIndex: "value",
      render: (record, text) => {
        return `${record === null ? "-" : record}`;
      },
    },
  ];
  const paging = (p, filters, sorter) => {
    let order = "";
    if (sorter.columnKey && sorter.order) {
      order =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }
    setQuery(order)
  };
  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      render: (text, row, index) => {
        return (
          <div style={{ width: 100 }}>
            <img src={row.bankLogo}></img>
          </div>
        );
      },
    },
    {
      title: "Bakiye",
      dataIndex: "balance",
      align: "right",
      key: "balances",
      sorter: true,
      render: (text, row, index) => {
        return (
          <CurrencyFormat
            value={row.balance}
            displayType={"text"}
            decimalSeparator={","}
            thousandSeparator={"."}
            prefix={row.currencyLogo}
          />
        );
      },
    },
    {
      title: "Detay",
      dataIndex: "BankId",
      width: "20%",
      render: (row, record, text) => {
        if (record.bankLogo === "Toplam") {
          return null;
        } else {
          return (
            <Link
              style={{ fontSize: "150%" }}
              to={`/bank-list-accounts/${record.bankId}-${record.currency}`}
            >
              <MenuUnfoldOutlined />
            </Link>
          );
        }
      },
    },
  ];
  const ContactSideBar = (value, e) => {
    var totalCurrencyValue = 0;
    var currency = "";
    bankListForTRY
      .filter((x) => x.bankLogo == "Toplam")
      .map((item) => {
        if (item.bankLogo === "Toplam") {
          totalCurrencyValue = item.balance;
          currency = item.currencyLogo;
        }
      });
    return (
      <Row>
        {isTabletOrMobile === false && (
          <>
            <Col span={12} style={{ paddingLeft: 19 }}>
              <Table
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Veri Yok"
                    />
                  ),
                }}
                style={{ overflowX: "auto", maxHeight: "100%" }}
                columns={columns}
                size="small"
                dataSource={bankListForTRY.filter(
                  (x) => x.currency == value && x.bankLogo != "Toplam"
                )}
                rowKey="Id"
                sortable={true}
                onChange={paging}
                bordered
                title={() => "BANKA LİSTESİ"}
                footer={() => (
                  <div>
                    <div>
                      Toplam :{" "}
                      <CurrencyFormat
                        value={totalCurrencyValue}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={currency}
                      />
                    </div>
                  </div>
                )}
              />
            </Col>
            <Col span={12}>
              <PieChart width={500} height={500}>
                <Pie
                  outerRadius={150}
                  data={chartTakeValuesState}
                  fill="#8884d8"
                  label={renderCustomizedLabelTRY}
                >
                  {chartTakeValuesState.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={CustomToolTip} />
              </PieChart>
              {chartTakeValuesState.map((entry, index) => (
                <div key={`cell-${index}`}>
                  <a style={{ color: COLORS[index % COLORS.length] }}>
                    <CaretRightFilled />
                  </a>
                  {entry.name}
                </div>
              ))}
            </Col>
          </>
        )}
        {isTabletOrMobile && (
          <>
            <Col span={24}>
              <Table
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Veri Yok"
                    />
                  ),
                }}
                style={{ overflowX: "auto", maxHeight: "100%" }}
                columns={columns}
                size="small"
                dataSource={bankListForTRY.filter(
                  (x) => x.currency == value && x.bankLogo != "Toplam"
                )}
                rowKey="Id"
                sortable={true}
                onChange={paging}
                bordered
                title={() => "BANKA LİSTESİ"}
                footer={() => (
                  <div>
                    <div>
                      Toplam :{" "}
                      <CurrencyFormat
                        value={totalCurrencyValue}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={currency}
                      />
                    </div>
                  </div>
                )}
              />
            </Col>
            <Col span={24}>
              <PieChart
                width={isChartWidth === true ? 500 : 300}
                height={isChartWidth === true ? 500 : 300}
              >
                <Pie
                  outerRadius={isChartWidth === true ? 150 : 80}
                  data={chartTakeValuesState}
                  fill="#8884d8"
                  label={renderCustomizedLabelTRY}
                >
                  {chartTakeValuesState.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={CustomToolTip} />
              </PieChart>
              {chartTakeValuesState.map((entry, index) => (
                <div key={`cell-${index}`}>
                  <a style={{ color: COLORS[index % COLORS.length] }}>
                    <CaretRightFilled />
                  </a>
                  {entry.name}
                </div>
              ))}
            </Col>
          </>
        )}
      </Row>
    );
  };
  const openNotificationWithIconExcel = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Excel indirme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });
  };
  const openNotificationWithIconWarningExcel = (type) => {
    notification[type]({
      message: "HATA",
      description: "Excel indirme işlemi sırasında bir hata oluştu.",
      duration: 2,
    });
  };

  const handleChange = (activeKey) => {
    axios
      .get(
        `${config.apiUrl}/tenantaccount/GetTenantAccountsList?currencyId=${activeKey == "null" ? 0 : activeKey
        }${query}&$apply=groupby((bankName,bankId,bankLogo,currency,currencyLogo),aggregate(Balance with sum as balance))`,
        config.headers
      )
      .then((res) => {
        var bankAccountList = [];
        var toplam = 0;
        var currency = "";
        var currencyLogo = "";
        res.data.map((item) => {
          var model = {};
          model.bankLogo = item.bankLogo;
          model.bankId = item.bankId;
          model.balance = item.balance.toFixed(2).replace(".", ",");
          model.currency = item.currency;
          model.currencyLogo = item.currencyLogo;
          bankAccountList.push(model);
          toplam += item.balance;
          currency = item.currency;
          currencyLogo = item.currencyLogo;
        });
        var model = {};
        model.bankLogo = "Toplam";
        model.balance = toplam.toFixed(2).replace(".", ",");
        model.bankId = "";
        model.currency = currency;
        model.currencyLogo = currencyLogo;
        bankAccountList.push(model);
        setBankListForTRY(bankAccountList);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });

    axios
      .get(
        `${config.apiUrl}/tenantaccount/GetTenantAccountChartList?currencyId=${activeKey == "null" ? 0 : activeKey
        }&$apply=groupby((name),aggregate(value with sum as value))`,
        config.headers
      )
      .then((res) => {
        const chartValuesTRY = res.data;
        var total = 0;
        var valueTotal = 0;
        var currency = "";
        try {
          {
            chartValuesTRY.map((value, index) => {
              if (value.value < 0) {
                value.value = 0;
              }
              total += value.value;
            });
          }

          {
            chartValuesTRY.map((value, index) => {
              var percents = ((value.value / total) * 100).toFixed(2);
              var percentsFloat = parseFloat(percents);

              if (percentsFloat < 0) {
                percentsFloat = 0;
              }
              value.value = percentsFloat;

              if (value.value < 10) {
                valueTotal += value.value;
              } else {
                currency = value.Currency;
                chartTakeValuesTotal.push(value);
              }
            });
          }
          if (valueTotal != 0) {
            var model = {};
            model.name = "DİĞER";
            model.value = valueTotal;
            chartTakeValuesTotal.push(model);
          }
          setChartTakeValuesState(chartTakeValuesTotal);
        } catch (error) {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push("/not-authorized-access");
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            });
            errorListNotification("error");
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    setActiveKey(activeKey);
  };

  const { TabPane } = Tabs;
  var totalValue = 0;
  var currency = "";
  bankListForTotal.map((item) => {
    if (item.bankLogo === "TOPLAM") {
      totalValue = item.balance;
      currency = "₺";
    }
  });
  return (
    <>
      <Row>
        <Col xs={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> BANKA LİSTESİ
          </p>
        </Col>
        <Col xs={8}>
          <Row justify="end" align="middle">
            {activeKey === "9999" && (
              <AntdTooltip title="Excel İndir">
                <ReactHTMLTableToExcel
                  table="emp"
                  filename="BankaListesi"
                  sheet="tablexlsx"
                  buttonText={
                    <FileExcelOutlined style={{ fontSize: "180%", color: "green" }} />
                  }
                  className="ant-btn ant-btn-link"
                />
              </AntdTooltip>
            )}
          </Row>
        </Col>
      </Row>

      <Tabs
        style={{ marginTop: -21 }}
        onChange={handleChange}
        activeKey={activeKey}
      >
        {currencyList.length !== 0 ? (
          <TabPane tab="TÜMÜ" key="9999">
            <Spin spinning={loading}>
              <Row justify={"center"}>
                {isTabletOrMobile === false && (
                  <>
                    <Col span={13}>
                      <table class="table" style={{ marginBottom: 50 }}>
                        <tr>
                          {titles.map((item) => {
                            return <th style={{ paddingBottom: 10 }}>{item}</th>;
                          })}
                          <td>
                            <Select
                              showSearch
                              optionFilterProp="children"
                              onChange={onChange}
                              size="small"
                              style={{ marginBottom: 4 }}
                              onSearch={onSearch}
                              defaultValue={"TRY"}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {
                                currencyList.map((item) => {
                                  return <Option value={item.currency}> <strong>{item.currency} TOPLAM </strong></Option>
                                })
                              }
                            </Select>
                          </td>
                        </tr>
                        {bankListForTotal.map((item) => {
                          if (item.bankLogo !== "TOPLAM") {
                            var currency = item.currency;
                            return (
                              <tr>
                                <td style={{ paddingBottom: 13 }}>
                                  <img
                                    style={{ width: 100, marginRight: 15 }}
                                    src={item.bankLogo}
                                  />
                                </td>
                                {currency?.map((accounts) => {
                                  return (
                                    <td
                                      style={{ paddingBottom: 13, width: 120 }}
                                      class="text-center"
                                    >
                                      {accounts.balance
                                        .toString()
                                        .includes(".") ? (
                                        <div style={{ whiteSpace: "nowrap" }}>
                                          <CurrencyFormat
                                            value={accounts.balance}
                                            displayType={"text"}
                                            decimalSeparator={","}
                                            thousandSeparator={"."}
                                            prefix={accounts.currencyLogo}
                                          />
                                        </div>
                                      ) : (
                                        <div style={{ whiteSpace: "nowrap" }}>
                                          {" "}
                                          <CurrencyFormat
                                            value={accounts.balance}
                                            displayType={"text"}
                                            decimalSeparator={","}
                                            thousandSeparator={"."}
                                            prefix={accounts.currencyLogo}
                                          />
                                          ,00{" "}
                                        </div>
                                      )}
                                    </td>
                                  );
                                })}

                                <td style={{ paddingBottom: 13, textAlign: 'center' }}>
                                  {item.totalBalance.toString().includes(".") ? (
                                    <div style={{ whiteSpace: "nowrap" }}>
                                      <CurrencyFormat
                                        value={item.totalBalance}
                                        displayType={"text"}
                                        decimalSeparator={","}
                                        thousandSeparator={"."}
                                        prefix={item.currencyLogo ?? "₺"}
                                      />
                                    </div>
                                  ) : (
                                    <div style={{ whiteSpace: "nowrap" }}>
                                      <CurrencyFormat
                                        value={item.totalBalance}
                                        displayType={"text"}
                                        decimalSeparator={","}
                                        thousandSeparator={"."}
                                        prefix={item.currencyLogo ?? "₺"}
                                      />
                                      ,00
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        })}
                        <tr>
                          <td class="text-center">
                            <strong>TOPLAM</strong>
                          </td>
                          {totalBalanceWithCurrency.map((accounts) => {
                            return (
                              <td >
                                {accounts.balance.toString().includes(".") ? (
                                  <div style={{ whiteSpace: "nowrap" }}>
                                    {" "}
                                    <CurrencyFormat
                                      value={accounts.balance}
                                      displayType={"text"}
                                      decimalSeparator={","}
                                      thousandSeparator={"."}
                                      prefix={accounts.currencyLogo}
                                    />
                                  </div>
                                ) : (
                                  <div style={{ whiteSpace: "nowrap" }}>
                                    {" "}
                                    <CurrencyFormat
                                      value={accounts.balance}
                                      displayType={"text"}
                                      decimalSeparator={","}
                                      thousandSeparator={"."}
                                      prefix={accounts.currencyLogo}
                                    />
                                    ,00
                                  </div>
                                )}
                              </td>
                            );
                          })}
                          <td style={{ textAlign: 'center' }}>
                            {/* SAĞ ALT TOPLAM */}

                            {totalCurrencyValue.toString().includes(".") ? (
                              <div style={{ whiteSpace: "nowrap" }}>
                                <CurrencyFormat
                                  value={totalCurrencyValue}
                                  displayType={"text"}
                                  decimalSeparator={","}
                                  thousandSeparator={"."}
                                  prefix={currencyLogo}
                                />
                              </div>
                            ) : (
                              <div style={{ whiteSpace: "nowrap" }}>
                                <CurrencyFormat
                                  value={totalCurrencyValue}
                                  displayType={"text"}
                                  decimalSeparator={","}
                                  thousandSeparator={"."}
                                  prefix={currencyLogo}
                                />
                                ,00
                              </div>
                            )}
                          </td>
                        </tr>
                        <tr></tr>
                      </table>
                      <table
                        hidden
                        id="emp"
                        class="table"
                        style={{ marginBottom: 50 }}
                      >
                        <tr>
                          {titles.map((item) => {
                            return <th>{item}</th>;
                          })}
                          <td>
                            <strong>TOPLAM</strong>{" "}
                          </td>
                        </tr>
                        {bankListForTotal.map((item) => {
                          if (item.bankLogo !== "TOPLAM") {
                            var currency = item.currency;
                            return (
                              <tr>
                                <td>
                                  <strong>{item.bankName}</strong>
                                </td>
                                {currency?.map((accounts) => {
                                  return (
                                    <td class="text-center">
                                      {accounts.balance}
                                    </td>
                                  );
                                })}
                                <td>{item.totalBalance} </td>
                              </tr>
                            );
                          }
                        })}
                        <tr>
                          <td>
                            <strong>TOPLAM</strong>
                          </td>

                          {totalBalanceWithCurrency.map((accounts) => {
                            return <td>{accounts.balance}</td>;
                          })}
                          <td>{totalCurrencyValue}</td>
                        </tr>

                        <tr></tr>
                      </table>
                    </Col>
                    <Col span={9}>
                      <PieChart width={500} height={500}>
                        <Pie
                          outerRadius={150}
                          data={totalChart}
                          fill="#8884d8"
                          label={renderCustomizedLabelTRY}
                        >
                          {totalChart.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip content={CustomToolTip} />
                      </PieChart>
                      {totalChart.map((entry, index) => (
                        <div key={`cell-${index}`}>
                          <a style={{ color: COLORS[index % COLORS.length] }}>
                            <CaretRightFilled />
                          </a>
                          {entry.name}
                        </div>
                      ))}
                    </Col>
                    <Col span={1}></Col>
                    <Col span={22} style={{ marginBottom: 50, marginTop: 40 }}>
                      <Collapse>
                        <Panel
                          header="Döviz kurlarını görmek için tıklayın"
                          key="1"
                        >
                          <Card
                            title={"KUR BİLGİSİ  "}
                            style={{ marginLeft: "1%" }}
                            extra={`Kur bilgileri ${isLiveValue ? liveDate : dailyDate
                              } tarihine aittir.`}
                            className={`gx-card-widget`}
                          >
                            <div className="gx-table-responsive">
                              <Table
                                className="gx-table-no-bordered"
                                columns={columnsExchangeRate}
                                dataSource={exchangeRate}
                                pagination={false}
                                bordered={false}
                                size="small"
                              />
                            </div>
                          </Card>
                        </Panel>
                      </Collapse>
                    </Col>
                    <Col span={1}></Col>
                  </>
                )}
                {isTabletOrMobile && (
                  <>
                    <Col span={24} style={{ overflowX: "auto" }}>
                      <table
                        style={{
                          marginBottom: 50,
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <tr>
                          {titles.map((item) => {
                            return <th>{item}</th>;
                          })}
                          <td>
                            <Select
                              showSearch
                              optionFilterProp="children"
                              onChange={onChange}
                              onSearch={onSearch}
                              defaultValue={"TRY"}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {
                                currencyList.map((item) => {
                                  return <Option value={item.currency}> <strong>{item.currency} TOPLAM </strong></Option>
                                })
                              }
                            </Select>
                          </td>
                        </tr>
                        {bankListForTotal.map((item) => {
                          if (item.bankLogo !== "TOPLAM") {
                            var currency = item.currency;
                            return (
                              <tr>
                                <td>
                                  <img
                                    style={{ width: 120 }}
                                    src={item.bankLogo}
                                  />
                                </td>
                                {currency?.map((accounts) => {
                                  return (
                                    <td
                                      style={{
                                        paddingBottom: 13,
                                        width: 120,
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                      }}
                                      class="text-center"
                                    >
                                      {accounts.balance
                                        .toString()
                                        .includes(".") ? (
                                        <div style={{ whiteSpace: "nowrap" }}>
                                          <CurrencyFormat
                                            value={accounts.balance}
                                            displayType={"text"}
                                            decimalSeparator={","}
                                            thousandSeparator={"."}
                                            prefix={accounts.currencyLogo}
                                          />
                                        </div>
                                      ) : (
                                        <div style={{ whiteSpace: "nowrap" }}>
                                          {" "}
                                          <CurrencyFormat
                                            value={accounts.balance}
                                            displayType={"text"}
                                            decimalSeparator={","}
                                            thousandSeparator={"."}
                                            prefix={accounts.currencyLogo}
                                          />
                                          ,00{" "}
                                        </div>
                                      )}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{
                                    paddingBottom: 13,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                  }}
                                >
                                  {item.totalBalance.toString().includes(".") ? (
                                    <div style={{ whiteSpace: "nowrap" }}>
                                      <CurrencyFormat
                                        value={item.totalBalance}
                                        displayType={"text"}
                                        decimalSeparator={","}
                                        thousandSeparator={"."}
                                        prefix={item.currencyLogo ?? "₺"}
                                      />
                                    </div>
                                  ) : (
                                    <div style={{ whiteSpace: "nowrap" }}>
                                      <CurrencyFormat
                                        value={item.totalBalance}
                                        displayType={"text"}
                                        decimalSeparator={","}
                                        thousandSeparator={"."}
                                        prefix={item.currencyLogo ?? "₺"}
                                      />
                                      ,00
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        })}
                        <tr></tr>
                      </table>
                    </Col>
                    <Col span={24}>
                      <Collapse>
                        <Panel
                          header="Döviz kurlarını görmek için tıklayın"
                          key="1"
                        >
                          <Card
                            title={"KUR BİLGİSİ  "}
                            style={{ marginLeft: "1%" }}
                            extra={`Kur bilgileri ${isLiveValue ? liveDate : dailyDate
                              } tarihine aittir.`}
                            className={`gx-card-widget`}
                          >
                            <div className="gx-table-responsive">
                              <Table
                                className="gx-table-no-bordered"
                                columns={columnsExchangeRate}
                                dataSource={exchangeRate}
                                pagination={false}
                                bordered={false}
                                size="small"
                              />
                            </div>
                          </Card>
                        </Panel>
                      </Collapse>
                    </Col>
                    <Col span={24}>
                      <PieChart
                        width={isChartWidth === true ? 500 : 300}
                        height={isChartWidth === true ? 500 : 300}
                      >
                        <Pie
                          outerRadius={isChartWidth === true ? 150 : 80}
                          data={totalChart}
                          fill="#8884d8"
                          label={renderCustomizedLabelTRY}
                        >
                          {totalChart.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip content={CustomToolTip} />
                      </PieChart>
                      {totalChart.map((entry, index) => (
                        <div key={`cell-${index}`}>
                          <a style={{ color: COLORS[index % COLORS.length] }}>
                            <CaretRightFilled />
                          </a>
                          {entry.name}
                        </div>
                      ))}
                    </Col>
                  </>
                )}
              </Row>
            </Spin>
          </TabPane>
        ) : (
          <Table
            style={{ marginLeft: "50%" }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
          />
        )}
        {currencyList.length === 0 ? (
          <TabPane tab={<span></span>} key="1">
            <Row>
              <Col span={24}>
                <Table
                  locale={{
                    emptyText: (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="Veri Yok"
                      />
                    ),
                  }}
                />
              </Col>
            </Row>
          </TabPane>
        ) : (
          currencyList.map((item, index) => {
            return (
              <TabPane tab={<span>{item.currency}</span>} key={item.currencyId}>
                <Spin spinning={loading}>
                  {ContactSideBar(item.currency)}
                </Spin>
              </TabPane>
            );
          })
        )}
      </Tabs>
    </>
  );
};
export default BankList;
