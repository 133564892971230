import React, { useState, useEffect } from "react";
import { Row, Col, Table, Empty } from "antd";
import RequestHandler from "util/RequestHandler";
import CurrencyFormat from "react-currency-format";
import { BarsOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const BankSummaryDetailReport = (props) => {
  const [tableData, setWidgetData] = useState({
    loading: true,
  });

  useEffect(() => getData(), []);

  const getData = () => {
    const url = props.dataUrl ? props.dataUrl : "/DbsDashboard/BankSummaryReport";

    RequestHandler.get(url).then((response) => {
      setWidgetData({
        data: response.data,
        loading: false,
      });
    });
  };

  const renderCurrencyFormat = (data) => (
    <CurrencyFormat
      value={data}
      displayType={"text"}
      decimalSeparator={","}
      thousandSeparator={"."}
      prefix={"₺"}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  )

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      render: (text, row, index) => {
        return (
          <div style={{ width: 100 }}>
            <img src={text}></img>
          </div>
        );
      },
    },
    {
      title: "Limit",
      dataIndex: "totalLimit",
      render: renderCurrencyFormat,
    },
    {
      title: "Kullanılabilir Limit",
      dataIndex: "totalAvailability",
      render: renderCurrencyFormat,
    },
    {
      title: "Risk",
      dataIndex: "totalRisk",
      render: renderCurrencyFormat,
    },
    {
      title: "Kullanılan Limit",
      dataIndex: "totalLimit",
      render: (data, row) => renderCurrencyFormat(row.totalLimit - row.totalAvailability),
    },
    {
      title: "Bayi Sayısı",
      dataIndex: "dealerCount",
    },
    {
      title: "Fatura Adedi",
      dataIndex: "invoiceCount",
    },
    {
      title: "Fatura Toplamı",
      dataIndex: "invoiceTotal",
      render: renderCurrencyFormat,
    },
    {
      title: "Detay",
      dataIndex: "bankId",
      align: "center",
      render: (data, record, text) => (<a
        style={{ fontSize: "130%" }}
        href={`/dbs-dealer/${data}`}
      >
        <MenuUnfoldOutlined />
      </a>),
    }
  ];

  return (
    <>

      <Row>
        <Col xs={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> BANKA ÖZET RAPORU
          </p>
        </Col>
        <Col xs={8}>
        </Col>
      </Row>

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="bankId"
            filterable={false}
            sortable={false}
            pageable={false}
            pagination={false}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};

export default BankSummaryDetailReport;
