import React from "react";
import {Card} from "antd";
import PropTypes from "prop-types";

const Widget = ({title, children, styleName, cover, extra, actions}) => {

  return (
    <Card className={`gx-card-widget ${styleName} ant-col gx-col-full ant-col-xs-11 ant-col-sm-11 ant-col-md-11 ant-col-lg-11 ant-col-xl-11`} style={{marginTop:"2%", marginBottom:"2%", borderRadius:"2%",marginLeft:"1%"}} >
      {children}
    </Card>
  )
};

export default Widget;
Widget.defaultProps = {
  styleName: '',
};

Widget.propTypes = {
  title: PropTypes.node,
  extra: PropTypes.node,
  cover: PropTypes.node,
  actions: PropTypes.node,
  children: PropTypes.node.isRequired
};
