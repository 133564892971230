import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";
import axios from 'util/Api'
import {notification} from 'antd'
import config from "../../util/ApiforContext"

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const userSignUp = ({email, password, name}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/register', {
        email: email,
        password: password,
        name: name
      }
    ).then(({data}) => {
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        axios.defaults.headers.common['authorization'] = "Bearer " + data.token.access_token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }
};
export const userSignIn = ({email, password}) => {
  const sameError = type => {
    notification[type]({
      message: 'Hata',
      description:
        'Bir hata oluştu.',
        duration: 2,
    });
  };
  const errorList = [];

  const errorListNotification = type => {
    errorList.map((item)=> {
      notification[type]({
        message: 'Hata',
        description:
        item
         ,
          duration: 2,
      });
    })
  };
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post(`${config.apiUrl}/Auth/Login`, 
    {
        email: email,
        password: password,
    }
    ).then(({data}) => {
      if (data.success) {
        
        window.location.reload()
         const expiration = new Date(data.data.expiration).getTime();
         localStorage.setItem("userId", JSON.stringify(data.data.userId));
         localStorage.setItem("email", JSON.stringify(data.data.email));
         localStorage.setItem("tenantId", JSON.stringify(data.data.tenantId));
         localStorage.setItem("expiration", JSON.stringify(expiration));
         localStorage.setItem("token", JSON.stringify(data.data.token));
         localStorage.setItem("userName", JSON.stringify(data.data.userName));
        axios.defaults.headers.common['Authorization'] = "Bearer " + data.data.token;
        //  dispatch({type: FETCH_SUCCESS});
        // dispatch({type: USER_TOKEN_SET, payload: data.data.token});
        //  dispatch({type: USER_TOKEN_SET, payload: expiration});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.message});
      }
    }).catch(function (error) {
      if(error.response.status === 406){
        error.response.data.data.map((item)=>{
          errorList.push(item);
        })
        errorListNotification('error')
       }else{
        sameError('error')

       }
    });
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/me',
    ).then(({data}) => {
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }
};


export const userSignOut = (value) => {
  axios.get(`${config.apiUrl}/Auth/Logout?userId=${value}`,config.headers)
  .then(res => {
    if(res.data.success==true){
      localStorage.removeItem("token");
      window.location.reload()
    }
         
    }
  );
};
 
