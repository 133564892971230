import React, { useState,useEffect } from 'react'
import { Row, Col, List, Typography, Divider,Avatar } from 'antd';
import { AliwangwangOutlined ,QuestionCircleOutlined,UnorderedListOutlined} from '@ant-design/icons';
import Questions from './Questions'
import SupportRequestFirst from './SupportRequestFirst';
import SupportsList from './SupportsList';

const SupportPanel = (props) => {
        const [activeNumber, setActiveNumber] = useState(props.match.params.id === undefined ? 1 : props.match.params.id);
        
        return (
            <>
            
                <Row  justify="center" >

                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} style={{textAlign:'center'}}>
                    <h1 style={{marginBottom:50}}>Size Nasıl Yardımcı Olabiliriz ?</h1>

                    </Col>
                    <Col className="gutter-row" xs={24} sm={12} md={14} lg={14} >
                        {
                            activeNumber == 1 ?
                            <Questions /> : (activeNumber == 2 ?  <SupportRequestFirst /> : <SupportsList /> )
                        }
                    </Col>
                </Row>
            </>
        )
}
export default SupportPanel;