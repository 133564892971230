import React, { useState } from "react";
import DailyBalanceReport from "./DailyBalanceReport";
import MonthlyBalanceReport from "./MonthlyBalanceReport";
import { Tabs, Col, Row, Tooltip, message } from "antd";
import { BarsOutlined, FileExcelOutlined } from "@ant-design/icons";
import moment from "moment";
import RequestHandler from "util/RequestHandler";

import config from "../../util/ApiforContext";

const DayReports = () => {
  const { TabPane } = Tabs;
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [query, setQuery] = useState("");
  const [bankId, setBankId] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [amountMax, setAmountMax] = useState("");
  const [amounMin, setAmountMin] = useState("");

  const onMonthlyFilterChange = (filterData) => {
    if (filterData.bankFilterId !== "") {
      setBankId(filterData.bankFilterId);
    } else {
      setBankId("")
    }
    if (filterData.amountMin !== "") {
      setAmountMin(filterData.amountMin);
    } else {
      setAmountMin("")
    }
    if (filterData.amountMax !== "") {
      setAmountMax(filterData.amountMax);
    } else {
      setAmountMax("")
    }
    if (filterData.endDate !== undefined && filterData.startDate !== undefined) {
      setEndDate(moment(filterData.endDate).format("DD.MM.YYYY"));
      setStartDate(moment(filterData.startDate).format("DD.MM.YYYY"));
    } else {
      setEndDate("")
      setStartDate("")
    }
    if (filterData.filter !== "") {
      setQuery("isBankMonthly=true&isExcelValue=true&isOdataQuery=true&$filter=" + filterData.filter)
    } else if (filterData.endDate !== null) {
      setQuery("isBankMonthly=true&isExcelValue=true&isOdataQuery=true")
    }
    else {
      setQuery("isBankMonthly=true&isExcelValue=true&")
    }
  };

  const onDailyFilterChange = (filterData) => {

    if (filterData.bankFilterId !== "") {
      setBankId(filterData.bankFilterId);
    } else {
      setBankId("")
    }

    if (filterData.amountMin !== "") {
      setAmountMin(filterData.amountMin);
    } else {
      setAmountMin("")
    }
    if (filterData.amountMax !== "") {
      setAmountMax(filterData.amountMax);
    } else {
      setAmountMax("")
    }
    if (filterData.endDate !== undefined && filterData.startDate !== undefined) {
      setEndDate(moment(filterData.endDate).format("DD.MM.YYYY"));
      setStartDate(moment(filterData.startDate).format("DD.MM.YYYY"));
    } else {
      setEndDate("")
      setStartDate("")
    }
    if (filterData.filter !== "") {
      setQuery("isBankDaily=true&isExcelValue=true&isOdataQuery=true&$filter=" + filterData.filter)
    } else if (filterData.endDate !== null) {
      setQuery("isBankDaily=true&isExcelValue=true&isOdataQuery=true")
    }
    else {
      setQuery("isBankDaily=true&isExcelValue=true&")
    }
  };


  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);
    RequestHandler.post(
      `/DailyBalanceReport/ExportExcel`,
      {
        query: query,
        BankId: bankId,
        EndDate: endDate,
        StartDate: startDate
      },
      false
    ).then((response) => {
      hideMessage();
      if (response) {
        window.open(
          `${config.apiUrl
          }/DailyBalanceReport/DownloadExcel?fileName=${response}&t=${RequestHandler.getToken()}`
        );
      }
    });
  };

  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> GÜN SONU RAPORU
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
          <Tooltip title="Excel İndir">
            <a
              style={{
                color: "green",
                fontSize: "180%",
                marginRight: 30,
              }}
              onClick={onClickExportExcel}
            >
              <FileExcelOutlined />
            </a>
          </Tooltip>
        </Col>
      </Row>

      <Tabs
        defaultActiveKey="1"
        onChange={(activeKey) => {
          setActiveTabKey(activeKey);
        }}
      >
        <TabPane tab="Gün Bazlı" key="1">
          <DailyBalanceReport onFilterChange={onDailyFilterChange} />
        </TabPane>
        <TabPane tab="Ay Bazlı" key="2">
          <MonthlyBalanceReport onFilterChange={onMonthlyFilterChange} />
        </TabPane>
      </Tabs>
    </>
  )
}
export default DayReports;