import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Select,
  DatePicker,
  InputNumber,
  Upload,
  notification,
} from "antd";
import {
  PlusOutlined,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";
import { odata } from "../Filters/Helper";
import moment from "moment";
import config from "../../util/ApiforContext";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const DbsInvoiceAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: true,
  });
  const [currencySelect, setCurrencySelect] = useState({
    data: [],
    loading: true,
  });
  const [dealerSelect, setDealerSelect] = useState({
    data: [],
    loading: false,
  });
  const [activeBankId, setActiveBankId] = useState();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getBanks();
    form.setFieldsValue({ invoiceDate: moment() });
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    form.resetFields(["dbsDealerId", "currencyId"]);
    setDealerSelect({
      data: [],
      loading: false,
    });
    getCurrencies();
  }, [activeBankId]);

  const getBanks = () => {
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    RequestHandler.get(`/DbsBankInfo/AddedBanks?$select=id,name`).then(
      (response) => {
        setBankSelect({
          data: response,
          loading: false,
        });
      }
    );
  };

  const getCurrencies = () => {
    if (!activeBankId) {
      setCurrencySelect({
        data: [],
        loading: false,
      });
      return;
    }

    setCurrencySelect({
      data: [],
      loading: true,
    });
    RequestHandler.get(`/DbsInvoice/AvailableCurrencies/${activeBankId}`).then(
      (response) => {
        setCurrencySelect({
          data: response,
          loading: false,
        });

        const isTrySelectable =
          response?.findIndex((x) => x.currencyId == 1) >= 0;
        if (isTrySelectable) {
          form.setFieldsValue({ currencyId: 1 });
        }
      }
    );
  };

  const onDealerSearch = (value) => {
    if (value.length >= 2) {
      setDealerSelect({ ...dealerSelect, loading: true });

      RequestHandler.get(
        `/DbsDealer?$select=id,code,name&$filter=(${odata.contains(
          "name",
          value
        )} or ${odata.contains("code", value)}) and bankId eq ${activeBankId}`
      ).then((res) => {
        setDealerSelect({ data: res.value, loading: false });
      });
    } else {
      setDealerSelect({ data: [], loading: false });
    }
  };
  const onSuccess = () => {
    form.resetFields();
    setFileList([]);

    //default values
    setActiveBankId();
    form.setFieldsValue({ invoiceDate: moment() });

    if (props.onSuccess) props.onSuccess();
  };

  const onFinish = (values) => {
    values.invoiceDate = values.invoiceDate.format("YYYY-MM-DD");
    values.dueDate = values.dueDate.format("YYYY-MM-DD");

    RequestHandler.post(`/DbsInvoice`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        onSuccess();
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  const downloadExcelTemplate = () => {
    window.open(
      `${
        config.apiUrl
      }/DbsInvoice/DownloadExcelTemplate?t=${RequestHandler.getToken()}`
    );
  };

  const downloadImportResult = (fileName) => {
    window.open(
      `${
        config.apiUrl
      }/DbsInvoice/DownloadImportResult?t=${RequestHandler.getToken()}&fileName=${fileName}`
    );
  };

  const uploadExcelProps = {
    name: "excel",
    action: `${config.apiUrl}/DbsInvoice/ImportExcel`,
    headers: {
      Authorization: "bearer " + RequestHandler.getToken(),
    },
    accept: ".xlsx",
    maxCount: 1,
    onChange(info) {
      setFileList(info.fileList);
      const { response, status } = info.file;

      if (status !== "error" && status !== "done") return;
      if (!response) return;

      const notificationType = response.success ? "success" : "error";

      notification[notificationType]({
        message: response.message,
        duration: 3,
      });
      if (!response.success) {
        downloadImportResult(info.file.response.data);
      }
      onSuccess();
    },
  };
  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="dbsInvoiceAdd"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="bankId" label="Banka" rules={[{ required: true }]}>
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              onChange={(value) => setActiveBankId(value)}
              loading={bankSelect.loading}
            >
              {bankSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="dbsDealerId"
            label="Bayi"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              disabled={!activeBankId}
              placeholder="Seçiniz"
              onSearch={onDealerSearch}
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0||
                option.code
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              loading={dealerSelect.loading}
            >
              {dealerSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id} code={item.code}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="currencyId"
            label="Para Birimi"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              disabled={!activeBankId}
              placeholder="Seçiniz"
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              loading={currencySelect.loading}
            >
              {currencySelect.data.map((item) => (
                <Select.Option key={item.currencyId} value={item.currencyId}>
                  {item.currencyCode}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Fatura Tarihi"
            name="invoiceDate"
            rules={[{ required: true }]}
          >
            <DatePicker format="DD.MM.YYYY" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Vade Tarihi"
            name="dueDate"
            rules={[{ required: true }]}
          >
            <DatePicker format="DD.MM.YYYY" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Fatura Numarası"
            name="invoiceNumber"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Fatura Tutarı"
            name="amount"
            rules={[{ required: true }]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item label="Açıklama" name="description">
            <Input.TextArea rows={3} maxLength={300} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <Button
            icon={<DownloadOutlined />}
            onClick={(() => setLoading(true), downloadExcelTemplate)}
            loading={loading}
          >
            Excel Şablonu İndir
          </Button>
          <Upload {...uploadExcelProps} fileList={fileList}>
            <Button icon={<UploadOutlined />}>Excel ile Ekle</Button>
          </Upload>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            htmlType="submit"
            onClick={() => setLoading(true)}
            loading={loading}
          >
            Ekle
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
export default DbsInvoiceAddForm;
