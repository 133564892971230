import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Select,
  Collapse,
  InputNumber,
  Typography,
  DatePicker
} from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { odata } from "./Helper";
import moment from "moment";

const FirmStatusReportTableFilter = (props) => {

  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });
  const [currencySelect, setCurrencySelect] = useState({
    data: [],
    loading: true,
  });
  const [form] = Form.useForm();

  useEffect(() => {
    getCurrencies();
  }, []);

  const getCurrencies = () => {
    setCurrencySelect({
      ...currencySelect,
      loading: true,
    });
    RequestHandler.get(`/Currency/GetFilterCurrency`).then((response) => {
      setCurrencySelect({
        data: response,
        loading: false,
      });
    });
  };

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onFirmSeach = (value) => {
    if (value.length >= 2) {
      setFirmSelect({ data: [], loading: true });

      RequestHandler.get(
        `/Firm?$select=id,firmBranchNumber,name&$filter=(${odata.contains("name", value)} or ${odata.contains("firmBranchNumber", value)}) and Name ne 'Diğer'`
      ).then((res) => {
        setFirmSelect({ data: res.value, loading: false });
      });
    } else {
      setFirmSelect({ data: [], loading: false });
    }
  };

  const onFinish = (values) => {
    {
      let query = {};

      if (values.dateRange) {
        const [startDate, endDate] = values.dateRange;
        query.date = startDate !== null ? startDate?.format("MM/DD/YYYY") : "";
        query.endDate = endDate !== null ? endDate?.format("MM/DD/YYYY") : "";
      }

      if (values.firmId) {
        query.firmId = values.firmId;
      }

      if (values.collectTotal2) {
        query.collectTotal2 = values.collectTotal2;
      }
      if (values.collectTotal) {
        query.collectTotal = values.collectTotal;
      }

      if (values.collectPlanTotal2) {
        query.collectPlanTotal2 = values.collectPlanTotal2;
      }
      if (values.collectPlanTotal) {
        query.collectPlanTotal = values.collectPlanTotal;
      }

      if (values.paymentTotal2) {
        query.paymentTotal2 = values.paymentTotal2;
      }
      if (values.paymentTotal) {
        query.paymentTotal = values.paymentTotal;
      }

      if (values.paymentPlanTotal2) {
        query.paymentPlanTotal2 = values.paymentPlanTotal2;
      }
      if (values.paymentPlanTotal) {
        query.paymentPlanTotal = values.paymentPlanTotal;
      }

      if (values.currencyCode) {
        query.currencyCode = values.currencyCode;
      }

      if (props.onFinishFilter) props.onFinishFilter(query);
    }
  };

  const clearFilter = () => {
    form.resetFields();

    if (props.onClearFilter) {
      props.onClearFilter();
    }
  };

  return (
    <Row id="filterArea" style={{ ...props.style }}>
      <Col span={24}>
        <Collapse>
          <Collapse.Panel
            header="Filtreleme için tıklayınız."
            extra={
              props.extraText ? (
                <Typography>
                  <Typography.Paragraph
                    style={{
                      marginBottom: 0,
                      fontSize: "80%",
                    }}
                    type="secondary"
                  >
                    {props.extraText}
                  </Typography.Paragraph>
                </Typography>
              ) : null
            }
          >
            <Form
              name="filter"
              form={form}
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row style={{ flexDirection: "row" }}>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="dateRange" label="Tarih :">
                    <DatePicker.RangePicker
                      allowClear
                      picker={"month"}
                      format={"MMMM YYYY"}
                      disabledDate={(current) => {
                        var d = new Date();
                        var n = d.getFullYear();
                        const start = moment(`${n - 2}-01-01`, "YYYY-MM-DD");
                        const end = moment(`${n + 3}-01-01`, "YYYY-MM-DD");
                        return current < start || current > end;
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="firmId" label="Cari :" >
                    <Select
                      allowClear
                      showSearch
                      placeholder="En az 2 harf giriniz.."
                      onSearch={onFirmSeach}
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                        option.code
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      loading={firmSelect.loading}
                    >
                      {firmSelect.data.map((item) => (
                        <Select.Option
                          key={item.id}
                          value={item.name}
                          code={item.firmBranchNumber}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label="Gerçekleşen Tahsilat :"
                  >
                    <Input.Group>
                      <Row gutter={1} style={{ flexDirection: "row" }}>
                        <Col span={12}>
                          <Form.Item name="collectTotal2">
                            <InputNumber style={{ width: "100%" }} precision={2} placeholder="Başlangıç" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="collectTotal">
                            <InputNumber style={{ width: "100%" }} precision={2} placeholder="Bitiş" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label="Tahsilat Planı :"
                  >
                    <Input.Group>
                      <Row gutter={1} style={{ flexDirection: "row" }}>
                        <Col span={12}>
                          <Form.Item name="collectPlanTotal2">
                            <InputNumber style={{ width: "100%" }} precision={2} placeholder="Başlangıç" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="collectPlanTotal">
                            <InputNumber style={{ width: "100%" }} precision={2} placeholder="Bitiş" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label="Gerçekleşen Ödeme :"
                  >
                    <Input.Group>
                      <Row gutter={1} style={{ flexDirection: "row" }}>
                        <Col span={12}>
                          <Form.Item name="paymentTotal2">
                            <InputNumber style={{ width: "100%" }} precision={2} placeholder="Başlangıç" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="paymentTotal">
                            <InputNumber style={{ width: "100%" }} precision={2} placeholder="Bitiş" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label="Ödeme Planı :"
                  >
                    <Input.Group>
                      <Row gutter={1} style={{ flexDirection: "row" }}>
                        <Col span={12}>
                          <Form.Item name="paymentPlanTotal2">
                            <InputNumber style={{ width: "100%" }} precision={2} placeholder="Başlangıç" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="paymentPlanTotal">
                            <InputNumber style={{ width: "100%" }} precision={2} placeholder="Bitiş" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="currencyCode" label="Para Birimi :">
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      loading={currencySelect.loading}
                    >
                      {currencySelect.data.map(({ text, value }) => (
                        <Select.Option key={text} value={text}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

              </Row>

              <Row style={{ flexDirection: "row" }}>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    danger
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={() => clearFilter()}
                  >
                    Sıfırla
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    icon={<SearchOutlined />}
                    style={{ color: "#40a9ff", borderColor: "#40a9ff" }}
                    htmlType="submit"
                  >
                    Filtrele
                  </Button>
                </Col>
              </Row>

            </Form>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};
export default FirmStatusReportTableFilter;
