import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import RequestHandler from "util/RequestHandler";

import EntityPermissionAddForm from "../Forms/EntityPermissionAddForm";
import EntityPermissionUpdateForm from "../Forms/EntityPermissionUpdateForm";

import { Table, Empty, Col, Row, Button, Drawer, Popconfirm, Tabs } from "antd";
import {
  BarsOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  RollbackOutlined,
} from "@ant-design/icons";

const EntityPermissions = (props) => {
  const [tabOptions, setTabOptions] = useState({
    data: [],
    activeKey: null,
    loading: true,
  });
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
  });
  const [addFormVisibility, setAddFormVisibility] = useState(false);
  const [updateFormVisibility, setUpdateFormVisibility] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({});
  const [formOptions, setFormOptions] = useState({
    corparisonTypes: [],
    permissibleProperties: [],
  });

  useEffect(() => getEntityTypes(), []);
  useEffect(() => getTableData(), [tableDataQueryOptions]);
  useEffect(() => {
    getTableData();
    getEntityPermissionOptions();
  }, [tabOptions.activeKey]);
  const history = useHistory();

  const getEntityTypes = () => {
    setTabOptions({
      ...tabOptions,
      loading: true,
    });
    RequestHandler.get("/EntityPermission/Types").then((response) => {
      setTabOptions({
        data: response,
        activeKey: response[0]?.key,
        loading: false,
      });
    });
  };

  const getTableData = () => {
    if (!tabOptions.activeKey) {
      return;
    }

    setTableData({
      ...tableData,
      loading: true,
    });

    const { skip, take } = tableDataQueryOptions;
    const { tenantUserId } = props.match.params;

    RequestHandler.get(
      `/EntityPermission?$count=true&$filter=entityType eq '${tabOptions.activeKey}' and tenantUserId eq ${tenantUserId} &$skip=${skip}&$top=${take}`
    ).then((response) => {
      setTableData({
        data: response.value,
        total: response["@odata.count"],
        loading: false,
      });
    });
  };

  const getEntityPermissionOptions = () => {
    if (!tabOptions.activeKey) {
      return;
    }

    RequestHandler.get(
      "/EntityPermission/Options/" + tabOptions.activeKey
    ).then((response) => {
      setFormOptions(response);
    });
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const handleOnClickUpdate = (record) => {
    RequestHandler.get(`/EntityPermission/GetById/${record.id}`).then(
      (response) => {
        setUpdateFormData(response);
        setUpdateFormVisibility(true);
      }
    );
  };

  const handleOnClickDelete = (id) => {
    RequestHandler.delete(`/EntityPermission/${id}`).then((response) => {
      if (response.success) {
        getTableData();
      }
    });
  };

  const onAddFromSuccess = () => {
    getTableData();
    setAddFormVisibility(false);
  };

  const onUpdateFormSuccess = () => {
    getTableData();
    setUpdateFormVisibility(false);
  };

  const onTabChange = (key) => {
    setTabOptions({
      ...tabOptions,
      activeKey: key,
    });
  };

  const columns = [
    {
      title: "Yetkilendirilen Alan",
      dataIndex: "propertyDisplayName",
    },
    {
      title: "Karşılaştırma Türü",
      dataIndex: "corparisonTypeName",
    },
    {
      title: "Karşılaştırılacak Değer",
      dataIndex: "valueDisplayName",
      render: (data, row) => (data ? data : row.value),
    },
    {
      title: "Düzenle",
      dataIndex: "id",
      align: "center",
      render: (data, row) => (
        <a onClick={() => handleOnClickUpdate(row)}>
          <EditOutlined style={{ color: "orange", fontSize: "130%" }} />
        </a>
      ),
    },
    {
      title: "Sil",
      dataIndex: "id",
      align: "center",
      render: (data, record) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleOnClickDelete(data)}
        >
          <a>
            <DeleteOutlined style={{ color: "red", fontSize: "130%" }} />
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Row style={{ marginBottom: 9 }}>
        <Col flex={1}>
          <a
            onClick={() => history.goBack()}
            style={{ color: "black", marginLeft: "4%" }}
          >
            {" "}
            <RollbackOutlined /> GERİ DÖN
          </a>
        </Col>
        <Col flex={4}></Col>
      </Row>
      <Row>
        <Col xs={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> İŞLEM YETKİLENDİRME
          </p>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={() => {
              setAddFormVisibility(true);
            }}
            style={{ marginBottom: 0 }}
          >
            <PlusOutlined /> Yetki Ekle
          </Button>
        </Col>
      </Row>

      <Tabs onChange={onTabChange}>
        {tabOptions.data.map((option) => {
          return (
            <Tabs.TabPane tab={option.value} key={option.key}></Tabs.TabPane>
          );
        })}
      </Tabs>

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Drawer
        id="addFormDrawer"
        title="Yetki Ekle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setAddFormVisibility(false)}
        visible={addFormVisibility}
      >
        <EntityPermissionAddForm
          data={{
            entityType: tabOptions.activeKey,
            tenantUserId: props.match.params.tenantUserId,
          }}
          options={formOptions}
          onSuccess={onAddFromSuccess}
        />
      </Drawer>

      <Drawer
        id="updateFormDrawer"
        title="Yetki Güncelle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setUpdateFormVisibility(false)}
        visible={updateFormVisibility}
      >
        <EntityPermissionUpdateForm
          options={formOptions}
          data={updateFormData}
          onSuccess={onUpdateFormSuccess}
        />
      </Drawer>
    </>
  );
};

export default EntityPermissions;
