import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Menu, Switch, Spin, Col, Row, Modal } from "antd";
import { Link, useHistory } from "react-router-dom";
import Replace2x from "../../util/Extensions/ReplaceExtension";

import getMenuItems from "util/MenuItems";
import RequestHandler from "util/RequestHandler";

const SubMenu = Menu.SubMenu;

const HorizontalNav = () => {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleSms, setIsModalVisibleSms] = useState(false);
  const [isModalNotificationsVisible, setIsModalNotificationsVisible] =
    useState(false);
  const [isLiveValue, setIsLiveValue] = useState(false);
  const [isSmsAccess, setIsSmsAccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const [permissions, setPermissions] = useState({
    loading: true,
    modules: [],
    isAdmin: false,
    isGroupAdmin: false,
    isGroupUser: false,
  });
  useEffect(() => {
    RequestHandler.get("/User/MenuPermissions").then((response) => {
      setPermissions({
        ...response,
        loading: false,
      });
      localStorage.setItem("defaultModuleId", response.defaultModuleId);
    });
  }, []);
  const showModal = () => {
    setLoading(true);
    RequestHandler.get("/TenantCurrency/GetIsDailyValue").then((response) => {
      setIsLiveValue(response.isLiveValue);
      setIsModalVisible(true);
    });
    setLoading(false);
  };
  const showModalSms = () => {
    setLoading(true);
    RequestHandler.get(
      "/Tenant/GetById/" + Replace2x(localStorage.getItem("tenantId"))
    ).then((response) => {
      setIsSmsAccess(response.isSmsAccess);
      setIsModalVisibleSms(true);
    });
    setLoading(false);
  };
  const showModalNotifications = () => {
    setLoading(true);
    setIsModalNotificationsVisible(true);
  };
  function onChange(checked) {
    RequestHandler.put(
      "/TenantCurrency/PutIsLiveValue",
      {
        isLiveValue: checked,
      },
      true
    ).then((res) => {
      setIsLiveValue(res.data.isLiveValue);
    });
    setTimeout(() => {
      setIsModalVisible(false);
      window.location.reload();
    }, 500);
  }
  function onChangeSms(checked) {
    RequestHandler.post(
      "/Tenant/AllTenantUpdateForSms",
      {
        UserId: Replace2x(localStorage.getItem("userId")),
        IsSmsAccess: checked,
      },
      true
    ).then((res) => {
      setIsSmsAccess(!isSmsAccess);
      window.location.reload();
    });
    setTimeout(() => {
      setIsModalVisible(false);
    }, 500);
  }
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOkSms = () => {
    setIsModalVisibleSms(false);
  };

  const handleCancelSms = () => {
    setIsModalVisibleSms(false);
  };

  const renderMenuItem = (menuItem) => {
    if (
      menuItem.isRender === false ||
      (menuItem.module && !permissions.modules.includes(menuItem.module))
    )
      return null;

    if (menuItem.children) {
      return (
        <SubMenu
          key={menuItem.key}
          title={
            <span>
              <i className={menuItem.icon} />
              <span>{menuItem.title}</span>
            </span>
          }
        >
          {menuItem.children.map((childItem) => renderMenuItem(childItem))}
        </SubMenu>
      );
    } else {
      switch (menuItem.key) {
        case "currency-live":
          return (
            <Menu.Item key={menuItem.key} onClick={() => showModal()}>
              <Link>
                <i className={menuItem.icon} />
                <span>{menuItem.title} </span>
              </Link>
            </Menu.Item>
          );
        case "password-access":
          return (
            <Menu.Item key={menuItem.key} onClick={() => showModalSms()}>
              <Link>
                <i className={menuItem.icon} />
                <span>{menuItem.title} </span>
              </Link>
            </Menu.Item>
          );
        default:
          return (
            <Menu.Item
              key={menuItem.key}
              onClick={() => history.push(menuItem.url)}
            >
              <Link to={menuItem.url} style={menuItem.style}>
                <i className={menuItem.icon} />
                <span>{menuItem.title}</span>
              </Link>
            </Menu.Item>
          );
      }
    }
  };
  const pathname = useSelector(({ settings }) => settings.pathname);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  const menuItems = getMenuItems(permissions);

  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal"
      style={{ backgroundColor: "transparent" }}
    >
      {permissions.loading
        ? null
        : menuItems.map((menuItem) => {
          return renderMenuItem(menuItem);
        })}
      <Modal
        title="Döviz Kurları"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Spin spinning={loading}>
          <Row>
            <Col span={2}></Col>
            <Col span={6}>Günlük Veri</Col>
            <Col span={2}>
              <Switch defaultChecked={isLiveValue} onChange={onChange} />
            </Col>
            <Col span={2}></Col>
            <Col span={6}>Anlık Veri </Col>
            <Col span={5}> </Col>
          </Row>
        </Spin>
      </Modal>
      <Modal
        title="Sms Onayı"
        visible={isModalVisibleSms}
        onOk={handleOkSms}
        onCancel={handleCancelSms}
        footer={false}
      >
        <Spin spinning={loading}>
          <Row>
            <Col span={2}></Col>
            <Col span={6}>Şifresiz Giriş</Col>
            <Col span={2}>
              <Switch defaultChecked={isSmsAccess} onChange={onChangeSms} />
            </Col>
            <Col span={2}></Col>
            <Col span={6}>Şifreli Giriş </Col>
            <Col span={5}> </Col>
          </Row>
        </Spin>
      </Modal>
    </Menu>
  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;
