import React, { useState, useEffect } from "react";
import { Empty, notification, Tooltip, Table } from "antd";
import "react-phone-input-2/lib/style.css";

const SubscriberUserMatchedRecords = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [SubscriberUsers, SetSubscriberUsers] = useState({
    data: props.Data,
    loading: false,
  });

  const onTableSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  useEffect(() => {
    if (selectedRowKeys.length != 0) {
      const selectedRowsData = SubscriberUsers.data.filter((row) =>
        selectedRowKeys.includes(row.id)
      );

      selectedRowsData.map((item) => {
        item.tenantSubscriberUserDto.isHirer === true
          ? props.HirerId(item.id)
          : props.HomeOwnerId(item.id);
      });
    } else {
      props.HirerId("");
      props.HomeOwnerId("");
    }

  }, [selectedRowKeys]);

  const Columns = [
    {
      title: "Ev Sahibi mi? - Kiracı mı?",
      dataIndex: "tenantSubscriberUserDto",
      key: "tenantSubscriberUserDto",
      align: "left",
      render: (data) => {
        return data.isHirer === true ? "Kiracı" : "Ev Sahibi";
      },
    },
    {
      title: "Adı Soyadı",
      dataIndex: "name",
      key: "name",
      align: "left",
      render: (text) => {
        return text != Empty ? text : "";
      },
    },
    {
      title: "Telefon Numarası",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: "left",
      render: (text) => {
        return text != Empty ? text : "";
      },
    },
    {
      title: "T.C. Kimlk Numarası",
      dataIndex: "tcNo",
      key: "tcNo",
      align: "left",
      render: (text) => {
        return text != Empty ? text : "";
      },
    },
    {
      title: "Vergi Numarası",
      dataIndex: "vkn",
      key: "vkn",
      align: "left",
      render: (text) => {
        return text != Empty ? text : "";
      },
    },
    {
      title: "E-Mail",
      dataIndex: "email",
      key: "email",
      align: "left",
      render: (text) => {
        return text != Empty ? text : "";
      },
    },
  ];

  return (
    <div>
      <p style={{fontWeight:"700"}}>
        Girdiğiniz bilgiler ile eşleşen kayıtlar bulunmaktadır. Eşleştirmek
        istediğiniz kayıtları seçip Tamam'a tıklayınız. Eşleştirmek
        istemiyorsanız Tamam'a tıklayıp devam edebilirsiniz.
      </p>
      <br/>
      <Table
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: onTableSelectChange,
        }}
        columns={Columns}
        dataSource={SubscriberUsers.data}
        loading={SubscriberUsers.loading}
        pagination={false}
        sortable={true}
        rowKey="id"
        size="small"
        bordered={false}
        showHeader={true}
      ></Table>
    </div>
  );
};

export default SubscriberUserMatchedRecords;
