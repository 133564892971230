import React from "react";

import { Row, Col } from "antd";
import PosReportValueTotal from "../HomeComponent/Pos/PosReportValueTotal";
import PosReportValueTotalCurrentMonth from "../HomeComponent/Pos/PosReportValueTotalCurrentMonth";
import PosReportValueTotalToday from "../HomeComponent/Pos/PosReportValueTotalToday";
import PosReportTransactionCountYesterday from "../HomeComponent/Pos/PosReportTransactionCountYesterday";

import PosReportValueReport from "../HomeComponent/Pos/PosReportValueReport";
import PosReportVolumeReport from "../HomeComponent/Pos/PosReportVolumeReport";

const PosReportHome = (props) => {
  return (
    <>
      <Row>
        <PosReportValueTotal />
        <PosReportValueTotalCurrentMonth />
        <PosReportValueTotalToday />
        <PosReportTransactionCountYesterday />
      </Row>
      <Row>
        <Col md={24} lg={12}>
          <PosReportValueReport />
        </Col>
        <Col md={24} lg={12}>
          <PosReportVolumeReport />
        </Col>
      </Row>
    </>
  );
};
export default PosReportHome;
