import React ,{useEffect,useState,useRef}from "react";
import {Button, message, Form,Row,Col,Input} from "antd";
import {Link} from "react-router-dom";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import LoginPic from '../assets/images/logoAdosoft.png'
import LoginPicImza from '../assets/images/imzaPosLogo.png'
import LoginPicBakiyem from '../assets/images/logoBakiyem.png'
import Screen from '../util/Extensions/Screens'

import {
  FETCH_ERROR,
  INIT_URL,
} from "../constants/ActionTypes";
import axios from 'util/Api'
import {notification,Modal} from 'antd'
import config from "util/ApiforContext"
import {useDispatch, useSelector} from "react-redux";
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
const FormItem = Form.Item;
const SignIn = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [smsValue, setSmsValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [mailLogin, setMailLogin] = useState("");
  const [psr, setPsr] = useState("");
  const [screenOpt, setScreenOpt] = useState("");
  const [expiration, setExpiration] = useState("");
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [smsPsrInput, setSmsPsrInput] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [tokenUser, setToken] = useState("");
  const [userName, setUserName] = useState("");
  const [smsPassword, setSmsPassword] = useState("");
  const {initialMinute = 0} = props;
  const [ minutes, setMinutes ] = useState(initialMinute);
  const [seconds, setSeconds ] =  useState(180);
  const [errorText, setErrorText ] =  useState("");
  useEffect(()=>{
    let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                  handleCancel()
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
          };
    });
  const [form] = Form.useForm();
  
  const showModal = () => {
    setSeconds(180)
    setSmsPsrInput("")
    setErrorText("")
    setVisible(true)
  }

  const handleSubmit = (values) => {
        axios.post(`${config.apiUrl}/Auth/Login`, 
            {
              email: mailLogin,
              password: psr,
              ScreenOption: Screen.screen,
              SecurityKey: values.Sms
            }
          ).then(({data}) => {
              const expiration = new Date(data.data.expiration).getTime();
              setExpiration(expiration)
              setUserId(data.data.userId)
              setEmail(data.data.email)
              setTenantId(data.data.tenantId)
              setToken(data.data.token)
              setUserName(data.data.userName)
                if (data.success) {
                    axios.defaults.headers.common['Authorization'] = "Bearer " + data.data.token;
                    axios.get(`${config.apiUrl}/Tenant/GetById/${data.data.tenantId}`,{headers: {"Authorization": "Bearer " + data.data.token}})
                        .then(res => {
                              openNotificationWithIcon("success");
                              window.location.reload()
                              const expiration = new Date(data.data.expiration).getTime();
                              localStorage.setItem("userId", JSON.stringify(data.data.userId));
                              localStorage.setItem("email", JSON.stringify(data.data.email));
                              localStorage.setItem("tenantId", JSON.stringify(data.data.tenantId));
                              localStorage.setItem("expiration", JSON.stringify(expiration));
                              localStorage.setItem("token", JSON.stringify(data.data.token));
                              localStorage.setItem("userName", JSON.stringify(data.data.userName));
                              axios.defaults.headers.common['Authorization'] = "Bearer " + data.data.token;
                           });
                } else {
                       dispatch({type: FETCH_ERROR, payload: data.message});
                }
              }).catch(function (error) {
                      if(error.response.status === 406){
                       error.response.data.data.map((item)=>{
                       errorList.push(item);
                      })
                      errorListNotification('error')
                      setLoadingBtn(false)
                      setLoading(false)
                      }else{
                      sameError('error')
                      setLoading(false)
                      setLoadingBtn(false)
                      }
          });
   
    setSmsValue(values.Sms)
  }
  
  const handleCancel = () => {
    if(handleCancel){
      setLoading(false)
    }
    setVisible(false)
    setLoadingBtn(false)
  };

  const sameError = type => {
    notification[type]({
      message: 'Hata',
      description:
        'Bir hata oluştu.',
        duration: 2,
    });
  };
  const errorList = [];
  const errorListNotification = type => {
    errorList.map((item)=> {
      notification[type]({
        message: 'Hata',
        description:
        item
         ,
          duration: 3,
      });
    })
  };
  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);
  const onFinishFailed = errorInfo => {
    setLoading(false)
  };
  const success = () => {
    const hide = message.loading('Giriş yapılıyor..', 0);
    setTimeout(hide, 5000);
  };
  const onFinish = values => {
    // axios.get(`${config.apiUrl}/Auth/PsrControl?email=${values.email}`).then(({data}) => {
    //     if(data === null || data === ""){
          axios.post(`${config.apiUrl}/Auth/Login`, 
          {
              Email: values.email,
              Password: values.password,
              ScreenOption: Screen.screen
          }
          ).then(({data}) => {
            if(data.data === null){
              showModal()
              setMailLogin(values.email)
              setPsr(values.password)
              setScreenOpt(Screen.screen)
            }else{
            const expiration = new Date(data.data.expiration).getTime();
              setExpiration(expiration) 
              setUserId(data.data.userId)
              setEmail(data.data.email)
              setTenantId(data.data.tenantId)
              setToken(data.data.token)
              setUserName(data.data.userName)
                if (data.success) {
                      axios.defaults.headers.common['Authorization'] = "Bearer " + data.data.token;
                      openNotificationWithIcon("success");
                      window.location.reload()
                      const expiration = new Date(data.data.expiration).getTime();
                      localStorage.setItem("userId", JSON.stringify(data.data.userId));
                      localStorage.setItem("email", JSON.stringify(data.data.email));
                      localStorage.setItem("tenantId", JSON.stringify(data.data.tenantId));
                      localStorage.setItem("expiration", JSON.stringify(expiration));
                      localStorage.setItem("token", JSON.stringify(data.data.token));
                      localStorage.setItem("userName", JSON.stringify(data.data.userName));
                  } else {
                        dispatch({type: FETCH_ERROR, payload: data.message});
                  }
            }                 
          }).catch(function (error) {
            if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
              setLoading(false)
              setLoadingBtn(false)
            }else{
              sameError('error')
              setLoading(false)
              setLoadingBtn(false)
            }
          });
        // }else{
        //   props.history.push(`/reset-password/${data}/true`);
        // }
    // }).catch(function (error) {
    //   if(error.response.status === 406){
    //     error.response.data.data.map((item)=>{
    //       errorList.push(item);
    //     })
    //     errorListNotification('error')
    //     setLoadingBtn(false)
    //     setLoading(false)
    //     }else{
    //         sameError('error')
    //         setLoading(false)
    //         setLoadingBtn(false)
    //     }
    //   });
  };
  const onChangePsrLoginValue = (e) => {
    setErrorText("")
    setSmsPsrInput(e.target.value)
   }
  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'Giriş işlemi başarılı.',
        duration: 2,
    });
  };
  const ref = useRef();
  useEffect(() => {
    if (token !== null) {
      props.history.push('/home');
    }
  }, [token, props.history]);

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-login-header gx-text-center">
          <h1 className="gx-login-title">
            {Screen.screen === 0 ? 
            <img src={LoginPic} alt='Paratic' style={{marginBottom: '11%',width: '64%' }} />
            :
            (
              Screen.screen === 1 ? 
              <img src={LoginPicImza} alt='Imzapos' style={{marginBottom: '11%',width: '64%' }} />
              :
              <img src={LoginPicBakiyem} alt='Bakiyem' style={{marginBottom: '11%',width: '64%' }} />
            )
            }
          
          </h1>
        </div>
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">
          <FormItem rules={[{ required: true, message: 'Email alanı zorunludur!' }]} name="email">

              <Input prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                     placeholder="Email"/>
          </FormItem>
          <FormItem rules= {[{required: true, message: 'Şifre alanı zorunludur!'}]}  name="password">

              <Input prefix={<LockOutlined  style={{color: 'rgba(0,0,0,.25)'}}/>}
                     type="password"
                     placeholder="Şifre"/>
          </FormItem>
          <Link className="gx-login-form-forgot" to="/forget-password" style={{color:'darkRed'}}>Şifremi Unuttum</Link>
          <FormItem className="gx-text-center">
          <Button onClick={success}   
            htmlType="submit" 
            style={{backgroundColor:'darkRed'}}  
            onClick={() => setLoading(true)} 
            loading={loading}>
             <span style={{color:"#fff"}}> Giriş Yap</span>
            </Button>
          </FormItem>
        </Form>
      </div>

      <Modal maskClosable={false} visible={visible} onOk={form.submit} okButtonProps={{hidden:true}} keyboard={false} cancelButtonProps={{hidden:true}} onCancel={handleCancel}>
      <p style={{marginTop:10,textAlign:'center',fontSize:16}}> Lütfen telefonunuza gelen şifreyi <b>{seconds}</b> saniye içinde girin; </p>
              
               <Form
                   onFinish={handleSubmit}
                   fields={[
                    {name:"Sms",value:smsPsrInput},
                  ]}
                  ref={ref}
                    >
                <Form.Item
                name="Sms"
                >
                <Input onChange={onChangePsrLoginValue} type='text' maxLength={6} 
                autoComplete="off"
                 style={{marginTop:40}} onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
                if (event.key === "Enter") {
                  ref.current.submit();
                }
                }} />
                </Form.Item>  
                <Row style={{marginBottom: -25}}>
               
                <Col span={15}></Col>
                <Col span={6}>
                <Form.Item  >
                <Button type="primary" htmlType="submit" onClick={() => setLoadingBtn(true)} loading={loadingBtn}>
                    Onayla
                </Button>
                </Form.Item>
                </Col>
                <Col span={3}></Col>
                </Row>
              </Form>
              <p style={{color:'darkred',textAlign:'center',fontWeight:600}}>{errorText}</p>
      </Modal>
    </div>
  );
};

export default SignIn;
