import React,{useState,useEffect} from "react";
import {Button,notification, Form, Input} from "antd";
import axios from 'util/Api'
import config from "../util/ApiforContext"
import Screen from '../util/Extensions/Screens'
const FormItem = Form.Item;

const ForgotPassword = (props) => {
  const [disabled, setDisabled] = useState(false);
  const onFinishFailed = errorInfo => {
  };
  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'Şifre yenileme linkiniz mail adresinize gönderilmiştir.',
        duration: 2,
    });
  };
  const openNotificationWithIconWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Şifre gönderimi sırasında bir hata oluştu.',
        duration: 2,
    });
  };
  const errorListNotification = type => {
    errorList.map((item)=> {
      notification[type]({
        message: 'Hata',
        description:
        item
         ,
          duration: 2,
      });
    })
  };
  const errorList = [];

  const onFinish = values => {
    setDisabled(true)
    axios.post(`${config.apiUrl}/Auth/ForgotPassword`, {
        Email: values.email,
        ScreenOption : Screen.screen
      }
    ).then(({data}) => {
      if (data.success) {
        openNotificationWithIcon('success');
        setTimeout(function () {
            props.history.push('/');
         }.bind(this), 2000)
      } 
    }).catch(function (error) {
      if(error.response.status === 406){
        error.response.data.data.map((item)=>{
          setDisabled(false)
          errorList.push(item);
        })
        errorListNotification('error')
       }else{
         openNotificationWithIconWarning('error')
         setDisabled(false)
      }
    });
  };

  return (
    <div className="gx-login-container" style={{backgroundColor:'#f0f8ff'}}>
      <div className="gx-login-content">
        <div className="gx-login-header">
          {
            Screen.screen === 0 ?
            <img src={require("assets/images/logoAdosoft.png")} alt="paratic" title="paratic" style={{width:'40%'}}/>
            :
            (
              Screen.screen === 1 ? 
              <img src={require("assets/images/imzaPosLogo.png")} alt="paratic" title="paratic" style={{width:'40%'}}/>
              :
              <img src={require("assets/images/logoBakiyem.png")} alt="paratic" title="bakiyem" style={{width:'40%'}}/>
            )
            
          }
          
        </div>
        <div className="gx-mb-4">
          <h3>Şifreni mi unuttun ?</h3>
          <p>Mail adresinizi yazdığnız takdirde şifrenizi yenilemeniz için bir link gönderilecektir.</p>
        </div>
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">
          <FormItem name="email" rules={[{ required: true, message: 'Lütfen mail adresinizi girin!' }]}>
              <Input className='gx-input-lineheight' type="email" placeholder="Email adresinizi girin"/>
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit" disabled={disabled}>
              GÖNDER
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
}

export default ForgotPassword
