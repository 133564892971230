import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Checkbox, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";
import ParaticPhoneInput from "./Helper/ParaticPhoneInput";
import { odata } from "../Filters/Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const B2bFirmUserUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.data) {
      props.data.phoneNumber = props.data.phoneNumber
        ? "90" + props.data.phoneNumber
        : "90";
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onFirmSeach = (value) => {
    if (value.length >= 2) {
      setFirmSelect({ ...firmSelect, loading: true });

      RequestHandler.get(
        `/Firm?$select=id,firmBranchNumber,name&$filter=${odata.contains(
          "name",
          value
        )} or ${odata.contains("firmBranchNumber", value)}`
      ).then((res) => {
        setFirmSelect({ data: res.value, loading: false });
      });
    } else {
      setFirmSelect({ data: [], loading: false });
    }
  };

  const onFinish = (values) => {
    values.phoneNumber = values.phoneNumber?.slice(2);
    RequestHandler.put(`/TenantFirmUser`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        form.resetFields();
        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="firmUserUpdate"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item label="Cari" name="firmId" rules={[{ required: true }]}>
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              onSearch={onFirmSeach}
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                option.code
                  ?.toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              loading={firmSelect.loading}
            >
              {props.data?.firmId && (
                <Select.Option
                  key={props.data.firmId}
                  value={props.data.firmId}
                  code={props.data.firmCode}
                >
                  {props.data.firmName}
                </Select.Option>
              )}
              {firmSelect.data
                .filter((x) => x.id != props.data?.firmId)
                .map((item) => (
                  <Select.Option
                    key={item.id}
                    value={item.id}
                    code={item.firmBranchNumber}
                  >
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="Ad Soyad" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Şifre" name="Password">
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <Form.Item label="Telefon Numarası" name="phoneNumber">
            <ParaticPhoneInput />
          </Form.Item>

          <Form.Item
            label="Ödeme Planı"
            name="paymentPlan"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            label="Tahsilat Planı"
            name="collectionPlan"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            label="Pos Tahsilatı"
            name="posCollection"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item label="Stoklar" name="stocks" valuePropName="checked">
            <Checkbox />
          </Form.Item>

          <Form.Item
            label="Dbs Faturalar"
            name="dbsInvoices"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            label="Fatura İskontolama"
            name="invoiceDiscount"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item label="Erp Rapor" name="erpReport" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            htmlType="submit"
            onClick={() => setLoading(true)}
            loading={loading}
          >
            Güncelle
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
export default B2bFirmUserUpdateForm;
