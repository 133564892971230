import React, { useState, useEffect } from 'react'
import { Form, DatePicker, Input, notification, Button, Select, Col, Row } from 'antd';
import CurrencyInput from 'react-currency-input';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { odata } from '../Filters/Helper';
import RequestHandler from '../../util/RequestHandler';

const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 11,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const { TextArea } = Input;
const MonthlyPlanUpdate = (props) => {

  const [loading, setLoading] = useState(false);
  const [OtherTransactionProcessType, setOtherTransactionProcessType] = useState(props.OtherTransactionProcessType);
  const [MonthlyPlanTypeId, setMonthlyPlanTypeId] = useState(props.MonthlyPlanTypeId);
  const [CurrencyCode, setCurrencyCode] = useState(props.CurrencyCode);
  const [Amount, setAmount] = useState(props.Amount);
  const [TransactionDate, setTransactionDate] = useState(props.TransactionDate);
  const [Description, setDescription] = useState(props.Description);
  const [changeAll, setChangeAll] = useState(props.changeAll);
  const [teminatDropdown, setteminatDropdown] = useState([]);
  const [currencyDropdown, setcurrencyDropdown] = useState([]);
  const [ManualTransactionTypeDropdown, setManualTransactionTypeDropdown] = useState([]);
  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });
  const [FirmId, setFirmId] = useState(props.FirmId);

  useEffect(() => {
    RequestHandler.get(`/Firm?$filter=id eq ${props.FirmId}`).then(response => {
      setFirmSelect({ loading: false, data: response.value })
    });

    setManualTransactionTypeDropdown(props.ManualTransactionTypeDropdown)
    setcurrencyDropdown(props.currencyDropdown)
    setteminatDropdown(props.teminatDropdown)
    setDescription(props.Description)
    setTransactionDate(props.TransactionDate)
    setChangeAll(props.changeAll)
    setAmount(props.Amount)
    setCurrencyCode(props.CurrencyCode)
    setMonthlyPlanTypeId(props.MonthlyPlanTypeId)
    setOtherTransactionProcessType(props.OtherTransactionProcessType)
    setFirmId(props.FirmId)

  },
    [setOtherTransactionProcessType, setChangeAll, setFirmSelect, setMonthlyPlanTypeId, setCurrencyCode, setAmount, setTransactionDate, setDescription, setFirmId, props.FirmId, props.changeAll, props.isRepeat, props.OtherTransactionProcessType, props.MonthlyPlanTypeId, props.CurrencyCode, props.Amount, props.TransactionDate, props.Description]
  );

  const onChangeAmount = (e) => {
    setAmount(e)
  }
  const onChangeFirmValue = (e) => {
    setFirmId(e)
  }
  const onChangeOtherTransactionProcessTypeValue = (e) => {
    setOtherTransactionProcessType(e)
  }
  const onChangeCurrencyValue = (e) => {
    setCurrencyCode(e)
  }
  const onChangeDateValue = (e) => {
    setTransactionDate(e)
  }
  const onChangeMonthlyPlanValue = (e) => {
    setMonthlyPlanTypeId(e)
  }
  const onChangeDescriptionValue = (e) => {
    setDescription(e.target.value)
  }

  const onFirmSearch = (value) => {
    if (value.length >= 2) {
      setFirmSelect({ data: [], loading: true });

      RequestHandler.get(
        `/Firm?$select=id,firmBranchNumber,name&$filter=${odata.contains(
          "name",
          value
        )} or ${odata.contains("firmBranchNumber", value)}`
      ).then((res) => {
        setFirmSelect({ data: res.value, loading: false });
      });
    } else {
      setFirmSelect({ data: [], loading: false });
    }
  };

  const onFinishUpdate = values => {
    var amountString = values.Amount.toString();
    if (amountString.includes(".")) {
      if (amountString.includes(",")) {

      } else {
        amountString = amountString.replace(".", ",")
      }
    }
    RequestHandler.put(`/MonthlyPlan`, {
      Id: props.monthlyPlanId,
      MonthlyPlanTypeId: values.MonthlyPlanTypeId,
      OtherTransactionProcessType: values.OtherTransactionProcessType,
      CurrencyId: values.CurrencyCode,
      Amount: 1,
      IsChangeAll: changeAll,
      AmountString: amountString,
      Description: values.Description,
      TransactionDate: values.TransactionDate.format('YYYY-MM-DD'),
      FirmId: values.FirmId

    }, false, { rawResponse: true }).then(res => {
      if (res.status == 200) {
        openNotificationWithIconUpdate('success');
        setTimeout(function () {
          props.sendDataForUpdate("success");
          setLoading(false)
        }.bind(this), 2000)
      }
    });
  };

  const onFinishFailedUpdate = () => setLoading(false);

  const openNotificationWithIconUpdate = type => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'Güncelleme işlemi başarı ile gerçekleşti.',
      duration: 2,
    });
  };


  const openNotificationWithIconWarningUpdate = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Güncelleme işlemi sırasında bir hata oluştu.',
      duration: 2,
    });
  };

  return (
    <div>
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinishUpdate}
        onFinishFailed={onFinishFailedUpdate}
        fields={[
          { name: "OtherTransactionProcessType", value: OtherTransactionProcessType },
          { name: "CurrencyCode", value: CurrencyCode },
          { name: "Amount", value: Amount },
          { name: "TransactionDate", value: moment(TransactionDate, dateFormat) },
          { name: "Description", value: Description },
          { name: "MonthlyPlanTypeId", value: MonthlyPlanTypeId },
          { name: "FirmId", value: FirmId }
        ]}
      >
        <Row>

          <Col span={24}>
            <Form.Item
              label="Ödeme/Tahsilat Tipi "
              style={{ marginTop: "4%" }}
              name="OtherTransactionProcessType"
              rules={[
                {
                  required: true,
                  message: 'Lütfen Ödeme/Tahsilat tipi alanını boş bırakmayın.',
                },
              ]}
            >
              <Select
                showSearch
                onChange={onChangeOtherTransactionProcessTypeValue}
                placeholder="Ödeme/Tahsilat tipi seçiniz"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
              >
                {teminatDropdown.map(item => (
                  <Option key={item.key}>{item.value}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Cari Adı/Ünvanı"
              name="FirmId"
              rules={[
                {
                  required: true,
                  message: 'Lütfen Cari Adı/Ünvanı tipi alanını boş bırakmayın.',
                },
              ]}
            >
              <Select
                showSearch
                onChange={onChangeFirmValue}
                placeholder="En az 2 harf giriniz.."
                onSearch={onFirmSearch}
                filterOption={(input, option) =>
                  option.children
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                  option.code
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
                loading={firmSelect.loading}
              >
                {firmSelect.data.map((item) => (
                  <Select.Option
                    key={item.id}
                    value={item.id}
                    code={item.firmBranchNumber}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Tutar"
              name="Amount"
              rules={[
                {
                  required: true,
                  message: 'Lütfen Tutar alanını boş bırakmayın.',
                },
              ]}
            >
              <CurrencyInput value={Amount} onChange={onChangeAmount} style={{ width: "100%" }} className="ant-input" decimalSeparator="," thousandSeparator="." />
            </Form.Item>
            <Form.Item
              label="Para Birimi"
              name="CurrencyCode"
              rules={[
                {
                  required: true,
                  message: 'Lütfen Para Birimi alanını boş bırakmayın.',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Para birimini seçiniz"
                optionFilterProp="children"
                onChange={onChangeCurrencyValue}
                filterOption={(input, option) =>
                  option.props.children
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
              >
                {currencyDropdown.map(item => (
                  <Option key={item.id}>{item.code + ' (' + item.symbol + ')'}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Tarih"
              name="TransactionDate"
              rules={[
                {
                  required: true,
                  message: 'Lütfen Tarih alanını boş bırakmayın.',
                },
              ]}
            >
              <DatePicker format={"DD-MM-YYYY"} disabled={changeAll} onChange={onChangeDateValue} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="İşlem Tipi"
              name="MonthlyPlanTypeId"
              rules={[
                {
                  required: true,
                  message: 'Lütfen İşlem Tipi alanını boş bırakmayın.',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="İşlem tipi seçiniz"
                optionFilterProp="children"
                onChange={onChangeMonthlyPlanValue}
                filterOption={(input, option) =>
                  option.props.children
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
              >
                {ManualTransactionTypeDropdown.map(item => (
                  <Option key={item.id}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Açıklama"
              name="Description"
            >
              <TextArea onChange={onChangeDescriptionValue} rows={4} />
            </Form.Item>
          </Col>
          <Col span={6}></Col>
          <Col span={6}></Col>
          <Col span={6}></Col>
          <Col span={6}>
            <Form.Item {...tailLayout} >
              <Button type="primary" icon={<PlusOutlined />} htmlType="submit" onClick={() => setLoading(true)} loading={loading}>
                Güncelle
              </Button>
            </Form.Item>
          </Col>



        </Row>
      </Form>
    </div>
  )
}
export default MonthlyPlanUpdate