import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Checkbox,
  Input,
  Button,
  Table,
  Drawer,
  Form,
  Popconfirm,
  Spin,
  Modal
} from "antd";
import {
  BarsOutlined,
  UserOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
  ProfileTwoTone,
  DownOutlined,
} from "@ant-design/icons";
import { useHistory } from 'react-router-dom';
import config from "util/ApiforContext"
import Replace2x from 'util/Extensions/ReplaceExtension'
import axios from "axios"
import NotificationUpdate from "../Forms/NotificationUpdate";
import { TimePicker, notification } from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import ContactList from "components/contact/ContactNotification";
import CurrencyInput from "react-currency-input";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { Transfer } from 'antd';
import RequestHandler from "util/RequestHandler";

const format = "HH:mm";

const Notifications = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const errorListNotification = type => {
    errorList.map((item)=> {
      notification[type]({
        message: 'Hata',
        description:
        item
         ,
          duration: 2,
      });
    })
  };
  const errorList = [];
  const history = useHistory();
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [checkboxDisabled, setCheckboxDisabled] = useState(false);
    const [spinLoading, setSpinLoading] = useState(false);
    const [targetKeysTransactionType, setTargetKeysTransactionType] = useState([]);  
    const [mockDataTransactionType, setMockDataTransactionType] = useState([]);
  const [visible, setVisible] = useState(true);
  const [upDisabled, setUpDisabled] = useState(true); 
  const [isFullAccountChecked, setIsFullAccountChecked] = useState(false);
  const [isFullTransactionTypeChecked, setIsFullTransactionTypeChecked] = useState(false);
  const [notificationUpdateVisibility, setNotificationUpdateVisibility] =
    useState(false);
  const [updateFormData, setUpdateFormData] = useState({});
  const [time, setTime] = useState("");
  const [mail, setMail] = useState("");
  const [notId, setNotId] = useState("");
  const [drawerState, setDrawerState] = useState(false);

  const [upMoney, setUpMoney] = useState("0,00");
  const [selectedList, setSelectedList] = useState({ data: [], loading: true });
  const [checkedList, setCheckedList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [bankListForTRY, setBankListForTRY] = useState([]);
  const [transactionTargetValue, setTransactionTargetValue] = useState([]);

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [isModaAccountlVisible, setIsModalAccountVisible] = useState(false);
  const [isModalTransactionVisible, setIsModalTransactionVisible] = useState(false);

  const columns = [
    {
      title: "Mail Adresi",
      dataIndex: "mail",
      key: "mail",
    },
    {
      title: "Saat",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Tutar",
      dataIndex: "upMoney",
      key: "upMoney",
    },
    {
      title: "Banka Bakiye",
      dataIndex: "balance",
      key: "balance",
      align: "center",
      render: (text, row, index) => {
        return row.balance === true ? (
          <span className="gx-text-green">
            <CheckOutlined />
          </span>
        ) : (
          <span className="gx-text-red">
            <CloseOutlined />
          </span>
        );
      },
    },
    {
      title: "Banka Hesap Bakiye",
      dataIndex: "accountBalance",
      key: "accountBalance",
      align: "center",
      render: (text, row, index) => {
        return row.accountBalance === true ? (
          <span className="gx-text-green">
            <CheckOutlined />
          </span>
        ) : (
          <span className="gx-text-red">
            <CloseOutlined />
          </span>
        );
      },
    },
    {
      title: "Hesaba Para Girişi",
      dataIndex: "moneyInflow",
      key: "moneyInflow",
      align: "center",
      render: (text, row, index) => {
        return row.moneyInflow === true ? (
          <span className="gx-text-green">
            <CheckOutlined />
          </span>
        ) : (
          <span className="gx-text-red">
            <CloseOutlined />
          </span>
        );
      },
    },
    {
      title: "Hesaptan Para Çıkışı",
      dataIndex: "moneyOut",
      key: "moneyOut",
      align: "center",
      render: (text, row, index) => {
        return row.moneyOut === true ? (
          <span className="gx-text-green">
            <CheckOutlined />
          </span>
        ) : (
          <span className="gx-text-red">
            <CloseOutlined />
          </span>
        );
      },
    },
    {
      title: "Konsolide",
      dataIndex: "consolidated",
      key: "consolidated",
      align: "center",
      render: (text, row, index) => {
        return row.consolidated === true ? (
          <span className="gx-text-green">
            <CheckOutlined />
          </span>
        ) : (
          <span className="gx-text-red">
            <CloseOutlined />
          </span>
        );
      },
    },
    ,
    {
      title: "Hesaplar",
      dataIndex: "isFullTenantAccount",
      key: "isFullTenantAccount",
      align: "center",
      render: (text, row, index) => {
        return <a onClick={() => handleShowAccounts(row)} className="gx-text-green">
            <ProfileTwoTone style={{fontSize:18}} />
          </a>
      },
    },
    {
      title: "İşlem Tipleri",
      dataIndex: "isFullTransactionType",
      key: "isFullTransactionType",
      align: "center",
      render: (text, row, index) => {
        return <a onClick={() => handleShowTransactionType(row)} className="gx-text-green">
            <ProfileTwoTone style={{fontSize:18}}/>
          </a>
      },
    },
    {
      title: "Düzenle",
      dataIndex: "id",
      align: "center",
      render: (data, row) => (
        <a onClick={() => handleOnClickUpdate(row)}>
          <EditOutlined style={{ color: "orange", fontSize: "130%" }} />
        </a>
      ),
    },
    {
      title: "Sil",
      dataIndex: "operation",
      width: "1%",
      render: (text, record) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleDelete(record.id)}
        >
          <a style={{ color: "red", fontSize: "130%" }}>
            <DeleteOutlined />
          </a>
        </Popconfirm>
      ),
    },
  ];
  const openNotificationWithIconWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Hesap yetkilendirme işlemi sırasında bir hata oluştu.',
        duration: 2,
    });
};
const onTransactionTypeSelect = (value) => {
  axios.post(`${config.apiUrl}/notification/AddFullNotificationAuth`,{
        Id:notId,
        IsForTransactionType:true
      },config.headers)
      .then(res => {
        if(res.status === 200){
          setIsFullTransactionTypeChecked(res.data.isFullTransactionType);
        }
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }else if(error.response.status === 406){
            error.response.data.data.map((item)=>{
              errorList.push(item);
            })
            errorListNotification('error')
          }
        }
    );
};
  const onContactSelect = (value) => {
        axios.post(`${config.apiUrl}/notification/AddFullNotificationAuth`,{
              Id:notId,
              IsForTransactionType:false
            },config.headers)
            .then(res => {
              if(res.status === 200){
                setIsFullAccountChecked(res.data.isFullTenantAccount);
              }
            }).catch(
              error =>  {
                if(error.response.status===403 || error.response.status ===401){
                  history.push('/not-authorized-access');
                }else if(error.response.status === 406){
                  error.response.data.data.map((item)=>{
                    errorList.push(item);
                  })
                  errorListNotification('error')
                }
              }
          );
    };
  const getComp = (val) => {
    axios.post(`${config.apiUrl}/tenantaccount/GetWithTenantAccountUser`,{
      TenantId: Replace2x(localStorage.getItem("tenantId")),
      BankId:val,
       NotificationId:notId,
      IsNotificationValue:"true"
    },config.headers)
    .then(res => {
      setContactList(res.data)
    }).catch(
      error =>  {
        if(error.response.status===403 || error.response.status ===401){
          history.push('/not-authorized-access');
        }else if(error.response.status === 406){
          error.response.data.data.map((item)=>{
            errorList.push(item);
          })
          errorListNotification('error')
        }
      }
  );
  }
  const getMockTransactionType = (value,selectedList) => {
    const targetKeysTransaction = [];
    const mockDataTransaction = [];
    selectedList.map((item)=>{
      targetKeysTransaction.push(item.group)
    })
    
    value.map((item,index)=>{
      const data = {
        key: item.value,
        title: item.text,
        description: ``,
      };
      if (data.chosen) {
        targetKeysTransaction.push(data.key);
      }
      mockDataTransaction.push(data);
    })
    setMockDataTransactionType(mockDataTransaction)
    setTargetKeysTransactionType(targetKeysTransaction)
  };
  const onToggleDrawer = () => {
    setDrawerState(true)
}
const onToggleDrawerClose = () => {
    setDrawerState(false)
}
  const handleDelete = (key) => {
    RequestHandler.delete(`/notification?id=${key}`).then((response) => {
      reList();
    });
  };
  const handleOnClickUpdate = (record) => {
    RequestHandler.get(`/notification/getbyid/${record.id}`).then(
      (response) => {
        setUpdateFormData(response);
        setNotificationUpdateVisibility(true);
      }
    );
  };
  const handleShowAccounts = (record) => {
    setContactList([])
    axios.get(`${config.apiUrl}/Notification/GetById/${record.id}`,config.headers)
    .then(resp => {
      setIsFullAccountChecked(resp.data.isFullTenantAccount)
      setIsModalAccountVisible(true)
    }).catch(
      error =>  {
        if(error.response.status===403 || error.response.status ===401){
          history.push('/not-authorized-access');
        }else if(error.response.status === 406){
          error.response.data.data.map((item)=>{
            errorList.push(item);
          })
          errorListNotification('error')
          setLoading(false)
        }
      }
    );
        setNotId(record.id);
    
  };
  const handleShowTransactionType = (record) => {
    axios.get(`${config.apiUrl}/Notification/GetById/${record.id}`,config.headers)
    .then(resp => {
      setIsFullTransactionTypeChecked(resp.data.isFullTransactionType)
    setIsModalTransactionVisible(true)
    }).catch(
      error =>  {
        if(error.response.status===403 || error.response.status ===401){
          history.push('/not-authorized-access');
        }else if(error.response.status === 406){
          error.response.data.data.map((item)=>{
            errorList.push(item);
          })
          errorListNotification('error')
          setLoading(false)
        }
      }
    );

      setNotId(record.id);

    axios.get(`${config.apiUrl}/Notification/GetTransactionTypes`,config.headers)
      .then(res => {
        axios.get(`${config.apiUrl}/Notification/GetTransactionTypesById/${record.id}`,config.headers)
        .then(resp => {
          getMockTransactionType(res.data,resp.data);
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }else if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
              setLoading(false)
            }
          }
      );
         
         const transactionTypeList=[];
         res.data.map((item)=>{
           transactionTypeList.push(item.value)
         })
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }else if(error.response.status === 406){
            error.response.data.data.map((item)=>{
              errorList.push(item);
            })
            errorListNotification('error')
            setLoading(false)
          }
        }
    );
  };
  const handleAccountCancel = () => {
    setIsModalAccountVisible(false)
  }
  const handleAccountOk = () => {
    setIsModalAccountVisible(false)
  }
  const handleTransactionCancel = () => {
    setIsModalTransactionVisible(false)
  }
  const handleTransactionOk = () => {
    setIsModalTransactionVisible(false)
  }

  const reList = () => {
    setSelectedList({ ...selectedList, loading: true });

    RequestHandler.get(
      `/Notification?$count=true&$skip=${skip}&$top=${take}`
    ).then((response) => {
      setSelectedList({ data: response.value, loading: false });
    });
  };
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Uyarı",
      description: "İşleminiz başarı ile gerçekleştirildi.",
    });
  };
  const timeError = (type) => {
    notification[type]({
      message: "Uyarı",
      description: "Lütfen saati tam veya buçuk olacak şekilde seçiniz.",
    });
  };
  const setSubmit = () => {
    setLoading(true);
    if (
      (time === "" || time === undefined) &&
      (checkedList.includes("balance") ||
        checkedList.includes("accountBalance") ||
        checkedList.includes("consolidated"))
    ) {
      timeError("warning");
      setLoading(false);
      return;
    }
    RequestHandler.post("/Notification", {
      Mail: mail,
      Time: time,
      UpMoney: upMoney,
      Balance: checkedList.includes("balance") ? true : false,
      AccountBalance: checkedList.includes("accountBalance") ? true : false,
      MoneyInflow: checkedList.includes("moneyInflow") ? true : false,
      MoneyOut: checkedList.includes("moneyOut") ? true : false,
      Consolidated: checkedList.includes("consolidated") ? true : false,
      IsFullTenantAccount:true,
      IsFullTransactionType:true
    }).then((response) => {
      if (response.statusCode === 200 || response.statusCode === 204) {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        setLoading(false);
      }
    });
  };

  const onChangeTime = (time) => {
    if (
      moment(time?.toString()).format("LT").split(":")[1] !== "00" &&
      moment(time?.toString()).format("LT").split(":")[1] !== "30"
    ) {
      if (time !== null) {
        timeError("warning");
      }
      return;
    }

    setTime(moment(time?.toString()).format("LT"));
  };
  const onChangeMail = (e) => {
    setMail(e.target.value);
  };
  const onChangeUpMoney = (e) => {
    setUpMoney(e);
  };
  useEffect(() => {
    setSelectedList({ ...selectedList, loading: true });
    RequestHandler.get(
      `/Notification?$count=true&$skip=${skip}&$top=${take}`
    ).then((response) => {
      setSelectedList({ data: response.value, loading: false });
    });
    axios.get(`${config.apiUrl}/tenantaccount?$apply=groupby((BankLogo,BankId,BankCode),aggregate(Balance with sum as Balance))`,config.headers)
        .then(res => {
            setBankListForTRY(res.data)
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }else if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
            }
          }
      );
      
      
  }, [setSelectedList, skip, setSkip, take]);

  useEffect(() => {}, [visible, setVisible]);
  const getData = (val) => {
    if (val === "success") {
      reList();
      setNotificationUpdateVisibility(false);
    }
  };
  function onChangeNotifications(checkedValues) {
    if (checkedValues.length === 0) {
      setVisible(true);
      setUpDisabled(true);
    } else {
      if (
        checkedValues.includes("moneyInflow") ||
        checkedValues.includes("moneyOut")
      ) {
        setUpDisabled(false);
      } else {
        setUpDisabled(true);
      }
      setVisible(false);
      setCheckedList(checkedValues);
    }
  }
  const filterOptionTransaction = (inputValue, option) => option.description.indexOf(inputValue) > -1;

  const handleChangeTransaction = targetKeysTransaction => {
      setTargetKeysTransactionType(targetKeysTransaction)
      axios.post(`${config.apiUrl}/Notification/AddTransactionType`,{
        Group:targetKeysTransaction,
        NotificationId:notId
      },config.headers)
      .then(res => {
        openNotificationWithIcon("success")
        }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }else if(error.response.status === 406){
            error.response.data.data.map((item)=>{
              errorList.push(item);
            })
            errorListNotification('error')
            setLoading(false)
          }
        }
      );
  };

  const handleSearchTransaction = (dir, value) => {
  };
  const goBankAccount=(value)=>{
    setSpinLoading(true)
    axios.post(`${config.apiUrl}/tenantaccount/GetWithTenantAccountUser`,{
      TenantId: Replace2x(localStorage.getItem("tenantId")),
      BankId:value,
       NotificationId:notId,
      IsNotificationValue:"true"
    },config.headers)
    .then(res => {
      setContactList(res.data)
      setSpinLoading(false)
    }).catch(
      error =>  {
        if(error.response.status===403 || error.response.status ===401){
          history.push('/not-authorized-access');
          setSpinLoading(false)
        }else if(error.response.status === 406){
          error.response.data.data.map((item)=>{
            errorList.push(item);
          })
          errorListNotification('error')
          setSpinLoading(false)
        }
      }
  );
  }
  const ContactSideBar = () => {
    return <div style={disabled ? {pointerEvents: "none", opacity: "0.4"} : {}} className="gx-module-side">
      <div className="gx-module-side-header">
        <div className="gx-module-logo">
          <span>Bildirim Hesap Yetkileri </span>
        </div>
      </div>
      <div className="gx-module-side-content">
        <CustomScrollbars className="gx-module-side-scroll">
          <div className="gx-module-add-task">
          </div>
          <div className="gx-module-side-nav">
            <ul className="gx-module-nav">
              {bankListForTRY.map(option => 
              <li key={option.bankId} className="gx-nav-item" onClick={() =>goBankAccount(option.bankId)}>
                  <span
                    className={`gx-link`} >
                    <img style={{width:'80%'}} src={option.bankLogo}  ></img>
                  </span>
                </li>
              )}

            </ul>
          </div>
        </CustomScrollbars>
      </div>
    </div>

  };
  return (
    <>
      <Row>
        <Col xs={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> BİLDİRİM
          </p>
        </Col>
        <Col xs={8} style={{ textAlign: "right" }}></Col>
      </Row>

      <Checkbox.Group
        style={{ width: "100%" }}
        onChange={onChangeNotifications}
      >
        <Row
          style={
            isTabletOrMobileDevice ? null : { width: "70%", marginLeft: "20%" }
          }
        >
          <Col md={12} xs={24}>
            <Checkbox value="balance">
              Banka Bakiyelerinin mail ile gönderilmesi{" "}
            </Checkbox>
          </Col>

          <Col md={12} xs={24}>
            <Checkbox value="accountBalance">
              Banka Hesap Bakiyelerinin mail ile gönderilmesi{" "}
            </Checkbox>
          </Col>

          <Col md={12} xs={24}>
            <Checkbox value="consolidated">Konsolide </Checkbox>
          </Col>

          <Col md={12} xs={24}>
            <Checkbox value="moneyInflow">
              Hesaplara para girişinde bilgi verilmesi
            </Checkbox>
          </Col>

          <Col md={12} xs={24}>
            <Checkbox value="moneyOut">
              Hesaplardan para çıkışında bilgi verilmesi
            </Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>

      <Row style={{ marginTop: "4%" }}>
        <Col md={6} xs={24} hidden={visible === true ? true : false}></Col>
        <Col md={6} xs={12} hidden={visible === true ? true : false}>
          <Input
            style={{ width: "100%" }}
            placeholder="E-mail Adresi"
            value={mail}
            onChange={onChangeMail}
            prefix={<UserOutlined />}
          />
        </Col>
        <Col md={3} xs={12} hidden={visible === true ? true : false}>
          <TimePicker
            disabled={
              upDisabled === false
                ? checkedList.includes("consolidated")
                  ? false
                  : checkedList.includes("balance")
                  ? false
                  : checkedList.includes("accountBalance")
                  ? false
                  : true
                : false
            }
            placeholder={"Saat Seçin"}
            minuteStep={30}
            format={format}
            onChange={onChangeTime}
          />
        </Col>
        <Col md={3} xs={12} hidden={visible === true ? true : false}></Col>
        <Col md={6} xs={24} hidden={visible === true ? true : false}></Col>
      </Row>
      <Row style={{ marginTop: "1%" }}>
        <Col md={6} xs={24} hidden={visible === true ? true : false}></Col>
        <Col md={6} xs={12} hidden={visible === true ? true : false}>
          <CurrencyInput
            className="ant-input"
            style={{ width: "100%" }}
            disabled={upDisabled}
            placeholder="Tutardan fazlası"
            value={upMoney}
            onChange={onChangeUpMoney}
            decimalSeparator=","
            thousandSeparator="."
          />
        </Col>
        <Col md={6} xs={12} hidden={visible === true ? true : false}>
          <Button
            style={{ width: 137 }}
            type="primary"
            loading={loading}
            onClick={() => setSubmit()}
          >
            Ekle
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={2}></Col>
        <Col md={20}>
          <Table
            style={{ overflowX: "auto" }}
            dataSource={selectedList.data}
            loading={selectedList.loading}
            columns={columns}
          />
        </Col>
        <Col md={2}></Col>
      </Row>

      <Drawer
        id="notificationUpdate"
        title="Bildirim Düzenle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setNotificationUpdateVisibility(false)}
        visible={notificationUpdateVisibility}
      >
        <NotificationUpdate data={updateFormData} sendDataForUpdate={getData} />
      </Drawer>


      <Modal title={""}
      footer={false}
      width={1300}
      visible={isModaAccountlVisible} 
      onOk={handleAccountCancel} 
      onCancel={handleAccountOk}>
      <Spin spinning={spinLoading} style={{marginLeft:100,marginTop:200}}>
          <div className="gx-main-content">
            <div className="gx-app-module">
                <div className="gx-d-block gx-d-lg-none">
                    <Drawer
                      placement="left"
                      width={100}
                      closable={false}
                      visible={drawerState}
                      onClose={onToggleDrawerClose}>
                      {ContactSideBar()}
                    </Drawer>
                  </div>
                  <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
                    {ContactSideBar()}
                  </div>
                  <div className="gx-module-box">
                    <div className="gx-module-box-header">
                    <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                          <i className="icon icon-menu gx-icon-btn" aria-label="Menu"
                            onClick={onToggleDrawer}/>
                      </span>
                    </div>
                    <div className="gx-module-box-content">

                      <div className="gx-module-box-topbar">
                      <Checkbox className="gx-icon-btn"
                            checked={isFullAccountChecked}
                            value="checkedF"
                            disabled = {checkboxDisabled}
                            onClick={() => onContactSelect()}/> <p>Tüm Yetkiler</p> 
                      </div>
                      <CustomScrollbars className="gx-module-content-scroll">
                        {contactList.length === 0 ?
                          <div className="gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center">
                            Lütfen bir banka seçiniz
                          </div>
                          : <ContactList disabledPage={isFullAccountChecked} getDataComp={getComp}  userId={notId} contactList={contactList}/>
                        }
                      </CustomScrollbars>

                    </div>
                  </div>
            </div>
            
          </div>
      </Spin>
      </Modal>



      <Modal title={""}
      footer={false}
      visible={isModalTransactionVisible} 
      onOk={handleTransactionCancel} 
      onCancel={handleTransactionOk}>
      <div>
        
      <Form.Item
        label=""
        name="users"
      >
          <div className="gx-module-box-topbar">
              <Checkbox className="gx-icon-btn"
                    checked={isFullTransactionTypeChecked}
                    value="checkedF"
                    disabled = {checkboxDisabled}
                    onClick={() => onTransactionTypeSelect()}/> <p>Tüm Yetkiler</p> 
              </div>
         <Transfer
            dataSource={mockDataTransactionType}
            showSearch
            disabled={isFullTransactionTypeChecked}
            locale={
              { searchPlaceholder: "Arama Yapınız",
              notFoundContent: 'Liste Boş',
              itemUnit:'İşlem Tipi',
              itemsUnit:'İşlem Tipi',
              selectAll:'Hepsini Seç',
              selectInvert:'Seçimleri Kaldır' }
            }
            listStyle={{
              width: 300,
              height: 480,
            }}
            titles={['(-)','(+)']}
             filterOption={filterOptionTransaction}
             targetKeys={targetKeysTransactionType}
             onChange={handleChangeTransaction}
             onSearch={handleSearchTransaction}
            render={item => item.title}
          />
      </Form.Item>
        
      </div>
      </Modal>
    </>
  );
};
export default Notifications;
