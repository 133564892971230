import React, { useEffect,useState } from 'react'
import {Table,Empty,Card, notification,Col,message,Row,Select,Tabs,Modal,Spin  } from "antd";
import axios from "axios"
import {Link} from 'react-router-dom'
import CurrencyFormat from 'react-currency-format';
import {Cell, Pie, PieChart, Tooltip} from "recharts";
import { FileExcelOutlined,BarsOutlined,CaretRightFilled,MenuUnfoldOutlined } from '@ant-design/icons';
import config from "../../util/ApiforContext"
import { object } from 'prop-types';
import ReplaceForI from "../../util/Extensions/IReplaceExtension"
import moment from 'moment';
import { useMediaQuery } from 'react-responsive'
import { Collapse,Switch } from 'antd';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ConsolidatedTenant from './ConsolidatedTenant'
import Replace2x from '../../util/Extensions/ReplaceExtension'
import {useHistory} from "react-router-dom";
import Screen from 'util/Extensions/Screens'
const { Panel } = Collapse;

const expiration = localStorage.getItem("expiration");
const COLORS = ['#003668', '#0061ba', '#1f94ff', '#8dbbe5','#8de5cd','#00b684','#006448'];
const RADIAN = Math.PI / 180;   
const renderCustomizedLabelTRY = ({ x, y,stroke, value }) => {
 return (
  "% " + value.toFixed(2)
 );
};
const CustomToolTip = props => {
  try {
    const { active, payload, label } = props;
    if (!active || !payload) {
      return null;
    }
    return (
      <div
      >
        <p>
          <strong>{label}</strong>
        </p>
        {payload.map((item, i) => (
          <p key={i}>
            <strong style={{backgroundColor:"white",opacity:"0.6"}}>{item.name}</strong>
          </p>
        ))}
      </div>
    );
    
  } catch (error) {
  }
  
};

const Consolidated = () => {
  const token = localStorage.getItem("token");
const { Option } = Select;

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isChartWidth = useMediaQuery({ query: '(min-width: 500px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const history = useHistory();
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [bankListForTRY, setBankListForTRY] = useState([]);
  const [tenantIdList, setTenantIdList] = useState([]);
  const [yesterday, setYesterday] = useState("");

  const [bankListForTotal, setBankListForTotal] = useState([]);
  const [exchangeRate, setExchangeRate] = useState([]);
  const [groups, setGroups] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyTenantList, setCurrencyTenantList] = useState([]);
  const [chartTakeValuesStateTotal, setChartTakeValuesStateTotal] = useState([]);
  const [chartTakeValuesState, setChartTakeValuesState] = useState([]);
  const [totalTenantAccountList, setTotalTenantAccountList] = useState([]);
  const [totalTenantAccountTenantList, setTotalTenantAccountTenantList] = useState([]);
  const [isLiveValue, setIsLiveValue] = useState(false);
  const [dailyDate, setDailyDate] = useState("");
  const [liveDate, setLiveDate] = useState("");
  const [totalWithCurrency, setTotalWithCurrency] = useState([]);
  const [totalWithCurrencyTenant, setTotalWithCurrencyTenant] = useState([]);
  const [firstGroups, setFirstGroups] = useState("");
  const [firstName, setFirstName] = useState("");
  const [activeKey, setActiveKey] = useState("1");
  const [allTotal, setAllTotal] = useState(0);
  const [allTotalTenant, setAllTotalTenant] = useState(0);
  const [bankId, setBankId] = useState(0);
  const [bankConsolideTenant, setBankConsolideTenant] = useState([]);
  const [switchTenant, setSwitchTenant] = useState(false);
  const [isModalVisibleBank, setIsModalVisibleBank] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingTenant, setLoadingTenant] = useState(true);
  const chartTakeValues=[];
  var bankListConsolide = [];
  var bankListConsolideTotal = [];
  var totalListConsolide = 0;
  var totalListConsolideTotal = 0;
  const errorList = [];
  const emailError = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Mail formatı hatalı.',
        duration: 2,
    });
  };
  const openNotificationWithIconExcel = type => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'Excel indirme işlemi başarı ile gerçekleşti.',
        duration: 2,
    });
  };
  const openNotificationWithIconWarningExcel = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Excel indirme işlemi sırasında bir hata oluştu.',
        duration: 2,
    });
  };
  const errorListNotification = type => {
    errorList.map((item)=> {
      notification[type]({
        message: 'Hata',
        description:
        item
         ,
          duration: 2,
      });
    })
  };
var bankAndTotalList = [];

  useEffect(() => {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    today.toDateString()
    setYesterday(yesterday)
    yesterday.toDateString()
    if(expiration<Date.now()){
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if(localStorage.getItem("token")==null){
      window.location.reload()
    }
    axios.get(`${config.apiUrl}/Group/GetConsolidateDropdown`,config.headers)
    .then(res => {
      onChange(res.data[0]?.id)
      setFirstName(res.data[0]?.name)
      setFirstGroups(res.data[0]?.id)
        setGroups(res.data)
    }).catch(
      error =>  {
        if(error.response.status===403 || error.response.status ===401){
          history.push('/not-authorized-access');
        }else if(error.response.status === 406){
          error.response.data.data.map((item)=>{
            errorList.push(item);
          })
          errorListNotification('error')
        }
      }
  );
  axios.get(`${config.apiUrl}/TenantCurrency/GetExchangeRateList`,config.headers)
  .then(res => {
      axios.get(`${config.apiUrl}/TenantCurrency/GetIsDailyValue`,config.headers)
      .then(ress => {
        setIsLiveValue(ress.data.isLiveValue)
          }).catch(
            error =>  {
              if(error.response.status===403 || error.response.status ===401){
                history.push('/not-authorized-access');
              }else if(error.response.status === 406){
                error.response.data.data.map((item)=>{
                  errorList.push(item);
                })
                errorListNotification('error')
              }
            }
        );
        setLiveDate(res.data[0].liveDate)
        setDailyDate(res.data[0].dailyDate)
    setExchangeRate(res.data)
  }).catch(
    error =>  {
      if(error.response.status===403 || error.response.status ===401){
        history.push('/not-authorized-access');
      }else if(error.response.status === 406){
        error.response.data.data.map((item)=>{
          errorList.push(item);
        })
        errorListNotification('error')
      }
    }
);
  axios.get(`${config.apiUrl}/TenantCurrency/GetExchangeRateList`,config.headers)
  .then(res => {
    setExchangeRate(res.data)
  }).catch(
    error =>  {
      if(error.response.status===403 || error.response.status ===401){
        history.push('/not-authorized-access');
      }else if(error.response.status === 406){
        error.response.data.data.map((item)=>{
          errorList.push(item);
        })
        errorListNotification('error')
      }
    }
);
axios.post(`${config.apiUrl}/Consolidated/GetListForTotalConsolidated`,{
  TenantIdList: tenantIdList
},config.headers)
.then(res => {
  var chartValuesTRY = [];
  res.data.map((item)=> {
    var model={};
    model.name= item.bankName;
    model.value = item.balance;
    chartValuesTRY.push(model)
  })
  var total=0;
  var valueTotal = 0;
  var currency ="";
  try {
    {chartValuesTRY.map((value, index) => {
    if(value.value <0){
      value.value= 0
    }
    total+=value.value;
    })}

    {chartValuesTRY.map((value, index) => {
      var percents = ((value.value/total)*100).toFixed(2);
      var percentsFloat =parseFloat(percents);

      if(percentsFloat <0){
        percentsFloat = 0;
      }
      value.value=percentsFloat;

      if(value.value < 10){
      valueTotal += value.value;
      }else{
        currency=value.Currency;
        chartTakeValues.push(value)
      }
      })}
      if(valueTotal != 0){
        var model ={};
        model.name="DİĞER";
        model.value=valueTotal;
        chartTakeValues.push(model)
      }
      setChartTakeValuesStateTotal(chartTakeValues)
      
  } catch (error) {
    if(error.response.status===403 || error.response.status ===401){
      history.push('/not-authorized-access');
    }
  }
}).catch(
  error =>  {
    if(error.response.status===403 || error.response.status ===401){
      history.push('/not-authorized-access');
    }
  }
);
  },[setLoading,setGroups,loading,setCurrencyList,setActiveKey,setFirstName]);
  let hide = () => {};
  const exportExcel = () => {
    const hide = message.loading('Excel indiriliyor...', 0);
    
    axios.post(`${config.apiUrl}/Consolidated/exportexcel`,
    {TenantIdList:tenantIdList,
    ScreenOption : Screen.screenGercek},
    config.headers)
    .then(res => {
      if(res.status==200){
        openNotificationWithIconExcel('success');
        window.open(`${config.apiUrl}/Consolidated/downloadexcel?fileName=${res.data}&t=${Replace2x(token)}`)
        hide();
       }
     }).catch(
      error =>  
      {
        if(error.response.status === 406){
          error.response.data.data.map((item)=>{
            errorList.push(item);
          })
          errorListNotification('error')
        hide();

        }else if(error.response.status===403 || error.response.status ===401){
          history.push('/not-authorized-access');
        hide();

        }
        else if(error.response.data.message=="EmailError"){
          emailError('error')
        hide();

        }
        else{
          openNotificationWithIconWarningExcel('error')
        hide();

        }
      }
    );
  };
  const showModalBank = (value) => {
    setCurrencyTenantList([])
    setBankConsolideTenant([])
    setAllTotalTenant(0);
    setTotalTenantAccountTenantList([])
    setTotalWithCurrencyTenant([])

    setBankId(value)
    axios.post(`${config.apiUrl}/Consolidated/GetCurrencyListForConsolidate`,{
      TenantIdList:tenantIdList,
      BankId:value
    },config.headers)
    .then(res => {
      setCurrencyTenantList(res.data)
    }).catch(
      error =>  {
        if(error.response.status===403 || error.response.status ===401){
          history.push('/not-authorized-access');
        }
      }
  );
    axios.post(`${config.apiUrl}/Consolidated/GetListForTotalConsolidatedWithTenants`,{
      TenantIdList : tenantIdList,
      bankId:value
    },config.headers)
    .then(res => {
      var total= 0;
      res.data.map((item)=>{
        var model = {};
        total += item.balance;
        model.tenantName = item.tenantName;
        model.balance = item.balance;
        model.bankName = item.bankName;
        model.tenantList = item.tenantList;
        model.tenantId = item.tenantId;
        bankAndTotalList.push(model);
      })
      var totalString = total.toFixed(2);
      var totalDecimal = parseFloat(totalString);
      setAllTotalTenant(totalDecimal);
      setTotalTenantAccountTenantList(bankAndTotalList)
      axios.post(`${config.apiUrl}/Consolidated/GetLastTotalsConsalide`,{
        TenantIdList : tenantIdList,
        bankId:value
      },config.headers)
      .then(res => {
        setTotalWithCurrencyTenant(res.data)
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
    }).catch(
      error =>  {
        if(error.response.status===403 || error.response.status ===401){
          history.push('/not-authorized-access');
        }
      }
  );
  setTimeout(() => {
  setLoadingTenant(false)
  }, 500);

    setIsModalVisibleBank(!isModalVisibleBank);
  };

  const handleOkBank = () => {
    setIsModalVisibleBank(!isModalVisibleBank);
    setCurrencyTenantList([])
    setBankConsolideTenant([])
    setAllTotalTenant(0);
    setTotalTenantAccountTenantList([])
    setTotalWithCurrencyTenant([])
    setLoadingTenant(true)
  };

  const handleCancelBank = () => {
    setIsModalVisibleBank(!isModalVisibleBank);
    setCurrencyTenantList([])
    setBankConsolideTenant([])
    setAllTotalTenant(0);
    setTotalTenantAccountTenantList([])
    setTotalWithCurrencyTenant([])
    setLoadingTenant(true)
  };

  const changeQuery = (query) => {
    if(Screen.screenGercek === 2){
      axios.post(`${config.apiUrl}/Consolidated/GetListForTotalConsolidated`,{
        TenantIdList : query
      },config.headers)
      .then(res => {
        var total= 0;
        res.data.sort((a, b) => a.bankNameEnglish > b.bankNameEnglish ? 1:-1).map((item)=>{
          if(item.bankId !== 999){
            var model = {};
            total += item.balance;
            model.bankId= item.bankId;
            model.bankLogo = item.bankLogo;
            model.balance = item.balance;
            model.bankName = item.bankName;
            model.tenantList = item.tenantList
            bankAndTotalList.push(model);
          }
         
        })
        var totalString = total.toFixed(2);
        var totalDecimal = parseFloat(totalString);
        setAllTotal(totalDecimal);
        setTotalTenantAccountList(bankAndTotalList)
        setLoading(false)
      }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }
          }
      );
    }else{
      axios.post(`${config.apiUrl}/Consolidated/GetListForTotalConsolidated`,{
        TenantIdList : query
      },config.headers)
        .then(res => {
          var total= 0;
          res.data.map((item)=>{
            var model = {};
            total += item.balance;
            model.bankId= item.bankId;
            model.bankLogo = item.bankLogo;
            model.balance = item.balance;
            model.bankName = item.bankName;
            model.tenantList = item.tenantList
            bankAndTotalList.push(model);
          })
          var totalString = total.toFixed(2);
          var totalDecimal = parseFloat(totalString);
          setAllTotal(totalDecimal);
          setTotalTenantAccountList(bankAndTotalList)
          setLoading(false)
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }
          }
      );
    }
   
  
    axios.post(`${config.apiUrl}/Consolidated/GetCurrencyListForConsolidate`,{
      TenantIdList:query
    },config.headers)
    .then(res => {
      setCurrencyList(res.data)
        setActiveKey("9999")

      axios.post(`${config.apiUrl}/Consolidated/GetListForConsolidated`,{
        CurrencyId:res.data[0]?.currencyId,
        TenantIdList : query
      },config.headers)
      .then(res => {
        var currency = "";
        var currencyId=0;
        var currencyLogo="";
        res.data.map((item)=> {
          totalListConsolide += item.balance;
          currency=item.currency;
          currencyId=item.currencyId;
          currencyLogo = item.currencyLogo;
          bankListConsolide.push(item);
        })
        var model ={};
        model.bankLogo= "TOPLAM";
        model.balance = parseFloat(totalListConsolide.toFixed(2));
        model.currency = currency;
        model.currencyId = currencyId;
        model.currencyLogo = currencyLogo;
        bankListConsolide.push(model);
        setBankListForTRY(bankListConsolide)
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
      
        axios.post(`${config.apiUrl}/Consolidated/GetConsolidateChart?$apply=groupby((name),aggregate(value with sum as value))`,{
          CurrencyId:res.data[0]?.currencyId.toString(),
          TenantIdList: query
        },config.headers)
        .then(res => {
          const chartValuesTRY = res.data;
          var total=0;
          var valueTotal = 0;
          var currency ="";
          try {
            {chartValuesTRY.map((value, index) => {
            if(value.value <0){
              value.value= 0
            }
            total+=value.value;
            })}

            {chartValuesTRY.map((value, index) => {
              var percents = ((value.value/total)*100).toFixed(2);
              var percentsFloat =parseFloat(percents);

              if(percentsFloat <0){
                percentsFloat = 0;
              }
              value.value=percentsFloat;

              if(value.value < 10){
              valueTotal += value.value;
              }else{
                currency=value.Currency;
                chartTakeValues.push(value)
              }
              })}
              if(valueTotal != 0){
                var model ={};
                model.Currency=res.data.code;
                model.name="DİĞER";
                model.value=valueTotal;
                chartTakeValues.push(model)
              }
              setChartTakeValuesState(chartTakeValues)
          } catch (error) {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }
          }
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }
          }
      );
      
    });
  setTenantIdList(query)

  }
  const  columnsExchangeRate = [
    {
      dataIndex: 'currencyName',
    },
    {
      dataIndex: 'currencyCode',
    },
    {
      dataIndex: 'value',
      render: (record,text) => {
           return `${record === null ? "-" : record}`
          },
    },
    
  ];
  const  columns = [
      {
        title: 'Banka',
        dataIndex: 'bankLogo',
          render: (text, row, index) => {
              return <div style={{width:100}}><img src={row.bankLogo} ></img></div>
        },
      },
      {
        title: 'Bakiye',
        dataIndex: 'balance', 
        align: 'right',
        render: (text, row, index) => {
            if(text.toString().includes(".") === true){
              return <div>{text === null ? text : <CurrencyFormat value={row.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={row.currencyLogo} />} </div>
            }else{
              return <div>{text === null ? text : <CurrencyFormat value={row.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={row.currencyLogo} /> }{',00'} </div>
            }
        }
    }
    ];
    const titles = [];
    titles.push("Banka")
    currencyList.map((item)=>{
      titles.push(item.currency)
    })
    const titlesTenant = [];
    titlesTenant.push("Şirket")
    currencyTenantList.map((item)=>{
      titlesTenant.push(item.currency)
    })
  const  columnsExpanded = [
      {
        title: 'Şirket',
        dataIndex: 'tenantName',
        align: 'center',
        width: '20%',
      },
      {
        title: 'Tutar',
        dataIndex: 'balance',
        align: 'center',
        render: (text, row, index) => {
            if(text.toString().includes(".") === true){
              return <div>{text === null ? text : <CurrencyFormat value={row.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={row.currencyLogo} />} </div>
            }else{
              return <div>{text === null ? text : <CurrencyFormat value={row.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={row.currencyLogo} /> }{',00'} </div>
            }
        }
    }
    ];
  const  columnsTenant = [
      {
        title: 'Şirket',
        dataIndex: 'tenantName',
        align: 'center',
        width: '20%',
      },
      {
        title: 'Tutar',
        dataIndex: 'balance',
        align: 'right',
        render: (text, row, index) => {
            if(text.toString().includes(".") === true){
              return <div>{text === null ? text : <CurrencyFormat value={row.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={row.currencyLogo} />} ₺ </div>
            }else{
              return <div>{text === null ? text : <CurrencyFormat value={row.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={row.currencyLogo} /> }{',00 ₺'} </div>
            }
        }
    }
    ];
    const ContactSideBar = (value,e) => {
      var totalCurrencyValue = 0;
      var currency = "";
      bankListForTRY.map((item)=> {
        if(item.bankLogo === "TOPLAM"){
          totalCurrencyValue= item.balance
          currency = item.currencyLogo
        }
      })
        return(
      <Row>
        {isTabletOrMobile === false && <>
          <Col span={12} style={{paddingLeft:19}}>
        <Table
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" /> }}
            style={{ overflowX: "auto",maxHeight: "100%"}}
            columns={columns}
            size="small"
            dataSource={Screen.screenGercek === 2 ? 
              bankListForTRY.filter(x=> x.currency == value && x.bankId !== 999 && x.bankLogo !== "TOPLAM") : 
              bankListForTRY.filter(x=> x.currency == value && x.bankLogo !== "TOPLAM")}
            rowKey="bankId"
            expandable={{
              expandedRowRender: record => {
                if(record.bankLogo !== "TOPLAM"){
                  return <Table
                  locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" /> }}
                      columns={columnsExpanded}
                      size="small"
                      pagination={false}
                      dataSource={record.tenantList}
                      rowKey="bankId"
                      />
                }else{
                  return <Table
                  locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" /> }}
                      columns={columns}
                      size="small"
                      pagination={false}
                      dataSource={bankListForTRY.filter(x=> x.currency == value && x.bankLogo !== "TOPLAM")}
                      rowKey="bankId"
                      />
                }
              
              },
            }}
            bordered
            pagination={{
              defaultPageSize: 40,
            }}
            title={() => 'BANKA LİSTESİ'}
            footer={() => <div><div>Toplam : <CurrencyFormat value={totalCurrencyValue} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={currency}  /></div></div>}
            />
        </Col>
          <Col span={12}>
                <PieChart width={500} height={500}>
                  <Pie outerRadius={150}  data={chartTakeValuesState} fill="#8884d8" label={renderCustomizedLabelTRY}>
                    {
                      chartTakeValuesState.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                  </Pie>
                  <Tooltip content={CustomToolTip}
                />
                </PieChart>
                {
                  chartTakeValuesState.map((entry, index) => <div key={`cell-${index}`} ><a style={{color:COLORS[index % COLORS.length]}}><CaretRightFilled /></a>{entry.name}</div>)
                }
                </Col>
          </>}
          {isTabletOrMobile && <>
          <Col span={24}>
          <Table
       locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" /> }}
       style={{overflowX: "auto", maxHeight: "100%"}}
       columns={columns}
       size="small"
       pagination={{
        defaultPageSize: 40,
      }}
       dataSource={bankListForTRY.filter(x=> x.currency == value && x.bankLogo !== "TOPLAM")}
       rowKey="bankId"
       expandable={{
         expandedRowRender: record => {
           if(record.bankLogo !== "TOPLAM"){
             return <Table
             locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" /> }}
                 columns={columnsExpanded}
                 size="small"
                 pagination={false}
                 dataSource={record.tenantList}
                 rowKey="bankId"
                 />
           }else{
             return <Table
             locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" /> }}
                 columns={columns}
                 size="small"
                 pagination={false}
                 dataSource={bankListForTRY.filter(x=> x.currency == value && x.bankLogo !== "TOPLAM")}
                 rowKey="bankId"
                 />
           }
         
         },
       }}
       bordered
       title={() => 'BANKA LİSTESİ'}
       footer={() => <div><div>Toplam : <CurrencyFormat value={totalCurrencyValue} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={currency}  /></div></div>}
            />
        </Col>
          <Col span={24}>
                <PieChart width={isChartWidth === true ? 500 : 300} height={isChartWidth === true ? 500 : 300}>
                  <Pie outerRadius={isChartWidth === true ? 150 : 80}  data={chartTakeValuesState} fill="#8884d8" label={renderCustomizedLabelTRY}>
                    {
                      chartTakeValuesState.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                  </Pie>
                  <Tooltip content={CustomToolTip}
                />
                </PieChart>
                {
                  chartTakeValuesState.map((entry, index) => <div key={`cell-${index}`} ><a style={{color:COLORS[index % COLORS.length]}}><CaretRightFilled /></a>{entry.name}</div>)
                }
                </Col>
          </>}
        </Row>
      )
    };
    function onChange(value) {
      setFirstName(value)
        axios.get(`${config.apiUrl}/Group/GetTenantGroup/${value}`,config.headers)
        .then(res => {
            var query = "&$filter=";
            var tenantIdList = [];
            for (let index = 0; index < res.data.length; index++) {
              tenantIdList.push(res.data[index].tenantId)
                if(index == res.data.length-1){
                    query+= ` TenantId eq ${res.data[index].tenantId} `
                }else{
                    query+= ` TenantId eq ${res.data[index].tenantId} or `
                }
            }
            var chartTakeValues=[];
            axios.post(`${config.apiUrl}/Consolidated/GetListForTotalConsolidated`,{
              TenantIdList: tenantIdList
            },config.headers)
            .then(res => {
              var chartValuesTRY = [];
              res.data.map((item)=> {
                var model={};
                model.name= item.bankName;
                model.value = item.balance;
                chartValuesTRY.push(model)
              })
              var total=0;
              var valueTotal = 0;
              var currency ="";
              try {
                {chartValuesTRY.map((value, index) => {
                if(value.value <0){
                  value.value= 0
                }
                total+=value.value;
                })}
        
                {chartValuesTRY.map((value, index) => {
                  var percents = ((value.value/total)*100).toFixed(2);
                  var percentsFloat =parseFloat(percents);
        
                  if(percentsFloat <0){
                    percentsFloat = 0;
                  }
                  value.value=percentsFloat;
        
                  if(value.value < 10){
                  valueTotal += value.value;
                  }else{
                    currency=value.Currency;
                    chartTakeValues.push(value)
                  }
                  })}
                  if(valueTotal != 0){
                    var model ={};
                    model.name="DİĞER";
                    model.value=valueTotal;
                    chartTakeValues.push(model)
                  }
                  setChartTakeValuesStateTotal(chartTakeValues)
              } catch (error) {
                if(error.response.status===403 || error.response.status ===401){
                  history.push('/not-authorized-access');
                }
              }
            }).catch(
              error =>  {
                if(error.response.status===403 || error.response.status ===401){
                  history.push('/not-authorized-access');
                }
              }
          );
            changeQuery(tenantIdList)
            axios.post(`${config.apiUrl}/Consolidated/GetLastTotalsConsalide`,{
              TenantIdList : tenantIdList
            },config.headers)
            .then(res => {
              setTotalWithCurrency(res.data)
              
            }).catch(
              error =>  {
                if(error.response.status===403 || error.response.status ===401){
                  history.push('/not-authorized-access');
                }
              }
          );
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }else if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
            }
          }
      );
      }
      function onChangeTenantSwift(checked) {
        setSwitchTenant(checked)
      }
    const handleChange = activeKeys => {
      setActiveKey(activeKeys)

     
    axios.post(`${config.apiUrl}/Consolidated/GetListForTotalConsolidated`,{
      TenantIdList : tenantIdList
    },config.headers)
    .then(res => {
      res.data.map((item) => {
       totalListConsolideTotal += item.balance;
       bankListConsolideTotal.push(item);
      })
      var model = {};
      model.bankLogo= "TOPLAM";
      model.balance = parseFloat(totalListConsolideTotal.toFixed(2));
      bankListConsolideTotal.push(model);
      setBankListForTotal(bankListConsolideTotal)
    }).catch(
      error =>  {
        if(error.response.status===403 || error.response.status ===401){
          history.push('/not-authorized-access');
        }
      }
  );
    if(activeKeys === "9999"){
      axios.post(`${config.apiUrl}/Consolidated/GetListForTotalConsolidated`,{
        TenantIdList: tenantIdList
      },config.headers)
      .then(res => {
        var chartValuesTRY = [];
        res.data.map((item)=> {
          var model={};
          model.name= item.bankName;
          model.value = item.balance;
          chartValuesTRY.push(model)
        })
        var total=0;
        var valueTotal = 0;
        var currency ="";
        try {
          {chartValuesTRY.map((value, index) => {
          if(value.value <0){
            value.value= 0
          }
          total+=value.value;
          })}
  
          {chartValuesTRY.map((value, index) => {
            var percents = ((value.value/total)*100).toFixed(2);
            var percentsFloat =parseFloat(percents);
  
            if(percentsFloat <0){
              percentsFloat = 0;
            }
            value.value=percentsFloat;
  
            if(value.value < 10){
            valueTotal += value.value;
            }else{
              currency=value.Currency;
              chartTakeValues.push(value)
            }
            })}
            if(valueTotal != 0){
              var model ={};
              model.name="DİĞER";
              model.value=valueTotal;
              chartTakeValues.push(model)
            }
            setChartTakeValuesStateTotal(chartTakeValues)
        } catch (error) {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
    }else{
      axios.post(`${config.apiUrl}/Consolidated/GetConsolidateChart?$apply=groupby((name),aggregate(value with sum as value))`,{
        CurrencyId:activeKeys,
        TenantIdList: tenantIdList
      },config.headers)
      .then(res => {
        const chartValuesTRY = res.data;
        var total=0;
        var valueTotal = 0;
        var currency ="";
        try {
          {chartValuesTRY.map((value, index) => {
          if(value.value <0){
            value.value= 0
          }
          total+=value.value;
          })}
    
          {chartValuesTRY.map((value, index) => {
            var percents = ((value.value/total)*100).toFixed(2);
            var percentsFloat =parseFloat(percents);
    
            if(percentsFloat <0){
              percentsFloat = 0;
            }
            value.value=percentsFloat;
    
            if(value.value < 10){
            valueTotal += value.value;
            }else{
              currency=value.Currency;
              chartTakeValues.push(value)
            }
            })}
            if(valueTotal != 0){
              var model ={};
              model.Currency=res.data.code;
              model.name="DİĞER";
              model.value=valueTotal;
              chartTakeValues.push(model)
            }
            setChartTakeValuesState(chartTakeValues)
        } catch (error) {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
    
    }
      axios.post(`${config.apiUrl}/Consolidated/GetListForConsolidated`,{
        CurrencyId:activeKeys,
        TenantIdList : tenantIdList
      },config.headers)
      .then(res => {
        var currency = "";
        var currencyId=0;
        var currencyLogo="";
        res.data.map((item)=> {
          totalListConsolide += item.balance;
          currency=item.currency;
          currencyId=item.currencyId;
          currencyLogo = item.currencyLogo;
          bankListConsolide.push(item);
        })
        var model ={};
        model.bankLogo= "TOPLAM";
        model.balance = parseFloat(totalListConsolide.toFixed(2));
        model.currency = currency;
        model.currencyId = currencyId;
        model.currencyLogo = currencyLogo;
        bankListConsolide.push(model);
        setBankListForTRY(bankListConsolide)
      }).catch(
        error =>  {
          if(error.response.status===403 || error.response.status ===401){
            history.push('/not-authorized-access');
          }
        }
    );
    
    };
        const { TabPane } = Tabs;
        var totalValue = 0;
        var currency = "";
        bankListForTotal.map((item)=> {
          if(item.bankLogo === "TOPLAM"){
            totalValue= item.balance
            currency = '₺'
          }
        })
        return (
         <div>
           {
             isDesktopOrLaptop ?
             <Modal  visible={isModalVisibleBank} width={titlesTenant.length>5 ? 1000:( titlesTenant.length==5 ? 700:500)} onOk={handleOkBank} onCancel={handleCancelBank} footer={null}>
             <Spin spinning={loadingTenant}>
             <table class="table" style={{ marginTop:50,marginBottom:50,overflowX:'auto'}}>
                      <tr>
                        {titlesTenant.map((item)=>{
                         return <th style={{paddingBottom:10}}>{item}</th>
                        })}
                        <td><strong>TOPLAM</strong> </td>
                      </tr>
                        {
                          totalTenantAccountTenantList.map((item)=>{
                            return <tr><td style={{paddingBottom: 13,paddingRight:15,width:120}}><strong>{item.tenantName}</strong></td> 
                            {item.tenantList.map((item)=>{
                              return <td style={{paddingBottom: 13,width: 129}} class="text-center"> 
                              {
                                item.balance.toString().includes(".") ?
                                <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={item.currencyLogo} /></div>:
                                <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={item.currencyLogo} />,00</div>
                              }
                              
                               </td>
                            })}
                            <td style={{paddingBottom: 13,width:129}}>
                              {
                                item.balance.toString().includes(".") ? 
                                <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /> </div> :
                                <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /> ,00</div>
                              }
                              
                              
                              </td>
                             </tr>
                          })
                         
                        }
                       
                          <tr><td><strong>TOPLAM</strong></td>
                          {
                          totalWithCurrencyTenant.map((item)=>{
                            
                            return <td><td class="text-center"> 
                            {
                              item.balance.toString().includes(".") ?
                              <div><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={item.currencyLogo} /> </div> :
                              <div><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={item.currencyLogo} /> ,00</div>
                            }
                            
                            </td></td>
                          })
                          }
                        <td style={{paddingBottom: 13}}>
                          {
                            allTotalTenant.toString().includes(".") ? 
                            <div><CurrencyFormat value={allTotalTenant} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /> </div> :
                            <div><CurrencyFormat value={allTotalTenant} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /> ,00</div>
                          }
                          
                          </td>
                         
                       </tr>
                    </table>
           
             </Spin> 
           
           </Modal>
           :
           <Modal  visible={isModalVisibleBank} onOk={handleOkBank} onCancel={handleCancelBank} footer={null}>
           <Spin spinning={loadingTenant}>
             <Col style={{overflowX:'auto'}}>
           <table class="table" style={{backgroundColor:'white',marginTop:20,marginBottom:50,overflowX:'auto'}}>
                    <tr>
                      {titlesTenant.map((item)=>{
                       return <th style={{paddingBottom:10}}>{item}</th>
                      })}
                      <td><strong>TOPLAM</strong> </td>
                    </tr>
                      {
                        totalTenantAccountTenantList.map((item)=>{
                          return <tr><td style={{paddingBottom: 13,paddingRight:15,width:150}}><strong>{item.tenantName}</strong></td> 
                          {item.tenantList.map((item)=>{
                            return <td style={{paddingBottom: 13,width: 150}} class="text-center"> 
                            {
                              item.balance.toString().includes(".") ?
                              <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={item.currencyLogo} /></div>:
                              <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={item.currencyLogo} />,00</div>
                            }
                            
                             </td>
                          })}
                          <td style={{paddingBottom: 13,width:150}}>
                            {
                              item.balance.toString().includes(".") ? 
                              <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /> </div> :
                              <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /> ,00</div>
                            }
                            
                            
                            </td>
                           </tr>
                        })
                       
                      }
                     
                        <tr><td><strong>TOPLAM</strong></td>
                        {
                        totalWithCurrencyTenant.map((item)=>{
                          
                          return <td><td class="text-center"> 
                          {
                            item.balance.toString().includes(".") ?
                            <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={item.currencyLogo} /> </div> :
                            <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={item.currencyLogo} /> ,00</div>
                          }
                          
                          </td></td>
                        })
                        }
                      <td>
                        {
                          allTotalTenant.toString().includes(".") ? 
                          <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={allTotalTenant} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /> </div> :
                          <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={allTotalTenant} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /> ,00</div>
                        }
                        
                        </td>
                       
                     </tr>
                  </table>
                  </Col>
           </Spin> 
         
         </Modal>
           }
            <Spin spinning={loading}>
           {
             isDesktopOrLaptop ? 
             <Row>
             <Col span={10}></Col>
             <Col span={12}> 
            Banka Görünüm <Switch  style={{color:'white',backgroundColor:'#086880'}} onChange={onChangeTenantSwift} /> Şirket Görünüm
            </Col>
            <Col span={2}></Col>
            </Row>
            :
            <Row>
            <Col span={24}>
                      <p style={{fontSize:'120%'}}><BarsOutlined /> KONSOLİDE</p>
                      </Col>
            <Col span={24} style={{marginBottom:10}}> 
            Banka Görünüm <Switch  style={{color:'white',backgroundColor:'#086880'}} onChange={onChangeTenantSwift} /> Şirket Görünüm
            </Col></Row>
           }
             {switchTenant ? 
             <ConsolidatedTenant tenantIdList={tenantIdList} />
             :
             <div>
                 {
                   isDesktopOrLaptop ?
                   <Row>
                <Col span={24}>
                    <p style={{fontSize:'120%',marginTop:-5}}><BarsOutlined /> KONSOLİDE</p>
                    </Col>
                <Col span={12} style={{marginTop:-5}}>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Grup Seç"
                    optionFilterProp="children"
                    value= {firstName}
                    onChange={onChange}
                    filterOption={(input, option) =>
                      option.props.children
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                    }
                >
                        {groups.map(item => (
                            <Option key={item.id}>{item.name}</Option>
                            ))} 
                </Select>
                
                </Col>
                <Col span={10}></Col>
               <Col span={2}>
               {activeKey === "9999" ? 
                        <div>
                      <a style={{color:'green',fontSize:"180%",float: 'right'}} onClick={exportExcel}><FileExcelOutlined /></a>
                        </div>
                        :
                        null
                        } 
             
               </Col>
               </Row>
                    :
                    <Row>
                      <Col span={18} >
                      <Select
                          showSearch
                          style={{ width: 200 }}
                          placeholder="Grup Seç"
                          optionFilterProp="children"
                          value= {firstName}
                          onChange={onChange}
                          filterOption={(input, option) =>
                            option.props.children
                            .toLocaleLowerCase("tr-TR")
                            .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                          }
                      >
                              {groups.map(item => (
                                  <Option key={item.id}>{item.name}</Option>
                                  ))} 
                      </Select>
                        
                        </Col>
                    
                      <Col span={6}>
                         {activeKey === "9999" ? 
                        <div>
                      <a style={{color:'green',fontSize:"180%",marginRight:60,float: 'right'}} onClick={exportExcel}><FileExcelOutlined /></a>
                        </div>
                        :
                        null
                        } 

                       {/* {activeKey === "9999" ? 
               <div>
                 <ReactHTMLTableToExcel  
               table="emp"  
               filename="KonsolideListe"  
               sheet="tablexls"  
               buttonText={ <p style={{fontSize:"180%", color:"green"}}><FileExcelOutlined /></p> } />   
               </div>
               :
               null
               } */}
                      </Col>
                      </Row>
                      }
            
        
             <Tabs
             onChange={handleChange}
             activeKey={activeKey}
             >
                 {
                   currencyList.length !== 0 ?
                   <TabPane tab="TÜMÜ" key="9999">
                   <Row>
                   {isTabletOrMobile === false && <> 
                     <Col span={13} style={{overflowX:'auto'}}>
                     <table class="table" style={{ marginBottom:50}}>
                     <tr>
                       {titles.map((item)=>{
                        return <th style={{paddingBottom:10}}>{item}</th>
                       })}
                       <td style={{paddingBottom:10}}><strong>TOPLAM</strong> </td>
                       <td style={{paddingBottom:10}}><strong>ŞİRKETLER</strong></td>
                     </tr>
                       {
                         totalTenantAccountList.map((item)=>{ 
                           return <tr >
                             <td  style={{paddingBottom: 13,paddingRight:15,width:120}}><img style={{width:110,marginRight:15}} src={item.bankLogo} /></td>
                           {item.tenantList.map((item)=>{
                             return <td style={{paddingBottom: 13,width: 129}} class="text-center"> 
                             {
                               item.balance.toString().includes(".") ?
                               <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={item.currencyLogo} /></div>:
                               <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={item.currencyLogo} />,00</div>
                             }
                              </td>
                           })}
                           <td style={{paddingBottom: 13,width:129}}>
                             {
                               item.balance.toString().includes(".") ? 
                               <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /> </div> :
                               <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} />,00</div>
                             }
                             </td>
                             <td style={{textAlign:'center',color:'#1890ff',marginBottom:5}} ><MenuUnfoldOutlined style={{fontSize:20}}  onClick={() => showModalBank(item.bankId)} /></td>
                             </tr>
                         })
                       }
                      <tr><td style={{textAlign:'center'}}><strong style={{marginRight:6}}>TOPLAM</strong></td>
                         {
                         totalWithCurrency.map((item)=>{
                           
                           return <td><td class="text-center"> 
                           {
                             item.balance.toString().includes(".") ?
                             <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={item.currencyLogo} /> </div> :
                             <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={item.currencyLogo} />,00</div>
                           }
                           
                           </td></td>
                         })
                         }
                       <td style={{paddingBottom: 13,width:120}}>
                         {
                           allTotal.toString().includes(".") ? 
                           <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={allTotal} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /> </div> :
                           <div style={{whiteSpace:'nowrap'}}><CurrencyFormat value={allTotal} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} />,00</div>
                         }
                         </td>
                      </tr>
                   </table>
                     <table hidden id="emp" class="table" style={{ marginBottom:50}}>
                     <tr>
                       {titles.map((item)=>{
                        return <th >{item}</th>
                       })}
                       <td><strong>TOPLAM</strong> </td>
                     </tr>
                       {
                         totalTenantAccountList.map((item)=>{
                           return <tr><td><strong>{item.bankName}</strong></td>
                           {item.tenantList.map((item)=>{
                             return <td> {item.balance} </td>
                           })}
                           <td>{item.balance} </td></tr>
                         })
                        
                       }
                      
                         <tr><td><strong>TOPLAM</strong></td>
                         {
                         totalWithCurrency.map((item)=>{
                           
                           return <td> {item.balance}</td>
                         })
                         }
                       <td >{allTotal}</td>

                      </tr>
                   </table>
                     
                      </Col>
                     <Col span={9}>
                     <PieChart  width={isChartWidth === true ? 500 : 300} height={isChartWidth === true ? 500 : 300}>
                  <Pie outerRadius={isChartWidth === true ? 150 : 80}  data={chartTakeValuesStateTotal} fill="#8884d8" label={renderCustomizedLabelTRY}>
                          {
                            chartTakeValuesStateTotal.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                          }
                        </Pie>
                        <Tooltip content={CustomToolTip}
                        />
                        </PieChart>
                        {
                          chartTakeValuesStateTotal.map((entry, index) => <div key={`cell-${index}`} ><a style={{color:COLORS[index % COLORS.length]}}><CaretRightFilled /></a>{entry.name}</div>)
                        }
                     </Col>
                     <Col span={1}></Col>
                     <Col span={22} style={{marginBottom:25}}>
                     <Collapse  >
                       <Panel header="Döviz kurlarını görmek için tıklayın" key="1">
                         <Card title={'KUR BİLGİSİ  '} style={{marginLeft:'1%'}} extra={`Kur bilgileri ${isLiveValue ? liveDate : dailyDate} tarihine aittir.`} className={`gx-card-widget`} >
                         <div className="gx-table-responsive">
                           <Table className="gx-table-no-bordered"
                           columns={columnsExchangeRate}
                           dataSource={exchangeRate}
                           pagination={false} 
                           bordered={false}
                           size="small"
                           />
                         </div>
                       </Card>
                       </Panel>
                     </Collapse>
                     </Col>
                     <Col span={1}></Col>
                   </>}
                   {isTabletOrMobile && <>
                     <Col span={24} style={{overflowX:'auto'}}>
                     <table class="table" style={{ marginBottom:50}}>
                     <tr>
                       {titles.map((item)=>{
                        return <th >{item}</th>
                       })}
                       <td><strong>TOPLAM</strong> </td>
                       <td><strong>ŞİRKETLER</strong></td>
                     </tr>
                       {
                         totalTenantAccountList.map((item)=>{
                           return <tr><td style={{paddingBottom: 13}}><img style={{width:120}} src={item.bankLogo} /></td>
                           {item.tenantList.map((item)=>{
                             return <td class="text-center" style={{paddingBottom: 13,fontSize:12}} class="text-center">  <CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={item.currencyLogo} /> </td>
                           })}
                           <td class="text-center"  style={{fontSize:12}} >  <CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /> </td>
                           <td style={{textAlign:'center',color:'#1890ff',marginBottom:5}} ><MenuUnfoldOutlined style={{fontSize:20}}  onClick={() => showModalBank(item.bankId)} /></td></tr>
                         })
                        
                       }
                      
                         <tr><td><strong>TOPLAM</strong></td>
                         {
                         totalWithCurrency.map((item)=>{
                           
                           return <td><td class="text-center" style={{fontSize:12}}> <CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={item.currencyLogo} /> </td></td>
                         })
                         }
                       <td class="text-center" style={{fontSize:12}}><CurrencyFormat value={allTotal} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={"₺"} /> </td>
                       
                      </tr>
                   </table>
                   <table hidden id="emp" class="table" style={{ marginBottom:50,width:'100%'}}>
                     <tr>
                       {titles.map((item)=>{
                        return <th >{item}</th>
                       })}
                       <td><strong>TOPLAM</strong> </td>
                     </tr>
                       {
                         totalTenantAccountList.map((item)=>{
                           return <tr><td><strong>{item.bankName}</strong></td>
                           {item.tenantList.map((item)=>{
                             return <td> <CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={""} /> </td>
                           })}
                           <td><CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={""} /> </td></tr>
                         })
                        
                       }
                      
                         <tr><td><strong>TOPLAM</strong></td>
                         {
                         totalWithCurrency.map((item)=>{
                           
                           return <td> <CurrencyFormat value={item.balance} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={""} /> </td>
                         })
                         }
                       <td ><CurrencyFormat value={allTotal} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={""} /> </td>

                      </tr>
                   </table>
                     </Col>
                     <Col span={24}>
                     <PieChart  width={isChartWidth === true ? 500 : 300} height={isChartWidth === true ? 500 : 300}>
                  <Pie outerRadius={isChartWidth === true ? 150 : 80}  data={chartTakeValuesStateTotal} fill="#8884d8" label={renderCustomizedLabelTRY}>
                          {
                            chartTakeValuesStateTotal.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                          }
                        </Pie>
                        <Tooltip content={CustomToolTip}
                        />
                        </PieChart>
                        {
                          chartTakeValuesStateTotal.map((entry, index) => <div key={`cell-${index}`} ><a style={{color:COLORS[index % COLORS.length]}}><CaretRightFilled /></a>{entry.name}</div>)
                        }
                     </Col>
                     <Col span={24} style={{marginBottom:25}}>
                     <Collapse  >
                       <Panel header="Döviz kurlarını görmek için tıklayın" key="1">
                         <Card title={'KUR BİLGİSİ  '} style={{marginLeft:'1%'}} extra={`Kur bilgileri ${isLiveValue ? liveDate : dailyDate} tarihine aittir.`} className={`gx-card-widget`} >
                         <div className="gx-table-responsive">
                           <Table className="gx-table-no-bordered"
                           pagination={{
                            defaultPageSize: 40,
                          }}
                           columns={columnsExchangeRate}
                           dataSource={exchangeRate}
                           pagination={false}
                           bordered={false}
                           size="small"
                           />
                         </div>
                       </Card>
                       </Panel>
                     </Collapse>
                     </Col>
                    </>}
                   </Row>
                   </TabPane>
                   :
                   <TabPane tab="TÜMÜ" key="9999">
                   <Row>
                     <Col md={6} xs={6}></Col>
                     <Col md={12} xs={12}>
                     <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" />
                     </Col>
                     <Col md={6} xs={6}></Col>
                   </Row></TabPane>
                                    }
                {
                  currencyList.length === 0 ?
                  <TabPane
                  tab={
                    <span>
                    </span>
                  }
                  key="1"
                >
              <Row>
              <Col span={24}>
              <Table
              locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{color:'red'}} description="Konsolide Grubu Seçin" /> }}
                  />
              </Col>
             </Row>
              </TabPane> :
              currencyList.map((item,index)=>{
                return(
               <TabPane
               tab={
                     <span>
                      {item.currency}
                     </span>
                   }
                   key={item.currencyId}
             >
             {ContactSideBar(item.currency)}
               </TabPane>)
              })
                 }
          </Tabs>
            </div>
            }
           </Spin>
           </div>
        )
}
export default Consolidated;
