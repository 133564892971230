import React, { useState } from "react";
import { Avatar, Popover, Modal } from "antd";
import Replace from "../../util/Extensions/ReplaceExtension";
import { UserOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import DefaultModuleUpdateForm from "../../routes/Forms/DefaultModuleUpdateForm";

const UserProfile = () => {
  const [defaultModuleModalVisible, setDefaultModuleModalVisible] =
    useState(false);

  const userSignOut = () => {
    RequestHandler.get("/Auth/Logout").then((response) => {
      if (response.success) {
        localStorage.removeItem("token");
        window.location.reload();
      }
    });
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => setDefaultModuleModalVisible(true)}>
        Anasayfayı Değiştir
      </li>
      <li>
        <a style={{ color: "black" }} href="/password-change">
          Şifremi Değiştir
        </a>
      </li>
      <li onClick={() => userSignOut()}>Çıkış Yap</li>
    </ul>
  );
  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          style={{ backgroundColor: "#002447" }}
          icon={<UserOutlined />}
        />
        <span
          className="gx-avatar-name"
          style={{ color: "#ffffff", marginLeft: "10px", fontSize: "96%" }}
        >
          {Replace(localStorage.getItem("userName"))}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>

      <Modal
        id="defaultModuleModal"
        centered
        visible={defaultModuleModalVisible}
        onOk={() => setDefaultModuleModalVisible(false)}
        onCancel={() => setDefaultModuleModalVisible(false)}
        footer={null}
      >
        <DefaultModuleUpdateForm
          onSuccess={() => setDefaultModuleModalVisible(false)}
        />
      </Modal>
    </div>
  );
};

export default UserProfile;
