import React from "react";

import { Row, Col } from "antd";
import PosCollectionTotal from "../HomeComponent/B2B/PosCollectionTotal";
import PosCollectionTotalCurrentMonth from "../HomeComponent/B2B/PosCollectionTotalCurrentMonth";
import PosCollectionTotalToday from "../HomeComponent/B2B/PosCollectionTotalToday";
import PosCollectionCount from "../HomeComponent/B2B/PosCollectionCount";
import PosCollectionTransactionSummary from "../HomeComponent/B2B/PosCollectionTransactionSummary";

const B2bHome = (props) => {
  return (
    <>
      <Row>
        <PosCollectionTotal />
        <PosCollectionTotalCurrentMonth />
        <PosCollectionTotalToday />
        <PosCollectionCount />
      </Row>
      <Row>
        <Col span={24}>
          <PosCollectionTransactionSummary />
        </Col>
      </Row>
    </>
  );
};
export default B2bHome;
