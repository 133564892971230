import React, { useState, useEffect } from "react";

import RequestHandler from "util/RequestHandler";
import { ColumnHelper } from "util/TableHelper";

import DbsSentInvoiceTableFilter from "../Filters/DbsSentInvoiceTableFilter";
import DbsSentInvoiceUpdateForm from "../Forms/DbsSentInvoiceUpdateForm";
import DbsSentInvoiceSummary from "./DbsSentInvoiceSummary";
import { odata } from "../Filters/Helper";

import moment from "moment";
import config from "../../util/ApiforContext";

import {
  Table,
  Empty,
  Col,
  Row,
  Drawer,
  Popconfirm,
  Tooltip,
  message,
  Tabs,
} from "antd";
import {
  BarsOutlined,
  EditOutlined,
  CloseOutlined,
  WarningOutlined,
  FileExcelOutlined,
  DeleteOutlined
} from "@ant-design/icons";

const DbsSentInvoice = () => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    activeTab: "0",
    filter: "",
  });
  const [updateInvoiceVisibility, setUpdateInvoiceVisibility] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({});
  const [order, setOrder] = useState("");

  useEffect(() => getTableData(), [order, tableDataQueryOptions]);

  const getFilterQuery = () => {
    const { activeTab, filter } = tableDataQueryOptions;

    const conditions = [];
    let query = "";
    switch (activeTab) {
      //Başarılı
      case "1":
        //tahsil edilecek ve hata olmayanlar
        conditions.push("statusId eq 1 and isHaveWarning eq false");
        break;
      //Teyit Bekliyor
      case "2":
        //teyit bekliyor ve hata olmayanlar
        conditions.push("statusId eq 7 and isHaveWarning eq false");
        break;
      //Hatalı
      case "3":
        //başarısız veya hata olanlar
        conditions.push(
          "(statusId eq 6 or isHaveWarning eq true)"
        );
        break;
      //Tahsil Edilemedi
      case "4":
        //ödenmeyenler
        conditions.push("statusId eq 2");
        break;
      //Kısmı Tahsil Edildi
      case "5":
        //bir kısmı ödenenler
        conditions.push("statusId eq 3");
        break;
      //Tahsil Edildi
      case "6":
        //komple ödenenler
        conditions.push("statusId eq 4");
        break;
      //İptal
      case "7":
        //iptal edilen ve hata olmayanlar
        conditions.push("statusId eq 5 and isHaveWarning eq false");
        break;
    }

    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length > 0) {
      query = "&$filter=" + conditions.join(" and ");
    }

    return query;
  };

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    const { skip, take } = tableDataQueryOptions;

    RequestHandler.get(
      `/DbsSentInvoice?$count=true${getFilterQuery()}${order}&$skip=${skip}&$top=${take}`
    ).then((response) => {
      if (Array.isArray(response.value)) {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      }
    });
  };

  const paging = (p, filters, sorter) => {
    let order = "";
    if (sorter.columnKey && sorter.order) {
      order =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }
    setOrder(order);
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      ...tableDataQueryOptions,
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...tableDataQueryOptions,
        skip: 0,
        current: 1,
        take: prevState.take,
        filter: query
      };
    });
  };

  const handleOnClickUpdate = (record) => {
    RequestHandler.get(`/DbsSentInvoice/GetById/${record.id}`).then((response) => {
      if (response) {
        setUpdateFormData(response);
        setUpdateInvoiceVisibility(true);
      }
    });
  };

  const handleOnClickCancel = (id) => {
    RequestHandler.post(`/DbsBank/Delete`, { id: id }).then((response) => {
      if (response.success) {
        getTableData();
      }
    });
  };

  const handleOnClickDelete = (id) => {
    RequestHandler.delete(`/DbsSentInvoice/${id}`).then((response) => {
      if (response.success) {
        getTableData();
      }
    });
  };

  const onUpdateInvoiceSuccess = () => {
    getTableData();
    setUpdateInvoiceVisibility(false);
  };

  const onTabChange = (tabKey) => {
    setTableDataQueryOptions({
      ...tableDataQueryOptions,
      skip: 0,
      current: 1,
      activeTab: tabKey,
    });
  };

  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);

    RequestHandler.post(
      `/DbsSentInvoice/ExportExcel`,
      {
        query: getFilterQuery(),
      },
      false
    ).then((response) => {
      hideMessage();
      if (response) {
        window.open(
          `${config.apiUrl
          }/DbsSentInvoice/DownloadExcel?fileName=${response}&t=${RequestHandler.getToken()}`
        );
      }
    });
  };

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      width: 150,
      render: (text) => <img src={text}></img>,
    },
    {
      title: "Bayi Adı",
      dataIndex: "dbsDealerName",
    },
    {
      title: "Bayi Kodu",
      dataIndex: "dbsDealerCode",
    },
    {
      title: "Fatura Tarihi",
      dataIndex: "invoiceDate",
      sorter: true,
      key: "invoiceDate",
      render: (data) => moment(data).format("DD.MM.YYYY"),
    },
    {
      title: "Vade Tarihi",
      dataIndex: "dueDate",
      sorter: true,
      key: "dueDate",
      render: (data) => moment(data).format("DD.MM.YYYY"),
    },
    {
      title: "Fatura Tutarı",
      dataIndex: "amount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Tahsilat Tutarı",
      dataIndex: "collectedAmount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Para Birimi",
      dataIndex: "currencyCode",
    },
    {
      title: "Tahsilat Tarihi",
      dataIndex: "collectionDate",
      sorter: true,
      key: "collectionDate",
      render: (data) => data ? moment(data).format("DD.MM.YYYY") : null,
    },
    {
      title: "Fatura Durumu",
      dataIndex: "status",
    },
    {
      title: "Fatura Numarası",
      dataIndex: "invoiceNumber",
    },
    {
      title: "Açıklama",
      dataIndex: "description",
    },
    {
      title: "Uyarı",
      dataIndex: "isHaveWarning",
      align: "center",
      render: (data, row) => {
        return data ? (
          <Tooltip title={row.warningMessage}>
            <WarningOutlined style={{ color: "orange", fontSize: "130%" }} />
          </Tooltip>
        ) : undefined;
      },
    },
    {
      title: "Düzenle",
      dataIndex: "statusId",
      align: "center",
      render: (data, row) => {
        //[payable, failed]
        const editableStatusIds = [1, 6];
        if (!editableStatusIds.includes(data)) {
          return null;
        }

        return (
          <a onClick={() => handleOnClickUpdate(row)}>
            <EditOutlined style={{ color: "orange", fontSize: "130%" }} />
          </a>
        );
      },
    },
    {
      title: "İptal",
      dataIndex: "id",
      align: "center",
      render: (data, row) => {
        //[payable, failed]
        const cancelableStatusIds = [1, 6];
        if (!cancelableStatusIds.includes(row.statusId)) {
          return null;
        }
        return (
          <Popconfirm
            title="İptal etmek istiyor musunuz?"
            okText="Evet"
            cancelText="Hayır"
            onConfirm={() => handleOnClickCancel(data)}
          >
            <a>
              <CloseOutlined style={{ color: "red", fontSize: "130%" }} />
            </a>
          </Popconfirm>
        );
      },
    },
  ];

  //Hatalı
  if (tableDataQueryOptions.activeTab == 3) {
    columns.push(
      {
        title: "Sil",
        dataIndex: "id",
        align: "center",
        render: (data, row) => {
          //failed
          if (row.statusId != 6) return null;

          return (
            <Popconfirm
              title="Silmek istiyor musunuz?"
              okText="Evet"
              cancelText="Hayır"
              onConfirm={() => handleOnClickDelete(data)}
            >
              <a>
                <DeleteOutlined style={{ color: "red", fontSize: "130%" }} />
              </a>
            </Popconfirm>
          );
        }
      });
  }

  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> GÖNDERİLMİŞ FATURALAR
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
          <Tooltip title="Excel İndir">
            <a
              style={{
                color: "green",
                fontSize: "180%",
                marginRight: 30,
              }}
              onClick={onClickExportExcel}
            >
              <FileExcelOutlined />
            </a>
          </Tooltip>
        </Col>
      </Row>

      <DbsSentInvoiceTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <DbsSentInvoiceSummary />

      <Tabs
        defaultActiveKey={tableDataQueryOptions.activeTab}
        onChange={onTabChange}
      >
        <Tabs.TabPane tab="Tümü" key="0" />
        <Tabs.TabPane tab="Başarılı" key="1" />
        <Tabs.TabPane tab="Teyit Bekliyor" key="2" />
        <Tabs.TabPane tab="Tahsil Edildi" key="6" />
        <Tabs.TabPane tab="Kısmı Tahsil Edildi" key="5" />
        <Tabs.TabPane tab="Tahsil Edilemedi" key="4" />
        <Tabs.TabPane tab="Hatalı" key="3" />
        <Tabs.TabPane tab="İptal" key="7" />
      </Tabs>

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Drawer
        id="updateSentInvoiceDrawer"
        title="Fatura Güncelle ve Gönder"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setUpdateInvoiceVisibility(false)}
        visible={updateInvoiceVisibility}
      >
        <DbsSentInvoiceUpdateForm
          data={updateFormData}
          onSuccess={onUpdateInvoiceSuccess}
        />
      </Drawer>
    </>
  );
};

export default DbsSentInvoice;
