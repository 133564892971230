import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Form,
  Col,
  notification,
  Row,
  Button,
  Upload,
  Spin,
  Modal,
} from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  PlusOutlined,
  MinusOutlined,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import Replace2x from "../../util/Extensions/ReplaceExtension";
import { useHistory } from "react-router-dom";
import config from "../../util/ApiforContext";
import FirmAccountUpdate from "./FirmAccountUpdate";
const token = localStorage.getItem("token");
const expiration = localStorage.getItem("expiration");
const openNotificationWithIconTcNoVkno = (type) => {
  notification[type]({
    message: "Hata",
    description:
      "Vergi Numarası veya TC Kimlik numarası alanlarından en az birinin dolu olması zorunludur.",
    duration: 2,
  });
};
const vkNoError = (type) => {
  notification[type]({
    message: "Hata",
    description: "Vergi Numarası doğru değil.",
    duration: 2,
  });
};
const tcNoError = (type) => {
  notification[type]({
    message: "Hata",
    description: "Tc Kimlik numarası doğru değil",
    duration: 2,
  });
};
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 15,
    span: 5,
  },
};
const { TextArea } = Input;
const FirmAddForm = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });

  const history = useHistory();
  const [inputList, setInputList] = useState([{ word: "" }]);
  const [spinLoading, SetSpinLoading] = useState(false);
  const [wordList, setWordList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firmTypeDropdown, setfirmTypeDropdown] = useState([]);
  const [firmClassDropdown, setfirmClassDropdown] = useState([]);
  const [FirmTypeValue, setFirmTypeValue] = useState(3);
  const [tckn, setTckn] = useState(props.Tckn);
  const [vkn, setVkn] = useState(props.Vkn);
  const [id, setId] = useState(props.Id);
  const [senderName, setSenderName] = useState(props.senderName);
  const [firmId, setFirmId] = useState("");
  const [iban, setIban] = useState(props.Iban);
  const [bankCode, setBankCode] = useState("");
  const [bankBranchName, setbankBranchName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [errors, setErrors] = useState([]);
  const [firmTypeId, setFirmTypeId] = useState(3);
  const [firmAccountVisible, setFirmAccountVisible] = useState(false);
  const errorList = [];

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { word: "" }]);
  };
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };
  const handleCancelFirmAccount = (e) => {
    openNotificationWithIcon("success");
    setLoading(false);
    window.location.reload();
    setFirmAccountVisible(false);
  };
  const onChangeFirmType = (value) => {
    setFirmTypeValue(value);
    axios
      .get(`${config.apiUrl}/FirmClass?firmTypeId=${value}`, config.headers)
      .then((res) => {
        setfirmClassDropdown(res.data.value);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  };
  useEffect(() => {
    setId(props.Id);
    setVkn(props.Vkn);
    setTckn(props.Tckn);
    setSenderName(props.SenderName);
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }
    if (props.AccountId !== undefined) {
      axios
        .get(
          `${config.apiUrl}/AccountTransaction/Getbyid/${props.Id}`,
          config.headers
        )
        .then((res) => {
          setIban(res.data.iban);
          setAccountNumber(res.data.accountNumber);
          setbankBranchName(res.data.branchCode);
          setBankCode(res.data.otherBankCode);
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push("/not-authorized-access");
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            });
            errorListNotification("error");
          }
        });
      axios
        .get(
          `${config.apiUrl}/AccountTransaction/GetBankCode/${props.Id}`,
          config.headers
        )
        .then((res) => {
          setbankBranchName(res.data.message);
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push("/not-authorized-access");
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            });
            errorListNotification("error");
          }
        });
    }
    axios
      .get(`${config.apiUrl}/FirmClass?firmTypeId=3`, config.headers)
      .then((res) => {
        setfirmClassDropdown(res.data.value);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
    axios
      .get(`${config.apiUrl}/Firm/GetFirmType`, config.headers)
      .then((res) => {
        const firmTypeDropdown = res.data.data;
        setfirmTypeDropdown(firmTypeDropdown);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });

    setFirmTypeValue(3);
  }, [
    setfirmTypeDropdown,
    setfirmClassDropdown,
    setFirmTypeValue,
    setTckn,
    setVkn,
    setSenderName,
    props.SenderName,
    props.Vkn,
    props.Tckn,
  ]);

  const { Option } = Select;
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Ekleme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });
  };
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 5,
      });
    });
  };
  const onChangeVknValue = (e) => {
    setVkn(e.target.value);
  };
  const onChangeTcNoValue = (e) => {
    setTckn(e.target.value);
  };
  const onChangeSenderNameValue = (e) => {
    setSenderName(e.target.value);
  };
  const openNotificationWithIconWarning = (type) => {
    notification[type]({
      message: "HATA",
      description: "Ekleme işlemi sırasında bir hata oluştu.",
      duration: 2,
    });
  };
  const onFinish = (values) => {
    const words = [];
    inputList.map((item) => {
      words.push(item.word.trim());
    });
    var error = 0;
    words.map((item) => {
      var wordLength = item.length;
      if (wordLength < 5 && wordLength != 0) {
        error += 1;
      }
    });

    axios
      .post(
        `${config.apiUrl}/Firm`,
        {
          Name: values.Name,
          FirmBranchNumber: values.FirmBranchNumber,
          TcNo: values.TcNo,
          ErpSalesCode: values.ErpSalesCode,
          ErpCode: values.ErpCode,
          DirectiveName: values.DirectiveName,
          Email: values.Email,
          TaxOffice: values.TaxOffice,
          Vkn: values.Vkn,
          Phone: values.Phone,
          MobilePhone: values.MobilePhone,
          Fax: values.Fax,
          Address: values.Address,
          GroupCode: values.GroupCode,
          AccountTransactionId:
            props.AccountId === undefined
              ? "00000000-0000-0000-0000-000000000000"
              : props.AccountId,
          FirmTypeValue: values.FirmTypeValue,
          FirmClassId: values.FirmClassId,
        },
        config.headers
      )
      .then((res) => {
        if (res.status == 200) {
          if (error == 0) {
            axios
              .post(
                `${config.apiUrl}/Firm/FirmMatchingWordAdd`,
                {
                  FirmId: res.data.data.id,
                  Words: words,
                },
                config.headers
              )
              .then((res) => {
                if (res.status == 200) {
                  openNotificationWithIcon("success");
                  if (props.AccountId === undefined) {
                    setTimeout(
                      function () {
                        window.location.reload();
                      }.bind(this),
                      2000
                    );
                  }
                }
              })
              .catch((error) => {
                openNotificationWithIcon("success");
                if (
                  error.response.status === 403 ||
                  error.response.status === 401
                ) {
                  history.push("/not-authorized-access");
                } else if (error.response.status === 406) {
                  error.response.data.data.map((item) => {
                    errorList.push(item);
                  });
                  errorListNotification("error");
                  setLoading(false);
                  if (props.AccountId === undefined) {
                    setTimeout(
                      function () {
                        window.location.reload();
                      }.bind(this),
                      3500
                    );
                  }
                } else {
                  openNotificationWithIconWarning("error");
                  setLoading(false);
                  // setTimeout(
                  //   function () {
                  //     window.location.reload();
                  //   }.bind(this),
                  //   3500
                  // );
                }
              });

            if (props.AccountId !== undefined) {
              setFirmAccountVisible(true);
              setFirmId(res.data.data.id);
              //  setTimeout(
              //    function () {
              //      window.location.reload();
              //    }.bind(this),
              //    2000
              //  );
            }
          } else {
            openNotificationWithIcon("success");
            wordLengtError("error");
            setLoading(false);
            setTimeout(
              function () {
                window.location.reload();
              }.bind(this),
              3500
            );
          }
          setWordList(words);
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
          setLoading(false);
        } else {
          openNotificationWithIconWarning("error");
          setLoading(false);
        }
      });
  };
  const excelDownload = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Excel şablonu indirme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });
  };
  const excelDownloadFailed = (type) => {
    notification[type]({
      message: "HATA",
      description: "Excel şablonu indirme işlemi sırasında bir hata oluştu.",
      duration: 2,
    });
  };
  const wordLengtError = (type) => {
    notification[type]({
      message: "HATA",
      description:
        "Kelimeler bölümündeki harf sayısı 5ten küçük olduğu için kayıt başarısız oldu, düzenleyip tekrar deneyin.",
      duration: 4,
    });
  };
  const excelDownloadFailedUntilThis = (type) => {
    notification[type]({
      message: "HATA",
      description: "Bu veriden sonra yüklemeye devam edilemedi.",
      duration: 5,
    });
  };
  const downloadExcel = () => {
    window.open(`${config.apiUrl}/Firm/DownloadExcel?t=${Replace2x(token)}`);
  };
  const propsFirmExcel = {
    name: "excel",
    action: `${config.apiUrl}/Firm/ImportExcel?type=1`,
    headers: {
      Authorization:
        "bearer " + token?.replace('"', "").replace('"', "").toString(),
    },
    onChange(info) {
      const { response, status } = info.file;
      if (info.fileList.length > 0) {
        SetSpinLoading(true);
      }
      if (info.file.status === "done") {
        excelDownload("success");
        setTimeout(
          function () {
            window.location.reload();
          }.bind(this),
          2000
        );
      } else if (info.file.status === "error") {
        if (info.file.response.statusCode === 406) {
          info.file.response.data.map((item) => {
            errorList.push(item);
            setTimeout(function () {
              window.location.reload();
            }, 5000);
          });
          errorListNotification("error");
          excelDownloadFailedUntilThis("error");
          setTimeout(function () {
            window.location.reload();
          }, 5000);
        } else {
          const notificationType = response.success ? "success" : "error";

          notification[notificationType]({
            message: response.message,
            duration: 3,
          });
          if (!response.success) {
            downloadImportResult(info.file.response.data);
          }
          setTimeout(function () {
            window.location.reload();
          }, 5000);
        }

        SetSpinLoading(false);
      }
    },
  };
  const downloadImportResult = (fileName) => {
    window.open(
      `${config.apiUrl}/Firm/DownloadImportResult?t=${Replace2x(
        token
      )}&fileName=${fileName}`
    );
  };
  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };
  return (
    <div>
      <Spin tip="Excel yükleniyor..." spinning={spinLoading}>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          fields={[
            { name: "FirmTypeValue", value: FirmTypeValue.toString() },
            {
              name: "Vkn",
              value: vkn?.toString() === "" ? null : vkn?.toString(),
            },
            { name: "TcNo", value: tckn === "" ? null : tckn },
            { name: "Name", value: senderName === "" ? null : senderName },
          ]}
        >
          <Row>
            {isDesktopOrLaptop && (
              <>
                <Col span={20}>
                  <Form.Item
                    onChange={onChangeSenderNameValue}
                    style={{ marginTop: "4%" }}
                    label="Cari Adı/Ünvanı"
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message:
                          "Lütfen Cari Adı/Ünvanı alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Talimat Ünvanı" name="DirectiveName">
                    <Input />
                  </Form.Item>
                  <Form.Item label="E-Posta Adresi" name="Email">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Vergi Dairesi" name="TaxOffice">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Vergi Numarası" name="Vkn">
                    <Input
                      onChange={onChangeVknValue}
                      type="number"
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="T.C. Kimlik No "
                    name="TcNo"
                    rules={[
                      {
                        min: 11,
                        message: "TcNo 11 karakter olmalıdır.",
                      },
                    ]}
                  >
                    <Input
                      onChange={onChangeTcNoValue}
                      type="number"
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      maxLength="11"
                      onInput={maxLengthCheck}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Telefon Numarası"
                    name="Phone"
                    rules={[
                      {
                        min: 12,
                        message: "Telefon numarasını eksiksiz giriniz.",
                      },
                    ]}
                  >
                    <PhoneInput
                      inputStyle={{ width: "100%" }}
                      country="tr"
                      masks={{ tr: "(...) ...-..-.." }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Cep Numarası"
                    name="MobilePhone"
                    rules={[
                      {
                        min: 12,
                        message: "Telefon numarasını eksiksiz giriniz.",
                      },
                    ]}
                  >
                    <PhoneInput
                      inputStyle={{ width: "100%" }}
                      country="tr"
                      masks={{ tr: "(...) ...-..-.." }}
                    />
                  </Form.Item>
                  <Form.Item label="Fax Numarası" name="Fax">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Adres" name="Address">
                    <TextArea rows={4} />
                  </Form.Item>
                  <Form.Item label="Cari Tipi" name="FirmTypeValue">
                    <Select
                      placeholder="Seçiniz"
                      optionFilterProp="children"
                      onChange={onChangeFirmType}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {firmTypeDropdown.map((item) => (
                        <Option key={item.key}>{item.value}</Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Cari Kodu"
                    name="FirmBranchNumber"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Cari Kodu alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Erp Kodu"
                    name="ErpCode"
                    rules={[
                      {
                        max: 100,
                        message:
                          "Erp Kodu alanı maksimum 100 karakter olmalıdır.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Satıcı Kodu" name="ErpSalesCode">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Cari Sınıfı" name="FirmClassId">
                    <Select
                      showSearch
                      placeholder="Seçiniz"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {firmClassDropdown.map((item) => (
                        <Option key={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Grup Kodu" name="GroupCode">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Kelimeler" name="words">
                    {inputList.map((x, i) => {
                      return (
                        <Row>
                          <Col span={20} style={{ marginLeft: "-6%" }}>
                            <Input
                              name="word"
                              placeholder="Kelime Girin"
                              value={x.word}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </Col>
                          <Col span={2}>
                            {inputList.length !== 1 && (
                              <Button
                                className="mr10"
                                onClick={() => handleRemoveClick(i)}
                              >
                                <MinusOutlined />
                              </Button>
                            )}
                            {inputList.length - 1 === i && (
                              <Button onClick={handleAddClick}>
                                <PlusOutlined />
                              </Button>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
                <Col span={6} style={{ marginLeft: -5 }}>
                  <Upload {...propsFirmExcel}>
                    <Button icon={<UploadOutlined />}>
                      Excel ile Cari Ekle
                    </Button>
                  </Upload>
                </Col>
                <Col span={6}>
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={(() => setLoading(true), downloadExcel)}
                    loading={loading}
                  >
                    Excel Şablonu İndir
                  </Button>
                </Col>
                <Col span={6}></Col>
                <Col span={6}>
                  <Form.Item {...tailLayout}>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      htmlType="submit"
                      onClick={() => setLoading(true)}
                      loading={loading}
                    >
                      Ekle
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
            {isTabletOrMobileDevice && (
              <>
                <Col span={24}>
                  <Form.Item
                    onChange={onChangeSenderNameValue}
                    style={{ marginTop: "4%" }}
                    label="Cari Adı/Ünvanı"
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message:
                          "Lütfen Cari Adı/Ünvanı alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Talimat Ünvanı" name="DirectiveName">
                    <Input />
                  </Form.Item>
                  <Form.Item label="E-Posta Adresi" name="Email">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Vergi Dairesi" name="TaxOffice">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Vergi Numarası" name="Vkn">
                    <Input
                      onChange={onChangeVknValue}
                      type="number"
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="T.C. Kimlik No "
                    name="TcNo"
                    rules={[
                      {
                        min: 11,
                        message: "TcNo 11 karakter olmalıdır.",
                      },
                    ]}
                  >
                    <Input
                      onChange={onChangeTcNoValue}
                      type="number"
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      maxLength="11"
                      onInput={maxLengthCheck}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Telefon Numarası"
                    name="Phone"
                    rules={[
                      {
                        min: 12,
                        message: "Telefon numarasını eksiksiz giriniz.",
                      },
                    ]}
                  >
                    <PhoneInput
                      inputStyle={{ width: "100%" }}
                      country="tr"
                      masks={{ tr: "(...) ...-..-.." }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Cep Numarası"
                    name="MobilePhone"
                    rules={[
                      {
                        min: 12,
                        message: "Telefon numarasını eksiksiz giriniz.",
                      },
                    ]}
                  >
                    <PhoneInput
                      inputStyle={{ width: "100%" }}
                      country="tr"
                      masks={{ tr: "(...) ...-..-.." }}
                    />
                  </Form.Item>
                  <Form.Item label="Fax Numarası" name="Fax">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Adres" name="Address">
                    <TextArea rows={4} />
                  </Form.Item>
                  <Form.Item label="Cari Tipi" name="FirmTypeValue">
                    <Select
                      placeholder="Seçiniz"
                      optionFilterProp="children"
                      onChange={onChangeFirmType}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {firmTypeDropdown.map((item) => (
                        <Option key={item.key}>{item.value}</Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Cari Kodu"
                    name="FirmBranchNumber"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Cari Kodu alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Erp Kodu"
                    name="ErpCode"
                    rules={[
                      {
                        max: 100,
                        message:
                          "Erp Kodu alanı maksimum 100 karakter olmalıdır.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Cari Sınıfı" name="FirmClassId">
                    <Select
                      showSearch
                      placeholder="Seçiniz"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {firmClassDropdown.map((item) => (
                        <Option key={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Grup Kodu" name="GroupCode">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Kelimeler" name="words">
                    {inputList.map((x, i) => {
                      return (
                        <Row>
                          <Col span={20} style={{ marginLeft: "-6%" }}>
                            <Input
                              name="word"
                              placeholder="Kelime Girin"
                              value={x.word}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </Col>
                          <Col span={2}>
                            {inputList.length !== 1 && (
                              <Button
                                className="mr10"
                                onClick={() => handleRemoveClick(i)}
                              >
                                <MinusOutlined />
                              </Button>
                            )}
                            {inputList.length - 1 === i && (
                              <Button onClick={handleAddClick}>
                                <PlusOutlined />
                              </Button>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  </Form.Item>
                </Col>

                <Col span={12}></Col>
                <Col span={6}>
                  <Form.Item {...tailLayout}>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      htmlType="submit"
                      onClick={() => setLoading(true)}
                      loading={loading}
                    >
                      Ekle
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={24}>
                  <Upload {...propsFirmExcel}>
                    <Button icon={<UploadOutlined />}>
                      Excel ile Cari Ekle
                    </Button>
                  </Upload>
                </Col>
                <Col span={24}>
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={(() => setLoading(true), downloadExcel)}
                    loading={loading}
                  >
                    Excel Şablonu İndir
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </Spin>
      <Modal
        title="Firma Hesabı Ekle"
        visible={firmAccountVisible}
        onCancel={handleCancelFirmAccount}
        closable={true}
        footer={[]}
      >
        <FirmAccountUpdate
          firmId={firmId}
          AccountId={props.AccountId}
          bankId={bankCode}
          iban={iban}
          accountNumber={accountNumber}
        />
      </Modal>
    </div>
  );
};
export default FirmAddForm;
