import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Select,
  Collapse,
  InputNumber,
} from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { odata } from "./Helper";

const FirmDbsStatusReportFilter = (props) => {
  const [form] = Form.useForm();

  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onFirmSeach = (value) => {
    if (value.length >= 2) {
      setFirmSelect({ data: [], loading: true });

      RequestHandler.get(
        `/Firm?$select=id,firmBranchNumber,name&$filter=${odata.contains(
          "name",
          value
        )} or ${odata.contains("firmBranchNumber", value)}`
      ).then((res) => {
        setFirmSelect({ data: [{ id: `_${value}`, firmBranchNumber: "", name: `'${value}' içerenleri ara` }].concat(res.value), loading: false });
      });
    } else {
      setFirmSelect({ data: [], loading: false });
    }
  };

  const onFinish = (values) => {
    {
      let query = "";
      const conditions = [];

      if (values.id) {
        if (values.id.startsWith("_")) {
          const searchValue = values.id.slice(1);
          conditions.push(`(${odata.contains("name", searchValue)} or ${odata.contains("code", searchValue)})`);
        } else {
          conditions.push(`id eq ${values.id}`);
        }
      }

      const invoiceCountQuery = odata.ConditionalRange(
        "invoiceCount",
        values.invoiceCountMin,
        values.invoiceCountMax
      );
      if (invoiceCountQuery) conditions.push(invoiceCountQuery);

      const invoiceTotalQuery = odata.ConditionalRange(
        "invoiceTotal",
        values.invoiceTotalMin,
        values.invoiceTotalMax
      );
      if (invoiceTotalQuery) conditions.push(invoiceTotalQuery);

      const limitQuery = odata.ConditionalRange(
        "limit",
        values.limitMin,
        values.limitMax
      );
      if (limitQuery) conditions.push(limitQuery);

      const availabilityQuery = odata.ConditionalRange(
        "availability",
        values.availabilityMin,
        values.availabilityMax
      );
      if (availabilityQuery) conditions.push(availabilityQuery);

      const riskQuery = odata.ConditionalRange(
        "risk",
        values.riskMin,
        values.riskMax
      );
      if (riskQuery) conditions.push(riskQuery);

      if (conditions.length !== 0) {
        query = conditions.join(" and ");
      }

      if (props.onFinishFilter) props.onFinishFilter(query);
    }
  };

  const clearFilter = () => {
    form.resetFields();

    if (props.onClearFilter) {
      props.onClearFilter();
    }
  };

  return (
    <Row id="filterArea" style={{ ...props.style }}>
      <Col span={24}>
        <Collapse>
          <Collapse.Panel header="Filtreleme için tıklayınız.">
            <Form
              name="filter"
              form={form}
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row style={{ flexDirection: "row" }}>

                <Col xs={24} md={12} lg={6}>

                  <Form.Item name="id" label="Cari :" >
                    <Select
                      allowClear
                      showSearch
                      placeholder="En az 2 harf giriniz.."
                      onSearch={onFirmSeach}
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                        option.code
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      loading={firmSelect.loading}
                    >
                      {firmSelect.data.map((item) => (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          code={item.firmBranchNumber}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label="İleri Tarihli Fatura Adedi :"
                  >
                    <Input.Group>
                      <Row gutter={1} style={{ flexDirection: "row" }}>
                        <Col span={12}>
                          <Form.Item name="invoiceCountMin">
                            <InputNumber style={{ width: "100%" }} min={0} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="invoiceCountMax">
                            <InputNumber style={{ width: "100%" }} min={0} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label="İleri Tarihli Fatura Toplamı :"
                  >
                    <Input.Group>
                      <Row gutter={1} style={{ flexDirection: "row" }}>
                        <Col span={12}>
                          <Form.Item name="invoiceTotalMin">
                            <InputNumber style={{ width: "100%" }} min={0} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="invoiceTotalMax">
                            <InputNumber style={{ width: "100%" }} min={0} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label="Tanımlı Limit :"
                  >
                    <Input.Group>
                      <Row gutter={1} style={{ flexDirection: "row" }}>
                        <Col span={12}>
                          <Form.Item name="limitMin">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="limitMax">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label="Kullanılabilir Limit :"
                  >
                    <Input.Group>
                      <Row gutter={1} style={{ flexDirection: "row" }}>
                        <Col span={12}>
                          <Form.Item name="availabilityMin">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="availabilityMax">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item style={{ marginBottom: 0 }} label="Risk :">
                    <Input.Group>
                      <Row gutter={1} style={{ flexDirection: "row" }}>
                        <Col span={12}>
                          <Form.Item name="riskMin">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="riskMax">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ flexDirection: "row" }}>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    danger
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={() => clearFilter()}
                  >
                    Sıfırla
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    icon={<SearchOutlined />}
                    style={{ color: "#40a9ff", borderColor: "#40a9ff" }}
                    htmlType="submit"
                  >
                    Filtrele
                  </Button>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};
export default FirmDbsStatusReportFilter;
