import React, { useState,useEffect } from 'react'
import {Input,Select,Col,Row,Form,notification, Button } from 'antd';
import { PlusOutlined} from '@ant-design/icons';
import ReplaceForI from "../../util/Extensions/IReplaceExtension"
import {useHistory} from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import config from "../../util/ApiforContext";
import axios from 'axios';
const expiration = localStorage.getItem("expiration");
const { Option } = Select;
const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 13,
    },
};
const tailLayout = {
    wrapperCol: {
      offset: 15,
      span: 5,
    },
  };
const openNotificationWithIcon = type => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'Ekleme işlemi başarı ile gerçekleşti.',
        duration: 2,
    });
};
const openNotificationWithIconWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Ekleme işlemi sırasında bir hata oluştu.',
        duration: 2,
    });
};

const FirmClassAddForm = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
     
  const history = useHistory();
  const [loading, setLoading] = useState(false);
      const [firmTypeDropdown, setfirmTypeDropdown] = useState([]);
      const errorList = [];
      useEffect(() => {
        if(expiration<Date.now()){
          localStorage.removeItem("expiration");
          localStorage.removeItem("token");
          window.location.reload();
        }
        if(localStorage.getItem("token")==null){
          window.location.reload()
        }
        axios.get(`${config.apiUrl}/Firm/GetFirmType`,config.headers)
        .then(res => {
          const firmTypeDropdown = res.data.data;
        setfirmTypeDropdown(firmTypeDropdown)
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }else if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
            }
          }
      );
      },[setfirmTypeDropdown]);
      const errorListNotification = type => {
        errorList.map((item)=> {
          notification[type]({
            message: 'Hata',
            description:
            item
             ,
              duration: 2,
          });
        })
      };
    
      const onFinish = values => {
        axios.post(`${config.apiUrl}/FirmClass`,
         { 
          Name:values.Name,
          FirmTypeValue:values.FirmTypeValue,
          ErpValue: values.ErpValue,
          SerialNumber: values.SerialNumber 
        },
         config.headers)
          .then(res => {
           if(res.status==200){
             openNotificationWithIcon('success');
             setTimeout(function () {
                window.location.reload();
              }.bind(this), 2000)
              }
          }).catch(
           error =>  {
                if(error.response.status===403 || error.response.status ===401){
                  history.push('/not-authorized-access');
                }
            else if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
              setLoading(false)
            }
            else {
              openNotificationWithIconWarning('error')
              setLoading(false)
            }
            
          } 
       );
     };
    
     const onFinishFailed = errorInfo => {
      setLoading(false)
     };
        return (
            <div>
                   <Form
                    {...layout}
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    >
                <Row>
                {isDesktopOrLaptop && <> 
                  <Col span={5}></Col>
                  <Col span={13}>
                  <Form.Item
                  label="Cari Sınıfı Adı"
                  name="Name"
                  style={{marginTop:"4%"}}
                  rules={[
                      {
                      required: true,
                      message: 'Lütfen cari sınıfı adı alanını boş bırakmayın.',
                      },
                  ]}
                  >
                  <Input />
                  </Form.Item>  
                  <Form.Item
                      label="Cari Tipi"
                      name="FirmTypeValue"
                      rules={[
                      {
                          required: true,
                          message: 'Lütfen Cari Tipi alanını boş bırakmayın.',
                      },
                      ]}
                  >
                      <Select
                      placeholder="Seçiniz"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                        .toLocaleLowerCase("tr-TR")
                        .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      >
                      {firmTypeDropdown.map(item => (
                      <Option key={item.key}>{item.value}</Option>
                      ))}
                      </Select>
                  </Form.Item> 
                  <Form.Item
                  label="Seri No"
                  name="SerialNumber"
                  style={{marginTop:"4%"}}
                  >
                  <Input maxLength={20} />
                  </Form.Item>  
                  <Form.Item
                  label="Erp Kod"
                  name="ErpValue"
                  style={{marginTop:"4%"}}
                  >
                  <Input maxLength={100} />
                  </Form.Item>  
                  </Col>
                  <Col span={6}></Col>
                  <Col span={6}></Col>
                  <Col span={9}></Col>
                  <Col span={7}>
                <Form.Item {...tailLayout} >
                <Button type="primary" icon={<PlusOutlined />} htmlType="submit"  onClick={() => setLoading(true)} loading={loading}>
                    Ekle
                </Button>
                </Form.Item>
                </Col>
                <Col span={2}></Col>
                </>}
                {isTabletOrMobileDevice && <>
                  <Col span={24}>
                <Form.Item
                label="Cari Sınıfı Adı"
                name="Name"
                style={{marginTop:"4%"}}
                rules={[
                    {
                    required: true,
                    message: 'Lütfen cari sınıfı adı alanını boş bırakmayın.',
                    },
                ]}
                >
                <Input />
                </Form.Item>  
                <Form.Item
                    label="Cari Tipi"
                    name="FirmTypeValue"
                    rules={[
                    {
                        required: true,
                        message: 'Lütfen Cari Tipi alanını boş bırakmayın.',
                    },
                    ]}
                >
                    <Select
                    placeholder="Seçiniz"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                    }
                    >
                    {firmTypeDropdown.map(item => (
                    <Option key={item.key}>{item.value}</Option>
                    ))}
                    </Select>
                </Form.Item> 
                <Form.Item
                  label="Seri No"
                  name="SerialNumber"
                  style={{marginTop:"4%"}}
                  >
                  <Input maxLength={20} />
                  </Form.Item>  
                  <Form.Item
                  label="Erp Kod"
                  name="ErpValue"
                  style={{marginTop:"4%"}}
                  >
                  <Input maxLength={100} />
                  </Form.Item>  
                </Col>
                <Col span={12}></Col>
                <Col span={6}>
                <Form.Item {...tailLayout} >
                <Button type="primary" icon={<PlusOutlined />} htmlType="submit"  onClick={() => setLoading(true)} loading={loading}>
                    Ekle
                </Button>
                </Form.Item>
                </Col>
                <Col span={6}></Col>

                 </>}
              
            </Row>
                </Form>
            </div>
      )
}
export default FirmClassAddForm