import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Input, Row, Form, Col, notification, Button } from 'antd';
import { PlusOutlined, KeyOutlined } from '@ant-design/icons';
import config from "../../util/ApiforContext"
import axios from 'axios'
const expiration = localStorage.getItem("expiration");

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const Change = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const errorList = [];
  const errorListNotification = type => {
    errorList.map((item) => {
      notification[type]({
        message: 'Hata',
        description:
          item
        ,
        duration: 2,
      });
    })
  };
  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload()
    }
    setOldPassword(oldPassword)
    setNewPassword(newPassword)
    setNewPasswordAgain(newPasswordAgain)
  }, [setOldPassword, setNewPassword, setNewPasswordAgain]);
  const onChangeOldPassword = (e) => {
    setOldPassword(e.target.value)
  }
  const onChangeNewPassword = (e) => {
    setNewPassword(e.target.value)
  }
  const onChangeNewPasswordAgain = (e) => {
    setNewPasswordAgain(e.target.value)
  }
  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'Şifre güncelleme işlemi başarı ile gerçekleşti.',
      duration: 2,
    });
  };
  const openNotificationWithIconWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Şifre Güncelleme işlemi sırasında bir hata oluştu.',
      duration: 2,
    });
  };
  const openNotificationOldNewPasswordWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Girdiğiniz şifreler uyuşmuyor.',
      duration: 2,
    });
  };
  const passwordIsNotValid = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Şifreniz en az 6 karakter olmalı ve içerisinde en az bir küçük, bir büyük ve bir rakam içermelidir.',
      duration: 5,
    });
  };
  const onFinish = values => {
    const hata = values.NewPassword !== values.NewPasswordAgain ? "hata" : null;
    if (hata === "hata") {
      openNotificationOldNewPasswordWarning('error');
      setLoading(false)
    } else {
      axios.post(`${config.apiUrl}/Auth/ChangePassword`, {
        OldPassword: values.OldPassword,
        NewPassword: values.NewPassword,
        NewPasswordAgain: values.newPasswordAgain
      },
        config.headers)
        .then(res => {
          if (res.status == 200) {
            openNotificationWithIcon('success');
            setTimeout(function () {
              history.push(`/home`)
            }.bind(this), 2000)
          }
        }).catch(
          error => {
            if (error.response.status === 403 || error.response.status === 401) {
              history.push('/not-authorized-access');
            } else if (error.response.status === 406) {
              error.response.data.data.map((item) => {
                errorList.push(item);
              })
              errorListNotification('error')
              setLoading(false)
            }
            else {

              openNotificationWithIconWarning('error')
              setLoading(false)
            }
          }
        );
    }
  };

  const onFinishFailed = errorInfo => {
    setLoading(false)
  };
  return (
    <div>
      <Row>
        <Col span={24}>
          <p style={{ fontSize: '200%' }}> <KeyOutlined /> ŞİFRE GÜNCELLE</p> <hr></hr><br></br>
        </Col>
      </Row>
      <Form
        {...layout}
        name="basic"
        style={{ width: "100%" }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Eski Şifre"
              name="OldPassword"
              rules={[
                {
                  required: true,
                  message: 'Lütfen eski şifre alanını boş bırakmayın.',
                },
              ]}
            >
              <Input type="password" onKeyDown={(evt) => evt.keyCode === 32 && evt.preventDefault()} value={oldPassword} onChange={onChangeOldPassword} />
            </Form.Item>
            <Form.Item
              label="Yeni Şifre"
              name="NewPassword"
              rules={[
                {
                  required: true,
                  message: 'Lütfen yeni şifre alanını boş bırakmayın.',
                },
              ]}
            >
              <Input type="password" onKeyDown={(evt) => evt.keyCode === 32 && evt.preventDefault()} value={newPassword} onChange={onChangeNewPassword} />
            </Form.Item>
            <Form.Item
              label="Yeni Şifre Tekrar"
              name="NewPasswordAgain"
              rules={[
                {
                  required: true,
                  message: 'Lütfen şifre tekrar alanını boş bırakmayın.',
                },
              ]}
            >
              <Input type="password" onKeyDown={(evt) => evt.keyCode === 32 && evt.preventDefault()} value={newPasswordAgain} onChange={onChangeNewPasswordAgain} />
            </Form.Item>

          </Col>
          <Col span={16}></Col>
          <Col span={8}>
            <Form.Item {...tailLayout} >
              <Button type="primary" icon={<PlusOutlined />} htmlType="submit" onClick={() => setLoading(true)} loading={loading}>
                Güncelle
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
export default Change