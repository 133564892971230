import React, { useEffect, useState } from "react";
import { Card, Col, Row, Switch, Form, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { KeyOutlined, RollbackOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 12,
    },
    sm: {
      span: 12,
    },
  },
  wrapperCol: {
    xs: {
      span: 12,
    },
    sm: {
      span: 12,
    },
  },
};

const UserRoles = (props) => {
  const history = useHistory();
  const [roleList, setRoleList] = useState({ data: [], loading: true });
  const [permissions, setPermissions] = useState({
    modules: [],
    isFullPageAuth: false,
  });

  useEffect(() => {
    RequestHandler.get("/User/MenuPermissions", {
      userId: props.match.params.userId,
    }).then((response) => {
      setPermissions(response);
      RequestHandler.post(
        "/User/RoleList",
        {
          UserId: props.match.params.userId,
        },
        false
      ).then((res) => {
        setRoleList({ data: res.data, loading: false });
      });
    });
  }, []);

  function fullPageAuthOnChange(isChecked) {
    RequestHandler.post("/User/AddRoles", {
      UserId: props.match.params.userId,
      isActive: isChecked,
      IsAll: true,
    }).then((response) => {
      if (response.success) {
        setPermissions({
          ...permissions,
          isFullPageAuth: isChecked,
        });
      }
    });
  }

  function roleSwitchOnChange(isChecked, roleId) {
    const switchDataIndex = roleList.data.findIndex(
      (role) => role.id == roleId
    );

    RequestHandler.post("/User/AddRoles", {
      UserId: props.match.params.userId,
      RoleId: roleId,
      isActive: isChecked,
    }).then((response) => {
      if (response.success) {
        const { data } = roleList;
        data[switchDataIndex].isActive = isChecked;

        setRoleList({
          ...roleList,
          data,
        });
      }
    });
  }

  const renderRoleSwitches = () => {
    const moduleIds = [
      ...new Set(roleList.data.map((item) => item.moduleId)),
    ].filter((moduleId) => permissions.modules.includes(moduleId));

    return (
      <Row justify="center">
        {moduleIds.map((moduleId) => {
          const moduleRoles = roleList.data.filter(
            (role) => role.moduleId == moduleId
          );

          if (moduleRoles.length > 0) {
            return (
              <Col xs={24} sm={12} md={8} key={moduleId}>
                <Card
                  title={moduleRoles[0].moduleName}
                  style={{ margin: "10px 0", height: "90%" }}
                  className="gx-card"
                >
                  {moduleRoles.map((role) => (
                    <Form.Item
                      {...formItemLayout}
                      label={role.name}
                      labelAlign="right"
                      key={role.id}
                    >
                      <Switch
                        disabled={permissions.isFullPageAuth}
                        onChange={(isChecked) =>
                          roleSwitchOnChange(isChecked, role.id)
                        }
                        checked={role.isActive}
                      />
                    </Form.Item>
                  ))}
                </Card>
              </Col>
            );
          }
        })}
      </Row>
    );
  };

  return (
    <div>
      <Row style={{ marginBottom: 9 }}>
        <Col flex={1}>
          <a
            onClick={() => history.goBack()}
            style={{ color: "black", marginLeft: "4%" }}
          >
            {" "}
            <RollbackOutlined /> GERİ DÖN
          </a>
        </Col>
        <Col flex={4}></Col>
      </Row>

      <Row>
        <Col xs={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <KeyOutlined /> EKRAN YETKİLENDİRME
          </p>
        </Col>
        <Col xs={8} style={{ textAlign: "right" }}></Col>
      </Row>

      {roleList.loading ? (
        <Row justify="center">
          <Spin style={{ margin: 5 }} />
        </Row>
      ) : (
        <>
          <Row justify="center">
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                {...formItemLayout}
                label={<b style={{ color: "#1890ff" }}>Tüm İşlemler</b>}
                labelAlign="right"
              >
                <Switch
                  checked={permissions.isFullPageAuth}
                  onChange={fullPageAuthOnChange}
                />
              </Form.Item>
            </Col>
          </Row>

          {renderRoleSwitches()}
        </>
      )}
    </div>
  );
};

export default UserRoles;
