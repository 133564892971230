import React from "react";

const ColumnHelper = {
  renderDecimal: (data) =>
    data.toLocaleString("tr-TR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
};

const RowHelper = {
  renderRowDetail: ({ columns, data }) => {
    return (
      <p>
        {columns.map((column, index) => (
          <p key={index} style={{ marginBottom: ".25em" }}>
            <b>{`${column.title} : `}</b>
            {column.render
              ? column.render(data[column.dataIndex], data, index)
              : data[column.dataIndex]}
          </p>
        ))}
      </p>
    );
  },
};

export { ColumnHelper, RowHelper };
