import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Select,
  Upload,
  notification,
} from "antd";
import {
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";
import { odata } from "../Filters/Helper";
import config from "../../util/ApiforContext";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const DbsDealerAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: false,
  });
  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getBanks();
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const getBanks = () => {
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    RequestHandler.get(`/DbsBankInfo/AddedBanks?$select=id,name`).then(
      (response) => {
        setBankSelect({
          data: response,
          loading: false,
        });
      }
    );
  };

  const onFirmSeach = (value) => {
    if (value.length >= 2) {
      setFirmSelect({ ...firmSelect, loading: true });

      RequestHandler.get(
        `/Firm?$select=id,firmBranchNumber,name&$filter=${odata.contains(
          "name",
          value
        )} or ${odata.contains("firmBranchNumber", value)}`
      ).then((res) => {
        setFirmSelect({ data: res.value, loading: false });
      });
    } else {
      setFirmSelect({ data: [], loading: false });
    }
  };

  const onSuccess = () => {
    form.resetFields();
    setFileList([]);

    if (props.onSuccess) props.onSuccess();
  };

  const onFinish = (values) => {
    RequestHandler.post(`/DbsDealer`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        onSuccess();
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  const downloadExcelTemplate = () => {
    window.open(
      `${
        config.apiUrl
      }/DbsDealer/DownloadExcelTemplate?t=${RequestHandler.getToken()}`
    );
  };

  const downloadImportResult = (fileName) => {
    window.open(
      `${
        config.apiUrl
      }/DbsDealer/DownloadImportResult?t=${RequestHandler.getToken()}&fileName=${fileName}`
    );
  };

  const uploadExcelProps = {
    name: "excel",
    action: `${config.apiUrl}/DbsDealer/ImportExcel`,
    headers: {
      Authorization: "bearer " + RequestHandler.getToken(),
    },
    accept: ".xlsx",
    maxCount: 1,
    onChange(info) {
      setFileList(info.fileList);
      const { response, status } = info.file;

      if (status !== "error" && status !== "done") return;
      if (!response) return;

      const notificationType = response.success ? "success" : "error";

      notification[notificationType]({
        message: response.message,
        duration: 3,
      });
      if (!response.success) {
        downloadImportResult(info.file.response.data);
      }
      onSuccess();
    },
  };
  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="dbsDealerAdd"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="bankId" label="Banka" rules={[{ required: true }]}>
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              loading={bankSelect.loading}
            >
              {bankSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Bayi Adı" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Bayi Kodu" name="code" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Cari" name="firmId">
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              onSearch={onFirmSeach}
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                option.code
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              loading={firmSelect.loading}
            >
              {firmSelect.data.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                  code={item.firmBranchNumber}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Row justify="space-between">
            <Col>
              <Button
                icon={<DownloadOutlined />}
                onClick={(() => setLoading(true), downloadExcelTemplate)}
                loading={loading}
              >
                Excel Şablonu İndir
              </Button>
              <Upload {...uploadExcelProps} fileList={fileList}>
                <Button icon={<UploadOutlined />}>Excel ile Ekle</Button>
              </Upload>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                htmlType="submit"
                onClick={() => setLoading(true)}
                loading={loading}
              >
                Ekle
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default DbsDealerAddForm;
