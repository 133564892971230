import React,{useEffect,useState} from "react";
import { useHistory } from 'react-router-dom';
import {Link} from 'react-router-dom'
import { Table,Empty, Input, Button, notification,Drawer,Select,Form,Row,Col,Collapse,Tooltip,Tag } from 'antd';
import { SearchOutlined,BankOutlined,CloseOutlined,MenuUnfoldOutlined,EditOutlined,CheckOutlined } from '@ant-design/icons';
import TenantAccountUpdateForm from "../Forms/TenantAccountUpdateForm";
import config from "../../util/ApiforContext"
import ReplaceForI from "../../util/Extensions/IReplaceExtension"
import moment from 'moment'
import axios from 'axios';
import { List, message, Avatar, Spin } from 'antd';
import { useMediaQuery } from 'react-responsive'
const { Panel } = Collapse;
const expiration = localStorage.getItem("expiration");
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};
const BankPage = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1230px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const history = useHistory();
  const [tenantAccounts, setTenantAccounts] = useState([{ data: [] , total: 0 }]);
  const [dataState, setDataState] = useState([{ skip: 0 , take: 10 }]);
  const [tenantList, setTenantList] = useState([]);
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [query, setQuery] = useState('');
 
  const errorList = [];
    const errorListNotification = type => {
      errorList.map((item)=> {
        notification[type]({
          message: 'Hata',
          description:
          item
           ,
            duration: 2,
        });
      })
    };
  useEffect(() => {
    if(expiration<Date.now()){
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if(localStorage.getItem("token")==null){
      window.location.reload()
    }
    axios.get(`${config.apiUrl}/TenantAccount?$filter=isCalculated eq true &$count=true&$skip=${skip}&$top=${take}`,config.headers)
    .then(res => {
      setTenantAccounts({
        data:res.data.value,
        total:res.data['@odata.count']
      })
      setTenantList(res.data.value)
    }).catch(
      error =>  {
        if(error.response.status===403 || error.response.status ===401){
          setTenantList([])
        }else if(error.response.status === 406){
          error.response.data.data.map((item)=>{
            errorList.push(item);
          })
          errorListNotification('error')
        }
      }
  );
   
  },[query,setDataState,skip,setSkip]);
    return (
   isTabletOrMobile ? 
        tenantList.map((item)=>{
          if(item.bankId === 999){
            return  <Link to={`/bank-accounts-account-transaction/${item.id}`}> <div className="gx-media gx-featured-item"style={{borderBottom:'solid 1px #e8e8e8',paddingBottom:11,marginBottom:16,backgroundImage: `url(${require(`assets/images/BankCarts/${item.bankId}.png`)})`,backgroundRepeat: 'no-repeat',backgroundSize:'cover'}} >
            <div >
              <img  style={{width:150}} src={item.bankLogo} alt="paratic"/> 
            </div>
            
            <div className="gx-media-body gx-featured-content">
              <div className="gx-featured-content-left">
                <p className="gx-rounded-xs gx-text-uppercase" style={{color:'black',fontSize:12}}>{item.currency}</p>  
                <h3 className="gx-mb-2"></h3>
                <div className="ant-row-flex">
                  <div className="gx-media gx-text-grey gx-mb-1">
                    <i className={`icon icon-datepicker gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}/>
                    <span className="gx-media-body gx-ml-1" style={{fontSize:12,width:203}}>Son sorgulanma: {moment(item.updateDate).format("L")} {moment(item.updateDate).format("LT")}</span>
                  </div>
                </div>
              </div>
              <div className="gx-featured-content-right gx-profile-content-right">
                <h4 className="gx-text-primary gx-mb-1">
                <span
                  className="gx-d-inline-flex gx-vertical-align-middle" >
                      {item.balanceString.includes('-') ? <p style={{color:"red",fontSize:11,width:72}}>{item.balanceString}</p> :
                <p style={{color:"green",fontSize:11,width:72}}>{item.balanceString}</p>}
                      </span>
                </h4>
              </div>
            </div>
          </div></Link>
          }else{
            return  <Link to={`/bank-accounts-account-transaction/${item.id}`}> <div className="gx-media gx-featured-item" style={{borderBottom:'solid 1px #e8e8e8',paddingBottom:11,marginBottom:16,backgroundImage: `url(${require(`assets/images/BankCarts/${item.bankId}.png`)})`,backgroundRepeat: 'no-repeat',backgroundSize:'cover'}}>
            <div >
              <img  style={{width:150}} src={item.bankLogo} alt="paratic"/> 
            </div>
            <div className="gx-media-body gx-featured-content">
              <div className="gx-featured-content-left" style={{color:'black',fontSize:12}}>
                {item.iban === null ? item.accountNumber : item.iban} / {item.currency}
                <h3 className="gx-mb-2"></h3>
                <div className="ant-row-flex">
                  <div className="gx-media gx-text-grey gx-mb-1">
                    <i className={`icon icon-datepicker gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}/>
                    <span className="gx-media-body gx-ml-1" style={{fontSize:12,width:203}}>Son sorgulanma: {moment(item.updateDate).format("L")} {moment(item.updateDate).format("LT")}</span>
                  </div>
                </div>
              </div>
              <div className="gx-featured-content-right gx-profile-content-right">
                <h4 className="gx-text-primary gx-mb-1">
                  <span
                  className="gx-d-inline-flex gx-vertical-align-middle" >
                      {item.balanceString.includes('-') ? <p style={{color:"red",fontSize:11,width:72}}>{item.balanceString}</p> :
                <p style={{color:"green",fontSize:11,width:72}}>{item.balanceString}</p>}
                      </span>
                </h4>
              </div>
            </div>
          </div></Link>
          }
         
        })
        :
        tenantList.map((item)=>{
          if(item.bankId === 999){
            return  <Link to={`/bank-accounts-account-transaction/${item.id}`}> <div className="gx-media gx-featured-item" style={{borderBottom:'solid 1px #e8e8e8',paddingBottom:11,marginBottom:16,backgroundImage: `url(${require(`assets/images/BankCarts/${item.bankId}.png`)})`,backgroundRepeat: 'no-repeat',backgroundSize:'cover'}}>
            <div className="gx-featured-thumb">
              <img className="gx-rounded-lg" style={{width:110}} src={item.bankLogo} alt="paratic"/> 
            </div>
            <div className="gx-media-body gx-featured-content">
              <div className="gx-featured-content-left">
                <p className="gx-rounded-xs gx-text-uppercase">{item.currency}</p>  
                <h3 className="gx-mb-2"></h3>
                <div className="ant-row-flex">
                  <div className="gx-media gx-text-grey gx-mb-1">
                    <i className={`icon icon-datepicker gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}/>
                    <span className="gx-media-body gx-ml-1">Son sorgulanma: {moment(item.updateDate).format("L")} {moment(item.updateDate).format("LT")}</span>
                  </div>
                </div>
              </div>
              <div className="gx-featured-content-right gx-profile-content-right">
                <h4 className="gx-text-primary gx-mb-1">
                <span
                  className="gx-d-inline-flex gx-vertical-align-middle" >
                      {item.balanceString.includes('-') ? <p style={{color:"red"}}>{item.balanceString}</p> :
                <p style={{color:"green"}}>{item.balanceString}</p>}
                      </span>
                </h4>
              </div>
            </div>
          </div></Link>
          }else{
            return  <Link to={`/bank-accounts-account-transaction/${item.id}`}> <div className="gx-media gx-featured-item" style={{borderBottom:'solid 1px #e8e8e8',paddingBottom:11,marginBottom:16,backgroundImage: `url(${require(`assets/images/BankCarts/${item.bankId}.png`)})`,backgroundRepeat: 'no-repeat',backgroundSize:'cover'}}>
            <div className="gx-featured-thumb">
              <img className="gx-rounded-lg" style={{width:110}} src={item.bankLogo} alt="paratic"/> 
            </div>
            <div className="gx-media-body gx-featured-content">
              <div className="gx-featured-content-left" style={{color:'black'}}>
                {item.iban === null ? item.accountNumber : item.iban} / {item.currency}
                <h3 className="gx-mb-2"></h3>
                <div className="ant-row-flex">
                  <div className="gx-media gx-text-grey gx-mb-1">
                    <i className={`icon icon-datepicker gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}/>
                    <span className="gx-media-body gx-ml-1">Son sorgulanma: {moment(item.updateDate).format("L")} {moment(item.updateDate).format("LT")}</span>
                  </div>
                </div>
              </div>
              <div className="gx-featured-content-right gx-profile-content-right">
                <h4 className="gx-text-primary gx-mb-1">
                  <span
                  className="gx-d-inline-flex gx-vertical-align-middle" >
                      {item.balanceString.includes('-') ? <p style={{color:"red"}}>{item.balanceString}</p> :
                <p style={{color:"green"}}>{item.balanceString}</p>}
                      </span>
                </h4>
              </div>
            </div>
          </div></Link>
          }
         
        }))
}
export default BankPage
