import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import CurrencyFormat from 'react-currency-format';
import {Card} from "antd";
function currencyFormat(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    if(payload!= null){
    return (
      <div className="custom-tooltip" style={{backgroundColor:"white",width:"150%", height:"150%"}}>
        <p className="label" style={{color:"orange",marginTop:"5%",marginLeft:"5%"}} >{`${label}`}</p>
        <p className="label" style={{marginTop:"5%",marginLeft:"5%"}} >{<CurrencyFormat value={payload[0].value.toString().includes(".") ? payload[0].value : payload[0].value + "0,00"} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} />}</p>
      </div>
    );
  }}

  return null;
};
const PositiveAndNegativeBarChart = (props) => {
  const {chartValues} = props;
  try {
    chartValues.map(item =>{
      item.monthlyBalance = item.monthlyBalance;
    })
  } catch (error) {
  }
  const data = chartValues;
return(
     <Card
            style={{width:"90%", marginLeft:"4%", marginTop:"1%"}}
            className={`gx-card-widget ant-col `}>
        <h2 style={{textAlign:"center"}} >TAHMİNİ AYLIK BAKİYE GRAFİĞİ</h2><br/>
      <ResponsiveContainer width="95%" height={200}>
        <BarChart data={data}
                  margin={{top: 10, right: 0, left: 1, bottom: 0}}>
          <XAxis dataKey="name"/>
          <YAxis/>
          <CartesianGrid strokeDasharray="3 3"/>
          <Tooltip  content={<CustomTooltip />}/>
          <Legend/>
          <ReferenceLine y={0} stroke='#000'/>
          <Bar dataKey="monthlyBalance" name="Aylık Bakiye" fill="#87cefa" />
        </BarChart>
      </ResponsiveContainer>
      </Card>

)
  
};

export default PositiveAndNegativeBarChart;
