import React, { useState, useEffect } from "react";
import { Col, Row, Form, Select, Input, Button } from "antd";

import { PlusOutlined } from "@ant-design/icons";
import RequestHandler from "../../util/RequestHandler";
import { defaultValidateMessages } from "./Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const { Option } = Select;

const TransactionBankInfoAccountAddForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [bankBranchDropdown, setbankBranchDropdown] = useState({
    data: [],
    loading: true,
  });
  const [currencyDropdown, setCurrencyDropdown] = useState({
    data: [],
    loading: true,
  });
  const [bankRequirements, setBankRequirements] = useState({});

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    getBankBranchDropdownData();
    getCurrencyDropdownData();
    getBankInfoRequirements(props.data.bankId);
  }, []);

  const getBankInfoRequirements = (bankId) => {
    RequestHandler.get("/Bank/GetBankColumnsByBankId/" + bankId).then(
      (response) => {
        setBankRequirements({
          isRequiredAccountInfo: response.isRequiredAccountInfo,
          ...response.bankInfoRequiredColumns,
          ...response.bankInfoAccountRequiredColumns,
        });
      }
    );
  };

  const getBankBranchDropdownData = () => {
    setbankBranchDropdown({
      ...bankBranchDropdown,
      loading: true,
    });

    RequestHandler.get(
      `/BankBranch/GetBranchForBank?id=${props.data.bankId}`
    ).then((response) => {
      setbankBranchDropdown({
        data: response,
        loading: false,
      });
    });
  };

  const getCurrencyDropdownData = () => {
    setCurrencyDropdown({
      ...currencyDropdown,
      loading: true,
    });

    RequestHandler.get(`/Currency`).then((response) => {
      setCurrencyDropdown({
        data: response.value,
        loading: false,
      });
    });
  };

  const onFinish = (values) => {
    RequestHandler.post(
      `/TransactionBankInfoAccount/AddWithBankCheck`,
      values
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        form.resetFields();

        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  return (
    <>
      <Form
        {...formItemLayout}
        validateMessages={defaultValidateMessages}
        form={form}
        name="addForm"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col span={24}>
            <Form.Item name="transactionBankInfoId" hidden>
              <Input />
            </Form.Item>

            {bankRequirements.isBankBranchIdRequired && (
              <Form.Item
                label="Şube Kodu"
                name="bankBranchId"
                rules={[
                  {
                    required: true,
                    message: "Lütfen şube kodu alanını boş bırakmayın.",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Şube seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                  }
                  loading={bankBranchDropdown.loading}
                >
                  {bankBranchDropdown.data.map((item) => (
                    <Option key={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {bankRequirements.isAccountNumberRequired && (
              <Form.Item
                label="Hesap Numarası"
                name="accountNumber"
                rules={[
                  {
                    required: true,
                    message: "Lütfen hesap numarası alanını boş bırakmayın.",
                  },
                ]}
              >
                <Input maxLength="17" />
              </Form.Item>
            )}

            {bankRequirements.isIbanRequired && (
              <Form.Item
                label="Iban/Hesap No"
                name="iban"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Iban/Hesap No alanını boş bırakmayın.",
                  },
                ]}
              >
                <Input type="text" maxLength="26" />
              </Form.Item>
            )}

            {bankRequirements.isAccountSuffixRequired && (
              <Form.Item
                label="Hesap Ek Numarası"
                name="accountSuffix"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Hesap Ek Numarası alanını boş bırakmayın.",
                  },
                ]}
              >
                <Input maxLength="4" />
              </Form.Item>
            )}

            {bankRequirements.isCurrencyIdRequired && (
              <Form.Item
                label="Döviz Kodu"
                name="currencyId"
                rules={[
                  {
                    required: true,
                    message: "Lütfen döviz kodu alanını boş bırakmayın.",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Para birimini seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLocaleLowerCase("tr-TR")
                      .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                  }
                  loading={currencyDropdown.loading}
                >
                  {currencyDropdown.data.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.code + " (" + item.symbol + ")"}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Col style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                htmlType="submit"
                onClick={() => setLoading(true)}
                loading={loading}
              >
                Ekle
              </Button>
            </Col>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default TransactionBankInfoAccountAddForm;
