import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Form,
  message,
  Input,
  Button,
  notification,
  Modal
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import config from "../../util/ApiforContext";
import Screen from "../../util/Extensions/Screens";
import SweetAlert from "react-bootstrap-sweetalert";
import RequestHandler from "../../util/RequestHandler";
import { defaultValidateMessages } from "./Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const { Option } = Select;

const TransactionBankInfoAddForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [pdfAlertVisible, setPdfAlertVisible] = useState(false);
  const [bankDropdown, setbankDropdown] = useState({
    data: [],
    loading: true,
  });
  const [bankBranchDropdown, setbankBranchDropdown] = useState({
    data: [],
    loading: true,
  });
  const [currencyDropdown, setCurrencyDropdown] = useState({
    data: [],
    loading: true,
  });
  const [activeBankId, setActiveBankId] = useState(0);

  const [bankRequirements, setBankRequirements] = useState({});

  useEffect(() => {
    getBankDropdownData();
    getCurrencyDropdownData();
  }, []);

  useEffect(() => {
    if(props.demoInformation){
      welcome();
    }
    if (
      bankRequirements.isRequiredAccountInfo &&
      bankRequirements.isBankBranchIdRequired
    ) {
      getBankBranchDropdownData();
    }
  }, [bankRequirements]);

  const onFinish = (values) => {
    RequestHandler.post(`/TransactionBankInfo/AddWithBankCheck`, values).then(
      (res) => {
        setLoading(false);
        if (res.success) {
          form.resetFields();
          getBankDropdownData();

          if (props.onSuccess) props.onSuccess();
        }
      }
    );
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  const onCancelPdfAlert = () => {
    setPdfAlertVisible(false);
  };
  const onConfirmPdfAlert = () => {
    const hide = message.loading("Pdf indiriliyor...", 0);
    setTimeout(hide, 2500);

    const pdfEndPoint = `/Pdf/DownloadPdfByBankId?id=${activeBankId}&screenOption=${
      Screen.screen
    }&t=${RequestHandler.getToken()}`;

    RequestHandler.get(pdfEndPoint).then((res) => {
      window.open(config.apiUrl + pdfEndPoint);
      setPdfAlertVisible(false);
      notifyPdfDownloadSuccess();
    });
  };

  const notifyPdfDownloadSuccess = () => {
    notification.success({
      message: "BAŞARILI",
      description: "Pdf indirme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });
  };

  const onActiveBankChange = (bankId) => {
    setActiveBankId(bankId);
    RequestHandler.get("/Bank/GetBankColumnsByBankId/" + bankId).then(
      (response) => {
        setBankRequirements({
          isRequiredAccountInfo: response.isRequiredAccountInfo,
          ...response.bankInfoRequiredColumns,
          ...response.bankInfoAccountRequiredColumns,
        });
      }
    );
    setPdfAlertVisible(true);
  };

  const getBankDropdownData = () => {
    setbankDropdown({
      ...bankDropdown,
      loading: true,
    });

    RequestHandler.get(`/Bank/GetBankDropdown`).then((response) => {
      setbankDropdown({
        data: response.filter((x) => x.id !== 999),
        loading: false,
      });
    });
  };
  function welcome() {
    Modal.confirm({
      title: 'Merhaba',
      content: 'Banka hesabı tanımlaması yaptığınız takdirde demo kayıtlarınız silinecektir.',
      okText: 'Anladım',
      keyboard: false,
      cancelButtonProps: {hidden: true}
    });
  }
  const getBankBranchDropdownData = () => {
    setbankBranchDropdown({
      ...bankBranchDropdown,
      loading: true,
    });

    RequestHandler.get(`/BankBranch/GetBranchForBank?id=${activeBankId}`).then(
      (response) => {
        setbankBranchDropdown({
          data: response,
          loading: false,
        });
      }
    );
  };

  const getCurrencyDropdownData = () => {
    setCurrencyDropdown({
      ...currencyDropdown,
      loading: true,
    });

    RequestHandler.get(`/Currency`).then((response) => {
      setCurrencyDropdown({
        data: response.value,
        loading: false,
      });
    });
  };

  return (
    <>
      <Form
        {...formItemLayout}
        validateMessages={defaultValidateMessages}
        form={form}
        name="addForm"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Banka"
              name="bankId"
              rules={[
                {
                  required: true,
                  message: "Lütfen banka alanını boş bırakmayın.",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Banka Seçiniz"
                optionFilterProp="children"
                onChange={onActiveBankChange}
                filterOption={(input, option) =>
                  option.props.children
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
                loading={bankDropdown.loading}
              >
                {bankDropdown.data.map((item) => (
                  <Option key={item.id}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>

            {bankRequirements.isUserNameRequired && (
              <Form.Item
                label="Kullanıcı Adı"
                name="userName"
                rules={[
                  {
                    required: true,
                    message: "Lütfen kullanıcı adı alanını boş bırakmayın.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            {bankRequirements.isUserCodeRequired && (
              <Form.Item
                label="Kullanıcı Kodu"
                name="userCode"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Kullanıcı Kodu alanını boş bırakmayın.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            {bankRequirements.isPasswordRequired && (
              <Form.Item
                label="Şifre"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Şifre alanını boş bırakmayın.",
                  },
                ]}
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>
            )}

            {bankRequirements.isCustomerNumberRequired && (
              <Form.Item
                label="Müşteri Numarası"
                name="customerNumber"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Müşteri Numarası alanını boş bırakmayın.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            {bankRequirements.isCorporateCodeRequired && (
              <Form.Item
                label="Kurum Kodu"
                name="corporateCode"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Kurum Kodu alanını boş bırakmayın.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            {bankRequirements.isServiceUrlRequired && (
              <Form.Item
                label="Url"
                name="serviceUrl"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Url alanını boş bırakmayın.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            {bankRequirements.isFirmNameRequired && (
              <Form.Item
                label="Firma Adı"
                name="firmName"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Firma Adı alanını boş bırakmayın.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            {bankRequirements.isFirmKeyRequired && (
              <Form.Item
                label="Firma Anahtarı"
                name="firmKey"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Firma Anahtarı alanını boş bırakmayın.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            {bankRequirements.isRequiredAccountInfo ? (
              <>
                {bankRequirements.isBankBranchIdRequired && (
                  <Form.Item
                    label="Şube Kodu"
                    name="bankBranchId"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen şube kodu alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Şube seçiniz"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      loading={bankBranchDropdown.loading}
                    >
                      {bankBranchDropdown.data.map((item) => (
                        <Option key={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {bankRequirements.isAccountNumberRequired && (
                  <Form.Item
                    label="Hesap Numarası"
                    name="accountNumber"
                    rules={[
                      {
                        required: true,
                        message:
                          "Lütfen hesap numarası alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input maxLength="17" />
                  </Form.Item>
                )}

                {bankRequirements.isIbanRequired && (
                  <Form.Item
                    label="Iban/Hesap No"
                    name="iban"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Iban/Hesap No alanını boş bırakmayın.",
                      },
                    ]}
                  >
                     <Input type="text" maxLength="26" />
                  </Form.Item>
                )}

                {bankRequirements.isAccountSuffixRequired && (
                  <Form.Item
                    label="Hesap Ek Numarası"
                    name="accountSuffix"
                    rules={[
                      {
                        required: true,
                        message:
                          "Lütfen Hesap Ek Numarası alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Input maxLength="4" />
                  </Form.Item>
                )}

                {bankRequirements.isCurrencyIdRequired && (
                  <Form.Item
                    label="Döviz Kodu"
                    name="currencyId"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen döviz kodu alanını boş bırakmayın.",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Para birimini seçiniz"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      loading={currencyDropdown.loading}
                    >
                      {currencyDropdown.data.map((item) => (
                        <Option key={item.id}>
                          {item.code + " (" + item.symbol + ")"}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </>
            ) : null}

            <Col style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                htmlType="submit"
                onClick={() => setLoading(true)}
                loading={loading}
              >
                Ekle
              </Button>
            </Col>
          </Col>
        </Row>
      </Form>
      <SweetAlert
        show={pdfAlertVisible}
        showCancel
        confirmBtnText={"Hayır"}
        cancelBtnText={"Evet"}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title={"Uyarı"}
        onConfirm={onConfirmPdfAlert}
        onCancel={onCancelPdfAlert}
      >
        Erişim Bilgilerinizi Bankanızdan Aldınız mı?
      </SweetAlert>
    </>
  );
};
export default TransactionBankInfoAddForm;
