import React, { useState, useEffect } from "react";

import RequestHandler from "util/RequestHandler";
import { ColumnHelper } from "util/TableHelper";

import TosPaymentTableFilter from "../Filters/TosPaymentTableFilter";
import TosPaymentAddForm from "../Forms/TosPaymentAddForm";
import TosPaymentUpdateForm from "../Forms/TosPaymentUpdateForm";
import TosCreateOrderForm from "../Forms/TosCreateOrderForm";

import moment from "moment";

import {
  Table,
  Empty,
  Col,
  Row,
  Button,
  Drawer,
  Popconfirm,
  Modal,
} from "antd";
import {
  BarsOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
} from "@ant-design/icons";

const TosPayment = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "&$filter=statusId eq 1",
  });
  const [addPaymentVisibility, setAddPaymentVisibility] = useState(false);
  const [updatePaymentVisibility, setUpdatePaymentVisibility] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({});
  const [selectedRows, setSelectedRows] = useState({
    keys: [],
    data: [],
  });
  const [createOrderModalVisible, setCreateOrderModalVisible] = useState(false);

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  const getTableData = () => {
    const { skip, take, filter } = tableDataQueryOptions;

    setTableData({
      ...tableData,
      loading: true,
    });
     RequestHandler.get(
       `/Payment?$count=true${filter}&$skip=${skip}&$top=${take}`
     ).then((response) => {
         setTableData({
           data: response.value,
           total: response.length,
           loading: false
         });
     });

    setTableData({ data: tableData.data, total: tableData.data.length, loading: false });
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "&$filter=statusId eq 1",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };

  const handleOnClickUpdate = (record) => {
      RequestHandler.get(
        `/payment?$select=id,title,accountNumber,iban,bankId,bankBranchId,currencyId,paymentDate,description&$filter=id eq ${record.id}`
      ).then((response) => {
        const [tenant] = response.value;
        if (tenant) {
          setUpdateFormData(tenant);
          setUpdatePaymentVisibility(true);
        }
      });
     setUpdateFormData(record);
     setUpdatePaymentVisibility(true);
  };

  const handleOnClickDelete = (id) => {
     RequestHandler.delete(`/payment?id=${id}`).then((response) => {
       if (response.success) {
         getTableData();
       }
     });
  };

  const onAddPaymentSuccess = () => {
    getTableData();
    setAddPaymentVisibility(false);
  };

  const onUpdatePaymentSuccess = () => {
    getTableData();
    setUpdatePaymentVisibility(false);
  };

  const onTableSelectChange = (selectedKeys, selectedRecords) => {
    setSelectedRows({
      keys: selectedKeys,
      data: selectedRecords,
    });
  };

  const onClickCreateOrder = () => {
    setCreateOrderModalVisible(true);
  };

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      width: "8%",
      render: (text) => <img src={text}></img>,
    },
    {
      title: "Ad Soyad / Ünvan",
      align:'center',
      dataIndex: "title",
    },
    {
      title: "Iban",
      align:'center',
      dataIndex: "iban",
    },
    {
      title: "Tutar",
      align:'center',
      dataIndex: "amount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Para Birimi",
      align:'center',
      dataIndex: "currency",
      render: (data, row) => (
        <>
          TRY
        </>
      ),
    },
    {
      title: "Talimat Numarası",
      align:'center',
      dataIndex: "referanceNumber",
    },
    {
      title: "Ödeme Durumu",
      align:'center',
      dataIndex: "status",
    },
    {
      title: "Durum Mesajı",
      dataIndex: "statusMessage",
    },
    {
      title: "Ödeme Tarihi",
      align:'center',
      dataIndex: "paymentDate",
      render: (data, row, index) => moment(data).format("DD.MM.YYYY"),
    },
    {
      title: "Açıklama",
      dataIndex: "description",
    },
    {
      title: "Düzenle",
      dataIndex: "id",
      align: "center",
      render: (data, row) => (
        <a onClick={() => handleOnClickUpdate(row)}>
          <EditOutlined style={{ color: "orange", fontSize: "130%" }} />
        </a>
      ),
    },
    {
      title: "Sil",
      dataIndex: "id",
      align: "center",
      render: (data, record) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleOnClickDelete(data)}
        >
          <a>
            <DeleteOutlined style={{ color: "red", fontSize: "130%" }} />
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> ÖDEME LİSTESİ
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
            <Button disabled={selectedRows.keys.length > 0 ? false : true} onClick={onClickCreateOrder} style={{ marginBottom: 0 }}>
              <SendOutlined /> Talimat Oluştur
            </Button>
          <Button
            type="primary"
            onClick={() => {
              setAddPaymentVisibility(true);
            }}
            style={{ marginBottom: 0 }}
          >
            <PlusOutlined /> Ödeme Ekle
          </Button>
        </Col>
      </Row>

      <TosPaymentTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            rowSelection={{
              selectedRowKeys: selectedRows.keys,
              onChange: onTableSelectChange,
            }}
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Drawer
        id="addPaymentDrawer"
        title="Ödeme Ekle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setAddPaymentVisibility(false)}
        visible={addPaymentVisibility}
      >
        <TosPaymentAddForm onSuccess={onAddPaymentSuccess} />
      </Drawer>

      <Drawer
        id="updatePaymentDrawer"
        title="Ödeme Güncelle"
        bodyStyle={{ padding: 24 }}
        width={500}
        onClose={() => setUpdatePaymentVisibility(false)}
        visible={updatePaymentVisibility}
      >
        <TosPaymentUpdateForm
          data={updateFormData}
          onSuccess={onUpdatePaymentSuccess}
        />
      </Drawer>

      <Modal
        id="createOrderModal"
        centered
        visible={createOrderModalVisible}
        onOk={() => setCreateOrderModalVisible(false)}
        onCancel={() => setCreateOrderModalVisible(false)}
        width={800}
        footer={null}
      >
        <TosCreateOrderForm
          selectedPayments={selectedRows.data}
          data={{ paymentIds: selectedRows.keys }}
        />
      </Modal>
    </>
  );
};

export default TosPayment;
