import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Empty, notification, Table } from "antd";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import config from "../../util/ApiforContext";
import moment from "moment";
const expiration = localStorage.getItem("expiration");

const SubscriberUserHistory = (props) => {
  const [tenantSubscriberUsers, setTenantSubscriberUsers] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [homeOwnersUsers, setHomeOwnerUsers] = useState({
    data: [],
  });
  const [hirerUsers, setHirerUsers] = useState({
    data: [],
  });
  const [firmId, setFirmId] = useState(props.firmId.toUpperCase());
  const errorList = [];
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 2,
      });
    });
  };
  const history = useHistory();

  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }
    setTenantSubscriberUsers({
      ...tenantSubscriberUsers,
      loading: true,
    });

    axios
      .get(`${config.apiUrl}/SubscriberFirm/GetById/${firmId}`, config.headers)
      .then((res) => {
        setTenantSubscriberUsers({
          data: res.data[0].tenantSubscriberUsersVm,
          total: res.data["@odata.count"],
          loading: false,
        });
        setHomeOwnerUsers({
          data: res.data[0].tenantSubscriberUsersVm.filter(
            (item) => item.isHirer === false
          ),
        });
        setHirerUsers({
          data: res.data[0].tenantSubscriberUsersVm.filter(
            (item) => item.isHirer === true
          ),
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  }, [setFirmId]);

  const dataStateChange = (e) => {
    setTenantSubscriberUsers(e);
  };

  const homeOwnersTableColumns = [
    {
      title: "Ev Sahibi Bilgileri",
      dataIndex: "",
      key: "names",
      align: "left",
      render: (data) => {
        return data != Empty ? data.subscriberUserVm.name : "";
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "Datetimes",
      align: "left",
      render: (data) =>
        moment(data.addDate).format("DD.MM.YYYY") +
        " - " +
        (data.updateDate != null
          ? moment(data.updateDate).format("DD.MM.YYYY")
          : ""),
    },
  ];

  const hirerTableColumns = [
    {
      title: "Kiracı Bilgileri",
      dataIndex: "",
      key: "names",
      align: "left",
      render: (data) => {
        return data != Empty ? data.subscriberUserVm.name : "";
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "Datetimes",
      align: "left",
      render: (data) =>
        moment(data.addDate).format("DD.MM.YYYY") +
        " - " +
        (data.updateDate != null
          ? moment(data.updateDate).format("DD.MM.YYYY")
          : ""),
    },
  ];

  return (
    <div
      className="container"
      style={{ display: "flex", flexDirection: "row", width: "max-content" }}
    >
      <div className="row" style={{ width: "max-content" }}>
        <div className="col">
          <Table
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            columns={homeOwnersTableColumns}
            dataSource={homeOwnersUsers.data}
            onDataStateChange={dataStateChange}
            loading={tenantSubscriberUsers.loading}
            pagination={false}
            sortable={true}
            rowKey="id"
            size="small"
            bordered={false}
            showHeader={true}
          ></Table>
        </div>
      </div>
      {hirerUsers.data.length != 0 && (
        <div className="row">
          <div className="col">
            <Table
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Veri Yok"
                  />
                ),
              }}
              columns={hirerTableColumns}
              dataSource={hirerUsers.data}
              onDataStateChange={dataStateChange}
              loading={tenantSubscriberUsers.loading}
              pagination={false}
              sortable={true}
              rowKey="id"
              size="small"
              bordered={false}
              showHeader={true}
            ></Table>
          </div>
        </div>
      )}
    </div>
  );
};
export default SubscriberUserHistory;
