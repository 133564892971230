import React, { useState, useEffect } from "react";
import { Button, Row, Col, Tabs, Spin } from "antd";

import { PlusOutlined, BarsOutlined } from "@ant-design/icons";
import BankInfo from "./BankInfo";
import PosInfo from "./PosInfo";
import TosInfo from "./TosInfo";
import DbsInfo from "./DbsInfo";
import PosCollectionInfo from "./PosCollectionInfo";
import RequestHandler from "util/RequestHandler";

const { TabPane } = Tabs;

const Index = () => {
  const [openBankInfoDrawer, setOpenBankInfoDrawer] = useState(0);
  const [openPOSInfoDrawer, setOpenPOSInfoDrawer] = useState(0);
  const [openTOSInfoDrawer, setOpenTOSInfoDrawer] = useState(0);
  const [openDbsInfoDrawer, setOpenDbsInfoDrawer] = useState(0);
  const [openPosCollectionInfoDrawer, setOpenPosCollectionInfoDrawer] = useState(0);
  const [demoInformation, setDemoInformation] = useState(false);
  const [permissions, setPermissions] = useState({
    loading: true,
    modules: [],
  });
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [addButton, setAddButton] = useState({ visible: false });
  useEffect(() => {
    RequestHandler.get("/User/MenuPermissions").then((response) => {
      setPermissions({
        ...response,
        loading: false,
      });
      RequestHandler.get(`/Tenant/GetById/${localStorage.getItem("tenantId").replace('"', '').replace('"', '').toString()}`).then(
        (res) => {
          if (res.isDemo) {
            setDemoInformation(true)
          }
        }
      );
      const screenExistModuleIds = [1, 2, 3, 5, 8];
      const availableModuleIds = response.modules.filter((moduleId) =>
        screenExistModuleIds.includes(moduleId)
      );

      setActiveTabKey(availableModuleIds[0]?.toString());
    });
  }, []);

  useEffect(() => setActiveAddButton(), [activeTabKey]);

  const bankInfoOnClick = (e) => {
    setOpenBankInfoDrawer((prevState) => prevState + 1);
  };
  const posInfoOnClick = (e) => {
    setOpenPOSInfoDrawer((prevState) => prevState + 1);
  };
  const tosInfoOnClick = (e) => {
    setOpenTOSInfoDrawer((prevState) => prevState + 1);
  };
  const dbsInfoOnClick = (e) => {
    setOpenDbsInfoDrawer((prevState) => prevState + 1);
  };
  const posCollectionInfoOnClick = (e) => {
    setOpenPosCollectionInfoDrawer((prevState) => prevState + 1);
  };

  const setActiveAddButton = () => {
    const buttons = {
      1: {
        text: "Banka Ekle",
        onClick: bankInfoOnClick,
        onRender: () => {
          setOpenTOSInfoDrawer(0);
          setOpenDbsInfoDrawer(0);
          setOpenPOSInfoDrawer(0);
          setOpenPosCollectionInfoDrawer(0);
        },
      },
      2: {
        text: "Tos Ekle",
        onClick: tosInfoOnClick,
        onRender: () => {
          setOpenBankInfoDrawer(0);
          setOpenDbsInfoDrawer(0);
          setOpenPOSInfoDrawer(0);
          setOpenPosCollectionInfoDrawer(0);
        },
      },
      3: {
        text: "Dbs Ekle",
        onClick: dbsInfoOnClick,
        onRender: () => {
          setOpenBankInfoDrawer(0);
          setOpenTOSInfoDrawer(0);
          setOpenPOSInfoDrawer(0);
          setOpenPosCollectionInfoDrawer(0);
        },
      },
      5: {
        text: "POS Ekle",
        onClick: posInfoOnClick,
        onRender: () => {
          setOpenBankInfoDrawer(0);
          setOpenTOSInfoDrawer(0);
          setOpenDbsInfoDrawer(0);
          setOpenPosCollectionInfoDrawer(0);
        },
      },
      8: {
        text: "POS Tahsilat Ekle",
        onClick: posCollectionInfoOnClick,
        onRender: () => {
          setOpenBankInfoDrawer(0);
          setOpenTOSInfoDrawer(0);
          setOpenDbsInfoDrawer(0);
          setOpenPOSInfoDrawer(0);
        },
      },
    };

    if (buttons[activeTabKey]) {
      setAddButton({
        text: buttons[activeTabKey].text,
        onClick: buttons[activeTabKey].onClick,
        visible: true,
      });
      buttons[activeTabKey].onRender();
    } else {
      setAddButton({
        visible: false,
      });
    }
  };

  const tabOptions = [
    {
      moduleId: 1,
      title: "Banka Hesapları",
      children: <BankInfo addButtonEvent={openBankInfoDrawer} />,
    },
    {
      moduleId: 5,
      title: "POS Hesapları",
      children: <PosInfo addButtonEvent={openPOSInfoDrawer} />,
    },
    {
      moduleId: 3,
      title: "DBS Hesapları",
      children: <DbsInfo addButtonEvent={openDbsInfoDrawer} />,
    },
    {
      moduleId: 2,
      title: "TOS Hesapları",
      children: <TosInfo addButtonEvent={openTOSInfoDrawer} />,
    },
    {
      moduleId: 8,
      title: "POS Tahsilat Hesapları",
      children: <PosCollectionInfo addButtonEvent={openPosCollectionInfoDrawer} />,
    },
  ];

  return permissions.loading ? (
    <Row justify="center" align="middle" style={{ height: "100%" }}>
      <Spin />
    </Row>
  ) : (
    <>
      <Row>
        <Col xs={15} lg={20}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> BANKA ERİŞİM BİLGİLERİ
          </p>
        </Col>
        <Col xs={9} lg={4}>
          {addButton.visible && (
            <Button type="primary" onClick={addButton.onClick} disabled={demoInformation}>
              <PlusOutlined /> {addButton.text}
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col span={20}>
          <Tabs
            activeKey={activeTabKey}
            onChange={(key) => setActiveTabKey(key)}
          >
            {tabOptions.map((option) => {
              const isModulePermissionExist = permissions.modules.includes(
                option.moduleId
              );
              return (
                <TabPane
                  tab={option.title}
                  key={option.moduleId}
                  disabled={!isModulePermissionExist}
                >
                  {isModulePermissionExist && option.children}
                </TabPane>
              );
            })}
          </Tabs>
        </Col>
        <Col span={2}></Col>
      </Row>
    </>
  );
};

export default Index;
