import React, { useState } from "react";
import { Button, Popover, Popconfirm } from 'antd';
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import moment from 'moment'
import { Link } from "react-router-dom";


const NotificationItem = ({ notification, dataIndex, onMarkAsReadSuccess, onDeleteSuccess, onClickNotificationWithBody }) => {
  const [loading, setLoading] = useState(false);
  const { title, shortDescription, addDate, url } = notification;

  const onClickMarkAsRead = (id) => {
    setLoading(true);
    RequestHandler.put(`/FeatureNotification/MarkAsRead/${id}`, null, false).then((response) => {
      setLoading(false);
      if (response.success) {
        onMarkAsReadSuccess(id, dataIndex);
      }
    });
  };

  const onClickDelete = (id) => {
    setLoading(true);
    RequestHandler.delete(`/FeatureNotification/${id}`, null, false).then((response) => {
      setLoading(false);
      if (response.success) {
        onDeleteSuccess(id, dataIndex);
      }
    });
  };

  const onClickUrlTitle = () => {
    if (!notification.isRead) {
      onClickMarkAsRead(notification.id);
    }
  };

  const onClickContentTitle = () => {
    if (!notification.isRead) {
      onClickMarkAsRead(notification.id);
    }

    if (onClickNotificationWithBody) {
      onClickNotificationWithBody(notification.id);
    }
  };

  const renderTitle = () => {
    if (url) {
      const isExternalUrl = url.toLowerCase().startsWith("http");
      return isExternalUrl ?
        (<a href={url} target="_blank" onClick={onClickUrlTitle}>{title}</a>) :
        (<Link to={url} onClick={onClickUrlTitle}>{title}</Link>);
    }

    if (notification.hasContentBody) {
      return (<a onClick={onClickContentTitle}>{title}</a>)
    }

    return title;
  };

  return (

    <li className={"gx-media gx-align-items-center gx-px-1"} style={notification.isRead ? undefined : { backgroundColor: "rgba(51, 108, 213, 0.2)" }}>
      <div className="gx-media-body gx-align-self-center gx-mr-1">
        <h4 className="gx-fs-sm gx-mb-0">{renderTitle()}</h4>
        <p className="gx-fs-sm gx-mb-0">{shortDescription}</p>
        <p className="gx-fs-sm gx-mb-0 gx-meta-date gx-text-right"><small>{moment(addDate).format("DD.MM.YYYY HH:mm:ss")}</small></p>
      </div>
      <span>
        {loading ?
          (
            <Button type="text" loading={loading} style={{ border: 0 }} />
          ) : (
            <>
              {!notification.isRead && (
                <Popover content="Okundu Olarak İşaretle">
                  <Button className="gx-d-block" type="text" icon={<EyeOutlined />} onClick={() => onClickMarkAsRead(notification.id)} style={{ border: 0 }} />
                </Popover>
              )}
              <Popover content="Sil" placement="bottom">
                <Popconfirm
                  title="Bildirimi silmek istiyor musunuz?"
                  okText="Evet"
                  cancelText="Hayır"
                  onConfirm={() => onClickDelete(notification.id)}
                >
                  <Button className="gx-d-block" type="text" icon={<DeleteOutlined />} style={{ border: 0 }} />
                </Popconfirm>
              </Popover>
            </>
          )}
      </span>
    </li>
  );
};

export default NotificationItem;
