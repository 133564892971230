import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const PosCollectionBankInfoAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: false,
  });
  const [activeBankId, setActiveBankId] = useState();
  const [bankRequirements, setBankRequirements] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getNotAddedBanks();
    getBankInfoRequirements();
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const getNotAddedBanks = () => {
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    RequestHandler.get(`/PosCollectionBankInfo/NotAddedBanks?$select=id,name`).then(
      (response) => {
        setBankSelect({
          data: response,
          loading: false,
        });
      }
    );
  };

  const getBankInfoRequirements = () => {
    RequestHandler.get(`/PosCollectionBankInfoRequirement`).then((response) => {

      if (Array.isArray(response.value)) {
        setBankRequirements(response.value);
      }
    });
  };

  const onFinish = (values) => {
    values.notifyMails = values.notifyMails?.join(",");

    RequestHandler.post(`/PosCollectionBankInfo`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        getNotAddedBanks();
        form.resetFields();
        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  const onBankChange = (bankId) => {
    setActiveBankId(bankId);
  };

  const bankRequirement = bankRequirements.find(
    (x) => x.bankId === activeBankId
  );

  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="posCollectionBankInfoAdd"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="bankId" label="Banka" rules={[{ required: true }]}>
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              onChange={onBankChange}
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              loading={bankSelect.loading}
            >
              {bankSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {bankRequirement && (
            <>
              {bankRequirement.isUserNameRequired && (
                <Form.Item
                  label={bankRequirement.userNameLabel ? bankRequirement.userNameLabel : "Kullanıcı Adı"}
                  name="userName"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              {bankRequirement.isPasswordRequired && (
                <Form.Item
                  label={bankRequirement.passwordLabel ? bankRequirement.passwordLabel : "Şifre"}
                  name="password"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
              )}

              {bankRequirement.isFirmKeyRequired && (
                <Form.Item
                  label={bankRequirement.firmKeyLabel ? bankRequirement.firmKeyLabel : "Firma Anahtarı"}
                  name="firmKey"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              {bankRequirement.isFirmSecretRequired && (
                <Form.Item
                  label={bankRequirement.firmSecretLabel ? bankRequirement.firmSecretLabel : "Firma Gizli Anahtarı"}
                  name="firmSecret"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              <Form.Item
                label="Ödeme Bildirim E-postaları"
                name="notifyMails"
                rules={[{
                  validator: (rule, values) => {
                    if (!values) {
                      return Promise.resolve();
                    }

                    const emailRegex = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
                    const invalidInputs = values.filter((value) => !value.match(emailRegex));
                    if (invalidInputs.length === 0) {
                      return Promise.resolve();
                    }

                    if (invalidInputs.length === 1) {
                      return Promise.reject(new Error(`'${invalidInputs.join('')}' geçersiz bir e-postadır.`));
                    } else {
                      return Promise.reject(new Error(`'${invalidInputs.join(', ')}' değerleri geçersiz e-postadır.`));
                    }
                  }
                }]}
              >
                <Select
                  allowClear
                  showSearch
                  tokenSeparators={[',', ';']}
                  mode="tags"
                >
                </Select>
              </Form.Item>

              <Form.Item
                label="Erp Kod"
                name="erpBankFirmCode"
              >
                <Input />
              </Form.Item>

            </>
          )}

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Ekle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default PosCollectionBankInfoAddForm;
