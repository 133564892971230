import React from "react";
import {Tag,Tooltip} from "antd";
import { useMediaQuery } from 'react-responsive'

const Events = (data) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1500px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  return (
    data.data?.map((item)=>{
      return isTabletOrMobile ?
        <div style={{width:'100%'}} className="gx-media gx-featured-item">
        <div className="gx-featured-thumb">
          <img  style={{maxWidth:150}} src={item.tenantBankLogo} alt="paratic"/> 
        </div>
        <div className="gx-media-body gx-featured-content">
          <div className="gx-featured-content-left" style={{color:'black',fontSize:12}}>
          <Tooltip placement="topLeft" title={item.description}>
          {item.description?.substring(0,25) === undefined ? " " :
        item.description?.substring(0,25).trim().length < 25 ? item.description.substring(0,25)+  "" : item.description?.substring(0,21)+ "..."}
        </Tooltip> / {item.currency}
            <h3 className="gx-mb-2"></h3>
            <div className="ant-row-flex">
              <div className="gx-media gx-text-grey gx-mb-1">
                <i className={`icon icon-datepicker gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}/>
                <span className="gx-media-body gx-ml-1" style={{fontSize:12,width:203}}>{item.transactionDate}</span>
              </div>
            </div>
          </div>
          <div className="gx-featured-content-right gx-profile-content-right">
                <h4 className="gx-text-primary gx-mb-1">
                <span
                  className="gx-d-inline-flex gx-vertical-align-middle" >
                      {item.amountString.includes('-') ? <p style={{color:"red",fontSize:12,width:70,whiteSpace:'nowrap'}}>{item.amountString}</p> :
                <p style={{color:"green",fontSize:11,width:72,whiteSpace:'nowrap'}}>{item.amountString}</p>}
                      </span>
                </h4>
              </div>
        </div>
      </div>
      :
        <div style={{width:'100%'}} className="gx-media gx-featured-item">
       <div className="gx-featured-thumb">
      <img  style={{width:110}} src={item.tenantBankLogo} alt="paratic"/> 
       </div>
       <div className="gx-media-body gx-featured-content">
         <div className="gx-featured-content-left">
         <Tooltip placement="topLeft" title={item.description}>
         {item.description?.substring(0,25) === undefined ? " " :
       item.description?.substring(0,25).trim().length < 25 ? item.description.substring(0,25)+  "" : item.description?.substring(0,21)+ "..."}
       </Tooltip> / {item.currency}
           <h3 className="gx-mb-2"></h3>
           <div className="ant-row-flex">
             <div className="gx-media gx-text-grey gx-mb-1">
               <i className={`icon icon-datepicker gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}/>
               <span className="gx-media-body gx-ml-1">{item.transactionDate}</span>
             </div>
           </div>
         </div>
         <div className="gx-featured-content-right gx-profile-content-right">
               <h4 className="gx-text-primary gx-mb-1">
               <span
                 className="gx-d-inline-flex gx-vertical-align-middle" >
                     {item.amountString.includes('-') ? <p style={{color:"red",whiteSpace:'nowrap'}}>{item.amountString}</p> :
               <p style={{color:"green",whiteSpace:'nowrap'}}>{item.amountString}</p>}
                     </span>
               </h4>
             </div>
       </div>
     </div>
    })
  );
}

export default Events;
