import React, { useState, useEffect } from "react";
import RequestHandler from "util/RequestHandler";
import moment from "moment";

import { Table, Empty, Col, Row } from "antd";
import { BarsOutlined } from "@ant-design/icons";

const ErpFirmReportDetail = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });

  useEffect(
    () => getTableData(),
    [tableDataQueryOptions, props.firmCode, props.currency]
  );

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });
    const { skip, take, filter } = tableDataQueryOptions;

    let conditions = [];
    let query = "";

    if (props.firmCode) {
      conditions.push(`firmCode eq '${props.firmCode}'`);
    }

    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length !== 0) {
      query = conditions.join(" and ");
    }

    RequestHandler.get(
      `/ErpReport/GetEkstreReport?$count=true&$filter=${query}&$skip=${skip}&$top=${take}&currency=${props.currency}`
    ).then((response) => {
      setTableData({
        data: response[0].value,
        total: response[0]["@odata.count"],
        loading: false,
      });
    });
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      ...tableDataQueryOptions,
      skip: 0,
      current: 1,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { ...prevState, skip: 0, current: 1, filter: query };
    });
  };

  let columns = [
    {
      title: "Vade Tarihi",
      dataIndex: "dueDate",
      render: (data, row, index) => moment(data).format("DD.MM.YYYY"),
    },
    {
      title: "İşlem Tipi",
      dataIndex: "typeString",
    },
    {
      title: "Açıklama",
      dataIndex: "description",
    },
    {
      title: "Evrak No",
      dataIndex: "documentNumber",
    },
    {
      title: "Fatura No",
      dataIndex: "invoiceNumber",
    },
    {
      title: "Borç",
      dataIndex: "outgoingString",
    },
    {
      title: "Alacak",
      dataIndex: "incomeString",
    },
  ];

  if (props.currency == "99") {
    columns = columns.concat([
      {
        title: "Bakiye",
        dataIndex: "balanceString",
      },
      {
        title: "Döviz Kuru",
        dataIndex: "exchangeRate",
      },
    ]);
  }

  return (
    <>
      <Row>
        <Col xs={15} lg={20}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> CARİ DETAY
          </p>
        </Col>
      </Row>
      {/* 
      <ErpFirmReportTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      /> */}

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{
              overflowX: "auto",
              maxHeight: "100%",
              width: "100%",
            }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};

export default ErpFirmReportDetail;
