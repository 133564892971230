import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Select, DatePicker,Modal,notification } from "antd";
import { SendOutlined,ExclamationCircleOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";
import { ColumnHelper } from "util/TableHelper";
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const TosCreateOrderForm = (props) => {
  const { confirm } = Modal;
  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState();
  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: true,
  });
  const [form] = Form.useForm();

  useEffect(() => {
    getPayments();
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);
  const directiveSuccess = type => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'İşlem Başarılı.',
        duration: 2,
    });
  };
  const directiveError = type => {
    notification[type]({
      message: 'HATA',
      description:
        'İşlem Başarısız.',
        duration: 2,
    });
  };
  const getPayments = () => {
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    RequestHandler.get(`/TenantPaymentAccount?$filter= isDefault eq true`).then((response) => {
      if(response.value[0] !== undefined){
        setDefaultValue(response.value[0]?.id?.toString())
      }
    });
    RequestHandler.get(`/TenantPaymentAccount`).then((response) => {
      setBankSelect({
        data: response.value,
        loading: false,
      });
    });
  };

  const onFinish = (values) => {
    values.paymentDate = values.paymentDate.format("YYYY-MM-DD HH:mm:ss");
    var total = 0;
    props.selectedPayments.forEach((payment) => (total += payment.amount));
    values.Amount = total;
      RequestHandler.post(`/Directive`, values,false).then((response) => {
        setLoading(false);
        if (response.success) {
          if(response.data === null){
            showConfirm(values);
          }else{
            getPayments();
            form.resetFields();
            if (props.onSuccess) props.onSuccess();
            directiveSuccess('success');
            setTimeout(() => {
              window.location.reload();
            }, 2500);
          }
        }else{
          directiveError('error');
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };
  const handleValue = (value) => {
    setDefaultValue(value);
  }
  const showConfirm = (values) => {
    confirm({
      title: 'Ödeme tarihi ve işlem tarihi aynı değil!',
      icon: <ExclamationCircleOutlined />,
      content: 'Ödeme tarihlerini talimat tarihi ile aynı yapıp devam etmek ister misiniz?',
      cancelText: 'Hayır',
      okText:'Evet',
      onOk() {
        values.IsChangeDate = true;
        RequestHandler.post(`/Directive`, values).then((response) => {
          setLoading(false);
          if (response.success) {
              getPayments();
              form.resetFields();
              if (props.onSuccess) props.onSuccess();
              setTimeout(() => {
                window.location.reload();
              }, 2500);
          }
        });
      },
      onCancel() {
        
      },
    });
  }
  const getPaymentTotalAmount = () => {
    let total = 0;

    if (!props.selectedPayments) {
      return ColumnHelper.renderDecimal(total);
    }

    props.selectedPayments.forEach((payment) => (total += payment.amount));
    return ColumnHelper.renderDecimal(total);
  };
  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="tosPaymentAdd"
      initialValues={{
        remember: true
      }}
      fields={[
        {name:"tenantPaymentAccountId",value:defaultValue}
      ]}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="paymentIds" hidden rules={[{ required: true }]} />

          <Form.Item label="Toplam Ödeme Sayısı">
            {props.selectedPayments?.length}
          </Form.Item>

          <Form.Item label="Toplam Tutar">{getPaymentTotalAmount()}</Form.Item>

          <Form.Item
            name="tenantPaymentAccountId"
            label="Gönderen Banka Kodu"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              onChange={handleValue}
              value={defaultValue === "" ? null : defaultValue}
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              loading={bankSelect.loading}
            >
              {bankSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name} - {item.iban}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
    
          <Form.Item
            label="Ödeme Tarihi"
            name="paymentDate"
            rules={[{ required: true }]}
          >
            <DatePicker format="DD.MM.YYYY" />
          </Form.Item>
          <Form.Item
                label="Açıklama"
                name="Description"
            >
                <TextArea rows={4} />
            </Form.Item>   
          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<SendOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Talimat Oluştur
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default TosCreateOrderForm;
