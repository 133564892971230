import React from 'react'
import { Comment, Avatar, message, Form, Spin, Button, Row, Col, Upload, List, Input, Modal, Typography } from 'antd';
import { UploadOutlined, PictureOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import RequestHandler from "util/RequestHandler";
import InfiniteScroll from 'react-infinite-scroller';
import Replace2x from "../../util/Extensions/ReplaceExtension";
import config from "../../util/ApiforContext";
import MediaQuery from 'react-responsive'
import { saveAs } from 'file-saver'

const { TextArea } = Input;
const { Title } = Typography;
const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
    itemLayout="horizontal"
    renderItem={props => <Comment {...props} />}
  />
);

const { Dragger } = Upload;

const Editor = ({ onChange, onSubmit, submitting, value, fileList, topicId }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Row style={{ justifyContent: 'space-between' }}>

      <Col style={{ height: 55, marginLeft: 15, width: 140 }}>
        <Dragger {...{
          name: "images",
          multiple: true,
          action: `${config.apiUrl}/SupportPanel/AddImage`,
          accept: "image/jpg,image/jpeg,image/png,image/bmp",
          showUploadList: true,
          headers: {
            Authorization: "bearer " + Replace2x(localStorage.getItem("token")),
            'titleId': topicId,
            'userId': Replace2x(localStorage.getItem("userId"))
          },
          onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
            }
            if (status === 'done') {
              message.success(`${info.file.name} adlı fotoğraf başarı ile eklenmiştir.`);
              setTimeout(() => {
                window.location.reload()
              }, 2000);
            } else if (status === 'error') {
              message.error(`${info.file.name} adlı fotoğrafı eklerken bir sorunla karşılaştık.`);
            }
          },
          onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
          },
        }}>
          <div style={{ display: 'flex', marginLeft: 10 }}><UploadOutlined style={{ marginRight: 10, fontSize: 20 }} />  Fotoğraf Yükle</div>
        </Dragger>
      </Col>
      <Col>
        <Form.Item>
          <Button htmlType="submit" loading={submitting} onClick={onSubmit} style={{ marginRight: 15 }} type="primary">
            Gönder
          </Button>
        </Form.Item>
      </Col>
    </Row>

  </>
);
const downloadImage = (val) => {
  saveAs(val, 'image')
}
class SupportRequest extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      submitting: false,
      value: '',
      data: [],
      loading: false,
      hasMore: true,
      activeNumber: 1,
      userName: '',
      topicId: '',
      fileList: [],
      isModalVisible: false,
      pathList: [],
      topicName: ''
    }
    this.showModal = this.showModal.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    localStorage.setItem("topicId", JSON.stringify(this.props.match.params.id));
  }
  showModal(imagePaths) {
    const paths = [];
    imagePaths.map((path) => {
      paths.push(path.path)
    })
    this.setState({
      pathList: paths,
      isModalVisible: true
    })
  };

  handleOk() {
    this.setState({
      isModalVisible: false
    })
  };

  handleCancel() {
    this.setState({
      isModalVisible: false
    })
  };
  handleSubmit = () => {
    if (!this.state.value) {
      return;
    }

    this.setState({
      submitting: true,
    });
    RequestHandler.post(
      `/SupportPanel`,
      {
        SupportTitleId: this.state.topicId,
        message: this.state.value,
        UserId: Replace2x(localStorage.getItem("userId")),
      },
      false
    ).then((response) => {
    });

    setTimeout(() => {
      this.setState({
        submitting: false,
        value: '',
        comments: [
          {
            author: this.state.userName,
            avatar: require("assets/images/user.jpg"),
            // avatar: 'https://testapi.paratic.com.tr/content/mobilebanklogo/Kasahesabi.png',
            content: <p>{this.state.value}</p>,
            datetime: moment().fromNow(),
          },
          ...this.state.comments,
        ],
      });
    }, 900);
  };

  handleChange = e => {
    this.setState({
      value: e.target.value,
    });
  };
  componentDidMount() {
    var min = 800;
    var max = 900;
    var rand = min + (Math.random() * (max - min));
    const topicId = this.props.match.params.id;
    localStorage.setItem("topicId", JSON.stringify(topicId));
    var modelList = [];
    RequestHandler.get(`/SupportPanel/GetMessageById/${topicId}`).then((response) => {
      this.setState({
        userName: response.userName,
        topicId: response.id,
        topicName: response.title
      })
      RequestHandler.get(`/SupportPanel/GetMessagesWithMyId/${topicId}`).then((response) => {
        response.map((item) => {
          const model = {};
          model.author = item.userId === null ? item.operationUserName : item.userName;
          model.avatar = item.userId == null ? `${config.supportPanelImgUrl}/content/mobilebanklogo/Kasahesabi.png` : require("assets/images/user.jpg");
          model.content = item.message;
          model.supportFiles = item.supportFiles;
          model.datetime = moment(item.addDate).fromNow();
          modelList.push(model);
        })
        setTimeout(() => {
          this.setState({
            comments: modelList
          })
        }, rand);
      });
    });
    setTimeout(() => {
      this.setState({
        comments: modelList
      })
    }, rand);

  }

  // handleInfiniteOnLoad = () => {
  //     let { data } = this.state;
  //     this.setState({
  //       loading: true,
  //     });
  //     if (data.length > 14) {
  //       message.warning('Infinite List loaded all');
  //       this.setState({
  //         hasMore: false,
  //         loading: false,
  //       });
  //       return;
  //     }
  // };

  render() {
    const topicIdProp = this.props.match.params.id;
    const { comments, submitting, value } = this.state;
    return (
      <>
        <Row>
          <Col className="gutter-row" xs={24} sm={8} md={8} lg={8} >

            <Title level={3}>{this.state.topicName}</Title>
          </Col>
          <Col className="gutter-row" xs={24} sm={16} md={16} lg={16}>
          </Col>
        </Row>
        <Row >

          <Col className="gutter-row" xs={24} sm={8} md={8} lg={8} style={{ marginTop: '7%' }}>
            <>

              {/* {comments.length > 0 && <CommentList comments={comments} />} */}
              <Comment
                content={
                  <Editor
                    onChange={this.handleChange}
                    onSubmit={this.handleSubmit}
                    submitting={submitting}
                    topicId={topicIdProp}
                    value={value}
                    fileList={this.state.fileList}
                  />
                }
              />
            </>
          </Col>
          <Col className="gutter-row" xs={24} sm={16} md={16} lg={16}>
            <div className="demo-infinite-container" style={{ backgroundImage: `url(${require(`assets/images/supportPanelBackground.png`)})` }}>
              <InfiniteScroll
                initialLoad={false}
                style={{ overflowY: 'auto', height: 680 }}
                pageStart={0}
                loadMore={this.handleInfiniteOnLoad}
                hasMore={!this.state.loading && this.state.hasMore}
                useWindow={true}
              >
                <List
                  dataSource={this.state.comments}
                  renderItem={item => (
                    <List.Item key={item.id}>
                      <List.Item.Meta
                        avatar={
                          <Avatar src={item.avatar} />
                        }
                        title={<p style={{ width: 750 }}>{item.content === "Image" ?
                          <a onClick={() => { this.showModal(item.supportFiles) }}>  <PictureOutlined style={{ fontSize: 20 }} /> Fotoğrafı görmek için tıklayınız.</a>
                          :
                          item.content}</p>}
                        description={<p></p>}
                      />
                      <MediaQuery minWidth={1500}>
                        <div style={{ color: 'gray' }}>{item.datetime} - </div>
                        <div style={{ color: 'gray' }}> {item.author}</div>
                      </MediaQuery>
                    </List.Item>
                  )}
                >
                  {this.state.loading && this.state.hasMore && (
                    <div className="demo-loading-container">
                      <Spin />
                    </div>
                  )}
                </List>
              </InfiniteScroll>
            </div>
          </Col>
          <Modal
            visible={this.state.isModalVisible}
            okButtonProps={{ hidden: true }}
            onCancel={this.handleCancel}
            footer={null}

            cancelButtonProps={{ hidden: true }}>
            {
              this.state.pathList.map((item) => {
                return <><a style={{ color: 'black' }} onClick={() => { downloadImage(item) }}><DownloadOutlined style={{ fontSize: 26 }} /></a><img src={item}></img></>
              })
            }
          </Modal>
        </Row>
      </>
    );
  }
}
export default SupportRequest

