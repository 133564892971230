import React, { useState, useEffect } from 'react'
import { Input, Select, Col, Row, Form, notification, Button } from 'antd';
import { useHistory } from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';
import config from "../../util/ApiforContext";
import { useMediaQuery } from 'react-responsive'
import axios from 'axios';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Screen from '../../util/Extensions/Screens'
const expiration = localStorage.getItem("expiration");
const { Option } = Select;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 13,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 15,
        span: 5,
    },
};
const openNotificationWithIcon = type => {
    notification[type]({
        message: 'BAŞARILI',
        description:
            'Ekleme işlemi başarı ile gerçekleşti.',
        duration: 2,
    });
};
const openNotificationWithIconWarning = type => {
    notification[type]({
        message: 'HATA',
        description:
            'Ekleme işlemi sırasında bir hata oluştu.',
        duration: 2,
    });
};
const mailError = type => {
    notification[type]({
        message: 'HATA',
        description:
            'Mail adresi zaten kayıtlı.',
        duration: 2,
    });
};

const UserAddForm = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [firmTypeDropdown, setfirmTypeDropdown] = useState([]);
    const errorList = [];
    useEffect(() => {
        if (expiration < Date.now()) {
            localStorage.removeItem("expiration");
            localStorage.removeItem("token");
            window.location.reload();
        }
        if (localStorage.getItem("token") == null) {
            window.location.reload()
        }
    }, []);
    const errorListNotification = type => {
        errorList.map((item) => {
            notification[type]({
                message: 'Hata',
                description:
                    item
                ,
                duration: 2,
            });
        })
    };
    const onFinish = values => {
        axios.post(`${config.apiUrl}/Auth/RegisterWithTenant`,
            {
                Email: values.Email,
                SalesCode: values.ErpSalesCode,
                FirstName: values.FirstName,
                LastName: values.LastName,
                PhoneNumber: values.PhoneNumber,
                ScreenOption: Screen.screen
            },
            config.headers)
            .then(res => {
                if (res.status == 200) {
                    openNotificationWithIcon('success');
                    setTimeout(function () {
                        window.location.reload();
                    }.bind(this), 2000)
                }
            }).catch(
                error => {
                    if (error.response.status === 403 || error.response.status === 401) {
                        history.push('/not-authorized-access');
                    }
                    else if (error.response.status === 406) {
                        error.response.data.data.map((item) => {
                            errorList.push(item);
                        })
                        errorListNotification('error')
                        setLoading(false)
                    } else {
                        openNotificationWithIconWarning('error')
                        setLoading(false)
                    }
                }
            );
    };
    const passwordIsNotValid = type => {
        notification[type]({
            message: 'HATA',
            description:
                'Kayıtlı olan bir hesabı tekrar ekleyemezsiniz.',
            duration: 5,
        });
    };
    const onFinishFailed = errorInfo => {
        setLoading(false)
    };
    return (
        <div>
            {isDesktopOrLaptop && <>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Row>
                        <Col span={4}></Col>
                        <Col span={15}>
                            <Form.Item
                                label="Ad"
                                name="FirstName"
                                style={{ marginTop: "4%" }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen Ad alanını boş bırakmayın.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Soyad"
                                name="LastName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen Soyad alanını boş bırakmayın.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="E-Posta Adresi"
                                name="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen E-Posta Adresi alanını boş bırakmayın.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Satıcı Kodu"
                                name="ErpSalesCode"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Telefon Numarası"
                                name="PhoneNumber"
                                rules={[
                                    {
                                        min: 12,
                                        message: 'Telefon numarasını eksiksiz giriniz.'
                                    },
                                    {
                                        required: true,
                                        message: 'Lütfen Telefon Numarası alanını boş bırakmayın.',
                                    },
                                ]}
                            >
                                <PhoneInput
                                    inputStyle={{ width: "100%" }}
                                    country='tr'
                                    masks={{ tr: '(...) ...-..-..' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}></Col>
                        <Col span={6}></Col>
                        <Col span={9}></Col>
                        <Col span={7}>
                            <Form.Item {...tailLayout} >
                                <Button type="primary" icon={<PlusOutlined />} htmlType="submit" onClick={() => setLoading(true)} loading={loading}>
                                    Ekle
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                    </Row>
                </Form>
            </>}
            {isTabletOrMobileDevice && <>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Ad"
                                name="FirstName"
                                style={{ marginTop: "4%" }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen Ad alanını boş bırakmayın.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Soyad"
                                name="LastName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen Soyad alanını boş bırakmayın.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="E-Posta Adresi"
                                name="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen E-Posta Adresi alanını boş bırakmayın.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Satıcı Kodu"
                                name="ErpSalesCode"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Telefon Numarası"
                                name="PhoneNumber"
                                rules={[
                                    {
                                        min: 12,
                                        message: 'Telefon numarasını eksiksiz giriniz.'
                                    },
                                    {
                                        required: true,
                                        message: 'Lütfen Telefon Numarası alanını boş bırakmayın.',
                                    },
                                ]}
                            >
                                <PhoneInput
                                    inputStyle={{ width: "100%" }}
                                    country='tr'
                                    masks={{ tr: '(...) ...-..-..' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                        <Col span={6}>
                            <Form.Item {...tailLayout} >
                                <Button type="primary" icon={<PlusOutlined />} htmlType="submit" onClick={() => setLoading(true)} loading={loading}>
                                    Ekle
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </>}

        </div>
    )
}
export default UserAddForm