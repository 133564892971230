import React, { useState } from "react";
import RequestHandler from "util/RequestHandler";
import ValueReportMonthly from "./ValueReportMonthly";
import ValueReportDaily from "./ValueReportDaily";
import ValueReportOperationDateBased from "./ValueReportOperationDateBased";
import config from "../../util/ApiforContext";

import { Tabs, Col, Row, Tooltip, message } from "antd";
import { BarsOutlined, FileExcelOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;

const ValueReport = (props) => {
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [reportFilters, setReportFilters] = useState({
    daily: {},
    monthly: {},
    operationDateBased: {},
  });

  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);

    switch (activeTabKey) {
      case "1":
        RequestHandler.post(
          "/PosTransaction/MonthlyValueReportExportExcel",
          reportFilters.monthly
        ).then((response) => {
          hideMessage();

          if (response.success) {
            window.open(
              `${
                config.apiUrl
              }/PosTransaction/MonthlyValueReportDownloadExcel?fileName=${
                response.data
              }&t=${RequestHandler.getToken()}`
            );
          }
        });
        break;
      case "2":
        RequestHandler.post(
          "/PosTransaction/DailyValueReportExportExcel",
          reportFilters.daily
        ).then((response) => {
          hideMessage();

          if (response.success) {
            window.open(
              `${
                config.apiUrl
              }/PosTransaction/DailyValueReportDownloadExcel?fileName=${
                response.data
              }&t=${RequestHandler.getToken()}`
            );
          }
        });
        break;

      default:
        RequestHandler.post(
          "/PosTransaction/OperationDateBasedValueReportExportExcel",
          reportFilters.daily
        ).then((response) => {
          hideMessage();

          if (response.success) {
            window.open(
              `${
                config.apiUrl
              }/PosTransaction/OperationDateBasedValueReportDownloadExcel?fileName=${
                response.data
              }&t=${RequestHandler.getToken()}`
            );
          }
        });
        break;
    }
  };

  const onMonthlyFilterChange = (filterData) => {
    setReportFilters({ ...reportFilters, monthly: filterData });
  };

  const onDailyFilterChange = (filterData) => {
    setReportFilters({ ...reportFilters, daily: filterData });
  };

  const onOperationDateBasedFilterChange = (filterData) => {
    setReportFilters({ ...reportFilters, operationDateBased: filterData });
  };

  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> VALÖR RAPORU
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
          <Tooltip title="Excel İndir">
            <a
              style={{
                color: "green",
                fontSize: "180%",
                marginRight: 30,
              }}
              onClick={onClickExportExcel}
            >
              <FileExcelOutlined />
            </a>
          </Tooltip>
        </Col>
      </Row>

      <Tabs
        defaultActiveKey="1"
        onChange={(activeKey) => {
          setActiveTabKey(activeKey);
        }}
      >
        <TabPane tab="Ay Bazlı" key="1">
          <ValueReportMonthly onFilterChange={onMonthlyFilterChange} />
        </TabPane>
        <TabPane tab="Gün Bazlı" key="2">
          <ValueReportDaily onFilterChange={onDailyFilterChange} />
        </TabPane>
        <TabPane tab="İşlem Tarihi Bazlı" key="3">
          <ValueReportOperationDateBased
            onFilterChange={onOperationDateBasedFilterChange}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default ValueReport;
