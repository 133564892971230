import React, { useState, useEffect } from "react";
import NotificationItem from "./NotificationItem";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";
import { Button, Popover, Popconfirm, Spin, Row, Empty, Modal } from 'antd';
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";


const NotificationContent = ({ refreshNotReadBadge }) => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ data: null, visible: false, loading: false });
  const [notifications, setNotifications] = useState({
    data: [],
    total: 0,
    loading: true,
    nextPageLoading: false,
  });
  const [queryOptions, setQueryOptions] = useState({
    skip: 0,
    take: 5,
    current: 1,
  });

  useEffect(() => getNotifications(), [queryOptions]);

  const refreshNotifications = () => {
    setNotifications({ data: [], total: 0, loading: true });
    setQueryOptions({ ...queryOptions, skip: 0, current: 1 });
  };

  const getNotifications = () => {

    setNotifications({ ...notifications, nextPageLoading: true });
    const { skip, take } = queryOptions;

    RequestHandler.get(`/FeatureNotification?$count=true&$skip=${skip}&$top=${take}`
    ).then((response) => {
      if (Array.isArray(response.value)) {
        setNotifications({
          data: [...notifications.data, ...response.value],
          total: response["@odata.count"],
          nextPageLoading: false,
          loading: false
        });
      } else {
        setNotifications({ ...notifications, nextPageLoading: false, loading: false });
      }
    });
  };

  const onClickMarkAllAsRead = () => {
    setLoading(true);
    RequestHandler.put(`/FeatureNotification/MarkAllAsRead`).then((response) => {
      setLoading(false);
      if (response.success) {
        refreshNotifications();
        refreshNotReadBadge();
      }
    });
  };

  const onClickDeleteAll = () => {
    setLoading(true);
    RequestHandler.delete(`/FeatureNotification/DeleteAll`).then((response) => {
      setLoading(false);
      if (response.success) {
        refreshNotifications();
        refreshNotReadBadge();
      }
    });
  };

  const onMarkAsReadSuccess = (_, index) => {
    refreshNotReadBadge();

    let rows = [...notifications.data];
    rows[index] = {
      ...rows[index],
      isRead: true,
    };
    setNotifications({ ...notifications, data: rows });
  };

  const onDeleteSuccess = () => {
    refreshNotReadBadge();
    refreshNotifications();
  };

  const onScrollFrame = (e) => {
    if (e.top > 0.85 && !notifications.nextPageLoading && notifications.total > notifications.data.length) {
      setQueryOptions({
        ...queryOptions,
        skip: queryOptions.take * (queryOptions.current),
        current: queryOptions.current + 1
      });
    }
  };

  const getNotificationDetail = (id) => {
    setModal({ data: null, loading: true, visible: true });

    RequestHandler.get(`/FeatureNotification/GetById/${id}`).then((response) => {
      setModal({ data: response, loading: false, visible: true })
    });
  };

  return (
    <>
      <Auxiliary>
        <div className="gx-popover-header">
          <h3 className="gx-mb-0">Bildirimler</h3>

          {!notifications.loading && notifications.data.length > 0 && (
            <span>
              <Popover content="Tümünü Okundu Olarak İşaretle">
                <Button type="text" icon={<EyeOutlined />} style={{ border: 0 }} onClick={onClickMarkAllAsRead} loading={loading} />
              </Popover>

              <Popover content="Sil">
                <Popconfirm
                  title="Tüm bildirimleri silmek istiyor musunuz?"
                  okText="Evet"
                  cancelText="Hayır"
                  onConfirm={onClickDeleteAll}
                >
                  <Button type="text" icon={<DeleteOutlined />} style={{ border: 0 }} loading={loading} />
                </Popconfirm>
              </Popover>
            </span>
          )}

        </div>
        <CustomScrollbars className="gx-popover-scroll" onScrollFrame={onScrollFrame}>
          {notifications.loading &&
            (
              <Row align="middle" justify="center" style={{ height: "100%" }}>
                <Spin />
              </Row>
            )
          }

          {!notifications.loading &&
            (
              notifications.data.length > 0 ? (
                <ul className="gx-sub-popover gx-m-0">
                  {
                    notifications.data.map((notification, index) =>
                      <NotificationItem
                        key={notification.id}
                        dataIndex={index}
                        notification={notification}
                        onMarkAsReadSuccess={onMarkAsReadSuccess}
                        onDeleteSuccess={onDeleteSuccess}
                        onClickNotificationWithBody={getNotificationDetail}
                      />
                    )
                  }
                </ul>
              ) :
                (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Bildirim Yok"
                  />
                )
            )
          }
        </CustomScrollbars>
      </Auxiliary>

      <Modal
        id="notificationDetailModal"
        centered
        visible={modal.visible}
        onOk={() => setModal({ ...modal, visible: false })}
        onCancel={() => setModal({ ...modal, visible: false })}
        title={modal.data?.title}
        footer={null}
        zIndex={1050}
      >
        {modal.loading ? <Row justify="center" align="middle" style={{ height: "100%" }}><Spin /></Row> :
          <div dangerouslySetInnerHTML={{ __html: modal.data?.body }} />}
      </Modal>
    </>
  )
}

export default NotificationContent;