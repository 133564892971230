import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./NotFound/NotFound";
import AccountPage from "./AccountPage/AccountPage";
import ReportFirmAccountPage from "./Report/ReportFirmAccountPage";
import BankAccountPage from "./AccountPage/BankAccountPage";
import AccountPageBank from "./AccountPage/AccountBank";
import TransactionBankInfo from "./TransactionBankInfoAccount/index";
import ReportMonthlyDetail from "./Report/ReportMonthlyDetail";
import ReportMonthlyDetailPayment from "./Report/ReportMonthlyDetailPayment";
import FirmStatusCollectChart from "./Report/FirmStatusCollectChart";
import FirmStatusPaymentChart from "./Report/FirmStatusReportChartjs";
import FirmStatusReport from "./Report/FirmStatusReport";
import GroupList from "./Groups/GroupList";
import NotAuthorized from "./NotFound/Authorize";
import Consolidated from "./Consolidated/ConsolidatedBankList";
import AccountTransaction from "./AccountPage/index";
import BankList from "./BankPage/BankList";
import BankAccounts from "./TenantAccount/index";
import ManualAccountTransaction from "./AccountPage/ManualAccountPage";
import ManualAccountTransactionType from "./AccountPage/ManualTransactionTypeList";
import MonthlyPlanList from "./MounthlyPlan/MonthlyPlanList";
import MonthlyPlanTypeList from "./MonthlyPlanType/MonthlyPlanTypeList";
import FirmList from "./Firm/FirmList";
import FirmAccountList from "./FirmAccount/FirmAccountList";
import FirmClassList from "./FirmClass/FirmClassList";
import TransactionBankInfoList from "./TransactionBankInfo/Index";
import PasswordChange from "./Password/Change";
import UserList from "./User/UserList";
import UserRoleList from "./User/UserRoles";
import MonthlyFirmStatusReport from "./Report/MonthlyStatusPlanReports";
import UserAccountsRoles from "./User/UserAccountRoles";
import EntityPermissions from "./User/EntityPermissions";
import Notifications from "./Other/Notifications";
import { useMediaQuery } from "react-responsive";
import ChartReports from "./Report/ChartsReport";
import DayReports from "./Report/DayReports";
import DailyReportAccount from "./Report/DailyReportAccount";
import MonthlyReportAccount from "./Report/MonthlyReportAccount";

import TableTransactionRole from "./TableRole/TableTransactionRole";
import AccountTransactionRole from "./TableRole/AccountTransactionRole";

import HomePage from "./HomePage";
import AccountTransactionHome from "./HomePage/Pages/AccountTransactionHome";
import PosReportHome from "./HomePage/Pages/PosReportHome";
import DbsHome from "./HomePage/Pages/DbsHome";
import B2bHome from "./HomePage/Pages/B2bHome";
import SubscriberFirmHome from "./HomePage/Pages/SubscriberFirmHome";

import PosTransaction from "./PosOperations/PosTransaction";
import ValueReport from "./PosOperations/ValueReport";
import CommissionReport from "./PosOperations/CommissionReport";
import PosVolumeReport from "./PosOperations/PosVolumeReport";
import PosCommissionDefinition from "./PosOperations/PosCommissionDefinition";

import ErpActivityList from "./AccountPage/ErpActivityList";

import DbsDealer from "./Dbs/DbsDealer";
import DbsInvoice from "./Dbs/DbsInvoice";
import DbsSentInvoice from "./Dbs/DbsSentInvoice";

import BankSummaryDetailReport from "./Dbs/Dashboard/BankSummaryDetailReport";
import BankVolumeDetailReport from "./Dbs/Dashboard/BankVolumeDetailReport";
import DbsDealerVolumeReport from "./Dbs/Dashboard/DbsDealerVolumeReport";

import BankLimitReport from "./Dbs/Report/BankLimitReport";
import BankVolumeReport from "./Dbs/Report/BankVolumeReport";
import FirmDbsStatusReport from "./Dbs/Report/FirmDbsStatusReport";
import DbsValueReport from "./Dbs/Report/DbsValueReport";

import TosPayment from "./Tos/TosPayment";
import TosOrder from "./Tos/TosOrder";
import TosSentPayment from "./Tos/TosSentPayment";
import TosSentPaymentList from "./Tos/TosSentPaymentAllList";
import TosSentOrderList from "./Tos/TosSentOrderList";

import ReportDailyDetail from "./Report/ReportDailyDetail";
import ReportDailyDetailPayment from "./Report/ReportDailyDetailPayment";
import TenantPaymentAccount from "./Tos/TenantPaymentAccount";

import ErpExportTransaction from "./Erp/ErpExportTransaction";
import ErpFirmReport from "./Erp/ErpFirmReport";
import ErpStockReport from "./Erp/ErpStockReport";
import ErpFirmInvoiceReport from "./Erp/ErpFirmInvoiceReport";
import SupportRequest from "./SupportPanel/SupportRequest"

import FirmUser from "./B2B/FirmUser";
import PosCollectionTransaction from "./B2B/PosCollectionTransaction";
import PosCollection from "./B2B/PosCollection";
import PaymentRequest from "./B2B/PaymentRequest";
import PaymentList from "./B2B/PaymentList";

import SupportPanel from "./SupportPanel/supportPanel";
import SubscriberFirmList from "./SubscriberFirm/SubscriberFirmList";
import SubscriberFirmPanelInformation from "./SubscriberFirm/SubscriberFirmPanelInformation";
import SubscriberFirmStatusReport from "./SubscriberFirm/SubscriberFirmStatusReport";

import FirmMonthlyStatusReportPage from "./Report/FirmMonthlyPaymentStatusReport";

const App = ({ match }) => (
  <div
    className="gx-main-content-wrapper"
    style={{
      backgroundColor: "white",
      width: useMediaQuery({
        query: "(max-width: 1070px)",
      })
        ? ""
        : "99%",
    }}
  >
    <Switch>
      {/* Anasayfa */}
      {/* <Route path={`${match.url}`} component={asyncComponent(() => import('./HomePage/index'))}/> */}
      <Route path={`${match.url}home`} component={HomePage} />
      <Route path={`${match.url}home-act`} component={AccountTransactionHome} />
      <Route path={`${match.url}home-pos`} component={PosReportHome} />
      <Route path={`${match.url}home-dbs`} component={DbsHome} />
      <Route path={`${match.url}home-b2b`} component={B2bHome} />
      <Route path={`${match.url}home-subscriberFirm`} component={SubscriberFirmHome}/>

      {/* Hesap hareketleri */}
      <Route
        path={`${match.url}account-transaction`}
        component={AccountTransaction}
      />
      {/* {TABLO YETKİ} */}
      <Route
        path={`${match.url}table-transaction-role`}
        component={TableTransactionRole}
      />
      <Route
        path={`${match.url}account-transaction-role`}
        component={AccountTransactionRole}
      />

      {/* RAPORLAR */}
      <Route
        path={`${match.url}firm-status-report`}
        component={FirmStatusReport}
      />
      <Route
        path={`${match.url}firm-status-report-chart-payment/:Date/:Date2/:CurrencyCode/:firmId/:CollectTotal/:CollectTotal2/:CollectPlanTotal/:CollectPlanTotal2/:PaymentPlanTotal/:PaymentPlanTotal2/:PaymentTotal/:PaymentTotal2`}
        component={FirmStatusPaymentChart}
      />
      <Route
        path={`${match.url}firm-status-report-chart-collect/:Date/:Date2/:CurrencyCode/:firmId/:CollectTotal/:CollectTotal2/:CollectPlanTotal/:CollectPlanTotal2/:PaymentPlanTotal/:PaymentPlanTotal2/:PaymentTotal/:PaymentTotal2`}
        component={FirmStatusCollectChart}
      />
      <Route
        path={`${match.url}monthly-firm-status-report`}
        component={MonthlyFirmStatusReport}
      />

      {/* POS İşlemleri */}
      <Route
        exact
        path={`${match.url}pos-transaction/:filter?`}
        component={PosTransaction}
      />
      <Route exact path={`${match.url}value-report`} component={ValueReport} />
      <Route
        exact
        path={`${match.url}commission-report`}
        component={CommissionReport}
      />
      <Route
        exact
        path={`${match.url}pos-volume-report`}
        component={PosVolumeReport}
      />
      <Route
        exact
        path={`${match.url}commission-definition`}
        component={PosCommissionDefinition}
      />

      {/* Hesap İşlemleri - Banka listesi */}
      <Route path={`${match.url}bank-list`} component={BankList} />
      {/* Hesap İşlemleri - Banka Hesapları */}
      <Route path={`${match.url}bank-accounts`} component={BankAccounts} />
      {/* Hesap İşlemleri - Banka Listesi - Banka Hesapları */}
      <Route
        exact
        path={`${match.url}bank-list-accounts/:tenantid`}
        component={AccountPage}
      />
      {/* Hesap İşlemleri - Banka Listesi - Banka Hesapları - Hesap Hareketleri */}
      <Route
        exact
        path={`${match.url}bank-list-accounts-account-transaction/:bankaccountid`}
        component={BankAccountPage}
      />
      {/* Banka Hesapları - Hesap Hareketleri */}
      <Route
        exact
        path={`${match.url}bank-accounts-account-transaction/:tenantAccountId`}
        component={AccountPageBank}
      />
      {/* Elden İşlem */}
      <Route
        path={`${match.url}manual-account-transaction`}
        component={ManualAccountTransaction}
      />
      {/* Elden İşlem Tipi */}
      <Route
        path={`${match.url}manual-account-transaction-type`}
        component={ManualAccountTransactionType}
      />
      {/*Aylık Plan Listesi */}
      <Route
        path={`${match.url}monthly-plan-list`}
        component={MonthlyPlanList}
      />
      {/*Elden İşlem Erp Listesi */}
      <Route
        path={`${match.url}erp-activity-list`}
        component={ErpActivityList}
      />
      {/* Aylık Plan Tipi*/}
      <Route
        path={`${match.url}monthly-plan-type-list`}
        component={MonthlyPlanTypeList}
      />
      {/* Bildirim*/}
      <Route path={`${match.url}notifications`} component={Notifications} />
      {/* Ödeme Hesapları*/}
      <Route path={`${match.url}tenant-payment-account/:bankId`} component={TenantPaymentAccount} />
      {/* Firma Listesi */}
      <Route path={`${match.url}firm-list`} component={FirmList} />
      {/* Firma Hesapları */}
      <Route
        path={`${match.url}firm-account-list/:firmId?`}
        component={FirmAccountList}
      />
      {/* Firma Sınfı Listesi */}
      <Route path={`${match.url}firm-class-list`} component={FirmClassList} />
      {/* Hesap Erişimi */}
      <Route
        path={`${match.url}transaction-bank-info`}
        component={TransactionBankInfoList}
      />
      {/* Şifre Değiştir */}
      <Route path={`${match.url}password-change`} component={PasswordChange} />
      {/* Kullanıcı Yetkiler */}
      <Route path={`${match.url}user-list`} component={UserList} />
      <Route path={`${match.url}user-roles/:userId`} component={UserRoleList} />
      <Route
        path={`${match.url}user-accounts-roles/:userId`}
        component={UserAccountsRoles}
      />
      <Route
        path={`${match.url}entity-permission/:tenantUserId`}
        component={EntityPermissions}
      />

      {/* Konsolide */}
      <Route
        exact
        path={`${match.url}consolidated-bank`}
        component={Consolidated}
      />
      {/* Gruplar */}
      <Route exact path={`${match.url}group-list`} component={GroupList} />
      <Route
        exact
        path={`${match.url}chart-reports`}
        component={ChartReports}
      />
      <Route
        exact
        path={`${match.url}day-reports`}
        component={DayReports}
      />
      {/* <Route exact path = {`${match.url}group-add`} component = {GroupAdd} /> */}

      <Route
        exact
        path={`${match.url}transaction-bank-account-info/:bankId/:bankInfoId`}
        component={TransactionBankInfo}
      />
      <Route
        exact
        path={`${match.url}report-monthly-firm-details/:date/:currency`}
        component={ReportMonthlyDetail}
      />
      <Route
        exact
        path={`${match.url}report-monthly-firm-details-payment/:date/:currency`}
        component={ReportMonthlyDetailPayment}
      />
      <Route
        exact
        path={`${match.url}report-daily-firm-details/:date/:currency`}
        component={ReportDailyDetail}
      />
      <Route
        exact
        path={`${match.url}report-daily-firm-details-payment/:date/:currency`}
        component={ReportDailyDetailPayment}
      />
      <Route
        exact
        path={`${match.url}daily-report-account/:bankId/:transactionDate/:date?`}
        component={DailyReportAccount}
      />
      <Route
        exact
        path={`${match.url}monthly-report-account/:bankId/:transactionDate`}
        component={MonthlyReportAccount}
      />

      <Route
        exact
        path={`${match.url}report-firm-account-transaction/:firmid/:transactionDate?/:transactionDate2/:currency`}
        component={ReportFirmAccountPage}
      />
      
      <Route
        exact
        path={`${match.url}report-firm-monthly-payment/:firmid/:transactionDate?/:transactionDate2/:currency`}
        component={FirmMonthlyStatusReportPage}
      />

      {/* DBS */}
      <Route exact path={`${match.url}dbs-dealer/:bankId?`} component={DbsDealer} />
      <Route exact path={`${match.url}dbs-invoice`} component={DbsInvoice} />
      <Route exact path={`${match.url}dbs-sent-invoice`} component={DbsSentInvoice} />

      <Route exact path={`${match.url}bank-summary-report`} component={(props) => <BankSummaryDetailReport dataUrl="/DbsDashboard/BankSummaryReport" {...props} />} />
      <Route exact path={`${match.url}bank-volume-report-detail`} component={(props) => <BankVolumeDetailReport dataUrl="/DbsDashboard" {...props} />} />
      <Route exact path={`${match.url}dbs-firm-status-report`} component={(props) => <FirmDbsStatusReport dataUrl="/DbsDashboard/DbsFirmStatus" excelUrl="/DbsDashboard/ExportDbsFirmStatusExcel" {...props} />} />
      <Route exact path={`${match.url}dbs-dealer-volume-report`} component={DbsDealerVolumeReport} />

      <Route exact path={`${match.url}dbs-report/bank-limit-report`} component={BankLimitReport} />
      <Route exact path={`${match.url}dbs-report/bank-volume-report`} component={BankVolumeReport} />
      <Route exact path={`${match.url}dbs-report/firm-status-report`} component={(props) => <FirmDbsStatusReport dataUrl="/DbsReport/DbsFirmStatus" excelUrl="/DbsReport/ExportDbsFirmStatusExcel" {...props} />} />
      <Route exact path={`${match.url}dbs-report/value-report`} component={DbsValueReport} />
      <Route exact path={`${match.url}dbs-report/bank-limit-report-detail`} component={(props) => <BankSummaryDetailReport dataUrl="/DbsReport/BankSummaryReport" {...props} />} />
      <Route exact path={`${match.url}dbs-report/bank-volume-report-detail`} component={(props) => <BankVolumeDetailReport dataUrl="/DbsReport" {...props} />} />

      {/* TOS */}
      <Route exact path={`${match.url}tos-payment`} component={TosPayment} />
      <Route exact path={`${match.url}tos-order`} component={TosOrder} />
      <Route exact path={`${match.url}tos-sent-payment`} component={TosSentPaymentList} />
      <Route exact path={`${match.url}tos-sent-order`} component={TosSentOrderList} />
      <Route
        exact
        path={`${match.url}tos-sent-payment/:directiveId?`}
        component={TosSentPayment}
      />
      {/* ERP */}
      <Route
        exact
        path={`${match.url}erp-incoming-transaction`}
        component={() => <ErpExportTransaction transactionProcessType={1} />}
      />
      <Route
        exact
        path={`${match.url}erp-outgoing-transaction`}
        component={() => <ErpExportTransaction transactionProcessType={2} />}
      />
      <Route
        exact
        path={`${match.url}erp-firm-report`}
        component={ErpFirmReport}
      />
      <Route
        exact
        path={`${match.url}erp-firm-invoice/:firmCode/:currency`}
        component={ErpFirmInvoiceReport}
      />
      <Route
        exact
        path={`${match.url}erp-stock-report`}
        component={ErpStockReport}
      />

      {/* B2B */}
      <Route exact path={`${match.url}firm-user`} component={FirmUser} />
      <Route exact path={`${match.url}pos-collection-transaction`} component={PosCollectionTransaction} />
      <Route exact path={`${match.url}pos-collection`} component={PosCollection} />
      <Route exact path={`${match.url}payment-request`} component={PaymentRequest} />
      <Route exact path={`${match.url}payment-list`} component={PaymentList} />

      {/* DESTEK PANELİ */}
      <Route exact path={`${match.url}support-panel/:id?`} component={SupportPanel} />
      <Route exact path={`${match.url}support-request/:id`} component={SupportRequest} />

      {/* Asy */}
      <Route exact path={`${match.url}subscriberfirm-list`} component= {SubscriberFirmList} />
      <Route exact path={`${match.url}subscriberfirm-panel-info/:firmId`} component= {SubscriberFirmPanelInformation} />
      <Route path={`${match.url}subscriberfirm-status-report`} component={SubscriberFirmStatusReport} />
      <Route
        path={`${match.url}subscriberfirm-status-report-chart-collect/:Date/:Date2/:CurrencyCode/:firmId/:CollectTotal/:CollectTotal2/:CollectPlanTotal/:CollectPlanTotal2/:PaymentPlanTotal/:PaymentPlanTotal2/:PaymentTotal/:PaymentTotal2`}
        component={FirmStatusCollectChart}
      />

      {/* Error */}
      <Route
        exact
        path={`${match.url}not-authorized-access`}
        component={NotAuthorized}
      />
      <Route component={NotFound} />
    </Switch>
  </div>
);

export default App;
