//gerçek oto için hem screen hem screenGercek açık olmalı
//imza pos için sadece imza pos açık olmalı
//paratic için sadece paratic açık olmalı

const screen = 0; //paratic
//const screen = 1; //imzapos
//const screen = 2 //bakiyem//
//const screenGercek = 2; //gercek oto
export default {
    screen: screen,
    //screenGercek : screenGercek
}