import React, { useState, useEffect } from "react";

import RequestHandler from "util/RequestHandler";
import { ColumnHelper } from "util/TableHelper";

import PosCollectionTransactionTableFilter from "../Filters/PosCollectionTransactionTableFilter";
import B2bPosCollectionSendReceiptForm from "../Forms/B2bPosCollectionSendReceiptForm";
import moment from "moment";

import {
  Table,
  Empty,
  Col,
  Row,
  Tooltip,
  Modal
} from "antd";
import {
  BarsOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { odata } from "../Filters/Helper";


const PosCollectionTransaction = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });
  const [sendReceiptModal, setSendReceiptModal] = useState({
    visible: false,
    data: null
  });
  const [order, setOrder] = useState("");

  useEffect(() => getTableData(), [order, tableDataQueryOptions]);

  const getFilterQuery = () => {
    const { filter } = tableDataQueryOptions;

    const conditions = [];
    let query = "";

    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length !== 0) {
      query = "&$filter=" + conditions.join(" and ");
    }
    return query;
  };

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });
    const { skip, take } = tableDataQueryOptions;

    RequestHandler.get(
      `/PosCollectionTransaction?$count=true${getFilterQuery()}${order}&$skip=${skip}&$top=${take}`
    ).then((response) => {
      if (Array.isArray(response.value)) {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      }
    });
  };

  const paging = (p, filters, sorter) => {
    let order = "";
    if (sorter.columnKey && sorter.order) {
      order =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }
    setOrder(order);
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };

  const onClickSendReceipt = (id) => {
    setSendReceiptModal({ visible: true, data: { id } })
  };

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      width: 150,
      render: (text) => <img src={text}></img>,
    },
    {
      title: "Cari Kodu",
      dataIndex: "firmCode",
      render: (data) => data ? data : "-",
    },
    {
      title: "İşlemi Yapan",
      dataIndex: "addUserName"
    },
    {
      title: "Ünvan",
      dataIndex: "firmName",
      render: (data, row) => row.firmCode ? data : row.customerInformation,
    },
    {
      title: "Tahsilat Tutarı",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Para Birimi",
      dataIndex: "currencyCode",
    },
    {
      title: "Taksit Sayısı",
      dataIndex: "installmentCount"
    },
    {
      title: "İşlem Tarihi",
      dataIndex: "addDate",
      key: "addDate",
      sorter: true,
      align: "center",
      render: (data) => moment(data).format("DD.MM.YYYY HH:mm"),
    },
   
    {
      title: "İşlem Sonucu",
      dataIndex: "success",
      align: "center",
      render: (data, row) => {
        if (data === null) {
          return (
            <Tooltip title={row.message}>
              <ClockCircleOutlined style={{ color: "orange", fontSize: "130%" }} />
            </Tooltip>
          )
        }

        return data ? (
          <Tooltip title={row.message}>
            <CheckCircleOutlined style={{ color: "green", fontSize: "130%" }} />
          </Tooltip>
        ) : (
          <Tooltip title={row.message}>
            <CloseCircleOutlined style={{ color: "red", fontSize: "130%" }} />
          </Tooltip>
        );
      },
    },
    {
      title: "Erp Kodu",
      dataIndex: "documentNumber"
    },
    {
      title: "Dekont",
      dataIndex: "id",
      align: "center",
      render: (data, row) =>
        //simdilik sadece param posta
        row.success && row.bankId == 889 ?
          (
            <a onClick={() => onClickSendReceipt(data)}>
              <FilePdfOutlined style={{ color: "black", fontSize: "130%" }} />
            </a>
          ) : null,
    },
  ];

  return (
    <>
      <Row>
        <Col xs={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> POS TAHSİLATLARI
          </p>
        </Col>
        <Col xs={8}>
          <Row justify="end" align="middle" style={{ paddingRight: 16 }}>

          </Row>
        </Col>
      </Row>

      <PosCollectionTransactionTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Modal
        id="sendReceiptModal"
        centered
        visible={sendReceiptModal.visible}
        onCancel={() => setSendReceiptModal({ visible: false, data: null })}
        footer={null}
      >
        <B2bPosCollectionSendReceiptForm
          data={sendReceiptModal.data}
          onFinish={() => setSendReceiptModal({ visible: false, data: null })}
        />
      </Modal>
    </>
  );
};

export default PosCollectionTransaction;
