import React from "react";

const ChartCard = ({ title, totalBalance, icon, currency, color }) => {
  return (
    <div class="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6" >
      <div class="ant-card gx-card-widget gx-card-full ant-card-bordered">
        <div class="ant-card-body">
          <div class="ant-row">
            <div class="gx-actchart gx-px-3 gx-pt-3">
              <i class={`icon icon-${icon} gx-fs-sm`} style={{ fontSize: 35, marginLeft: 20, color: color }}></i>
            </div>
            <div class="gx-actchart gx-px-3 gx-pt-3">
              <h3 class="gx-chart-up gx-mb-1" style={{ color: 'black' }}>
                {totalBalance}
              </h3>
              <p class="gx-mb-0 gx-fs-sm gx-text-grey">{title}</p>
              <p class="gx-mb-0 gx-fs-sm gx-text-grey" style={{ height: 20 }}>{currency}</p>
            </div>
          </div>

          <div id="undefined" class="recharts-responsive-container" style={{ width: '100%', height: 15 }}>
            <div></div></div></div></div></div>
  );
};

export default ChartCard;
