import React, { useState, useEffect } from "react";
import RequestHandler from "util/RequestHandler";
import CommissionReportTableFilter from "../Filters/CommissionReportTableFilter";

import { Table, Empty, Col, Row, Tooltip } from "antd";
import { BarsOutlined, MenuUnfoldOutlined, WarningOutlined } from "@ant-design/icons";
import moment from "moment";
import { ColumnHelper } from "util/TableHelper";
import { Link } from "react-router-dom";

const CommissionReport = () => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
    queryDate: "",
  });

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });
    const { skip, take, filter, queryDate } = tableDataQueryOptions;

    RequestHandler.get(
      `/PosTransaction/CommissionReport?$count=true${filter}&$skip=${skip}&$top=${take}${queryDate ? "&queryDate=" + queryDate : ""}`
    ).then((response) => {
      if (Array.isArray(response)) {
        setTableData({
          data: response,
          total: response.length,
          loading: false,
        });
      }
    });
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
      queryDate: ""
    });
  };

  const onFinishTableFilter = (query, queryDate) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query, queryDate: queryDate };
    });
  };

  const renderCommissionDefinition = (data) => data == 0 ? (
    <Tooltip title="Komisyon tanımınız yok">
      <WarningOutlined style={{ color: "orange", fontSize: "130%" }} />
    </Tooltip>
  ) : (
    ColumnHelper.renderDecimal(data)
  );

  const renderCommissionAvarage = (definition, value) => {
    if (definition == 0) {
      return ColumnHelper.renderDecimal(value);
    }

    return value == 0 ? (
      <p style={{ opacity: 0.5, marginBottom: 0 }}>-</p>
    ) : (
      <p style={{ color: value <= definition ? "green" : "red", marginBottom: 0 }}>{ColumnHelper.renderDecimal(value)}</p>
    )
  };

  const renderDetailButton = (bankId, installmentCount, detailDate) => (
    <Link to={`/pos-transaction/${bankId}&${installmentCount}&${detailDate}`} >
      <MenuUnfoldOutlined style={{ fontSize: "130%" }} />
    </Link>
  )

  const queryDate = tableDataQueryOptions.queryDate ? tableDataQueryOptions.queryDate : undefined;
  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      width: "8%",
      render: (text) => <img src={text}></img>,
    },
    {
      title: "Taksit Sayısı",
      align: "center",
      dataIndex: "installmentCount",
    },

    {
      title: moment(queryDate).startOf("month").add(-2, "month").format("MMMM YYYY"),
      children: [
        {
          title: "Anlaşılan",
          align: "center",
          dataIndex: "twoMonthBeforeDefinition",
          render: renderCommissionDefinition,
        },
        {
          title: "Gerçekleşen",
          align: "center",
          dataIndex: "twoMonthBefore",
          render: (data, row) => renderCommissionAvarage(row.twoMonthBeforeDefinition, data),
        },
        {
          title: "Detay",
          dataIndex: "bankId",
          width: "1%",
          align: "center",
          render: (data, row) => renderDetailButton(data, row.installmentCount, moment(queryDate).startOf("month").add(-2, "month").format("YYYY-MM-01"))
        },
      ],
    },

    {
      title: moment(queryDate).startOf("month").add(-1, "month").format("MMMM YYYY"),
      children: [
        {
          title: "Anlaşılan",
          align: "center",
          dataIndex: "oneMonthBeforeDefinition",
          render: renderCommissionDefinition,
        },
        {
          title: "Gerçekleşen",
          align: "center",
          dataIndex: "oneMonthBefore",
          render: (data, row) => renderCommissionAvarage(row.oneMonthBeforeDefinition, data),
        },
        {
          title: "Detay",
          dataIndex: "bankId",
          width: "1%",
          align: "center",
          render: (data, row) => renderDetailButton(data, row.installmentCount, moment(queryDate).startOf("month").add(-1, "month").format("YYYY-MM-01"))
        },
      ],
    },

    {
      title: moment(queryDate).startOf("month").format("MMMM YYYY"),
      children: [
        {
          title: "Anlaşılan",
          align: "center",
          dataIndex: "currentMonthDefinition",
          render: renderCommissionDefinition,
        },
        {
          title: "Gerçekleşen",
          align: "center",
          dataIndex: "currentMonth",
          render: (data, row) => renderCommissionAvarage(row.currentMonthDefinition, data),
        },
        {
          title: "Detay",
          dataIndex: "bankId",
          width: "1%",
          align: "center",
          render: (data, row) => renderDetailButton(data, row.installmentCount, moment(queryDate).startOf("month").format("YYYY-MM-01"))
        },
      ],
    },

  ];

  return (
    <>
      <Row>
        <Col xs={15} lg={20}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> KOMİSYON RAPORU
          </p>
        </Col>
      </Row>

      <CommissionReportTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
        extraText="Varsayılan olarak bu ay ve önceki 2 ayın verisi
        listelenmektedir."
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey={(record) => `${record.bankId}-${record.installmentCount}`}
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
            bordered={true}
          />
        </Col>
      </Row>
    </>
  );
};

export default CommissionReport;
