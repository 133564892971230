import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import RequestHandler from "util/RequestHandler";
import MicroErpFirmReportTableFilter from "../../../Filters/MicroErpFirmReportTableFilter";
import MicroErpFirmReportDetail from "./ErpFirmReportDetail";

import { Table, Empty, Col, Row, Tabs, Modal } from "antd";
import {
  BarsOutlined,
  InfoCircleOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { odata } from "../../../Filters/Helper";

const MicroErpFirmReport = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    activeTab: "99",
    filter: "",
    order: "",
  });
  const [detailModal, setDetailModal] = useState({
    visible: false,
  });
  const history = useHistory();

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });
    const { skip, take, filter, activeTab, order } = tableDataQueryOptions;
    RequestHandler.get(
      `/ErpReport/GetFirmReport?$count=true${filter}&$skip=${skip}&$top=${take}${order}&currency=${activeTab}`
    ).then((response) => {
      setTableData({
        data: response[0].value,
        total: response[0]["@odata.count"],
        loading: false,
      });
    });
  };

  const onTableChange = (pagination, filters, sorter) => {
    let order = "";
    if (sorter.columnKey && sorter.order) {
      order =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (pagination.current - 1) * prevState.take,
        take: pagination.pageSize,
        current: pagination.current,
        order: order,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      ...tableDataQueryOptions,
      skip: 0,
      current: 1,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { ...prevState, skip: 0, current: 1, filter: query };
    });
  };

  const handleOnClickDetail = (rowData) => {
    setDetailModal({
      firmCode: rowData.firmCode,
      visible: true,
    });
  };

  const handleOnClickInvoice = (rowData) => {
    history.push(
      `erp-firm-invoice/${rowData.firmCode}/${tableDataQueryOptions.activeTab}`
    );
  };

  const onTabChange = (tabKey) => {
    setTableDataQueryOptions({
      ...tableDataQueryOptions,
      skip: 0,
      current: 1,
      activeTab: tabKey,
    });
  };

  const columns = [
    {
      title: "Cari Kodu",
      dataIndex: "firmCode",
    },
    {
      title: "Cari Adı",
      dataIndex: "name",
    },
    {
      title: "Bakiye",
      dataIndex: "balanceString",
      key: "balance",
      sorter: true,
    },
    {
      title: "Detay",
      dataIndex: "firmCode",
      align: "center",
      render: (data, row) => (
        <a onClick={() => handleOnClickDetail(row)}>
          <InfoCircleOutlined style={{ fontSize: "130%" }} />
        </a>
      ),
    },
    {
      title: "Faturalar",
      dataIndex: "firmCode",
      align: "center",
      render: (data, row) => (
        <a onClick={() => handleOnClickInvoice(row)}>
          <FileTextOutlined style={{ color: "orange", fontSize: "130%" }} />
        </a>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col xs={15} lg={20}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> CARİ TAKİP
          </p>
        </Col>
      </Row>

      <MicroErpFirmReportTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Tabs
        defaultActiveKey={tableDataQueryOptions.activeTab}
        onChange={onTabChange}
      >
        <Tabs.TabPane tab="Tümü" key="99" />
        <Tabs.TabPane tab="TRY" key="0" />
        <Tabs.TabPane tab="USD" key="1" />
        <Tabs.TabPane tab="EUR" key="2" />
      </Tabs>

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{
              overflowX: "auto",
              maxHeight: "100%",
              width: "100%",
            }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={onTableChange}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Modal
        id="detailModal"
        centered
        visible={detailModal.visible}
        onOk={() => setDetailModal({ ...detailModal, visible: false })}
        onCancel={() => setDetailModal({ ...detailModal, visible: false })}
        width={1200}
        footer={null}
      >
        <MicroErpFirmReportDetail
          firmCode={detailModal.firmCode}
          currency={tableDataQueryOptions.activeTab}
        />
      </Modal>
    </>
  );
};

export default MicroErpFirmReport;
