import React, { useState, useEffect } from "react";
import RequestHandler from "util/RequestHandler";
import moment from "moment";
import ValueReportTableFilter from "../Filters/ValueReportTableFilter";
import { ColumnHelper } from "util/TableHelper";

import { Table, Empty, Col, Row } from "antd";

const ValueReportOperationDateBased = (props) => {
  const defaultQueryData = {
    pageSize: 15,
  };
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] =
    useState(defaultQueryData);

  useEffect(
    () => getTableData(),
    [
      tableDataQueryOptions.bankId,
      tableDataQueryOptions.startDate,
      tableDataQueryOptions.endDate,
    ]
  );

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    RequestHandler.post(
      `/PosTransaction/OperationDateBasedValueReport`,
      tableDataQueryOptions,
      false
    ).then((response) => {
      setTableData({
        data: response.value,
        total: response.totalCount,
        totalRow: response.totalRow,
        loading: false,
      });
    });

    if (props.onFilterChange) {
      props.onFilterChange(tableDataQueryOptions);
    }
  };

  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        pageSize: p.pageSize,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions(defaultQueryData);
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        ...query,
      };
    });
  };

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      width: "8%",
      render: (text) => <img src={text}></img>,
    },
    {
      title: "Valör Tarihi",
      dataIndex: "date",
      render: (data, row, index) => moment(data).format("DD.MM.YYYY"),
    },
    {
      title: "İşlem Tarihi",
      dataIndex: "operationDate",
      render: (text, row, index) => moment(text).format("DD.MM.YYYY"),
    },
    {
      title: "İşlem Tutarı",
      dataIndex: "amount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Komisyon Tutarı",
      dataIndex: "commissionAmount",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Komisyon Oranı(%)",
      dataIndex: "commissionRate",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Net Tutar",
      dataIndex: "netAmount",
      render: ColumnHelper.renderDecimal,
    },
  ];

  return (
    <>
      <ValueReportTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
        extraText="Varsayılan olarak bugünden itibaren 30 günlük veri
        listelenmektedir."
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey={(record, index) => index}
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.pageSize,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
            summary={() => {
              if (!tableData.totalRow) return;

              const { amount, commissionAmount, netAmount } =
                tableData.totalRow;
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>Toplam:</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {ColumnHelper.renderDecimal(amount)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={2}>
                    {ColumnHelper.renderDecimal(commissionAmount)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {ColumnHelper.renderDecimal(netAmount)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ValueReportOperationDateBased;
