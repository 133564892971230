import React, { useEffect, useState } from 'react';
import { Form, Col, Input, notification, Button, Transfer } from 'antd';
import config from "../../util/ApiforContext"
import { useMediaQuery } from 'react-responsive'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Replace2x from '../../util/Extensions/ReplaceExtension'
const userId = localStorage.getItem("userId");
const expiration = localStorage.getItem("expiration");
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const GroupUpdate = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const history = useHistory();
  const [userList, setUserList] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [groupName, setGroupName] = useState(props.groupName);
  const [loading, setLoading] = useState(false);
  const [mockDataTenant, setmockDataTenant] = useState([]);
  const [mockDataUser, setmockDataUser] = useState([]);
  const [tenants, SetTenants] = useState([]);
  const [targetKeysTenant, setTargetKeysTenant] = useState([]);
  const [groupId, setGroupId] = useState(props.groupId);
  const errorList = [];
  const [targetKeysUser, setTargetKeysUser] = useState([]);
  const errorListNotification = type => {
    errorList.map((item) => {
      notification[type]({
        message: 'Hata',
        description:
          item
        ,
        duration: 2,
      });
    })
  };
  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'Ekleme işlemi başarı ile gerçekleşti.',
      duration: 2,
    });
  };
  const openNotificationWithIconWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Ekleme işlemi sırasında bir hata oluştu.',
      duration: 2,
    });
  };
  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload()
    }
    axios.get(`${config.apiUrl}/User/GetUserTenants/${Replace2x(userId)}`, config.headers)
      .then(res => {
        getMockTenant(res.data);
        const tenantList = [];
        res.data.map((item) => {
          tenantList.push(item.id)
        })
        axios.post(`${config.apiUrl}/User/GetUserListForTenants`, {
          tenants: tenantList
        }, config.headers)
          .then(res => {
            getMockUser(res.data)
          }).catch(
            error => {
              if (error.response.status === 403 || error.response.status === 401) {
                history.push('/not-authorized-access');
              } else if (error.response.status === 406) {
                error.response.data.data.map((item) => {
                  errorList.push(item);
                })
                errorListNotification('error')
                setLoading(false)
              }
            }
          );
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
            setLoading(false)
          }
        }
      );

    axios.get(`${config.apiUrl}/Group/GetTenantGroup/${props.groupId}`, config.headers)
      .then(res => {
        const tenants = [];
        res.data.map((item) => {
          tenants.push(item.tenantId)
        })
        setTenantList(tenants)
        setTargetKeysTenant(tenants)
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
            setLoading(false)
          }
        }
      );
    axios.get(`${config.apiUrl}/Group/GetUserGroup/${props.groupId}`, config.headers)
      .then(res => {
        const users = [];
        res.data.map((item) => {
          users.push(item.userId)
        })
        setUserList(users)
        setTargetKeysUser(users)
      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
            setLoading(false)
          }
        }
      );
    setGroupId(props.groupId)
    setGroupName(props.groupName)
  }, [SetTenants, setGroupName, props.groupName, setTargetKeysUser, setTargetKeysTenant, setGroupId, props.groupId]);
  const onChangeName = (e) => {
    setGroupName(e.target.value)
  }
  const onFinish = (values) => {
    axios.put(`${config.apiUrl}/Group`, {
      Id: groupId,
      Name: values.name,
      TenantIds: targetKeysTenant,
      UserIds: targetKeysUser
    }, config.headers)
      .then(res => {
        openNotificationWithIcon('success');
        setTimeout(function () {
          window.location.reload();
        }.bind(this), 2000)

      }).catch(
        error => {
          if (error.response.status === 403 || error.response.status === 401) {
            history.push('/not-authorized-access');
          } else if (error.response.status === 406) {
            error.response.data.data.map((item) => {
              errorList.push(item);
            })
            errorListNotification('error')
            setLoading(false)
          } else {
            openNotificationWithIconWarning('error')
            setLoading(false)
          }
        }
      );
  };
  const onFinishFailed = (errorInfo) => {
  };
  const getMockTenant = (value) => {
    const targetKeysTenant = [];
    const mockDataTenant = [];
    value.map((item, index) => {
      const data = {
        key: item.id,
        title: item.name,
        description: ``,
      };
      if (data.chosen) {
        targetKeysTenant.push(data.key);
      }
      mockDataTenant.push(data);
    })
    setmockDataTenant(mockDataTenant)
  };
  const getMockUser = (value) => {
    const targetKeysUser = [];
    const mockDataUser = [];
    value.map((item, index) => {
      const data = {
        key: item.id,
        title: item.firstName,
        description: ``,
      };
      if (data.chosen) {
        targetKeysUser.push(data.key);
      }
      mockDataUser.push(data);
    })
    setmockDataUser(mockDataUser)
  };
  const filterOptionUser = (inputValue, option) => option.description.indexOf(inputValue) > -1;

  const handleChangeUser = targetKeysUser => {
    setTargetKeysUser(targetKeysUser)
  };

  const handleSearchUser = (dir, value) => {
  };
  const filterOptionTenant = (inputValue, option) => option.description.indexOf(inputValue) > -1;

  const handleChangeTenant = targetKeysTenant => {
    setTargetKeysTenant(targetKeysTenant)
  };

  const handleSearchTenant = (dir, value) => {
  };

  return (
    <div>
      {isDesktopOrLaptop && <>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          fields={[
            { name: "name", value: groupName },
          ]}
        >
          <Form.Item
            label="Grup Adı"
            name="name"
            rules={[
              {
                required: true,
                message: 'İsim alanını boş bırakmayın',
              },
            ]}
          >
            <Input onChange={onChangeName} />
          </Form.Item>
          <Form.Item
            label="Şirketler"
            name="users"
          >
            <Transfer
              dataSource={mockDataTenant}
              showSearch
              locale={
                {
                  searchPlaceholder: "Arama Yapınız",
                  notFoundContent: 'Liste Boş',
                  itemUnit: 'Şirket',
                  itemsUnit: 'Şirket',
                  selectAll: 'Hepsini Seç',
                  selectInvert: 'Seçimleri Kaldır'
                }
              }
              listStyle={{
                width: 300,
                height: 250,
              }}
              titles={['(-)', '(+)']}
              filterOption={filterOptionTenant}
              targetKeys={targetKeysTenant}
              onChange={handleChangeTenant}
              onSearch={handleSearchTenant}
              render={item => item.title}
            />
          </Form.Item>
          <Form.Item
            label="Kullanıcılar"
            name="tenants"
          >

            <Transfer
              dataSource={mockDataUser}
              showSearch
              locale={
                {
                  searchPlaceholder: "Arama Yapınız",
                  notFoundContent: 'Liste Boş',
                  itemUnit: 'Kullanıcı',
                  itemsUnit: 'Kullanıcı',
                  selectAll: 'Hepsini Seç',
                  selectInvert: 'Seçimleri Kaldır'
                }
              }
              listStyle={{
                width: 300,
                height: 250,
              }}
              titles={['(-)', '(+)']}

              filterOption={filterOptionUser}
              targetKeys={targetKeysUser}
              onChange={handleChangeUser}
              onSearch={handleSearchUser}
              render={item => item.title}
            />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" style={{ marginLeft: '100%' }} htmlType="submit" onClick={() => setLoading(true)} loading={loading}>
              Güncelle
            </Button>
          </Form.Item>
        </Form>
      </>}
      {isTabletOrMobileDevice && <>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          fields={[
            { name: "name", value: groupName },
          ]}
        >
          <Form.Item
            label="Grup Adı"
            name="name"
            rules={[
              {
                required: true,
                message: 'İsim alanını boş bırakmayın',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Şirketler"
            name="users"
          >
            <Transfer
              dataSource={mockDataTenant}
              style={{ marginLeft: '-24%' }}
              showSearch
              locale={
                {
                  searchPlaceholder: "Arama Yapınız",
                  notFoundContent: 'Liste Boş',
                  itemUnit: 'Şirket',
                  itemsUnit: 'Şirket',
                  selectAll: 'Hepsini Seç',
                  selectInvert: 'Seçimleri Kaldır'
                }
              }
              listStyle={{
                width: 300,
                height: 250,
              }}
              titles={['(-)', '(+)']}
              filterOption={filterOptionTenant}
              targetKeys={targetKeysTenant}
              onChange={handleChangeTenant}
              onSearch={handleSearchTenant}
              render={item => item.title}
            />
          </Form.Item>
          <Form.Item
            label="Kullanıcılar"
            name="tenants"
          >

            <Transfer
              dataSource={mockDataUser}
              showSearch
              style={{ marginLeft: '-24%' }}
              locale={
                {
                  searchPlaceholder: "Arama Yapınız",
                  notFoundContent: 'Liste Boş',
                  itemUnit: 'Kullanıcı',
                  itemsUnit: 'Kullanıcı',
                  selectAll: 'Hepsini Seç',
                  selectInvert: 'Seçimleri Kaldır'
                }
              }
              listStyle={{
                width: 300,
                height: 250,
              }}
              titles={['(-)', '(+)']}

              filterOption={filterOptionUser}
              targetKeys={targetKeysUser}
              onChange={handleChangeUser}
              onSearch={handleSearchUser}
              render={item => item.title}
            />
          </Form.Item>

          <Col span={20}>
            <Form.Item {...tailLayout}>
              <Button type="primary" style={{ marginLeft: '100%' }} htmlType="submit" onClick={() => setLoading(true)} loading={loading}>
                Güncelle
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </>}

    </div>

  );
}

export default GroupUpdate