import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Select,
  Collapse,
  InputNumber,
  DatePicker,
} from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { odata } from "./Helper";

const ErpExportTransactionTableFilter = (props) => {
  const [form] = Form.useForm();
  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: true,
  });
  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    RequestHandler.get(`/Bank/GetErpDropdown`).then((response) => {
      setBankSelect({
        data: response,
        loading: false,
      });
    });
  }, []);

  const onFirmSearch = (value) => {
    if (value.length >= 2) {
      setFirmSelect({ ...firmSelect, loading: true });
      RequestHandler.get(
        `/Firm?$select=id,name&$filter=${odata.contains("name", value)}`
      ).then((res) => {
        if (res.value) {
          setFirmSelect({ data: res.value, loading: false });
        }
      });
    } else {
      setFirmSelect({ ...firmSelect, data: [] });
    }
  };

  const onFinish = (values) => {
    {
      let query = "";
      const conditions = [];

      if (values.bankId) {
        conditions.push(`bankId eq ${values.bankId}`);
      }

      if (values.firmId) {
        conditions.push(`firmId eq ${values.firmId}`);
      }

      if (values.amountMin) {
        conditions.push(`amount Ge ${values.amountMin}`);
      }

      if (values.amountMax) {
        conditions.push(`amount Le ${values.amountMax}`);
      }

      if (values.transactionDateRange) {
        const [startDate, endDate] = values.transactionDateRange;
        conditions.push(
          odata.dateRange("transactionDate", startDate, endDate)
        );
      }

      if (values.description) {
        conditions.push(odata.contains("description", values.description));
      }

      if (conditions.length !== 0) {
        query = conditions.join(" and ");
      }

      if (props.onFinishFilter) props.onFinishFilter(query);
    }
  };

  const clearFilter = () => {
    form.resetFields();
    setFirmSelect({ data: [] });

    if (props.onClearFilter) {
      props.onClearFilter();
    }
  };

  return (
    <Row id="filterArea" style={{ ...props.style }}>
      <Col span={24}>
        <Collapse>
          <Collapse.Panel header="Filtreleme için tıklayınız.">
            <Form
              name="filter"
              form={form}
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row style={{ flexDirection: "row" }}>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="bankId" label="Banka :">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Tümü"
                      loading={bankSelect.loading}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {bankSelect.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="firmId" label="Cari :">
                    <Select
                      allowClear
                      showSearch
                      placeholder="En az 2 harf giriniz.."
                      loading={firmSelect.loading}
                      onSearch={onFirmSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {firmSelect.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item label="Tutar :" style={{ marginBottom: 0 }}>
                    <Input.Group>
                      <Row gutter={1} style={{ flexDirection: "row" }}>
                        <Col span={12}>
                          <Form.Item name="amountMin">
                            <InputNumber min={0} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="amountMax">
                            <InputNumber min={0} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="transactionDateRange" label="Tarih :">
                    <DatePicker.RangePicker format="DD.MM.YYYY" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="description" label="Açıklama :">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ flexDirection: "row" }}>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    danger
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={() => clearFilter()}
                  >
                    Sıfırla
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    icon={<SearchOutlined />}
                    style={{ color: "#40a9ff", borderColor: "#40a9ff" }}
                    htmlType="submit"
                  >
                    Filtrele
                  </Button>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};
export default ErpExportTransactionTableFilter;
