import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Empty,
  notification,
  Table,
  Col,
  Tooltip,
  Row,
  Popconfirm,
  Tag,
  Button,
} from "antd";
import "react-phone-input-2/lib/style.css";
import { BarsOutlined, MailOutlined, WarningOutlined,RollbackOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../util/ApiforContext";
import { useMediaQuery } from "react-responsive";
import RequestHandler from "util/RequestHandler";
const expiration = localStorage.getItem("expiration");

const SubscriberFirmPanelInformation = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const [tenantSubscriberUsers, setTenantSubscriberUsers] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [subscriberFirmName, setSubscriberFirmName] = useState("");
  const [subscriberFirmAddress, setSubscriberFirmAddress] = useState("");
  const errorList = [];
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 2,
      });
    });
  };
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "İşlem Başarılı.",
      duration: 1,
    });
  };
  const history = useHistory();

  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }
    setTenantSubscriberUsers({
      ...tenantSubscriberUsers,
      loading: true,
    });

    axios
      .get(
        `${config.apiUrl}/SubscriberFirm/GetById/${props.match.params.firmId}`,
        config.headers
      )
      .then((res) => {
        setSubscriberFirmName(res.data[0].name);
        setSubscriberFirmAddress(res.data[0].address);
        setTenantSubscriberUsers({
          data: res.data[0].tenantSubscriberUsersVm.filter(
            (item) => item.isActive === true
          ),
          total: res.data["@odata.count"],
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
      });
  }, [props]);

  const dataStateChange = (e) => {
    setTenantSubscriberUsers(e);
  };

  const sendCreatePasswordEmail = (subscriberUserId) => {
    setTenantSubscriberUsers({
      loading: true,
    });
    RequestHandler.post(
      "/SubscriberUser/SendCreatePasswordMail/" + subscriberUserId
    ).then((response) => {
      if (response.success) {
        setTimeout(
          function () {
            window.location.reload();
          }.bind(this),
          300
        );
      } else {
        setTenantSubscriberUsers({
          loading: false,
        });
      }
    });
  };

  const columns = [
    {
      title: "Daire No",
      dataIndex: "",
      key: "subscriberFirmName",
      align: "left",
      render: () => {
        return subscriberFirmName;
      },
    },
    {
      title: "Apartman-Site",
      dataIndex: "",
      key: "subscriberFirmAddress",
      align: "left",
      render: () => {
        return subscriberFirmAddress;
      },
    },
    {
      title: "Ev Sahibi - Kiracı",
      dataIndex: "subscriberUserVm",
      key: "subscriberUserName",
      align: "left",
      render: (data) => {
        return data.name;
      },
    },
    {
      title: "Ev Sahibi mi? - Kiracı mı?",
      dataIndex: "",
      key: "subscriberUserStatus",
      align: "left",
      render: (data) => {
        return data.isHirer === false ? "Ev Sahibi" : "Kiracı";
      },
    },
    {
      title: "E-Mail",
      dataIndex: "subscriberUserVm",
      key: "subscriberUserEmail",
      align: "left",
      render: (data) => {
        return data.email != null ? (
          data.email
        ) : (
          <Tooltip
            title={
              "İlgili kullanıcının E-mail'i tanımlanmadığından, Üye Paneli işlemleri yapılamamaktadır."
            }
          >
            <WarningOutlined style={{ color: "orange", fontSize: "130%" }} />
          </Tooltip>
        );
      },
    },
    {
      title: "Şifre Gönder",
      dataIndex: "subscriberUserVm",
      align: "center",
      render: (data) => {
        return data.isPasswordSent ||
          data.email == null ||
          data.isPasswordCreated ? (
          ""
        ) : (
          <Button
            type="link"
            style={{ marginBottom: 0 }}
            onClick={() => sendCreatePasswordEmail(data.id)}
          >
            <MailOutlined style={{ fontSize: "130%", color: "#531dab" }} />
          </Button>
        );
      },
    },
    {
      title: "Şifre Durumu",
      dataIndex: "subscriberUserVm",
      align: "center",
      render: (data) => {
        let color = data.isPasswordCreated ? "green" : "orange";
        let text = data.isPasswordCreated ? "Oluşturuldu" : "Bekliyor";
        return data.isPasswordSent || data.isPasswordCreated ? (
          <Tag color={color}>{text}</Tag>
        ) : (
          ""
        );
      },
    },
    {
      title: "Şifre Maili",
      dataIndex: "subscriberUserVm",
      align: "center",
      render: (data, row) =>
        data.email == null ||
        data.isPasswordSent === false ||
        data.isPasswordCreated ? null : (
          <Popconfirm
            title="Kullanıcıya şifrenizi oluşturun maili yollamak istiyor musunuz?"
            okText="Evet"
            cancelText="Hayır"
            onConfirm={(e) => sendCreatePasswordEmail(data.id)}
          >
            <Button type="link" style={{ marginBottom: 0 }}>
              <MailOutlined style={{ fontSize: "130%" }} />
            </Button>
          </Popconfirm>
        ),
    },
  ];

  return (
    <div>
       <Row style={{marginBottom:9}}>
         <Col flex={1}><a onClick={() => history.goBack()} style={{color:'black',marginLeft:"4%"}}> <RollbackOutlined /> GERİ DÖN</a></Col>
         <Col flex={4}></Col>
       </Row>
      <Row>
        {isTabletOrMobileDevice && (
          <>
            <Col span={13}>
              <p style={{ fontSize: "120%" }}>
                <BarsOutlined /> PANEL BİLGİLERİ
              </p>
              <hr></hr>
              <br></br>
            </Col>
          </>
        )}
        {isDesktopOrLaptop && (
          <>
            <Col span={20}>
              <p style={{ fontSize: "120%", marginTop: -9 }}>
                <BarsOutlined /> PANEL BİLGİLERİ
              </p>
            </Col>
          </>
        )}
      </Row>
      <Row>
        {isDesktopOrLaptop && (
          <>
            <Col span={24}>
              <Table
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Veri Yok"
                    />
                  ),
                }}
                style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
                columns={columns}
                dataSource={tenantSubscriberUsers.data}
                onDataStateChange={dataStateChange}
                loading={tenantSubscriberUsers.loading}
                pagination={false}
                sortable={true}
                rowKey="id"
                showHeader={true}
              ></Table>
            </Col>
          </>
        )}
        {isTabletOrMobileDevice && (
          <>
            <Col span={24}>
              <Table
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Veri Yok"
                    />
                  ),
                }}
                style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
                columns={columns}
                dataSource={tenantSubscriberUsers.data}
                onDataStateChange={dataStateChange}
                loading={tenantSubscriberUsers.loading}
                pagination={false}
                sortable={true}
                rowKey="id"
                showHeader={true}
              ></Table>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
export default SubscriberFirmPanelInformation;
