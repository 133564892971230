import React, { useEffect, useState } from "react";
import RequestHandler from "util/RequestHandler";
import moment from "moment";

import { Table, Empty, Col, Row, Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ColumnHelper } from "util/TableHelper";
import NetsisErpFirmInvoiceReportDetail from "./ErpFirmInvoiceReportDetail";

const NetsisErpFirmInvoiceReport = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: false,
  });
  const [detailModalVisible, setDetailModalVisible] = useState();
  const [detailModalDocumentNumber, setDetailModalDocumentNumber] = useState();

  useEffect(() => getTableData(), [props.activeFirmCode]);

  const getTableData = () => {
    if (!props.activeFirmCode) return;

    setTableData({ ...tableData, loading: true });
    RequestHandler.get(`/ErpReport/NetsisFirmInvoiceReport/${props.activeFirmCode}`).then((response) => {
      setTableData({
        data: response,
        total: response.length,
        loading: false,
      });
    });
  };

  const onClickDetail = (documentNumber) => {
    setDetailModalDocumentNumber(documentNumber);
    setDetailModalVisible(true);
  };

  const columns = [
    {
      title: "Tarih",
      dataIndex: "date",
      align: "center",
      render: (text, row, index) => moment(text).format("DD.MM.YYYY"),
    },
    {
      title: "Belge No",
      dataIndex: "documentNumber",
    },
    {
      title: "Genel Toplam",
      dataIndex: "generalTotal",
      render: ColumnHelper.renderDecimal,
    },
    {
      title: "Detay",
      dataIndex: "documentNumber",
      align: "center",
      render: (data, row) => (
        <a onClick={() => onClickDetail(data)}>
          <InfoCircleOutlined style={{ fontSize: "130%" }} />
        </a>
      ),
    },
  ];

  return (
    <>
      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={props.activeFirmCode ? "Veri Yok" : "Önce Cari Seçmelisiniz"}
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            pagination={{
              defaultPageSize: 15,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Modal
        id="detailModal"
        centered
        visible={detailModalVisible}
        onOk={() => setDetailModalVisible(false)}
        onCancel={() => setDetailModalVisible(false)}
        width={1200}
        footer={null}
      >
        <NetsisErpFirmInvoiceReportDetail documentNumber={detailModalDocumentNumber} />
      </Modal>
    </>
  );
};

export default NetsisErpFirmInvoiceReport;
