import React, { useEffect, useState } from "react";
import {
  Table,
  Empty,
  notification,
  Row,
  Col,
  message,
  Tooltip,
} from "antd";
import {
  BarsOutlined,
  RollbackOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import axios from "axios";
import CurrencyFormat from "react-currency-format";
import { useHistory } from "react-router-dom";
import config from "../../util/ApiforContext";
import moment from "moment";
import RequestHandler from "util/RequestHandler";
import { ColumnHelper } from "../../util/TableHelper";
const expiration = localStorage.getItem("expiration");

const AccountPage = (props) => {
  const [firmMonthlyStatusReport, setFirmMonthlyStatusReport] = useState({
    data: [],
    total: 0,
    loading: true,
  });

  const [skip, setSkip] = useState(0);
  const [query, setQuery] = useState("");
  const [currencyString, setCurrencyString] = useState("");
  const [dataState, setDataState] = useState({ skip: 0, take: 20 });
  const [firmName, setFirmName] = useState("");
  const [firmId, setFirmId] = useState(props.match.params.firmid);
  const [TransactionDate, setTransactionDate] = useState(
    props.match.params.transactionDate
  );
  const [TransactionDate2, setTransactionDate2] = useState(
    props.match.params.transactionDate2
  );
  const [Currency, setCurrency] = useState(props.match.params.currency);
  const [balanceDate, setBalanceDate] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);

  const history = useHistory();
  const errorList = [];
  const errorListNotification = (type) => {
    errorList.map((item) => {
      notification[type]({
        message: "Hata",
        description: item,
        duration: 2,
      });
    });
  };
  useEffect(() => {
    if (expiration < Date.now()) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (localStorage.getItem("token") == null) {
      window.location.reload();
    }

    setFirmMonthlyStatusReport({
      ...firmMonthlyStatusReport,
      loading: true,
    });
    axios
      .post(
        `${config.apiUrl}/Reports/GetMonthlyCollectionStatusReport`,
        {
          FirmId: firmId,
          Date:
            TransactionDate === "" || TransactionDate == "null"
              ? null
              : TransactionDate,
          EndDate:
            TransactionDate2 === "" || TransactionDate2 == "null"
              ? null
              : TransactionDate2,
          CurrencyCode: Currency === "" ? null : Currency,
        },
        config.headers
      )
      .then((res) => {
        setCurrencyString(" TRY");
        setTotalAmount(res.data.at(-1).dateTimeNowBalance);
        setBalanceDate(
          moment(
            res.data.at(-2).transactionDate.split("/")[0] +
            (res.data.at(-2).transactionDate.split("/")[1].length > 1
              ? res.data.at(-2).transactionDate.split("/")[1]
              : "0" + res.data.at(-2).transactionDate.split("/")[1]) +
            "13"
          ).format("MMMM YYYY") + " -"
        );
        setFirmMonthlyStatusReport({
          data: res.data,
          total: res.data["@odata.count"],
          loading: false,
        });
        setFirmName(res.data[0].collectPlanTransactionReportVms[0].firmName);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          history.push("/not-authorized-access");
        } else if (error.response.status === 406) {
          error.response.data.data.map((item) => {
            errorList.push(item);
          });
          errorListNotification("error");
        }
        setFirmMonthlyStatusReport({
          loading: false,
        });
      });
  }, [setDataState, skip, setSkip, query, setQuery, setFirmId]);

  const renderDecimal = (value) =>
    value !== null ? ColumnHelper.renderDecimal(value) : null;

  const columns = [
    {
      title: "Tarih",
      dataIndex: "transactionDate",
      key: "transactionDate",
      align: "center",
      style: "color:green",
      width: "25%",
      render: (text, record) => {
        return (
          <p style={{ fontWeight: "700" }}>
            {text == "Toplam"
              ? " Toplam "
              : moment(
                text.split("/")[0] +
                (text.split("/")[1].length > 1
                  ? text.split("/")[1]
                  : "0" + text.split("/")[1]) +
                "13"
              ).format("MMM YYYY")}
          </p>
        );
      },
    },
    {
      title: "Tahsilat Planı",
      dataIndex: "collectPlanTotal",
      key: "collectPlanTotal",
      align: "center",
      width: "25%",
      render: renderDecimal,
    },
    {
      title: "Gerçekleşen Tahsilat",
      dataIndex: "collectTotal",
      key: "collectTotal",
      align: "center",
      width: "25%",
      render: renderDecimal,
    },
    {
      title: "Bakiye",
      dataIndex: "balance",
      key: "balance",
      align: "center",
      width: "25%",
      render: renderDecimal,
    },
  ];

  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);
    RequestHandler.post(
      `/Reports/GetMonthlyCollectionStatusPlanForExcel`,
      {
        FirmId: firmId,
        Date:
          TransactionDate === "" || TransactionDate == "null"
            ? null
            : TransactionDate,
        EndDate:
          TransactionDate2 === "" || TransactionDate2 == "null"
            ? null
            : TransactionDate2,
        CurrencyCode: Currency === "" ? null : Currency,
      },
      false
    ).then((response) => {
      hideMessage();
      if (response) {
        window.open(
          `${config.apiUrl
          }/Reports/DownloadExcel?fileName=${response}&t=${RequestHandler.getToken()}`
        );
      }
    });
  };

  const expandedRowRender = (data) => {
    const columns = [
      {
        title: "Tarih",
        dataIndex: "transactionDate",
        key: "transactionDate",
        width: "20%",
      },
      {
        title: "İşlem Tipi",
        dataIndex: "processTypeName",
        key: "processTypeName",
        width: "20%",
      },
      {
        title: "Plan",
        dataIndex: "collectPlanTotal",
        key: "collectPlanTotal",
        width: "20%",
        render: renderDecimal,
      },
      {
        title: "Gelen",
        dataIndex: "collectTotal",
        key: "collectTotal",
        width: "20%",
        render: renderDecimal,
      },
      {
        title: "Bakiye",
        dataIndex: "balance",
        key: "balance",
        width: "20%",
        render: renderDecimal,
      },
    ];

    return (
      <Table
        columns={columns}
        size="small"
        dataSource={data}
        pagination={false}
      />
    );
  };

  return (
    <div>
      <Row style={{ marginBottom: 9 }}>
        <Col flex={1}>
          <a
            onClick={() => history.goBack()}
            style={{ color: "black", marginLeft: "4%" }}
          >
            {" "}
            <RollbackOutlined /> GERİ DÖN
          </a>
        </Col>
        <Col flex={4}></Col>
      </Row>
      <Row>
        <Col xs={24} md={12} lg={20}>
          <p style={{ fontSize: "120%", marginLeft: 13 }}>
            <BarsOutlined /> AYLIK TAHSİLAT RAPORU
          </p>
        </Col>
        <Col
          xs={24}
          md={12}
          lg={4}
          style={{ textAlign: "right" }}
          hidden={firmMonthlyStatusReport.data == undefined ? true : false}
        >
          <Tooltip title="Excel İndir">
            <a
              style={{
                color: "green",
                fontSize: "180%",
                marginRight: 30,
              }}
              onClick={onClickExportExcel}
            >
              <FileExcelOutlined />
            </a>
          </Tooltip>
        </Col>
      </Row>
      <Row>
        <Col span={12} style={{ textAlign: "start", fontSize: 19 }}>
          <p>{firmName}</p>
        </Col>
        <Col span={12} style={{ textAlign: "end", fontSize: 19 }}>
          {balanceDate} Cari Bakiye :{" "}
          <CurrencyFormat
            value={parseFloat(totalAmount)}
            displayType={"text"}
            decimalSeparator={","}
            thousandSeparator={"."}
          />
          {Currency == "null" ? currencyString : " " + Currency}
        </Col>
      </Row>

      <Table
        columns={columns}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Veri Yok"
            />
          ),
        }}
        expandable={{
          expandedRowRender: (record) =>
            expandedRowRender(record.collectPlanTransactionReportVms),
          rowExpandable: (record) =>
            record.collectPlanTransactionReportVms !== null,
        }}
        style={{ overflowX: "auto", maxHeight: "100%", tableLayout: "unset" }}
        size="small"
        rowKey="transactionDate"
        sortable={true}
        dataSource={firmMonthlyStatusReport.data}
        loading={firmMonthlyStatusReport.loading}
        pagination={false}
      />
    </div>
  );
};

export default AccountPage;
