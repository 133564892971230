import React,{useEffect,useState} from "react";
import {Button,notification,Result,message, Form, Input} from "antd";
import {useHistory} from "react-router-dom";
import axios from 'util/Api'
import config from "../util/ApiforContext"
import Screen from '../util/Extensions/Screens'
const FormItem = Form.Item;

const ResetPassword = (props) => {
const history = useHistory();
var errorList = [];

  const errorListNotification = type => {
    errorList.map((item)=> {
      notification[type]({
        message: 'Hata',
        description:
        item
         ,
          duration: 4,
      });
    })
    errorList = []
  };
  const PsrChange90Days = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Şifrenizi 90 gün süre ile değiştirmeniz gerekmektedir.',
        duration: 6,
    });
  };
  const [isHavePsrGuid, setIsHavePsrGuid] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // if(props.match.params.isPsrExpired === "true"){
    //   PsrChange90Days("error");
    // }
    axios.get(`${config.apiUrl}/auth/IsHavePsrGuid?PsrGuid=${props.match.params.psrGuid}`,
     {})
     .then(res => {
        if(res.status==200){
          setIsHavePsrGuid(true)
             }
         }).catch(
          error => {
            if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
              setTimeout(() => {
                window.location.href="/signin";
              }, 1000); 
            }else{
              openNotificationWithIconWarning('error')}
             }
      );
  }, [])
  const onFinishFailed = errorInfo => {
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}
let hide = () => {};
  const onFinish = values => {
    const hide = message.loading('İşleminiz gerçekleştiriliyor..', 0);
    if(values.password !== values.confirm){
        NotSamePasswordWarning('error')
        setLoading(false)
        hide()
    }else{
            axios.post(`${config.apiUrl}/auth/ResetPassword`, { 
               PsrGuid:props.match.params.psrGuid,
               Password:values.password
             },
             {})
             .then(res => {
                if(res.status==200){
                    openNotificationWithIcon('success');
                    hide()
                    setTimeout(function () {
                      history.push('/home')
                     }.bind(this), 2000)
                     }
                 }).catch(
                  error => {
                    if(error.response.status === 406){
                      error.response.data.data.map((item)=>{
                        errorList.push(item);
                      })
                      errorListNotification('error')
                      setLoading(false)
                      hide()
                    }else{
                      openNotificationWithIconWarning('error')}
                      setLoading(false)
                      hide()
                     }
              );
    }
  };

  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'BAŞARILI',
      description:
        'Şifre güncelleme işlemi başarı ile gerçekleşti.',
        duration: 2,
    });
  };
  const NotSamePasswordWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Şifreler aynı değil.',
        duration: 2,
    });
  };
  const openNotificationWithIconWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Şifre güncelleme işlemi sırasında bir hata oluştu.',
        duration: 2,
    });
  };
  return (
    isHavePsrGuid === true ?
    <div className="gx-login-container"  style={{backgroundColor:'#f0f8ff'}}>
      <div className="gx-login-content">

        <div className="gx-login-header">
          {
            Screen.screen === 0 ? <img src={require("assets/images/logoAdosoft.png")} alt="paratic" title="paratic" style={{width:'40%'}}/>
            :
            (
              Screen.screen === 1 ? 
              <img src={require("assets/images/imzaPosLogo.png")} alt="paratic" title="paratic" style={{width:'40%'}}/>
              :
              <img src={require("assets/images/logoBakiyem.png")} alt="paratic" title="bakiyem" style={{width:'40%'}}/>
            )
          }
          
        </div>
        <div className="gx-mb-4">
          <h3>Şifreyi Yenile</h3>
          <p>Hesabınızın yeni şifresini giriniz.</p>
        </div>

        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">

          <FormItem rules={ [{required: true, message: 'Lütfen şifrenizi giriniz.'}]} name="password">
            <Input className='gx-input-lineheight' onKeyDown={ (evt) => evt.keyCode === 32 && evt.preventDefault() } type="password" placeholder="Yeni Şifrenizi Giriniz"/>
          </FormItem>

          <FormItem rules={ [{required: true, message: 'Lütfen şifrenizi giriniz.'}]} name="confirm">
            <Input className='gx-input-lineheight' onKeyDown={ (evt) => evt.keyCode === 32 && evt.preventDefault() } placeholder="Yeni Şifrenizi Tekrar Giriniz" type="password"/>
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit"  onClick={() => setLoading(true)} loading={loading}>
              Yenile
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
       :
       <div>
                   <Result
                       status="404"
                       title="404"
                       subTitle="Üzgünüm, böyle bir kullanıcı bulamadım."
                       extra={<Button type="primary" href='/signin'> Giriş Sayfasına Dön</Button>}
                   />
               </div>
  );
};

export default ResetPassword;
