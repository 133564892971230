import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function ParaticPhoneInput(props) {
  return (
    <PhoneInput
      countryCodeEditable={false}
      country={"tr"}
      onlyCountries={["tr"]}
      inputClass="ant-input"
      inputStyle={{ width: "100%", borderRadius: "2px" }}
      {...props}
    />
  );
}

export default ParaticPhoneInput;
