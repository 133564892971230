import React, { useState, useEffect } from "react";
import {
  Table,
  Empty,
  Popconfirm,
  Button,
  Col,
  Drawer,
  Row,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  BarsOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { odata } from "../Filters/Helper";

import { RowHelper } from "../../util/TableHelper";
import ManualAccountUpdate from "../Forms/ManualAccountUpdate";
import ManualAccountAdd from "../Forms/ManualAccountAdd";
import RequestHandler from "../../util/RequestHandler";
import ManualAccountPageTableFilter from "../Filters/ManualAccountPageTableFilter";

const ManualAccountPage = () => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });
  const [order, setOrder] = useState("");
  const [updateFormData, setUpdateFormData] = useState();
  const [visible, setVisible] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);

  useEffect(() => getTableData(), [order, tableDataQueryOptions]);

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    let query = '';
    const { skip, take, filter } = tableDataQueryOptions;

    const conditions = [];
    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length > 0) {
      query = `&$filter=${conditions.join(" and ")}`;
    }

    RequestHandler.get(
      `/ManualAccountTransaction?$count=true${query}${order}&$skip=${skip}&$top=${take}`
    ).then((response) => {
      if (Array.isArray(response.value)) {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      }
    });
  };

  const paging = (p, filters, sorter) => {
    let orderBy = "";
    if (sorter.columnKey && sorter.order) {
      orderBy =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }
    setOrder(orderBy);
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };

  const showDrawerUpdate = (id) => {
    RequestHandler.get(`/ManualAccountTransaction/GetById/${id}`).then((response) => {
      setUpdateFormData(response);
      setVisibleUpdate(true);
    });
  };

  const onAddSuccess = () => {
    getTableData();
    setVisible(false);
  };

  const onUpdateSuccess = () => {
    getTableData();
    setVisibleUpdate(false);
  };

  const handleOnClickDelete = (id) => {
    RequestHandler.delete(`/ManualAccountTransaction?id=${id}`).then((response) => {
      if (response.success) {
        getTableData();
      }
    });
  };

  const columns = [
    {
      title: "Cari Adı",
      dataIndex: "firmName",
      width: "10%",
      render: (text) => {
        if (!text) return;

        return (
          <Tooltip placement="topLeft" title={text}>
            {
              text.length < 15 ? text : text.substring(0, 15) + "..."
            }
          </Tooltip>
        )
      },
    },
    {
      title: "Cari Kodu",
      dataIndex: "firmCode",
      width: "10%",
    },
    {
      title: "Döviz",
      dataIndex: "currency",
      width: "10%",
    },
    {
      title: "Tutar",
      dataIndex: "amountString",
      align: "left",
      render: (text) => (<div style={{ width: "170%" }}>{text}</div>),
    },
    {
      title: "İşlem Türü",
      dataIndex: "transactionProcessType",
      width: "10%",
      render: (text) => (
        <div>
          {text === "Odeme" ? "Ödeme" : text}
        </div>
      ),
    },
    {
      title: "İşlem Tipi",
      dataIndex: "manualTransactionTypeName",
      width: "10%",
    },
    {
      title: "Tarih",
      dataIndex: "transactionDate",
      key: "transactionDateValue",
      sorter: true,
      render: (text) => (<div>{text.substring(0, 10)}</div>),
    },

    {
      title: "Açıklama",
      dataIndex: "description",
      align: "left",
      render: (text) => {
        if (!text) return;

        return (
          <Tooltip placement="topLeft" title={text}>
            {
              text.length < 50 ? text : text.substring(0, 50) + "..."
            }
          </Tooltip>
        )
      },
    },
    {
      title: "Güncelle",
      dataIndex: "id",
      width: "10%",
      align: "right",
      render: (data) => (
        <a
          onClick={() => showDrawerUpdate(data)}
          style={{ color: "orange", fontSize: "130%" }}
        >
          <EditOutlined />
        </a>
      ),
    },
    {
      title: "Sil",
      dataIndex: "id",
      width: "1%",
      render: (data) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleOnClickDelete(data)}
        >
          <a style={{ color: "red", fontSize: "130%" }}>
            <DeleteOutlined />
          </a>
        </Popconfirm>
      ),
    },
  ];

  const detailColumns = [
    {
      title: "Açıklama",
      dataIndex: "description",
    },
  ];
  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={14}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> KASA İŞLEMLERİ
          </p>
        </Col>
        <Col xs={24} md={12} lg={10} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={() => setVisible(true)}
          >
            <PlusOutlined /> Elden İşlem Ekle
          </Button>
        </Col>
      </Row>

      <ManualAccountPageTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            expandable={{
              expandedRowRender: (row) =>
                RowHelper.renderRowDetail({
                  data: row,
                  columns: detailColumns,
                }),
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%" }}
            rowKey="id"
            filterable={true}
            size="small"
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
          />
        </Col>
      </Row>

      <Drawer
        title="Elden İşlem Ekle"
        onClose={() => setVisible(false)}
        visible={visible}
        bodyStyle={{ padding: 24 }}
        width={500}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <ManualAccountAdd onSuccess={onAddSuccess} />
      </Drawer>

      <Drawer
        title="Elden İşlem Güncelle"
        onClose={() => setVisibleUpdate(false)}
        visible={visibleUpdate}
        bodyStyle={{ padding: 24 }}
        width={500}
        footer={<div style={{ textAlign: "right" }}></div>}
      >
        <ManualAccountUpdate
          data={updateFormData}
          onSuccess={onUpdateSuccess}
        />
      </Drawer>
    </>
  );
};

export default ManualAccountPage;
