import React, { useState, useEffect, useRef } from "react";

import {
  Col,
  Row,
  Divider,
  Input,
  Form,
  Button,
  Card,
  InputNumber,
  Checkbox,
  Modal,
  Select,
} from "antd";
import Cleave from "cleave.js/react";

import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "../routes/Forms/Helper";
import { odata } from "../routes/Filters/Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
const PaymentPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [creditCardType, setCreditCardType] = useState("");
  const [installmentCount, setInstallmentCount] = useState("");
  const [description, setDescription] = useState("");
  const [amountString, setAmountString] = useState("");
  const [bankId, setBankId] = useState(0);
  const [form] = Form.useForm();
  const [paymentModal, setPaymentModal] = useState({
    visible: false,
    source: null,
  });
  const [availableProviders, setAvailableProviders] = useState({
    loading: true,
    data: [],
  });
  const [availableInstallments, setAvailableInstallments] = useState({
    loading: false,
    data: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  });
  const [activeProviderId, setActiveProviderId] = useState();
  const [activeFirmId, setActiveFirmId] = useState();
  const [currencyCodes, setCurrencyCodes] = useState(["TRY", "USD", "EUR"]);
  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });
  const iframeRef = useRef();

  useEffect(() => {
    getAvailableProviders();
  }, []);

  useEffect(() => {
    let currencies = ["TRY", "USD", "EUR"];

    //Param Pos || NKolay Pos
    if (bankId === 889 || bankId === 890) {
      currencies = ["TRY"];
    }

    setCurrencyCodes(currencies);
    form.setFieldsValue({ ...form.getFieldsValue(), currencyCode: currencies[0] });

  }, [activeProviderId]);

  useEffect(() => {
    getPaymentRequestInfo();

    if (!activeFirmId) return;
    getDefaultInstallment(activeFirmId);
  }, [activeFirmId]);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const getPaymentRequestInfo = () => {
     RequestHandler.post(`/PaymentRequest/GetByPaymentRequestId`,
     {
      PaymentRequestString:props.match.params.paymentGuid
     },false
     ).then((response) => {
       setIsPaid(response.isPaid)
       setAmountString(response.amountString)
       setInstallmentCount(response.installmentCount)
       setDescription(response.description)
       setBankId(response.bankId)
     });
  }

  const getAvailableProviders = () => {
   
  }


  const getDefaultInstallment = (firmId) => {
    setAvailableInstallments({ ...availableInstallments, loading: true });

    RequestHandler.get(`/PosCollection/MaxAvailableInstallmentCount/${firmId}`).then((response) => {
      setAvailableInstallments({ ...availableInstallments, loading: false });

      //Bayiye tanımlı taksit sayısını seçili getiriyoruz.
      form.setFieldsValue({ ...form.getFieldsValue(), installmentCount: response.data });
    });
  }


  const onFinish = (values) => {
    let expireMonth, expireYear;
    if (values.expiration) {
      [expireMonth, expireYear] = values.expiration.split("/");
    }

    RequestHandler.post(
      `/PosCollection/SalesRequestWithCode`,
      {
        paymentCode:props.match.params.paymentGuid,
        holderName: values.holderName,
        cardNumber: values.cardNumber,
        cvv: values.cvv,
        expireMonth: expireMonth,
        expireYear: expireYear,
        customerInformation: values.customerInformation
      },
      false
    ).then((response) => {
      setLoading(false);
      if (response.success) {
        //fill html
        setPaymentModal({ visible: true, source: response.data });
        form.resetFields();
        form.setFieldsValue({
          bankId: values.bankId,
          firmId: values.firmId
        });

        if (props.onSuccess) props.onSuccess();
      }
    });
  
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const renderCardInfoInputs = () => {
    return bankId == 890 ? (
      <>
       <Col span={24}>
         <Form.Item
            label="Müşteri Bilgisi"
            name="customerInformation"
            rules={[{ required: true }]}
          >
            <Input placeholder="Müşteri Bilgisi" />
          </Form.Item>
       </Col>
      </>
    ) : (
      <>
        
        <Col span={24}>
          <Form.Item
              label="Müşteri Bilgisi"
              name="customerInformation"
              rules={[{ required: true }]}
            >
            <Input placeholder="Müşteri Bilgisi" />
          </Form.Item>
          <Form.Item
            label="Kart Üzerindeki İsim"
            name="holderName"
            rules={[{ required: true }]}
          >
            <Input placeholder="Ad Soyad" />
          </Form.Item>
          <Form.Item
            label="Kart Numarası"
            name="cardNumber"
            valuePropName="rawValue"
            rules={[{ required: true }]}
          >
            <Cleave
              className="ant-input"
              placeholder="0000 0000 0000 0000"
              options={{
                creditCard: true,
                onCreditCardTypeChanged: (type) =>
                  setCreditCardType(type),
              }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Son Kullanma Tarihi"
            name="expiration"
            rules={[{ required: true }]}
          >
            <Cleave
              className="ant-input"
              placeholder="AA/YY"
              options={{ date: true, datePattern: ["m", "d"] }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Güvenlik Kodu"
            name="cvv"
            rules={[{ required: true }]}
          >
            <Cleave
              className="ant-input"
              placeholder="CVV"
              options={{
                blocks: [4],
                numericOnly: true,
              }}
            />
          </Form.Item>
        </Col>
      </>
    )
  };

  return (
    <>
      <Row id="titleArea">
        <Col xs={24} md={12} lg={16}>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}></Col>
      </Row>

      <Divider style={{ margin: "12px 0" }} />

      <Row id="paymentArea" justify="center">
        <Col md={16} lg={16} xl={12}>
          <Card title="Ödeme Formu" >
            {
              isPaid ? <div style={{textAlign:'center'}}>
                <h2>Ödeme başarılı şekilde yapılmıştır.</h2>
              </div>
              :
              <Form
              {...formItemLayout}
              validateMessages={defaultValidateMessages}
              form={form}
              name="PaymentForm"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelAlign="left"
            >
              <Row>
                <Col span={24}>
                <div style={{textAlign:'center'}}>Tutar: <strong>{amountString}</strong></div>
                <div style={{textAlign:'center'}}>Taksit: <strong>{installmentCount} taksit</strong></div>
                <div style={{textAlign:'center'}}>Açıklama: <strong>{description}</strong></div>
                </Col>
               
                {renderCardInfoInputs()}

                <Col span={24}>
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        message: "Sözleşmeleri kabul etmeniz gerekmektedir.",
                      },
                    ]}
                  >
                    <Checkbox>
                      <a
                        href={"/gizlilik-ve-guvenlik-politikasi.pdf"}
                        target="_blank"
                      >
                        Gizlilik
                      </a>{" "}
                      ve{" "}
                      <a href={"/tuketici-haklari.pdf"} target="_blank">
                        Tüketici Hakları
                      </a>{" "}
                      sözleşmelerini okudum, onaylıyorum.
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => setLoading(true)}
                    loading={loading}
                  >
                    Öde
                  </Button>
                </Col>
              </Row>
            </Form>
            }
       
          </Card>
        </Col>
      </Row>

      <Modal
        id="paymentModal"
        centered
        visible={paymentModal.visible}
        onCancel={() => setPaymentModal({ visible: false, source: null })}
        destroyOnClose={true}
        width={600}
        footer={null}
      >
        <iframe
          id="paymentFrame"
          ref={iframeRef}
          style={{ width: "100%", border: 0 }}
          srcDoc={paymentModal.source}
          sandbox="allow-same-origin allow-scripts allow-forms"
          scrolling="auto"
          onLoad={() => {
            const iframe = iframeRef.current;
            iframe.style.height = iframe.scrollWidth + 150 + "px";
          }}
        />
      </Modal>
    </>
  );
};

export default PaymentPage;
