import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Menu, Switch, Spin, Col, Row, Modal } from "antd";
import { Link, useHistory } from "react-router-dom";
import Replace2x from "../../util/Extensions/ReplaceExtension";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";

import SubMenu from "antd/lib/menu/SubMenu";
import getMenuItems from "util/MenuItems";
import RequestHandler from "util/RequestHandler";

const SidebarContent = () => {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleSms, setIsModalVisibleSms] = useState(false);
  const [isLiveValue, setIsLiveValue] = useState(false);
  const [isSmsAccess, setIsSmsAccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    loading: true,
    modules: [],
    isAdmin: false,
    isGroupAdmin: false,
    isGroupUser: false,
  });

  useEffect(() => {
    RequestHandler.get("/User/MenuPermissions").then((response) => {
      setPermissions({
        ...response,
        loading: false,
      });
      localStorage.setItem("defaultModuleId", response.defaultModuleId);
    });
  }, []);

  let { navStyle, themeType, pathname } = useSelector(
    ({ settings }) => settings
  );

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const showModal = () => {
    setLoading(true);
    RequestHandler.get("/TenantCurrency/GetIsDailyValue").then((response) => {
      setIsLiveValue(response.isLiveValue);
      setIsModalVisible(true);
    });
    setLoading(false);
  };
  const showModalSms = () => {
    setLoading(true);
    RequestHandler.get(
      "/Tenant/GetById/" + Replace2x(localStorage.getItem("tenantId"))
    ).then((response) => {
      setIsSmsAccess(response.isSmsAccess);
      setIsModalVisibleSms(true);
    });
    setLoading(false);
  };
  function onChangeSms(checked) {
    RequestHandler.post(
      "/Tenant/AllTenantUpdateForSms",
      {
        UserId: Replace2x(localStorage.getItem("userId")),
        IsSmsAccess: checked,
      },
      true
    ).then((res) => {
      setIsSmsAccess(!isSmsAccess);
      window.location.reload();
    });
    setTimeout(() => {
      setIsModalVisible(false);
    }, 500);
  }

  function onChange(checked) {
    RequestHandler.put(
      "/TenantCurrency/PutIsLiveValue",
      {
        isLiveValue: checked,
      },
      true
    ).then((res) => {
      setIsLiveValue(res.data.isLiveValue);
    });
    setTimeout(() => {
      setIsModalVisible(false);
      window.location.reload();
    }, 500);
  }
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleOkSms = () => {
    setIsModalVisibleSms(false);
  };

  const handleCancelSms = () => {
    setIsModalVisibleSms(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const renderMenuItem = (menuItem) => {
    if (
      menuItem.isRender === false ||
      (menuItem.module && !permissions.modules.includes(menuItem.module))
    )
      return null;

    if (menuItem.children) {
      return (
        <SubMenu
          key={menuItem.key}
          title={
            <span>
              <i className={menuItem.icon} />
              <span>{menuItem.title}</span>
            </span>
          }
        >
          {menuItem.children.map((childItem) => renderMenuItem(childItem))}
        </SubMenu>
      );
    } else {
      switch (menuItem.key) {
        case "currency-live":
          return (
            <Menu.Item
              key={menuItem.key}
              style={{
                marginLeft: -39,
                width: 297,
              }}
              onClick={() => showModal()}
            >
              <Link>
                <i className={menuItem.icon} />
                <span>{menuItem.title} </span>
              </Link>
            </Menu.Item>
          );
        case "password-access":
          return (
            <Menu.Item
              key={menuItem.key}
              style={{
                marginLeft: -39,
                width: 297,
              }}
              onClick={() => showModalSms()}
            >
              <Link>
                <i className={menuItem.icon} />
                <span>{menuItem.title} </span>
              </Link>
            </Menu.Item>
          );
        default:
          return (
            <Menu.Item
              key={menuItem.key}
              style={{
                marginLeft: menuItem.key !== "home" ? -39 : null,
                width: menuItem.key !== "home" ? 297 : null,
              }}
              onClick={() => history.push(menuItem.url)}
            >
              <Link to={menuItem.url}>
                <i className={menuItem.icon} />
                <span>{menuItem.title}</span>
              </Link>
            </Menu.Item>
          );
      }
    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  const menuItems = getMenuItems(permissions);

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            style={{ backgroundColor: "transparent" }}
            className="gx-layout-sider-scrollbar"
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            {permissions.loading ? (
              <Row justify="center">
                <Spin style={{ margin: 5 }} />
              </Row>
            ) : (
              menuItems.map((menuItem) => {
                return renderMenuItem(menuItem);
              })
            )}
          </Menu>
        </CustomScrollbars>
        <Modal
          title="Döviz Kurları"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
        >
          <Spin spinning={loading}>
            <Row>
              <Col span={8}>Günlük Veri</Col>
              <Col span={6}>
                <Switch defaultChecked={isLiveValue} onChange={onChange} />
              </Col>
              <Col span={10}>Anlık Veri </Col>
            </Row>
          </Spin>
        </Modal>
        <Modal
          title="Sms Onayı"
          visible={isModalVisibleSms}
          onOk={handleOkSms}
          onCancel={handleCancelSms}
          footer={false}
        >
          <Spin spinning={loading}>
            <Row>
              <Col span={2}></Col>
              <Col span={6}>Şifresiz Giriş</Col>
              <Col span={2}>
                <Switch defaultChecked={isSmsAccess} onChange={onChangeSms} />
              </Col>
              <Col span={2}></Col>
              <Col span={6}>Şifreli Giriş </Col>
              <Col span={5}> </Col>
            </Row>
          </Spin>
        </Modal>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
