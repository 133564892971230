import React, { useState, useEffect } from "react";
import {
  Form,
  Col,
  Row,
  Button,
  Select,
  Collapse,
  DatePicker,
  Input,
} from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { odata } from "./Helper";

const DbsSentInvoiceTableFilter = (props) => {
  const [form] = Form.useForm();

  const [bankSelect, setBankSelect] = useState({
    data: [],
    loading: false,
  });
  const [dealerSelect, setDealerSelect] = useState({
    data: [],
    loading: false,
  });
  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });
  const [activeBankId, setActiveBankId] = useState();

  useEffect(() => {
    getBanks();
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const getBanks = () => {
    setBankSelect({
      ...bankSelect,
      loading: true,
    });
    RequestHandler.get(`/DbsBankInfo/AddedBanks?$select=id,name`).then(
      (response) => {
        setBankSelect({
          data: response,
          loading: false,
        });
      }
    );
  };

  const onDealerSearch = (value) => {
    if (value.length >= 2) {
      setDealerSelect({ data: [], loading: true });

      let url = `/DbsDealer?$select=id,code,name&$filter=(${odata.contains(
        "name",
        value
      )} or ${odata.contains("code", value)})`;
      if (activeBankId) {
        url += ` and bankId eq ${activeBankId}`;
      }
      RequestHandler.get(url).then((res) => {
        setDealerSelect({ data: [{ id: `_${value}`, code: "", name: `'${value}' içerenleri ara` }].concat(res.value), loading: false });
      });
    } else {
      setDealerSelect({ data: [], loading: false });
    }
  };

  const onFirmSeach = (value) => {
    if (value.length >= 2) {
      setFirmSelect({ data: [], loading: true });

      RequestHandler.get(
        `/Firm?$select=id,firmBranchNumber,name&$filter=${odata.contains(
          "name",
          value
        )} or ${odata.contains("firmBranchNumber", value)}`
      ).then((res) => {
        setFirmSelect({ data: res.value, loading: false });
      });
    } else {
      setFirmSelect({ data: [], loading: false });
    }
  };

  const onFinish = (values) => {
    {
      let query = "";
      const conditions = [];

      if (values.bankId) {
        conditions.push(`bankId eq ${values.bankId}`);
      }

      if (values.dbsDealerId) {
        if (values.dbsDealerId.startsWith("_")) {
          const searchValue = values.dbsDealerId.slice(1);
          conditions.push(`(${odata.contains("dbsDealerName", searchValue)} or ${odata.contains("dbsDealerCode", searchValue)})`);
        } else {
          conditions.push(`dbsDealerId eq ${values.dbsDealerId}`);
        }
      }

      if (values.dbsDealerFirmId) {
        conditions.push(`dbsDealerFirmId eq ${values.dbsDealerFirmId}`);
      }

      if (values.invoiceNumber) {
        conditions.push(odata.contains("invoiceNumber", values.invoiceNumber));
      }

      if (values.invoiceDateRange) {
        const [startDate, endDate] = values.invoiceDateRange;

        conditions.push(odata.dateRange("invoiceDate", startDate, endDate));
      }

      if (values.dueDateRange) {
        const [startDate, endDate] = values.dueDateRange;

        conditions.push(odata.dateRange("dueDate", startDate, endDate));
      }

      if (values.statusId && values.statusId.length > 0) {
        conditions.push(`statusId in (${values.statusId})`);
      }

      if (conditions.length !== 0) {
        query = conditions.join(" and ");
      }

      if (props.onFinishFilter) props.onFinishFilter(query);
    }
  };

  const clearFilter = () => {
    form.resetFields();
    setActiveBankId();

    if (props.onClearFilter) {
      props.onClearFilter();
    }
  };

  return (
    <Row id="filterArea" style={{ ...props.style }}>
      <Col span={24}>
        <Collapse>
          <Collapse.Panel header="Filtreleme için tıklayınız.">
            <Form
              name="sentInvoiceFilter"
              form={form}
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row style={{ flexDirection: "row" }}>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="bankId" label="Banka :">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Tümü"
                      loading={bankSelect.loading}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      onChange={(value) => setActiveBankId(value)}
                    >
                      {bankSelect.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="dbsDealerId" label="Bayi :">
                    <Select
                      allowClear
                      showSearch
                      placeholder="En az 2 harf giriniz.."
                      onSearch={onDealerSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                        option.code
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      loading={dealerSelect.loading}
                    >
                      {dealerSelect.data.map((item) => (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          code={item.code}
                          style={item.id.startsWith('_') ? { color: 'gray' } : {}}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="dbsDealerFirmId" label="Cari :" >
                    <Select
                      allowClear
                      showSearch
                      placeholder="En az 2 harf giriniz.."
                      onSearch={onFirmSeach}
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                        option.code
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                      loading={firmSelect.loading}
                    >
                      {firmSelect.data.map((item) => (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          code={item.firmBranchNumber}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="invoiceNumber" label="Fatura Numarası :">
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="invoiceDateRange" label="Fatura Tarihi :">
                    <DatePicker.RangePicker
                      format="DD.MM.YYYY"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="dueDateRange" label="Vade Tarihi :">
                    <DatePicker.RangePicker
                      format="DD.MM.YYYY"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="statusId" label="Fatura Durumu :">
                    <Select
                      mode="multiple"
                      allowClear
                      showSearch
                      placeholder="Tümü"
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase("tr-TR")
                          .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                      }
                    >
                      {[
                        { id: 1, name: "Tahsil Edilecek" },
                        { id: 2, name: "Tahsil Edilemedi" },
                        { id: 3, name: "Kısmi Tahsil Edildi" },
                        { id: 4, name: "Tahsil Edildi" },
                        { id: 5, name: "İptal" },
                        { id: 6, name: "Başarısız" },
                        { id: 7, name: "Teyit Bekliyor" },
                      ].map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ flexDirection: "row" }}>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    danger
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={() => clearFilter()}
                  >
                    Sıfırla
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    icon={<SearchOutlined />}
                    style={{ color: "#40a9ff", borderColor: "#40a9ff" }}
                    htmlType="submit"
                  >
                    Filtrele
                  </Button>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};
export default DbsSentInvoiceTableFilter;
