import React,{useState,useEffect} from "react";
import Widget from "components/Widget";
import {Row,Col,notification,Empty } from 'antd';
import HomePageBankList from '../../../routes/BankPage/HomePageBankList'
import { useMediaQuery } from 'react-responsive'
import config from "../../../util/ApiforContext"
import axios from 'axios';
const expiration = localStorage.getItem("expiration");

const TicketList =()=> {
  const [tenantAccounts, setTenantAccounts] = useState([{ data: [] , total: 0 }]);
  const [dataState, setDataState] = useState([{ skip: 0 , take: 10 }]);
  const [tenantList, setTenantList] = useState([]);
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(0);
  const [query, setQuery] = useState('');
 
  const errorList = [];
    const errorListNotification = type => {
      errorList.map((item)=> {
        notification[type]({
          message: 'Hata',
          description:
          item
           ,
            duration: 2,
        });
      })
    };
  useEffect(() => {
    if(expiration<Date.now()){
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if(localStorage.getItem("token")==null){
      window.location.reload()
    }
     axios.get(`${config.apiUrl}/TenantAccount? ${query} &$count=true&$skip=${skip}&$top=${take}`,config.headers)
     .then(res => {
       setTenantAccounts({
         data:res.data.value,
         total:res.data['@odata.count']
       })
       setTenantList(res.data.value)
       setCount(res.data['@odata.count'])
     }).catch(
       error =>  {
         if(error.response.status===403 || error.response.status ===401){
          setTenantAccounts({
            data:[],
            total:0
          })
          setTenantList([])
          setCount(0)
         }else if(error.response.status === 406){
           error.response.data.data.map((item)=>{
             errorList.push(item);
           })
           errorListNotification('error')
         }
       }
   );
   
  },[query,setDataState,skip,setSkip]);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isLittleWith = useMediaQuery({
    query: '(max-width: 1336px)'
  })
    return (
        isTabletOrMobile ? 
        <Row>
        <Col style={{marginLeft:17,width:'91%',marginBottom:20}}>
          <Widget title={
            <h2 className="h4 gx-text-capitalize gx-mb-0">
                Banka Hesapları</h2>
                } styleName="gx-card-ticketlist"
                  extra={<h5 className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
                
                  </h5>}>
                  <div key={"TicketItem"} className="gx-media gx-task-list-item gx-flex-nowrap">
                    <Col span={24} style={{overflowY:'auto'}} >
                    <HomePageBankList /> 
                    </Col>
                  </div>
                  <a href="bank-accounts">Tüm Hesaplar</a>  <i
                    className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle"/>
          </Widget>
      </Col>
      </Row>
      :
        <Widget  title={
          <h2 className="h4 gx-text-capitalize gx-mb-0">
              Banka Hesapları</h2>
              } styleName="gx-card-ticketlist"
                extra={<h5 className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
                  <a href="bank-accounts">Tüm Hesaplar</a>  <i
                  className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle"/>
                </h5>}>
                <div key={"TicketItem"} className="gx-media gx-task-list-item gx-flex-nowrap">
                  {
                    count === 0 ?
                    <Col span={24} style={{height:490}}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" />
                    </Col> 
                    :
                    (
                    count < 5 ?   <Col span={24} style={{height:490}}>
                    <HomePageBankList /> 
                    </Col>
                    :
                    (
                      isLittleWith ?
                      <Col span={24} >
                    <HomePageBankList /> 
                    </Col>
                    :
                    <Col span={24} style={{height:800}}>
                    <HomePageBankList /> 
                    </Col>
                    )
                    )
                  }
                 
                </div>
        </Widget>

      
    );
}


export default TicketList;
