import React, { useState, useEffect } from "react";
import RequestHandler from "util/RequestHandler";
import ErpStockReportTableFilter from "../Filters/ErpStockReportTableFilter";

import { Table, Empty, Col, Row } from "antd";
import { BarsOutlined } from "@ant-design/icons";
import { odata } from "../Filters/Helper";

const ErpStockReport = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
    order: "",
  });

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    const { skip, take, filter, order } = tableDataQueryOptions;
    RequestHandler.get(
      `/ErpReport/GetStockReport?$count=true${filter}&$skip=${skip}&$top=${take}${order}`
    ).then((response) => {
      setTableData({
        data: response[0].value,
        total: response[0]["@odata.count"],
        loading: false,
      });
    });
  };

  const onTableChange = (pagination, filters, sorter) => {
    let order = "";

    if (sorter.columnKey && sorter.order) {
      order =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (pagination.current - 1) * prevState.take,
        take: pagination.pageSize,
        current: pagination.current,
        order: order,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      ...tableDataQueryOptions,
      skip: 0,
      current: 1,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { ...prevState, skip: 0, current: 1, filter: query };
    });
  };

  const columns = [
    {
      title: "Ürün Kodu",
      dataIndex: "productCode",
    },
    {
      title: "Ürün Adı",
      dataIndex: "productName",
    },
    {
      title: "Stok Türü",
      dataIndex: "stockType",
    },
    {
      title: "Ana Grup",
      dataIndex: "mainGroupCode",
    },
    {
      title: "Alt Grup",
      dataIndex: "subGroupCode",
    },
    {
      title: "Fabrika Depo",
      dataIndex: "stockCountForStore1",
      key: "stockCountForStore1",
      sorter: true,
    },
    {
      title: "Gıda Çarşısı Depo",
      dataIndex: "stockCountForStore2",
      key: "stockCountForStore2",
      sorter: true,
    },
    {
      title: "Birim",
      dataIndex: "unitType",
    },
  ];

  return (
    <>
      <Row>
        <Col xs={15} lg={20}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> STOK DURUMU
          </p>
        </Col>
      </Row>

      <ErpStockReportTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{
              overflowX: "auto",
              maxHeight: "100%",
              width: "100%",
            }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={onTableChange}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};

export default ErpStockReport;
