import React, { useState, useEffect } from "react";
import { Form, Col, Row, Button, Select } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const DefaultModuleUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [moduleSelect, setModuleSelect] = useState({
    data: [],
    loading: true,
  });
  const [form] = Form.useForm();

  useEffect(() => {
    getModules();
  }, []);

  const getModules = () => {
    setModuleSelect({
      ...moduleSelect,
      loading: true,
    });

    RequestHandler.get("/Module/GetDefaultSelectableModules").then(
      (response) => {
        setModuleSelect({
          data: response,
          loading: false,
        });

        const defaultModuleId = localStorage.getItem("defaultModuleId");
        if (response.some((x) => x.id == defaultModuleId)) {
          form.setFieldsValue({ moduleId: parseInt(defaultModuleId) });
        }
      }
    );
  };

  const onFinish = (values) => {
    RequestHandler.put(`/User/UpdateDefaultModule/${values.moduleId}`).then(
      (response) => {
        setLoading(false);
        if (response.success) {
          localStorage.setItem("defaultModuleId", values.moduleId);
          if (props.onSuccess) props.onSuccess();
        }
      }
    );
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="defaultModuleUpdate"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="moduleId"
            label="Varsayılan Modül"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder="Seçiniz"
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              loading={moduleSelect.loading}
            >
              {moduleSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Kaydet
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default DefaultModuleUpdateForm;
