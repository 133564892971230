import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const DbsDealerAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [activePropertyName, setActivePropertyName] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);

      setActivePropertyName(props.data.propertyName);
    }
  }, [props.data]);

  const onFinish = (values) => {
    RequestHandler.put("/EntityPermission", values).then((response) => {
      setLoading(false);
      if (response.success) {
        form.resetFields();
        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  const onPropertyNameChange = (value) => {
    setActivePropertyName(value);
    form.setFieldsValue({ value: null });
  };

  const renderValueInput = (propertyName) => {
    var propertyInfo = props.options.permissibleProperties.filter(
      (x) => x.name == propertyName
    )[0];

    switch (propertyInfo.inputType) {
      //Number
      case 1:
        return "Number";
      //SelectBox
      case 2:
        return (
          <Select
            showSearch
            placeholder="Seçiniz"
            filterOption={(input, option) =>
              option.children
                .toLocaleLowerCase("tr-TR")
                .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
            }
          >
            {propertyInfo.selectableValues?.map((item) => (
              <Select.Option key={item.key} value={item.key.toString()}>
                {item.value}
              </Select.Option>
            ))}
          </Select>
        );
      //Checkbox
      case 3:
        return "Checkbox";
      //Radio
      case 4:
        return "Radio";

      //0
      default:
        return <Input />;
    }
  };

  const comparisonTypes = {
    equal: 0,
    notEqual: 1,
    greaterThan: 2,
    greaterThanOrEqual: 3,
    lessThan: 4,
    lessThanOrEqual: 5,
    contains: 6,
    notContains: 7,
  };

  const renderComparisonTypes = (propertyName) => {
    var propertyInfo = props.options.permissibleProperties.filter(
      (x) => x.name == propertyName
    )[0];

    let availableCorparisonTypes = [];
    if (propertyInfo && props.options?.corparisonTypes) {
      switch (propertyInfo.inputType) {
        //Number
        case 1:
          availableCorparisonTypes = props.options.corparisonTypes.filter(
            (item) =>
              ![comparisonTypes.contains, comparisonTypes.notContains].includes(
                item.key
              )
          );
          break;
        //SelectBox
        case 2:
        //Checkbox
        case 3:
          availableCorparisonTypes = props.options.corparisonTypes.filter(
            (item) =>
              [comparisonTypes.equal, comparisonTypes.notEqual].includes(
                item.key
              )
          );
          break;
        //Radio
        case 4:
          availableCorparisonTypes = props.options.corparisonTypes;
          break;
        //Text
        default:
          availableCorparisonTypes = props.options.corparisonTypes.filter(
            (item) =>
              [
                comparisonTypes.equal,
                comparisonTypes.notEqual,
                comparisonTypes.contains,
                comparisonTypes.notContains,
              ].includes(item.key)
          );
          break;
      }
    }

    return availableCorparisonTypes.map((item) => (
      <Select.Option key={item.key} value={item.key}>
        {item.value}
      </Select.Option>
    ));
  };

  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="entityPermissionUpdate"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="id" hidden rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="entityType" hidden rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="tenantUserId" hidden rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            name="propertyName"
            label="Filtrelenecek Alan"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              onChange={onPropertyNameChange}
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
            >
              {props.options?.permissibleProperties.map((item) => (
                <Select.Option key={item.name} value={item.name}>
                  {item.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="corparisonType"
            label="Karşılaştırma Türü"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
            >
              {renderComparisonTypes(activePropertyName)}
            </Select>
          </Form.Item>

          {activePropertyName && (
            <Form.Item
              name="value"
              label="Karşılaştırılacak Değer"
              rules={[{ required: true }]}
            >
              {renderValueInput(activePropertyName)}
            </Form.Item>
          )}

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Güncelle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default DbsDealerAddForm;
