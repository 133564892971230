import React, { useState, useEffect } from "react";
import {
  Form,
  Table,
  Empty,
  Popconfirm,
  Button,
  notification,
  Col,
  Upload,
  Row,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  BarsOutlined,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import config from "../../util/ApiforContext";
import RequestHandler from "util/RequestHandler";
import { odata } from "../Filters/Helper";
import { RowHelper } from "../../util/TableHelper";
import ErpActivityListTableFilter from "../Filters/ErpActivityListTableFilter";

const ErpActivityList = () => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });
  const [order, setOrder] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();


  useEffect(() => getTableData(), [order, tableDataQueryOptions]);

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    let query = '';
    const { skip, take, filter } = tableDataQueryOptions;

    const conditions = ["isErpActivity eq true"];
    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length > 0) {
      query = `&$filter=${conditions.join(" and ")}`;
    }

    RequestHandler.get(
      `/ErpActivity?$count=true${query}${order}&$skip=${skip}&$top=${take}`
    ).then((response) => {
      if (Array.isArray(response.value)) {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      }
    });
  };

  const paging = (p, filters, sorter) => {
    let orderBy = "";
    if (sorter.columnKey && sorter.order) {
      orderBy =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }
    setOrder(orderBy);
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };

  const downloadExcelTemplate = () => {
    window.open(`${config.apiUrl}/ErpActivity/DownloadExcelTemplate?t=${RequestHandler.getToken()}`);
  };

  const downloadImportResult = (fileName) => {
    window.open(`${config.apiUrl}/ErpActivity/DownloadImportResult?t=${RequestHandler.getToken()}&fileName=${fileName}`);
  };

  const onExcelUploadSuccess = () => {
    form.resetFields();
    setFileList([]);
    getTableData();
  };

  const handleOnClickDelete = (id) => {
    RequestHandler.delete(`/ErpActivity?id=${id}`).then((response) => {
      if (response.success) {
        getTableData();
      }
    });
  };

  const uploadExcelProps = {
    name: "excel",
    action: `${config.apiUrl}/ErpActivity/ImportManualTransactionExcel`,
    headers: {
      Authorization: "bearer " + RequestHandler.getToken(),
    },
    accept: ".xlsx",
    maxCount: 1,
    onChange(info) {
      setFileList(info.fileList);
      const { response, status } = info.file;

      if (status !== "error" && status !== "done") return;
      if (!response) return;

      const notificationType = response.success ? "success" : "error";

      notification[notificationType]({
        message: response.message,
        duration: 3,
      });
      if (!response.success) {
        downloadImportResult(info.file.response.data);
      }
      onExcelUploadSuccess();
    },
  };

  const columns = [
    {
      title: "Hesap Kodu",
      dataIndex: "erpCode",
      width: "10%"
    },
    {
      title: "Cari Kodu",
      dataIndex: "firmCode",
      width: "10%",
    },
    {
      title: "Döviz",
      dataIndex: "currency",
      width: "10%",
    },
    {
      title: "Tutar",
      dataIndex: "amountString",
      align: "left",
      render: (text) => (<div style={{ width: "170%" }}>{text}</div>),
    },
    {
      title: "İşlem Türü",
      dataIndex: "transactionProcessType",
      width: "10%",
      align: 'left',
      render: (text) => (
        <div>
          {text === "Odeme" ? "Ödeme" : text}
        </div>
      ),
    },
    {
      title: "Tarih",
      dataIndex: "transactionDate",
      key: "transactionDateValue",
      sorter: true,
      render: (text) => (<div>{text.substring(0, 10)}</div>),
    },

    {
      title: "Açıklama",
      dataIndex: "description",
      align: "left",
      render: (text) => {
        if (!text) return;

        return (
          <Tooltip placement="topLeft" title={text}>
            {
              text.length < 50 ? text : text.substring(0, 50) + "..."
            }
          </Tooltip>
        )
      },
    },
    {
      title: "Sil",
      dataIndex: "id",
      width: "1%",
      render: (data) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleOnClickDelete(data)}
        >
          <a style={{ color: "red", fontSize: "130%" }}>
            <DeleteOutlined />
          </a>
        </Popconfirm>
      ),
    },
  ];

  const detailColumns = [
    {
      title: "Açıklama",
      dataIndex: "description",
    },
  ];

  return (
    <>

      <Row>
        <Col xs={24} md={12} lg={14}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> VERİ YÜKLEME
          </p>
        </Col>
        <Col xs={24} md={12} lg={10} style={{ textAlign: "right" }}>
          <Button
            icon={<DownloadOutlined />}
            onClick={(() => setLoading(true), downloadExcelTemplate)}
            loading={loading}
          >
            Excel Şablonu İndir
          </Button>
          <Upload {...uploadExcelProps} fileList={fileList}>
            <Button icon={<UploadOutlined />}>Excel ile Ekle</Button>
          </Upload>
        </Col>
      </Row>

      <ErpActivityListTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            expandable={{
              expandedRowRender: (row) =>
                RowHelper.renderRowDetail({
                  data: row,
                  columns: detailColumns,
                }),
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{ overflowX: "auto", maxHeight: "100%" }}
            rowKey="id"
            filterable={true}
            size="small"
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default ErpActivityList;
