import React, { useState, useEffect } from "react";
import {
  Form,
  Col,
  Row,
  Button,
  Select,
  Input,
  notification,
  Modal
} from "antd";
import {
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";
import { odata } from "../Filters/Helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const FirmTransactionMatchForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });
  const [vknTcknModal, setVknTcknModal] = useState({
    data: {},
    visible: false,
    request: {}
  });
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onFirmSearch = (value) => {
    if (value.length >= 2) {
      setFirmSelect({ ...firmSelect, loading: true });

      RequestHandler.get(
        `/Firm?$select=id,firmBranchNumber,name&$filter=${odata.contains(
          "name",
          value
        )} or ${odata.contains("firmBranchNumber", value)}`
      ).then((res) => {
        setFirmSelect({ data: res.value, loading: false });
      });
    } else {
      setFirmSelect({ data: [], loading: false });
    }
  };

  const onSuccess = () => {
    form.resetFields();

    notification.success({
      message: "BAŞARILI",
      description: "Eşleştirme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });

    if (props.onSuccess) props.onSuccess();
  };

  const onFinish = (values) => {
    const request = {
      id: values.firmId,
      accountTransactionId: values.accountTransactionId
    };
    RequestHandler.put("/Firm", request, false).then((response) => {
      setLoading(false);
      if (response.success) {

        RequestHandler.post("/Firm/GetDiffrentVknTckn", request, false).then((res) => {
          if (res.data.oldVkn !== null || res.data.oldTckn !== null) {
            setVknTcknModal({ data: res.data, visible: true, request: request });
          } else {
            onSuccess();
          }
        });
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  const onOkVknTckn = () => {
    RequestHandler.post("/Firm/GetDiffrentVknTckn",
      vknTcknModal.request,
      false
    ).then((res) => {
      setVknTcknModal({ ...vknTcknModal, visible: false });
      if (res.success) {
        onSuccess();
      }
    });

  }
  const onCancelVknTckn = () => {
    setVknTcknModal({ ...vknTcknModal, visible: false });
    onSuccess();
  }

  const { oldVkn, oldTckn, newVkn, newTckn } = vknTcknModal.data;
  return (
    <>
      <Form
        {...formItemLayout}
        validateMessages={defaultValidateMessages}
        form={form}
        name="dbsDealerAdd"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col span={24}>

            <Form.Item name="accountTransactionId" hidden >
              <Input />
            </Form.Item>

            <Form.Item
              label="Cari Adı/Ünvanı"
              name="firmId"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Seçiniz"
                onSearch={onFirmSearch}
                filterOption={(input, option) =>
                  option.children
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                  option.code
                    .toLocaleLowerCase("tr-TR")
                    .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                }
                loading={firmSelect.loading}
              >
                {firmSelect.data.map((item) => (
                  <Select.Option
                    key={item.id}
                    value={item.id}
                    code={item.firmBranchNumber}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Row justify="space-between">
              <Col>
                <Button
                  type="primary"
                  icon={props.isUpdate ? <EditOutlined /> : <PlusOutlined />}
                  htmlType="submit"
                  onClick={() => setLoading(true)}
                  loading={loading}
                >
                  {props.isUpdate ? "Güncelle" : "Eşleştir"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Modal
        title=""
        centered
        visible={vknTcknModal.visible}
        onOk={onOkVknTckn}
        onCancel={onCancelVknTckn}
        okText="Güncelle"
        cancelText="Vazgeç"
      >
        <p>
          Hesap Hareketindeki {oldTckn !== null && "Tckn"}{oldVkn !== null && " Vkn "} alanı ile firmada bulunan
          {newTckn !== null && "Tckn"}{newVkn !== null && " Vkn "} alanı farklı.
        </p>

        <p> </p>
        <p> {oldTckn !== null && "Hesap Hareketindeki Tckn : " + oldTckn}  </p>
        <p> {oldTckn !== null && "Firmadan Gelen Tckn : " + (newTckn === null ? "BOŞ" : newTckn)} </p>
        <p> {oldVkn !== null && "Hesap Hareketindeki Vkn : " + oldVkn}  </p>
        <p> {oldVkn !== null && "Firmadan Gelen Vkn : " + (newVkn === null ? "BOŞ" : newVkn)} </p>
        <p></p>
        <p>Alanları güncellemek ister misiniz?</p>
      </Modal>
    </>
  );
};
export default FirmTransactionMatchForm;
