import React from "react";
import Widget from "components/Widget";
import { Col } from 'antd';
import AccountTransactionGoingList from './AccountTransactionGoingList'
import { useMediaQuery } from 'react-responsive'


const TicketList = () => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });
  return (
    isDesktopOrLaptop ?
      <Widget title={<h2 className="h4 gx-text-capitalize gx-mb-0">Giden Hesap Hareketleri</h2>} styleName="gx-card-ticketlist"
        extra={<h5 className="gx-text-primary gx-mb-0 gx-pointer gx-d-no ne gx-d-sm-block">
          <a href="/account-transaction">Tümü</a>  <i
            className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle" />
        </h5>}>
        <div key={"TicketItem"} className="gx-media gx-task-list-item gx-flex-nowrap">
          <Col span={24} style={{ width: 700, overflowY: 'auto' }}>
            <AccountTransactionGoingList />
          </Col>
        </div>
      </Widget>
      :
      <Widget title={<h2 className="h4 gx-text-capitalize gx-mb-0">Giden Hesap Hareketleri</h2>} styleName="gx-card-ticketlist"
        extra={<h5 className="gx-text-primary gx-mb-0 gx-pointer gx-d-no ne gx-d-sm-block">

        </h5>}>
        <div key={"TicketItem"} className="gx-media gx-task-list-item gx-flex-nowrap">
          <Col span={24} style={{ width: 700, overflowY: 'auto' }}>
            <AccountTransactionGoingList />
            <a href="/account-transaction">Tümü</a>  <i
              className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle" />
          </Col>
        </div>
      </Widget>
  );
}


export default TicketList;
