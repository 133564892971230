import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  Empty,
  message,
  Input,
  Tooltip,
  Modal,
  notification,
  Button,
  Form,
  Row,
  Col,
  Spin
} from "antd";
import {
  RollbackOutlined,
  BarsOutlined,
  MailOutlined,
  MailFilled,
  TagsOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import config from "../../util/ApiforContext";
import ReactTags from 'react-autocomplete-tag';
import { useMediaQuery } from "react-responsive";
import Screen from "../../util/Extensions/Screens";
import Replace2x from "../../util/Extensions/ReplaceExtension";
import Iframe from "react-iframe";
import { ColumnHelper, RowHelper } from "util/TableHelper";
import RequestHandler from "util/RequestHandler";
import { odata } from "../Filters/Helper";
import AccountBankTableFilter from "../Filters/AccountBankTableFilter";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 13,
  },
};

const AccountBank = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });
  const [order, setOrder] = useState("");
  const [tableRole, setTableRole] = useState({});

  const [loading, setLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [EmailVisible, setEmailVisible] = useState(true);
  const [Email, setEmail] = useState(Replace2x(localStorage.getItem("email")));
  const [isModalVisibleReceipt, setIsModalVisible] = useState(false);
  const [accountIdForModalReceipt, setAccountIdForModalReceipt] = useState("");
  const [spinLoading, setSpinLoading] = useState(false);
  const [accountTransactionId, setAccountTransactionId] = useState();

  const [tags, setTags] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [prevTagList, setprevTagList] = useState([])
  const [isModalTagVisible, setIsModalTagVisible] = useState(false);

  const history = useHistory();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });

  useEffect(() => {
    RequestHandler.get(`/AccountTransaction/GetTransactionTableRole`).then((response) => {
      if (response.success) {
        setTableRole(response.data);
      }
    });

    RequestHandler.get(`/TenantTransactionTag/GetTransactionTagList`).then((response) => {
      let tagList = [];
      response.map((item) => {
        tagList.push(item.tag)
      })
      setprevTagList(tagList)
    });
  }, []);

  useEffect(() => getTableData(), [order, tableDataQueryOptions]);

  const getFilterQuery = () => {
    const { filter } = tableDataQueryOptions;

    const conditions = ["isErpActivity eq false"];

    if (props.match.params.tenantAccountId) {
      conditions.push(`tenantAccountId eq ${props.match.params.tenantAccountId}`);
    }

    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length !== 0) {
      return "$filter=" + conditions.join(" and ");
    }
    return "";
  };

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });
    const { skip, take } = tableDataQueryOptions;

    RequestHandler.get(`/AccountTransaction?$count=true&${getFilterQuery()}${order}&$skip=${skip}&$top=${take}`).then((response) => {
      if (Array.isArray(response.value)) {
        setTableData({
          data: response.value,
          total: response["@odata.count"],
          loading: false,
        });
      }
    });
  };

  const paging = (p, filters, sorter) => {
    let order = "";
    if (sorter.columnKey && sorter.order) {
      order =
        "&" +
        (sorter.order === "ascend"
          ? odata.orderBy(sorter.columnKey)
          : odata.orderByDesc(sorter.columnKey));
    }
    setOrder(order);
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };

  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);

    RequestHandler.post(
      `/AccountTransaction/ExportExcel`,
      {
        query: getFilterQuery(),
      },
      false
    ).then((response) => {
      if (response) {
        window.open(`${config.apiUrl}/AccountTransaction/DownloadExcel?fileName=${response}&t=${RequestHandler.getToken()}`);
      }

      hideMessage();
    });
  };

  const onChangeEmail = (values) => {
    setEmail(values.target.value);
  };
  const addTag = (val) => {
    if (val.length > 1 && val.length < 21) {
      setTags([...tags, val])
      setSuggestions([])
    } else {
      countTagError("error")
    }
  }

  const countTagError = (type) => {
    notification[type]({
      message: "HATA",
      description: "Girdiğiniz etiket 2-20 karakter arasında olmalıdır.",
      duration: 4,
    });
  };

  const handleOkTag = () => {
    RequestHandler.post(`/TenantTransactionTag`,
      {
        AccountTransactionId: accountTransactionId,
        tenantTransactionTags: tags
      }, true).then((response) => {
        setIsModalTagVisible(false);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });

  };

  const handleCancelTag = () => {
    setIsModalTagVisible(false)
  };

  const showModalTag = (transactionId) => {
    setSpinLoading(true);
    setAccountTransactionId(transactionId);
    RequestHandler.get(`/TenantTransactionTag/GetListQueryableOdataWithAccountTransaction/${transactionId}`).then((response) => {
      let tagList = [];
      response.map((item) => {
        tagList.push(item.transactionTagName)
      })
      setTags(tagList)
      setSpinLoading(false);
      setIsModalTagVisible(true);
    })
  }

  const removeTag = (idx) => {
    let t = [...tags]

    t.splice(idx, 1)

    setTags(t)
  }

  const handleTagChange = (val) => {
    if (val.length > 0) {
      let new_sug = [];
      prevTagList.forEach((t) => {
        if (t.includes(val)) {
          new_sug.push(t)
        }
      })
      setSuggestions(new_sug)
    } else {
      setSuggestions([])
    }
  }

  const setDownloadPdf = () => {
    const hide = message.loading("Pdf indiriliyor...", 0);
    window.open(
      `${config.apiUrl
      }/Pdf/DownloadPdfReceiptColumnsWithDoc?id=${accountIdForModalReceipt}&isDownload=true&t=${Replace2x(
        localStorage.getItem("token")
      )}`
    );
    setIsModalVisible(true);
    alertSuccess("success");
    hide();
  };

  const alertSuccess = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Pdf indirme işlemi başarı ile gerçekleşti.",
      duration: 2,
    });
  };

  const sendSuccessMail = (type) => {
    notification[type]({
      message: "BAŞARILI",
      description: "Mail gönderme işlemi başarılı.",
      duration: 2,
    });
  };

  const showModalReceipt = (value) => {
    setAccountIdForModalReceipt(value);
    setEmailVisible(true);

    RequestHandler.get(`/Pdf/DownloadPdfReceiptColumnsWithDoc?id=${value}&isDownload=false&t=${RequestHandler.getToken()}`, undefined, { rawResponse: true }).then((res) => {
      if (res.status == 200) {
        setPdfUrl(res.data);
      }
    });
    setIsModalVisible(true);
  };

  const mailEmptyError = (type) => {
    notification[type]({
      message: "HATA",
      description: "Mail adresi girilmedi.",
      duration: 2,
    });
  };

  const mailNotFound = (type) => {
    notification[type]({
      message: "HATA",
      description: "Mail adresi kayıtlı değil.",
      duration: 2,
    });
  };

  const handleOkReceipt = () => {
    setPdfUrl("");
    setIsModalVisible(false);
  };

  const handleCancelReceipt = () => {
    setPdfUrl("");

    setIsModalVisible(false);
  };

  const sendMail = () => {
    setLoading(true);
    if (Email.length > 0) {
      RequestHandler.post(`/AccountTransaction/ReceiptSendMail`, { Email: Email, Path: pdfUrl, ScreenOption: Screen.screen }, false, { rawResponse: true }).then((res) => {
        if (res.data === "NotFound") {
          mailNotFound("error");
          setLoading(false);
        } else if (res.status == 204 || res.status == 200) {
          setLoading(false);
          setIsModalVisible(false);
          sendSuccessMail("success");
        }
      });
    } else {
      mailEmptyError("error");
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Banka",
      dataIndex: "bankId",
      key: "bankId",
      render: (text, row, index) => {
        return (
          <div style={{ width: 84 }}>
            <img src={row.tenantBankLogo}></img>
          </div>
        );
      },
    },
    tableRole.isBranchShow === true ?
      {
        title: "Şube",
        dataIndex: "tenantBranchCode",
        key: "tenantBranchCode",
        align: "center",
      } : {},
    tableRole.isTenantIbanShow === true ?
      {
        title: "Iban / Hesap Numarası",
        align: "center",
        dataIndex: "ibanOrAccountNumber",
        key: "ibanOrAccountNumber",
      } : {},
    {
      title: "Tutar",
      dataIndex: "amountString",
      key: "amountString",
      render: (text) => {
        {
          return Screen.screenGercek === 2 ? (
            <div style={{ width: 98, whiteSpace: "nowrap" }}>
              {text.includes("-") ? (
                <div style={{ color: "red" }}>{text}</div>
              ) : (
                <div style={{ color: "green", whiteSpace: "nowrap" }}>
                  {text}
                </div>
              )}
            </div>
          ) : (
            <div style={{ width: 98, whiteSpace: "nowrap" }}>{text}</div>
          );
        }
      },
    },
    tableRole.isBalanceAfterTransactionShow === true ?
      {
        title: "İşlem Sonrası Bakiye",
        dataIndex: "balanceAfterTransaction",
        render: ColumnHelper.renderDecimal,
      } : {},
    tableRole.isCurrencyShow === true ?
      {
        title: "Döviz",
        dataIndex: "currency",
        align: "center",
        key: "currency",
        render: (text) => {
          {
            return <div>{text}</div>;
          }
        },
      } : {},
    {
      title: "Tarih",
      dataIndex: "transactionDate",
      align: "center",
      width: "11%",
      sorter: true,
      key: "transactionDateValue",
      render: (text, row, index) => {
        {
          return <div> {row.transactionDate} </div>;
        }
      },
    },
    tableRole.isDescriptionShow === true ?
      {
        title: "Açıklama",
        align: "left",
        dataIndex: "description",
        key: "description",
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            <div style={{ whiteSpace: "break-spaces" }}>
              {text?.substring(0, 60) === undefined
                ? " "
                : text?.trim().length <= 60
                  ? text + ""
                  : text?.substring(0, 125) + "..."}
            </div>
          </Tooltip>
        ),
      } : {},
    tableRole.isFirmNameShow ? {
      title: "Cari Adı",
      dataIndex: "firmName",
      align: "center",
      key: "firmName",
      render: (text, row, index) =>
        text == null ? (
          <div style={{ opacity: 0.7 }}>-</div>
        ) : (
          <Tooltip placement="topLeft" title={text}>
            {text?.substring(0, 15) === undefined ? (
              <div style={{ opacity: 0.7 }}>-</div>
            ) : text?.substring(0, 15).length < 8 ? (
              text?.substring(0, 15) + ""
            ) : (
              text?.substring(0, 8) + "..."
            )}
          </Tooltip>
        ),
    } : {},
    {
      title: "Etiket",
      dataIndex: "id",
      width: "1%",
      align: "center",
      render: (data) => {
        return (
          <a
            style={{ color: "black", fontSize: "130%" }}
            onClick={() => showModalTag(data)}
          >
            {" "}
            <TagsOutlined />
          </a>
        );
      },
    },
    {
      title: "Dekont",
      dataIndex: "id",
      width: "1%",
      align: "center",
      render: (data) => {
        return (
          <a
            style={{ color: "black", fontSize: "130%" }}
            onClick={() => showModalReceipt(data)}
          >
            {" "}
            <FilePdfOutlined />
          </a>
        );
      },
    },
  ];

  const detailColumns = [
    tableRole.isIbanShow ?
      {
        title: "Karşı Iban",
        dataIndex: "iban",
      } : {},
    tableRole.isVknTcknShow ?
      {
        title: "Vkn / T.C. No",
        dataIndex: "vkn",
        render: (data, row, index) => data ?? row.tckn,
      } : {},
    tableRole.isTransactionTypeShow ?
      {
        title: "İşlem Tipi",
        dataIndex: "transactionTypeName",
      } : {},
    tableRole.isSenderNameShow ?
      {
        title: "Gönderen Adı",
        dataIndex: "senderName",
      } : {},
    tableRole.isReceiptShow ?
      {
        title: "Dekont No",
        dataIndex: "receiptNumber",
      } : {},
    tableRole.isDescriptionShow ?
      {
        title: "Banka Açıklaması",
        dataIndex: "description",
      } : {},
    tableRole.isAdditionalDescriptionShow ?
      {
        title: "Ek Açıklama",
        dataIndex: "additionalDescription",
      } : {},
  ];

  return (
    <>

      {props.match.params.tenantAccountId && (
        <Row style={{ marginBottom: 9 }}>
          <Col flex={1}>
            <a
              onClick={() => history.goBack()}
              style={{ color: "black", marginLeft: "4%" }}
            >
              {" "}
              <RollbackOutlined /> GERİ DÖN
            </a>
          </Col>
          <Col flex={4}></Col>
        </Row>
      )}

      <Row>
        <Col xs={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> ŞUBE LİSTESİ - HESAP HAREKETLERİ
          </p>
        </Col>
        <Col xs={8}>
          <Row justify="end" align="middle" style={{ paddingRight: 16 }}>
            <Tooltip title="Excel İndir">
              <a
                style={{
                  color: "green",
                  fontSize: "180%",
                  marginRight: 30,
                }}
                onClick={onClickExportExcel}
              >
                <FileExcelOutlined />
              </a>
            </Tooltip>
          </Row>
        </Col>
      </Row>

      <AccountBankTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            expandable={{
              expandedRowRender: (row) =>
                RowHelper.renderRowDetail({
                  data: row,
                  columns: detailColumns,
                }),
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{
              overflowX: "auto",
              maxHeight: "100%",
              width: "100%",
            }}
            rowKey="id"
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>

      <Modal
        title="Etiket"
        width={600}
        style={{ height: 1000 }}
        visible={isModalTagVisible}
        onOk={handleOkTag}
        onCancel={handleCancelTag}
        okText="Kaydet"
      >
        <Spin spinning={spinLoading} tip="Loading...">

          <ReactTags
            tags={tags}
            suggestions={suggestions}
            placeholder="Etiket girin"
            onAddHandler={(val) => addTag(val)}
            onDeleteHandler={(idx) => removeTag(idx)}
            onChangeHandler={(val) => handleTagChange(val)}
          />
        </Spin>
      </Modal>

      {isDesktopOrLaptop ? (
        <Modal
          title="Dekont"
          width={1000}
          style={{ height: 1000 }}
          visible={isModalVisibleReceipt}
          onOk={handleOkReceipt}
          onCancel={handleCancelReceipt}
          footer={null}
        >
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            fields={[{ name: "Email", value: Email }]}
          >
            <Row>
              <Col span={20}></Col>
              <Col span={2}>
                {EmailVisible ? (
                  <MailOutlined
                    style={{ fontSize: 20, marginBottom: 20 }}
                    onClick={() => setEmailVisible(!EmailVisible)}
                  />
                ) : (
                  <MailFilled
                    style={{ fontSize: 20, marginBottom: 20 }}
                    onClick={() => setEmailVisible(!EmailVisible)}
                  />
                )}
              </Col>
              <Col span={2}>
                <DownloadOutlined
                  style={{ fontSize: 20, marginBottom: 20 }}
                  onClick={() => setDownloadPdf()}
                />
              </Col>
            </Row>
            <Row hidden={EmailVisible}>
              <Col span={18}>
                <Form.Item
                  label="Mail Adresi"
                  name="Email"
                  style={{ width: "100%" }}
                >
                  <Input value={Email} onChange={onChangeEmail} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => sendMail()}
                    loading={loading}
                  >
                    Gönder
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Iframe
            url={pdfUrl}
            width="100%"
            height="1000px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
          />
        </Modal>
      ) : (
        <Modal
          title="Dekont"
          visible={isModalVisibleReceipt}
          onOk={handleOkReceipt}
          onCancel={handleCancelReceipt}
          footer={null}
        >
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            fields={[{ name: "Email", value: Email }]}
          >
            <Row>
              <Col span={14}></Col>
              <Col span={5}>
                {EmailVisible ? (
                  <MailOutlined
                    style={{ fontSize: 20, marginBottom: 20 }}
                    onClick={() => setEmailVisible(!EmailVisible)}
                  />
                ) : (
                  <MailFilled
                    style={{ fontSize: 20, marginBottom: 20 }}
                    onClick={() => setEmailVisible(!EmailVisible)}
                  />
                )}
              </Col>
              <Col span={5}>
                <DownloadOutlined
                  style={{ fontSize: 20, marginBottom: 20 }}
                  onClick={() => setDownloadPdf()}
                />
              </Col>
            </Row>
            <Row hidden={EmailVisible}>
              <Col span={12}>
                <Form.Item label="" name="Email" style={{ width: "140%" }}>
                  <Input value={Email} onChange={onChangeEmail} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => sendMail()}
                    loading={loading}
                  >
                    Gönder
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Iframe
            url={pdfUrl}
            width="100%"
            height="400px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
          />
        </Modal>
      )}
    </>
  );
};

export default AccountBank;
