import React, { useState, useEffect } from "react";
import Widgets from "../../HomeCards/Card";
import CurrencyFormat from "react-currency-format";
import RequestHandler from "util/RequestHandler";
import moment from "moment";

const PosReportValueTotalCurrentMonth = () => {
  const [widgetData, setWidgetData] = useState({
    loading: true,
  });

  useEffect(() => getData(), []);

  const getData = () => {
    RequestHandler.get("/PosDashboard/ValueTotalCurrentMonth").then((response) => {
      setWidgetData({
        data: response.data,
        loading: false,
      });
    });
  };

  return (
    <Widgets
      icon="calendar"
      currency={`${moment().format('DD')}-${moment().endOf('month').format('DD MMMM')}`}
      title={"Valör Toplamı"}
      totalBalance={
        <CurrencyFormat
          value={widgetData.data}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
          prefix={"₺"}
        />
      }
    />
  );
};

export default PosReportValueTotalCurrentMonth;
