import React, { useEffect,useState } from 'react'
import {Table,Empty, Col,notification,Row,Tabs} from "antd";
import axios from "axios"
import CurrencyFormat from 'react-currency-format';
import config from "../../util/ApiforContext"
import { useHistory } from 'react-router-dom';
import { RollbackOutlined} from '@ant-design/icons';

const expiration = localStorage.getItem("expiration");
function currencyFormat(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
const ReportMonthlyDetail = (props) => {
  const history = useHistory();
  const [detailReport, SetDetailReport] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const errorList = [];
    const errorListNotification = type => {
      errorList.map((item)=> {
        notification[type]({
          message: 'Hata',
          description:
          item
           ,
            duration: 2,
        });
      })
    };
  useEffect(() => {
    if(expiration<Date.now()){
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location.reload();
    }
    if(localStorage.getItem("token")==null){
      window.location.reload()
    }
    axios.get(`${config.apiUrl}/TenantCurrency?$apply=groupby((Currency,CurrencyId))&$orderby=currencyId`,config.headers)
    .then(res => {
      setCurrencyList(res.data)
        setActiveKey(res.data[0]?.currencyId.toString())
        axios.post(`${config.apiUrl}/Reports/GetDailyReportsDetailCollect`,{
          Date : props.match.params.date,
          CurrencyId: res.data[0]?.currencyId.toString(),
          CurrencyCode: props.match.params.currency
      },config.headers)
    .then(res => {
      SetDetailReport(res.data)
    }).catch(
      error =>  {
        if(error.response.status===403 || error.response.status ===401){
          history.push('/not-authorized-access');
        }else if(error.response.status === 406){
          error.response.data.data.map((item)=>{
            errorList.push(item);
          })
          errorListNotification('error')
        }
      }
  );
    })
    
  },[]);

  const  columns = [
      {
        title: 'Cari',
        dataIndex: 'firmName',
        width: '20%',
          render: (text, row, index) => {
            return <div>{text}</div>
        },
      },
      {
        title: 'Cari Kodu',
        dataIndex: 'firmCode',
        width: '20%',
          render: (text, row, index) => {
            return <div>{text}</div>
        },
      },
      {
        title: 'Plan',
        dataIndex: 'collectPlanTotal',
        width: '20%',
          render: (text, row, index) => {
            if(text === null){
              return '-'
            }else{
              if(text.toString().includes(".") === true){
                return <div><CurrencyFormat value={parseFloat(text)} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} /></div>
              }else{
                return <div><CurrencyFormat value={parseFloat(text)} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} />{',00'}</div>
              }
            }
        },
      },
      {
        title: 'Gerçekleşen',
        dataIndex: 'collectTotal',
        width: '20%',
          render: (text, row, index) => {
            if(text === null){
              return '-'
            }else{
              if(text.toString().includes(".") === true){
                return <div><CurrencyFormat value={parseFloat(text)} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} /></div>
              }else{
                return <div><CurrencyFormat value={parseFloat(text)} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} />{',00'}</div>
              }
            }
        },
      },
    ];
  
    const handleChange = (activeKey) => {
        axios.post(`${config.apiUrl}/Reports/GetDailyReportsDetailCollect`,{
          Date : props.match.params.date,
          CurrencyId: activeKey
        },config.headers)
        .then(res => {
          SetDetailReport(res.data)
        }).catch(
          error =>  {
            if(error.response.status===403 || error.response.status ===401){
              history.push('/not-authorized-access');
            }else if(error.response.status === 406){
              error.response.data.data.map((item)=>{
                errorList.push(item);
              })
              errorListNotification('error')
            }
          }
      );
      setActiveKey(activeKey)
    };
        
        const { TabPane } = Tabs;
        
        return (
         <div>
           <Row style={{marginBottom:9}}>
         <Col flex={1}><a onClick={() => history.goBack()} style={{color:'black',marginLeft:"4%"}}> <RollbackOutlined /> GERİ DÖN</a></Col>
         <Col flex={4}></Col>
       </Row>
                <Row>
                <Col span={24}>
                <Table
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" /> }}
                    style={{ overflowX: "auto",maxHeight: "100%"}}
                    columns={columns}
                    size="small"
                    dataSource={detailReport}
                    rowKey="Id"
                    bordered
                    title={() => `TAHSİLAT DETAY - ( ${props.match.params.currency} )`}
                    footer={() => ''}
                    />
                </Col>
                </Row>
         </div>
           
        )
}
export default ReportMonthlyDetail;
