import React, {useEffect,useState} from 'react';
import ReactDOM from 'react-dom';
import {useHistory} from "react-router-dom";
import { Form, Input,Tag, Button, Select,Table,Badge,Row,Col,Upload,message,notification } from 'antd';
import moment from 'moment'
import RequestHandler from "util/RequestHandler";
import Replace2x from "../../util/Extensions/ReplaceExtension";
import config from "../../util/ApiforContext";
import axios from 'axios';
import {
  SyncOutlined,
  UnorderedListOutlined,
  CheckCircleOutlined,
  UploadOutlined
} from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';
import { fill } from 'lodash';
const {TextArea} = Input;
const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 24,
    span: 16,
  },
};
const formRef = React.createRef();
    const onTopicChange = (value) => {
  };
    const onImportanceChange = (value) => {
        
  };
  
  const openNotificationWithIconWarning = type => {
    notification[type]({
      message: 'HATA',
      description:
        'Fotoğraf yükleme işlemi sırasında bir hata oluştu.',
        duration: 2,
    });
};
  const onReset = () => {
    formRef.current.resetFields();
  };

  
const SupportRequestFirst = () =>  {
  const [moduleList, setModuleList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const errorList = [];
    const errorListNotification = type => {
      errorList.map((item)=> {
        notification[type]({
          message: 'Hata',
          description:
          item
           ,
            duration: 2,
        });
      })
    };
  useEffect(() => {
    RequestHandler.get("/module/GetDefaultSelectableModules?isSupportPanel=true").then((response) => {
      setModuleList(response)
    });

    RequestHandler.get("/SupportPanel/GetTitles").then((response) => {
      setMessageList(response)
    });
  }, [])
  const props = {
    beforeUpload: file => {
      setFileList(file);
      return false;
    }
  };

  const goMessages = (val) =>{
    history.push(`/support-request/${val}`)
  }
  const onFinishFailed = (values) => {
    setLoading(false);
  };
  const onFinish = (values) => {
    let images = new FormData();
    images.append('images', fileList);
     setLoading(true);
    
     RequestHandler.post(
       `/SupportPanel/AddTitle`,
       {
         Title: values.Title,
         Importance: values.Importance,
         TopicId: values.Topic,
         UserId: Replace2x(localStorage.getItem("userId")),
         Message: values.Message
       },
       false
     ).then((response) => {


       if(response.success){
        if(fileList.length !== 0){
          var bodyFormData = new FormData();
          bodyFormData.append('images', fileList);
  
          axios({
            method: "post",
            url: `${config.apiUrl}/SupportPanel/AddImage`,
            data: bodyFormData,
            headers: { 
                        "Content-Type": "multipart/form-data",
                        Authorization: "bearer " +  Replace2x(localStorage.getItem("token")),
                        'titleId': response.data.id,
                        'userId': response.data.userId
          },
          }).then(function (res) {
            history.push(`/support-request/${response.data.id}`);
              
            }).catch(
              error => {
                    if(error.response.status===403 || error.response.status ===401){
                      history.push('/not-authorized-access');
                    }else if(error.response.status === 406){
                      error.response.data.Data.map((item)=>{
                        errorList.push(item);
                      })
                      errorListNotification('error')
                    }else{
                      openNotificationWithIconWarning('error')
                    }}
          );
        }else{
          history.push(`/support-request/${response.data.id}`);
        }
      

       }
     });
    //  history.push('/support-request')
  };
  const columns = [
    {
      title: 'Başlık',
      dataIndex: 'title',
      key: 'title',
      render: (text, row, index) =>
      <div style={{width:550}}>
        {text}
      </div>
    }, 
    {
      title: 'Konu',
      dataIndex: 'topicText',
      align:'center',
      key: 'topicText',
    },
    {
      title: 'Talep Tarihi',
      dataIndex: 'adddate',
      align:'center',
      key: 'adddate',
      render: (data, row, index) => moment(row.addDate).format('L LTS'),
    },
    {
      title: 'Talep Durumu',
      dataIndex: 'status',
      align:'center',
      key: 'status',
      render: (text, row, index) =>
      <>
        {
          row.isClosed ? 
          <Tag icon={<CheckCircleOutlined />} color="success">
            Kapandı
          </Tag>  :
          <Tag icon={<SyncOutlined spin />} color="processing">
          Açık
          </Tag>
        }
       
      </>
    },
    {
      title: 'Mesajlar',
      dataIndex: 'address',
      key: 'address',
      render: (text, row, index) =>
        <a  onClick={() => goMessages(row.id)}>
          {row.isNewMessage ? 
           <Badge dot>
          <UnorderedListOutlined style={{fontSize:20}} />
          </Badge>
          :
          <UnorderedListOutlined style={{fontSize:20}} /> }
         </a>
    },
  ];
const history = useHistory();

        return (
            <>
                <Form ref={formRef} name="control-ref" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                  <Row>
                    <Col span={8}>
                      <Form.Item
                      name="Title"
                      rules={[
                          {
                          required: true,
                          message: "Başlık alanı boş bırakılamaz."
                          },
                      ]}
                      >
                      <Input placeholder='Başlık' maxLength={25}/>
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                        name="Topic"
                        rules={[
                            {
                            required: true,
                            message: "Modül alanı boş bırakılamaz."
                            },
                        ]}
                        >
                        <Select
                            placeholder="Hangi modül için "
                            onChange={onTopicChange}
                            allowClear
                        >
                            {
                              moduleList.map((item)=>{
                                return <Option value={item.id}>{item.name}</Option>
                              })
                            }
                        </Select>
                        </Form.Item>            
                    </Col>

                    <Col span={8}>
                      <Form.Item
                      name="Importance"
                      rules={[
                        {
                        required: true,
                        message: "Önem derecesi alanı boş bırakılamaz."
                        },
                    ]}
                      >
                      <Select
                          placeholder="Önem derecesini yazın"
                          onChange={onImportanceChange}
                          allowClear
                      >
                          <Option value={0}>Normal</Option>
                          <Option value={1}>Önemli</Option>
                          <Option value={2}>Acil</Option>
                      </Select>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                      name="Message"
                      rules={[
                        {
                        required: true,
                        message: "Mesaj alanı boş bırakılamaz."
                        },
                    ]}
                      >
                      <TextArea placeholder='Mesaj' />
                      </Form.Item>
                    </Col>
                  </Row>
                   
                    <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
                    >
                    {({ getFieldValue }) =>
                        getFieldValue('gender') === 'other' ? (
                        <Form.Item
                            name="customizeGender"
                            label="Customize Gender"
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        ) : null
                    }
                    </Form.Item>
                    <div style={{display:'flex',justifyContent:'right'}}>
                    <Form.Item style={{marginRight:20}}>
                    <Upload {...props}>
                      <Button icon={<UploadOutlined />}>Fotoğraf Ekle</Button>
                    </Upload>
                    </Form.Item>
                    <Form.Item >

                    <Button style={{width:'100%'}} type="primary" htmlType="submit"  onClick={() => setLoading(true)} loading={loading}>
                        Talep Başlat
                    </Button>
                    </Form.Item>
                    </div>
                </Form>

                <h3 style={{marginBottom:30}}>Geçmiş Taleplerim</h3>
                <Table dataSource={messageList} size={'small'} columns={columns} />
            </>
        )
}
export default SupportRequestFirst;