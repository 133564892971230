import React from 'react';
import { Result, Button } from 'antd';

const Authorize = () => {
    return (
        <div>
            <Result
                status="403"
                title="403"
                subTitle="Üzgünüm bu sayfa için yetkiniz bulunmamaktadır."
                extra={<Button type="primary" href='/home'>Anasayfa'ya dön</Button>}
            />
        </div>
    )
}

export default Authorize