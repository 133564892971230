import React, { useState, useEffect } from "react";
import RequestHandler from "util/RequestHandler";
import moment from "moment";
import DailyReportTableFilter from "../Filters/DailyReportTableFilter";
import { ColumnHelper } from "util/TableHelper";
import { Table, Empty, Col, Row, Tooltip, message } from "antd";
import { BarsOutlined, FileExcelOutlined,MenuUnfoldOutlined } from "@ant-design/icons";
import config from "../../util/ApiforContext";
import { useHistory } from "react-router-dom";
const MonthlyReportAccount = (props) => {
const history = useHistory();

  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableDataQueryOptions, setTableDataQueryOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
    filter: "",
  });

  useEffect(() => getTableData(), [tableDataQueryOptions]);

  const GoDailyAccountReport = (value,date) => {
    history.push(`monthly-report-account/${value}/${date}`);
  };
  const getFilterQuery = () => {
    const { filter } = tableDataQueryOptions;

    const conditions = [];
    let query = "";

    if (filter) {
      conditions.push(filter);
    }

    if (conditions.length !== 0) {
      query = "$filter=" + conditions.join(" and ");
    }
    return query;
  };

  const getTableData = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    const { skip, take } = tableDataQueryOptions;
    RequestHandler.get(
      `/DailyBalanceAccountReport?isAccountDaily=true&bankId=${props.match.params.bankId}&transactionDate=${props.match.params.transactionDate}&$count=true&${getFilterQuery()}&$skip=${skip}&$top=${take}`
    ).then((response) => {
      setTableData({
        data: response.value,
        total: response["@odata.count"],
        loading: false,
      });
    });
  if (props.onFilterChange) {
    props.onFilterChange(tableDataQueryOptions);
  }
  };
  
  const paging = (p) => {
    setTableDataQueryOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const onClearTableFilter = () => {
    setTableDataQueryOptions({
      skip: 0,
      current: 1,
      take: tableDataQueryOptions.take,
      filter: "",
    });
  };

  const onFinishTableFilter = (query) => {
    setTableDataQueryOptions((prevState) => {
      return { skip: 0, current: 1, take: prevState.take, filter: query };
    });
  };
  const onClickExportExcel = () => {
    const hideMessage = message.loading("Excel indiriliyor...", 0);

    RequestHandler.post(
      `/DailyBalanceAccountReport/ExportExcel`,
      {
        query: `isAccountDaily=true&bankId=${props.match.params.bankId}&transactionDate=${props.match.params.transactionDate}&${getFilterQuery()}`,
      },
      false
    ).then((response) => {
      hideMessage();
      if (response) {
        window.open(
          `${
            config.apiUrl
          }/DailyBalanceAccountReport/DownloadExcel?fileName=${response}&t=${RequestHandler.getToken()}`
        );
      }
    });
  };
  const tableColumns = [
    {
      title: "Banka",
      dataIndex: "bankLogo",
      key: "bankLogo",
      render: (text, row, index) => {
        return (
          <div style={{ width: 100 }}>
            {" "}
            <img src={text}></img>
          </div>
        );
      },
    },
    {
      title: "Hesap Numarası / Iban",
      dataIndex: "accountNumber",
      key: "accountNumber",
      align: "center",

      render: (text, row, index) => {
        return (
          <div>{text}
          </div>
        );
      },
    },
    {
      title: "Tarih",
      dataIndex: "transactionDate",
      key: "transactionDate",
      align: "center",
      render: (data, row, index) => props.match.params.date,
    },
    {
      title: "Döviz Kuru",
      dataIndex: "currencyCode",
      key: "currencyCode",
      align: "center"
    },
    {
      title: "Bakiye",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: ColumnHelper.renderDecimal,
    }
    
  ];

  return (
    <>
     <Row>
        <Col xs={24} md={12} lg={16}>
          <p style={{ fontSize: "120%", marginTop: -5 }}>
            <BarsOutlined /> GÜN BAZLI HESAP DETAY
          </p>
        </Col>
        <Col xs={24} md={12} lg={8} style={{ textAlign: "right" }}>
          <Tooltip title="Excel İndir">
            <a
              style={{
                color: "green",
                fontSize: "180%",
                marginRight: 30,
              }}
              onClick={onClickExportExcel}
            >
              <FileExcelOutlined />
            </a>
          </Tooltip>
        </Col>
      </Row>
      <DailyReportTableFilter
        onClearFilter={onClearTableFilter}
        monthly={false}
        isAccountFilter={true}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={tableColumns.filter((column) => !column.isRowDetail)}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Veri Yok"
                />
              ),
            }}
            style={{
              overflowX: "auto",
              maxHeight: "100%",
              width: "100%",
            }}
            rowKey={(record, index) => index}
            filterable={true}
            sortable={true}
            pageable={true}
            onChange={paging}
            pagination={{
              defaultPageSize: tableDataQueryOptions.take,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: tableData.total,
              current: tableDataQueryOptions.current,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};

export default MonthlyReportAccount;
