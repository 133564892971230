import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Checkbox,
  Select,
  Upload,
  notification,
} from "antd";
import {
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "./Helper";
import ParaticPhoneInput from "./Helper/ParaticPhoneInput";
import { odata } from "../Filters/Helper";
import ScreenOptions from "../../util/Extensions/Screens";
import config from "../../util/ApiforContext";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const B2bFirmUserAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [firmSelect, setFirmSelect] = useState({
    data: [],
    loading: false,
  });
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onFirmSeach = (value) => {
    if (value.length >= 2) {
      setFirmSelect({ ...firmSelect, loading: true });

      RequestHandler.get(
        `/Firm?$select=id,firmBranchNumber,name&$filter=${odata.contains(
          "name",
          value
        )} or ${odata.contains("firmBranchNumber", value)}`
      ).then((res) => {
        setFirmSelect({ data: res.value, loading: false });
      });
    } else {
      setFirmSelect({ data: [], loading: false });
    }
  };

  const onSuccess = () => {
    form.resetFields();
    setFileList([]);

    if (props.onSuccess) props.onSuccess();
  };

  const onFinish = (values) => {
    values.phoneNumber = values.phoneNumber?.slice(2);
    values.screenOption = ScreenOptions.screen;

    RequestHandler.post(`/TenantFirmUser`, values).then((response) => {
      setLoading(false);
      if (response.success) {
        onSuccess();
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  const downloadExcelTemplate = () => {
    window.open(
      `${
        config.apiUrl
      }/TenantFirmUser/DownloadExcelTemplate?t=${RequestHandler.getToken()}`
    );
  };

  const downloadImportResult = (fileName) => {
    window.open(
      `${
        config.apiUrl
      }/TenantFirmUser/DownloadImportResult?t=${RequestHandler.getToken()}&fileName=${fileName}`
    );
  };

  const uploadExcelProps = {
    name: "excel",
    action: `${config.apiUrl}/TenantFirmUser/ImportExcel`,
    headers: {
      Authorization: "bearer " + RequestHandler.getToken(),
      ScreenOption: ScreenOptions.screen
    },
    accept: ".xlsx",
    maxCount: 1,
    onChange(info) {
      setFileList(info.fileList);
      const { response, status } = info.file;

      if (status !== "error" && status !== "done") return;
      if (!response) return;

      const notificationType = response.success ? "success" : "error";

      notification[notificationType]({
        message: response.message,
        duration: 3,
      });
      if (!response.success) {
        downloadImportResult(info.file.response.data);
      }
      onSuccess();
    },
  };
  return (
    <Form
      {...formItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="firmUserAdd"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>
          <Form.Item label="Cari" name="firmId" rules={[{ required: true }]}>
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              onSearch={onFirmSeach}
              filterOption={(input, option) =>
                option.children
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0 ||
                option.code
                  .toLocaleLowerCase("tr-TR")
                  .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
              }
              loading={firmSelect.loading}
            >
              {firmSelect.data.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                  code={item.firmBranchNumber}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Ad Soyad" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="E-Posta"
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Şifre" name="password">
            <Input.Password autoComplete="new-password" />
          </Form.Item>

          <Form.Item label="Telefon Numarası" name="phoneNumber">
            <ParaticPhoneInput />
          </Form.Item>

          <Form.Item
            label="Ödeme Planı"
            name="paymentPlan"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            label="Tahsilat Planı"
            name="collectionPlan"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            label="Pos Tahsilatı"
            name="posCollection"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item label="Stoklar" name="stocks" valuePropName="checked">
            <Checkbox />
          </Form.Item>

          <Form.Item
            label="Dbs Faturalar"
            name="dbsInvoices"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            label="Fatura İskontolama"
            name="invoiceDiscount"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item label="Erp Rapor" name="erpReport" valuePropName="checked">
            <Checkbox />
          </Form.Item>

          <Row justify="space-between">
            <Col>
              <Button
                icon={<DownloadOutlined />}
                onClick={(() => setLoading(true), downloadExcelTemplate)}
                loading={loading}
              >
                Excel Şablonu İndir
              </Button>
              <Upload {...uploadExcelProps} fileList={fileList}>
                <Button icon={<UploadOutlined />}>Excel ile Ekle</Button>
              </Upload>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                htmlType="submit"
                onClick={() => setLoading(true)}
                loading={loading}
              >
                Ekle
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default B2bFirmUserAddForm;
